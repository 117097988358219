import {
  Button, Icon,
} from '@chakra-ui/react';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import React from 'react';
import CenteredFlex from '~/components/CenteredFlex';

const LeftRightArrowButtons = (
  {
    onLeftClick,
    onRightClick,
    color = 'rgba(53,155,75, 0.05)',
    isDisabled,
  } : {
    onLeftClick: () => void;
    onRightClick: () => void;
    color?: string;
    isDisabled: boolean;
  },
) => (
  <CenteredFlex direction="column">
    <Button
      isDisabled={isDisabled}
      borderWidth={1}
      borderColor="black"
      width="40px"
      height={5}
      onClick={onLeftClick}
    >
      <Icon as={ChevronLeft} />
      <Icon as={ChevronLeft} ml={-4} />
    </Button>
    <Button
      isDisabled={isDisabled}
      mt={2}
      backgroundColor={color}
      _hover={{
        backgroundColor: color,
        borderColor: 'gray.300',
      }}
      borderWidth={1}
      borderColor="gray.200"
      width="40px"
      height={5}
      onClick={onRightClick}
    >
      <Icon color="black" as={ChevronRight} />
      <Icon color="black" as={ChevronRight} ml={-4} />
    </Button>
  </CenteredFlex>
);

export default LeftRightArrowButtons;
