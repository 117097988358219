import {
  Button,
  Modal, ModalBody, ModalCloseButton,
  ModalContent, ModalFooter, ModalHeader, ModalOverlay,
  Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import PriceActions from '~/redux/prices/actions';
import { useAppDispatch } from '~/redux/store';
import { Price } from '~/types/price';
import PriceEditForm from './PriceForm';

interface PriceEditModalProps {
  price: Price,
  title: string,
  onClose: () => void,
}

const PriceEditModal = ({
  price,
  title = 'Edit rate',
  onClose = () => {},
}: PriceEditModalProps) => {
  const dispatch = useAppDispatch();
  const [editedPrice, setEditedPrice] = useState(price);

  const onSaveClicked = () => {
    dispatch(PriceActions.save({
      price: editedPrice,
    }));
    onClose();
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen
      onClose={onClose}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {title}
          <Text fontFamily="open sans" fontWeight="normal" fontSize="xl">
            Labour, equipment, consumables and other costs
          </Text>
        </ModalHeader>
        <ModalCloseButton tabIndex={-1} />
        <ModalBody>
          <PriceEditForm
            value={editedPrice}
            onChange={(p) => {
              setEditedPrice({
                ...editedPrice,
                ...p,
              });
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="brandDark"
            disabled={!editedPrice.name || editedPrice.unit === null}
            onClick={onSaveClicked}
          >
            Save
          </Button>
          <Button tabIndex={-1} variant="ghost" onClick={onClose}>cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PriceEditModal;
