import { Button, ButtonProps } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';

interface GreenButtonProps extends ButtonProps {
  to?: string;
}

const GreenButton = ({ children, to, ...props } : GreenButtonProps) => (
  <Button
    bg="magnetize.brand-4"
    _hover={{
      bg: 'magnetize.brand-4',
    }}
    to={to}
    as={to ? RouterLink : undefined}
    {...props}
  >
    {children}
  </Button>
);

export default GreenButton;
