import {
  Icon, Table, Text, Tbody, Tr, VStack, Td, Skeleton, MenuItem,
} from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
import React, { useState } from 'react';
import { ReactComponent as RefusalIcon } from '~/assets/live_geo_refusal_icon.svg';
import ConfirmationDialog from '~/components/ConfirmationDialog';
import { ExpandableCountSection } from '~/components/ExpandableCountSection';
import { KebabOptionsMenu } from '~/components/KebabOptionsMenu';
import LinkButton from '~/components/LinkButton';
import { useModalManager } from '~/components/ModalManager';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import { RefusalReasonEditModal } from './RefusalReasonEditModal';
import { triggerRefusalReasonsFetch, deleteRefusalReason } from '~/redux/refusalReasons/actions';
import { getActiveRefusalReasons } from '~/redux/refusalReasons/selectors';
import { useAppDispatch } from '~/redux/store';
import { RefusalReason } from '~/types/refusalReason';
import eventStopper from '~/utils/eventStopper';

export const RefusalReasonsList = () => {
  const dispatch = useAppDispatch();
  const modalManager = useModalManager();
  const { data: refusalReasons, isLoading } = useTrackedFetch({
    key: 'refusal-reasons',
    trigger: () => triggerRefusalReasonsFetch({ force: false }),
    selector: getActiveRefusalReasons,
  });

  const [
    selectedRefusalReason,
    setSelectedRefusalReason,
  ] = useState<Partial<RefusalReason>>(null);
  const [refusalReasonModalTitle, setRefusalReasonModalTitle] = useState<string>();

  const addTechnique = () => {
    setSelectedRefusalReason({
      id: nanoid(),
    });
    setRefusalReasonModalTitle('Add refusal reason');
  };

  const editTechnique = (refusalReason: RefusalReason) => {
    setSelectedRefusalReason(refusalReason);
    setRefusalReasonModalTitle('Edit refusal reason');
  };

  const deleteTechnique = (refusalReasonId) => {
    dispatch(deleteRefusalReason({
      id: refusalReasonId,
    }));
  };

  return (
    <VStack
      align="stretch"
    >
      <ExpandableCountSection
        title="Refusal reasons"
        icon={<Icon boxSize={8} as={RefusalIcon} />}
        count={refusalReasons?.length || 0}
      >
        <Table size="md">
          <Tbody>
            {isLoading ? (
              <Tr>
                <Td><Skeleton height={8} /></Td>
                <Td><Skeleton height={8} /></Td>
                <Td />
              </Tr>
            )
              : (orderBy(refusalReasons, (t) => t.name).map((tr) => (
                <Tr
                  key={tr.id}
                  _hover={{ bg: '#fafafa', cursor: 'pointer' }}
                  transition="color 0.1s ease-out"
                  role="group"
                  onClick={() => editTechnique(tr)}
                >
                  <Td>
                    <Text pl={24}>
                      {tr.name}
                    </Text>
                  </Td>

                  <Td textAlign="end" {...eventStopper()}>
                    <KebabOptionsMenu>
                      <MenuItem onClick={() => editTechnique(tr)}>Edit</MenuItem>
                      <MenuItem
                        onClick={() => {
                          modalManager.open(ConfirmationDialog, {
                            title: 'Delete refusal reason',
                            children: 'This will remove the refusal reason from Magnetize, but will not remove it from any existing logs.',
                            onConfirm: () => deleteTechnique(tr.id),
                            confirmButtonLabel: 'Delete',
                          });
                        }}
                      >
                        Delete
                      </MenuItem>
                    </KebabOptionsMenu>
                  </Td>
                </Tr>
              )))}
          </Tbody>
        </Table>
        <LinkButton ml={24} mt={6} isGreen noUnderline onClick={addTechnique}>
          + add refusal reason
        </LinkButton>
        {!!selectedRefusalReason
      && (
      <RefusalReasonEditModal
        title={refusalReasonModalTitle}
        onClose={() => {
          setSelectedRefusalReason(null);
        }}
        refusalReason={selectedRefusalReason}
      />
      )}
      </ExpandableCountSection>
    </VStack>
  );
};
