import {
  Icon, Text, VStack,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import {
  isEmpty, isNumber, map, orderBy, values,
} from 'lodash';
import React from 'react';
import { ReactComponent as Drill } from '~/assets/live_geo_drill_icon.svg';
import { CountSectionAccordionItem } from '~/components/CountSectionAccordionItem';
import ExpandableText from '~/components/ExpandableText';
import LogRow from '~/pages/LiveGeo/components/LogRow';
import { CasingType } from '~/types/casingType';
import { ClearanceTechnique } from '~/types/clearanceTechnique';
import { DrillingTechnique } from '~/types/drillingTechnique';
import { Equipment } from '~/types/equipment';
import {
  Borehole,
} from '~/types/geotechnical';

interface DrillLogsRowProps {
  borehole: Borehole,
  equipment: Partial<Equipment>[];
  drillingTechniques: { [key: string]: DrillingTechnique };
  casingTypes: { [key: string]: CasingType };
  clearanceTechniques: { [key: string]: ClearanceTechnique };
}

const DrillLogsRow = ({
  equipment,
  borehole,
  drillingTechniques,
  casingTypes,
  clearanceTechniques,
} : DrillLogsRowProps) => {
  const ordered = orderBy(values(borehole.drillLogs), (dl) => dl.depth);

  return (
    <CountSectionAccordionItem
      title="Drill Logs"
      icon={<Icon boxSize={8} as={Drill} />}
    >
      { [
        ...(borehole.undergroundServiceClearanceTechnique ? [
          <LogRow key="clearance">
            <Text fontWeight="semibold" minW="62px">
              {borehole.undergroundServiceClearanceTechnique !== 'not-required' && (
                `0m - ${borehole.undergroundServiceClearanceDepth}m`
              )}
            </Text>
            <VStack alignItems="flex-start">
              <Text>
                {isEmpty(ordered) ? '' : format(ordered[0].startTime, 'dd MMM yyyy')}
              </Text>
              <Text>
                {isEmpty(ordered) ? '' : format(ordered[0].startTime, 'h:mm aa')}
              </Text>
            </VStack>
            <VStack alignItems="flex-start">
              <Text>Services Clearance</Text>
              <Text>
                {clearanceTechniques[borehole.undergroundServiceClearanceTechnique]?.name}
              </Text>
            </VStack>
            <ExpandableText fontStyle="italic">
              {borehole.undergroundServiceNote || ''}
            </ExpandableText>
          </LogRow>,
        ] : []),
        ...map(ordered, (log, index) => {
          const prevLog = index > 0 ? ordered[index - 1] : undefined;
          const technique = drillingTechniques[log.drillingTechnique];
          const logEquipment = equipment.find((e) => e.id === log.equipmentId);
          let nextDrillDepth = ordered[index + 1]?.depth;
          if (!isNumber(nextDrillDepth) && isNumber(borehole.endDepth)) {
            nextDrillDepth = borehole.endDepth;
          }
          return (
            <LogRow key={`drill-log-${index}`}>
              <Text fontWeight="semibold" minW="62px">
                {`${log.depth}m - ${nextDrillDepth ? `${nextDrillDepth}m` : ''}`}
              </Text>
              <VStack alignItems="flex-start">
                <Text>
                  {format(log.startTime, 'dd MMM yyyy')}
                </Text>
                <Text>
                  {format(log.startTime, 'h:mm aa')}
                </Text>
              </VStack>
              <VStack alignItems="flex-start">
                {prevLog?.equipmentId !== log.equipmentId && (
                  <Text>
                    {`${logEquipment?.name}${logEquipment?.hammerEfficiency ? ` (HE: ${logEquipment?.hammerEfficiency}%)` : ''}`}
                  </Text>
                )}
                <Text>
                  {technique?.name}
                </Text>
              </VStack>
              <ExpandableText fontStyle="italic">
                {log.note || ''}
              </ExpandableText>
            </LogRow>
          );
        }),
        ...(isNumber(borehole.endDepth) ? [(
          <LogRow key="drill-log-end">
            <Text fontWeight="semibold" minW="62px">
              {`${borehole.endDepth}m`}
            </Text>
            <Text>
              {format(borehole.endTime, 'dd MMM yyyy')}
            </Text>
            <VStack alignItems="flex-start">
              <Text>
                End of hole
              </Text>
              {borehole.casingType && (
                <Text>
                  {`${casingTypes[borehole.casingType]?.name} casing`}
                </Text>
              )}
              {isNumber(borehole.casingDepth) && (
                <Text>
                  {`${borehole.casingDepth}m casing depth`}
                </Text>
              )}
            </VStack>
            <ExpandableText fontStyle="italic">
              {borehole.endNote || ''}
            </ExpandableText>
          </LogRow>
        )] : []),
      ]}
    </CountSectionAccordionItem>
  );
};

export default DrillLogsRow;
