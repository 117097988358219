import { SearchIcon } from '@chakra-ui/icons';
import {
  Input, useCallbackRef, InputProps, InputGroup, InputLeftElement,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import useGoogleMapsApi from '~/hooks/useGoogleMapsApi';
import { useAppSelector } from '~/redux/store';
import { Address } from '~/types/address';
import makeAddressFromPlaceResult from '~/utils/makeAddressFromPlaceResult';

// TODO: smarter. This is just the middle of Auckland
const USER_LOCATION_TO_BIAS = { lat: -36.860336287719136, lng: 174.76271335371015 };

/**
 * Google Maps places autocomplete
 *
 * Shows a search box for finding an address. When the user selects an address,
 * the selected place will get translated into an Address object and emitted.
 *
 * By default this will clear the input once a place has been located.
 *
 * Currently this uses the Google-provided Autocomplete widget, and there is
 * some global styling in the theme to slightly Chakra-ify it
 */
const PlacesAutocomplete = ({
  onAddressChange = () => undefined,
  clearAfterSelect = true,
  // Don't mention 'address' here or Chrome will ''helpfully'' autofill this
  // input which interferes with the places autocomplete
  placeholder = 'Search for a location',
  size,
  autoFocus,
  enableAutocomplete = false,
}: {
  onAddressChange?: (address: Address | undefined) => void;
  placeholder?: string;
  clearAfterSelect?: boolean,
  size?: InputProps['size'];
  autoFocus?: boolean;
  enableAutocomplete?: boolean;
}) => {
  const { isLoaded } = useGoogleMapsApi();
  const inputRef = useRef<HTMLInputElement>(null);
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete>();
  const tenantAddress = useAppSelector((s) => s.currentUser?.tenant?.address);
  // useCallbackRef is a helper from Chakra which helps avoid the 'stale
  // closure' issue that would occur when we register the 'onPlaceChanged'
  // handler with the Autocomplete object. A regular callback here (even if
  // memoized) would only have access to the state/props when the autocomplete
  // was created. On subsequent renders these would be stale. useCallbackRef
  // gets around this issue by putting the latest callback into a mutable ref,
  // then returning a memoized callback that always calls the latest value in
  // that ref (from the latest render pass).
  const onPlaceChanged = useCallbackRef(() => {
    const place = autocomplete?.getPlace();

    onAddressChange?.(place
      ? makeAddressFromPlaceResult(place.address_components ?? [], place.geometry)
      : undefined);

    if (clearAfterSelect && inputRef.current) {
      // We don't want React controlling this input, and the Autocomplete
      // widget doesn't expose an API for doing this, so we'll just directly
      // poke the DOM element here to reset it
      inputRef.current.value = '';
    }
  });

  useEffect(() => {
    if (inputRef.current && isLoaded) {
      const ac = new google.maps.places.Autocomplete(
        inputRef.current,
        {
          // Restrict the autocomplete to finding places with a precise address.
          // We can also limit the results to a particular country. I'm assuming
          // NZ clients right now, but it would be good to drive this off some
          // stored config for the client.
          types: ['address'],

          // This sets bounds to bias queries toward. It doesn't strictly limit
          // to places within this zone. We should be a bit smarter here -- the
          // browser geolocation API, or some stored info for this client could
          // help ensure the place results were relevant.
          bounds: new google.maps.Circle({
            center: tenantAddress?.latitude && tenantAddress?.longitude
              ? { lat: tenantAddress.latitude, lng: tenantAddress.longitude }
              : USER_LOCATION_TO_BIAS,
            radius: 100 * 1000,
          }).getBounds(),
        },
      );
      setAutocomplete(ac);
      ac.setFields(['address_component', 'geometry']);
      const listener = ac.addListener('place_changed', onPlaceChanged);

      // Re: cleanup, the Autocomplete widget doesn't expose a cleanup, which is
      // a shame because it litters the DOM with it's .pac-container leftovers.
      // This has been a long-standing issue with this widget, and fields
      // holding the DOM nodes are minified, it's terrible, but not that
      // important. We should eventually rewrite the component as a pure React
      // one that calls the PlacesService directly and uses Chakra or React
      // Select
      return () => listener.remove();
    }
    return () => {};
  }, [inputRef.current, isLoaded]);

  return (
    <InputGroup size={size}>
      <InputLeftElement zIndex={0}>
        <SearchIcon />
      </InputLeftElement>
      <Input
        type="text"
        autoFocus={autoFocus}
        autoComplete={enableAutocomplete ? 'address-line1' : undefined}
        ref={inputRef}
        size={size}
        placeholder={placeholder}
        paddingLeft="34px"
      />
    </InputGroup>
  );
};

export default PlacesAutocomplete;
