import { omit } from 'lodash';
import { Invoice } from '~/types/invoice';
import fetchJson from '~/utils/fetchJson';

export const getJobInvoice = (jobId: string, invoiceId: string) => fetchJson<Invoice>(
  `/api/jobs/${jobId}/invoices/${invoiceId}`,
);

export const getJobInvoices = (jobId: string) => fetchJson<Invoice[]>(
  `/api/jobs/${jobId}/invoices`,
);

export const patchInvoice = (jobId: string, invoiceId: string, changes: Partial<Invoice>) => (
  fetchJson<Invoice>(
    `/api/jobs/${jobId}/invoices/${invoiceId}?includeUpdated=true`,
    {
      method: 'PATCH',
      body: omit(changes, 'id'),
    },
  )
);

export const createInvoice = (
  jobId: string,
  fromDate?: string,
  toDate?: string,
  proportionOfQuote?:number,
) => (
  fetchJson<Invoice>(`/api/jobs/${jobId}/invoices/`, {
    method: 'POST',
    body: {
      fromDate,
      toDate,
      proportionOfQuote,
    },
  })
);

export const duplicateInvoice = (
  jobId: string,
  invoiceId: string,
) => (
  fetchJson<Invoice>(`/api/jobs/${jobId}/invoices/${invoiceId}/duplicate`, {
    method: 'POST',
  })
);
