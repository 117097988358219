import { Box, Button } from '@chakra-ui/react';
import React from 'react';
import AutoSizingTextarea from '~/components/AutoSizingTextarea';
import EditContainer from '~/components/EditContainer';

export interface AddSchedulingNoteProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  onEditingChange?: (isEditing: boolean) => void;
}

const AddSchedulingNote = ({
  value: valueProp, onChange: onChangeProp, placeholder, onEditingChange,
}: AddSchedulingNoteProps) => (
  <EditContainer
    value={valueProp}
    onChange={onChangeProp}
    startEditingOnClick
    hideControls
    onEditingChange={onEditingChange}
    renderValue={({ value, startEditing }) => {
      if (value) {
        return (
          <Box
            cursor="pointer"
            lineHeight="short"
            px="2.5"
            py="2"
            transition="all 0.1s"
            whiteSpace="pre-wrap"
            border="2px solid transparent"
            _hover={{ backgroundColor: 'gray.50' }}
          >
            {value}
          </Box>
        );
      }
      return (
        <Button
          mt="2"
          color="magnetize.text-3"
          size="small"
          variant="link"
          onClick={startEditing}
        >
          Add scheduling note
        </Button>
      );
    }}
    renderEditor={({ value, onChange, finishEditing }) => (
      <AutoSizingTextarea
        value={value ?? ''}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        onBlur={finishEditing}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            finishEditing();
          }
        }}
        autoFocus
      />
    )}
  />
);

export default AddSchedulingNote;
