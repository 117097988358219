import { formatJobName } from '~/helpers/job';
import { Customer } from '~/types/customer';
import { Job } from '~/types/job';
import formatShortAddress from '~/utils/formatShortAddress';

/**
 * Prepare the text to show on a job calendar card.
 *
 * I've refactored this into a separate function so that it's consistent between
 * the calendar components, and to make it easier to in future offer an option
 * to vary the information that is shown.
 *
 * @param jobSummary
 * @param customer
 * @returns
 */
export const getJobCardText = (jobSummary: Job, customer: Customer) => ({
  mainText: formatJobName(jobSummary),
  secondaryText: [
    formatShortAddress(jobSummary?.siteAddress),
    customer?.companyName,
  ].filter((x) => !!x).join(', '),
});
