import { useDisclosure, Collapse } from '@chakra-ui/react';

import React, { useEffect, useRef } from 'react';

interface SlideInProps {
  animateEntrance?: boolean;
  children: React.ReactNode;
}

const SlideIn = ({ animateEntrance = true, children }: SlideInProps) => {
  // To implement the animate feature, we're using the Chakra
  // <Collapse> transition which has no animation if `in` is set on the first
  // render. Using an effect here to open it will trigger the animation if
  // desired.
  const { isOpen, onOpen } = useDisclosure();
  const hasStarted = useRef(false);
  useEffect(() => {
    if (animateEntrance && !hasStarted.current) {
      hasStarted.current = true;
      const timer = setTimeout(() => onOpen(), 50);
      return () => clearTimeout(timer);
    }
    return undefined;
  }, [animateEntrance]);

  // The Collapse sets overflow: hidden which doesn't _seem_ necessary, but
  // clips outlines/shadows that extend beyond the box. Rather than introduce
  // padding that could mess with alignment, we can just force the overflow
  // in this case -- it doesn't seem to cause issues
  return (
    <Collapse
      in={!animateEntrance || isOpen}
      style={{ overflow: 'inherit' }}
    >
      {children}
    </Collapse>
  );
};

export default SlideIn;
