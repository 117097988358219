import { createAction } from '@reduxjs/toolkit';
import { Address } from '~/types/address';
import createTrackedAction from '~/redux/tracked/createTrackedAction';
import {
  PowerUpId,
  SubscriptionInvoice,
  SubscriptionPaymentMethod,
  SubscriptionPrices,
} from '~/types/subscription';

const SubscriptionActions = {
  fetchPrices: createAction('subscription/fetchPrices'),
  fetchPaymentMethod: createAction('subscription/fetchPaymentMethod'),
  fetchInvoices: createAction('subscription/fetchInvoices'),

  setDefaultPaymentMethod: createTrackedAction<{ stripe: any, cardElement: any, billingAddress: Address, name: string }>('subscription/setDefaultPaymentMethod'),
  activatePlan: createTrackedAction('subscription/activatePlan'),
  cancelPlan: createTrackedAction('subscription/cancelPlan'),
  pricesUpdated: createAction<{ prices: SubscriptionPrices }>('subscription/pricesUpdated'),
  invoicesUpdated: createAction<{ invoices: SubscriptionInvoice[] }>('subscription/invoicesUpdated'),
  paymentMethodUpdated: createAction<{ paymentMethod: SubscriptionPaymentMethod }>('subscription/paymentMethodUpdated'),

  addPowerUp: createTrackedAction<{ powerUpId: PowerUpId }>('subscription/addPowerUp'),
  removePowerUp: createTrackedAction<{ powerUpId: PowerUpId }>('subscription/removePowerUp'),

  powerUpAdded: createTrackedAction<{ powerUpId: PowerUpId }>('subscription/powerUpAdded'),
  powerUpRemoved: createTrackedAction<{ powerUpId: PowerUpId }>('subscription/powerUpRemoved'),
};

export default SubscriptionActions;
