import { HStack, Text, VStack } from '@chakra-ui/react';
import {
  groupBy, isNumber, map, orderBy, uniq, values,
} from 'lodash';
import React, { useMemo } from 'react';
import { DepthLayer } from './DepthLayer';
import { ClearanceTechnique } from '~/types/clearanceTechnique';
import { DrillingTechnique } from '~/types/drillingTechnique';
import { Equipment } from '~/types/equipment';
import {
  Borehole, DrillLog,
} from '~/types/geotechnical';
import { SoilType } from '~/types/soilType';

export const DrillVisual = ({
  borehole, equipment, drillingTechniques, clearanceTechniques, soilTypes,
} : {
  borehole: Borehole;
  equipment: Partial<Equipment>[];
  drillingTechniques: { [key: string]: DrillingTechnique };
  clearanceTechniques: { [key: string]: ClearanceTechnique };
  soilTypes: { [key: string]: SoilType };
}) => {
  const logsByDepth = useMemo(() => {
    if (!borehole || !borehole.undergroundServiceClearanceTechnique) {
      return [];
    }

    const logs = [
      {
        type: 'clearance',
        depth: 0,
        id: 'clearance',
        undergroundServiceClearanceTechnique: borehole.undergroundServiceClearanceTechnique,
      },
      ...map(borehole.drillLogs, (log, id) => ({
        type: 'drill', id, ...log,
      })),
      ...map(borehole.sptTests, (log, id) => ({
        type: 'spt', id, ...log,
      })),
      ...map(borehole.pushTubes, (log, id) => ({
        type: 'push-tube', id, ...log,
      })),
      ...map(borehole.soilLayers, (log, id) => ({
        type: 'soil', id, depth: log.startDepth, ...log,
      })),
      ...(borehole.waterLevel ? [{
        type: 'water',
        id: 'water',
        depth: borehole.waterLevel,
      }] : []),
      ...(borehole.endDepth ? [{ type: 'end', id: 'end', depth: borehole.endDepth }] : []),
    ];
    const byDepth = groupBy(logs, (l) => l.depth);
    const depths = orderBy(uniq(map(logs, (l) => l.depth)));

    return map(depths, (depth) => ({
      depth,
      logs: byDepth[depth],
    }));
  }, [borehole]);

  const soilLayers = useMemo(() => orderBy(
    values(borehole?.soilLayers || {}),
    (l) => l.startDepth,
  ).reverse(), [borehole]);

  if (!borehole) {
    return <></>;
  }

  return (
    <VStack spacing={2} align="stretch">
      {logsByDepth.map((dl, ix) => {
        const previousLogsInReverse = logsByDepth.slice(0, ix).reverse();
        const currentSoilLayer = soilLayers.find((l) => l.startDepth <= dl.depth);
        const hasWater = borehole.waterLevel && borehole.waterLevel <= dl.depth;

        return (
          <DepthLayer
            key={`depth-log-${dl.depth}`}
            depth={dl.depth}
            soilImageId={
              currentSoilLayer && soilTypes[currentSoilLayer.soilTypes[0]]
                ? soilTypes[currentSoilLayer.soilTypes[0]].imageId
                : null
}
            isEndOfHole={borehole.endDepth && dl.depth >= borehole.endDepth}
            hasWater={hasWater}
          >
            {dl.logs.map((log : any) => {
              if (log.type === 'clearance') {
                const clearanceType = clearanceTechniques[log.undergroundServiceClearanceTechnique];
                return (
                  <Text key={log.id}>
                    {`Clearance ${log.undergroundServiceClearanceTechnique === 'not-required' ? '' : ' by'} ${clearanceType?.name}`}
                  </Text>
                );
              }

              if (log.type === 'drill') {
                const previousDrillLog = previousLogsInReverse.flatMap((l) => l.logs).find((l) => l.type === 'drill') as unknown as DrillLog;
                const technique = drillingTechniques[log.drillingTechnique];
                const logEquipment = equipment.find((e) => e.id === log.equipmentId);

                return (
                  <VStack key={log.id} align="stretch" alignItems="start">
                    {previousDrillLog?.equipmentId !== log.equipmentId && (
                    <Text>
                      {logEquipment?.name}
                    </Text>
                    )}
                    <Text>
                      {technique?.name}
                    </Text>
                  </VStack>
                );
              }

              if (log.type === 'spt') {
                return (
                  <HStack key={log.id} align="stretch" justifyContent="space-between">
                    <Text>
                      {[
                        log.blows1,
                        log.blows2,
                        log.blows3,
                        log.blows4,
                        log.blows5,
                        log.blows6,
                      ].filter((x) => isNumber(x)).join('/')}
                    </Text>
                    {isNumber(log.n) && (
                    <Text>
                      {`N=${log.n}`}
                    </Text>
                    )}
                  </HStack>
                );
              }

              if (log.type === 'soil') {
                const prevLayer = orderBy(Object.values(borehole.soilLayers), (x) => x.startDepth)
                  .reverse()
                  .find((l) => l.startDepth < log.startDepth);

                return (
                  <Text key={log.id} color="#A67619">
                    {`${prevLayer ? `${soilTypes[prevLayer.soilTypes[0]]?.name} → ` : ''}${soilTypes[log.soilTypes[0]]?.name}`}
                  </Text>
                );
              }

              if (log.type === 'water') {
                return (
                  <Text key={log.id} color="#6CAAC4">
                    Ground Water Level
                  </Text>
                );
              }

              if (log.type === 'end') {
                return (
                  <Text key={log.id}>
                    Hole ended
                  </Text>
                );
              }

              if (log.type === 'push-tube') {
                return (
                  <Text key={log.id}>
                    Push tube
                  </Text>
                );
              }

              return undefined;
            })}
          </DepthLayer>
        );
      })}
    </VStack>
  );
};
