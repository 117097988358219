import {
  Icon, SkeletonText, Spinner, Text,
} from '@chakra-ui/react';
import { InfoOutlined } from '@material-ui/icons';
import { formatRelative } from 'date-fns';
import React, { useState } from 'react';
import { MutationStatus, useMutation, useQuery } from 'react-query';
import LinkButton from '~/components/LinkButton';
import { selectStaffById } from '~/redux/staff/selectors';
import { useAppSelector } from '~/redux/store';
import { showToast } from '~/toast';
import { Staff, StaffCognitoGroup } from '~/types/staff';
import fetchJson from '~/utils/fetchJson';

const StaffInviteInfo = ({ staff } : { staff: Staff }) => {
  const { data: signupStatus, isLoading } = useQuery(
    ['staff-signup-status', staff.cognitoUsername],
    () => fetchJson<any>(
      `/api/staff/by-cognito-username/${staff.cognitoUsername}/signup-status`,
    ),
  );

  const [hasSent, setHasSent] = useState(false);

  const { status, mutate: resend }
  : { status: MutationStatus, mutate: any, error: Error } = useMutation(
    () => fetchJson<any>(`/api/staff/by-cognito-username/${staff.cognitoUsername}/resend-invite`,
      {
        method: 'POST',
      }), {
      onSuccess: () => {
        setHasSent(true);
      },
      onError: () => {
        showToast({
          description: 'Error resending invite',
          status: 'error',
        });
      },
    },
  );

  if (isLoading || !signupStatus) {
    return <SkeletonText mb={3} noOfLines={1} />;
  }

  if (signupStatus.cognitoStatus === 'CONFIRMED') {
    return (
      <Text color="magnetize.text-3" mb={3}>
        <Icon as={InfoOutlined} fontSize="14px" mb={1} mr={2} />
        Signed up and ready to go!
      </Text>
    );
  }

  let sendText = `We tried to send an invite to ${staff.name}, but something went wrong. `;

  if (signupStatus.cognitoStatus === 'FORCE_CHANGE_PASSWORD') {
    switch (signupStatus.emailStatus) {
      case 'Queued':
      case 'Processing':
        sendText = `We are sending the invite to ${staff.name} but its taking a while. `;
        break;
      case 'Delivery':
      case 'Sent':
        sendText = `An email with a link to get started has been sent to ${staff.name}. `;
        break;
      default:
        sendText = `We tried to send an invite email to ${staff.name}, but it couldn't be delivered. `;
    }
  }

  return (
    <Text color="magnetize.text-3" mb={3}>
      <Icon as={InfoOutlined} fontSize="14px" mb={1} mr={2} />
      {sendText}
      {hasSent ? <strong>Invite resent!</strong> : (
        <LinkButton isGreen onClick={resend} isDisabled={status === 'loading'}>
          {' '}
          {status === 'loading' ? (
            <>
              <Spinner />
              {' '}
              sending...
            </>
          ) : 'Resend invite'}
        </LinkButton>
      )}
    </Text>
  );
};

const StaffLoginInfo = (
  { staffId, emailModified, cognitoGroup }
  : { staffId: string, emailModified?: boolean, cognitoGroup?: StaffCognitoGroup },
) => {
  const staff = useAppSelector((s) => selectStaffById(s, staffId));
  if (!cognitoGroup) {
    return (
      <Text color="magnetize.text-3" mb={3}>
        <Icon as={InfoOutlined} fontSize="14px" mb={1} mr={2} />
        No access to Magnetize is granted to this user
      </Text>
    );
  }

  if (staff && emailModified) {
    return (
      <Text color="magnetize.text-3" mb={3}>
        <Icon as={InfoOutlined} fontSize="14px" mb={1} mr={2} />
        We&apos;ll send them an email link to login with this new email address.
      </Text>
    );
  }

  if (!staff || !staff.cognitoUsername) {
    return (
      <Text color="magnetize.text-3" mb={3}>
        <Icon as={InfoOutlined} fontSize="14px" mb={1} mr={2} />
        We&apos;ll send them an email link to get started.
      </Text>
    );
  }

  if (staff?.lastAccessTimestamp) {
    return (
      <Text color="magnetize.text-3" mb={3}>
        <Icon as={InfoOutlined} fontSize="14px" mb={1} mr={2} />
        {`Last active ${formatRelative(staff.lastAccessTimestamp, new Date())}`}
      </Text>
    );
  }

  return <StaffInviteInfo staff={staff} />;
};

export default StaffLoginInfo;
