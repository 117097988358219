import { useCallbackRef } from '@chakra-ui/react';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  useCallback,
  useEffect, useMemo, useState,
} from 'react';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import { selectTrackedById } from '~/redux/tracked/selectors';
import { TrackedAction } from '~/types/trackedAction';

interface UseTrackedFetchProps {
  trigger: (...args) => PayloadAction<TrackedAction<any>>;
  onSuccess?: (result: any) => void;
}

const useTrackedAction = ({
  trigger, onSuccess,
}: UseTrackedFetchProps) => {
  const dispatch = useAppDispatch();
  const [trackId, setTrackId] = useState<string>();
  const [hasFiredOnSuccessCallback, setHasFiredOnSuccessCallback] = useState(false);
  const state = useAppSelector((s) => selectTrackedById(s, trackId)?.state);
  const result = useAppSelector((s) => selectTrackedById(s, trackId)?.result);
  const onSuccessCb = useCallbackRef(() => onSuccess?.(result));

  const track = useCallback((id: string) => {
    setTrackId(id);
  }, [setTrackId]);

  const triggerCb = useCallbackRef((...args) => {
    const action = trigger(...args);
    dispatch(action);
    setHasFiredOnSuccessCallback(false);
    setTrackId(action.payload.id);
  });

  const returnValue = useMemo(() => ({
    trigger: triggerCb,
    trackingId: trackId,
    isTracking: !!trackId,
    isLoading: state === 'saving',
    isDone: state === 'saved',
    state: trackId ? state : undefined,
    reset: () => track(undefined),
  }), [trackId, state]);

  useEffect(() => {
    if (returnValue.isDone && !hasFiredOnSuccessCallback) {
      onSuccessCb();
      setHasFiredOnSuccessCallback(true);
    }
  }, [returnValue.isDone, hasFiredOnSuccessCallback, onSuccessCb]);

  return returnValue;
};

export default useTrackedAction;
