import {
  Button,
  Menu, MenuButton, MenuItem, MenuList,
} from '@chakra-ui/react';
import React from 'react';
import { DownloadIcon } from '@chakra-ui/icons';
import { JobActions } from '~/redux/jobs/actions';
import useTrackedAction from '~/hooks/useTrackedAction';

const ExportJobsMenu = () => {
  const { trigger, isLoading } = useTrackedAction({
    trigger: (format: 'csv' | 'xlsx') => JobActions.exportJobs({ format }),
  });

  return (
    <Menu placement="bottom-end">
      <MenuButton
        leftIcon={<DownloadIcon />}
        as={Button}
        variant="ghost"
      >
        {isLoading ? 'Exporting...' : 'Export Jobs'}

      </MenuButton>
      <MenuList>
        <MenuItem onClick={() => trigger('xlsx')}>As Excel sheet</MenuItem>
        <MenuItem onClick={() => trigger('csv')}>As CSV</MenuItem>
      </MenuList>
    </Menu>
  );
};

export default ExportJobsMenu;
