import {
  Box, Button, Flex, Icon,
} from '@chakra-ui/react';
import { Schedule } from '@material-ui/icons';
import {
  addMinutes, format, startOfDay,
} from 'date-fns';
import React, { useState } from 'react';
import TimePicker from '~/components/TimePicker';

export const AddStartTime = ({ startTimeMinutes, onChange }
:{ startTimeMinutes: number, onChange: (startTimeMinutes:number)=>void }) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <Flex>
      <Icon fontSize="18px" boxSize="18px" as={Schedule} mr="6px" />
      <Box mt={-2} flex="1">
        {isEditing ? (
          <TimePicker
            time={startTimeMinutes
              ? { hours: Math.floor(startTimeMinutes / 60), minutes: startTimeMinutes % 60 }
              : null}
            placeholder="0:00 am"
            autoFocus
            onChange={(time) => {
              onChange(time.hours * 60 + time.minutes);
              setIsEditing(false);
            }}
            onBlur={() => {
              setIsEditing(false);
            }}
          />
        )
          : (
            <Box onClick={() => setIsEditing(true)}>
              {startTimeMinutes ? (
                <Box
                  cursor="pointer"
                  lineHeight="short"
                  px="2.5"
                  py="2"
                  transition="all 0.1s"
                  whiteSpace="pre-wrap"
                  border="2px solid transparent"
                  _hover={{ backgroundColor: 'gray.50' }}
                >
                  {format(addMinutes(startOfDay(new Date()), startTimeMinutes), 'p')}
                </Box>
              ) : (
                <Button
                  mt="2"
                  color="magnetize.text-3"
                  size="small"
                  variant="link"
                >
                  Add start time
                </Button>
              )}
            </Box>
          )}
      </Box>
    </Flex>
  );
};
