import { createAction } from '@reduxjs/toolkit';
import {
  ApiIncrementConsumable, ApiSetConsumable, ApiSetConsumableForDate, SummaryConsumableRecord,
} from '~/types/consumable';

const ConsumablesActions = {
  fetchForJob: createAction<{ jobId: string }>('consumables/fetchForJob'),
  consumablesUpdated: createAction<{ jobId: string, consumables: SummaryConsumableRecord[] }>('consumables/updated'),

  addToJob: createAction<{
    jobId: string,
    consumables: ApiIncrementConsumable[]
  }>('consumables/addToJob'),

  set: createAction<{
    jobId: string,
    set: ApiSetConsumable,
  }>('consumables/set'),

  increment: createAction<{
    jobId: string,
    increment: ApiIncrementConsumable
  }>('consumables/increment'),

  setForDate: createAction<{
    jobId: string,
    id:string,
    setForDate: ApiSetConsumableForDate
  }>('consumables/set-for-date'),

  deleteForDate: createAction<{
    jobId: string,
    id:string,
    date: string,
    staffId: string,
  }>('consumables/delete-for-date'),

  delete: createAction<{ jobId: string, consumableId: string }>('consumables/delete'),
};

export default ConsumablesActions;
