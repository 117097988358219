import { ActionCreator, PayloadAction } from '@reduxjs/toolkit';
import { Saga, SagaIterator } from 'redux-saga';
import {
  call, put, takeEvery,
} from 'redux-saga/effects';
import { TrackedActions } from '~/redux/tracked/actions';
import { TrackedAction } from '~/types/trackedAction';

interface PerformTrackedSagaOptions {
  key: string;
  saga: Saga,
}

export function* performTrackedSaga(
  { key, saga }: PerformTrackedSagaOptions,
): SagaIterator {
  try {
    yield put(TrackedActions.started({ trackId: key }));
    yield call(saga);
    yield put(TrackedActions.completed({ trackId: key }));
  } catch (e) {
    yield put(TrackedActions.errored({ trackId: key }));
  }
}

/**
 * A helper to combine takeEvery + performTrackedSaga
 * @param pattern
 * @param worker
 * @returns
 */
export function takeEveryTracked<T extends PayloadAction<TrackedAction<unknown>>>(
  pattern: ActionCreator<T>,
  worker: (action: T) => any,
) {
  return takeEvery(
    pattern,
    function* handle(action: T) {
      yield performTrackedSaga({
        key: action.payload.id,
        * saga() {
          yield call(worker, action);
        },
      });
    },
  );
}
