import { Input } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import TileSelector from '~/pages/WelcomeModal/components/TileSelector';

interface ProblemsSelectorProps {
  value: string[];
  otherValue: string;
  onChange: (categories: string[], otherValue: string) => void;
}

const ProblemsSelector = ({ value, otherValue, onChange } : ProblemsSelectorProps) => {
  const options = useMemo(() => ([
    {
      label: 'Scheduling',
    },
    {
      label: 'Visibility of Equipment',

    }, {
      label: 'Less paperwork',
    },
    {
      label: 'Customer management',

    },
    {
      label: 'Quotes & Invoices',
    },
    {
      label: 'Other',
    },
  ].map((o) => ({ ...o, isSelected: value.includes(o.label) }))), [value]);

  return (
    <>
      <TileSelector
        options={options}
        onClick={(option) => {
          if (option.isSelected) {
            onChange(value.filter((c) => c !== option.label), otherValue);
          } else {
            onChange([...value, option.label], otherValue);
          }
        }}
      />
      {value.includes('Other') && (
      <Input
        mt={4}
        value={otherValue}
        onChange={(e) => {
          onChange(value, e.target.value);
        }}
        placeholder="&#34;Other&#34; what are you looking for?"
      />
      )}
    </>
  );
};
export default ProblemsSelector;
