import {
  Button,
  Modal, ModalBody, ModalCloseButton,
  ModalContent, ModalFooter, ModalHeader, ModalOverlay,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useAppDispatch } from '~/redux/store';
import { updateSurfaceType } from '~/redux/surfaceTypes/actions';
import { SurfaceType } from '~/types/surfaceType';
import { SurfaceTypeForm } from './SurfaceTypeForm';

export const SurfaceTypeEditModal = ({
  surfaceType,
  title = 'Edit surface finish type',
  onClose = () => {},
}: {
  surfaceType: Partial<SurfaceType>,
  title: string,
  onClose: () => void
}) => {
  const dispatch = useAppDispatch();
  const [editedSurfaceType, setEditedSurfaceType] = useState(surfaceType);

  const canSave = editedSurfaceType.name;
  const onSaveClicked = () => {
    dispatch(updateSurfaceType(editedSurfaceType as SurfaceType));
    onClose();
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen
      onClose={onClose}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {title}
        </ModalHeader>
        <ModalCloseButton tabIndex={-1} />
        <ModalBody>
          <SurfaceTypeForm
            value={editedSurfaceType}
            onChange={(dt) => {
              setEditedSurfaceType({
                ...editedSurfaceType,
                ...dt,
              });
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={!canSave}
            colorScheme="brandDark"
            onClick={onSaveClicked}
            size="lg"
          >
            Save
          </Button>
          <Button tabIndex={-1} variant="ghost" onClick={onClose}>cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
