import {
  Box, Flex, Heading, Stack,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import BackButton from '~/components/BackButton';
import { HelpLinks } from '~/components/SupportLinks';

const LoginImage = () => (
  <Box pt={6}>
    <img
      width="210px"
      height="37px"
      alt="Magnetize Login Logo"
      src="/images/login-logo.png"
    />
  </Box>
);

interface LoginCardProps {
  title?: string;
  showBackButton?: boolean;
  children: ReactNode;
}

const LoginCard = ({ title, showBackButton, children }: LoginCardProps) => (
  <Flex
    boxShadow="md"
    borderRadius="md"
    bgColor="white"
    width={{ base: '100vw', md: '425px' }}
    height={{ base: '100vh', md: 'fit-content' }}
  >
    <Flex
      flex={1}
      direction="column"
      margin={12}
    >
      <LoginImage />
      <Stack spacing={4} mt={6}>
        <Box h="24px">
          {showBackButton && <BackButton pl={0}>Back</BackButton>}
        </Box>
        {title && (
        <Heading maxW="300px" size="lg" textTransform="none">
          {title}
        </Heading>
        )}
        <form>
          {children}
        </form>
      </Stack>
      <HelpLinks />
    </Flex>
  </Flex>
);

export default LoginCard;
