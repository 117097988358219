import React, { useState } from 'react';
import {
  Button, Box, Text, Heading, Icon,
} from '@chakra-ui/react';
import CheckIcon from '@material-ui/icons/Check';
import PriorityHigh from '@material-ui/icons/PriorityHigh';
import { format, differenceInDays } from 'date-fns';
import { Tenant, SubscriptionStatusInfo } from '~/types/tenant';
import { SubscriptionPrices } from '~/types/subscription';
import LinkButton from '~/components/LinkButton';
import PlanModal from '~/components/PlanModal';
import { calculateSubscriptionTotalPrice } from '~/helpers/subscription';
import CurrencyText from '~/components/CurrencyText';

const SelectedPlan = (
  {
    tenant, openSelectPlanModal, reactivatePlan, prices,
  }
  : {
    tenant: Tenant,
    openSelectPlanModal: () => void,
    reactivatePlan: () => void,
    prices: SubscriptionPrices },
) => {
  const nextPayment = tenant.planExpiry ? new Date(tenant.planExpiry) : new Date();
  const isPastDue = nextPayment < new Date();
  const isCancelled = tenant.status === 'subscriptionCancelled';
  const subscriptionStatus = SubscriptionStatusInfo[tenant.status];
  const [selectPlanVisible, setSelectPlanVisible] = useState(false);

  if (subscriptionStatus.isTrial) {
    return (
      <Box
        display="flex"
        padding={6}
        borderLeftWidth={7}
        borderColor="brand.300"
        flexDirection="row"
        alignItems="center"
        backgroundColor="#359B4B44"
      >
        <Box flex={1} marginRight={4}>
          <Heading size="md" textTransform="none" marginBottom={4}>Magnetize - 30 DAY FREE TRIAL</Heading>
          <Text>
            {`Heads up! Your free trial ${tenant.planExpiry > new Date().getTime() ? `is ending in ${differenceInDays(nextPayment, new Date())} days on ${format(nextPayment, 'MMM do')}.` : 'has ended'}`}
          </Text>
          <Text>
            Set your plan now and you&apos;ll be good to go
          </Text>
        </Box>
        <Button flexShrink={1} size="lg" onClick={() => openSelectPlanModal()}>Set your plan</Button>
      </Box>
    );
  }

  const currentTotal = calculateSubscriptionTotalPrice(prices);
  return (
    <Box
      display="flex"
      padding={6}
      flexDirection="row"
      backgroundColor="gray.50"
    >
      <Box
        marginRight={4}
        height="20px"
        width="20px"
        borderRadius="10px"
        backgroundColor="magnetize.brand-4"
        alignItems="center"
        justifyContent="center"
        display="flex"
      >
        <Icon fontSize="14px" as={isCancelled ? PriorityHigh : CheckIcon} color="white" />
      </Box>
      <Box flex={1} flexDirection="row" display="flex">
        <Box display="flex" flexDirection="column">
          <Heading size="md" textTransform="none" marginBottom={4} lineHeight="20px">
            {`Magnetize Monthly Subscription ${tenant.powerUps.geotechnical || tenant.powerUps.xero ? ' + Power ups' : ''}`}
          </Heading>
          <Box display="flex" flexDirection="row">
            <Box>
              <Box display="flex" flexDirection="row" padding={1}>
                <Text fontWeight="700" paddingRight={2}>{prices.admin.currentCount}</Text>
                <Text>{`full user${prices.admin.currentCount > 1 ? 's' : ''}`}</Text>
              </Box>
              {prices.staff.currentCount > 0
                && (
                <Box display="flex" flexDirection="row" padding={1}>
                  <Text fontWeight="700" paddingRight={2}>{prices.staff.currentCount}</Text>
                  <Text>staff</Text>
                </Box>
                )}
            </Box>
            <Box width="0.5px" backgroundColor="gray.200" marginLeft={4} marginRight={4} />
            <Box>
              <Box display="flex" flexDirection="row" padding={1}>
                <CurrencyText fontWeight="700" marginRight={1} value={currentTotal} currency="NZD" />
                <Text fontWeight="600">+ GST per month</Text>
              </Box>
              <Text padding={1} />
              {isCancelled
                ? `Plan ${isPastDue ? 'ended' : 'will end'} ${format(nextPayment, 'MMMM d, yyyy')}`
                : `Next payment due ${format(nextPayment, 'MMMM d, yyyy')}`}
            </Box>
          </Box>
        </Box>
        <Box flexGrow={1} />
        <Box>
          <Box display="flex" flexDirection="column" alignItems="flex-end">
            {isCancelled
              ? <Button flexShrink={1} size="lg" onClick={() => reactivatePlan()}>Reactivate Plan</Button>
              : (
                <LinkButton
                  pl={1}
                  isGreen
                  noUnderline
                  onClick={() => { setSelectPlanVisible(true); }}
                >
                  details
                </LinkButton>
              )}
          </Box>
        </Box>
      </Box>
      {selectPlanVisible
      && (
      <PlanModal
        isPlanActive={tenant.status === 'subscribed' || tenant.status === 'subscriptionGrace' || tenant.status === 'subscriptionPending'}
        prices={prices}
        onCancel={() => { setSelectPlanVisible(false); }}
        actionButton={(
          <Button
            size="lg"
            autoFocus
            onClick={() => {
              setSelectPlanVisible(false);
            }}
          >
            Done
          </Button>
        )}
      />
      )}
    </Box>
  );
};

export default SelectedPlan;
