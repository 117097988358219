/**
 * Linearly interpolates between two scales of values.
 *
 * @param from The input range range, e.g. [0, 100]
 * @param to The output range e.g. [0, 1]
 * @param s A value in the input range (e.g. 57)
 * @returns A corresponding value in the output range. (e.g. 0.57)
 */
const mapRange = (
  s: number,
  from: [number, number],
  to: [number, number],
) => (
  to[0] + ((s - from[0]) * (to[1] - to[0])) / (from[1] - from[0])
);

export default mapRange;
