import {
  Button,
  GridItem,
  Heading,
  HStack,
  Icon,
  Link,
  List,
  ListItem,
  Stack,
} from '@chakra-ui/react';
import AwsS3 from '@uppy/aws-s3';
import Uppy from '@uppy/core';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import { Dashboard, StatusBar } from '@uppy/react';
import '@uppy/status-bar/dist/style.css';
import axios from 'axios';
import React from 'react';
import { FaUserSecret } from 'react-icons/fa';
import { QueryOptions } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import Card from '~/components/Card';
import CardTitle from '~/components/CardTitle';
import SwatchPreview from '~/components/SwatchPreview';
import { getCurrentUser } from '~/helpers/auth';
import PrimarySecondaryColumns from '~/layouts/PrimarySecondaryColumns';
import { showToast } from '~/toast';
import { exportToSpreadsheet } from '~/utils/exportToSpreadsheet';
import fetchJson from '~/utils/fetchJson';

interface TenantUsers {
  tenantId: string;
  users: string[];
}

export const userListQuery = (): QueryOptions<TenantUsers[]> => ({
  queryFn: () => fetchJson<TenantUsers[]>('/api/users'),
  queryKey: ['user-list'],
});

const uppy = Uppy({
  meta: { type: 'avatar' },
  restrictions: { maxNumberOfFiles: 0 },
  autoProceed: true,
});
uppy.use(AwsS3, {
  limit: 0,
  getUploadParameters: async (file) => {
    const user = await getCurrentUser();
    const { data } = await axios.post('/api/files/make-file-post-url', {
      fileExtension: file.extension,
    }, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    return { method: 'POST', ...data.signedUrl };
  },
});

const triggerTestError = async () => {
  try {
    await fetchJson('/api/error-test');
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    showToast({
      status: 'error',
      title: 'Test error',
      description: String(error),
    });
  }
};

const DevPage = () => (
  <PrimarySecondaryColumns>
    <GridItem gridArea="header">
      <Heading>Dev</Heading>
    </GridItem>

    <GridItem gridArea="secondary">
      <Card>
        <CardTitle>
          <Icon boxSize="6" as={FaUserSecret} mr={2} />
          Secret places for dev eyes only
        </CardTitle>
        <List>
          <ListItem>
            <Link as={RouterLink} to="/calendar-jobs">Main calendar</Link>
          </ListItem>
        </List>
        <Button onClick={triggerTestError}>Error test - Trigger error</Button>
      </Card>
    </GridItem>

    <GridItem gridArea="primary">
      <Stack spacing="8">
        <Card>
          <Button onClick={async () => exportToSpreadsheet({
            name: 'test',
            format: 'xlsx',
            sheets: [{
              headers: ['Hello', 'World', 'Date'],
              data: [
                ['Foo', 14, { v: new Date(), t: 'd' }],
                ['Bar', 344.98, new Date()],
                ['Blah'],
              ],
              name: 'BLOO BLOOO BLAH BLAHH',
            },
            {
              headers: ['WOW', 'NO', 'NO'],
              data: [
                ['HEE', 14, { v: new Date(), t: 'd' }],
                ['LOL', 344.98, new Date()],
                ['EWE'],
              ],
              // name: 'BLOO BLOOO BLAH BLAHH',
            }],
          })}
          >
            Download Excel sheet
          </Button>
        </Card>
        <Card>
          <CardTitle>Colour Swatches</CardTitle>
          <HStack spacing={10}>
            <SwatchPreview colorScheme="gray" />
            <SwatchPreview colorScheme="brand" />
            <SwatchPreview colorScheme="green" />
            <SwatchPreview colorScheme="brandGreen" />
          </HStack>
        </Card>

        <Card>
          <CardTitle>Files</CardTitle>
          <Dashboard
            uppy={uppy}
            thumbnailWidth={50}
            hideUploadButton
            showLinkToFileUploadResult
          />
          <StatusBar uppy={uppy} />
        </Card>
        <Card>
          <Button
            onClick={() => {
              throw new Error('whoops!');
            }}
          >
            Throw error (for sentry testing)
          </Button>
        </Card>

      </Stack>
    </GridItem>
  </PrimarySecondaryColumns>
);

export default DevPage;
