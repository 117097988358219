import React from 'react';
import {
  Box, Heading, Text,
} from '@chakra-ui/react';

import TimelineItem, { TimelineItemProps } from '~/components/TimelineItem';
import { JobTimelineJobCreated } from '~/types/job';
import StatusBadge from '~/components/JobStatusBadge';

const TimelineJobCreated = ({
  event, ...rest
}: {
  event: JobTimelineJobCreated;
} & TimelineItemProps) => (
  <TimelineItem
    {...rest}
    event={event}
  >
    {event.status && <Box mb={2}><StatusBadge status={event.status} /></Box> }
    <Heading textTransform="none" size="sm" mb={1}>{`JOB CREATED: #JOB-${event.code} - ${event.name}`}</Heading>
    {!!event.statusReason && (
      <Text>
        {event.statusReason}
      </Text>
    )}

  </TimelineItem>
);

export default TimelineJobCreated;
