import { createReducer } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';
import { QuoteActions } from '~/redux/quote/actions';
import { Dictionary } from '~/types/helpers';
import { Quote } from '~/types/quote';

export type QuoteState = {
  quotesById: Dictionary<Quote>,
  quoteIdsByJobId: Dictionary<string[]>
};

export const quotesReducer = createReducer<QuoteState>(
  {
    quotesById: {},
    quoteIdsByJobId: {},
  },

  (builder) => {
    builder.addCase(QuoteActions.quotesUpdated, (state, action) => ({
      ...state,
      quotesById: {
        ...state.quotesById,
        ...keyBy(action.payload.quotes, (q) => q.id),
      },
    }));

    builder.addCase(QuoteActions.jobQuotesUpdated, (state, action) => ({
      ...state,
      quoteIdsByJobId: {
        ...state.quoteIdsByJobId,
        [action.payload.jobId]: action.payload.quoteIds ?? [],
      },
    }));

    builder.addCase(QuoteActions.quoteRemoved, (state, action) => ({
      ...state,
      quoteIdsByJobId: {
        ...state.quoteIdsByJobId,
        [action.payload.jobId]: (state.quoteIdsByJobId[action.payload.jobId] ?? [])
          .filter((id) => id !== action.payload.quoteId),
      },
    }));
  },
);
