import { createAction } from '@reduxjs/toolkit';
import createTrackedAction from '~/redux/tracked/createTrackedAction';
import { CustomerFacingQuote } from '~/types/quote';

export const triggerCustomerQuoteFetch = createAction<{ token: string }>('quotes/customer/triggerFetch');
export const customerQuoteFetchStart = createAction<{ token: string }>('quotes/customer/fetchStart');
export const customerQuoteFetchSuccess = createAction<{ token: string, data: CustomerFacingQuote }>('quotes/customer/fetchSuccess');
export const customerQuoteFetchError = createAction<{ token: string, errorMessage: string }>('quotes/customer/fetchError');

export const acceptQuote = createTrackedAction<{
  token: string,
  comment: string
}>('quotes/customer/accept');

export const queryQuote = createTrackedAction<{
  token: string,
  comment: string
}>('quotes/customer/query');
