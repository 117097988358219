import {
  Button,
  Modal, ModalBody, ModalCloseButton,
  ModalContent, ModalFooter, ModalHeader, ModalOverlay,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useAppDispatch } from '~/redux/store';
import { updateCasingType } from '~/redux/casingTypes/actions';
import { CasingType } from '~/types/casingType';
import { CasingTypeForm } from './CasingTypeForm';

export const CasingTypeEditModal = ({
  casingType,
  title = 'Edit casing type',
  onClose = () => {},
}: {
  casingType: Partial<CasingType>,
  title: string,
  onClose: () => void
}) => {
  const dispatch = useAppDispatch();
  const [editedCasingType, setEditedCasingType] = useState(casingType);

  const canSave = editedCasingType.name;
  const onSaveClicked = () => {
    dispatch(updateCasingType(editedCasingType as CasingType));
    onClose();
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen
      onClose={onClose}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {title}
        </ModalHeader>
        <ModalCloseButton tabIndex={-1} />
        <ModalBody>
          <CasingTypeForm
            value={editedCasingType}
            onChange={(dt) => {
              setEditedCasingType({
                ...editedCasingType,
                ...dt,
              });
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={!canSave}
            colorScheme="brandDark"
            onClick={onSaveClicked}
            size="lg"
          >
            Save
          </Button>
          <Button tabIndex={-1} variant="ghost" onClick={onClose}>cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
