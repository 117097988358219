import { useEffect } from 'react';
import { useHistory } from 'react-router';
import Routes from '~/pages/routes';
import { useAppDispatch } from '~/redux/store';
import { XeroActions } from '~/redux/xero/actions';

const XeroAuthCallbackPage = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(XeroActions.connect({ callbackUrl: window.location.href }));
    history.replace(Routes.powerUpsSettings({}));
  }, [dispatch, history]);

  return null;
};

export default XeroAuthCallbackPage;
