import React from 'react';
import {
  Box,
  Button,
  HStack,
  Icon,
  Text,
} from '@chakra-ui/react';
import { Description, Edit } from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import useTrackedAction from '~/hooks/useTrackedAction';
import QuoteEditor from '~/pages/Job/components/quotes/QuoteEditor';
import QuoteSummary from '~/pages/Job/components/quotes/QuoteSummary';
import Routes from '~/pages/routes';
import { addQuoteToJob } from '~/redux/jobs/actions';
import { QuoteActions } from '~/redux/quote/actions';
import { Quote } from '~/types/quote';
import { getDraftFromQuote } from '~/utils/quoteEdit';
import ConfirmationDialog from '~/components/ConfirmationDialog';
import { useModalManager } from '~/components/ModalManager';

const QuotePanel = ({ quote, canCreateVariations = false, isVariation = false }
: {
  quote : Quote,
  canCreateVariations?:boolean,
  isVariation?: boolean
}) => {
  const modalManager = useModalManager();
  const { isLoading: isEditLoading, trigger: triggerEdit } = useTrackedAction({
    trigger: (q: Quote) => QuoteActions.edit({
      jobId: quote.jobId,
      quoteId: q.id,
      edits: getDraftFromQuote(q),
    }),
  });

  const { isLoading: isAddLoading, trigger: triggerAdd } = useTrackedAction({
    trigger: () => addQuoteToJob({
      jobId: quote.jobId,
    }),
  });

  const { isLoading: isDiscardingQuote, trigger: triggerDiscard } = useTrackedAction({
    trigger: (quoteId: string) => QuoteActions.discardDraft({
      jobId: quote.jobId,
      quoteId,
    }),
  });

  let discardText = 'changes';
  if (quote.status === 'draft') {
    if (isVariation) {
      discardText = 'variation';
    } else {
      discardText = 'quote';
    }
  }
  if (quote) {
    return (
      <Box pb={20}>
        {quote.draft
          ? (
            <QuoteEditor
              quote={quote.draft}
              jobId={quote.jobId}
              quoteId={quote.id}
            />
          )
          : <QuoteSummary quote={quote} />}
        <HStack mt="50px">
          <Box flex={1} />
          {quote.status !== 'accepted' && !quote.draft && (
          <Button
            size="lg"
            variant="outline"
            onClick={() => triggerEdit(quote)}
            isLoading={isEditLoading}
            color="magnetize.brand-4"
          >
            <Icon as={Edit} mr={1} fontSize="1em" />
            edit quote
          </Button>
          )}
          {quote.status === 'accepted' && canCreateVariations
      && (
      <Button
        size="lg"
        variant="outline"
        onClick={() => triggerAdd()}
        isLoading={isAddLoading}
        color="magnetize.brand-4"
      >
        + add variation
      </Button>
      )}
          {quote.draft
      && (
      <Button
        size="lg"
        variant="outline"
        onClick={() => {
          modalManager.open(ConfirmationDialog, {
            title: `Discard ${discardText}`,
            children: `Are you sure you want to discard ${quote.status === 'draft' ? `this ${discardText}?` : 'changes to this quote?'}`,
            onConfirm: () => triggerDiscard(quote.id),
            confirmButtonLabel: 'Discard',
            colorScheme: 'brandDark',
          });
        }}
        color="magnetize.support-warning"
        disabled={isDiscardingQuote}
      >
        {`discard ${discardText}`}
      </Button>
      )}
          <Button
            size="lg"
            alignItems="center"
            as={RouterLink}
            to={Routes.jobQuotePreview({ jobId: quote.jobId, quoteId: quote.id })}
            disabled={Object.keys((quote.draft || quote).lineItems).length === 0}
          >
            <Text>
              <Icon as={Description} mr={1} fontSize="1.2em" mb={1} />
              {quote.draft ? 'Preview & Send' : 'View Quote'}
            </Text>
          </Button>
        </HStack>
      </Box>
    );
  }
  return null;
};

export default QuotePanel;
