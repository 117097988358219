export const backfillTypeImageIds = <const> [
  'backfill-gravel',
  'cement-grout',
  'bentonite-grout',
  'bentonite',
  'filter-gravel',
  'drilling-spoil',
  'other',
  'custom-1',
  'custom-2',
  'custom-3',
  'custom-4',
  'custom-5',
  'custom-6',
  'custom-7',
  'custom-8',
  'custom-9',
  'custom-10',
  'custom-11',
  'custom-12',
  'blank',
];

export type BackfillTypeImageId = typeof backfillTypeImageIds[number];

export interface BackfillType {
  id: string;
  name: string;
  imageId: BackfillTypeImageId;
  isDeleted: boolean;
}
