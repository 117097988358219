import {
  Button,
  Modal, ModalBody, ModalCloseButton,
  ModalContent, ModalFooter, ModalHeader, ModalOverlay,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useAppDispatch } from '~/redux/store';
import { updateDrillingTechnique } from '~/redux/drillingTechniques/actions';
import { DrillingTechnique } from '~/types/drillingTechnique';
import { DrillingTechniqueForm } from './DrillingTechniqueForm';

export const DrillingTechniqueEditModal = ({
  drillingTechnique,
  title = 'Edit drilling technique',
  onClose = () => {},
}: {
  drillingTechnique: Partial<DrillingTechnique>,
  title: string,
  onClose: () => void
}) => {
  const dispatch = useAppDispatch();
  const [editedDrillingTechnique, setEditedDrillingTechnique] = useState(drillingTechnique);

  const canSave = editedDrillingTechnique.name;
  const onSaveClicked = () => {
    dispatch(updateDrillingTechnique(editedDrillingTechnique as DrillingTechnique));
    onClose();
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen
      onClose={onClose}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {title}
        </ModalHeader>
        <ModalCloseButton tabIndex={-1} />
        <ModalBody>
          <DrillingTechniqueForm
            value={editedDrillingTechnique}
            onChange={(dt) => {
              setEditedDrillingTechnique({
                ...editedDrillingTechnique,
                ...dt,
              });
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={!canSave}
            colorScheme="brandDark"
            onClick={onSaveClicked}
            size="lg"
          >
            Save
          </Button>
          <Button tabIndex={-1} variant="ghost" onClick={onClose}>cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
