import { createAction } from '@reduxjs/toolkit';
import createTrackedAction from '~/redux/tracked/createTrackedAction';
import { Equipment } from '~/types/equipment';

export const EquipmentActions = {
  fetch: createAction('equipment/fetch'),
  save: createTrackedAction<{ equipment: Equipment }>('equipment/save'),
  updated: createAction<{ equipment: Equipment[] }>('equipment/updated'),
  archive: createAction<{ equipmentId: string }>('equipment/archive'),
};
