import {
  compact, max, orderBy,
} from 'lodash';
import stringSimilarity from 'string-similarity';
import { Address } from '~/types/address';
import { Customer } from '~/types/customer';
import { CustomerContact } from '~/types/customerContact';
import { XeroContact } from '~/types/xero';
import formatShortAddress from '~/utils/formatShortAddress';

const similarity: (a: string, b: string) => number = stringSimilarity.compareTwoStrings;

export const getAddressFromXeroContact = (xc: XeroContact) => (
  xc.address ? {
    line1: xc.address.addressLine1,
    line2: xc.address.addressLine2,
    city: xc.address.city,
    postCode: xc.address.postalCode,
    country: xc.address.country,
  } : null
);

const compareAddress = (m: Address, x: Address) => {
  const magAddr = formatShortAddress(m);
  const xeroAddr = formatShortAddress(x);

  // Cannot be matched in this situation. Give up.
  if (!magAddr || !xeroAddr) {
    return 0;
  }
  return similarity(magAddr, xeroAddr);
};

const scoreCustomerMatch = (m: Customer, mainContact: CustomerContact, x: XeroContact) => {
  const doEmailsMatch = !!mainContact?.email && !!x.emailAddress
    && mainContact.email.toLowerCase() === x.emailAddress.toLowerCase();

  return {
    name: max(
      compact([m.companyName, mainContact?.name])
        .map((name) => similarity(name, x.name)),
    ),
    address: compareAddress(
      m.companyAddress,
      getAddressFromXeroContact(x),
    ),
    emailAddress: doEmailsMatch ? 1 : 0,
    xeroId: !!m.xeroId && m.xeroId === x.contactID ? 10 : 0, // Id match is an uber match
  };
};

export const rankMatchingXeroContacts = (
  customer: Customer,
  mainContact: CustomerContact,
  xeroContacts: XeroContact[],
) => {
  const scoredContacts = xeroContacts.map((x) => {
    const score = scoreCustomerMatch(customer, mainContact, x);
    return ({
      contact: x,
      score,
      totalScore: score.name + score.address + score.emailAddress + score.xeroId,
    });
  });
  return orderBy(scoredContacts, ({ totalScore }) => totalScore, ['desc']);
};
