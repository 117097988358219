import { keyBy, uniq } from 'lodash';
import {
  all, call, put, select, takeEvery,
} from 'redux-saga/effects';
import { fetchCompleted } from '~/redux/fetch/actions';
import { ScheduleEventActions } from '~/redux/schedule/actions';
import { getScheduleEventsForJob, getScheduleEventsInRange } from '~/api/scheduleApi';
import { fetchManyJobsSaga } from '~/redux/jobs/saga/fetchJob';
import { selectDoesNeedRefetch } from '~/redux/fetch/selectors';
import { RootState } from '~/redux/store';
import { ScheduleEvent } from '~/types/ScheduleEvent';
import { performFetchSaga } from '~/utils/performFetchSaga';

const handleFetchScheduleEventsForRange = takeEvery(
  ScheduleEventActions.fetchForRange,
  function* handle(action) {
    const { from, to } = action.payload;
    yield performFetchSaga({
      key: `schedule-for-range-${from}-${to}`,
      staleTime: 10000,
      * saga() {
        const events: ScheduleEvent[] = yield call(
          getScheduleEventsInRange, from, to,
        );
        const eventMap = keyBy(events, (e) => e.id);
        yield put(ScheduleEventActions.eventsUpdated({ events: eventMap }));
        yield put(fetchCompleted({ keys: events.map((e) => `schedule-event-${e.id}`), at: Date.now() }));

        // We want to bulk-fetch the jobs for these events too as the calendar
        // views need this data to do anything useful with it.
        //
        // We're doing it as part of the fetch here so that its loading status
        // gets tracked along with the range query, but I don't think this is
        // actually necessary and it could just dispatch an action to fetch
        // them separately.
        const jobIds = uniq(events
          .map((e) => (e.type === 'job' || e.type === 'job-resource' ? e.jobId : null))
          .filter((x) => !!x));
        const jobsToFetch: string[] = yield select((state: RootState) => (
          jobIds.filter((id) => selectDoesNeedRefetch(state, id, { staleTime: 10000 }))
        ));

        if (jobsToFetch.length > 0) {
          yield call(fetchManyJobsSaga, jobsToFetch);
        }
      },
    });
  },
);

const handleFetchScheduleEventsForJob = takeEvery(
  ScheduleEventActions.fetchForJob,
  function* handle(action) {
    const { jobId } = action.payload;
    yield performFetchSaga({
      key: `schedule-for-job-${jobId}`,
      staleTime: 10000,
      * saga() {
        const events: ScheduleEvent[] = yield call(
          getScheduleEventsForJob, jobId,
        );
        const eventMap = keyBy(events, (e) => e.id);
        yield put(ScheduleEventActions.eventsUpdated({ events: eventMap }));
        yield put(fetchCompleted({ keys: events.map((e) => `schedule-event-${e.id}`), at: Date.now() }));
      },
    });
  },
);

export default function* handleFetchScheduleEvents() {
  yield all([
    handleFetchScheduleEventsForRange,
    handleFetchScheduleEventsForJob,
  ]);
}
