import React, { useMemo } from 'react';
import { forwardRef, Text, TextProps } from '@chakra-ui/react';
import { useCurrencyFormatter } from '~/hooks/useFormatCurrency';

interface CurrencyTextProps {
  /** The value to render, in cents */
  value: number;
  currency?: string;
}

/**
 * Renders a price in dollars
 */
const CurrencyText = forwardRef<TextProps, 'span'>(({ value, currency, ...textProps }: CurrencyTextProps & TextProps, ref) => {
  const formatCurrency = useCurrencyFormatter(currency);
  const formatted = useMemo(() => formatCurrency(value), [value]);
  return <Text as="span" ref={ref} {...textProps}>{formatted}</Text>;
});

export default CurrencyText;
