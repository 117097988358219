import { Heading } from '@chakra-ui/react';
import React from 'react';
import StatusBadge from '~/components/JobStatusBadge';

import TimelineItem, { TimelineItemProps } from '~/components/TimelineItem';
import { JobTimelineScheduleRemoved } from '~/types/job';

const TimelineScheduleRemoved = ({
  event, ...rest
}: {
  event: JobTimelineScheduleRemoved;
  hideHeading?: boolean;
} & TimelineItemProps) => (
  <TimelineItem
    {...rest}
    event={event}
  >

    {event.status && <StatusBadge status={event.status} />}
    <Heading textTransform="none" size="sm" mb={1}>JOB UNSCHEDULED</Heading>

  </TimelineItem>
);

export default TimelineScheduleRemoved;
