import {
  NumberDecrementStepper, NumberIncrementStepper, NumberInput,
  NumberInputField, NumberInputStepper,
} from '@chakra-ui/react';
import React, {
  MutableRefObject, useEffect, useRef, useState,
} from 'react';
import CenteredFlex from '~/components/LineItemsEditor/components/CenteredFlex';

interface LineItemQuantityProps {
  value: number;
  onChange: (value: number) => void;
  hide?: boolean;
  focusRef?: MutableRefObject<HTMLInputElement>;
  fontWeight?: string;
}

const LineItemQuantity = ({
  value, onChange, hide, focusRef, fontWeight = 'normal',
} : LineItemQuantityProps) => {
  const ref = useRef<HTMLInputElement>();
  const [quantityString, setQuantityString] = useState(value?.toString() ?? '0');
  useEffect(() => {
    setQuantityString(value?.toString() ?? '0');
  }, [value]);
  return (
    <CenteredFlex flex="0 0 85px">
      {!hide && (
      <NumberInput
        variant="ghost"
        min={0}
        max={100000}
        onChange={(e) => {
          setQuantityString(e);
          const newQuantity = Number(e);
          if (!Number.isNaN(newQuantity) && newQuantity !== value) {
            onChange(newQuantity);
          }
        }}
        onClick={() => {
          focusRef?.current?.select();
          ref?.current?.select();
        }}
        value={quantityString}
      >
        <NumberInputField
          backgroundColor="transparent"
          fontWeight={fontWeight}
          ref={focusRef ?? ref}
        />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      )}
    </CenteredFlex>
  );
};

export default LineItemQuantity;
