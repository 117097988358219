import { all } from 'redux-saga/effects';
import watchAddTimelineNoteToJob from '~/redux/jobs/saga/addTimelineNoteToJob';
import handleJobFetch from '~/redux/jobs/saga/fetchJob';
import handleUpdateJob from '~/redux/jobs/saga/updateJob';
import handleSaveJob from '~/redux/jobs/saga/saveJob';
import handleDuplicateJob from '~/redux/jobs/saga/duplicateJob';
import watchExportJobs from '~/redux/jobs/saga/exportJobs';
import watchFetchAll from '~/redux/jobs/saga/fetchAll';
import {
  watchCloseJob,
  watchCompleteJob,
  watchHoldJob,
  watchReopenJob,
  watchStartJob,
  watchArchiveJob,
} from '~/redux/jobs/saga/changeJobStatus';

export default function* handleJobs() {
  yield all([
    handleJobFetch,
    handleUpdateJob,
    handleSaveJob,
    handleDuplicateJob,
    watchAddTimelineNoteToJob,
    watchExportJobs,
    watchFetchAll,
    watchCompleteJob,
    watchReopenJob,
    watchCloseJob,
    watchHoldJob,
    watchStartJob,
    watchArchiveJob,
  ]);
}
