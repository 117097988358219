import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import { sumBy } from 'lodash';
import QuoteLineItemCard from './QuoteLineItemCard';
import { SummaryConsumableRecord } from '~/types/consumable';
import { LineItem } from '~/types/lineItem';
import ReconcileButtons from '~/pages/Job/components/invoices/ReconciliationManager/components/ReconcileButtons';
import { useAppSelector } from '~/redux/store';
import { selectPriceById } from '~/redux/prices/selectors';

const ConsumableCard = (
  { consumables, overBudget, unit }
  : { consumables : SummaryConsumableRecord[], overBudget: boolean, unit: string },
) => {
  const { name } = consumables[0];
  const totalQuantity = sumBy(consumables, (c) => c.quantity);

  return (
    <Flex
      flex={1}
      boxShadow="0px 1px 2px rgba(0, 0, 0, 0.1)"
      borderRadius="2"
      backgroundColor={overBudget ? 'rgba(255, 138, 0, 0.05)' : 'rgba(53,155,75, 0.05)'}
      borderWidth={1}
      borderColor="gray.200"
      my={5}
      py={5}
      px={4}
      direction="column"
    >
      <Flex>
        <Text fontWeight="600">
          {name}
        </Text>
      </Flex>
      <Flex>
        <Text>
          {`${consumables.length} logs`}
        </Text>
      </Flex>
      <Flex justifyContent="flex-end" fontWeight="600">
        {`${totalQuantity}${unit ? ` ${unit}` : ''}`}
      </Flex>
    </Flex>
  );
};

const ConsumableReconciliationRow = (
  {
    quoted, alreadyInvoiced, logged, onItemAdded, onItemIgnored, isDisabled,
  } : {
    quoted: LineItem[];
    alreadyInvoiced: LineItem[];
    logged: SummaryConsumableRecord[];
    onItemAdded: (lineItem: LineItem) => void;
    onItemIgnored: () => void;
    isDisabled: boolean;
  },
) => {
  const hasQuoted = quoted.length > 0;
  const totalLoggedQuantity = sumBy(logged, (l) => l.quantity);
  const totalQuotedQuantity = sumBy(quoted, (q) => q.quantity);
  const totalAlreadyInvoicedQuantity = sumBy(alreadyInvoiced, (q) => q.quantity);
  const price = useAppSelector(
    (s) => (logged[0]?.priceId ? selectPriceById(s, logged[0].priceId) : null),
  );

  const quotedMatchesLogged = totalLoggedQuantity === totalQuotedQuantity;

  const addToInvoice = (useQuoted: boolean) => {
    if (useQuoted) {
      const quantity = Math.max(totalQuotedQuantity - totalAlreadyInvoicedQuantity, 0);
      if (quantity > 0) {
        onItemAdded({
          id: nanoid(),
          type: 'price',
          order: 0,
          name: quoted[0].name,
          description: quoted[0].description,
          quantity,
          unitPriceCents: quoted[0].unitPriceCents,
          priceId: quoted[0].priceId,
        });
      } else {
        onItemIgnored();
      }
    } else {
      onItemAdded({
        id: nanoid(),
        type: 'price',
        order: 0,
        name: logged[0].name,
        description: price?.description,
        quantity: totalLoggedQuantity,
        unitPriceCents: quoted.length > 0 ? quoted[0].unitPriceCents : undefined,
        priceId: logged[0].priceId,
      });
    }
  };

  return (
    <Flex>
      {hasQuoted
        ? (
          <QuoteLineItemCard
            name={quoted[0].name}
            unitPriceCents={quoted[0].unitPriceCents}
            unit={quoted[0].unit}
            quotedQuantity={totalQuotedQuantity}
            alreadyInvoicedQuantity={totalAlreadyInvoicedQuantity}
          />
        )
        : <Flex flex={1} px={2} />}
      <Flex mx={6} alignItems="center" justifyContent="center">
        <ReconcileButtons
          isDisabled={isDisabled}
          onLeftClick={() => {
            addToInvoice(true);
          }}
          onRightClick={() => {
            addToInvoice(false);
          }}
          onIgnoreClick={onItemIgnored}
          hasQuoted={hasQuoted}
          quotedMatchesLogged={quotedMatchesLogged}
          invoicedMoreThanQuoted={totalQuotedQuantity - totalAlreadyInvoicedQuantity < 0}
          loggedMoreThanQuoted={
            totalQuotedQuantity - totalAlreadyInvoicedQuantity - totalLoggedQuantity < 0
          }
        />
      </Flex>
      <ConsumableCard
        consumables={logged}
        unit={quoted[0]?.unit || price?.unit}
        overBudget={totalQuotedQuantity - totalAlreadyInvoicedQuantity - totalLoggedQuantity < 0}
      />
    </Flex>
  );
};

export default ConsumableReconciliationRow;
