import {
  Box, Button, Flex, FormControl, FormLabel, Grid, Input,
} from '@chakra-ui/react';
import { isEqual } from 'lodash';
import React, { useState } from 'react';
import * as yup from 'yup';

import AddressForm from '~/components/AddressForm';
import { Address } from '~/types/address';
import { Tenant } from '~/types/tenant';

interface ContactDetailsTabProps {
  tenant: Tenant;
  isSaving: boolean;
  onSave: (tenant: Tenant) => void;
}

const ContactDetailsTab = ({ tenant, isSaving, onSave } : ContactDetailsTabProps) => {
  const [name, setName] = useState(tenant.name ?? '');
  const [phone, setPhone] = useState(tenant.phone ?? '');
  const [email, setEmail] = useState(tenant.email ?? '');
  const [website, setWebsite] = useState(tenant.website ?? '');
  const [address, setAddress] = useState(
    tenant.address ?? {} as Partial<Address>,
  );
  const emailValid = yup.string().email().isValidSync(email);
  const isDirty = name !== (tenant.name ?? '')
  || phone !== (tenant.phone ?? '')
  || email !== (tenant.email ?? '')
  || website !== (tenant.website ?? '')
  || !(isEqual(address, tenant.address ?? {}));

  const saveEnabled = name.length > 0 && emailValid && isDirty;
  return (
    <Grid gap={6}>
      <FormControl>
        <FormLabel>Organisation Name</FormLabel>
        <Input
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </FormControl>
      <Box>
        <FormLabel>Main Address</FormLabel>
        <AddressForm
          enableAutocomplete
          value={address}
          onChange={setAddress}
        />
      </Box>
      <FormControl>
        <FormLabel>Main Phone Number</FormLabel>
        <Input
          value={phone}
          onChange={(e) => {
            setPhone(e.target.value);
          }}
        />
      </FormControl>
      <FormControl isInvalid={!!email && !emailValid}>
        <FormLabel>Main Email</FormLabel>
        <Input
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Website</FormLabel>
        <Input
          value={website}
          onChange={(e) => {
            setWebsite(e.target.value);
          }}
        />
      </FormControl>
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
        mt={4}
      >
        <Button
          disabled={!saveEnabled || isSaving}
          onClick={() => {
            onSave({
              ...tenant,
              name,
              address: address as Address,
              phone,
              email,
              website,
            });
          }}
          size="lg"
        >
          Save
        </Button>
      </Flex>
    </Grid>
  );
};

export default ContactDetailsTab;
