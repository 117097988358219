import {
  Box, useMediaQuery,
} from '@chakra-ui/react';
import React from 'react';
import SideNav from '~/components/SideNav';
import UserProfileAvatar from '~/components/UserProfileAvatar';
import PrefsActions from '~/redux/prefs/actions';
import { useAppDispatch, useAppSelector } from '~/redux/store';

const EXPANDED_SIDEBAR_WIDTH = '228px';
const COLLAPSED_SIDEBAR_WIDTH = '66px';

const SidebarLayout = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector((s) => s.prefs.sideNavExpanded ?? false);
  const [isPrinting] = useMediaQuery('print');

  if (isPrinting) {
    return <>{children}</>;
  }

  const setIsOpen = (sideNavExpanded) => {
    dispatch(PrefsActions.set({
      prefs: {
        sideNavExpanded,
      },
    }));
  };

  return (
    <Box position="relative">
      <Box
        position="fixed"
        left="0"
        top="0"
        bottom="0"
        width={isOpen ? EXPANDED_SIDEBAR_WIDTH : COLLAPSED_SIDEBAR_WIDTH}
        transition="width 0.5s"
        zIndex="10"
      >
        <SideNav isOpen={isOpen} setIsOpen={setIsOpen} />
      </Box>
      <Box position="absolute" right="8" top="8">
        <UserProfileAvatar />
      </Box>
      <Box
        pl={isOpen ? EXPANDED_SIDEBAR_WIDTH : COLLAPSED_SIDEBAR_WIDTH}
        pt="80px"
        transition="padding 0.5s"
        height="100vh"
        display="flex"
      >
        {children}
      </Box>
    </Box>
  );
};

export default SidebarLayout;
