import {
  Flex, Icon,
} from '@chakra-ui/react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DragIndicator } from '@material-ui/icons';
import React from 'react';

interface DndKitDragItemProps {
  id: string;
  disabled?: boolean;
  children: React.ReactNode;
}

const DndKitDragItem = ({
  id, disabled = false, children,
}: DndKitDragItemProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    isDragging,
    // transition,
  } = useSortable({ id, disabled });

  const style = {
    transform: CSS.Translate.toString(transform),
    // Don't use transition as it can cause really wacky behaviour
    // when adding new line items after the list has been reordered
    // items seem to all transition from random positions making the list look like
    // its shuffling itself
    // transition,
  };

  return (
    <Flex
      borderColor="gray.100"
      borderTopWidth={1}
      flex="1"
      style={style}
      ref={setNodeRef}
      cursor="unset"
      zIndex={isDragging ? 100 : undefined}
      background={isDragging ? 'white' : undefined}
    >
      <Flex
        {...attributes}
        {...listeners}
        visibility={disabled ? 'hidden' : 'visible'}
        cursor={disabled ? 'unset' : undefined}
        justifyContent="center"
        mr="4"
        mt="13px"
        tabIndex={-1}
      >
        <Icon
          as={DragIndicator}
          ml={-1}
          boxSize="16px"
        />
      </Flex>
      <Flex flex="1" py={1}>{children}</Flex>
    </Flex>
  );
};

export default DndKitDragItem;
