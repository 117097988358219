import { getCurrentUser } from '~/helpers/auth';

interface FetchOptions<TRequest> extends Omit<RequestInit, 'body' | 'headers'> {
  body?: TRequest;
  headers?: { [key: string]: string };
}

const tryParseJson = (encoded: string): unknown | undefined => {
  try {
    return JSON.parse(encoded);
  } catch {
    return undefined;
  }
};

async function fetchJson<TResponse, TRequest = any>(
  url: string, options: FetchOptions<TRequest> = {},
): Promise<TResponse> {
  const currentUser = await getCurrentUser();
  const headers = { ...options?.headers ?? {} };
  if (currentUser?.token) {
    headers.Authorization = `Bearer ${currentUser.token}`;
  }

  const shouldSendBody = options.method && options.method !== 'GET';
  if (shouldSendBody) {
    headers['Content-Type'] = 'application/json';
  }

  const response = await fetch(url, {
    ...options ?? {},
    headers,
    body: shouldSendBody ? JSON.stringify(options.body) : undefined,
  });

  const bodyText = await response.text();
  if (response.headers.get('Content-Type')?.startsWith('application/json')) {
    const bodyJson = tryParseJson(bodyText);
    if (!response.ok) {
      const errorJson = bodyJson as { error: string };
      throw new Error(errorJson?.error ?? `${response.status} ${response.statusText}: ${bodyText}`);
    }
    return bodyJson as TResponse;
  } if (!response.ok) {
    throw new Error(bodyText);
  } else if (bodyText) {
    throw new Error('Response was not JSON');
  } else {
    // No content
    return undefined;
  }
}

export default fetchJson;
