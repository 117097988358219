import { Tag, Text } from '@chakra-ui/react';
import React from 'react';

interface AvailabilityStatusBadgeProps {
  isAvailable: Boolean;
  isEquipment?: Boolean;
}

const AvailabilityStatusBadge = (
  { isAvailable, isEquipment = false }: AvailabilityStatusBadgeProps,
) => {
  const colorScheme = isAvailable ? 'green' : 'gray';
  const availableText = isEquipment ? 'In Service' : 'Available';
  const unavailableText = isEquipment ? 'Out of Service' : 'Unavailable';

  return (
    <Tag borderRadius="full" colorScheme={colorScheme}>
      <Text fontWeight="bold" color="black" whiteSpace="nowrap">
        &bull;
        {' '}
        {isAvailable ? availableText : unavailableText}
      </Text>
    </Tag>
  );
};

export default AvailabilityStatusBadge;
