import {
  Flex, HStack, SkeletonText,
  Spacer, Text, VStack,
} from '@chakra-ui/react';
import { PersonOutlined } from '@material-ui/icons';
import React, { useState } from 'react';
import CustomerContactEditModal from '~/components/CustomerContactEditModal';
import CustomerContactSelect from '~/components/CustomerContactSelect';
import LinkButton from '~/components/LinkButton';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import { CustomerContactActions } from '~/redux/customerContacts/actions';
import { selectCustomerContactById } from '~/redux/customerContacts/selectors';
import { CustomerContact } from '~/types/customerContact';

interface CustomerContactProps {
  customerId?: string;
  value: string;
  onChange: (contact: CustomerContact) => void;
}

const CustomerContactEditor = (
  { value, onChange, customerId }: CustomerContactProps,
) => {
  const contactId = value;
  const [editedContact, setEditedContact] = useState<CustomerContact>(null);
  const [editedContactIsNew, setEditedContactIsNew] = useState(false);

  const {
    data: selectedContact, isLoading,
  } = useTrackedFetch({
    trigger: () => CustomerContactActions.fetch({ id: contactId }),
    selector: (s) => (contactId ? selectCustomerContactById(s, contactId) : null),
    key: `contact-${contactId}`,
    enabled: !!contactId,
  });

  if (isLoading) {
    return <SkeletonText />;
  }

  return (
    <>
      { selectedContact
        ? (
          <HStack align="stretch">
            <PersonOutlined />
            <VStack flex={1} align="stretch" spacing={1}>
              <Flex alignItems="center">
                <LinkButton
                  noUnderline
                  onClick={() => {
                    setEditedContactIsNew(false);
                    setEditedContact(selectedContact);
                  }}
                >
                  {selectedContact.name}
                </LinkButton>
                <Spacer />
                <LinkButton
                  noUnderline
                  onClick={() => {
                    onChange(null);
                  }}
                >
                  change
                </LinkButton>
              </Flex>
              <Spacer />
              <Flex>
                <Text width={8}>ph.</Text>
                <Text fontWeight="semibold">{selectedContact.phone}</Text>
              </Flex>
              <Flex>
                <Text width={8}>e.</Text>
                <Text fontWeight="semibold">{selectedContact.email}</Text>
              </Flex>
            </VStack>
          </HStack>
        )
        : (
          <CustomerContactSelect
            customerId={customerId}
            value={contactId}
            onChange={(c) => {
              setEditedContactIsNew(false);
              onChange(c);
            }}
            onCreateCustomerContact={(name, id) => {
              setEditedContactIsNew(true);
              setEditedContact({
                id,
                name,
                customerId,
              });
            }}
          />
        )}
      {editedContact
      && (
      <CustomerContactEditModal
        customerContact={editedContact}
        disableCustomerSelect={!!editedContact.customerId}
        isNew={editedContactIsNew}
        onSave={(c) => {
          onChange(c);
        }}
        onClose={() => {
          setEditedContact(null);
        }}
      />
      )}
    </>
  );
};

export default CustomerContactEditor;
