import {
  Box, Flex, FormControl, FormLabel,
  FormHelperText, Grid, HStack, Input, Switch, Text,
  Textarea, FormErrorMessage, FormErrorIcon,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import CentsEditor from '~/components/CentsEditor';
import CreatableTagSelect from '~/components/CreatableTagSelect';
import ToggleButtonGroup from '~/components/ToggleButtonGroup';
import { Price } from '~/types/price';
import UnitSearchSelect from './UnitSearchSelect';

interface PriceEditFormProps {
  value?: Partial<Price>;
  onChange?: (price: Partial<Price>) => void
}

const PriceEditForm = (
  {
    value = {},
    onChange = () => {},
  }
  : PriceEditFormProps,
) => {
  const [name, setName] = useState(value.name ?? '');
  const [description, setDescription] = useState(value.description ?? '');
  const [unit, setUnit] = useState(value.unit ?? null);
  const [unitLength, setUnitLength] = useState(0);
  const [unitPriceCents, setUnitPriceCents] = useState(value.unitPriceCents ?? 0);
  const [tags, setTags] = useState(value.tags ?? []);
  const [category, setCategory] = useState(value.category ?? 'labour');
  const [code, setCode] = useState(value.code ?? '');
  const [isAvailable, setIsAvailable] = useState(value.isAvailable ?? true);
  const nameRef = useRef(null);
  const maxUnitLength = 9;

  useEffect(() => {
    onChange({
      ...(value || {}),
      name,
      description,
      unit,
      unitPriceCents,
      tags,
      category,
      code,
      isAvailable,
    });
  }, [
    name,
    description,
    unit,
    unitPriceCents,
    tags,
    category,
    code,
    isAvailable,
  ]);

  useEffect(() => {
    nameRef.current.focus();
  }, []);

  return (
    <Grid gap={6}>
      <FormControl isRequired>
        <FormLabel>Type</FormLabel>
        <ToggleButtonGroup
          activeItem={category}
          setActiveItem={(c) => setCategory(c)}
          items={['labour', 'equipment', 'consumable', 'fee']}
        />
      </FormControl>
      <HStack>
        <FormControl flex="2" isRequired>
          <FormLabel>Name</FormLabel>
          <Input
            ref={nameRef}
            placeholder="Enter the name for the rate"
            value={name}
            onChange={(e) => { setName(e.target.value); }}
          />
        </FormControl>
        <FormControl flex="1">
          <FormLabel>Code</FormLabel>
          <Input
            placeholder="e.g. R00-01"
            value={code}
            onChange={(e) => { setCode(e.target.value); }}
          />
        </FormControl>
      </HStack>
      <FormControl>
        <FormLabel>Categories</FormLabel>
        <CreatableTagSelect
          placeholder="Add or create the rate category"
          type="price"
          tags={tags}
          setTags={setTags}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Description</FormLabel>
        <Textarea
          resize="none"
          placeholder="Enter a description"
          value={description}
          maxLength={200}
          onChange={(e) => { setDescription(e.target.value); }}
        />
      </FormControl>
      <HStack>
        <FormControl flex="1">
          <FormLabel display="flex">
            Price
            <Text ml={1} fontWeight="400" textTransform="none">(ex TAX)</Text>
          </FormLabel>
          <Flex>
            <CentsEditor
              onChange={(cents) => setUnitPriceCents(cents)}
              value={unitPriceCents}
              variant="outline"
              minW="100%"
            />
          </Flex>
        </FormControl>
        <FormControl flex="1" isInvalid={unitLength > maxUnitLength}>
          <HStack justifyContent="space-between">
            <FormLabel>Billing Type</FormLabel>
            {unitLength > 0 && unitLength <= maxUnitLength ? (
              <FormHelperText pb={2}>
                <HStack>
                  <Text fontWeight="bold">
                    {unitLength}
                  </Text>
                  <Text ml="4px !important">
                    {` of ${maxUnitLength} characters`}
                  </Text>
                </HStack>
              </FormHelperText>
            ) : null}
            <FormErrorMessage pb={2}>
              <HStack>
                <FormErrorIcon />
                <Text fontWeight="bold">
                  {unitLength}
                </Text>
                <Text ml="4px !important">
                  {` of ${maxUnitLength} characters`}
                </Text>
              </HStack>
            </FormErrorMessage>
          </HStack>
          <UnitSearchSelect
            value={unit}
            maxLength={maxUnitLength}
            onInputLengthChanged={(u) => setUnitLength(u)}
            onChange={(u) => setUnit(u)}
            placeholder="Fixed or Rate?"
          />
        </FormControl>
      </HStack>
      <Box>
        <FormControl display="flex" alignItems="center">
          <Switch
            name="is-required"
            size="lg"
            isChecked={isAvailable}
            onChange={(e) => setIsAvailable(e.target.checked)}
          />
          <Text
            ml={2}
            htmlFor="quote-required"
            cursor="pointer"
            onClick={() => setIsAvailable(!isAvailable)}
          >
            Available for quoting and invoicing
          </Text>
        </FormControl>
      </Box>
    </Grid>
  );
};

export default PriceEditForm;
