import { createSelector } from '@reduxjs/toolkit';
import { sortBy } from 'lodash';
import { RootState } from '~/redux/reducer';

export const selectAllCustomers = createSelector(
  (s: RootState) => s.customers.byId,
  (customers) => sortBy(customers, (c) => c.companyName.toLowerCase()),
);

export const selectCustomers = createSelector(
  selectAllCustomers,
  (customers) => customers.filter((c) => !c.isDeleted),
);

export const selectCustomerById = (s: RootState, customerId: string) => (
  s.customers.byId[customerId]
);
