import { Grid, GridItem, Text } from '@chakra-ui/react';
import React from 'react';

const daysInWeek = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

const MonthCalendarHeader = () => (
  <Grid gridTemplateColumns="repeat(7, 1fr)" my={2}>
    {daysInWeek.map((d) => (
      <GridItem key={d}>
        <Text
          textAlign="center"
          color="magnetize.text-2"
          fontSize="12px"
          textTransform="uppercase"
        >
          {d}
        </Text>
      </GridItem>
    ))}
  </Grid>
);

export default MonthCalendarHeader;
