import { createReducer } from '@reduxjs/toolkit';
import {
  now, keyBy, omit,
} from 'lodash';
import { drillingTechniqueDeleted, drillingTechniquesLoaded, drillingTechniqueUpdated } from '~/redux/drillingTechniques/actions';
import { DrillingTechnique } from '~/types/drillingTechnique';
import { FetchState } from '~/types/fetchState';

export const drillingTechniquesReducer = createReducer(
  { data: { byId: {} } } as FetchState<{
    byId: { [key:string]: DrillingTechnique }
  }>,
  (builder) => (builder
    .addCase(drillingTechniquesLoaded, (state, action) => ({
      status: 'success',
      data: {
        byId: keyBy(action.payload, (x) => x.id),
      },
      isInvalid: false,
      lastUpdatedAt: now(),
    }))

    .addCase(drillingTechniqueUpdated, (state, action) => ({
      ...state,
      data: {
        byId: {
          ...state.data.byId,
          [action.payload.id]: action.payload,
        },
      },
    }))

    .addCase(drillingTechniqueDeleted, (state, action) => ({
      ...state,
      data: {
        byId: omit(state.data.byId, action.payload.id),
      },
    }))
  ),
);
