import { createReducer } from '@reduxjs/toolkit';
import WelcomeModalActions from '~/redux/welcomeModal/actions';
import UploadedFile from '~/types/uploaded-file';

export interface WelcomeModalState {
  currentStep: number;
  organisationName: string;
  industry: string;
  otherIndustry: string;
  problems: string[];
  otherProblems: string;
  teamSize: string;
  exampleJobs: string[];
  quoteJobs: string;
  organisationLogo: UploadedFile;
  financialSoftware: string;
  enableLiveGeo: boolean;
}

const welcomeModalReducer = createReducer<WelcomeModalState>({
  currentStep: 0,
  organisationName: '',
  industry: '',
  otherIndustry: '',
  problems: [],
  otherProblems: '',
  teamSize: '',
  exampleJobs: ['', '', ''],
  quoteJobs: '',
  organisationLogo: null,
  financialSoftware: '',
  enableLiveGeo: false,
}, (builder) => {
  builder.addCase(WelcomeModalActions.incrementStep, (state) => ({
    ...state,
    currentStep: state.currentStep + 1,
  }));
  builder.addCase(WelcomeModalActions.setStep, (state, action) => ({
    ...state,
    currentStep: action.payload.step,
  }));
  builder.addCase(WelcomeModalActions.update, (state, action) => ({
    ...state,
    ...action.payload.update,
  }));
});

export default welcomeModalReducer;
