import React from 'react';
import {
  Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter,
  ModalHeader, ModalOverlay, Text,
} from '@chakra-ui/react';
import { useHistory } from 'react-router';
import useTrackedAction from '~/hooks/useTrackedAction';
import { useAppSelector } from '~/redux/store';
import { selectJobWithEdits } from '~/redux/jobs/selectors';
import { duplicateJob } from '~/redux/jobs/actions';
import Routes from '~/pages/routes';
import { selectCustomerById } from '~/redux/customers/selectors';

export const DuplicateJobModal = ({
  onCancel, jobId,
}: {
  onCancel: () => void
  jobId:string
}) => {
  const history = useHistory();

  const job = useAppSelector((state) => selectJobWithEdits(state, jobId));
  const customer = useAppSelector(
    (state) => (job ? selectCustomerById(state, job.customerId) : null),
  );

  const { trigger: onDuplicateJob, isLoading } = useTrackedAction({
    trigger: () => duplicateJob({ id: jobId }),
    onSuccess: (result: string) => {
      history.push(Routes.job({ jobId: result }));
    },
  });

  return (
    <Modal isOpen onClose={onCancel} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader paddingBottom={5}>
          <Text>Duplicate this job</Text>
          <Text marginTop={3} fontSize={14} fontWeight="normal">
            {'NEW: '}
            <strong>{job.name}</strong>

            {customer && ' for '}
            {customer && (
              <strong>{customer.companyName}</strong>
            )}
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody paddingBottom={6}>
          <Text>
            A new job with the same job name, details, quote items and files will be created.
            Any scheduled events, logs or invoices for this job will NOT be duplicated.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Flex>
            <Button
              size="lg"
              disabled={isLoading}
              onClick={() => {
                onDuplicateJob();
              }}
              isLoading={isLoading}
            >
              Duplicate
            </Button>

            <Button variant="ghost" size="lg" onClick={onCancel}>cancel</Button>

          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
