import { nanoid } from '@reduxjs/toolkit';
import React from 'react';
import SearchSelect from '~/components/SearchSelect';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import { CustomerActions } from '~/redux/customers/actions';
import { selectCustomers } from '~/redux/customers/selectors';

interface CustomerSelectProps {
  value: string;
  onChange: (value: string | undefined) => void;
  onCreateCustomer: (name: string, id: string) => void;
}

const CustomerSelect = ({ value, onChange, onCreateCustomer }: CustomerSelectProps) => {
  const {
    data: customers,
  } = useTrackedFetch({
    trigger: () => CustomerActions.fetchAll(),
    selector: selectCustomers,
    key: 'customers',
  });

  const customerOptions = [
    ...(customers ?? []).map((c) => ({ label: c.companyName, value: c.id })),
  ];
  const selectedOption = customerOptions.find((option) => option.value === value);
  return (
    <SearchSelect
      createable
      isClearable
      placeholder="Add or create customer"
      value={selectedOption}
      options={customerOptions}
      onChange={(option) => onChange?.(option?.value)}
      onCreateOption={(name) => {
        const id = nanoid();
        onCreateCustomer(name, id);
      }}
      createOptionText="Create a new customer"
      isValidNewOption={() => true}
    />
  );
};

export default CustomerSelect;
