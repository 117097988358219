import React, { useState } from 'react';
import {
  Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter,
  ModalHeader, ModalOverlay, FormControl, FormLabel, Input, Text, ListItem, UnorderedList,
} from '@chakra-ui/react';
import useTrackedAction from '~/hooks/useTrackedAction';
import { currentUserActions } from '~/redux/currentUser/actions';

export const DeleteExampleDataModal = ({
  onCancel,
}: {
  onCancel: () => void,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [confirmText, setConfirmText] = useState('');

  const { trigger: onDeleteTrialData } = useTrackedAction({
    trigger: () => {
      setIsSaving(true);
      return currentUserActions.deleteTrialData({});
    },
    onSuccess: () => {
      window.location.reload();// the easiest way to refetch all the data
    },
  });

  return (
    <Modal isOpen onClose={onCancel} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader paddingBottom={5}>
          <Text paddingTop={5}>Delete all demo data</Text>
          <Text marginTop={3} fontSize={14}>
            Are you sure you want to remove all demo jobs and data from your account?
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody paddingBottom={6}>
          <Text>
            This means:
          </Text>
          <UnorderedList marginLeft={10} marginTop={5}>
            <ListItem>
              All demo jobs, customers, prices, equipment
              and team members created by Magnetize
              as example data will be deleted.
            </ListItem>
            <ListItem>
              If you have renamed any of the demo data it will also be deleted.
            </ListItem>
            <ListItem>
              All other data will be left untouched.
            </ListItem>
          </UnorderedList>
          <FormControl marginTop={10}>
            <FormLabel>Type delete to confirm</FormLabel>
            <Input
              size="lg"
              value={confirmText}
              onChange={(e) => { setConfirmText(e.target.value); }}
              placeholder="delete"
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Flex>
            <Button
              size="lg"
              disabled={isSaving || confirmText.toLocaleLowerCase() !== 'delete'}
              colorScheme="red"
              isLoading={isSaving}
              onClick={onDeleteTrialData}
            >
              Delete all demo data
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
