import {
  Flex, Text,
} from '@chakra-ui/react';
import React from 'react';
import CurrencyText from '~/components/CurrencyText';

const QuoteLineItemCard = (
  {
    name,
    unitPriceCents,
    unit,
    quotedQuantity,
    alreadyInvoicedQuantity,
  }
  : { name:string,
    unitPriceCents: number,
    unit?: string,
    quotedQuantity: number,
    alreadyInvoicedQuantity:number },
) => {
  const remaining = Math.max(quotedQuantity - alreadyInvoicedQuantity, 0);

  return (
    <Flex
      borderBottomWidth={1}
      borderTopWidth={1}
      borderColor="gray.200"
      my={5}
      py={5}
      direction="column"
      flex={1}
      px={2}
    >
      <Flex flex={1}>
        <Text fontWeight="bold">
          {name}
        </Text>
      </Flex>
      <Flex flex={1} justifyContent="space-between">
        <CurrencyText value={unitPriceCents} />
      </Flex>
      <Flex flex={1} justifyContent="space-between">
        <Text color={alreadyInvoicedQuantity > quotedQuantity ? 'magnetize.support-error' : 'magnetize.text-3'}>
          {alreadyInvoicedQuantity > 0 ? `${alreadyInvoicedQuantity} of ${quotedQuantity} invoiced` : ''}
        </Text>
        <Text fontWeight="bold">
          {`x${remaining}${unit ? ` ${unit}` : ''}`}
        </Text>
      </Flex>
      {alreadyInvoicedQuantity > 0 && (
      <Flex flex={1} justifyContent="space-between">
        <Text />
        <Text fontWeight="bold">
          remaining
        </Text>
      </Flex>
      )}
    </Flex>
  );
};

export default QuoteLineItemCard;
