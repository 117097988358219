import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import React, { useEffect, useMemo } from 'react';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import FinancialSoftware from '~/pages/WelcomeModal/steps/FinancialSoftware';
import { LiveGeoSettings } from '~/pages/WelcomeModal/steps/LiveGeoSettings';
import ProblemsToSolve from '~/pages/WelcomeModal/steps/ProblemsToSolve';
import QuoteSettings from '~/pages/WelcomeModal/steps/QuoteSettings';
import TeamAndJobs from '~/pages/WelcomeModal/steps/TeamAndJobs';
import Welcome from '~/pages/WelcomeModal/steps/Welcome';
import { selectCurrentUser } from '~/redux/currentUser/selectors';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import SubscriptionActions from '~/redux/subscription/actions';
import WelcomeModalActions from '~/redux/welcomeModal/actions';
import { selectWelcomeModalCurrentStep, selectWelcomeModalState } from '~/redux/welcomeModal/selectors';
import { PowerUpId } from '~/types/subscription';

const Step = ({ currentStep, stepNumber, totalSteps }
: { currentStep: string, stepNumber: number, totalSteps: number }) => {
  switch (currentStep) {
    case 'problemsToSolve':
      return <ProblemsToSolve stepNumber={stepNumber} totalSteps={totalSteps} />;
    case 'liveGeo':
      return <LiveGeoSettings stepNumber={stepNumber} totalSteps={totalSteps} />;
    case 'teamAndJobs':
      return <TeamAndJobs stepNumber={stepNumber} totalSteps={totalSteps} />;
    case 'quoteSettings':
      return <QuoteSettings stepNumber={stepNumber} totalSteps={totalSteps} />;
    case 'financialSoftware':
      return <FinancialSoftware stepNumber={stepNumber} totalSteps={totalSteps} />;
    case 'welcome':
    default:
      return <Welcome stepNumber={stepNumber} totalSteps={totalSteps} />;
  }
};

const WelcomeModal = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const currentStep = useAppSelector(selectWelcomeModalCurrentStep);
  const welcomeState = useAppSelector(selectWelcomeModalState);

  const { data: subscriptionPrices } = useTrackedFetch({
    key: 'subscriptionPrices',
    trigger: () => SubscriptionActions.fetchPrices(),
    selector: (state) => state.subscription.prices,
  });

  const isOwner = currentUser.staff?.id
   && currentUser.staff?.id === currentUser.tenant?.ownerStaffId;
  const showWelcomeModal = isOwner && currentUser.tenant?.status === 'welcome';

  const promptLiveGeo = useMemo(() => welcomeState.industry === 'Drilling & Geotechnical'
    && !!subscriptionPrices?.powerUps?.find((p) => p.powerUpId === PowerUpId.liveGeo),
  [welcomeState.industry, subscriptionPrices]);

  useEffect(() => {
    dispatch(WelcomeModalActions.update({
      update: {
        enableLiveGeo: promptLiveGeo,
      },
    }));
  }, [promptLiveGeo]);

  const steps = [
    'welcome',
    'problemsToSolve',
    ...(promptLiveGeo ? ['liveGeo'] : []),
    'teamAndJobs',
    'quoteSettings',
    'financialSoftware',
  ];

  if (!showWelcomeModal || !steps[currentStep]) {
    return <></>;
  }

  return (
    <Modal
      isOpen
      onClose={() => {}}
    >
      <ModalOverlay />
      <ModalContent
        w="1000px"
        maxW="1000px"
        minW="1000px"
        minH="63rem"
      >
        <Step
          currentStep={steps[currentStep]}
          stepNumber={currentStep + 1}
          totalSteps={steps.length}
        />
      </ModalContent>
    </Modal>
  );
};
export default WelcomeModal;
