import { extendTheme, withDefaultSize } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';
import Color from 'tinycolor2';
import { AccordionStyles } from '~/theme/components/accordion';
import { ButtonStyles } from '~/theme/components/button';
import { CheckboxStyles } from '~/theme/components/checkbox';
import { InputComponentStyles } from '~/theme/components/inputs';
import { LinkStyles } from '~/theme/components/link';
import { MenuStyles } from '~/theme/components/menu';
import { ModalStyles } from '~/theme/components/modal';
import { PopoverStyles } from '~/theme/components/popover';
import { RadioStyles } from '~/theme/components/radio';
import { SwitchStyles } from '~/theme/components/switch';
import { TableStyles } from '~/theme/components/table';
import { TooltipStyles } from '~/theme/components/tooltip';
import { FORM_CONTROL_FOCUS_BORDER_COLOUR, MAGNETIZE_COLOURS } from '~/theme/constants';

// This is like Chakra's transparentize but doesn't require the theme (so we can
// use it in the base styles)
export const makeTransparent = (cssColour: string, opacity: number) => (
  Color(cssColour).setAlpha(opacity).toRgbString()
);

const breakpoints = createBreakpoints({
  sm: '320px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
  '2xl': '1400px',
  '3xl': '1600px',
});

export default extendTheme({
  breakpoints,
  styles: {
    global: {
      '.pac-container': {
        // Google Places Autocomplete uses its own overlay system and displays
        // the dropdown outside the parent element. Unfortunately this means it
        // appear below a Chakra modal.
        zIndex: 'modal',
        boxShadow: 'sm',
        border: '1px',
        borderColor: 'inherit',
        borderRadius: 'md',
      },
      html: {
        fontSize: '12px',
      },

      body: {
        backgroundColor: 'gray.50',
      },

      '@media print': {
        '@page': {
          size: 'A4',
          margin: '30mm 20mm',
        },

        html: {
          fontSize: '12px',

          // This is a bit of a hack to get the quotes printing nicely in Chrome
          // when you use Ctrl + P. They come out tiny.
          //
          // Chrome calculates the page dimensions and scale factor before the
          // layout changes have been fully applied, but this only happens with
          // JS based styling.
          //
          // https://bugs.chromium.org/p/chromium/issues/detail?id=1205201&q=component%3AInternals%3EPrinting%20scale&can=2
          //
          // This works correctly in Safari (unfortunately 150% will embiggen
          // it), and, we override this zoom when printing using react-to-print
          // (e.g. click the print button on the page rather than using
          // File+Print or Cmd+P)
          zoom: '150%',
        },

        'html, body': {
          backgroundColor: 'transparent',
          height: 'initial',
          width: 'initial',
        },

        table: { pageBreakfter: 'auto' },
        'tr, td': { pageBreakInside: 'avoid', pageBreakAfter: 'auto' },
        thead: { display: 'table-header-group' },

        'table > thead > tr > th': {
          borderBottomColor: '#ccc !important',
          borderBottomWidth: '1pt !important',
        },
        'table > tbody > tr > td': {
          borderBottomColor: '#ddd !important',
          borderBottomWidth: '1pt !important',
        },
      },
    },
  },

  colors: {
    brand: {
      100: MAGNETIZE_COLOURS['brand-1'],
      200: MAGNETIZE_COLOURS['brand-2'],
      300: MAGNETIZE_COLOURS['brand-4'],

      // I'm not sure what colour this is in the Magnetize product design kit.
      400: '#217932',

      500: MAGNETIZE_COLOURS['brand-3'],
    },

    brandDark: {
      50: MAGNETIZE_COLOURS['ui-3'],
      100: MAGNETIZE_COLOURS['ui-4'],

      500: MAGNETIZE_COLOURS['text-2'],
      600: MAGNETIZE_COLOURS['text-1'],
    },

    brandGreen: {
      500: MAGNETIZE_COLOURS['brand-4'],
    },

    // Overriding the green colour scale. This was generated by
    // https://themera.vercel.app/ but I've shifted some of the
    // values around to center it on the brand-4 green.
    green: {
      50: '#ECF9EF',
      // Dropping the generated 100 out of this scale and shifting
      // everything else up up.
      100: '#A8E1B4',
      200: '#85D597',
      300: '#63CA79',
      400: '#41BE5C',
      500: MAGNETIZE_COLOURS['brand-4'],
      600: '#277237',
      700: '#1A4C25',
      800: '#0D2612',
      900: '#0D2612', // Duplicate, but we likely never use this.
    },

    magnetize: MAGNETIZE_COLOURS,

    // This should override 'black' to be our darkest theme colour,
    // which reflects the way it was being used; and makes it synonymous
    // with gray.900 (based on the mappings below).
    black: MAGNETIZE_COLOURS['text-1'],

    gray: {
      // The magnetize brand palette doesn't give us a full gray scale, and
      // Chakra's defaults are a bit too cool to match. I've used
      // https://themera.vercel.app/ to generate some grays based on a colour
      // closer to the magnetize palette which we can use as a basis, then
      // override the specific entries. Themera is specifically designed for
      // Chakra so the output can just be dumped in here
      ...{
        50: '#F2F2F3',
        100: '#DBDADC',
        200: '#C3C3C6',
        300: '#ACABB0',
        400: '#959499',
        500: '#7D7C83',
        600: '#646369',
        700: '#4B4A4F',
        800: '#323234',
        900: '#19191A',
      },

      50: MAGNETIZE_COLOURS['ui-2'],
      100: MAGNETIZE_COLOURS['ui-3'],
      200: MAGNETIZE_COLOURS['ui-4'],

      400: MAGNETIZE_COLOURS['text-4'],

      700: MAGNETIZE_COLOURS['text-3'],

      // gray.800 is the default body colour
      800: MAGNETIZE_COLOURS['text-2'],
      900: MAGNETIZE_COLOURS['text-1'],
    },

  },
  fonts: {
    heading: 'Poppins, sans-serif',
    body: '"Open Sans", sans-serif',
    mono: '"Open Sans", sans-serif',
  },

  radii: {
    xs: '0.0625rem',
    sm: '0.165rem',
  },

  shadows: {
    base: '0 1px 2px 0 rgba(0, 0, 0, 0.1)',
    top: '0 -1px 2px rgba(0, 0, 0, 0.1)',
    bottom: '0 1px 2px rgba(0, 0, 0, 0.1)',
    lg: '0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 50px 40px 50px rgba(68, 68, 68, 0.05)',

    // Chakra uses a boxshadow rather than the CSS outline- properties to style
    // the focused element by default.
    outline: `0 0 0 3px ${makeTransparent(FORM_CONTROL_FOCUS_BORDER_COLOUR, 0.6)}`,
    outlineSelection: `0 0 0 1px white, 0 0 0 3px ${FORM_CONTROL_FOCUS_BORDER_COLOUR}`,
  },

  components: {
    Accordion: AccordionStyles,
    Button: ButtonStyles,
    Checkbox: CheckboxStyles,
    Link: LinkStyles,
    Menu: MenuStyles,
    Modal: ModalStyles,
    Popover: PopoverStyles,
    Radio: RadioStyles,
    Switch: SwitchStyles,
    Table: TableStyles,
    Tooltip: TooltipStyles,
    ...InputComponentStyles,

    Heading: {
      sizes: {
        xl: {
          textTransform: 'uppercase',
        },

        lg: {
          // This is like our 'h2' heading at the top of the job page.
        },

        md: {
          textTransform: 'uppercase',
        },

        sm: {
          fontFamily: '"Open Sans", sans-serif',
          textTransform: 'uppercase',
          fontWeight: '600',
        },

        xs: {
          fontFamily: '"Open Sans", sans-serif',
          fontWeight: '600',
        },
      },
      variants: {
        section: {
          textTransform: 'none',
        },
      },
    },

    Text: {
      sizes: {
        xl: {
          fontSize: '1.5rem',
        },
        lg: {
          fontSize: '1.167rem',
        },
      },
    },

    FormLabel: {
      baseStyle: {
        textTransform: 'uppercase',
        fontWeight: 600,
      },
    },
    Form: {
      baseStyle: {
        requiredIndicator: {
          color: 'inherit',
        },
      },
    },

    Skeleton: {
      baseStyle: {
        opacity: 0.4,
      },
    },

    CardTitle: {
      baseStyle: {
        px: '8',
        mx: '-8',
        mb: '4',
      },

      variants: {
        default: {},
        'magnetize-icon': {
          pb: '4',
          mt: '-4',
        },
      },
    },
    Tabs: {
      baseStyle: {
        tab: {
          color: 'magnetize.text-3',
          _hover: {
            color: 'magnetize.text-1',
          },
        },
      },
    },
  },
},
withDefaultSize({
  size: 'lg',
  components: ['Input', 'Select', 'Textarea', 'NumberInput'],
}));
