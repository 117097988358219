import {
  Box, BoxProps, Center, Circle, Grid, GridItem, Stack, Text,
} from '@chakra-ui/react';
import format from 'date-fns/format';
import React from 'react';
import SlideIn from '~/components/SlideIn';
import StaffAvatar from '~/components/StaffAvatar';
import UserAvatar from '~/components/UserAvatar';
import { JobTimelineItemCommon } from '~/types/job';

export interface TimelineItemProps {
  event?: JobTimelineItemCommon;
  first?: boolean;
  last?: boolean;
  timestamp?: number | Date;
  topSpacing?: BoxProps['marginTop'];
  bottomSpacing?: BoxProps['marginTop'];
  animateIn?: boolean;
}

interface TimelineItemRenderProps {
  dot?: React.ReactNode;
  children?: React.ReactNode;
}

const TIMELINE_ITEM_AREAS = `
  "head head head"
  "date dot content"
  "date dot content"
  "empty empty content"
  "foot foot foot"
`;

const TimelineItem = ({
  event, dot, first, last, children, topSpacing = '8', bottomSpacing = '8',
  animateIn: animateEntrance = false,
}: TimelineItemProps & TimelineItemRenderProps) => {
  let dotComponent = dot;
  if (!dotComponent) {
    if (event.staffId) {
      dotComponent = <StaffAvatar staffId={event.staffId} />;
    } else if (event.user) {
      dotComponent = <UserAvatar name={event.user} />;
    } else {
      dotComponent = <Circle size="10px" bg="magnetize.text-2" />;
    }
  }
  return (
    <SlideIn animateEntrance={animateEntrance}>
      <Grid gridTemplateAreas={TIMELINE_ITEM_AREAS} gridTemplateColumns="80px 60px 1fr">
        {!first && (
        <GridItem minWidth="0" gridRow="1 / 3" colStart={2} colSpan={1}>
          <Center height="100%">
            <Box width="1px" bg="gray.200" height="100%" />
          </Center>
        </GridItem>
        )}
        {!last && (
        <GridItem minWidth="0" gridRow="3 / 6" colStart={2} colSpan={1}>
          <Center height="100%">
            <Box width="1px" bg="gray.200" height="100%" />
          </Center>
        </GridItem>
        )}
        {!first && <GridItem minWidth="0" gridArea="head" minHeight={topSpacing} />}
        <GridItem minWidth="0" gridArea="date" textAlign="right" alignSelf="center">
          {event?.at && (
          <Stack fontSize="12px" spacing={0}>
            <Text>{format(event.at, 'd MMM yyyy')}</Text>
            <Text>{format(event.at, 'h:mmaaa')}</Text>
          </Stack>
          )}
        </GridItem>
        <GridItem minWidth="0" gridArea="dot">
          <Center>
            {dotComponent}
          </Center>
        </GridItem>
        <GridItem minWidth="0" gridArea="content">{children}</GridItem>
        {!last && <GridItem minWidth="0" gridArea="foot" minHeight={bottomSpacing} />}
      </Grid>
    </SlideIn>

  );
};

export default TimelineItem;
