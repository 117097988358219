import {
  Button, Flex, FormControl, FormLabel, Grid, HStack, Box,
} from '@chakra-ui/react';
import { isEqual } from 'lodash';
import React, { useMemo, useState, useEffect } from 'react';
import Holidays from 'date-holidays';
import useDebouncedState from '~/hooks/useDebouncedState';
import OrgSettingsFileUpload from '~/components/FileUpload/OrgSettingsFileUpload';
import JobTagsTable from '~/components/JobTagsTable';
import SearchSelect from '~/components/SearchSelect';
import { Tenant } from '~/types/tenant';
import JobSchedulingWorkWeek from '~/components/JobSchedulingWorkWeek';

interface JobSettingsTabProps {
  tenant: Tenant;
  isSaving: boolean;
  onSave: (tenant: Tenant) => void;
}

const JobSettingsTab = ({ tenant, isSaving, onSave } : JobSettingsTabProps) => {
  const [jobAttachments, setJobAttachments] = useState(
    tenant.jobAttachments ?? [],
  );
  const [publicHolidaysCountry, setPublicHolidaysCountry] = useState(tenant.publicHolidaysCountry);
  const [publicHolidaysState, setPublicHolidaysState] = useState(tenant.publicHolidaysState);
  const [schedulingWeekDays, setSchedulingWeekDays] = useDebouncedState({
    value: tenant.schedulingWeekDays,
    dispatchUpdate: () => {
      if (!isEqual(tenant.schedulingWeekDays, schedulingWeekDays)) {
        onSave({ ...tenant, schedulingWeekDays });
      }
    },
    delay: 100,
  });
  const canSave = !isEqual(jobAttachments, tenant.jobAttachments ?? [])
  || tenant.publicHolidaysCountry !== publicHolidaysCountry
  || tenant.publicHolidaysState !== publicHolidaysState
  || tenant.schedulingWeekDays !== schedulingWeekDays;

  useEffect(() => {
    if (!isEqual(tenant.schedulingWeekDays, schedulingWeekDays)) {
      onSave({ ...tenant, schedulingWeekDays });
    }
  }, [schedulingWeekDays]);

  const save = () => {
    onSave({
      ...tenant,
      jobAttachments,
      publicHolidaysCountry,
      publicHolidaysState,
      schedulingWeekDays,
    });
  };

  const holidaysCountries = useMemo(() => {
    const countries = new Holidays().getCountries();
    return Object.entries(countries).map(([code, country]) => ({
      value: code,
      label: country,
    }));
  }, []);

  const holidaysStates = useMemo(() => {
    if (!publicHolidaysCountry) return [];
    const states = new Holidays().getStates(publicHolidaysCountry);
    if (!states) return [];
    return Object.entries(states).map(([code, state]) => ({
      value: code,
      label: state,
    }));
  }, [publicHolidaysCountry]);

  return (
    <Grid gap={6}>
      <FormControl mt={10}>
        <JobSchedulingWorkWeek
          value={schedulingWeekDays}
          onChange={(value) => {
            setSchedulingWeekDays(value);
          }}
        />
      </FormControl>
      <JobTagsTable />
      <FormControl mt={10}>
        <FormLabel>Public Holidays</FormLabel>
        <HStack>
          <Box flex={1}>
            <SearchSelect
              placeholder="Select Country"
              hideSearchIcon
              options={holidaysCountries}
              value={publicHolidaysCountry
                ? holidaysCountries.find((c) => c.value === publicHolidaysCountry)
                : null}
              onChange={(value) => {
                setPublicHolidaysCountry(value?.value);
                setPublicHolidaysState(null);
              }}
            />
          </Box>
          <Box flex={1}>
            {!(publicHolidaysCountry && holidaysStates.length === 0) && (
            <SearchSelect
              hideSearchIcon
              placeholder="Select State"
              options={holidaysStates}
              isDisabled={!publicHolidaysCountry}
              value={publicHolidaysState
                ? holidaysStates.find((c) => c.value === publicHolidaysState)
                : null}
              onChange={(value) => setPublicHolidaysState(value?.value)}
            />
            )}
          </Box>
          <Box flex={1} />
        </HStack>
      </FormControl>

      <FormControl mt={10}>
        <FormLabel>Job Files</FormLabel>
        <Flex direction="column">
          <OrgSettingsFileUpload
            onFileUploaded={(file) => {
              setJobAttachments([...jobAttachments, file]);
            }}
            onFileRemoved={(file) => {
              const { s3Key } = file;
              const filtered = jobAttachments.filter((f) => f.s3Key !== s3Key);
              setJobAttachments(filtered);
            }}
            files={jobAttachments}
          />
        </Flex>
      </FormControl>
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
        mt={4}
      >
        <Button
          disabled={isSaving || !canSave}
          onClick={save}
          size="lg"
        >
          Save
        </Button>
      </Flex>
    </Grid>
  );
};

export default JobSettingsTab;
