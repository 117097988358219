import React from 'react';
import {
  HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ListItem,
  ModalHeader, ModalOverlay, Text, VStack, Image, Button, Stack, Link, Heading, OrderedList, Box,
} from '@chakra-ui/react';
import GreenButton from '~/components/GreenButton';
import { useAppSelector } from '~/redux/store';
import { XeroStatus } from '~/types/xero';
import { magnetizeDocsUrl } from '~/helpers/config';
import { PowerUpId } from '~/types/subscription';

export const ActivateXeroModal = ({
  onCancel, xeroStatus,
}: {
  xeroStatus: XeroStatus,
  onCancel: () => void,
}) => {
  // Will be loaded by parent
  const prices = useAppSelector((state) => state.subscription.prices);
  const powerUp = prices.powerUps.find((p) => p.powerUpId === PowerUpId.xero);

  const connectToXero = () => {
    window.location.href = xeroStatus?.consentUrl;
  };

  return (
    <Modal isOpen onClose={onCancel} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader paddingBottom={5}>
          <HStack>
            <Image src={powerUp.image} height="5.5rem" mr={2} />
            <VStack textAlign="left" alignItems="flex-start">
              <Text margin={0} fontWeight="bold">Xero</Text>
              <Text marginTop="0 !important" fontSize={14}>Connect to you Xero account for easy invoicing</Text>
            </VStack>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody paddingBottom={16}>
          <Stack spacing="8">
            <Text fontSize={14}>
              If you use Xero accounting software, you can automatically send
              invoices from Magnetize to Xero, ready to be billed! Too easy!
            </Text>
            <Heading size="sm" textTransform="none" mb="4">Connect to Xero to send invoices.</Heading>

            <Box>
              <Text>Steps: </Text>
              <OrderedList pl="4" spacing="1" maxW="40rem">
                <ListItem>Click &lsquo;Connect to Xero&rsquo;</ListItem>
                <ListItem>Login to your Xero account</ListItem>
                <ListItem>
                  Select the Xero organisation and authorize the integration
                </ListItem>
                <ListItem>
                  Match Magnetize to the correct sales account(s) in Xero
                </ListItem>
              </OrderedList>
            </Box>
            <Text>
              <strong>Need help?</strong>
              {' Read the '}
              <Link
                href={`${magnetizeDocsUrl}article/9-connect-to-xero`}
                target="_blank"
                color="magnetize.brand-4"
              >
                support article
              </Link>
              .
            </Text>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <GreenButton
            size="lg"
            onClick={() => {
              connectToXero();
            }}
          >
            Connect to Xero
          </GreenButton>
          <Button variant="ghost" onClick={onCancel}>
            cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
