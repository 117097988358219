import React from 'react';
import { DeactivatePowerUpModal } from '~/pages/TenantSettings/PowerUpsTab/DeactivatePowerUpModal';
import { PowerUpId } from '~/types/subscription';

export const DeactivateXeroModal = ({
  onCancel, onDeactivated,
}: {
  onCancel: () => void,
  onDeactivated: () => void
}) => (
  <DeactivatePowerUpModal
    onCancel={onCancel}
    onDeactivated={onDeactivated}
    powerUpId={PowerUpId.xero}
    powerUpName="Xero"
    deactivateMeansReasons={[
      'The Xero connection to Magnetize will be disconnected',
      'You will not be able to send invoices to Xero',
    ]}
  />
);
