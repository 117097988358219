import { keyBy } from 'lodash';
import { all, call, takeEvery } from 'redux-saga/effects';
import { formatJobCode, formatJobName } from '~/helpers/job';
import { JobActions } from '~/redux/jobs/actions';
import { Customer } from '~/types/customer';
import { CustomerContact } from '~/types/customerContact';
import { JobStatusInfo, Job } from '~/types/job';
import { getIsoDate } from '~/utils/calendarHelpers';
import { exportToSpreadsheet } from '~/utils/exportToSpreadsheet';
import fetchJson from '~/utils/fetchJson';
import formatAddress from '~/utils/formatAddress';
import { performTrackedSaga } from '~/utils/performTrackedSaga';

const watchExportJobs = takeEvery(
  JobActions.exportJobs,
  function* handler(action) {
    const { format } = action.payload.data;
    yield performTrackedSaga({
      key: action.payload.id,
      * saga() {
        const [jobs, customers, contacts]:
        [Job[], Customer[], CustomerContact[]] = yield all([
          call(() => fetchJson<Job[]>('/api/jobs')),
          call(() => fetchJson<Customer[]>('/api/customers')),
          call(() => fetchJson<CustomerContact[]>('/api/contacts')),
        ]);

        const customersById = keyBy(customers, (c) => c.id);
        const customerContactsById = keyBy(contacts, (c) => c.id);

        yield call(() => exportToSpreadsheet({
          name: `Jobs-${getIsoDate(Date.now())}`,
          format,
          sheets: [{
            headers: [
              'Code', 'Status',
              'Address', 'Job Name',
              'Customer', 'Customer Phone', 'Customer Email', 'Customer Address',
              'Job Contact Name', 'Job Contact Phone', 'Job Contact Email',
              'Site Contact Name', 'Site Contact Phone', 'Site Contact Email'],
            columnWidths: [
              10, 20,
              50, 50,
              40, 20, 40, 50,
              40, 20, 40,
              40, 20, 40,
            ],
            data: jobs
              .filter((job) => job.status !== 'draft' && job.status !== 'archived')
              .map((job) => {
                const customer = customersById[job.customerId];
                const jobContact = customerContactsById[job?.jobContactId];
                const siteContact = customerContactsById[job?.siteContactId];
                const customerMainContact = customerContactsById[customer?.mainContactId];

                return [
                  formatJobCode(job.code),
                  JobStatusInfo[job.status]?.text ?? '',
                  formatAddress(job.siteAddress) ?? '',
                  formatJobName(job),
                  customer?.companyName,
                  customerMainContact?.phone,
                  customerMainContact?.email,
                  formatAddress(customer?.companyAddress),
                  jobContact?.name,
                  jobContact?.phone,
                  jobContact?.email,
                  siteContact?.name,
                  siteContact?.phone,
                  siteContact?.email,
                ];
              }),
          }],
        }));
      },
    });
  },
);

export default watchExportJobs;
