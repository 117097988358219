import { Text, TextProps } from '@chakra-ui/react';
import React from 'react';

interface CollapsibleTextProps extends TextProps {
  isOpen: boolean;
}

const CollapsibleText = ({ isOpen, children, ...props } : CollapsibleTextProps) => (
  <Text
    fontSize="md"
    opacity={isOpen ? 1 : 0}
    width={isOpen ? '100%' : '0%'}
    textOverflow="clip"
    transition="all 0.5s"
    overflow="hidden"
    whiteSpace="nowrap"
    {...props}
  >
    {children}
  </Text>
);

export default CollapsibleText;
