import { createAction } from '@reduxjs/toolkit';

export const TrackedActions = {
  started: createAction<{ trackId: string }>('tracked/started'),
  errored: createAction<{ trackId: string }>('tracked/errored'),

  completed: createAction<{ trackId: string, }>('tracked/completed'),

  setResult: createAction<{ trackId: string, result: any }>('tracked/setResult'),
};
