import { createReducer } from '@reduxjs/toolkit';
import SubscriptionActions from '~/redux/subscription/actions';
import {
  SubscriptionPrices,
  SubscriptionPaymentMethod,
  SubscriptionInvoice,
} from '~/types/subscription';

interface SubscriptionState {
  prices: SubscriptionPrices;
  paymentMethod: SubscriptionPaymentMethod;
  invoices: SubscriptionInvoice[];
}

export default createReducer<SubscriptionState>({
  prices: null,
  paymentMethod: null,
  invoices: [],
} as SubscriptionState, (builder) => (builder
  .addCase(SubscriptionActions.pricesUpdated, (state, action) => {
    const { prices } = action.payload;
    return ({
      ...state,
      prices,
    });
  })
  .addCase(SubscriptionActions.powerUpAdded, (state, action) => {
    const { powerUpId } = action.payload.data;
    return ({
      ...state,
      prices: {
        ...state.prices,
        powerUps: state.prices.powerUps.map((p) => ({
          ...p,
          isActive: p.powerUpId === powerUpId ? true : p.isActive,
        })),
      },
    });
  })
  .addCase(SubscriptionActions.powerUpRemoved, (state, action) => {
    const { powerUpId } = action.payload.data;
    return ({
      ...state,
      prices: state.prices?.powerUps ? {
        ...state.prices,
        powerUps: state.prices.powerUps.map((p) => ({
          ...p,
          isActive: p.powerUpId === powerUpId ? false : p.isActive,
        })),
      } : state.prices,
    });
  })
  .addCase(SubscriptionActions.paymentMethodUpdated, (state, action) => {
    const { paymentMethod } = action.payload;
    return ({
      ...state,
      paymentMethod,
    });
  })
  .addCase(SubscriptionActions.invoicesUpdated, (state, action) => {
    const { invoices } = action.payload;
    return ({
      ...state,
      invoices,
    });
  })));
