import { CloseIcon } from '@chakra-ui/icons';
import {
  Box, Button, Flex, Icon, Spacer, useOutsideClick,
} from '@chakra-ui/react';
import React, { useCallback, useState } from 'react';

interface RenderValueProps<T> {
  value: T;
  startEditing: () => void;
}

interface RenderEditorProps<T> {
  value: T;
  onChange: (value: T) => void;
  finishEditing: () => void;
  discardEdits: () => void;
}

interface EditContainerProps<T = unknown> {
  value?: T;
  onChange?: (value: T) => void;
  showRemoveButton?: boolean;
  onRemove?: () => void;
  initiallyEditing?: boolean;
  startEditingOnClick?: boolean;
  renderValue: (props: RenderValueProps<T>) => React.ReactNode;
  renderEditor: (props: RenderEditorProps<T>) => React.ReactNode;
  validator?: (value: T) => boolean;
  hideControls?: boolean;
  onEditingChange?: (isEditing: boolean) => void;
}

/**
 * Provides a Trello-style click-to-edit container.
 *
 * The rendering is done via the renderValue and renderEditor render props.
 * These are provided the value (and onChange for the editor) as props.
 *
 * This edit container will track the local state, and only call the upstream
 * onChange when focus leaves the element, a click occurs outside, or the focus
 * leaves the edit area.
 *
 */
function EditContainer<T>({
  value,
  onChange = () => {},
  initiallyEditing = false,
  startEditingOnClick = true,
  renderValue, renderEditor,
  showRemoveButton, onRemove,
  validator = () => true,
  hideControls = false,
  onEditingChange,
}: EditContainerProps<T>) {
  const [isEditing, setIsEditingState] = useState(initiallyEditing);
  const [editValue, setEditValue] = useState(value);
  const editContainerRef = React.useRef(null);

  const setIsEditing = (editing: boolean) => {
    setIsEditingState(editing);
    if (isEditing !== editing) {
      onEditingChange?.(editing);
    }
  };

  const startEditing = useCallback(() => {
    setEditValue(value);
    setIsEditing(true);
  }, [value]);

  const onViewClicked = useCallback(() => {
    if (startEditingOnClick) {
      startEditing();
    }
  }, [startEditing, startEditingOnClick]);

  const saveValue = useCallback(() => {
    if (isEditing && validator(editValue)) {
      onChange?.(editValue);
      setIsEditing(false);
    }
  }, [editValue, onChange, isEditing, validator]);

  const discardEdits = useCallback(() => {
    setIsEditing(false);
  }, []);

  useOutsideClick({
    ref: editContainerRef,
    handler: () => saveValue(),
  });

  const handleFocusLost = useCallback((e: React.FocusEvent) => {
    if (isEditing
      && !e.currentTarget.contains(e.relatedTarget as Node)
      && e.relatedTarget) {
      saveValue();
    }
  }, [isEditing, saveValue, validator, editValue]);

  if (!isEditing) {
    return (
      <Box onClick={onViewClicked}>
        {renderValue({ value, startEditing })}
      </Box>
    );
  }
  return (
    <Box ref={editContainerRef} onBlur={handleFocusLost}>
      {renderEditor({
        value: editValue,
        onChange: setEditValue,
        finishEditing: saveValue,
        discardEdits,
      })}
      {!hideControls && (
      <Flex mt={2} alignItems="center">
        <Button
          onClick={saveValue}
          disabled={!validator(editValue)}
          size="sm"
        >
          Save
        </Button>
        {showRemoveButton && (
          <>
            <Spacer />
            <Button
              onClick={() => { onRemove?.(); discardEdits(); }}
              variant="ghost"
              opacity="0.2"
              _hover={{ opacity: '0.5' }}
              size="xs"
              tabIndex={-1}
              leftIcon={<Icon as={CloseIcon} />}
            >
              Remove
            </Button>
          </>
        )}
      </Flex>
      )}
    </Box>
  );
}

export default EditContainer;
