import {
  Accordion,
  Flex, Heading, Icon, Text,
  SkeletonText, Spinner, useDisclosure,
} from '@chakra-ui/react';
import { CheckOutlined } from '@material-ui/icons';
import React from 'react';
import MagnetizeAccordionItem from '~/components/MagnetizeAccordionItem';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import { PowerUpsSearch } from './PowerUpsSearch';
import { XeroSettings } from './XeroSettings';
import { useAppSelector } from '~/redux/store';
import { XeroActions } from '~/redux/xero/actions';
import useTrackedAction from '~/hooks/useTrackedAction';
import SubscriptionActions from '~/redux/subscription/actions';
import { showSuccessToast } from '~/toast';
import { selectTenantPowerUps } from '~/redux/currentUser/selectors';
import { LiveGeoSettings } from '~/pages/TenantSettings/PowerUpsTab/LiveGeoSettings';
import { Tenant } from '~/types/tenant';
import { ActivateLiveGeoModal } from '~/pages/TenantSettings/PowerUpsTab/LiveGeoSettings/ActivateLiveGeoModal';
import { ActivateXeroModal } from '~/pages/TenantSettings/PowerUpsTab/XeroSettings/ActivateXeroModal';
import { PowerUpId } from '~/types/subscription';

const PowerUpsTab = ({ tenant, onSave }: { tenant: Tenant,
  onSave: (tenant: Tenant) => void; }) => {
  const {
    data: xeroStatus, isLoading, isError, errorMessage,
  } = useTrackedFetch({
    key: 'xero-status',
    trigger: () => XeroActions.refreshStatus(),
    selector: (state) => state.xero.status,
  });
  const { trigger: addPowerUp } = useTrackedAction({
    trigger: (powerUpId:PowerUpId) => SubscriptionActions.addPowerUp({ powerUpId }),
    onSuccess: () => {
      showSuccessToast('Power up added');
    },
  });
  const { data: subscriptionPrices } = useTrackedFetch({
    key: 'subscriptionPrices',
    trigger: () => SubscriptionActions.fetchPrices(),
    selector: (state) => state.subscription.prices,
  });
  const activateLiveGeoModalState = useDisclosure();
  const activateXeroModalState = useDisclosure();
  const [forceOpenTab, setForceOpenTab] = React.useState(undefined);

  const isConnecting = useAppSelector((s) => s.xero.isConnecting);
  const powerUps = useAppSelector(selectTenantPowerUps);

  const hasPowerUps = powerUps.xero || powerUps.geotechnical;

  const status = xeroStatus?.status ?? 'needs-reauth';

  const liveGeo = subscriptionPrices?.powerUps?.find((p) => p.powerUpId === PowerUpId.liveGeo);
  const xero = subscriptionPrices?.powerUps?.find((p) => p.powerUpId === PowerUpId.xero);

  return (
    <>
      {hasPowerUps ? (
        <>
          <Heading as="h3" size="sm" mb={3}>Your power ups</Heading>
          <Accordion defaultIndex={forceOpenTab} key={forceOpenTab} allowToggle>
            {powerUps.xero && (
            <MagnetizeAccordionItem
              heading="Xero"
              imageSrc={xero?.image || '/images/blank.png'}
              rightContent={(
                <>
                  {isConnecting && (
                  <Flex alignItems="center" color="magnetize.text-4">
                    <Spinner size="sm" mr="2" />
                    Connecting
                  </Flex>
                  )}
                  {!isConnecting && (status === 'ready' || status === 'needs-select-org') && (
                  <Flex alignItems="center" color="magnetize.brand-4">
                    <Icon as={CheckOutlined} mr="2" />
                    Connected
                  </Flex>
                  )}
                </>
        )}
            >
              {isLoading ? (
                <SkeletonText noOfLines={2} />
              ) : (
                <XeroSettings
                  xeroStatus={xeroStatus}
                  isError={isError}
                  errorMessage={errorMessage}
                />
              )}
            </MagnetizeAccordionItem>
            )}
            {powerUps.geotechnical && (
            <MagnetizeAccordionItem
              heading="Live geo"
              imageSrc={liveGeo?.image || '/images/blank.png'}
              rightContent={(
                <Flex alignItems="center" color="magnetize.brand-4">
                  <Icon as={CheckOutlined} mr="2" />
                  Enabled
                </Flex>
)}
            >
              <LiveGeoSettings tenant={tenant} onSave={onSave} />
            </MagnetizeAccordionItem>
            )}
          </Accordion>
        </>
      ) : (
        <>
          <Heading as="h3" size="md">Add Power Ups</Heading>
          <Text size="lg" maxW="420px" mt={4} mb={16}>
            Power up your Magnetize account to get the additional features
            you need to get your business humming.
          </Text>
        </>
      )}

      <Heading size="sm" mt={16} mb={3}>Discover Power Ups</Heading>
      <PowerUpsSearch onSelected={(powerUpId) => {
        if (powerUpId === 'live-geo') {
          if (powerUps.geotechnical) {
            setForceOpenTab(powerUps.xero ? 1 : 0);
          } else {
            activateLiveGeoModalState.onOpen();
          }
        } else if (powerUpId === 'xero') {
          if (powerUps.xero) {
            setForceOpenTab(0);
          } else {
            activateXeroModalState.onOpen();
          }
        } else {
          addPowerUp(powerUpId);
        }
      }}
      />
      {activateLiveGeoModalState.isOpen && (
      <ActivateLiveGeoModal
        onCancel={activateLiveGeoModalState.onClose}
        onActivated={activateLiveGeoModalState.onClose}
      />
      )}
      {activateXeroModalState.isOpen && (
      <ActivateXeroModal
        onCancel={activateXeroModalState.onClose}
        xeroStatus={xeroStatus}
      />
      )}

    </>
  );
};

export default PowerUpsTab;
