import {
  Center, Flex, Icon, Image, Spinner, Tooltip,
} from '@chakra-ui/react';
import { Cancel } from '@material-ui/icons';
import React, { useMemo, useState } from 'react';
import FileTypeIcon from '~/components/FileTypeIcon';
import UploadedFile from '~/types/uploaded-file';

interface ThumbnailProps {
  file: UploadedFile;
  isSelected?: boolean;
  isCircle?: boolean;
  imageWidth?: number;
  imageHeight?: number | 'auto';
  onClick?: (file: UploadedFile) => void;
  onRemove?: (file: UploadedFile) => void;
}

const Thumbnail = ({
  file, imageWidth = 120, imageHeight = imageWidth, onClick, onRemove, isSelected, isCircle = false,
}: ThumbnailProps): JSX.Element => {
  // In an ideal world, <img>'s srcset attribute pick the best size for the
  // picture based on the element size. UNFORTUNATELY that's not the world we
  // live in, and srcset only considers the "intrinsic size" of the image. If
  // you want to do responsive sizing, you also need to set the sizes attribute
  // which can contain media conditions to responsively size the <img> and use
  // srcset, but these must be absolute or viewport relative sizes.
  //
  // For our needs it would be perfect if flex/grid controlled the size and the
  // browser picked the right one, but that's not possible :angryface:
  const thumbnailSrc = useMemo(
    () => file.thumbUrl || file.url,
    [file.s3Key, file.fileName, imageWidth],
  );
  const [loadingStatus, setLoadingStatus] = useState('loading');
  const [showRemove, setShowRemove] = useState(false);

  const isLoading = loadingStatus === 'loading';

  const imageWidthPx = `${imageWidth}px`;
  const imageHeightPx = typeof imageHeight === 'number' ? `${imageHeight}px` : imageHeight;

  return (
    <Flex
      zIndex={isSelected ? '1' : undefined}
      onMouseEnter={() => {
        setShowRemove(true);
      }}
      onMouseLeave={() => {
        setShowRemove(false);
      }}
      cursor="pointer"
      position="relative"
    >
      {onRemove && showRemove && !isLoading && (
        <Tooltip hasArrow placement="top" label="Remove file">
          <Icon
            onClick={(e) => {
              e.stopPropagation();
              onRemove(file);
            }}
            zIndex="1"
            position="absolute"
            backgroundColor="white"
            borderRadius={isCircle ? '50%' : undefined}
            top={-2}
            right={-2}
            as={Cancel}
          />
        </Tooltip>
      )}

      <Flex
        bg="gray.100"
        borderColor={isSelected ? 'magnetize.brand-4' : undefined}
        borderWidth={isSelected ? 3 : 0}
        borderRadius={isCircle ? '50%' : undefined}
      >
        <Image
          onClick={(e) => {
            e.stopPropagation();
            onClick?.(file);
          }}
          src={thumbnailSrc}
          fit="scale-down"
          width={imageWidthPx}
          height={imageHeightPx}
          maxHeight={imageHeightPx}
          maxWidth={imageWidthPx}
          borderRadius={isCircle ? '50%' : undefined}
          onLoad={() => setLoadingStatus('ready')}
          onError={() => setLoadingStatus('error')}
          fallback={(
            <Center width={imageWidthPx} height={imageHeightPx}>
              {isLoading
                ? <Spinner color="brand.100" size="lg" />
                : (
                  <FileTypeIcon
                    contentType={file.contentType}
                    width="3em"
                    height="auto"
                    color="gray.800"
                  />
                )}
            </Center>
          )}
        />
      </Flex>
    </Flex>
  );
};

export default Thumbnail;
