import {
  Box, Menu, MenuButton, MenuList, Text, useDisclosure,
} from '@chakra-ui/react';
import {
  differenceInDays, eachDayOfInterval, getTime, isSameDay,
} from 'date-fns';
import { startOfDay } from 'date-fns/esm';
import { enNZ } from 'date-fns/locale';
import {
  flatMap, sortBy, uniq,
} from 'lodash';
import React, { useEffect, useState } from 'react';
import { Calendar } from 'react-nice-dates';
import 'react-nice-dates/build/style.css';
import { endOfDaySeconds } from '~/utils/calendarHelpers';
import './nice-dates.css';

interface CalendarDayPickerProps {
  minDate?: Date;
  maxDate?: Date;
  value: { start: number, end: number }[];
  onChange: (updatedValue: { start: number, end: number }[]) => void;
}

const CalendarDayPicker = ({
  value, onChange, minDate, maxDate,
} : CalendarDayPickerProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isEdited, setIsEdited] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);
  const selectedDaysCount = selectedDates.length;

  const modifiers = {
    selected: (date) => selectedDates.some((selectedDate) => isSameDay(selectedDate, date)),
  };

  const handleDayClick = (date) => {
    setIsEdited(true);
    const index = selectedDates.findIndex(
      (d) => isSameDay(d, date),
    );

    if (index === -1) {
      setSelectedDates([...selectedDates, date]);
    } else {
      const updated = selectedDates;
      updated.splice(index, 1);
      setSelectedDates([...updated]);
    }
  };

  const handleClose = () => {
    if (!isEdited) {
      return;
    }
    setIsEdited(false);

    const sorted = sortBy(selectedDates);
    const intervals = [];

    let start = null;
    for (let i = 0; i < sorted.length; i += 1) {
      const current = sorted[i];
      const next = sorted[i + 1] || sorted[i];
      if (start === null) {
        start = current;
      }

      const diff = differenceInDays(next, current);
      if (diff > 1) {
        intervals.push({
          start: getTime(startOfDay(start)),
          end: getTime(endOfDaySeconds(current)),
        });
        start = null;
      }

      if (i >= sorted.length - 2) {
        intervals.push({
          start: getTime(startOfDay(start ?? next)),
          end: getTime(endOfDaySeconds(next)),
        });
        break;
      }
    }

    onChange(intervals);
  };

  useEffect(() => {
    const days = uniq(
      flatMap(
        value,
        (interval) => {
          const intervalDays = eachDayOfInterval(interval);
          return intervalDays;
        },
      ),
    );
    setSelectedDates(days);
  }, [value]);

  return (
    <Menu
      flip={false}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={() => {
        onClose();
        handleClose();
      }}
    >
      <MenuButton
        cursor="pointer"
        _hover={{ backgroundColor: 'magnetize.ui-2', borderTopRadius: 2 }}
        _active={{ borderBottomWidth: 2, borderColor: 'magnetize.brand-4' }}
        mr={3}
      >
        <Text
          p={2}
          fontWeight="semibold"
          borderBottomColor={isOpen ? 'magnetize.brand-4' : undefined}
          backgroundColor={isOpen ? 'gray.50' : undefined}
          color="magnetize.brand-4"
        >
          {`${selectedDaysCount} day${selectedDaysCount > 1 ? 's' : ''}`}
        </Text>
      </MenuButton>
      <MenuList p={0} borderRadius="sm" boxShadow="lg">
        <Box w={210} h={260}>
          {/* <DayPicker
            showOutsideDays
            disabledDays={(d) => {
              if (!minDate || !maxDate) {
                return false;
              }
              return !isWithinInterval(d, { start: minDate, end: maxDate });
            }}
            selectedDays={selectedDays}
            onDayClick={handleDayClick}
          /> */}
          <Calendar
            minimumDate={minDate}
            maximumDate={maxDate}
            onDayClick={handleDayClick}
            modifiers={modifiers}
            locale={{
              ...enNZ,
              localize: {
                ...enNZ.localize,
                day: (d) => {
                  switch (d) {
                    case 0: return 'Su';
                    case 1: return 'Mo';
                    case 2: return 'Tu';
                    case 3: return 'We';
                    case 4: return 'Th';
                    case 5: return 'Fr';
                    case 6: return 'Sa';
                    default: return '';
                  }
                },
              },
            }}
          />
        </Box>
      </MenuList>
    </Menu>

  );
};

export default CalendarDayPicker;
