import {
  Box,
  Flex, FormControl, Heading, Text, VStack, Image, Button,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import NextButton from '~/pages/WelcomeModal/components/NextButton';
import ProblemsSelector from '~/pages/WelcomeModal/components/ProblemsSelector';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import WelcomeModalActions from '~/redux/welcomeModal/actions';
import { selectWelcomeModalState } from '~/redux/welcomeModal/selectors';

const ProblemsToSolve = (
  { totalSteps, stepNumber }
  : { totalSteps: number, stepNumber: number },
) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const welcomeState = useAppSelector((state) => selectWelcomeModalState(state));

  return (
    <Flex
      h="100%"
      flex={1}
    >
      <Flex
        direction="column"
        flex={1}
      >
        <VStack
          padding={20}
          alignItems="start"
          spacing={8}
          flex={1}
        >
          <Flex display="column">
            <Heading textTransform="none">What are you looking for?</Heading>
          </Flex>
          <FormControl>
            <Text mb={1}>
              What do you need a hand with?
              <b> Click on all that apply</b>
            </Text>
            <ProblemsSelector
              value={welcomeState.problems}
              otherValue={welcomeState.otherProblems}
              onChange={(problems, otherProblems) => {
                dispatch(WelcomeModalActions.update({
                  update: {
                    problems,
                    otherProblems,
                  },
                }));
              }}
            />
          </FormControl>
          <Box flex={1} />
          <FormControl>
            <NextButton />
          </FormControl>
          <Text color="magnetize.text-4" mt={8} width="100%" textAlign="center">
            {`step ${stepNumber} of ${totalSteps}`}
          </Text>
        </VStack>
      </Flex>
      <Flex
        flex={1}
        backgroundImage="url('/images/login-bg.jpg')"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        alignItems="flex-end"
        justifyContent="center"
      >
        <Button
          position="absolute"
          top="20px"
          right="20px"
          isLoading={loading}
          isDisabled={loading}
          onClick={() => {
            setLoading(true);
            dispatch(WelcomeModalActions.complete());
          }}
        >
          Skip all
        </Button>
        <Image
          src="/images/welcomeModal/jobs.png"
        />
      </Flex>
    </Flex>
  );
};

export default ProblemsToSolve;
