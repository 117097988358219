import { createReducer } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';
import PriceBundleActions from '~/redux/priceBundle/actions';
import { Dictionary } from '~/types/helpers';
import { PriceBundle } from '~/types/priceBundle';

interface PriceBundleState {
  entities: Dictionary<PriceBundle>;
}

const priceBundleReducer = createReducer<PriceBundleState>({
  entities: {},
}, (builder) => {
  builder.addCase(PriceBundleActions.updated, (state, action) => {
    const { priceBundles } = action.payload;
    return ({
      entities: {
        ...state.entities,
        ...keyBy(priceBundles, (p) => p.id),
      },
    });
  });
});

export default priceBundleReducer;
