import {
  UnorderedList, Heading, Text, ListItem, Link, Box, Container,
} from '@chakra-ui/react';
import React from 'react';
import OneColumnBottomBrand from '~/layouts/OneColumnBottomBrand';
import { ReactComponent as LogoFullDark } from '~/assets/logo-full-dark.svg';

const PrivacyPolicy = () => (
  <OneColumnBottomBrand>
    <Container maxWidth="800px" paddingTop={20}>
      <LogoFullDark width={300} />
      <Heading mt={5}>PRIVACY POLICY</Heading>
      <Text color="gray.700" mb={10}>
        <b>Last update: </b>
        14 October 2021
      </Text>
      <Text>
        Welcome! At Magnetize, we understand that protecting your personal
        information is important. This Privacy Policy sets out our commitment to
        protecting the privacy of personal information provided to us, or otherwise
        collected by us when providing our business management software services
        (
        <b>Services</b>
        ) to you. In this Privacy Policy
        <b> we</b>
        ,
        <b> us </b>
        or
        <b> our </b>
        means Magnetize Ltd NZBN 94290 4671 4077.
      </Text>
      <Heading variant="section" size="lg" mt={8} mb={2}>Personal information</Heading>
      <Text>
        <b>Personal information </b>
        is information or an opinion, whether true or not and whether recorded in a
        material form or not, about an individual who is identified or reasonably identifiable.
      </Text>
      <Heading variant="section" size="lg" mt={8} mb={2}>The personal information we collect</Heading>
      <Text mb={2}>
        The types of personal information we may collect about you include:
      </Text>
      <Text mb={2} mt={2}>
        <u>
          As a business contact for a client of ours (
          <b>Business Contact</b>
          ):
        </u>
      </Text>
      <UnorderedList>
        <ListItem ml={4} mb={1}>your name;</ListItem>
        <ListItem ml={4} mb={1}>
          your contact details, including email address and/or telephone number;
        </ListItem>
        <ListItem ml={4} mb={1}>
          your credit card or payment details (through our third party payment processor); and
        </ListItem>
        <ListItem ml={4} mb={1}>the organisation you work for.</ListItem>
      </UnorderedList>
      <Text mb={2} mt={2}>
        <u>
          As a user of our software (
          <b>User</b>
          ):

        </u>
      </Text>
      <UnorderedList>
        <ListItem ml={4} mb={1}>your name;</ListItem>
        <ListItem ml={4} mb={1}>your login details, including your email address; and</ListItem>
        <ListItem ml={4} mb={1}>
          images of you if you choose to upload a profile picture.
        </ListItem>
      </UnorderedList>
      <Text mb={2} mt={2}>
        <u>As a Business Contact, User or visitor of our website:</u>
      </Text>
      <UnorderedList>
        <ListItem ml={4} mb={1}>
          your browser session and geo-location data, device and network information,
          statistics on page views and sessions, acquisition sources,
          search queries and/or browsing behaviour;
        </ListItem>
        <ListItem ml={4} mb={1}>
          information about your access and use of our Services, your communications with our
          online Services, the type of browser you are using, the type of operating system
          you are using and the domain name of your Internet service provider;
        </ListItem>
        <ListItem ml={4} mb={1}>
          details of services we have provided to you and/or that you have enquired about,
          and our response to you; and
        </ListItem>
        <ListItem ml={4} mb={1}>
          additional personal information that you provide to us, directly or indirectly,
          through your use of our Services and/or associated applications from which you
          permit us to collect information.
        </ListItem>
      </UnorderedList>
      <Text mt={4}>
        <b>Client data: </b>
        In providing Services to our clients (
        <b>Clients</b>
        ), our Clients may input data that they have collected about their customers into our
        Services in order to generate quotes and invoices and manage customer projects. This may
        include your name, email address, your credit card or other payment details, and
        details of products and services you have requested from the Client. If the data contains
        personal information about you, we will only use it as required or authorised by law.
      </Text>
      <Heading variant="section" size="lg" mt={8} mb={2}>How we collect personal information</Heading>
      <Text mb={2}>
        We collect personal information in a variety of ways, including:
      </Text>
      <UnorderedList>
        <ListItem ml={4} mb={1}>
          <b>Directly</b>
          : We collect personal information which you directly provide to us, including when
          you sign up for a free demo, when you enter into a contract with us for our Services,
          when you register for a login, through the  &lsquo;contact us&rsquo; form on our
          website or when you request our assistance via email or over the telephone.
        </ListItem>
        <ListItem ml={4} mb={1}>
          <b>Indirectly</b>
          : We may collect personal information which you indirectly
          provide to us while interacting with us, such as when you use our website, in emails,
          over the telephone and in your online enquiries.
        </ListItem>
        <ListItem ml={4} mb={1}>
          <b>From third parties</b>
          : We collect personal information from third parties,
          such as from our Clients when they input data about their customers into our Services
          and details of your use of our website from our analytics and cookie providers and
          marketing providers. See the &ldquo;Cookies&rdquo; section below for more detail
          on the use of cookies.
        </ListItem>
      </UnorderedList>
      <Heading variant="section" size="lg" mt={8} mb={2}>Collection and use of personal information</Heading>
      <Text mb={2}>
        <b>Personal information</b>
        : We may collect, hold, use and disclose personal information for the following purposes:
      </Text>
      <UnorderedList>
        <ListItem ml={4} mb={1}>
          to enable you to access and use our Services, including to provide you with a login;
        </ListItem>
        <ListItem ml={4} mb={1}>
          to enable you to access and use our associated applications;
        </ListItem>
        <ListItem ml={4} mb={1}>
          to contact and communicate with you about our Services;
        </ListItem>
        <ListItem ml={4} mb={1}>
          for internal record keeping, administrative, invoicing and billing purposes;
        </ListItem>
        <ListItem ml={4} mb={1}>
          for analytics, market research and business development, including to operate and
          improve our Services, associated applications;
        </ListItem>
        <ListItem ml={4} mb={1}>
          for advertising and marketing, including to send you promotional information about
          our products and services and information that we consider may be of interest to
          you, noting we will comply with all laws that are relevant to marketing (including
          the Unsolicited Electronic Messages Act 2007 and Fair Trading Act 1986);
        </ListItem>
        <ListItem ml={4} mb={1}>
          to comply with our legal obligations and resolve any disputes that we may have;
        </ListItem>
        <ListItem ml={4} mb={1}>
          if you have applied for employment with us; to consider your employment
          application; and
        </ListItem>
        <ListItem ml={4} mb={1}>
          if otherwise required or authorised by law.
        </ListItem>
      </UnorderedList>
      <Heading variant="section" size="lg" mt={8} mb={2}>Disclosure of personal information to third parties</Heading>
      <Text mb={2}>
        We may disclose personal information to:
      </Text>
      <UnorderedList>
        <ListItem ml={4} mb={1}>
          third party service providers for the purpose of enabling them to provide their
          services, to us, including (without limitation) IT service providers such as Stripe,
          Xero, and Sentry, data storage, web-hosting and server providers such as Amazon Web
          Services, debt collectors, couriers, maintenance or problem-solving providers,
          marketing or advertising providers, professional advisors and payment systems
          operators;
        </ListItem>
        <ListItem ml={4} mb={1}>
          our employees, contractors and/or related entities;
        </ListItem>
        <ListItem ml={4} mb={1}>
          our existing or potential agents or business partners;
        </ListItem>
        <ListItem ml={4} mb={1}>
          anyone to whom our business or assets (or any part of them) are, or may
          (in good faith) be, transferred;
        </ListItem>
        <ListItem ml={4} mb={1}>
          courts, tribunals and regulatory authorities, in the event you fail to pay for goods
          or services we have provided to you;
        </ListItem>
        <ListItem ml={4} mb={1}>
          courts, tribunals, regulatory authorities and law enforcement officers, as required or
          authorised by law, in connection with any actual or prospective legal proceedings, or
          in order to establish, exercise or defend our legal rights;
        </ListItem>
        <ListItem ml={4} mb={1}>
          third parties to collect and process data, such as Google Analytics (To find out how
          Google uses data when you use third party websites or applications, please see
          www.google.com/policies/privacy/partners/ or any other URL Google may use from
          time to time); and
        </ListItem>
        <ListItem ml={4} mb={1}>
          any other third parties as required or permitted by law, such as where we receive
          a subpoena.
        </ListItem>
      </UnorderedList>
      <Text mb={2} mt={2}>
        <b>Overseas disclosure</b>
        : Where we disclose your personal information to third parties listed
        above, these third parties may store, transfer or access personal information outside
        of New Zealand, including but not limited to Australia and the United States of America,
        which may not have an equivalent level of data protection laws as those in New Zealand.
        Before disclosing any personal information to an overseas recipient, we will comply with
        Information Privacy Principle 12 and only disclose the information if:
      </Text>
      <UnorderedList>
        <ListItem ml={4} mb={1}>
          you have authorised the disclosure after we expressly informed you that the overseas
          recipient may not be required to protect the personal information in a way that,
          overall, provides comparable safeguards to those in the Privacy Act;
        </ListItem>
        <ListItem ml={4} mb={1}>
          we believe the overseas recipient is subject to the Privacy Act;
        </ListItem>
        <ListItem ml={4} mb={1}>
          we believe that the overseas recipient is subject to privacy laws that, overall,
          provide comparable safeguards to those in the Privacy Act;
        </ListItem>
        <ListItem ml={4} mb={1}>
          we believe that the overseas recipient is a participant in a prescribed
          binding scheme;
        </ListItem>
        <ListItem ml={4} mb={1}>
          we believe that the overseas recipient is subject to privacy laws in a prescribed
          country; or
        </ListItem>
        <ListItem ml={4} mb={1}>
          we otherwise believe that the overseas recipient is required to protect your personal
          information in a way that, overall, provides comparable safeguards to those in the
          Privacy Act (for example pursuant to a data transfer agreement entered into between
          us and the overseas recipient).
        </ListItem>
      </UnorderedList>
      <Heading variant="section" size="lg" mt={8} mb={2}>
        Your rights and controlling your personal information
      </Heading>
      <Text mb={2}>
        <b>Your choice</b>
        : Please read this Privacy Policy carefully. If you provide personal information to us,
        you understand we will collect, hold, use and disclose your personal information in
        accordance with this Privacy Policy. You do not have to provide personal information to
        us, however, if you do not, it may affect our ability to provide our Services to you and
        your use of our Services.
      </Text>
      <Text mb={2}>
        <b>Information from third parties</b>
        : If we receive personal information about you from a
        third party, we will protect it as set out in this Privacy Policy. If you are a third
        party providing personal information about somebody else, you represent and warrant
        that you have such person&apos;s consent to provide the personal information to us.
      </Text>
      <Text mb={2}>
        <b>Anonymity</b>
        : Where practicable we will give you the option of not identifying
        yourself or using a pseudonym in your dealings with us.
      </Text>
      <Text mb={2}>
        <b>Restrict and unsubscribe</b>
        : To object to processing for direct marketing/unsubscribe
        from our email database or opt-out of communications (including marketing communications),
        please contact us using the details below or opt-out using the opt-out facilities provided
        in the communication.
      </Text>
      <Text mb={2}>
        <b>Access</b>
        : You may request access to the personal information that we hold about you.
        An administrative fee may be payable for the provision of such information. Please note,
        in some situations, we may be legally permitted to withhold access to your personal
        information.
      </Text>
      <Text mb={2}>
        <b>Correction</b>
        : If you believe that any information we hold about you is inaccurate,  out of date,
        incomplete, irrelevant or misleading, please contact us using the details below.
        We will take reasonable steps to promptly correct any information found to be inaccurate,
        out of date, incomplete, irrelevant or misleading. Please note, in some situations, we may
        be legally permitted to not correct your personal information.
      </Text>
      <Text mb={2}>
        <b>Complaints</b>
        : If you wish to make a complaint, please contact us using the details below and provide
        us with full details of the complaint. We will promptly investigate your complaint and
        respond to you, in writing, setting out the outcome of our investigation and the steps
        we will take in response to your complaint. You also have the right to contact the
        Office of the New Zealand Privacy Commissioner.
      </Text>
      <Heading variant="section" size="lg" mt={8} mb={2}>Storage and security</Heading>
      <Text mb={2}>
        We are committed to ensuring that the personal information we collect is secure. In
        order to prevent unauthorised access or disclosure, we have put in place suitable
        physical, electronic and managerial procedures, to safeguard and secure personal
        information and protect it from misuse, interference, loss and unauthorised access,
        modification and disclosure.
      </Text>
      <Text>
        While we are committed to security, we cannot guarantee the security of any information
        that is transmitted to or by us over the Internet. The transmission and exchange of
        information is carried out at your own risk.
      </Text>
      <Heading variant="section" size="lg" mt={8} mb={2}>Cookies</Heading>
      <Text mb={2}>
        We may use cookies on our online Services from time to time. Cookies are text files placed
        in your computer&apos;s browser to store your preferences. Cookies, by themselves, do not
        tell us your email address or other personally identifiable information. If and when you
        choose to provide our online Services with personal information, this information may be
        linked to the data stored in the cookie.
      </Text>
      <Text>
        You can block cookies by activating the setting on your browser that allows you to refuse
        the setting of all or some cookies. However, if you use your browser settings to block all
        cookies (including essential cookies) you may not be able to access all or parts of our
        online Services.
      </Text>
      <Heading variant="section" size="lg" mt={8} mb={2}>Links to other websites</Heading>
      <Text>
        Our Services may contain links to other websites. We do not have any control over those
        websites and we are not responsible for the protection and privacy of any personal
        information which you provide whilst visiting those websites. Those websites are not
        governed by this Privacy Policy.
      </Text>
      <Heading variant="section" size="lg" mt={8} mb={2}>Amendments</Heading>
      <Text>
        We may, at any time and at our discretion, vary this Privacy Policy by publishing
        the amended Privacy Policy on our website. We recommend you check our website regularly
        to ensure you are aware of our current Privacy Policy.
      </Text>

      <Box marginTop={5} mb={20}>
        <Text>For any questions or notices, please contact our Privacy Officer at:</Text>
        <Text>Address: 11 Alpito Place, Pukekohe, Auckland 2120, New Zealand</Text>
        <Text>Phone: 0800 131 110</Text>
        <Text>Email: scott@magnetize.co.nz</Text>
        <Text>
          &copy;
          {' '}
          <Link href="https://legalvision.co.nz/">LegalVision NZ Limited</Link>
        </Text>
      </Box>
    </Container>
  </OneColumnBottomBrand>
);

export default PrivacyPolicy;
