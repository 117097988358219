import {
  Button,
  Flex,
  Modal, ModalBody, ModalCloseButton,
  ModalContent, ModalFooter, ModalHeader, ModalOverlay,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import ContactCustomerSelect from '~/components/CustomerContactEditModal/ContactCustomerSelect';
import CustomerContactEditForm from '~/components/CustomerContactEditModal/CustomerContactEditForm';
import useTrackedAction from '~/hooks/useTrackedAction';
import { CustomerContactActions } from '~/redux/customerContacts/actions';
import { CustomerContact } from '~/types/customerContact';

interface CustomerContactEditModalProps {
  customerContact: CustomerContact;
  disableCustomerSelect?: boolean;
  isNew?: boolean;
  onClose: () => void;
  onSave?: (customerContact: CustomerContact) => void;
}

const CustomerContactEditModal = (
  {
    customerContact,
    isNew,
    onClose,
    disableCustomerSelect = false,
    onSave = () => {},
  }: CustomerContactEditModalProps,
) => {
  const [editedContact, setEditedContact] = useState(customerContact);
  const [formCanSave, setFormCanSave] = useState(false);

  const { trigger: onSaveClicked, isLoading: isSaving } = useTrackedAction({
    trigger: () => CustomerContactActions.save({ contact: editedContact, hideSuccessToast: false }),
    onSuccess: () => {
      onSave(editedContact);
      onClose();
    },
  });

  const isValid = editedContact.customerId && formCanSave;

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen
      onClose={onClose}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {isNew ? 'Add new' : 'Edit'}
          {' '}
          contact
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ContactCustomerSelect
            isDisabled={disableCustomerSelect}
            customerId={editedContact.customerId}
            onChange={(customerId) => {
              setEditedContact({
                ...editedContact,
                customerId,
              });
            }}
          />
          <Flex h={8} />
          <CustomerContactEditForm
            focusName={!!editedContact.customerId}
            value={editedContact}
            onChange={(c, canSave) => {
              setEditedContact({
                ...editedContact,
                ...c,
              });
              setFormCanSave(canSave);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            size="lg"
            colorScheme="brandDark"
            isLoading={isSaving}
            disabled={!isValid}
            onClick={onSaveClicked}
          >
            Save
          </Button>
          <Button
            size="lg"
            variant="ghost"
            onClick={onClose}
            disabled={isSaving}
          >
            cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CustomerContactEditModal;
