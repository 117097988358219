import {
  forwardRef, Input, InputProps,
} from '@chakra-ui/react';
import { isNil } from 'lodash';
import React, { useState } from 'react';
import {
  formatDuration, formatTime12Hour, parseDuration, parseTime, Time,
} from '~/utils/parseTime';

interface TimePickerProps extends Omit<InputProps, 'value' | 'onChange'> {
  time?: Time;
  mode?: 'time' | 'duration';
  onChange?: (time: Time, isValid: boolean) => void;
}

const TimePicker = forwardRef(({
  time, onChange, mode = 'time', onBlur, ...inputProps
}: TimePickerProps,
ref) => {
  const format = mode === 'time' ? formatTime12Hour : formatDuration;
  const parse = mode === 'time' ? parseTime : parseDuration;

  const [editedText, setEditedText] = useState<string>();
  const inputValue = editedText ?? (time ? format(time) : '');

  const handleBlur = (e) => {
    if (!isNil(editedText)) {
      const parsedTime = parse(editedText);
      if (parsedTime) {
        setEditedText(undefined);
      }
      onChange?.(parsedTime, !editedText || !!parsedTime);
    }

    if (editedText === '') {
      setEditedText(undefined);
    }
    if (onBlur) {
      onBlur(e);
    }
  };

  return (
    <Input
      {...inputProps}
      ref={ref}
      value={inputValue}
      onChange={(e) => setEditedText(e.target.value)}
      onBlur={handleBlur}
    />
  );
});

export default TimePicker;
