import { createAction } from '@reduxjs/toolkit';
import createTrackedAction from '~/redux/tracked/createTrackedAction';
import { CognitoUser } from '~/types/cognitoUser';
import { Staff } from '~/types/staff';
import { Tenant } from '~/types/tenant';

export const currentUserActions = {
  fetch: createAction('currentUser/fetch'),
  signOut: createAction('currentUser/signOut'),
  signIn: createTrackedAction<{
    username: string,
    password: string,
    redirectTo?: string,
  }>('currentUser/signIn'),
  updated: createAction<{
    tenant: Tenant,
    staff: Staff,
    cognito: CognitoUser
  }>('currentUser/updated'),
  tenantPlanActivated: createAction('currentUser/tenant/plan-activated'),
  tenantPlanCancelled: createAction('currentUser/tenant/plan-cancelled'),
  clear: createAction('currentUser/clear'),
  saveTenant: createTrackedAction<{ tenant: Tenant, showSuccess?: boolean }>('currentUser/tenant/save'),
  deleteTrialData: createTrackedAction('currentUser/tenant/delete-trial-data'),
};
