import { SearchIcon } from '@chakra-ui/icons';
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import Fuse from 'fuse.js';
import React, { useEffect, useMemo, useState } from 'react';
import useDebouncedState from '~/hooks/useDebouncedState';

interface FusedSearchProps<T> {
  fuseSettings: any;
  items: T[];
  placeholder?: string;
  onChange: (filtered: T[]) => void;
}

function FusedSearch<T>({
  placeholder = 'Search', fuseSettings, items, onChange,
} : FusedSearchProps<T>) {
  const [searchTerm, setSearchTerm] = useState('');

  const fusedList = useMemo(() => {
    const f = new Fuse(items, fuseSettings);
    f.setCollection(items);
    return f;
  }, [items, fuseSettings]);

  const [, setVisible] = useDebouncedState({
    value: items,
    dispatchUpdate: (v) => {
      onChange(v);
    },
    delay: 100,
  });

  useEffect(() => {
    setVisible(searchTerm
      ? fusedList.search(searchTerm).map((s) => s.item)
      : items);
  }, [searchTerm, items]);

  return (
    <InputGroup>
      <InputLeftElement pointerEvents="none">
        <SearchIcon color="gray.300" />
      </InputLeftElement>
      <Input
        variant="flushed"
        placeholder={placeholder}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
    </InputGroup>
  );
}

export default FusedSearch;
