import { addDays, endOfDay } from 'date-fns';
import {
  groupBy, keyBy, map, omit, orderBy, sumBy, uniqBy,
} from 'lodash';
import {
  all, call, put, select, takeEvery,
} from 'redux-saga/effects';
import { formatTimeRecords, expandTimeRecords } from '~/helpers/timeRecord';
import TimesheetActions from '~/redux/timesheets/actions';
import { selectTimeRecordsForJob } from '~/redux/timesheets/selectors';
import { showToast } from '~/toast';
import { Job } from '~/types/job';
import { Staff } from '~/types/staff';
import { isTimeRecordValid, makeTimeId, TimeRecord } from '~/types/time';
import { getIsoDate } from '~/utils/calendarHelpers';
import { exportToSpreadsheet } from '~/utils/exportToSpreadsheet';
import fetchJson from '~/utils/fetchJson';
import { parseISODateLocal } from '~/utils/parseTime';
import { performFetchSaga } from '~/utils/performFetchSaga';
import { performTrackedSaga } from '~/utils/performTrackedSaga';
import queueWork from '~/utils/queueWork';

const watchFetchForJob = takeEvery(
  TimesheetActions.fetchForJob,
  function* handle(action) {
    const { jobId } = action.payload;
    yield performFetchSaga({
      key: `timesheets-${jobId}`,
      * saga() {
        const times: TimeRecord[] = yield call(
          fetchJson,
          `/api/time/forJob/${jobId}`,
        );

        yield put(TimesheetActions.timesUpdatedForJob({
          jobId,
          times: times.map((t) => ({
            ...t,

            // Hack: this shouldn't be necessary once the backend is
            // updated to store this information.
            date: t.date ?? getIsoDate(t.start),
          })),
        }));
      },
    });
  },
);

const watchSaveForJob = takeEvery(
  TimesheetActions.saveJobTimeRecord,
  function* handler(action) {
    const { jobId, timeRecord } = action.payload;

    if (!isTimeRecordValid(timeRecord)) {
      showToast({
        status: 'error',
        title: 'Saving time record failed',
        description: 'Time record is invalid, this is a bug.',
      });
    }

    const id = timeRecord.id || makeTimeId({
      staffId: timeRecord.staffId,
      date: timeRecord.date,
      jobId,
    });

    const updatedTimeRecord = {
      ...timeRecord,
      jobId,
      id,
    };

    const existingTimes: TimeRecord[] = yield select(
      (state) => selectTimeRecordsForJob(state, jobId),
    );

    yield put(TimesheetActions.timesUpdatedForJob({
      jobId,
      times: uniqBy([updatedTimeRecord, ...existingTimes], (t) => t.id),
    }));

    yield queueWork(function* worker() {
      try {
        yield call(() => fetchJson(
          `/api/time/${id}`, {
            method: 'PUT',
            body: omit(updatedTimeRecord, 'date'),
          },
        ));
        showToast({
          status: 'success',
          title: 'Time log saved!',
        });
      } catch (error) {
        yield put(TimesheetActions.timesUpdatedForJob({
          jobId,
          times: existingTimes,
        }));

        showToast({
          status: 'error',
          title: 'Saving time record failed',
          description: String(error),
        });
      }
    });
  },
);

const watchFetchForRange = takeEvery(
  TimesheetActions.fetchForRange,
  function* handle(action) {
    const { from, to } = action.payload;
    yield performFetchSaga({
      key: `timesheets-${from}-${to}`,
      * saga() {
        const times: TimeRecord[] = yield call(
          fetchJson,
          `/api/time/range?from=${parseISODateLocal(from).getTime()}&to=${endOfDay(parseISODateLocal(to)).getTime()}`,
        );

        yield put(TimesheetActions.timesUpdatedForRange({
          from,
          to,
          times: times.map((t) => ({
            ...t,
            // Hack: this shouldn't be necessary once the backend is
            // updated to store this information.
            date: t.date ?? getIsoDate(t.start),
          })),
        }));
      },
    });
  },
);

const watchExportSelectedTime = takeEvery(
  TimesheetActions.exportSelected,
  function* handler(action) {
    const {
      times,
      from,
      to,
    } = action.payload.data;
    yield performTrackedSaga({
      key: action.payload.id,
      * saga() {
        const [staff, jobs]: [ Staff[], Job[]] = yield all([
          call(() => fetchJson('/api/staff/')),
          call(() => fetchJson('/api/jobs/')),
        ]);

        const staffById = keyBy(staff, (s) => s.id);
        const expandedTimeRecords = expandTimeRecords(times,
          parseISODateLocal(from).getTime(),
          addDays(parseISODateLocal(to), 1).getTime());
        const formattedTimeRecords = formatTimeRecords(orderBy(expandedTimeRecords, [
          (tr) => staffById[tr.staffId].name,
          (tr) => tr.start,
        ]), staff, jobs, []);

        const byStaff = groupBy(
          formattedTimeRecords,
          (tr) => tr.staffId,
        );

        const byJob = groupBy(
          formattedTimeRecords,
          (tr) => tr.jobId,
        );

        const name = `Timesheet_${from}_${to}`;
        yield call(() => exportToSpreadsheet({
          name,
          format: 'xlsx',
          sheets: [
            {
              name: 'Raw',
              headers: [
                'Date',
                'Staff',
                'Job',
                'Note',
                'Travel Time Hours',
                'Start',
                'End',
                'Downtime Hours',
                'Total Hours',
              ],
              columnWidths: [10, 20, 20, 20, 10, 10, 10, 10, 10],
              data: formattedTimeRecords.map((tr) => ([
                tr.formattedDate,
                tr.staffName,
                tr.jobName,
                tr.note || '',
                tr?.travelTimeHours || '',
                tr.startTime,
                tr.endTime,
                tr?.downtimeHours || '',
                tr?.totalHours || '',
              ])),
            },
            {
              name: 'By Employee',
              headers: [
                'Logs',
                'Employee',
                'Total Hours',
              ],
              columnWidths: [10, 20, 10],
              data: map(byStaff, (group) => ([
                group.length,
                group[0].staffName,
                sumBy(group, (g) => g.totalHours),
              ])),
            },
            {
              name: 'By Job',
              headers: [
                'Logs',
                'Job',
                'Total Hours',
              ],
              columnWidths: [10, 20, 10],
              data: map(byJob, (group) => ([
                group.length,
                group[0].jobName,
                sumBy(group, (g) => g.totalHours),
              ])),
            }],
        }));
      },
    });
  },
);

export default function* handleTime() {
  yield all([
    watchFetchForJob,
    watchFetchForRange,
    watchSaveForJob,
    watchExportSelectedTime,
  ]);
}
