import {
  Icon, Table, Text, Tbody, Tr, VStack, Td, Skeleton, MenuItem, Image, Box,
} from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
import React, { useState } from 'react';
import { ReactComponent as DrillIcon } from '~/assets/backfill.svg';
import ConfirmationDialog from '~/components/ConfirmationDialog';
import { ExpandableCountSection } from '~/components/ExpandableCountSection';
import { KebabOptionsMenu } from '~/components/KebabOptionsMenu';
import LinkButton from '~/components/LinkButton';
import { useModalManager } from '~/components/ModalManager';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import { BackfillTypeEditModal } from './BackfillTypeEditModal';
import { triggerBackfillTypesFetch, deleteBackfillType } from '~/redux/backfillTypes/actions';
import { useAppDispatch } from '~/redux/store';
import { BackfillType } from '~/types/backfillType';
import eventStopper from '~/utils/eventStopper';
import { getActiveBackfillTypes } from '~/redux/backfillTypes/selectors';

export const BackfillTypesList = () => {
  const dispatch = useAppDispatch();
  const modalManager = useModalManager();
  const { data: backfillTypes, isLoading } = useTrackedFetch({
    key: 'backfill-types',
    trigger: () => triggerBackfillTypesFetch({ force: false }),
    selector: getActiveBackfillTypes,
  });

  const [
    selectedBackfillType,
    setSelectedBackfillType,
  ] = useState<Partial<BackfillType>>(null);
  const [backfillTypeModalTitle, setBackfillTypeModalTitle] = useState<string>();

  const addTechnique = () => {
    setSelectedBackfillType({
      id: nanoid(),
    });
    setBackfillTypeModalTitle('Add backfill type');
  };

  const editTechnique = (backfillType: BackfillType) => {
    setSelectedBackfillType(backfillType);
    setBackfillTypeModalTitle('Edit backfill type');
  };

  const deleteTechnique = (backfillTypeId) => {
    dispatch(deleteBackfillType({
      id: backfillTypeId,
    }));
  };

  return (
    <VStack align="stretch">
      <ExpandableCountSection
        title="Backfill types"
        icon={<Icon boxSize={8} as={DrillIcon} />}
        count={backfillTypes?.length || 0}
      >
        <Table size="md">
          <Tbody>
            {isLoading ? (
              <Tr>
                <Td><Skeleton height={8} /></Td>
                <Td><Skeleton height={8} /></Td>
                <Td />
                <Td />
              </Tr>
            )
              : (orderBy(backfillTypes, (t) => t.name).map((tr) => (
                <Tr
                  key={tr.id}
                  _hover={{ bg: '#fafafa', cursor: 'pointer' }}
                  transition="color 0.1s ease-out"
                  role="group"
                  onClick={() => editTechnique(tr)}
                >
                  <Td width="65px" />
                  <Td width="40px" px={0} m={0}>
                    {tr.imageId && (
                    <Box backgroundColor="magnetize.ui-4">
                      <Image src={`/images/live-geo/backfill/${tr.imageId}.png`} />
                    </Box>
                    )}
                  </Td>
                  <Td>
                    <Text>
                      {tr.name}
                    </Text>
                  </Td>

                  <Td textAlign="end" {...eventStopper()}>
                    <KebabOptionsMenu>
                      <MenuItem onClick={() => editTechnique(tr)}>Edit</MenuItem>
                      <MenuItem
                        onClick={() => {
                          modalManager.open(ConfirmationDialog, {
                            title: 'Delete backfill type',
                            children: 'This will remove the backfill type from Magnetize, but will not remove it from any existing logs.',
                            onConfirm: () => deleteTechnique(tr.id),
                            confirmButtonLabel: 'Delete',
                          });
                        }}
                      >
                        Delete
                      </MenuItem>
                    </KebabOptionsMenu>
                  </Td>
                </Tr>
              )))}
          </Tbody>
        </Table>
        <LinkButton ml={24} mt={6} isGreen noUnderline onClick={addTechnique}>
          + add backfill type
        </LinkButton>
        {!!selectedBackfillType
      && (
      <BackfillTypeEditModal
        title={backfillTypeModalTitle}
        onClose={() => {
          setSelectedBackfillType(null);
        }}
        backfillType={selectedBackfillType}
      />
      )}
      </ExpandableCountSection>
    </VStack>
  );
};
