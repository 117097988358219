import {
  Box, Flex, Spacer,
} from '@chakra-ui/react';
import { PlaceOutlined } from '@material-ui/icons';
import React, { useState } from 'react';
import Address from '~/components/Address';
import AddressForm from '~/components/AddressForm';
import EditContainer from '~/components/EditContainer';
import LinkButton from '~/components/LinkButton';
import PlacesAutocomplete from '~/components/PlacesAutocomplete';
import { Address as AddressType } from '~/types/address';

interface JobSiteAddressSectionProps {
  value?: AddressType;
  title: string;
  addButtonIcon?: JSX.Element;
  onChange?: (value: AddressType | undefined) => void;
}

const JobSiteAddressSection = ({
  title,
  value,
  onChange = () => {},
  addButtonIcon = <PlaceOutlined />,
}: JobSiteAddressSectionProps) => {
  const [isAddingAddress, setIsAddingAddress] = useState(false);

  const updateAddressAndFinish = (address: AddressType) => {
    onChange?.(address && Object.values(address).some((x) => x) ? address : null);
    setIsAddingAddress(false);
  };

  const removeAddress = () => {
    if (value) {
      updateAddressAndFinish(null);
    } else {
      setIsAddingAddress(false);
    }
  };

  if (!value && !isAddingAddress) {
    return (
      <LinkButton
        textDecoration="none"
        leftIcon={addButtonIcon}
        onClick={() => setIsAddingAddress(true)}
      >
        {`Add ${title.toLocaleLowerCase()}`}
      </LinkButton>
    );
  }

  if (value) {
    return (
      <EditContainer
        value={value}
        onChange={updateAddressAndFinish}
        startEditingOnClick={false}
        showRemoveButton
        onRemove={removeAddress}
        renderValue={({ value: address, startEditing }) => (
          <Flex alignItems="flex-start">
            <Box color="gray.900" marginRight={2}><PlaceOutlined /></Box>
            <Box onClick={startEditing} overflowWrap="break-word" overflow="hidden">
              <Address value={address} />
            </Box>
            <Spacer />
            <LinkButton noUnderline onClick={startEditing}>edit</LinkButton>
          </Flex>
        )}
        renderEditor={(props) => (
          <>
            <AddressForm value={props.value} onChange={props.onChange} />
          </>
        )}
      />
    );
  }
  return <PlacesAutocomplete autoFocus onAddressChange={updateAddressAndFinish} />;
};

export default JobSiteAddressSection;
