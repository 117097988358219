import { getLogged } from '~/helpers/job';
import { selectJobById } from '~/redux/jobs/selectors';
import { useAppSelector } from '~/redux/store';
import { JobLoggedStats } from '~/types/job';
import { Price } from '~/types/price';

export const useLoggedStats = (
  { jobId, interval, prices }
  :{ jobId: string, interval?: Interval, prices: Price[] },
)
: JobLoggedStats => {
  const job = useAppSelector((state) => selectJobById(state, jobId));
  return getLogged({ job, interval, prices });
};
