import {
  Button,
  FormControl,
  FormLabel, Input, Stack,
} from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/status-bar/dist/style.css';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import * as yup from 'yup';

import queryClient from '~/queryClient';
import { showErrorToast, showToast } from '~/toast';
import fetchJson from '~/utils/fetchJson';

interface AddToTenantFormProps {
  tenantId?: string;
  buttonText: string;
}

export const AddToTenantForm = (
  { tenantId, buttonText } : AddToTenantFormProps,
) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const { mutate, isLoading } = useMutation(async () => {
    await fetchJson('/api/users/invite', {
      method: 'POST',
      body: {
        tenantId: tenantId ?? nanoid(),
        email,
        name,
      },
    });
  }, {
    onSuccess: () => {
      showToast({
        description: `Invited ${email} to tenant`,
        status: 'success',
      });

      queryClient.invalidateQueries('admin-tenant');
    },
    onError: (e) => {
      showErrorToast(e);
    },
  });
  const emailValid = yup.string().email().required().isValidSync(email);

  return (
    <Stack spacing={8}>
      <FormControl isRequired>
        <FormLabel>Name</FormLabel>
        <Input onChange={(e) => setName(e.target.value)} value={name} />
      </FormControl>
      <FormControl isRequired>
        <FormLabel>Email</FormLabel>
        <Input type="email" onChange={(e) => setEmail(e.target.value)} value={email} />
      </FormControl>
      <Button
        isLoading={isLoading}
        disabled={isLoading || !emailValid || !name}
        onClick={() => { mutate(); }}
      >
        {buttonText}
      </Button>
    </Stack>
  );
};
