import {
  Flex, Skeleton, SkeletonText, Spacer, Stack,
} from '@chakra-ui/react';
import { isFunction } from 'lodash';
import React from 'react';
import Card from '~/components/Card';

const SkeletonQuote = ({
  children, isLoaded = false,
}: { children?: JSX.Element | (() => JSX.Element), isLoaded?: boolean }) => {
  if (isLoaded) {
    return isFunction(children) ? children() : children;
  }
  return (
    <Stack spacing={8}>
      <Card>
        <Stack spacing={16}>
          <Skeleton height="8" width="33%" />
          <SkeletonText width="40%" />
          <SkeletonText noOfLines={2} />
          <SkeletonText noOfLines={4} />
          <SkeletonText noOfLines={3} />

          <Flex>
            <Spacer />
            <SkeletonText width="40%" noOfLines={2} />
          </Flex>
        </Stack>
      </Card>
    </Stack>
  );
};

export default SkeletonQuote;
