import { DownloadIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex, HStack, SkeletonText, Spacer, Text,
} from '@chakra-ui/react';
import {
  addDays,
  addMonths,
  differenceInMonths,
  endOfMonth, format, startOfMonth,
} from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { OptionsType } from 'react-select';
import CardTitle from '~/components/CardTitle';
import SmartDatePicker from '~/components/SmartDatePicker';
import { FormattedTimeRecord, formatTimeRecords, expandTimeRecords } from '~/helpers/timeRecord';
import useTrackedAction from '~/hooks/useTrackedAction';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import TimeRecordsTable from '~/pages/Staff/TimeRecordsTable';
import { JobActions } from '~/redux/jobs/actions';
import { selectJobs } from '~/redux/jobs/selectors';
import StaffActions from '~/redux/staff/actions';
import { selectAllStaff } from '~/redux/staff/selectors';
import { useAppDispatch } from '~/redux/store';
import { triggerTagsFetch } from '~/redux/tags/actions';
import TimesheetActions from '~/redux/timesheets/actions';
import { selectTimeRecordsForRange } from '~/redux/timesheets/selectors';
import { selectTagsByType } from '~/redux/tags/selectors';
import SearchSelect from '~/components/SearchSelect';

const MAXIMUM_RANGE_IN_MONTHS = 3;

const TimeRecordsTab = () => {
  const dispatch = useAppDispatch();
  const today = new Date();

  const [start, setStart] = useState(startOfMonth(today));
  const [end, setEnd] = useState(endOfMonth(today));
  const [groupBy] = useState<'staff' | 'job'>('staff');
  const [selectedItems, setSelectedItems] = useState<OptionsType<any>>([]);

  const fromMinusOne = format(addDays(start, -1), 'yyyy-MM-dd');
  const from = format(start, 'yyyy-MM-dd');
  const to = format(end, 'yyyy-MM-dd');

  const { data: staffTags } = useTrackedFetch({
    key: 'tag',
    trigger: () => triggerTagsFetch({ force: false }),
    selector: (state) => selectTagsByType(state, 'staff'),
  });

  useEffect(() => {
    dispatch(TimesheetActions.fetchForRange({
      from: fromMinusOne,
      to,
    }));
  }, [fromMinusOne, to]);

  useEffect(() => {
    if (differenceInMonths(end, start) > MAXIMUM_RANGE_IN_MONTHS) {
      setEnd(addMonths(start, 3));
    }
  }, [start]);

  useEffect(() => {
    if (differenceInMonths(end, start) > MAXIMUM_RANGE_IN_MONTHS) {
      setStart(addMonths(end, -3));
    }
  }, [end]);

  const { data: timeRecords, isLoading: timeRecordsLoading } = useTrackedFetch({
    key: `timesheets-${fromMinusOne}-${to}`,
    selector: (state) => selectTimeRecordsForRange(state, fromMinusOne, to),
    trigger: () => TimesheetActions.fetchForRange({
      from: fromMinusOne,
      to,
    }),
  });
  const { data: staff, isLoading: staffLoading } = useTrackedFetch({
    key: 'staff',
    selector: (state) => selectAllStaff(state),
    trigger: () => StaffActions.fetch(),
  });
  const { data: tags, isLoading: tagsLoading } = useTrackedFetch({
    key: 'tag',
    trigger: () => triggerTagsFetch({ force: false }),
    selector: (state) => selectTagsByType(state, 'staff'),
  });
  const { data: jobs, isLoading: jobsLoading } = useTrackedFetch({
    key: 'jobs',
    selector: (state) => selectJobs(state),
    trigger: () => JobActions.fetchAll(),
  });

  const expandedTimeRecords = useMemo(
    () => expandTimeRecords(timeRecords, start.getTime(), addDays(end, 1).getTime()),
    [timeRecords],
  );

  const formattedTimeRecords : FormattedTimeRecord[] = useMemo(
    () => formatTimeRecords(expandedTimeRecords, staff, jobs, tags),
    [expandedTimeRecords, staff, jobs],
  );

  const selectedStaff = useMemo(() => selectedItems.flatMap((i) => {
    if (i.group === 'Staff') {
      return [i.value];
    }
    return staff.filter((s) => (s.tags || []).includes(i.value)).map((s) => s.id);
  }), [selectedItems]);

  const visibleTimeRecords = useMemo(
    () => {
      if (selectedItems.length === 0) {
        return formattedTimeRecords;
      }
      return formattedTimeRecords
        .filter((t) => selectedStaff.includes(t.staffId));
    },
    [selectedStaff, formattedTimeRecords],
  );

  const { trigger, isLoading: isExportLoading } = useTrackedAction({
    trigger: () => TimesheetActions.exportSelected({
      from,
      to,
      times: visibleTimeRecords,
    }),
  });

  const isLoading = timeRecordsLoading || staffLoading || jobsLoading || tagsLoading;

  const staffOrTeamOptions = useMemo(() => ([
    {
      label: 'Staff',
      options: staff.map((s) => ({
        label: s.name,
        value: s.id,
        group: 'Staff',
      })),
    },
    {
      label: 'Teams',
      options: staffTags.map((t) => ({
        label: t.name,
        value: t.id,
        group: 'Team',
      })),
    },
  ]), [staff, staffTags]);

  return (
    <Flex direction="column">
      <Flex direction="row-reverse">
        <Button
          leftIcon={<DownloadIcon />}
          as={Button}
          variant="ghost"
          onClick={() => trigger()}
          pr={2}
          pl={2}
        >
          {isExportLoading ? 'Exporting...' : 'Export logs'}
        </Button>
      </Flex>
      <HStack spacing={8} alignItems="flex-end">
        <Flex direction="column">
          <Flex flex="1" alignItems="start">
            <CardTitle>Filter by date range</CardTitle>
          </Flex>
          <HStack>
            <SmartDatePicker
              placeholder="Select date"
              selected={start}
              selectsStart
              startDate={start}
              value={start.toString()}
              endDate={end}
              onChange={(d) => setStart(d)}
              maxDate={new Date(Math.min(today.getTime(), end.getTime()))}
            />
            <Text>-</Text>
            <SmartDatePicker
              placeholder="Select date"
              selected={end}
              value={end.toString()}
              selectsEnd
              startDate={start}
              endDate={end}
              onChange={(d) => setEnd(d)}
              minDate={start}
            />
          </HStack>
        </Flex>
        {/* Commented out for now as this isn't in the designs
          however we actually have the ability to group the time records
          table by either staff or jobs, this may be useful in the future!! */}
        {/* <Flex direction="column">
          <Flex flex="1" alignItems="start">
            <CardTitle>Group by</CardTitle>
          </Flex>
          <Flex>
            <Select
              value={groupBy}
              onChange={(e) => {
                const group = e.target.value;
                if (group === 'staff' || group === 'job') {
                  setGroupBy(group);
                }
              }}
            >
              <option value="staff">Staff</option>
              <option value="job">Job</option>
            </Select>
          </Flex>
        </Flex> */}
        <Flex direction="column" flex="1">
          <SearchSelect
            isMulti
            hideSearchIcon
            placeholder="Filter by staff or teams"
            options={staffOrTeamOptions}
            value={selectedItems}
            onChange={(v) => {
              setSelectedItems(v);
            }}
          />
        </Flex>
        <Spacer flex="1" />
      </HStack>
      <Box height={8} />
      {isLoading && <SkeletonText noOfLines={2} />}
      {!isLoading
      && (
      <Flex direction="column">
        {visibleTimeRecords
          && <TimeRecordsTable groupBy={groupBy} timeRecords={visibleTimeRecords} />}
        {visibleTimeRecords.length === 0 && (
        <Text mt={4}>
          There are no time records to display.
        </Text>
        )}
      </Flex>
      )}
    </Flex>
  );
};

export default TimeRecordsTab;
