import { createReducer } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';
import { LogsActions } from '~/redux/logs/actions';
import { Dictionary } from '~/types/helpers';
import { LogEntry } from '~/types/LogEntry';

interface LogsState {
  byId: Dictionary<LogEntry>;
  byJobId: Dictionary<string[]>;
}

const logsReducer = createReducer<LogsState>(
  {
    byId: {},
    byJobId: {},
  },
  (builder) => {
    builder.addCase(LogsActions.jobLogsUpdated, (state, action) => ({
      ...state,
      byId: {
        ...state.byId,
        ...keyBy(action.payload.logs, (log) => log.id),
      },

      byJobId: {
        ...state.byJobId,
        [action.payload.jobId]: action.payload.logs.map((e) => e.id),
      },
    }));
  },
);

export default logsReducer;
