import { createAction } from '@reduxjs/toolkit';
import { Dictionary } from '~/types/helpers';
import { ResourceIdentifier } from '~/types/resource';
import { JobEvent, JobResourceEvent, ScheduleEvent } from '~/types/ScheduleEvent';

interface FetchScheduleEventsForRangePayload {
  from: number;
  to: number;
}

interface CreateJobSchedulePayload {
  event: JobEvent;
  resourceEvents: JobResourceEvent[];
}

export const ScheduleEventActions = {
  fetchForRange: createAction<FetchScheduleEventsForRangePayload>(
    'schedule/fetchRange',
  ),
  fetchForJob: createAction<{ jobId: string }>(
    'schedule/fetchForJob',
  ),
  eventsUpdated: createAction<{ events: Dictionary<ScheduleEvent> }>(
    'schedule/eventsUpdated',
  ),
  createEvent: createAction<ScheduleEvent>('schedule/createEvent'),
  updateEvent: createAction<ScheduleEvent>('schedule/updateEvent'),
  deleteEvent: createAction<{ eventId: string, hideToast?: boolean }>('schedule/deleteEvent'),
  eventDeleted: createAction<{ eventId: string }>('schedule/eventDeleted'),
  createJobSchedule: createAction<CreateJobSchedulePayload>('schedule/createJobSchedule'),
  addResourceToJob: createAction<{ jobEventId: string, resource: ResourceIdentifier }>('schedule/addResourceToJob'),
  removeResourceFromJob: createAction<{ jobEventId: string, resourceId: string }>(
    'schedule/removeResourceFromJob',
  ),
  deleteJobEvent: createAction<{ jobEventId: string }>('schedule/deleteJobEvent'),
  updateJobEvent: createAction<{
    jobEvent: JobEvent,
    events: JobResourceEvent[],
    eventsToRemove: JobResourceEvent[]
  }>('schedule/modifyJobEvent'),
  editResourceEvents: createAction<{
    jobEventId: string,
    resource: ResourceIdentifier,
    intervals: { start: number, end: number }[]
  }>('schedule/editResourceEvents'),
};
