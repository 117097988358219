import { Flex, Icon, Text } from '@chakra-ui/react';
import { AddOutlined } from '@material-ui/icons';
import React, { MutableRefObject } from 'react';
import FileUpload, { FileUploadRef } from '~/components/FileUpload';
import UploadedFile from '~/types/uploaded-file';

interface TimelineFileUploadProps {
  uploadRef: MutableRefObject<FileUploadRef>;
  onFileUploaded: (file: UploadedFile) => void;
}

const TimelineFileUpload = (
  {
    uploadRef, onFileUploaded,
  } : TimelineFileUploadProps,
) => (
  <Flex flex="1" direction="column">
    <FileUpload
      ref={uploadRef}
      onFileUploaded={onFileUploaded}
    >
      <Flex
        color="magnetize.text-1"
        alignItems="center"
        justifyContent="center"
        px={4}
      >
        <Icon as={AddOutlined} />
      </Flex>
      <Text my={1.5} color="magnetize.text-3">
        Upload files or photos
      </Text>
    </FileUpload>
  </Flex>
);

export default TimelineFileUpload;
