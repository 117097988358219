import { Saga, SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { fetchCompleted, fetchErrored, fetchStarted } from '~/redux/fetch/actions';
import { selectDoesNeedRefetch } from '~/redux/fetch/selectors';

/**
 * Fetch saga parameters which may be useful to use in action
 * definitions to give the app a little more control over data
 * fetching.
 */
export interface FetchSagaOptions {
  staleTime?: number;
  force?: boolean;
}

interface PerformFetchSagaOptions extends FetchSagaOptions {
  key: string;
  saga: Saga,
}

/**
 * A reusable saga to perform a fetch, while providing React-Query like
 * capabilities.
 *
 * This wraps a chunk of saga logic that is intended to perform a fetch request.
 * It will dispatch generic started/complete/error events allowing the state of
 * the request to be tracked by views, and for the app to keep track of the
 * freshness of data in the cache.
 *
 * It uses the `key` as the way to identify queries of this type. The content is
 * arbitrary, but it could be something like `quote-${quoteId}`.
 *
 * The default behaviour is to not dispatch another fetch if there is already
 * one running for this key.
 *
 * A basic example of using this is as follows:
 *
 *     yield performFetchSaga({
 *       key: 'my-request-key',
 *       *saga() {
 *         const result = yield call(...);
 *         yield put(doSomethingWith(result));
 *       }
 *     })
 *
 * A view can use the `selectFetchState` selector to observe 'my-request-key'
 * and track its progress
 * @param param0
 */
export function* performFetchSaga(
  {
    key, saga, staleTime, force = false,
  }: PerformFetchSagaOptions,
): SagaIterator {
  const needsRefetch: boolean = yield select(
    (state) => selectDoesNeedRefetch(state, key, { staleTime }),
  );

  if (needsRefetch || force) {
    yield put(fetchStarted({ keys: [key], at: Date.now() }));
    try {
      yield call(saga);
      yield put(fetchCompleted({ keys: [key], at: Date.now() }));
    } catch (error) {
      const errorMessage = error.message ?? String(error);
      yield put(fetchErrored({
        keys: [key],
        errorMessage,
        errorType: error.code || error.name,
        at: Date.now(),
      }));
    }
  }
}
