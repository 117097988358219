import { format, getUnixTime } from 'date-fns';
import { omit } from 'lodash';
import { ApiScheduleEvent, JobEvent, ScheduleEvent } from '~/types/ScheduleEvent';
import fetchJson from '~/utils/fetchJson';

export const getScheduleEventsInRange = async (from: number, to: number) => {
  const events = await fetchJson<ApiScheduleEvent[]>(`/api/schedule/range?from=${from}&to=${to}`);
  return events.map((event) => ({
    ...event,
    type: event.type ?? 'job-resource', // hack for compatibility
    start: event.from * 1000,
    end: event.to * 1000,
  } as ScheduleEvent));
};

export const getScheduleEventsForJob = async (jobId: string) => {
  const events = await fetchJson<ApiScheduleEvent[]>(`/api/schedule/job/${jobId}`);
  return events.map((event) => ({
    ...event,
    type: event.type ?? 'job-resource', // hack for compatibility
    start: event.from * 1000,
    end: event.to * 1000,
  } as ScheduleEvent));
};

export const putScheduleEvent = (scheduleEvent: ScheduleEvent) => (
  fetchJson<ApiScheduleEvent>(`/api/schedule/${scheduleEvent.id}`, {
    method: 'PUT',
    body: {
      fromDate: format(scheduleEvent.start, 'yyyy-MM-dd'),
      toDate: format(scheduleEvent.end, 'yyyy-MM-dd'),
      ...omit(scheduleEvent, 'start', 'end', 'fromDate', 'toDate'),
      from: getUnixTime(scheduleEvent.start),
      to: getUnixTime(scheduleEvent.end),
      children: (scheduleEvent as JobEvent).children
        ? (scheduleEvent as JobEvent).children.map((c) => ({
          fromDate: format(c.start, 'yyyy-MM-dd'),
          toDate: format(c.end, 'yyyy-MM-dd'),
          ...omit(c, 'start', 'end', 'fromDate', 'toDate'),
          from: getUnixTime(c.start),
          to: getUnixTime(c.end),
        }))
        : undefined,
    },
  })
);

export const deleteScheduleEvent = (eventId: string) => (
  fetchJson(`/api/schedule/${eventId}`, {
    method: 'DELETE',
  })
);
