import { nanoid } from '@reduxjs/toolkit';
import { mapValues } from 'lodash';
import { Dictionary } from '~/types/helpers';
import { BundleLineItem, LineItem, PriceBookLineItem } from '~/types/lineItem';
import { Price } from '~/types/price';
import { PriceBundle, PriceBundleItem } from '~/types/priceBundle';
import calculateQuoteTotals from '~/utils/calculateQuoteTotals';

export interface PriceBundleViewModel {
  id: string;
  name: string;
  description: string;
  isAvailable: boolean;
  isDeleted: boolean;
  lineItems: Dictionary<LineItem>;
}

export const isPriceBundleViewModel = (object: any): object is PriceBundleViewModel => 'totalCents' in object;

export const makePriceBundle = (bundleViewModel: PriceBundleViewModel) : PriceBundle => {
  const lineItems :
  Dictionary<PriceBundleItem> = mapValues(bundleViewModel.lineItems, (li) => ({
    id: li.id,
    order: li.order,
    quantity: li.quantity,
    priceId: li.priceId,
  }));
  return {
    id: bundleViewModel.id,
    name: bundleViewModel.name,
    description: bundleViewModel.description,
    lineItems,
    isAvailable: bundleViewModel.isAvailable,
    isDeleted: bundleViewModel.isDeleted,
  };
};

export const makeBundleLineItem = (bundleViewModel: PriceBundleViewModel) : BundleLineItem => {
  const totals = calculateQuoteTotals(Object.values(bundleViewModel.lineItems));
  return {
    id: nanoid(),
    bundleId: bundleViewModel.id,
    type: 'bundle',
    name: bundleViewModel.name,
    expanded: true,
    order: 0,
    quantity: 1,
    unitPriceCents: totals.subtotalCents,
    description: bundleViewModel.description,
    lineItems: mapValues(bundleViewModel.lineItems, (li) => ({
      id: li.id,
      type: 'price',
      name: li.name,
      order: li.order,
      description: li.description,
      unitPriceCents: li.unitPriceCents,
      unit: li.unit,
      quantity: li.quantity,
      priceId: li.priceId,
    } as PriceBookLineItem)),
  };
};

export const makePriceBundleViewModel = (
  bundle: PriceBundle, prices: Price[],
) : PriceBundleViewModel => {
  const lineItems = mapValues(bundle.lineItems, (lineItem) => {
    const price = prices.find((p) => p.id === lineItem.priceId);
    return {
      id: lineItem.id,
      type: 'price',
      quantity: lineItem.quantity,
      priceId: lineItem.priceId,
      order: lineItem.order,
      name: price.name,
      unitPriceCents: price.unitPriceCents,
    } as LineItem;
  });

  return {
    ...bundle,
    lineItems,
  };
};

export const makePriceBundleViewModels = (
  bundles: PriceBundle[], prices: Price[],
) : PriceBundleViewModel[] => {
  if (bundles.length === 0 || prices.length === 0) {
    return [];
  }
  return bundles.map((bundle) => makePriceBundleViewModel(bundle, prices));
};
