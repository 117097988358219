export const LinkStyles = {
  baseStyle: {
    fontFamily: 'body',
    fontWeight: 'semibold',
    textTransform: 'none',
    // textDecoration: 'none',
    _hover: {
      textDecoration: 'underline',
    },
  },
};
