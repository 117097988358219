import { createReducer } from '@reduxjs/toolkit';
import {
  currentUserActions,
} from '~/redux/currentUser/actions';
import SubscriptionActions from '~/redux/subscription/actions';
import { CurrentUser } from '~/types/currentUser';
import { PowerUpId } from '~/types/subscription';

export type UserState = CurrentUser;

export const currentUserReducer = createReducer(
  {} as UserState,
  (builder) => (builder
    .addCase(currentUserActions.updated, (state, action) => {
      const { staff, tenant, cognito } = action.payload;
      return {
        tenant: tenant ?? state.tenant,
        staff: staff ?? state.staff,
        cognito: cognito ?? state.cognito,
      };
    })
    .addCase(currentUserActions.tenantPlanActivated, (state) => ({
      ...state,
      tenant: {
        ...state.tenant,
        status: state.tenant.status === 'subscribed' ? state.tenant.status : 'subscriptionPending',
      },
    }))
    .addCase(currentUserActions.tenantPlanCancelled, (state) => ({
      ...state,
      tenant: {
        ...state.tenant,
        status: 'subscriptionCancelled',
      },
    }))
    .addCase(SubscriptionActions.powerUpAdded, (state, action) => ({
      ...state,
      tenant: {
        ...state.tenant,
        powerUps: {
          ...state.tenant.powerUps,
          ...(action.payload.data.powerUpId === PowerUpId.xero ? { xero: true } : {}),
          ...(action.payload.data.powerUpId === PowerUpId.liveGeo ? { geotechnical: true } : {}),
        },
      },
    }))
    .addCase(SubscriptionActions.powerUpRemoved, (state, action) => ({
      ...state,
      tenant: {
        ...state.tenant,
        powerUps: {
          ...state.tenant.powerUps,
          ...(action.payload.data.powerUpId === PowerUpId.xero ? { xero: false } : {}),
          ...(action.payload.data.powerUpId === PowerUpId.liveGeo ? { geotechnical: false } : {}),
        },
      },
    }))
    .addCase(currentUserActions.clear, () => ({
      tenant: null,
      staff: null,
      cognito: null,
    }))
  ),
);
