export const TableStyles = {
  baseStyle: {
    th: {
      fontFamily: '"Open Sans", sans-serif',
      fontSize: '12px',
      fontWeight: 'semibold',
      letterSpacing: 'normal',
    },

    table: {
      tableLayout: 'fixed',
      borderCollapse: 'separate',
      borderSpacing: '0',
    },
  },

  variants: {
    simple: {
      th: {
        color: 'magnetize.text-4',
        borderColor: 'magnetize.ui-3',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        borderBottomWidth: '1px',
        _first: {
          paddingLeft: '0',
        },
        _last: {
          paddingRight: '0',
        },
      },

      tr: {
      },

      td: {
        borderColor: 'magnetize.ui-3',
        borderBottomWidth: '1px',
        _first: {
          paddingLeft: '0',
        },
        _last: {
          paddingRight: '0',
        },
      },
    },
  },

  sizes: {
    md: {
      th: {
        fontSize: '12px',
      },
    },
  },
};
