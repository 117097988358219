import {
  MenuDivider, MenuItem, Stack, Table,
  Tbody, Td, Text, Th, Thead, Tr,
} from '@chakra-ui/react';
import React, {
  Dispatch, SetStateAction,
} from 'react';
import AvailabilityStatusBadge from '~/components/AvailabilityStatusBadge';
import ConfirmationDialog from '~/components/ConfirmationDialog';
import { KebabOptionsMenu } from '~/components/KebabOptionsMenu';
import { useModalManager } from '~/components/ModalManager';
import StaffActions from '~/redux/staff/actions';
import { useAppDispatch } from '~/redux/store';
import { Staff } from '~/types/staff';
import eventStopper from '~/utils/eventStopper';

interface StaffListProps {
  staff: Staff[];
  setSelected: Dispatch<SetStateAction<Staff>>;
}

const StaffList = ({ staff, setSelected }: StaffListProps) => {
  const dispatch = useAppDispatch();
  const modalManager = useModalManager();

  return (
    <Table size="md">
      <Thead>
        <Tr>
          <Th w="25%">Name</Th>
          <Th w="20%">Job Title</Th>
          <Th w="25%">Contact</Th>
          <Th w="15%">Role</Th>
          <Th w="15%">Status</Th>
          <Th w="10%" />
        </Tr>
      </Thead>
      <Tbody>
        { staff.map((item) => (
          <Tr
            _hover={{ bg: '#fafafa', cursor: 'pointer' }}
            transition="color 0.1s ease-out"
            key={item.id}
            onClick={() => {
              setSelected(item);
            }}
            role="group"
          >
            <Td>
              <Text>
                {item.name}
              </Text>
            </Td>
            <Td>
              <Text>
                {item.jobTitle}
              </Text>
            </Td>
            <Td>
              <Text>
                {item.phone}
              </Text>
              <Text>
                {item.email}
              </Text>
            </Td>
            <Td>
              {item.inviteEmailId && !item.lastAccessTimestamp && item.hasCognitoUserAccount
                ? <Text color="magnetize.text-4">Invite sent</Text>
                : <Text>{item.cognitoGroup || 'Contractor'}</Text>}

            </Td>
            <Td>
              <AvailabilityStatusBadge isAvailable={item.isAvailable} />
            </Td>
            <Td
              textAlign="end"
              {...eventStopper()}
            >
              <KebabOptionsMenu>
                <MenuItem onClick={() => setSelected(item)}>Edit</MenuItem>
                <MenuDivider my={0} />
                {item.hasCognitoUserAccount && (
                <MenuItem onClick={() => {
                  modalManager.open(ConfirmationDialog, {
                    title: 'Deactivate staff',
                    children: (
                      <Stack spacing="4">
                        <Text>
                          {`Deactivating ${item.name} will remove their access to Magnetize, but they will remain available for scheduling.`}
                        </Text>
                        <Text>
                          You can later invite them to join again, but they&apos;ll need to go
                          through the onboarding process again, setting up a new account.
                        </Text>
                      </Stack>),
                    confirmButtonLabel: 'Deactivate',
                    onConfirm: () => dispatch(StaffActions.deactivate({ staffId: item.id })),
                  });
                }}
                >
                  Deactivate user
                </MenuItem>
                )}
                {!item.hasCognitoUserAccount && (
                <MenuItem onClick={() => {
                  modalManager.open(ConfirmationDialog, {
                    title: 'Delete staff',
                    children: (
                      <Stack spacing="4">
                        <Text>
                          {`Deleting ${item.name} will remove them from Magnetize.`}
                        </Text>
                      </Stack>),
                    confirmButtonLabel: 'Delete',
                    onConfirm: () => dispatch(StaffActions.deactivate({
                      staffId: item.id,
                      archive: true,
                    })),
                  });
                }}
                >
                  Delete user
                </MenuItem>
                )}
              </KebabOptionsMenu>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default StaffList;
