import {
  Box,
  Flex, FormControl, FormLabel, Heading, Select, VStack, Image, Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import NextButton from '~/pages/WelcomeModal/components/NextButton';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import WelcomeModalActions from '~/redux/welcomeModal/actions';
import { selectWelcomeModalState } from '~/redux/welcomeModal/selectors';

const FinancialSoftware = (
  { totalSteps, stepNumber }
  : { totalSteps: number, stepNumber: number },
) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const welcomeState = useAppSelector((state) => selectWelcomeModalState(state));

  return (
    <Flex
      h="100%"
      flex={1}
    >
      <Flex
        direction="column"
        flex={1}
      >
        <VStack
          padding={20}
          alignItems="start"
          spacing={8}
          flex={1}
        >
          <Flex display="column">
            <Heading textTransform="none">Do you use accounting software?</Heading>
            <Heading
              pl={1}
              pt={2}
              textTransform="none"
              size="sm"
            >
              Connect Magnetize for easy billing
            </Heading>
          </Flex>
          <FormControl>
            <FormLabel>
              Financial Software
            </FormLabel>
            <Select
              placeholder="Select from the dropdown"
              value={welcomeState.financialSoftware}
              onChange={(e) => {
                dispatch(WelcomeModalActions.update({
                  update: {
                    financialSoftware: e.target.value,
                  },
                }));
              }}
            >
              <option value="none">None</option>
              <option value="xero">Xero</option>
              <option value="myob">MYOB</option>
              <option value="quickbooks">Quickbooks</option>
              <option value="cash-manager">Cash Manager</option>
              <option value="other">Other</option>
            </Select>
          </FormControl>

          <Box flex={1} />
          <FormControl>
            <NextButton
              isLoading={loading}
              isDisabled={loading}
              onClick={() => {
                setLoading(true);
                dispatch(WelcomeModalActions.complete());
              }}
            >
              Done!
            </NextButton>
          </FormControl>
          <Text color="magnetize.text-4" mt={8} width="100%" textAlign="center">
            {`step ${stepNumber} of ${totalSteps}`}
          </Text>
        </VStack>
      </Flex>
      <Flex
        flex={1}
        backgroundImage="url('/images/login-bg.jpg')"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        alignItems="flex-end"
        justifyContent="center"
      >
        <Image
          src="/images/welcomeModal/quote-invoice.png"
        />
      </Flex>
    </Flex>
  );
};

export default FinancialSoftware;
