import {
  Box, Button, GridItem, Heading, HStack, Icon,
  SkeletonText, Spacer, VStack,
} from '@chakra-ui/react';
import { find, keyBy, orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { MdFileDownload } from 'react-icons/md';
import { ReactComponent as DrillIcon } from '~/assets/live_geo_drill_icon.svg';
import Card from '~/components/Card';
import { CustomerFacingHeader } from '~/components/CustomerFacingHeader';
import ImageGallery from '~/components/ImageGallery';
import StatusBadge from '~/components/JobStatusBadge';
import NotFound from '~/components/NotFound';
import PagePrintPreview from '~/components/PagePrintPreview';
import TenantLogo from '~/components/TenantLogo';
import { formatJobCode, formatJobName } from '~/helpers/job';
import useTrackedAction from '~/hooks/useTrackedAction';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import OneColumnBottomBrand from '~/layouts/OneColumnBottomBrand';
import PrimarySecondaryColumns from '~/layouts/PrimarySecondaryColumns';
import { DrillVisual } from '~/pages/LiveGeo/components/DrillVisual';
import LiveGeoLogsTable from '~/pages/LiveGeo/components/LiveGeoLogsTable';
import Routes from '~/pages/routes';
import { CustomerLiveGeoActions } from '~/redux/customerLiveGeo/actions';
import { selectLiveGeoDataByToken } from '~/redux/customerLiveGeo/selectors';
import { triggerCustomerTenantFetch } from '~/redux/customerTenant/actions';
import { selectCustomerTenantByToken, selectCustomerTenantFetchState } from '~/redux/customerTenant/selectors';
import { LiveGeoActions } from '~/redux/liveGeo/actions';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import formatShortAddress from '~/utils/formatShortAddress';
import { useRouteParams } from '~/utils/RouteGenerator';

const CustomerLiveGeoLogsPage = () => {
  const dispatch = useAppDispatch();
  const { token } = useRouteParams(Routes.customerJobLiveGeo);

  const [selectedLogId, setSelectedLogId] = useState(null);

  const { data, isLoading: isLiveGeoLoading } = useTrackedFetch({
    key: `borehole-${token}`,
    trigger: () => CustomerLiveGeoActions.fetch({ token }),
    selector: (s) => selectLiveGeoDataByToken(s, token),
  });

  const tenant = useAppSelector((state) => selectCustomerTenantByToken(state, token));
  const powerUps = tenant?.powerUps;
  const {
    isLoading: isTenantLoading,
  } = useAppSelector((s) => selectCustomerTenantFetchState(s, token));

  useEffect(() => {
    dispatch(triggerCustomerTenantFetch({ token }));
  }, []);

  const boreholes = data?.boreholes ?? [];
  const equipment = data?.equipment ?? [];
  const drillingTechniques = data?.drillingTechniques ?? [];
  const soilTypes = data?.soilTypes ?? [];
  const casingTypes = data?.casingTypes ?? [];
  const refusalReasons = data?.refusalReasons ?? [];
  const clearanceTechniques = data?.clearanceTechniques ?? [];
  const surfaceTypes = data?.surfaceTypes ?? [];
  const backfillTypes = data?.backfillTypes ?? [];
  const piezoTypes = data?.piezoTypes ?? [];
  const cpts = data?.cpts ?? [];
  const job = data?.job;

  const isLoading = isLiveGeoLoading || isTenantLoading;

  const { trigger: onDownloadClicked, isLoading: isDownloading } = useTrackedAction({
    trigger: () => LiveGeoActions.downloadPDF({
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      tenant,
      customer: data.customer,
      job: data.job,
      boreholes,
      cpts,
      equipment,
      contact: data.contact,
      coordinateSystem: tenant?.coordinateSystem,
      drillingTechniques,
      soilTypes,
      casingTypes,
      clearanceTechniques,
      refusalReasons,
      backfillTypes,
      surfaceTypes,
      piezoTypes,
    }),
  });

  if (!isLoading && ((!boreholes || boreholes.length === 0) && (!cpts || cpts.length === 0))) {
    return <NotFound>Live geo logs not found</NotFound>;
  }

  const selectedBorehole = find(boreholes, (bh) => bh.id === selectedLogId);

  const selectedCpt = find(cpts, (bh) => bh.id === selectedLogId);

  return (
    <>
      <CustomerFacingHeader
        headerTitle={(
          <HStack align="stretch">
            <Icon boxSize={10} as={DrillIcon} />
            <Heading size="lg" fontSize="2.3rem">Live Geo logs</Heading>
            <Spacer />
          </HStack>
        )}
        actions={powerUps?.geotechnicalReport ? [
          <Button
            size="lg"
            height="3.8rem"
            px="2rem"
            isLoading={isDownloading}
            isDisabled={isDownloading}
            onClick={onDownloadClicked}
            key="live-geo-report"
          >
            <Icon fontSize="1.5rem" as={MdFileDownload} mr={2} />
            LIVE GEO REPORT
          </Button>,
        ] : undefined}
      />
      <OneColumnBottomBrand>
        <PrimarySecondaryColumns>

          <GridItem gridArea="primary">
            <PagePrintPreview>
              {isLoading ? (
                <Box>
                  <SkeletonText noOfLines={2} />
                </Box>
              ) : (
                <>
                  <HStack mb={10} align="stretch">
                    <VStack spacing={2} align="start">
                      <Heading
                        size="md"
                        pt={1}
                        pb={1}
                      >
                        {`${formatJobCode(job.code)} - ${formatJobName(job)}`}
                      </Heading>
                      <Heading size="sm" pb={5} fontWeight="bold" textTransform="none" color="magnetize.brand-4">{formatShortAddress(job.siteAddress)}</Heading>
                      {(boreholes.some((b) => !b.endDepth) || cpts.some((b) => !b.endDepth)) ? (<StatusBadge status="inProgress" />) : <StatusBadge status="complete" />}
                    </VStack>
                    <Spacer flex={9} />
                    <VStack
                      spacing={6}
                      flex={4}
                      align="start"
                    >
                      <TenantLogo logo={tenant?.logo} />
                    </VStack>
                  </HStack>
                  <LiveGeoLogsTable
                    cpts={cpts}
                    boreholes={boreholes}
                    equipment={equipment}
                    selectedId={selectedLogId}
                    onLogSelected={setSelectedLogId}
                    coordinateSystem={tenant?.coordinateSystem}
                    drillingTechniques={keyBy(drillingTechniques, 'id')}
                    soilTypes={keyBy(soilTypes, 'id')}
                    casingTypes={keyBy(casingTypes, 'id')}
                    clearanceTechniques={keyBy(clearanceTechniques, 'id')}
                    refusalReasons={keyBy(refusalReasons, 'id')}
                    surfaceTypes={keyBy(surfaceTypes, 'id')}
                    backfillTypes={keyBy(backfillTypes, 'id')}
                    piezoTypes={keyBy(piezoTypes, 'id')}
                  />
                </>
              )}
            </PagePrintPreview>
          </GridItem>
          <GridItem gridArea="secondary">
            {(isLoading || selectedBorehole?.undergroundServiceClearanceTechnique) && (
            <Card width={340} pb={12}>
              {isLoading
                ? <SkeletonText noOfLines={2} />
                : (
                  <DrillVisual
                    drillingTechniques={keyBy(drillingTechniques, 'id')}
                    clearanceTechniques={keyBy(clearanceTechniques, 'id')}
                    soilTypes={keyBy(soilTypes, 'id')}
                    equipment={equipment}
                    borehole={find(boreholes, (bh) => bh.id === selectedLogId)}
                  />
                )}
            </Card>
            )}

            {!!selectedBorehole && (
            <Box w={340} mt={8}>
              <ImageGallery
                imageWidth={109}
                files={[
                  ...selectedBorehole.photos,
                  ...selectedBorehole.undergroundServicePhotos,
                  ...(selectedBorehole.surfaceTypePhotos || []),
                  ...orderBy([
                    ...(selectedBorehole.waterLevel ? [{
                      depth: selectedBorehole.waterLevel,
                      photos: selectedBorehole.waterLevelPhotos,
                    }] : []),
                    ...Object.values(selectedBorehole.piezoLogs)
                      .map(({ startDepth, photos }) => ({ depth: startDepth, photos })),
                    ...Object.values(selectedBorehole.soilLayers)
                      .map(({ startDepth, photos }) => ({ depth: startDepth, photos })),
                    ...Object.values(selectedBorehole.sptTests)
                      .map(({ depth, photos }) => ({ depth, photos })),
                    ...Object.values(selectedBorehole.pushTubes)
                      .map(({ depth, photos }) => ({ depth, photos })),
                    ...Object.values(selectedBorehole.drillLogs)
                      .map(({ depth, photos }) => ({ depth, photos })),
                    ...Object.values(selectedBorehole.backfillLayers)
                      .map(({ startDepth, photos }) => ({ depth: startDepth, photos })),
                  ], (x) => x.depth)
                    .flatMap((p) => (p.photos || [])),
                  ...selectedBorehole.endPhotos,
                ]}
              />
            </Box>
            )}
            {!!selectedCpt && (
            <Box w={340} mt={8}>
              <ImageGallery
                imageWidth={109}
                files={[
                  ...selectedCpt.photos,
                  ...selectedCpt.undergroundServicePhotos,
                  ...selectedCpt.logPhotos,
                ]}
              />
            </Box>
            )}
          </GridItem>
        </PrimarySecondaryColumns>
      </OneColumnBottomBrand>
    </>
  );
};

export default CustomerLiveGeoLogsPage;
