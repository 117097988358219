import {
  AlertDialog, AlertDialogBody, AlertDialogCloseButton, AlertDialogContent, AlertDialogFooter,
  AlertDialogHeader, AlertDialogOverlay, Box, Button, Img, Text,
} from '@chakra-ui/react';
import React, { useRef } from 'react';

const noop = () => {};

const TurnOnDialog = ({
  isOpen,
  onTurnOn,
  onClose = noop,
}: {
  isOpen?: boolean;
  onClose?: () => void;
  onTurnOn: () => void;
}) => {
  const cancelRef = useRef<HTMLButtonElement>(null);

  return (
    <AlertDialog
      isOpen={isOpen ?? false}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      size="3xl"
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontWeight="bold">
            Turn on auto-send?
          </AlertDialogHeader>
          <AlertDialogCloseButton tabIndex={-1} />
          <AlertDialogBody>
            <Text size="lg">
              Turning on this default setting means that Live Geo reports will
              be automatically sent to the job contact when drilling starts on an job.
            </Text>
            <Text size="lg" fontWeight="bold" mt={2}>
              NOTE: You will still be able to turn auto-send ON/OFF for specific jobs.
            </Text>
            <Box padding={5} backgroundColor="magnetize.ui-2" justifyContent="center" mt={10}>
              <Img
                width="250px"
                alt="preview"
                margin="auto"
                src="/images/live-geo/customer-facing-preview.png"
              />
            </Box>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              size="lg"
              onClick={onTurnOn}
            >
              Turn On
            </Button>
            <Button ref={cancelRef} variant="ghost" onClick={onClose}>
              cancel
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default TurnOnDialog;
