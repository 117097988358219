import { Input } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import {
  FaCogs, FaHardHat, FaSuitcase, FaTruck,
} from 'react-icons/fa';
import { ReactComponent as DrillIcon } from '~/assets/live_geo_drill_icon.svg';
import TileSelector from '~/pages/WelcomeModal/components/TileSelector';

interface IndustrySelectorProps {
  value: string,
  otherValue: string,
  onChange: (category: string, otherValue: string) => void;
}

const IndustrySelector = ({ value, otherValue, onChange } : IndustrySelectorProps) => {
  const options = useMemo(() => ([
    {
      label: 'Drilling & Geotechnical',
      icon: DrillIcon,
    },
    {
      label: 'Civil or Construction',
      icon: FaHardHat,
    },
    {
      label: 'Trades and Services',
      icon: FaSuitcase,
    },
    {
      label: 'Manufacturing',
      icon: FaCogs,
    },
    {
      label: 'Transport',
      icon: FaTruck,
    },
    {
      label: 'Other',
      icon: null,
    },
  ].map((o) => ({ ...o, isSelected: o.label === value }))), [value]);

  return (
    <>
      <TileSelector options={options} onClick={(option) => onChange(option.label, otherValue)} />
      {value === 'Other' && (
      <Input
        mt={4}
        value={otherValue}
        onChange={(e) => {
          onChange(value, e.target.value);
        }}
        placeholder="&#34;Other&#34; what industry are you in?"
      />
      )}
    </>
  );
};
export default IndustrySelector;
