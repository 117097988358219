import { Address } from '~/types/address';

/**
 * Tries to extract an Address structure from a Google Maps place
 *
 * This is a bit of a messy problem, as addresses are complicated. The current
 * implementation of this works 'okay' for NZ addresses, but we need to
 * reconsider our data representation for international locations.
 *
 * @param addressComponents the geocoder/places address info
 * @returns an Address structure, filled out as best as possible
 */
function makeAddressFromPlaceResult(
  addressComponents: google.maps.GeocoderAddressComponent[],
  geometry: google.maps.places.PlaceGeometry,
): Address {
  // Finds the first component of the address matching the type. `long` and
  // `short` are way more convenient
  const part = (type: string) => addressComponents.find((c) => c.types.includes(type));
  const long = (type: string) => part(type)?.long_name;
  const short = (type: string) => part(type)?.short_name;

  // Joins multiple parts, IF all parts are defined, else undefined
  const join = (parts: (string | undefined)[]) => (parts.every((x) => !!x) ? parts.join(' ') : undefined);

  // The idea here is that we may need to try multiple patterns here to extract
  // the address info we want, because the data isn't consistent. Chaining
  // together part and join with ?? allows fallback forms to be tried
  return {
    line1: join([short('street_number'), long('route')])
      // Places can locate a street without a number, which is probably
      // important for us to support
      ?? long('route')
      ?? '',
    line2: long('sublocality') ?? '',
    city: long('locality')
      ?? long('administrative_area_level_1')
      ?? '',
    postCode: long('postal_code') ?? '',
    country: long('country') ?? '',
    latitude: geometry?.location.lat(),
    longitude: geometry?.location.lng(),
  };
}

export default makeAddressFromPlaceResult;
