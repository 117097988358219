import {
  Box,
  Center,
  Flex, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Spinner, Stack,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import CarouselButtons from '~/components/CarouselButtons';
import ImageGallery from '~/components/ImageGallery';
import UploadedFile from '~/types/uploaded-file';

interface ImagePreviewModalProps {
  images: UploadedFile[];
  initialImage?: UploadedFile;
  onClose?: () => void;
}

const ImagePreviewModal = ({
  images, initialImage = images[0], onClose,
}: ImagePreviewModalProps) => {
  const [currentImage, setCurrentImage] = useState<UploadedFile>(initialImage);
  const gotoNextImage = () => {
    setCurrentImage((current) => images[images.indexOf(current) + 1] ?? images[0]);
  };

  const gotoPrevImage = () => {
    setCurrentImage((current) => images[images.indexOf(current) - 1] ?? images[images.length - 1]);
  };

  return (
    <Modal autoFocus={false} isOpen onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent onKeyDown={(e) => {
        if (e.key === 'ArrowLeft') {
          gotoPrevImage();
        } else if (e.key === 'ArrowRight') {
          gotoNextImage();
        }
      }}
      >
        <ModalCloseButton />
        <ModalBody px={2} py={8}>
          <Stack spacing={8}>
            <CarouselButtons onBack={gotoPrevImage} onForward={gotoNextImage}>
              <Flex
                px={2}
                justifyContent="center"
                alignItems="stretch"
                minHeight="65vh"
                height="65vh"
                maxHeight="65vh"
              >
                <Image
                  src={currentImage.url}
                  flex="1"
                  width="100%"
                  height="auto"
                  fit="scale-down"
                  fallback={<Center><Spinner color="brand.100" size="xl" /></Center>}
                />
              </Flex>

            </CarouselButtons>
            <Box px={12}>
              <ImageGallery
                files={images}
                selectedImage={currentImage}
                onFileClicked={(file) => setCurrentImage(file)}
                imageWidth={72}
              />
            </Box>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ImagePreviewModal;
