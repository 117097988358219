import { toDate } from 'date-fns';
import {
  groupBy, sortBy, uniqBy,
} from 'lodash';
import { ResourceSummary, ResourceEventSummary } from '~/types/resource';
import { JobEvent, JobResourceEvent, ScheduleEvent } from '~/types/ScheduleEvent';

export interface JobEventViewModel {
  id: string;
  jobId: string;
  start: Date;
  end: Date;
  resources: ResourceEventSummary[];
  jobEvent: JobEvent;
}

export const makeViewModelsForRealJobEvents = (
  scheduleEvents: ScheduleEvent[],
  allResources: ResourceSummary[],
): JobEventViewModel[] => {
  const jobEvents = scheduleEvents.filter((e) => e.type === 'job') as JobEvent[];
  const resourceEvents = scheduleEvents.filter((e) => e.type === 'job-resource') as JobResourceEvent[];
  const resourceEventsByParent = groupBy(resourceEvents, (r) => r.parentId);

  return jobEvents.map((jev) => {
    const children: JobResourceEvent[] = resourceEventsByParent[jev.id] ?? [];
    return ({
      id: jev.id,
      jobId: jev.jobId,
      start: toDate(jev.start),
      end: toDate(jev.end),
      jobEvent: jev,
      resources: uniqBy(
        sortBy(
          children.map((c) => allResources
            .find((r) => c.resourceId === r.id))
            .filter((r) => !!r)
            .map((r) => ({
              ...r,
              resourceEvents: children.filter((c) => c.resourceId === r.id),
            })),
          // Put people first, then sort by name
          (r) => ['staff', 'equipment'].indexOf(r.type),
          (r) => r.displayName,
        ), (r) => r.id,
      ),
    });
  });
};
