import { put } from 'redux-saga/effects';
import { queueWork } from '~/redux/queuedTasks/actions';
import { addLocalEdit, removeLocalEdit } from '~/redux/localEdits/reducer';
import { Dictionary } from '~/types/helpers';

export default function* queueWithLocalEdit<T extends Dictionary<any>>(
  id: string,
  patch: T,
  worker: (patch: T) => any,
) {
  const localEdit = addLocalEdit({ id, patch });
  yield put(localEdit);
  try {
    yield queueWork(() => worker(patch));
  } finally {
    yield put(removeLocalEdit({ id, editId: localEdit.payload.editId }));
  }
}
