import { orderBy } from 'lodash';
import {
  all, call, put, select, takeEvery,
} from 'redux-saga/effects';
import {
  createQuote, getJobQuote, getJobQuotes,
  postDiscardDraft, postSendQuote, postAcceptQuote,
  postRevertAcceptQuote,
} from '~/api/quoteApi';
import { addQuoteToJob, updateJob } from '~/redux/jobs/actions';
import { QuoteActions } from '~/redux/quote/actions';
import { showErrorToast, showSuccessToast } from '~/toast';
import { Quote } from '~/types/quote';
import fetchJson from '~/utils/fetchJson';
import { performFetchSaga } from '~/utils/performFetchSaga';
import { takeEveryTracked } from '~/utils/performTrackedSaga';
import queueWork from '~/utils/queueWork';
import { selectQuoteById } from '~/redux/quote/selectors';
import { getDraftFromQuote } from '~/utils/quoteEdit';

const watchFetchQuote = takeEvery(
  QuoteActions.fetch,
  function* handle(action) {
    const { jobId, quoteId } = action.payload;
    yield performFetchSaga({
      key: `quote-${quoteId}`,
      * saga() {
        const quote: Quote = yield call(getJobQuote, jobId, quoteId);
        yield put(QuoteActions.quotesUpdated({
          quotes: [quote],
        }));
      },
    });
  },
);

const watchFetchJobQuotes = takeEvery(
  QuoteActions.fetchForJob,
  function* handle(action) {
    const { jobId, force } = action.payload;
    yield performFetchSaga({
      key: `job-quotes-${jobId}`,
      force: force ?? false,
      staleTime: 10000,
      * saga() {
        const quotes: Quote[] = yield call(getJobQuotes, jobId);
        yield put(QuoteActions.quotesUpdated({ quotes }));
        yield put(QuoteActions.jobQuotesUpdated({
          jobId,
          quoteIds: orderBy(quotes, (q) => q.code, 'desc')
            .map((q) => q.id),
        }));
      },
    });
  },
);

const watchEdit = takeEveryTracked(
  QuoteActions.edit,
  function* handle(action) {
    const { quoteId, jobId, edits } = action.payload.data;

    const quote = yield select((state) => selectQuoteById(state, quoteId));
    yield put(QuoteActions.quotesUpdated({
      quotes: [{
        ...quote,
        draft: {
          ...(quote?.draft || getDraftFromQuote(quote)),
          ...edits,
        },
      }],
    }));

    yield queueWork(function* worker() {
      try {
        yield call(() => fetchJson(`/api/jobs/${jobId}/quotes/${quoteId}`, {
          method: 'PATCH',
          body: edits,
        }));
      } catch (error) {
        showErrorToast(error);
      }
    });
  },
);

const watchSendQuote = takeEveryTracked(
  QuoteActions.send,
  function* handle(action) {
    try {
      const {
        jobId, quoteId, recipients, message, subject,
      } = action.payload.data;
      const [nextQuote] = yield all([
        call(() => postSendQuote(jobId, quoteId, recipients, subject, message)),
        put(updateJob({
          id: jobId,
          status: 'quoted',
        })),
      ]);

      showSuccessToast(`Quote sent to ${recipients[0].name} '${recipients[0].email}'`);

      yield put(QuoteActions.quotesUpdated({ quotes: [nextQuote] }));
    } catch (error) {
      showErrorToast(error);
      throw error;
    }
  },
);
const watchAcceptQuote = takeEveryTracked(
  QuoteActions.accept,
  function* handle(action) {
    try {
      const {
        jobId, quoteId,
      } = action.payload.data;
      const [nextQuote] = yield all([
        call(() => postAcceptQuote(jobId, quoteId)),
      ]);

      showSuccessToast('Quote accepted on behalf of the customer');

      yield put(QuoteActions.quotesUpdated({ quotes: [nextQuote] }));
    } catch (error) {
      showErrorToast(error);
      throw error;
    }
  },
);

const watchRevertAcceptQuote = takeEveryTracked(
  QuoteActions.revertAccept,
  function* handle(action) {
    try {
      const {
        jobId, quoteId,
      } = action.payload.data;
      const [nextQuote] = yield all([
        call(() => postRevertAcceptQuote(jobId, quoteId)),
      ]);

      showSuccessToast('Quote reverted to not accepted');

      yield put(QuoteActions.quotesUpdated({ quotes: [nextQuote] }));
    } catch (error) {
      showErrorToast(error);
      throw error;
    }
  },
);

const watchDiscardDraft = takeEveryTracked(
  QuoteActions.discardDraft,
  function* handle(action) {
    try {
      const {
        jobId, quoteId,
      } = action.payload.data;
      const [nextQuote] = yield all([
        call(() => postDiscardDraft(jobId, quoteId)),
      ]);

      showSuccessToast('Quote discarded');

      if (nextQuote) {
        yield put(QuoteActions.quotesUpdated({ quotes: [nextQuote] }));
      } else {
        yield put(QuoteActions.quoteRemoved({ jobId, quoteId }));
      }
    } catch (error) {
      showErrorToast(error);
      throw error;
    }
  },
);

const watchAddQuoteToJob = takeEveryTracked(
  addQuoteToJob,
  function* handle(action) {
    const { jobId } = action.payload.data;

    yield call(() => createQuote(jobId));
    yield put(QuoteActions.fetchForJob({ jobId, force: true }));
  },
);

export default function* handleQuotes() {
  yield all([
    watchFetchQuote,
    watchFetchJobQuotes,
    watchEdit,
    watchDiscardDraft,
    watchSendQuote,
    watchAcceptQuote,
    watchRevertAcceptQuote,
    watchAddQuoteToJob,
  ]);
}
