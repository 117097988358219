import { createReducer } from '@reduxjs/toolkit';
import { TrackedActions } from '~/redux/tracked/actions';
import { Dictionary } from '~/types/helpers';

interface TrackedActionState {
  id: string;
  state: string;
  result?: any;
}

const trackedReducer = createReducer<Dictionary<TrackedActionState>>(
  {},

  (builder) => {
    builder.addCase(TrackedActions.started, (state, action) => ({
      ...state,
      [action.payload.trackId]: {
        ...state[action.payload.trackId],
        state: 'saving',
      },
    }));

    builder.addCase(TrackedActions.completed, (state, action) => ({
      ...state,
      [action.payload.trackId]: {
        ...state[action.payload.trackId],
        state: 'saved',
      },
    }));

    builder.addCase(TrackedActions.setResult, (state, action) => ({
      ...state,
      [action.payload.trackId]: {
        ...state[action.payload.trackId],
        result: action.payload.result,
      },
    }));

    builder.addCase(TrackedActions.errored, (state, action) => ({
      ...state,
      [action.payload.trackId]: {
        ...state[action.payload.trackId],
        state: 'error',
      },
    }));
  },
);

export default trackedReducer;
