import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import createSagaMiddleware from 'redux-saga';

import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import persistStore from 'redux-persist/es/persistStore';
import { isEqual } from 'lodash';
import { rootReducer, RootState } from './reducer';
import rootSaga from '~/redux/saga';
import SubscriptionActions from '~/redux/subscription/actions';

export type { RootState } from './reducer';

const persistedReducer = persistReducer({
  key: 'root',
  storage,
  whitelist: ['prefs'],
  stateReconciler: autoMergeLevel2,
}, rootReducer);

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: persistedReducer,
  middleware: [
    ...getDefaultMiddleware({
      thunk: false,
      serializableCheck: {
        ignoredActions: [
          'persist/PERSIST',
          // THis is because we need to pass yucky stripe context through
          SubscriptionActions.setDefaultPaymentMethod.type,
        ],
      },
    }),
    sagaMiddleware,
  ],
});

persistStore(store);

sagaMiddleware.run(rootSaga);

export default store;

// A more convenient hook
export const useAppDispatch = () => useDispatch<typeof store.dispatch>();

function deepEqual<T>(a: T, b: T): boolean {
  return isEqual(a, b);
}

type EqualityFunction<T> = (a: T, b: T) => boolean;

export function useAppSelector<T>(
  selector: (state: RootState) => T,
  equalityFn?: 'strict' | 'deep' | EqualityFunction<T>,
): T {
  let comparer: EqualityFunction<T>;
  if (equalityFn === 'deep') {
    comparer = deepEqual;
  } else if (equalityFn === 'strict') {
    comparer = undefined;
  } else {
    comparer = equalityFn;
  }

  return useSelector<RootState, T>(
    selector,
    comparer,
  );
}
