import { createAction } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import { jobsReducer } from './jobs/reducer';
import pricesReducer from './prices/reducer';
import trackedReducer from './tracked/reducer';
import { localEditsReducer } from '~/redux/localEdits/reducer';
import syncStateReducer from '~/redux/queuedTasks/reducer';
import { quotesReducer } from '~/redux/quote/reducer';
import { invoicesReducer } from '~/redux/invoices/reducer';
import scheduleEventReducer from '~/redux/schedule/reducer';
import { customerQuotesReducer } from '~/redux/customerQuotes/reducer';
import { customerTenantReducer } from '~/redux/customerTenant/reducer';
import { currentUserReducer } from '~/redux/currentUser/reducer';
import fetchReducer from '~/redux/fetch/reducer';
import { tagsReducer } from '~/redux/tags/reducer';
import jobCalendarReducer from '~/redux/jobCalendar/reducer';
import resourceCalendarReducer from '~/redux/resourceCalendar/reducer';
import prefsReducer from '~/redux/prefs/reducer';
import timeReducer from '~/redux/timesheets/reducer';
import consumablesReducer from '~/redux/consumables/reducer';
import staffReducer from '~/redux/staff/reducer';
import xeroReducer from '~/redux/xero/reducer';
import logsReducer from '~/redux/logs/reducer';
import equipmentReducer from '~/redux/equipment/reducer';
import customersReducer from '~/redux/customers/reducer';
import subscriptionReducer from '~/redux/subscription/reducer';
import welcomeModalReducer from '~/redux/welcomeModal/reducer';
import calendarTourReducer from '~/redux/calendarTour/reducer';
import priceBundleReducer from '~/redux/priceBundle/reducer';
import customerContactReducer from '~/redux/customerContacts/reducer';
import liveGeoReducer from '~/redux/liveGeo/reducer';
import customerLiveGeoReducer from '~/redux/customerLiveGeo/reducer';
import taxRateReducer from '~/redux/taxRates/reducer';
import currenciesReducer from '~/redux/currencies/reducer';
import { drillingTechniquesReducer } from '~/redux/drillingTechniques/reducer';
import { clearanceTechniquesReducer } from '~/redux/clearanceTechniques/reducer';
import { casingTypesReducer } from '~/redux/casingTypes/reducer';
import { soilTypesReducer } from '~/redux/soilTypes/reducer';
import { refusalReasonsReducer } from '~/redux/refusalReasons/reducer';
import { surfaceTypesReducer } from '~/redux/surfaceTypes/reducer';
import { backfillTypesReducer } from '~/redux/backfillTypes/reducer';
import { piezoTypesReducer } from '~/redux/piezoTypes/reducer';

const combinedReducer = combineReducers({
  jobs: jobsReducer,
  quotes: quotesReducer,
  invoices: invoicesReducer,
  customerQuotes: customerQuotesReducer,
  customerTenant: customerTenantReducer,
  prices: pricesReducer,
  priceBundles: priceBundleReducer,
  logs: logsReducer,
  tracked: trackedReducer,
  localEdits: localEditsReducer,
  syncState: syncStateReducer,
  scheduleEvents: scheduleEventReducer,
  currentUser: currentUserReducer,
  fetch: fetchReducer,
  tags: tagsReducer,
  jobCalendar: jobCalendarReducer,
  resourceCalendar: resourceCalendarReducer,
  prefs: prefsReducer,
  time: timeReducer,
  consumables: consumablesReducer,
  equipment: equipmentReducer,
  customers: customersReducer,
  customerContacts: customerContactReducer,
  staff: staffReducer,
  xero: xeroReducer,
  subscription: subscriptionReducer,
  welcomeModal: welcomeModalReducer,
  calendarTour: calendarTourReducer,
  liveGeo: liveGeoReducer,
  customerLiveGeo: customerLiveGeoReducer,
  taxRates: taxRateReducer,
  currencies: currenciesReducer,
  drillingTechniques: drillingTechniquesReducer,
  soilTypes: soilTypesReducer,
  clearanceTechniques: clearanceTechniquesReducer,
  casingTypes: casingTypesReducer,
  piezoTypes: piezoTypesReducer,
  refusalReasons: refusalReasonsReducer,
  surfaceTypes: surfaceTypesReducer,
  backfillTypes: backfillTypesReducer,
});

export type RootState = ReturnType<typeof combinedReducer>;

export const reset = createAction('RESET');

export const rootReducer = (state: RootState, action) => {
  let nextState = state;
  if (action.type === reset.type) {
    nextState = undefined;
  }
  return combinedReducer(nextState, action);
};
