import { Flex, Icon, Text } from '@chakra-ui/react';
import { AddOutlined } from '@material-ui/icons';
import React, { useRef } from 'react';
import FileUpload, { FileUploadRef } from '~/components/FileUpload';
import Thumbnail from '~/components/Thumbnail';
import UploadedFile from '~/types/uploaded-file';

interface OrgLogoFileUploadProps {
  logo: UploadedFile;
  onFileUploaded: (file: UploadedFile) => void;
  onFileRemoved: (file: UploadedFile) => void;
}

const OrgLogoFileUpload = ({ logo, onFileUploaded, onFileRemoved } : OrgLogoFileUploadProps) => {
  const fileUploadRef = useRef<FileUploadRef>();

  return (
    <Flex direction="column">
      <FileUpload
        singleImageOnly
        ref={fileUploadRef}
        onFileUploaded={onFileUploaded}
      >
        {logo ? (
          <Flex
            flex="1"
            alignItems="center"
            justifyContent="center"
            my={10}
            mx={4}
          >
            <Thumbnail
              imageWidth={230}
              imageHeight={110}
              file={logo}
              onRemove={(file) => {
                fileUploadRef.current.reset();
                onFileRemoved(file);
              }}
            />
          </Flex>
        ) : (
          <Flex
            flex="1"
            alignItems="center"
            justifyContent="center"
            direction="column"
            height="150px"
          >
            <Flex
              color="magnetize.text-1"
              alignItems="center"
              justifyContent="center"
            >
              <Icon as={AddOutlined} />
            </Flex>
            <Text textAlign="center" mt={2} color="magnetize.text-3">
              Drag and drop
              <br />
              or select a file to upload
            </Text>
          </Flex>
        )}
      </FileUpload>
    </Flex>
  );
};

export default OrgLogoFileUpload;
