import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import ContactInfoComponent from '~/components/ContactInfo';
import UserAvatar from '~/components/UserAvatar';
import { CustomerFacingSentBy } from '~/types/contact-info';

const CustomerRepDetails = ({ rep } : { rep: CustomerFacingSentBy }) => (
  <>
    {rep && (
      <Flex>
        <UserAvatar size="lg" name={rep.name} src={rep.profilePicture?.thumbUrl || rep.profilePicture?.url} mr={4} />
        <Box>
          <ContactInfoComponent value={{
            ...rep,
            name: `Prepared by ${rep.name}`,
          }}
          />
        </Box>
      </Flex>
    )}
  </>
);

export default CustomerRepDetails;
