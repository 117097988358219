import {
  Box, FormControl, Grid, GridItem, Heading, Icon, SkeletonText, Stack, Text,
} from '@chakra-ui/react';
import { ChevronRight } from '@material-ui/icons';
import { MdPersonAdd } from 'react-icons/md';
import { sortBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import LinkButton from '~/components/LinkButton';
import SearchSelect from '~/components/SearchSelect';
import useTrackedAction from '~/hooks/useTrackedAction';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import { CustomerContactActions } from '~/redux/customerContacts/actions';
import { selectCustomerContactById } from '~/redux/customerContacts/selectors';
import { CustomerActions } from '~/redux/customers/actions';
import { selectCustomerById } from '~/redux/customers/selectors';
import { XeroActions } from '~/redux/xero/actions';
import { XeroContact } from '~/types/xero';
import formatShortAddress from '~/utils/formatShortAddress';
import { getAddressFromXeroContact, rankMatchingXeroContacts } from '~/utils/matchXeroContactToCustomer';

interface XeroContactMatchProps {
  customerId: string;
  xeroContactId: string;
  onChange: (xeroContactId: string) => void;
}

const XeroContactMatch = ({
  customerId, xeroContactId, onChange,
}: XeroContactMatchProps) => {
  const {
    data: customer, isLoading: isLoadingCustomer,
  } = useTrackedFetch({
    trigger: () => CustomerActions.fetch({ customerId }),
    selector: (s) => selectCustomerById(s, customerId),
    key: `customer-${customerId}`,
    enabled: !!customerId,
  });

  const {
    data: mainContact, isLoading: isLoadingContact,
  } = useTrackedFetch({
    trigger: () => CustomerContactActions.fetch({ id: customer?.mainContactId }),
    selector: (s) => selectCustomerContactById(s, customer?.mainContactId),
    key: `contact-${customer?.mainContactId}`,
    enabled: !!customer?.mainContactId,
  });

  const { data: sortedXeroContacts, isLoading: isLoadingXeroContacts } = useTrackedFetch({
    key: 'xero-contacts',
    trigger: () => XeroActions.fetchContacts(),
    selector: (state) => {
      const { contacts } = state.xero;
      return sortBy(contacts, (c) => c.name);
    },
  });

  useEffect(() => {
    if (customer?.xeroId) {
      onChange(customer.xeroId);
    }
  }, [customer]);

  const selectedXeroContact = useMemo(() => {
    if (sortedXeroContacts && xeroContactId) {
      const contact = sortedXeroContacts.find((c) => c.contactID === xeroContactId);
      return contact ? {
        ...contact,
        address: formatShortAddress(getAddressFromXeroContact(contact)),
      } : null;
    }
    return null;
  }, [sortedXeroContacts, xeroContactId]);

  const suggestedMatches = useMemo(() => {
    if (customer && sortedXeroContacts) {
      return rankMatchingXeroContacts(customer, mainContact, sortedXeroContacts)
        .filter((c) => c.totalScore >= 0.9)
        .slice(0, 3)
        .map((c) => ({
          ...c,
          address: formatShortAddress(getAddressFromXeroContact(c.contact)),
        }));
    }
    return [];
  }, [customer, sortedXeroContacts]);

  const xeroContact = useMemo(
    () => sortedXeroContacts.find((x) => x.contactID === xeroContactId),
    [sortedXeroContacts, xeroContactId],
  );

  const [userSaysNoneOfAbove, setUserSaysNoneOfAbove] = useState(false);

  useEffect(() => {
    const hasReallyGoodMatch = suggestedMatches[0] && suggestedMatches[0].totalScore >= 2;
    if (hasReallyGoodMatch && !userSaysNoneOfAbove && !xeroContactId) {
      onChange?.(suggestedMatches[0].contact.contactID);
    }
  }, [suggestedMatches, xeroContactId, userSaysNoneOfAbove]);

  const { trigger: createContactInXero, isLoading: isCreatingContact } = useTrackedAction({
    trigger: () => XeroActions.createContact({
      name: customer.companyName,
      address: customer.companyAddress,
      phoneNumber: mainContact?.phone || null,
      emailAddress: yup.string().email().isValidSync(mainContact?.email)
        ? mainContact.email
        : null,
    }),

    onSuccess: (result: XeroContact) => {
      onChange(result.contactID);
      setUserSaysNoneOfAbove(false);
    },
  });

  const isLoading = isLoadingCustomer || isLoadingContact || isLoadingXeroContacts;
  const contactWithExactName = sortedXeroContacts?.find((c) => c.name === customer?.companyName);
  const canCreateContact = customer && !contactWithExactName;
  const showSuggestions = !isLoading && suggestedMatches.length > 0 && !userSaysNoneOfAbove;

  return (
    <Grid gridTemplateColumns="5fr 1fr 5fr" columnGap={0}>
      <GridItem>
        <Heading size="sm" mb="4">Job Customer</Heading>
      </GridItem>
      <GridItem />
      <GridItem>
        <Heading size="sm" mb="4">Xero Customer *</Heading>
      </GridItem>
      {isLoading && (
      <>
        <GridItem>
          <SkeletonText />
        </GridItem>
        <GridItem />
        <GridItem>
          <SkeletonText />
        </GridItem>
      </>
      )}
      {!isLoading && (
        <>
          <GridItem>
            <Box
              borderColor="magnetize.ui-3"
              borderTopWidth="1px"
              borderBottomWidth="1px"
              py="4"
            >
              {customerId ? (
                <>
                  <Text>{customer.companyName}</Text>
                  <Text as="span" fontSize="xs" color="magnetize.text-4">
                    {customer.companyAddress && (
                    <Text>{formatShortAddress(customer.companyAddress)}</Text>
                    )}
                    {mainContact?.email && <Text>{mainContact?.email}</Text>}
                  </Text>
                </>
              ) : (
                <Text color="magnetize.text-4">No customer added to this job</Text>
              )}
            </Box>

            {canCreateContact && (
            <Box mt={6}>
              <Text mb={1}>No matches?</Text>
              <LinkButton
                leftIcon={<Icon fontSize="1.2rem" mb="1px" as={MdPersonAdd} />}
                isGreen
                noUnderline
                isDisabled={isCreatingContact}
                isLoading={isCreatingContact}
                onClick={createContactInXero}
              >
                Create customer in Xero
              </LinkButton>
              <Text color="magnetize.text-4">
                Magnetize will create
                {' '}
                {customer.companyName}
                {' '}
                in Xero for this invoice.
              </Text>
            </Box>
            )}
          </GridItem>
          <GridItem display="flex" justifyContent="center">
            <Icon as={ChevronRight} mt={2} fontSize="2rem" color="magnetize.ui-4" />
          </GridItem>
          <GridItem>
            {showSuggestions && (
            <>
              <Stack spacing="2">
                {suggestedMatches?.map((x) => {
                  const isActive = x.contact.contactID === xeroContactId;
                  return (
                    <Box
                      borderColor={isActive ? 'magnetize.brand-4' : 'magnetize.ui-3'}
                      borderWidth="1px"
                      borderRadius="xs"
                      cursor="pointer"
                      _hover={{
                        borderColor: !isActive ? 'magnetize.ui-4' : undefined,
                      }}
                      px="4"
                      py="2"
                      minH="70px"
                      key={x.contact.contactID}
                      onClick={() => onChange(x.contact.contactID)}
                    >
                      <Text>{x.contact.name}</Text>
                      {x.address && <Text fontSize="xs" color="magnetize.text-4">{x.address}</Text>}
                      {x.contact.emailAddress && <Text fontSize="xs" color="magnetize.text-4">{x.contact.emailAddress}</Text>}
                    </Box>
                  );
                })}
              </Stack>
              <LinkButton
                isGreen
                noUnderline
                onClick={() => {
                  setUserSaysNoneOfAbove(true);
                  onChange(null);
                }}
                mt="4"
              >
                change
              </LinkButton>
            </>
            )}
            {!showSuggestions && selectedXeroContact && (
            <>
              <Box
                borderColor="magnetize.brand-4"
                borderWidth="1px"
                borderRadius="xs"
                cursor="pointer"
                px="4"
                py="2"
                minH="70px"
                key={selectedXeroContact.contactID}
                onClick={() => onChange?.(selectedXeroContact.contactID)}
              >
                <Text>{selectedXeroContact.name}</Text>
                {selectedXeroContact.address
              && (
              <Text fontSize="xs" color="magnetize.text-4">
                {selectedXeroContact.address}
              </Text>
              )}
                {selectedXeroContact.emailAddress
              && (
              <Text fontSize="xs" color="magnetize.text-4">
                {selectedXeroContact.emailAddress}
              </Text>
              )}
              </Box>
              <LinkButton
                isGreen
                noUnderline
                onClick={() => {
                  setUserSaysNoneOfAbove(true);
                  onChange(null);
                }}
                mt="4"
              >
                change
              </LinkButton>
            </>
            )}
            {!showSuggestions && !xeroContactId && (
            <Stack spacing="8">
              <FormControl>
                <SearchSelect
                  placeholder="Search customers in Xero"
                  value={xeroContact}
                  options={sortedXeroContacts}
                  getOptionLabel={(c) => c.name}
                  getOptionValue={(c) => c.contactID}
                  onChange={(c) => onChange(c.contactID)}
                />
              </FormControl>
            </Stack>
            )}
          </GridItem>
        </>
      )}
    </Grid>
  );
};

export default XeroContactMatch;
