import React from 'react';
import {
  Box,
  Heading,
  HStack, Icon, Text,
} from '@chakra-ui/react';
import {
  CheckCircleRounded, EditRounded,
} from '@material-ui/icons';
import { format } from 'date-fns';
import { parseISO } from 'date-fns/esm';
import CurrencyText from '~/components/CurrencyText';
import calculateQuoteTotals from '~/utils/calculateQuoteTotals';
import { Invoice } from '~/types/invoice';

const InvoiceStatusLabel = ({ status, isXero } : { status : 'draft' | 'sent', isXero:boolean }) => {
  switch (status) {
    case 'sent':
      return (
        <Text fontWeight="bold" color="magnetize.brand-4">
          <Icon as={CheckCircleRounded} mr={1} mb="3px" />
          {isXero ? 'Sent to Xero' : 'Sent'}
        </Text>
      );

    default:
      return (
        <Text fontWeight="bold">
          <Icon as={EditRounded} mr={1} mb="2px" />
          Draft
        </Text>
      );
  }
};

const InvoiceStatus = ({ invoice }: { invoice: Invoice }) => {
  let secondaryText = '';
  if (invoice.status !== 'draft') {
    secondaryText = invoice.toDate ? `invoiced to ${format(parseISO(invoice.toDate), 'dd MMM yyyy')}` : 'sent';
  }

  const { totalCents } = calculateQuoteTotals(
    Object.values(invoice.lineItems),
    invoice.taxRate,
  );

  return (
    <HStack alignItems="center" py={1}>
      <Heading as="h3" size="md" minW="95px" textAlign="left">
        {invoice.status === 'draft' ? 'CREATE INVOICE' : `INVOICE-${invoice.code}`}
      </Heading>
      <HStack alignItems="baseline" flex={1}>
        {invoice.status !== 'draft' && (
        <>
          <InvoiceStatusLabel status={invoice.status} isXero={!!invoice.xeroInvoiceId} />
          <Text>
            for
            {' '}
            <CurrencyText fontWeight="bold" value={totalCents} />
            {' '}
            (incl. tax)
          </Text>
        </>
        )}

        <Box flex={1} />
        <Text fontWeight="normal" paddingRight={3}>
          {secondaryText}
        </Text>
      </HStack>
    </HStack>
  );
};

export default InvoiceStatus;
