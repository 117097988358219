import { createAction } from '@reduxjs/toolkit';
import { PriceBundle } from '~/types/priceBundle';

const PriceBundleActions = {
  fetch: createAction('priceBundles/fetch'),
  save: createAction<{ priceBundle: PriceBundle }>('priceBundles/save'),
  updated: createAction<{ priceBundles: PriceBundle[] }>('priceBundles/updated'),
  archive: createAction<{ priceBundleId: string }>('priceBundles/archive'),
};

export default PriceBundleActions;
