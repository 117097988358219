import { HStack, Link } from '@chakra-ui/react';
import React from 'react';
import { magnetizeWebsiteUrl, magnetizeDocsUrl } from '~/helpers/config';
import Routes from '~/pages/routes';

const HelpLinks = () => (
  <HStack mt={14} spacing={8}>
    <Link
      href={`${magnetizeDocsUrl}`}
      target="_blank"
      color="magnetize.text-4"
    >
      Get help
    </Link>
    <Link
      href={`${magnetizeWebsiteUrl}contact/`}
      target="_blank"
      color="magnetize.text-4"
    >
      Support
    </Link>
  </HStack>
);

const PrivacyPolicyLink = () => (
  <Link
    href={Routes.privacyPolicy({})}
    target="_blank"
    color="magnetize.brand-4"
  >
    Privacy Policy
  </Link>
);

const TermsOfUseLink = () => (
  <Link
    href={`${magnetizeWebsiteUrl}eula/`}
    target="_blank"
    color="magnetize.brand-4"
  >
    Terms of Use
  </Link>
);

export {
  HelpLinks,
  TermsOfUseLink,
  PrivacyPolicyLink,
};
