import {
  AlertDialog, AlertDialogBody, AlertDialogCloseButton, AlertDialogContent, AlertDialogFooter,
  AlertDialogHeader, AlertDialogOverlay, Button, Text,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import useTrackedAction from '~/hooks/useTrackedAction';
import { selectCurrentUser } from '~/redux/currentUser/selectors';
import { updateJob } from '~/redux/jobs/actions';
import { useAppSelector } from '~/redux/store';
import { showToast } from '~/toast';

const noop = () => {};

const TurnOnDialog = ({
  jobId,
  isOpen,
  onClose = noop,
}: {
  jobId: string;
  isOpen?: boolean;
  onClose?: () => void;
}) => {
  const currentUser = useAppSelector(selectCurrentUser);
  const cancelRef = useRef<HTMLButtonElement>(null);

  const { trigger: onTurnOffConfirm, isLoading: isSaving } = useTrackedAction({
    trigger: () => updateJob({
      id: jobId,
      liveGeoAutoSend: {
        enabled: false,
        setByStaffId: currentUser.staff.id,
      },
    }),
    onSuccess: () => {
      showToast({
        description: 'Auto-send turned off',
        status: 'success',
      });
    },
  });

  return (
    <AlertDialog
      isOpen={isOpen ?? false}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      size="3xl"
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontWeight="bold">
            Turn off auto-send?
          </AlertDialogHeader>
          <AlertDialogCloseButton tabIndex={-1} />
          <AlertDialogBody>
            <Text size="lg">
              Logs won&apos;t be shared with your customer automatically. You&apos;ll still
              be able to share the Live Geo report with your customer when you&apos;re ready.
            </Text>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              size="lg"
              onClick={() => {
                onTurnOffConfirm();
                onClose();
              }}
              disabled={isSaving}
            >
              Turn Off
            </Button>
            <Button ref={cancelRef} variant="ghost" onClick={onClose}>
              cancel
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default TurnOnDialog;
