import React from 'react';
import {
  HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter,
  ModalHeader, ModalOverlay, Text, VStack, Image, Button, Link,
} from '@chakra-ui/react';
import GreenButton from '~/components/GreenButton';
import { useAppSelector } from '~/redux/store';
import { selectIsTenantTrial } from '~/redux/currentUser/selectors';
import formatCurrency from '~/utils/formatCurrency';
import useTrackedAction from '~/hooks/useTrackedAction';
import SubscriptionActions from '~/redux/subscription/actions';
import { showSuccessToast } from '~/toast';
import { PowerUpId } from '~/types/subscription';

export const ActivateLiveGeoModal = ({
  onCancel, onActivated,
}: {
  onCancel: () => void,
  onActivated: () => void,
}) => {
  const isTrial = useAppSelector(selectIsTenantTrial);
  // Will be loaded by parent
  const prices = useAppSelector((state) => state.subscription.prices);
  const powerUp = prices.powerUps.find((p) => p.powerUpId === PowerUpId.liveGeo);

  const { trigger: addPowerUp, isLoading } = useTrackedAction({
    trigger: () => SubscriptionActions.addPowerUp({ powerUpId: PowerUpId.liveGeo }),
    onSuccess: () => {
      showSuccessToast('Live Geo power up activated');
      onActivated();
    },
  });

  return (
    <Modal isOpen onClose={onCancel} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader paddingBottom={5}>
          <HStack>
            <Image src={powerUp.image} height="5.5rem" mr={2} />
            <VStack textAlign="left" alignItems="flex-start">
              <Text margin={0} fontWeight="bold">Live Geo</Text>
              <Text marginTop="0 !important" fontSize={14}>Drilling & geotechnical reporting</Text>
            </VStack>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody paddingBottom={6}>
          <Text fontSize={14}>
            Use Live Geo to record drilling and geotechnical logs from your mobile
            device, any where, any time. Then, autogenerate a professional report
            to your customer in just a click.
            <Link
              ml={1}
              href="https://www.magnetize.co.nz/live-geo"
              target="_blank"
              color="magnetize.brand-4"
            >
              Learn more.
            </Link>
          </Text>
          <Image my={8} src="/images/live-geo/live-geo-marketing-card.png" alt="Live Geo" />
        </ModalBody>
        <ModalFooter>
          <GreenButton
            size="lg"
            onClick={addPowerUp}
            isLoading={isLoading}
          >
            {isTrial ? 'Activate on free trial' : `Activate for ${formatCurrency(powerUp.priceCents, undefined, true)}/month`}
          </GreenButton>
          <Button variant="ghost" onClick={onCancel} disabled={isLoading}>
            cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
