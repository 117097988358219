import { createReducer } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';
import StaffActions from '~/redux/staff/actions';
import { Dictionary } from '~/types/helpers';
import { Staff } from '~/types/staff';

interface StaffState {
  entities: Dictionary<Staff>;
}

const staffReducer = createReducer<StaffState>({
  entities: {},
}, (builder) => {
  builder.addCase(StaffActions.updated, (state, action) => {
    const { staff } = action.payload;
    return ({
      entities: {
        ...state.entities,
        ...keyBy(staff, (p) => p.id),
      },
    });
  });
});

export default staffReducer;
