import {
  Button, Flex, GridItem, Heading, HStack, SkeletonText, Spacer, Text, VStack,
} from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import React, { useMemo, useState } from 'react';
import Card from '~/components/Card';
import FusedSearch from '~/components/FusedSearch';
import PageTabs from '~/components/PageTabs';
import StatusAlert from '~/components/StatusAlert';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import PageHeader from '~/layouts/PageHeader';
import PrimarySecondaryColumns from '~/layouts/PrimarySecondaryColumns';
import { EquipmentActions } from '~/redux/equipment/actions';
import { selectEquipment } from '~/redux/equipment/selectors';
import { Equipment } from '~/types/equipment';
import EquipmentEditModal from './EquipmentEditModal';
import EquipmentList from './EquipmentList';

const fuseSettings = {
  includeScore: true,
  ignoreLocation: true,
  threshold: 0.3,
  keys: [
    { name: 'nickname', weight: 1 },
    { name: 'name', weight: 1 },
    { name: 'code', weight: 1 },
    { name: 'description', weight: 1 },
  ],
};

const EquipmentPage = () => {
  const {
    data, isLoading, isError, errorMessage,
  } = useTrackedFetch({
    trigger: () => EquipmentActions.fetch(),
    selector: selectEquipment,
    key: 'equipment',
  });

  const [tabIndex, setTabIndex] = React.useState(0);
  const [selected, setSelected] = useState<Equipment>(null);
  const [modalTitle, setModalTitle] = useState<string>();

  const tabsWithEquipment = useMemo(() => {
    const all = data;
    const inService = data.filter((e) => e.isAvailable);
    const outOfService = data.filter((e) => !e.isAvailable);
    return [
      { title: 'View All', count: all.length, equipment: all },
      { title: 'In Service', count: inService.length, equipment: inService },
      { title: 'Out of Service', count: outOfService.length, equipment: outOfService },
    ];
  }, [data]);

  const addEquipment = () => {
    setSelected({
      id: nanoid(),
      name: '',
      isAvailable: true,
      tags: [],
    });
    setModalTitle('Add equipment');
  };

  const duplicateEquipment = (item: Equipment) => {
    setSelected({
      ...item,
      id: nanoid(),
      isAvailable: true,
      isDeleted: false,
    });
    setModalTitle('Duplicate equipment');
  };

  const editEquipment = (item: Equipment) => {
    setSelected(item);
    setModalTitle('Edit equipment');
  };

  const [filteredEquipment, setFilteredEquipment] = useState(tabsWithEquipment[tabIndex].equipment);

  return (
    <>
      <PrimarySecondaryColumns>
        <PageHeader>
          <Flex justifyContent="space-between">
            <Heading size="lg">
              Equipment
            </Heading>
          </Flex>
        </PageHeader>
        <GridItem gridArea="primary-start / primary-start / secondary-end / secondary-end">
          <Flex direction="column">
            <PageTabs
              tabs={tabsWithEquipment.map((t) => ({
                title: t.title,
                count: t.count,
              }))}
              isLoading={isLoading}
              index={tabIndex}
              onChange={(index) => setTabIndex(index)}
            >
              <Button mb="1" onClick={addEquipment}>
                Add Equipment
              </Button>
            </PageTabs>
            <Card>
              <VStack align="stretch" spacing={8}>
                <HStack align="stretch">
                  <Flex flex={1}>
                    <FusedSearch
                      items={tabsWithEquipment[tabIndex].equipment}
                      onChange={(e) => setFilteredEquipment(e)}
                      fuseSettings={fuseSettings}
                    />
                  </Flex>
                  <Spacer flex={1} />
                </HStack>

                <SkeletonText isLoaded={!isLoading} noOfLines={3}>
                  {!isLoading && !isError && (
                  <EquipmentList
                    equipment={filteredEquipment}
                    onEdit={editEquipment}
                    onDuplicate={duplicateEquipment}
                  />
                  )}
                  {!isLoading && !isError && filteredEquipment?.length === 0 && (
                  <Text mt={4}>
                    There is no equipment to display.
                  </Text>
                  )}
                  {isError && (
                  <StatusAlert title={errorMessage} />
                  )}
                </SkeletonText>
              </VStack>
            </Card>
          </Flex>
        </GridItem>
      </PrimarySecondaryColumns>
      {selected !== null
      && (
      <EquipmentEditModal
        equipment={selected}
        title={modalTitle}
        onClose={() => {
          setSelected(null);
        }}
      />
      )}
    </>
  );
};

export default EquipmentPage;
