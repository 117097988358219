import {
  all,
  call, put, takeEvery,
} from 'redux-saga/effects';
import { fetchCompleted, fetchStarted } from '~/redux/fetch/actions';
import { jobFetchSuccess, triggerJobFetch, triggerJobFetchMany } from '~/redux/jobs/actions';
import { bulkFetchJobs, getJob } from '~/api/jobApi';
import { showToast } from '~/toast';
import { Job } from '~/types/job';
import { performFetchSaga } from '~/utils/performFetchSaga';

const handleJobFetch = takeEvery(
  triggerJobFetch,
  function* handle(action) {
    const { jobId } = action.payload;
    yield performFetchSaga({
      key: jobId,
      * saga() {
        const job: Job = yield call(getJob, jobId);
        yield put(jobFetchSuccess({ jobId, data: job }));
      },
    });
  },
);

export function* fetchManyJobsSaga(jobIds: string[]) {
  try {
    yield put(fetchStarted({ keys: jobIds, at: Date.now() }));
    const jobs: Job[] = yield call(bulkFetchJobs, jobIds);
    yield put(fetchCompleted({ keys: jobIds, at: Date.now() }));
    yield all(jobs.map((j) => put(jobFetchSuccess(({ jobId: j.id, data: j })))));
  } catch (error) {
    showToast({
      title: 'Error when bulk-fetching jobs',
      description: String(error),
      status: 'error',
    });
  }
}

const handleFetchManyJobs = takeEvery(
  triggerJobFetchMany,
  function* handle(action) {
    const { jobIds } = action.payload;
    // TODO: not sure if we should bother tracking this fetch state.
    // We may need to debounce this if multiple views are triggering
    // these fetches and we don't want redundant calls.
    yield call(fetchManyJobsSaga, jobIds);
  },
);

export default all([
  handleJobFetch,
  handleFetchManyJobs,
]);
