import { keyBy } from 'lodash';
import { call, put, takeEvery } from 'redux-saga/effects';
import { fetchPartiallyCompleted } from '~/redux/fetch/actions';
import { JobActions } from '~/redux/jobs/actions';
import { Job } from '~/types/job';
import fetchJson from '~/utils/fetchJson';
import { performFetchSaga } from '~/utils/performFetchSaga';

const key = 'jobs';
const watchFetchAll = takeEvery(
  JobActions.fetchAll,
  function* handler() {
    yield performFetchSaga({
      key,
      staleTime: 30000,
      * saga() {
        let jobsById = {};
        let id:string;
        let modifiedTimestamp:number;

        let maxCounter = 0;
        while (maxCounter < 20) {
          const res:{ id?:string, modifiedTimestamp?:number, jobs:Job[] } = yield call(
            fetchJson,
            `/api/jobs/paginated?pageSize=500${id && modifiedTimestamp ? `&modifiedTimestamp=${modifiedTimestamp}&id=${id}` : ''}`,
          );
          modifiedTimestamp = res.modifiedTimestamp;
          id = res.id;

          jobsById = {
            ...jobsById,
            ...keyBy(res.jobs, (j) => j.id),
          };

          yield put(JobActions.jobsListUpdated({
            jobsById,
          }));
          yield put(fetchPartiallyCompleted({ keys: [key], at: Date.now() }));

          if (!id) {
            break;
          }
          maxCounter += 1;
        }
      },
    });
  },
);

export default watchFetchAll;
