import { HStack, Text } from '@chakra-ui/react';
import React from 'react';
import nztm from 'nztm';
import { Location as LocationType } from '~/types/geotechnical';

const toDegreesMinutesAndSeconds = (coordinate:number) => {
  const absolute = Math.abs(coordinate);
  const degrees = Math.floor(absolute);
  const minutesNotTruncated = (absolute - degrees) * 60;
  const minutes = Math.floor(minutesNotTruncated);
  const seconds = Math.floor((minutesNotTruncated - minutes) * 60);

  return `${degrees}° ${minutes}' ${seconds}`;
};

const Wgs84Location = ({ location }: { location: LocationType }) => {
  if (!location) return null;
  const latitude = toDegreesMinutesAndSeconds(location.latitude);
  const latitudeCardinal = location.latitude >= 0 ? 'N' : 'S';

  const longitude = toDegreesMinutesAndSeconds(location.longitude);
  const longitudeCardinal = location.longitude >= 0 ? 'E' : 'W';

  return (
    <HStack fontWeight="normal" align="stretch" spacing={6}>
      <HStack align="stretch">
        <Text>{latitude}</Text>
        <Text color="magnetize.text-4">{latitudeCardinal}</Text>
      </HStack>
      <HStack align="stretch">
        <Text>{longitude}</Text>
        <Text color="magnetize.text-4">{longitudeCardinal}</Text>
      </HStack>
    </HStack>
  );
};

const Nztm2000Location = ({ location }: { location: LocationType }) => {
  if (!location) return null;
  const coordinates = nztm.geod_nztm(location.latitude, location.longitude);

  return (
    <HStack fontWeight="normal" align="stretch" spacing={6}>
      <HStack align="stretch">
        <Text color="magnetize.text-4">E</Text>
        <Text>{Math.round(coordinates.ce)}</Text>
      </HStack>
      <HStack align="stretch">
        <Text color="magnetize.text-4">N</Text>
        <Text>{Math.round(coordinates.cn)}</Text>
      </HStack>
    </HStack>
  );
};

export const Location = (
  { location, coordinateSystem }
  : { location: LocationType, coordinateSystem: string },
) => {
  if (coordinateSystem === 'NZTM2000') {
    return <Nztm2000Location location={location} />;
  }
  return <Wgs84Location location={location} />;
};
