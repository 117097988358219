import { isWithinInterval } from 'date-fns';
import { min } from 'lodash';
import { Job, JobLoggedStats } from '~/types/job';
import { Price } from '~/types/price';
import { parseISODateLocal } from '~/utils/parseTime';

export const formatJobCode = (code: number, prefix = '#JOB-') : string => {
  const paddedCode = String(code).padStart(3, '0');
  return prefix + paddedCode;
};

export const formatJobName = (
  job: { name?: string, serviceType?: string },
) : string => job?.name ?? job?.serviceType ?? '';

export const getInvoiced = ({ job, taxInclusive = false }) => ({
  hasDraftInvoices: job.hasDraftInvoices,
  invoiced: (taxInclusive
    ? job.sentInvoicesValue?.subTotal + job.sentInvoicesValue.tax
    : job.sentInvoicesValue?.subTotal) || 0,
  invoicedTo: job.invoicedTo,
  invoicedProportion: job.invoicedProportion || 0,
});

export const getQuoted = ({ job, taxInclusive = false }) => {
  const isBudget = !job?.needsQuote;

  const quotedList = isBudget
    ? [job.acceptedQuotesValue, job.sentQuotesValue, job.draftQuotesValue, job.expiredQuotesValue]
    : [job.acceptedQuotesValue];

  return {
    quoted: quotedList
      .filter((x) => x)
      .reduce((acc, val) => acc + (taxInclusive ? val.subTotal + val.tax : val.subTotal), 0),
  };
};

export const getLogged = ({ job, interval, prices }
:{ job: Job, interval?: Interval, prices: Price[] })
: JobLoggedStats => {
  if (interval && interval.end < interval.start) {
    throw Error('End date cannot be before start date');
  }

  const logs = (job.logs || [])
    .filter((l) => !interval || isWithinInterval(parseISODateLocal(l.date), interval));

  return {
    total: logs
      .filter((l) => l.priceId && prices.find((p) => p.id === l.priceId))
      .reduce(
        (acc, l) => acc + l.quantity * prices.find((p) => p.id === l.priceId)?.unitPriceCents,
        0,
      ),
    count: logs.length,
    earliestEntry: min(logs.map((l) => parseISODateLocal(l.date))),
  };
};

export const getIsArchivable = ({ job, quotes } : { job: Job, quotes: any }) => {
  // Archive job enabling conditions:
  // Check if job status is anything other than 'Ready to schedule', 'Quote sent', and 'On hold'.
  const hasArchivableJobStatus = ['draft', 'schedule', 'quote', 'quoted', 'onHold'].includes(job.status);
  // Check if job has time/equipment/consumable logs.
  const hasLogs = !!(job.logs && job.logs.length > 0);
  // Check if there are quotes with 'sent' or 'accepted' status (active quotes).
  const hasSentOrAcceptedQuotes = (quotes && quotes.length)
    ? !!(quotes.some((q) => q.status === 'sent' || q.status === 'accepted'))
    : false;
  // Set archivable status based on the above conditions.
  return (hasArchivableJobStatus && !(hasLogs || hasSentOrAcceptedQuotes));
};

export const getJobOptionAffordance = ({ job, quotes, option }: {
  job: Job,
  quotes: any,
  option: string,
}) => {
  if (['delete', 'archive'].includes(option)) {
    // Check if job has time/equipment/consumable logs.
    const hasLogs = !!(job.logs && job.logs.length > 0);
    if (hasLogs) {
      return `This job has logs and cannot be ${option}d`;
    }

    // Check if there are quotes with 'sent' or 'accepted' status (active quotes).
    const hasActiveQuotes = (quotes && quotes.length)
      ? !!(quotes.some((q) => q.status === 'sent' || q.status === 'accepted'))
      : false;
    if (hasActiveQuotes) {
      return `This job has an active quote and cannot be ${option}d`;
    }

    // Check if job status is "scheduled".
    const isScheduled = job.status === 'scheduled';
    if (isScheduled) {
      return `This job has a scheduled event and cannot be ${option}d`;
    }
  }
  return null;
};
