import { nanoid } from '@reduxjs/toolkit';
import { omit } from 'lodash';
import {
  all, call, put, takeEvery,
} from 'redux-saga/effects';
import {
  triggerRefusalReasonsFetch,
  refusalReasonsLoaded,
  deleteRefusalReason,
  refusalReasonDeleted,
  refusalReasonUpdated,
  updateRefusalReason,
} from '~/redux/refusalReasons/actions';
import { showErrorToast, showToast } from '~/toast';
import { RefusalReason } from '~/types/refusalReason';
import fetchJson from '~/utils/fetchJson';
import { performFetchSaga } from '~/utils/performFetchSaga';

const watchFetch = takeEvery(
  triggerRefusalReasonsFetch,
  function* handle(action) {
    const { force } = action.payload;

    yield performFetchSaga({
      key: force ? nanoid() : 'refusal-reasons',
      staleTime: 10000,
      * saga() {
        const tags: RefusalReason[] = yield call(
          fetchJson,
          '/api/geotechnical/refusal-reasons',
        );
        yield put(refusalReasonsLoaded(tags));
      },
    });
  },
);
const watchUpdate = takeEvery(
  updateRefusalReason,
  function* handler(action) {
    const refusalReason = action.payload;
    const id = refusalReason.id ?? nanoid();

    try {
      const nextJobTag = yield call(() => fetchJson(
        `/api/geotechnical/refusal-reasons/${id}`, {
          method: 'PUT',
          body: omit(refusalReason, 'id'),
        },
      ));
      yield put(refusalReasonUpdated(nextJobTag));
      showToast({
        status: 'success',
        title: 'Refusal reason saved',
      });
    } catch (error) {
      showToast({
        status: 'error',
        title: 'Saving refusal reason failed',
        description: String(error),
      });
    }
  },
);

const watchDelete = takeEvery(
  deleteRefusalReason,
  function* handle(action) {
    const { id } = action.payload;

    try {
      yield call(() => fetchJson(`/api/geotechnical/refusal-reasons/${id}`, {
        method: 'DELETE',
      }));
      yield put(refusalReasonDeleted({ id }));
      showToast({
        status: 'success',
        title: 'Refusal reason deleted',
      });
    } catch (e) {
      showErrorToast(e, {
        title: 'Could not delete refusal reason',
      });
    }
  },
);

export default function* saga() {
  yield all([
    watchFetch,
    watchUpdate,
    watchDelete,
  ]);
}
