import React from 'react';
import CalendarCard from '~/components/Calendar/CalendarCard';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import { CustomerActions } from '~/redux/customers/actions';
import { selectCustomerById } from '~/redux/customers/selectors';
import { triggerJobFetch } from '~/redux/jobs/actions';
import { selectJobById } from '~/redux/jobs/selectors';
import { JobStatusInfo } from '~/types/job';
import { ScheduleEvent } from '~/types/ScheduleEvent';
import { getJobCardText } from '~/utils/getJobCardText';

interface ScheduleEventCardProps {
  event: ScheduleEvent;
  isActive?: boolean;
}

const ScheduleEventCard = ({ event, isActive = true }: ScheduleEventCardProps) => {
  const { jobId } = event;

  const { data: jobSummary, isLoading: isLoadingJobs } = useTrackedFetch({
    key: jobId,
    trigger: () => triggerJobFetch({ jobId }),
    selector: (state) => selectJobById(state, jobId),
  });

  const {
    data: customer, isLoading: isLoadingCustomers,
  } = useTrackedFetch({
    trigger: () => CustomerActions.fetch({ customerId: jobSummary?.customerId }),
    selector: (s) => selectCustomerById(s, jobSummary?.customerId),
    key: `customer-${jobSummary?.customerId}`,
  });

  const { mainText, secondaryText } = getJobCardText(jobSummary, customer);

  const statusColor = JobStatusInfo[jobSummary?.status]?.color ?? 'transparent';
  const isLoading = isLoadingJobs || isLoadingCustomers;

  return (
    <CalendarCard
      isLoading={isLoading}
      mainText={mainText ?? ''}
      secondaryText={secondaryText ?? ''}
      statusColor={statusColor}
      isActive={isActive}
    />
  );
};

export default ScheduleEventCard;
