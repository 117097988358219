import {
  Button, Flex, GridItem, Heading, HStack, Icon, SkeletonText, Spacer, Text,
} from '@chakra-ui/react';
import { AddOutlined } from '@material-ui/icons';
import { nanoid } from '@reduxjs/toolkit';
import React, { useMemo, useState } from 'react';
import Card from '~/components/Card';
import LinkButton from '~/components/LinkButton';
import PageTabs from '~/components/PageTabs';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import PageHeader from '~/layouts/PageHeader';
import PrimarySecondaryColumns from '~/layouts/PrimarySecondaryColumns';
import BundleEditModal from '~/pages/PriceBook/BundleEditModal';
import { makePriceBundleViewModels, PriceBundleViewModel } from '~/pages/PriceBook/BundleViewModel';
import PriceFilter from '~/pages/PriceBook/PriceFilter';
import PriceBundleActions from '~/redux/priceBundle/actions';
import { selectPriceBundles } from '~/redux/priceBundle/selectors';
import PriceActions from '~/redux/prices/actions';
import { selectAllPrices, selectPrices } from '~/redux/prices/selectors';
import { useAppSelector } from '~/redux/store';
import { Price } from '~/types/price';
import { PriceBundle } from '~/types/priceBundle';
import PriceEditModal from './PriceEditModal';
import PriceList from './PriceList';

const PriceBookPage = () => {
  const { data: prices, isLoading: isPricesLoading } = useTrackedFetch<Price[]>({
    key: 'prices',
    selector: (state) => selectPrices(state),
    trigger: () => PriceActions.fetch(),
    equalityMode: 'deep',
  });

  const { data: priceBundles, isLoading: isPriceBundlesLoading } = useTrackedFetch<PriceBundle[]>({
    key: 'price-bundles',
    selector: (state) => selectPriceBundles(state),
    trigger: () => PriceBundleActions.fetch(),
    equalityMode: 'deep',
  });

  const allPrices = useAppSelector((s) => selectAllPrices(s));
  const bundleViewModels = useMemo(
    () => makePriceBundleViewModels(priceBundles, allPrices),
    [priceBundles, allPrices],
  );

  const isLoading = isPricesLoading || isPriceBundlesLoading;

  const [selectedBundle, setSelectedBundle] = useState<PriceBundleViewModel>(null);
  const [bundleModalTitle, setBundleModalTitle] = useState<string>();

  const addBundle = () => {
    setSelectedBundle({
      id: nanoid(),
      name: '',
      description: '',
      lineItems: {},
      isDeleted: false,
      isAvailable: true,
    });
    setBundleModalTitle('Create new bundle');
  };

  const editPriceBundle = (bundle: PriceBundleViewModel) => {
    setSelectedBundle(bundle);
    setBundleModalTitle('Edit bundle');
  };

  const duplicatePriceBundle = (bundle: PriceBundleViewModel) => {
    setSelectedBundle({
      ...bundle,
      id: nanoid(),
      isDeleted: false,
    });
    setBundleModalTitle('Duplicate bundle');
  };

  const [selectedPrice, setSelectedPrice] = useState<Price>(null);
  const [priceModalTitle, setPriceModalTitle] = useState<string>();
  const [tabIndex, setTabIndex] = useState(0);

  const addPrice = () => {
    setSelectedPrice({
      id: nanoid(),
      name: '',
      description: '',
      unitPriceCents: 0,
      tags: [],
      unit: null,
      category: 'labour',
      code: '',
      isAvailable: true,
    });
    setPriceModalTitle('Add rate');
  };

  const editPrice = (price: Price) => {
    setSelectedPrice(price);
    setPriceModalTitle('Edit rate');
  };

  const duplicatePrice = (price: Price) => {
    setSelectedPrice({
      ...price,
      id: nanoid(),
      isDeleted: false,
    });
    setPriceModalTitle('Duplicate rate');
  };

  const isEmpty = !isLoading && prices.length === 0 && priceBundles.length === 0;
  const [visiblePrices, setVisiblePrices] = useState(prices);
  const [visibleBundles, setVisibleBundles] = useState(bundleViewModels);

  return (
    <>
      <PrimarySecondaryColumns>
        <PageHeader>
          <Flex justifyContent="space-between">
            <Heading size="lg">
              Finance
            </Heading>
          </Flex>
        </PageHeader>
        <GridItem gridArea="primary-start / primary-start / secondary-end / secondary-end">
          <Flex direction="column">
            <PageTabs
              tabs={[
                { title: 'Pricing' },
                { title: 'Quotes', isDisabled: true },
                { title: 'Invoices', isDisabled: true },
              ]}
              index={tabIndex}
              onChange={(index) => setTabIndex(index)}
            />
            <Card>
              <HStack align="stretch" mb={8}>
                <PriceFilter
                  prices={prices}
                  bundles={bundleViewModels}
                  onChange={(p, b) => {
                    setVisiblePrices(p);
                    setVisibleBundles(b);
                  }}
                />
                <Spacer flex="1" />
                <Flex direction="column">
                  <Button mb="1" onClick={addPrice}>
                    Add Rate
                  </Button>
                  <LinkButton
                    onClick={addBundle}
                    leftIcon={<Icon fontSize="md" as={AddOutlined} />}
                    isGreen
                    noUnderline
                  >
                    create bundle
                  </LinkButton>
                </Flex>
              </HStack>
              <SkeletonText isLoaded={!isLoading} noOfLines={3}>
                {!isLoading && (
                <PriceList
                  bundles={visibleBundles}
                  prices={visiblePrices}
                  onEditPrice={editPrice}
                  onDuplicatePrice={duplicatePrice}
                  onEditPriceBundle={editPriceBundle}
                  onDuplicatePriceBundle={duplicatePriceBundle}
                />
                )}
                {isEmpty && (
                <Text mt={4}>
                  There are no prices to display.
                </Text>
                )}
              </SkeletonText>
            </Card>
          </Flex>
        </GridItem>
      </PrimarySecondaryColumns>
      {selectedPrice !== null
      && (
      <PriceEditModal
        price={selectedPrice}
        title={priceModalTitle}
        onClose={() => {
          setSelectedPrice(null);
        }}
      />
      )}
      {selectedBundle && (
        <BundleEditModal
          bundle={selectedBundle}
          title={bundleModalTitle}
          onClose={() => {
            setSelectedBundle(null);
          }}
        />
      )}
    </>
  );
};

export default PriceBookPage;
