import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box, Button, Heading, SkeletonText, Text, useDisclosure,
} from '@chakra-ui/react';
import { sortBy } from 'lodash';
import React, {
  useEffect,
  useMemo,
} from 'react';
import { useHistory, useLocation } from 'react-router';
import DismissibleStatusAlert from '~/components/DismissibleStatusAlert';
import LinkButton from '~/components/LinkButton';
import useTrackedAction from '~/hooks/useTrackedAction';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import CloseJobModal from '~/pages/Job/components/invoices/CloseJobModal';
import InvoicePanel from '~/pages/Job/components/invoices/InvoicePanel';
import InvoiceStatus from '~/pages/Job/components/invoices/InvoiceStatus';
import InvoiceTypeModal from '~/pages/Job/components/invoices/InvoiceTypeModal';
import Routes from '~/pages/routes';
import { InvoiceActions } from '~/redux/invoices/actions';
import { selectInvoicesByJobId } from '~/redux/invoices/selectors';
import { addInvoiceToJob } from '~/redux/jobs/actions';
import { selectJobWithEdits } from '~/redux/jobs/selectors';
import { useAppSelector } from '~/redux/store';

const JobInvoiceTab = ({ jobId }: { jobId: string }) => {
  const { isLoading, data: invoices } = useTrackedFetch({
    key: `job-invoices-${jobId}`,
    trigger: () => InvoiceActions.fetchForJob({ jobId }),
    selector: (state) => selectInvoicesByJobId(state, jobId),
  });
  const { state: locationState } = useLocation<{ create: boolean, close: boolean }>();
  const history = useHistory();
  const createInvoiceModalState = useDisclosure();
  const closeJobModalState = useDisclosure();

  const job = useAppSelector((state) => selectJobWithEdits(state, jobId));

  const isNewConsumables = job.consumablesVersion === 2;

  const { isLoading: isCreateInvoiceLoading, trigger: createInvoice } = useTrackedAction({
    trigger: () => addInvoiceToJob({
      jobId,
    }),
  });
  const draftInvoicesExist = invoices.some((i) => i.status === 'draft');

  useEffect(() => {
    if (locationState?.create) {
      // clear the state so it doesnt keep popping the modal on refresh
      history.replace({
        ...history.location,
        state: {},
      });
      createInvoiceModalState.onOpen();
    }
    if (locationState?.close) {
      // clear the state so it doesnt keep popping the modal on refresh
      history.replace({
        ...history.location,
        state: {},
      });
      closeJobModalState.onOpen();
    }
  }, []);

  const sortedInvoices = useMemo(() => sortBy(invoices, (i) => i.code), [invoices]);
  if (isLoading) {
    return (
      <SkeletonText noOfLines={2} />
    );
  }

  return (
    <>
      {sortedInvoices.length === 1 && sortedInvoices[0].status === 'draft'
        ? (
          <Box>
            <Heading as="h3" size="md" mb={8}>CREATE INVOICE</Heading>
            <InvoicePanel invoice={sortedInvoices[0]} draftInvoicesExist={draftInvoicesExist} />
          </Box>
        )
        : (
          <Accordion
            key={sortedInvoices.length}
            defaultIndex={[sortedInvoices.length - 1]}
            allowToggle
          >
            {sortedInvoices.map((invoice, ix) => (
              <AccordionItem key={invoice.id}>
                <AccordionButton px={3} py={0}>
                  <Box borderColor="magnetize.ui-3" flex={1} py={4}>
                    <InvoiceStatus invoice={invoice} />
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel
                  paddingTop={0}
                  paddingBottom={ix !== sortedInvoices.length - 1 ? 20 : undefined}
                  borderColor="magnetize.ui-3"
                  borderBottomWidth={ix === sortedInvoices.length - 1 ? 0 : 1}
                  px={3}
                >
                  <InvoicePanel invoice={invoice} draftInvoicesExist={draftInvoicesExist} />
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        ) }

      {sortedInvoices.length === 0 && (
        <Box>
          <Heading as="h3" size="md" mb={8}>CREATE INVOICE</Heading>
          <DismissibleStatusAlert
            id="use-accounting-software"
            title="Use financial accounting software?"
            mb={8}
          >
            <Text>
              Send your invoices directly to your financial accounting software (e.g. Xero)
              by connecting it in the
              {' '}
              <LinkButton
                textDecoration="none"
                isGreen
                to={Routes.powerUpsSettings({})}
              >
                organisation settings
              </LinkButton>
              .
            </Text>
          </DismissibleStatusAlert>
          <Text mb={10} maxW="420px" size="lg">
            Prepare an invoice for this job and send it directly to your financial
            accounting software.
          </Text>
          <Button
            size="lg"
            alignItems="center"
            disabled={isCreateInvoiceLoading}
            onClick={() => {
              if (isNewConsumables) {
                createInvoiceModalState.onOpen();
              } else {
                createInvoice();
              }
            }}
          >
            <Text>
              CREATE INVOICE
            </Text>
          </Button>
        </Box>
      )}
      {createInvoiceModalState.isOpen && (
      <InvoiceTypeModal
        jobId={jobId}
        onClose={createInvoiceModalState.onClose}
      />
      )}
      {closeJobModalState.isOpen && (
      <CloseJobModal
        jobId={jobId}
        onClose={closeJobModalState.onClose}
      />
      )}
    </>
  );
};
export default JobInvoiceTab;
