import { RootState } from '~/redux/store';
import { CurrentUser } from '~/types/currentUser';
import { SubscriptionStatusInfo } from '~/types/tenant';

export const selectCurrentUser = (
  state: RootState,
) : CurrentUser | null => state.currentUser;

export const selectCurrentTenant = (s: RootState) => s.currentUser?.tenant;

export const selectTenantStatus = (s: RootState) => selectCurrentTenant(s)?.status;

export const selectTenantStatusInfo = (s: RootState) => (
  SubscriptionStatusInfo[selectTenantStatus(s)]
);

export const selectIsTenantActive = (s: RootState) => selectTenantStatusInfo(s)?.isActive ?? true;

export const selectIsTenantOwner = (s: RootState) => s.currentUser?.staff?.id
&& s.currentUser.staff.id === s.currentUser.tenant.ownerStaffId;

export const selectIsTenantTrial = (s: RootState) => selectTenantStatusInfo(s)?.isTrial ?? false;

export const selectTenantPowerUps = (s: RootState) => selectCurrentTenant(s)?.powerUps;

export const selectTenantQuoteExpiry = (s: RootState) => selectCurrentTenant(s)
  ?.quoteExpiryDays ?? 30;
