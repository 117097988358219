import {
  Text, Grid, GridItem, Link, Heading, Flex,
} from '@chakra-ui/react';
import React from 'react';
import { ContactInfo as ContactInfoType } from '~/types/contact-info';

const GRID_AREAS = `
  "name name"
  "type type"
  "phone phone"
  "email email"
`;

const ContactInfo = ({ value, contactType }: { value: ContactInfoType, contactType?: string }) => (
  <Grid gridTemplateAreas={GRID_AREAS} rowGap={0.5} columnGap={2} gridTemplateColumns="max-content auto">
    {value.name && (
    <GridItem gridArea="name">
      <Heading size="sm">
        {value.name}
      </Heading>
    </GridItem>
    )}
    {contactType && (
    <GridItem gridArea="type">
      <Text fontStyle="italic">{contactType}</Text>
    </GridItem>
    )}

    {value.email && (
      <Flex gridArea="email">
        <Text mr={2}>e.</Text>
        <Link tabIndex={-1} href={`mailto:${value.email}`} isExternal>{value.email}</Link>
      </Flex>
    )}
    {value.phone && (
    <>
      <Flex gridArea="phone">
        <Text mr={2}>ph.</Text>
        <Link tabIndex={-1} href={`tel:${value.phone}`} isExternal>{value.phone}</Link>
      </Flex>
    </>
    )}
  </Grid>
);

export default ContactInfo;
