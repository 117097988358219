import { createAction } from '@reduxjs/toolkit';
import {
  Job, JobTimelineNote, JobStatusId,
} from '~/types/job';
import createTrackedAction from '~/redux/tracked/createTrackedAction';

export const updateJob = createTrackedAction<{ id: string } & Partial<Job>>('jobs/updateJob');
export const saveJob = createTrackedAction<{ id: string, status: JobStatusId }>('jobs/saveJob');
export const duplicateJob = createTrackedAction<{ id: string }>('jobs/duplicateJob');

export const triggerJobFetch = createAction<{ jobId: string }>('jobs/triggerFetch');
export const triggerJobFetchMany = createAction<{ jobIds: string[] }>('jobs/triggerFetchMany');
export const jobFetchStart = createAction<{ jobId: string }>('jobs/fetchStart');
export const jobFetchSuccess = createAction<{ jobId: string, data: Job }>('jobs/fetchSuccess');
export const jobFetchError = createAction<{ jobId: string, errorMessage: string }>('jobs/fetchError');

export const addQuoteToJob = createTrackedAction<{ jobId: string }>('jobs/addQuote');
export const addInvoiceToJob = createTrackedAction<{ jobId: string, fromDate?: string, toDate?: string, proportionOfQuote?: number }>('jobs/addInvoice');
export const duplicateInvoice = createTrackedAction<{ jobId: string, invoiceId: string }>('jobs/duplicateInvoice');
export const addJobNote = createAction<{ jobId: string, note: JobTimelineNote }>('jobs/addNote');
export const completeJob = createTrackedAction<{ jobId: string }>('jobs/completeJob');
export const closeJob = createTrackedAction<{ jobId: string }>('jobs/closeJob');
export const reopenJob = createTrackedAction<{ jobId: string }>('jobs/reopenJob');
export const holdJob = createTrackedAction<{ jobId: string }>('jobs/holdJob');
export const startJob = createTrackedAction<{ jobId: string }>('jobs/start');
export const archiveJob = createTrackedAction<{ jobId: string }>('jobs/archiveJob');

export const JobActions = {
  // TODO: refactor all of the actions into this collection.
  exportJobs: createTrackedAction<{ format: 'csv' | 'xlsx' }>('jobs/exportReport'),
  fetchAll: createAction('jobs/fetchAll'),
  jobsListUpdated: createAction<{ jobsById: { [id: string]:Job } }>('jobs/updated'),
};
