import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { JobTagSwatch } from '~/components/JobTagSwatch';

const JobTagsSummary = ({
  tagIds,
}: { tagIds: string[] }) => (
  <Box display="flex" flexDirection="row" alignItems="center">
    { tagIds.slice(0, 3).map((id) => (<JobTagSwatch id={id} key={id} mr={1} />))}
    {tagIds.length > 3 && <Text fontWeight="bold" color="gray.400">{`+${tagIds.length - 3}`}</Text>}
  </Box>
);

export default JobTagsSummary;
