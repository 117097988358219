import {
  Button,
  Modal, ModalBody, ModalCloseButton,
  ModalContent, ModalFooter, ModalHeader, ModalOverlay,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useAppDispatch } from '~/redux/store';
import { updateClearanceTechnique } from '~/redux/clearanceTechniques/actions';
import { ClearanceTechnique } from '~/types/clearanceTechnique';
import { ClearanceTechniqueForm } from './ClearanceTechniqueForm';

export const ClearanceTechniqueEditModal = ({
  clearanceTechnique,
  title = 'Edit clearance technique',
  onClose = () => {},
}: {
  clearanceTechnique: Partial<ClearanceTechnique>,
  title: string,
  onClose: () => void
}) => {
  const dispatch = useAppDispatch();
  const [editedClearanceTechnique, setEditedClearanceTechnique] = useState(clearanceTechnique);

  const canSave = editedClearanceTechnique.name;
  const onSaveClicked = () => {
    dispatch(updateClearanceTechnique(editedClearanceTechnique as ClearanceTechnique));
    onClose();
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen
      onClose={onClose}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {title}
        </ModalHeader>
        <ModalCloseButton tabIndex={-1} />
        <ModalBody>
          <ClearanceTechniqueForm
            value={editedClearanceTechnique}
            onChange={(st) => {
              setEditedClearanceTechnique({
                ...editedClearanceTechnique,
                ...st,
              });
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={!canSave}
            colorScheme="brandDark"
            onClick={onSaveClicked}
            size="lg"
          >
            Save
          </Button>
          <Button tabIndex={-1} variant="ghost" onClick={onClose}>cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
