import {
  parseISO, add, startOfWeek, endOfWeek, startOfDay, startOfMonth, endOfMonth, endOfDay, addDays,
} from 'date-fns';
import { getIsoDate } from '~/utils/calendarHelpers';

export type DateRangeMode = '3-day' | 'week' | '2-week' | 'month';

/**
 * Computes the data range for a particular day of interest and mode.
 *
 * Rather than keeping track of start/end dates, we instead track
 * the 'focused day' that the calendar is looking at. You can then
 * independently switch modes between 3-day/week/fortnight and get
 * the appropriate window of time for the mode.
 *
 * This makes interactios with the calendar feel a lot more natural,
 * as 'zooming out' then back in will not affect the date you're
 * looking at.
 *
 * Moving the focused date is also simpler as date-fns's `add`
 * function is really smart and so we can just jump forward a
 * day, month etc.
 *
 * @param focusedDay
 * @param mode
 * @returns
 */
export const getCalendarDateRange = (
  focusedDay: string,
  mode: DateRangeMode,
): { start: Date, end: Date } => {
  const day = startOfDay(parseISO(focusedDay));
  const weekStart = startOfWeek(day, { weekStartsOn: 1 });
  switch (mode) {
    case '3-day':
      return {
        start: add(day, { days: -1 }),
        end: endOfDay(add(day, { days: 1 })),
      };

    default:
    case 'week':
      return {
        start: weekStart,
        end: endOfWeek(weekStart, { weekStartsOn: 1 }),
      };

    case '2-week':
      return {
        start: weekStart,
        end: endOfDay(add(weekStart, { days: 13 })),
      };

    case 'month':
      return {
        // overfetch to show the start and end of next month
        start: addDays(startOfMonth(day), -6),
        end: addDays(endOfMonth(day), 6),
      };
  }
};

export const moveFocusedDay = (
  focusedDay: string,
  mode: DateRangeMode,
  direction: 1 | -1,
): string => {
  const day = startOfDay(parseISO(focusedDay));
  switch (mode) {
    case '3-day':
      return getIsoDate(add(day, { days: 1 * direction }));

    default:
    case 'week':
    case '2-week':
      return getIsoDate(add(day, { days: 7 * direction }));

    case 'month':
      return getIsoDate(add(day, { months: 1 * direction }));
  }
};
