import { createAction } from '@reduxjs/toolkit';
import createTrackedAction from '~/redux/tracked/createTrackedAction';
import { CustomerContact } from '~/types/customerContact';

export const CustomerContactActions = {
  fetchAll: createAction('customerContact/fetchAll'),
  fetchForCustomer: createAction<{ customerId: string }>('customerContact/fetchForCustomer'),
  fetch: createAction<{ id: string }>('customerContact/fetch'),
  save: createTrackedAction<{ contact: CustomerContact, hideSuccessToast: boolean }>('customerContact/save'),
  updated: createAction<{ contacts: CustomerContact[] }>('customerContact/updated'),
  // archive: createAction<{ contactId: string }>('customerContact/archive'),
};
