import {
  Icon, Text, Box,
} from '@chakra-ui/react';
import {
  isNumber, map, orderBy, values,
} from 'lodash';
import React from 'react';
import { ReactComponent as SptIcon } from '~/assets/live_geo_spt_test_icon.svg';
import { CountSectionAccordionItem } from '~/components/CountSectionAccordionItem';
import ExpandableText from '~/components/ExpandableText';
import LogRow from '~/pages/LiveGeo/components/LogRow';
import { PushTube, SptTest } from '~/types/geotechnical';

const SPTColumn = ({ spt }:{ spt: SptTest }) => (
  <Box>
    <Text>
      {[
        spt.blows1,
        spt.blows2,
        spt.blows3,
        spt.blows4,
        spt.blows5,
        spt.blows6,
      ].filter((x) => isNumber(x)).join('/')}
    </Text>
    {isNumber(spt.n) && (
      <Text>
        {`N=${spt.n}`}
      </Text>
    )}
  </Box>
);

const PushTubeColumn = () => (
  <Box>
    <Text>
      Push tube
    </Text>
  </Box>
);

const SPTTestsRow = ({ sptTests, pushTubes } : {
  sptTests: { [id:string]: SptTest };
  pushTubes: { [id: string]: PushTube };
}) => {
  const ordered = orderBy([
    ...values(pushTubes).map((t) => ({ ...t, type: 'push-tube' })),
    ...values(sptTests).map((t) => ({ ...t, type: 'spt' })),
  ], (spt) => spt.depth);

  return (
    <CountSectionAccordionItem
      title="SPT Tests"
      icon={<Icon boxSize={8} as={SptIcon} />}
    >
      {map(ordered, (test, index) => (
        <LogRow key={`spt-log-${index}`}>
          <Text fontWeight="semibold" minW="62px">
            {test.depth}
            m
          </Text>
          {test.type === 'spt' && (
            <SPTColumn spt={test as unknown as SptTest} />
          )}
          {test.type === 'push-tube' && (
            <PushTubeColumn />
          )}
          <ExpandableText gridColumn="3 /  span 2" fontStyle="italic">
            {test.note || ''}
          </ExpandableText>
        </LogRow>
      ))}
    </CountSectionAccordionItem>
  );
};

export default SPTTestsRow;
