import { createAction } from '@reduxjs/toolkit';
import createTrackedAction from '~/redux/tracked/createTrackedAction';
import { TimeRecord } from '~/types/time';

const TimesheetActions = {
  fetchForJob: createAction<{ jobId: string }>('time/fetchForJob'),
  timesUpdatedForJob: createAction<{ jobId: string, times: TimeRecord[] }>('time/timesUpdatedForJob'),
  saveJobTimeRecord: createAction<{ jobId: string, timeRecord: TimeRecord }>('time/saveForJob'),
  fetchForRange: createAction<{ from: string, to: string }>('time/fetchForRange'),
  exportSelected: createTrackedAction<{ from: string, to: string, times: TimeRecord[] }>('time/exportSelected'),
  timesUpdatedForRange: createAction<{ from: string, to: string, times: TimeRecord[] }>('time/updatedForRange'),
};

export default TimesheetActions;
