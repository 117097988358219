import { uniqBy } from 'lodash';
import {
  all, call, put, select, takeEvery,
} from 'redux-saga/effects';
import { LogsActions } from '~/redux/logs/actions';
import { selectLogsForJob } from '~/redux/logs/selectors';
import { showErrorToast } from '~/toast';
import { LogEntry } from '~/types/LogEntry';
import fetchJson from '~/utils/fetchJson';
import { performFetchSaga } from '~/utils/performFetchSaga';
import queueWork from '~/utils/queueWork';

const watchFetchForJob = takeEvery(
  LogsActions.fetchForJob,
  function* handle(action) {
    const { jobId } = action.payload;
    yield performFetchSaga({
      key: `job-logs-${jobId}`,
      * saga() {
        const logs: LogEntry[] = yield call(() => fetchJson(
          `/api/logs/by-job/${jobId}`,
          {
            method: 'GET',
          },
        ));
        yield put(LogsActions.jobLogsUpdated({ jobId, logs }));
      },
    });
  },
);

const watchSaveJobLog = takeEvery(
  LogsActions.save,
  function* handle(action) {
    const { jobId, log } = action.payload;
    yield queueWork(function* worker() {
      try {
        const updatedLog: LogEntry = yield call(() => (
          fetchJson('/api/logs', {
            method: 'POST',
            body: log,
          })
        ));
        const existingLogs: LogEntry[] = yield select((s) => selectLogsForJob(s, jobId));
        yield put(LogsActions.jobLogsUpdated({
          jobId,
          logs: uniqBy([updatedLog, ...existingLogs], (e) => e.id),
        }));
      } catch (e) {
        showErrorToast(e);
      }
    });
  },
);

export function* handleLogEntries() {
  yield all([
    watchFetchForJob,
    watchSaveJobLog,
  ]);
}
