import { all } from 'redux-saga/effects';
import handleDeleteScheduleEvent from '~/redux/schedule/saga/deleteScheduleEvent';
import handleEventEditing from '~/redux/schedule/saga/eventEditing';
import handleFetchScheduleEvents from '~/redux/schedule/saga/fetchScheduleEvents';

export default function* handleScheduleActions() {
  yield all([
    handleFetchScheduleEvents(),
    handleEventEditing(),
    handleDeleteScheduleEvent,
  ]);
}
