import {
  Flex, GridItem, Heading, SkeletonText,
} from '@chakra-ui/react';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import Card from '~/components/Card';
import OrgLogoFileUpload from '~/components/FileUpload/OrgLogoFileUpload';
import PageTabs from '~/components/PageTabs';
import StatusAlert from '~/components/StatusAlert';
import useTrackedAction from '~/hooks/useTrackedAction';
import PageHeader from '~/layouts/PageHeader';
import PrimarySecondaryColumns from '~/layouts/PrimarySecondaryColumns';
import ContactDetailsTab from '~/pages/TenantSettings/ContactDetailsTab';
import FinanceTab from '~/pages/TenantSettings/FinanceTab';
import JobSettingsTab from '~/pages/TenantSettings/JobSettingsTab';
import PlansTab from '~/pages/TenantSettings/PlansTab';
import QuoteSettingsTab from '~/pages/TenantSettings/QuoteSettingsTab';
import PowerUpsTab from '~/pages/TenantSettings/PowerUpsTab';
import { currentUserActions } from '~/redux/currentUser/actions';
import { selectCurrentUser } from '~/redux/currentUser/selectors';
import { selectFetchState } from '~/redux/fetch/selectors';
import { useAppSelector } from '~/redux/store';
import { Tenant } from '~/types/tenant';

const TenantSettingsPage = () => {
  const { isLoading, isError, errorMessage } = useAppSelector(
    (s) => selectFetchState(s, 'currentUser'),
  );
  const currentUser = useAppSelector((state) => selectCurrentUser(state));
  const { tenant } = currentUser;

  const { trigger, isLoading: isSaving } = useTrackedAction({
    trigger: (t: Tenant) => currentUserActions.saveTenant({
      tenant: t, showSuccess: true,
    }),
  });

  return (
    <>
      <PrimarySecondaryColumns>
        <PageHeader backButton>
          <Flex mb={6} justifyContent="space-between">
            <Heading size="lg">
              Organisation Settings
            </Heading>
          </Flex>
          <Flex mb={-6}>
            <PageTabs
              tabs={[
                { title: 'Details', path: '/settings/contact' },
                { title: 'Finance', path: '/settings/finance' },
                { title: 'Quotes', path: '/settings/quote' },
                { title: 'Jobs', path: '/settings/job' },
                { title: 'Power ups', path: '/settings/power-ups' },
                { title: 'Plan & Billing', path: '/settings/plans' },
              ]}
            />
          </Flex>
        </PageHeader>
        <GridItem gridArea="primary">
          <Flex direction="column">
            <Card minH="345px" padding={12}>
              <SkeletonText isLoaded={!isLoading} noOfLines={3}>
                {tenant && (
                <Switch>
                  <Route path="/settings/contact">
                    <ContactDetailsTab
                      tenant={tenant}
                      isSaving={isSaving}
                      onSave={(t) => {
                        trigger(t);
                      }}
                    />
                  </Route>
                  <Route path="/settings/finance">
                    <FinanceTab
                      tenant={tenant}
                      onSave={(t) => {
                        trigger(t);
                      }}
                    />
                  </Route>
                  <Route path="/settings/quote">
                    <QuoteSettingsTab
                      tenant={tenant}
                      isSaving={isSaving}
                      onSave={(t) => {
                        trigger(t);
                      }}
                    />
                  </Route>
                  <Route path="/settings/job">
                    <JobSettingsTab
                      tenant={tenant}
                      isSaving={isSaving}
                      onSave={(t) => {
                        trigger(t);
                      }}
                    />
                  </Route>
                  <Route path="/settings/power-ups">
                    <PowerUpsTab
                      tenant={tenant}
                      onSave={(t) => {
                        trigger(t);
                      }}
                    />
                  </Route>
                  <Route path="/settings/plans">
                    <PlansTab tenant={tenant} />
                  </Route>
                  <Route path="*" render={() => <Redirect to="/settings/contact" />} />
                </Switch>
                )}
                {isError && (
                  <StatusAlert title={errorMessage} />
                )}
              </SkeletonText>
            </Card>
          </Flex>
        </GridItem>
        <GridItem gridArea="secondary">
          <Card width="315px">
            <Heading size="sm" mb={2}>logo</Heading>
            {isLoading ? <SkeletonText noOfLines={2} />
              : (
                <OrgLogoFileUpload
                  logo={tenant?.logo}
                  onFileUploaded={(logo) => {
                    trigger({
                      ...tenant,
                      logo,
                    });
                  }}
                  onFileRemoved={() => {
                    trigger({
                      ...tenant,
                      logo: null,
                    });
                  }}
                />
              )}
          </Card>
        </GridItem>
      </PrimarySecondaryColumns>
    </>
  );
};

export default TenantSettingsPage;
