import {
  MenuDivider, MenuItem,
  Table, Tbody, Td, Text, Th, Thead, Tr,
} from '@chakra-ui/react';
import React from 'react';
import AvailabilityStatusBadge from '~/components/AvailabilityStatusBadge';
import ConfirmationDialog from '~/components/ConfirmationDialog';
import { KebabOptionsMenu } from '~/components/KebabOptionsMenu';
import { useModalManager } from '~/components/ModalManager';
import { EquipmentActions } from '~/redux/equipment/actions';
import { useAppDispatch } from '~/redux/store';
import { Equipment } from '~/types/equipment';
import eventStopper from '~/utils/eventStopper';

interface EquipmentListProps {
  equipment: Equipment[];
  onEdit: (equipment: Equipment) => void;
  onDuplicate: (equipment: Equipment) => void;
}

const EquipmentList = ({ equipment, onEdit, onDuplicate }: EquipmentListProps) => {
  const dispatch = useAppDispatch();
  const modalManager = useModalManager();

  return (
    <Table size="md">
      <Thead>
        <Tr>
          <Th>Nickname</Th>
          <Th>Name</Th>
          <Th>Code</Th>
          <Th>Description</Th>
          <Th>Status</Th>
          <Th w="10%" />
        </Tr>
      </Thead>
      <Tbody>
        { equipment.map((item) => (
          <Tr
            _hover={{ bg: '#fafafa', cursor: 'pointer' }}
            transition="color 0.1s ease-out"
            key={item.id}
            role="group"
            onClick={() => {
              onEdit(item);
            }}
          >
            <Td>
              <Text>
                {item.nickname}
              </Text>
            </Td>
            <Td>
              <Text>
                {item.name}
              </Text>
            </Td>
            <Td>
              <Text>
                {item.code}
              </Text>
            </Td>
            <Td>
              <Text noOfLines={3}>
                {item.description}
              </Text>
            </Td>
            <Td>
              <AvailabilityStatusBadge isAvailable={item.isAvailable} isEquipment />
            </Td>
            <Td
              textAlign="end"
              {...eventStopper()}
            >
              <KebabOptionsMenu>
                <MenuItem onClick={() => onEdit(item)}>Edit</MenuItem>
                <MenuItem onClick={() => onDuplicate(item)}>
                  Duplicate
                </MenuItem>
                <MenuDivider my={0} />
                <MenuItem onClick={() => {
                  modalManager.open(ConfirmationDialog, {
                    title: 'Delete equipment',
                    children: 'This equipment will be deleted from Magnetize. You will no longer be able to add it to jobs.',
                    onConfirm: () => dispatch(EquipmentActions.archive({
                      equipmentId: item.id,
                    })),
                    confirmButtonLabel: 'Delete',
                  });
                }}
                >
                  Delete
                </MenuItem>
              </KebabOptionsMenu>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default EquipmentList;
