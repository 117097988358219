import React, { useMemo, useState } from 'react';
import {
  Box, HStack, Icon, useDisclosure,
} from '@chakra-ui/react';
import { MdOutlineNewLabel } from 'react-icons/md';
import { nanoid } from '@reduxjs/toolkit';
import { useAppDispatch } from '~/redux/store';
import { selectJobWithEdits } from '~/redux/jobs/selectors';
import { triggerJobFetch, updateJob } from '~/redux/jobs/actions';
import { Job } from '~/types/job';
import JobTagSearchSelect from '~/components/JobTagEditor/JobTagSearchSelect';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import JobTagEditModal from '~/components/JobTagEditModal';
import { JobTag } from '~/types/tag';

export const JobTagEditor = ({
  jobId, hideIcon = false,
}: { jobId: string, hideIcon?: boolean }) => {
  const dispatch = useAppDispatch();
  const { data: job } = useTrackedFetch<Job>({
    enabled: !!jobId,
    key: `job:${jobId}`,
    selector: (state) => selectJobWithEdits(state, jobId),
    trigger: () => triggerJobFetch({ jobId }),
    equalityMode: 'deep',
  });
  const updateCurrentJob = (changes: Partial<Job>) => {
    dispatch(updateJob({ id: job.id, ...changes }));
  };
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [newTagName, setNewTagName] = useState('');
  const newTag = useMemo<Partial<JobTag>>(() => ({
    id: nanoid(),
    type: 'job',
    name: newTagName,
  }), [newTagName]);

  return (
    <HStack alignItems="normal">
      {!hideIcon && <Icon as={MdOutlineNewLabel} fontSize={18} mt={2} mr="-2px" />}
      <Box flex={1}>
        <JobTagSearchSelect
          disabled={!jobId}
          tags={job?.tags}
          onCreateTag={(name) => {
            setNewTagName(name);
            onOpen();
          }}
          onChange={(tags) => {
            updateCurrentJob(
              {
                tags,
              },
            );
          }}
        />
      </Box>
      {isOpen
      && (
      <JobTagEditModal
        jobTag={newTag}
        title="Add tag"
        onClose={onClose}
        onSave={(jobTag) => {
          updateCurrentJob(
            {
              tags: [...(job?.tags || []), jobTag.id],
            },
          );
        }}
      />
      )}
    </HStack>
  );
};
