import {
  Flex, Table, Tbody, Td, Th, Thead, Tr, TableRowProps, Text,
} from '@chakra-ui/react';
import React from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
  SortingState,
  ColumnDef,
  Row,
  OnChangeFn,
} from '@tanstack/react-table';

import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';

export const SortableTable = <T extends unknown>(
  {
    data, columns, getRowProps, defaultSort, renderRow, manualSorting = false, onSortingChange,
  }
  :{
    data: T[],
    columns: ColumnDef<T, any>[],
    getRowProps?: (row:Row<T>)=> TableRowProps,
    defaultSort?: SortingState,
    renderRow?: (row:Row<T>)=> React.ReactNode,
    manualSorting?: boolean,
    onSortingChange?: OnChangeFn<SortingState>,
  },
) => {
  const [sorting, setSorting] = React.useState<SortingState>(defaultSort || []);

  const {
    getFlatHeaders,
    getRowModel,
  } = useReactTable(
    {
      columns,
      data,
      state: {
        sorting,
      },
      manualSorting,
      onSortingChange: (x) => {
        setSorting(x);
        if (onSortingChange) {
          onSortingChange(x);
        }
      },
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
    },
  );

  return (
    <Table>
      <Thead>
        <Tr>
          {getFlatHeaders().map((header) => (
            <Th
              key={header.id}
              userSelect="none"
              cursor={header.column.getCanSort() ? 'pointer' : undefined}
              {...(header.column.columnDef?.meta?.headerProps || {})}
              _hover={header.column.getCanSort() ? {
                color: 'magnetize.text-2',
              } : {}}
            >
              <Flex
                alignItems="center"
                onClick={header.column.getToggleSortingHandler()}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                {{
                  asc: (
                    <>
                      {header.column.columnDef?.meta?.sortText?.asc && (
                      <Text pl={1}>
                        (
                        {header.column.columnDef?.meta?.sortText?.asc}
                        )
                      </Text>
                      )}
                      <ChevronUpIcon ml={1} w={4} h={4} />
                    </>),
                  desc: (
                    <>
                      {header.column.columnDef?.meta?.sortText?.desc
                      && (
                      <Text pl={1}>
                        (
                        {header.column.columnDef?.meta?.sortText?.desc}
                        )
                      </Text>
                      )}
                      <ChevronDownIcon ml={1} w={4} h={4} />
                    </>),
                }[header.column.getIsSorted() as string] ?? null}
              </Flex>
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {getRowModel().rows.map((row) => (renderRow
          ? renderRow(row)
          : (
            <Tr
              key={row.id}
              {...(getRowProps ? getRowProps(row) : {})}
            >
              {row.getVisibleCells().map((cell) => (
                <Td
                  key={cell.id}
                  {...(cell.column.columnDef?.meta?.cellProps || {})}
                >
                  {flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext(),
                  )}
                </Td>
              ))}
            </Tr>
          )))}
      </Tbody>
    </Table>
  );
};
