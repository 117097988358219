import { Grid, GridItem } from '@chakra-ui/react';
import React from 'react';
import LineItemTotals from '~/components/LineItemTotals';
import QuoteViewLineItemTable from '~/components/QuoteView/QuoteViewLineItemTable';
import { Quote } from '~/types/quote';

const QuoteSummary = ({ quote } : { quote : Quote }) => (
  <Grid templateColumns="repeat(12, 1fr)" gap={6} fontSize="sm" lineHeight="short">
    <GridItem colSpan={12}>
      <QuoteViewLineItemTable
        quoteLineItems={quote.draft?.lineItems || quote.lineItems}
        currency={quote.currency}
      />
    </GridItem>
    <GridItem colStart={8} colSpan={5} pt={4} fontSize="lg">
      <LineItemTotals
        currency={quote.currency}
        lineItems={quote.lineItems}
        taxRate={quote.taxRate}
        taxRateName={quote.taxRateName}
      />
    </GridItem>
  </Grid>
);

export default QuoteSummary;
