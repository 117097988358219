import {
  all, call, put, takeEvery,
} from 'redux-saga/effects';
import { triggerJobFetch } from '~/redux/jobs/actions';
import { LiveGeoActions } from '~/redux/liveGeo/actions';
import { showErrorToast, showSuccessToast } from '~/toast';
import { Borehole, CPT } from '~/types/geotechnical';
import fetchJson from '~/utils/fetchJson';
import { performFetchSaga } from '~/utils/performFetchSaga';
import { takeEveryTracked } from '~/utils/performTrackedSaga';

const watchFetchForJob = takeEvery(
  LiveGeoActions.fetchForJob,
  function* handle(action) {
    const { jobId } = action.payload;
    yield performFetchSaga({
      key: `borehole-${jobId}`,
      staleTime: 30000,
      * saga() {
        const result : any = yield call(
          () => fetchJson(`/api/geotechnical/for-job/${jobId}`),
        );
        const boreholes : Borehole[] = result?.boreholes ?? [];
        const cpts : CPT[] = result?.cpts ?? [];

        yield put(LiveGeoActions.updated({ boreholes, cpts }));
        yield put(LiveGeoActions.updatedForJob({
          jobId,
          boreholeIds: boreholes.map((b) => b.id),
          cptIds: cpts.map((c) => c.id),
        }));
      },
    });
  },
);

const watchSend = takeEveryTracked(
  LiveGeoActions.send,
  function* handle(action) {
    try {
      const { jobId, recipients } = action.payload.data;
      // const result =
      // result will contain public token for superusers so we can display a nice testing link
      // like we do on quotes
      yield call(
        () => fetchJson(`/api/geotechnical/for-job/${jobId}/send`, {
          method: 'POST',
          body: {
            recipients,
          },
        }),
      );

      showSuccessToast(`Logs sent to ${recipients[0].name} '${recipients[0].email}'`);

      yield put(triggerJobFetch({ jobId }));
    } catch (error) {
      showErrorToast(error);
      throw error;
    }
  },
);

function downloadFile(filePath) {
  const link = document.createElement('a');
  link.href = filePath;
  link.target = '_blank';
  link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
  link.click();
}

const watchForDownload = takeEveryTracked(
  LiveGeoActions.downloadPDF,
  function* handle(action) {
    try {
      const body = action.payload.data;

      const result = yield call(
        () => fetchJson('/api-pdf/live-geo', {
          method: 'POST',
          body,
        }),
      );

      const url = result?.fileUrl;
      // TODO change to download
      if (url) {
        downloadFile(url);
      } else {
        throw new Error('Failed to download pdf');
      }
    } catch (error) {
      showErrorToast(error);
      throw error;
    }
  },
);

export default function* liveGeoSaga() {
  yield all([
    watchFetchForJob,
    watchSend,
    watchForDownload,
  ]);
}
