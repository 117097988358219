import React from 'react';
import {
  GridItem,
  Heading, Menu, MenuButton, Icon, MenuList, MenuItem, Skeleton, Stack,
  Text,
} from '@chakra-ui/react';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/status-bar/dist/style.css';
import { useQuery } from 'react-query';
import {
  Redirect, Route, Switch,
} from 'react-router-dom';
import { KeyboardArrowDownOutlined } from '@material-ui/icons';
import { format } from 'date-fns';
import Card from '~/components/Card';
import CardTitle from '~/components/CardTitle';
import PageTabs from '~/components/PageTabs';
import StatusAlert from '~/components/StatusAlert';
import PrimarySecondaryColumns from '~/layouts/PrimarySecondaryColumns';
import { AddToTenantForm } from '~/pages/Admin/Tenant/AddToTenantForm';
import RunSubscriptionWorkerButton from '~/pages/Admin/Tenant/RunSubscriptionWorkerButton';
import { TenantsTable } from '~/pages/Admin/Tenant/TenantsTable';
import { AdminTenantSummary } from '~/types/tenant';
import fetchJson from '~/utils/fetchJson';
import { exportToSpreadsheet } from '~/utils/exportToSpreadsheet';

const TenantPage = () => {
  const {
    data: tenants,
    isLoading,
    error,
  } = useQuery<AdminTenantSummary[], Error>({
    queryFn: () => fetchJson('/api/tenant-admin'),
    queryKey: ['admin-tenant'],
  });

  return (
    <PrimarySecondaryColumns>
      <GridItem gridArea="header">
        <Heading>Tenants</Heading>
      </GridItem>
      <GridItem gridArea="primary">
        <PageTabs
          tabs={[
            { title: 'Trial', path: '/superuser/tenants/trial' },
            { title: 'Paying', path: '/superuser/tenants/paying' },
            { title: 'Expired', path: '/superuser/tenants/expired' },
            { title: 'All', path: '/superuser/tenants/all' },
          ]}
        >
          <Menu placement="bottom-end">
            <MenuButton fontSize="12px">
              Options
              <Icon ml={2} w={5} h={5} as={KeyboardArrowDownOutlined} />
            </MenuButton>
            <MenuList zIndex="2">
              <MenuItem onClick={() => {
                exportToSpreadsheet({
                  name: `Magnetize Tenants - ${new Date().toISOString()}`,
                  format: 'csv',
                  sheets: [{
                    headers: ['Id', 'Name', 'Email', 'Phone', 'Status', 'Sign Up Date'],
                    data: tenants.map((t) => ([
                      t.tenantId,
                      t.name || 'Unnamed organisation',
                      t.email,
                      t.phone,
                      t.status,
                      t.signUpTimestamp ? format(t.signUpTimestamp, 'yyyy-MM-dd') : 'unknown',
                    ])),
                  }],
                });
              }}
              >
                Export to csv
              </MenuItem>
            </MenuList>
          </Menu>
        </PageTabs>
        <Card>
          <Stack spacing={8}>
            {error ? <StatusAlert title={error.message} /> : (
              <Skeleton isLoaded={!isLoading}>
                {!isLoading && (
                <Switch>
                  <Route path="/superuser/tenants/trial">
                    <TenantsTable
                      tenants={tenants
                        .filter((t) => t.status === 'trial' || t.status === 'welcome')}
                    />
                  </Route>
                  <Route path="/superuser/tenants/paying">
                    <TenantsTable
                      tenants={tenants.filter((t) => t.status === 'subscribed' || t.status === 'subscriptionGrace' || t.status === 'subscriptionPending')}
                    />
                  </Route>
                  <Route path="/superuser/tenants/expired">
                    <TenantsTable
                      tenants={tenants
                        .filter((t) => t.status === 'trialExpired' || t.status === 'subscriptionExpired' || t.status === 'subscriptionCancelled')}
                    />
                  </Route>
                  <Route path="/superuser/tenants/all">
                    <TenantsTable
                      tenants={tenants}
                    />
                  </Route>
                  <Route path="*" render={() => <Redirect to="/superuser/tenants/trial" />} />
                </Switch>
                )}
              </Skeleton>
            )}
          </Stack>
        </Card>
      </GridItem>
      <GridItem gridArea="secondary">
        <Stack spacing={8}>
          <Card>
            <RunSubscriptionWorkerButton />
            <Text color="magnetize.text-4" mt="4">
              Use this to hurry along the background job that sends
              out emails and checks trial status.
            </Text>
          </Card>
          <Card>
            <CardTitle>Create Tenant</CardTitle>
            <AddToTenantForm buttonText="Create Tenant" />
          </Card>
        </Stack>
      </GridItem>
    </PrimarySecondaryColumns>
  );
};

export default TenantPage;
