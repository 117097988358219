import React from 'react';
import {
  Heading, Link, Text,
} from '@chakra-ui/react';

import TimelineItem, { TimelineItemProps } from '~/components/TimelineItem';
import { JobTimelineQuoteQuery } from '~/types/job';

export const TimelineQuoteQuery = ({
  event, ...rest
}: {
  event: JobTimelineQuoteQuery;
} & TimelineItemProps) => (
  <TimelineItem
    {...rest}
    event={event}
  >
    <Heading textTransform="none" size="sm" mb={1}>
      CUSTOMER QUERY FROM
      <Link ml={1} href={`mailto:${event.email}`} color="magnetize.brand-4">
        {event.user}
      </Link>
      {` ON QUOTE-${event.code}`}
    </Heading>
    <Text>
      {event.message}
    </Text>

  </TimelineItem>
);
