import {
  MenuDivider, MenuItem, Td, Text, Tr,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import ConfirmationDialog from '~/components/ConfirmationDialog';
import CurrencyText from '~/components/CurrencyText';
import { KebabOptionsMenu } from '~/components/KebabOptionsMenu';
import LinkButton from '~/components/LinkButton';
import { useModalManager } from '~/components/ModalManager';
import { PriceBundleViewModel } from '~/pages/PriceBook/BundleViewModel';
import { Price } from '~/types/price';
import calculateQuoteTotals from '~/utils/calculateQuoteTotals';
import eventStopper from '~/utils/eventStopper';
import { sortLineItems } from '~/utils/lineItemHelpers';

interface PriceBundleRowProps {
  bundle: PriceBundleViewModel;
  prices: Price[];
  onEdit: (p: PriceBundleViewModel) => void;
  onDuplicate: (p: PriceBundleViewModel) => void;
  onDelete: (p: PriceBundleViewModel) => void;
}

const BundleRow = (
  {
    bundle, prices, onEdit, onDuplicate, onDelete,
  } : PriceBundleRowProps,
) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const modalManager = useModalManager();
  const sortedLineItems = sortLineItems(bundle.lineItems);
  const { subtotalCents } = calculateQuoteTotals(sortedLineItems);
  const lineItemCount = sortedLineItems.length;

  return (
    <>
      <Tr
        _hover={{ bg: '#fafafa', cursor: 'pointer' }}
        transition="color 0.1s ease-out"
        role="group"
        onClick={() => {
          onEdit(bundle);
        }}
      >
        <Td>
          <LinkButton
            onClick={(e) => {
              setIsExpanded(!isExpanded);
              e.stopPropagation();
            }}
            isGreen
            noUnderline
          >
            {`${lineItemCount} item${lineItemCount === 1 ? '' : 's'}`}
          </LinkButton>
        </Td>
        <Td px={0}>
          <Text fontWeight="bold">
            {bundle.name}
          </Text>
        </Td>
        <Td>
          <Text color="magnetize.text-4" noOfLines={2}>
            {bundle.description}
          </Text>
        </Td>
        <Td>
          <Text fontWeight="bold">
            bundle
          </Text>
        </Td>
        <Td>
          <CurrencyText fontWeight="bold" value={subtotalCents} />
        </Td>
        <Td textAlign="end" {...eventStopper()}>
          <KebabOptionsMenu>
            <MenuItem onClick={() => onEdit(bundle)}>Edit</MenuItem>
            <MenuItem onClick={() => onDuplicate(bundle)}>Duplicate</MenuItem>
            <MenuDivider my={0} />
            <MenuItem onClick={() => {
              modalManager.open(ConfirmationDialog, {
                title: 'Delete bundle',
                children: 'This will remove this bundle from Magnetize, but existing quotes and invoices will be unaffected',
                onConfirm: () => onDelete(bundle),
                confirmButtonLabel: 'Delete',
              });
            }}
            >
              Delete
            </MenuItem>
          </KebabOptionsMenu>
        </Td>
      </Tr>
      {isExpanded && sortedLineItems.map((lineItem, index) => (
        <Tr key={lineItem.id}>
          <Td borderBottomWidth={index === lineItemCount - 1 ? 1 : 0} />
          <Td px={0}>
            <Text>{lineItem.name}</Text>
          </Td>
          <Td />
          <Td>
            {prices.find((p) => p.id === lineItem.priceId)?.category}
          </Td>
          <Td>
            <CurrencyText value={lineItem.quantity * lineItem.unitPriceCents} />
          </Td>
          <Td />
        </Tr>
      ))}
    </>
  );
};

export default BundleRow;
