import {
  Box,
  Button,
  Flex, FormControl, FormLabel, Heading, Image, Select, Text, VStack,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import OrgLogoFileUpload from '~/components/FileUpload/OrgLogoFileUpload';
import NextButton from '~/pages/WelcomeModal/components/NextButton';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import WelcomeModalActions from '~/redux/welcomeModal/actions';
import { selectWelcomeModalState } from '~/redux/welcomeModal/selectors';

const QuoteSettings = ({ totalSteps, stepNumber }: { totalSteps: number, stepNumber: number }) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const welcomeState = useAppSelector((state) => selectWelcomeModalState(state));

  return (
    <Flex
      h="100%"
      flex={1}
    >
      <Flex
        direction="column"
        flex={1}
      >
        <VStack
          p={20}
          alignItems="start"
          spacing={8}
          flex={1}
        >
          <Flex display="column">
            <Heading textTransform="none">Do you quote jobs?</Heading>
            <Heading
              pl={1}
              pt={2}
              textTransform="none"
              size="sm"
            >
              Getting set up to quote quickly
            </Heading>
          </Flex>
          <FormControl>
            <FormLabel>
              Quote jobs
            </FormLabel>
            <Select
              placeholder="Select from the dropdown"
              value={welcomeState.quoteJobs}
              onChange={(e) => {
                dispatch(WelcomeModalActions.update({
                  update: {
                    quoteJobs: e.target.value,
                  },
                }));
              }}
            >
              <option value="no">No - hardly ever</option>
              <option value="sometimes">Sometimes</option>
              <option value="mostly">Mostly</option>
              <option value="yes">Yes - always</option>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>
              Logo upload
            </FormLabel>
            <OrgLogoFileUpload
              logo={welcomeState.organisationLogo}
              onFileUploaded={(organisationLogo) => {
                dispatch(WelcomeModalActions.update({
                  update: {
                    organisationLogo,
                  },
                }));
              }}
              onFileRemoved={() => {
                dispatch(WelcomeModalActions.update({
                  update: {
                    organisationLogo: null,
                  },
                }));
              }}
            />
          </FormControl>

          <Box flex={1} />
          <FormControl>
            <NextButton />
          </FormControl>
          <Text color="magnetize.text-4" mt={8} width="100%" textAlign="center">
            {`step ${stepNumber} of ${totalSteps}`}
          </Text>
        </VStack>
      </Flex>
      <Flex
        flex={1}
        backgroundImage="url('/images/login-bg.jpg')"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        alignItems="flex-end"
        justifyContent="center"
      >
        <Button
          position="absolute"
          top="20px"
          right="20px"
          isLoading={loading}
          isDisabled={loading}
          onClick={() => {
            setLoading(true);
            dispatch(WelcomeModalActions.complete());
          }}
        >
          Skip all
        </Button>
        <Image
          src="/images/welcomeModal/quote-invoice.png"
        />
      </Flex>
    </Flex>
  );
};

export default QuoteSettings;
