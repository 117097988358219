import {
  GridItem, forwardRef, GridItemProps, Tooltip, Text, Heading,
} from '@chakra-ui/react';
import { formatISO } from 'date-fns';
import React, { useContext, useMemo } from 'react';
import { CalendarGridContext } from '~/components/Calendar/CalendarGrid';

export interface CalendarGridItemProps extends GridItemProps {
  start: Date;
  end: Date;
  row?: number;
  rowSpan?: GridItemProps['rowSpan'];
  children?: React.ReactNode;
}

const CalendarGridItem = forwardRef<CalendarGridItemProps, typeof GridItem>(({
  start, end, row = 1, rowSpan = 1, ...rest
}: CalendarGridItemProps,
ref) => {
  const gridContext = useContext(CalendarGridContext);

  const { startLine, endLine } = useMemo(
    () => ({
      startLine: gridContext.getNearestColumnLine(start),
      endLine: gridContext.getNearestColumnLine(end),
    }),
    [gridContext, start?.getTime(), end?.getTime()],
  );

  const isWithinView = gridContext.isIntervalWithinView({ start, end });
  const isInconvenientlyCloseToEndOfGrid = (
    (startLine === endLine && startLine >= gridContext.columnCount)
  );

  if (!isWithinView || isInconvenientlyCloseToEndOfGrid) {
    return null;
  }

  const gridItem = (
    <GridItem
      {...rest}
      gridColumnStart={startLine}
      gridColumnEnd={endLine}
      gridRowStart={row}
      gridRowEnd={`span ${rowSpan}`}
      minWidth="0"
      ref={ref}
    />
  );

  if (!gridContext.debugMode) {
    return gridItem;
  }
  const tooltipLabel = (
    <>
      <Heading size="xs">CalendarGrid Debug</Heading>
      <Text>{`start: ${formatISO(start)}`}</Text>
      <Text>{`end: ${formatISO(end)}`}</Text>
      <Text>{`col: ${startLine} / ${endLine}`}</Text>
      <Text>{`row: ${row} / span ${rowSpan}`}</Text>
      <Text>{`columnCount: ${gridContext.columnCount}`}</Text>
    </>
  );

  return (
    <Tooltip label={tooltipLabel}>
      {gridItem}
    </Tooltip>
  );
});

export default CalendarGridItem;
