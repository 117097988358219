import { nanoid } from '@reduxjs/toolkit';
import { Equipment } from '~/types/equipment';
import fetchJson from '~/utils/fetchJson';

export const getEquipmentList = () => fetchJson<Equipment[]>('/api/equipment');

export const getEquipment = (equipmentId: string) => (
  fetchJson<Equipment>(`/api/equipment/${equipmentId}`)
);

export const putEquipment = (equipment: Equipment) => (
  fetchJson<Equipment>(`/api/equipment/${equipment.id ?? nanoid()}`, {
    method: 'PUT',
    body: equipment,
  })
);
