import { createReducer } from '@reduxjs/toolkit';
import { keyBy, sortBy } from 'lodash';
import ConsumablesActions from '~/redux/consumables/actions';
import { Dictionary } from '~/types/helpers';
import { SummaryConsumableRecord } from '~/types/consumable';

interface ConsumablesState {
  entities: Dictionary<SummaryConsumableRecord>;
  byJob: Dictionary<string[]>;
}

const consumablesReducer = createReducer<ConsumablesState>({
  entities: {},
  byJob: {},
}, (builder) => {
  builder.addCase(ConsumablesActions.consumablesUpdated, (state, action) => {
    const { jobId, consumables } = action.payload;
    return ({
      entities: {
        ...state.entities,
        ...keyBy(consumables, (t) => t.id),
      },
      byJob: {
        ...state.byJob,
        [jobId]: sortBy(
          consumables,
          (t) => t.name,
          (t) => t.id,
        ).map((t) => t.id),
      },
    });
  });
});

export default consumablesReducer;
