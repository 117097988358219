import { without } from 'lodash';
import { RootState } from '~/redux/reducer';
import { soilTypeImageIds } from '~/types/soilType';

export const getActiveSoilTypes = (state: RootState) => Object
  .values(state.soilTypes.data.byId)
  .filter((x) => !x.isDeleted);

export const getAvailableSoilImages = (state: RootState) => without(
  soilTypeImageIds, 'blank', ...getActiveSoilTypes(state).map((x) => x.imageId),
);
