import {
  Box, Button, Flex, Grid, Heading, Link, Text, Stack,
} from '@chakra-ui/react';
import React from 'react';
import { isIOS } from 'react-device-detect';
import { first, split } from 'lodash';
import LinkButton from '~/components/LinkButton';
import LoginCard from '~/components/LoginCard';
import { magnetizeDocsUrl, mobileStoreLinks } from '~/helpers/config';
import { useCheckRole } from '~/hooks/useCheckRole';
import Routes from '~/pages/routes';
import { CognitoGroup } from '~/types/cognitoUser';
import { selectCurrentUser } from '~/redux/currentUser/selectors';
import { useAppSelector } from '~/redux/store';

const MobileRedirectPage = () => {
  const storeLink = isIOS ? mobileStoreLinks.ios : mobileStoreLinks.android;
  const isAdmin = useCheckRole(CognitoGroup.Admin);
  const currentUser = useAppSelector((state) => selectCurrentUser(state));
  const currentUserFirstName = first(split(currentUser.staff?.name ?? '', ' '));

  const PhoneImage = () => (
    <Box pt={6}>
      <img
        width="210px"
        height="37px"
        alt="Mobile App"
        src="/images/redirect-phone.png"
      />
    </Box>
  );

  const LogoImage = () => (
    <Box pt={6}>
      <img
        width="210px"
        height="37px"
        alt="Magnetize Logo"
        src="/images/login-logo.png"
      />
    </Box>
  );

  if (isAdmin) {
    return (
      <LoginCard>
        <Grid gap={6}>
          <Text pl={0}>
            Magnetize is built for your desktop computer or laptop,
            switch now if you have one handy.
          </Text>
          <LinkButton
            isGreen
            noUnderline
            to={Routes.jobCalendar({})}
            justifyContent="left"
          >
            Continue to desktop site
          </LinkButton>
        </Grid>
      </LoginCard>
    );
  }

  return (
    <Flex
      boxShadow="md"
      borderRadius="md"
      bgColor="white"
      width={{
        base: '100vw', sm: 'fit-content', md: '425px',
      }}
      height={{ base: '100vh', sm: 'fit-content', md: 'fit-content' }}
    >
      <Flex
        flex={1}
        direction="column"
        marginLeft={12}
        marginRight={12}
        marginTop={12}
      >
        <LogoImage />
        <Stack spacing={4} mt={14} mb={8}>
          <form>
            <Grid gap={6}>
              <Text pl={0} fontSize="xl" fontWeight={900}>
                {`Hey${currentUserFirstName ? ` ${currentUserFirstName}` : ''}, welcome! 👋`}
              </Text>
              <Heading pl={0} fontWeight={700} textTransform="none">
                Open the Magnetize mobile app to continue
              </Heading>
              <Text pl={0} fontSize="xl">
                Your account is set up for the mobile app.
                Download and open the app from your smartphone.
                <Link
                  href={`${magnetizeDocsUrl}`}
                  target="_blank"
                  color="magnetize.brand-4"
                >
                  {' More help'}
                </Link>
              </Text>
              <Flex>
                <Button
                  as={Link}
                  isExternal
                  href={storeLink}
                  size="lg"
                >
                  DOWNLOAD & OPEN APP
                </Button>
              </Flex>
            </Grid>
          </form>
        </Stack>
        <PhoneImage />
      </Flex>
    </Flex>
  );
};

export default MobileRedirectPage;
