import { createReducer } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import { ScheduleEventActions } from '~/redux/schedule/actions';
import { Dictionary } from '~/types/helpers';
import { ScheduleEvent } from '~/types/ScheduleEvent';

const scheduleEventReducer = createReducer(
  {} as Dictionary<ScheduleEvent>,

  (builder) => (builder
    .addCase(ScheduleEventActions.eventsUpdated, (state, action) => ({
      ...state,
      ...action.payload.events,
    }))

    .addCase(ScheduleEventActions.eventDeleted, (state, action) => (
      pickBy(state, (_, id) => id !== action.payload.eventId
        && (state[id] as any)?.parentId !== action.payload.eventId)))
  ),
);

export default scheduleEventReducer;
