import {
  Accordion, Box, SkeletonText,
} from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import { JobEventViewModel, makeViewModelsForRealJobEvents } from '~/components/JobCalendar/JobEventViewModel';
import MagnetizeAccordionItem from '~/components/MagnetizeAccordionItem';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import AddScheduleEventModal from '~/pages/Job/components/schedule/AddScheduleEventModal';
import JobScheduleCalendar from '~/pages/Job/components/schedule/JobScheduleCalendar';
import ScheduledDatesAccordion from '~/pages/Job/components/schedule/ScheduledDatesAccodion';
import { useResourcesQuery } from '~/queries/useResourcesQuery';
import { ScheduleEventActions } from '~/redux/schedule/actions';
import { selectScheduleEventsForJob } from '~/redux/schedule/selectors';

const JobScheduleTab = ({
  jobId,
} : { jobId: string }) => {
  const { data: scheduleEvents, isLoading } = useTrackedFetch({
    key: `schedule-for-job-${jobId}`,
    trigger: () => ScheduleEventActions.fetchForJob({ jobId }),
    selector: (state) => selectScheduleEventsForJob(state, jobId),
  });

  const { data: resourceViewModels } = useResourcesQuery();

  const jobEvents = useMemo(() => (
    makeViewModelsForRealJobEvents(
      scheduleEvents ?? [],
      resourceViewModels,
    )),
  [scheduleEvents, resourceViewModels]);

  const [selectedEvent, setSelectedEvent] = useState<JobEventViewModel>(null);
  const [createEvent, setCreateEvent] = useState(false);

  return (
    <>
      <Accordion
        allowMultiple
        allowToggle
        defaultIndex={0}
      >
        <MagnetizeAccordionItem heading="Scheduled dates">
          {isLoading
            ? <SkeletonText noOfLines={2} />
            : (
              <ScheduledDatesAccordion
                jobEvents={jobEvents}
                onCreateEvent={() => {
                  setCreateEvent(true);
                }}
                onSelectEvent={(e) => {
                  setSelectedEvent(e);
                }}
              />
            )}
        </MagnetizeAccordionItem>

        {!isLoading
            && (
            <Box mt={8}>
              <JobScheduleCalendar jobEvents={jobEvents} />
            </Box>
            )}
      </Accordion>

      {(createEvent || selectedEvent) && (
      <AddScheduleEventModal
        jobId={jobId}
        event={selectedEvent}
        onClose={() => {
          setSelectedEvent(null);
          setCreateEvent(false);
        }}
      />
      )}
    </>
  );
};

export default JobScheduleTab;
