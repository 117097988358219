import { Quote } from '~/types/quote';
import fetchJson from '~/utils/fetchJson';

export const getCustomerQuote = (token: string) => fetchJson<Quote>(
  `/api-public/quote?token=${token}`,
);

export const postAcceptCustomerQuote = (token: string, comment: string) => fetchJson<Quote>(
  `/api-public/quote/accept?token=${token}`,
  {
    method: 'POST',
    body: { comment },
  },
);

export const postQueryCustomerQuote = (token: string, comment: string) => fetchJson<Quote>(
  `/api-public/quote/query?token=${token}`,
  {
    method: 'POST',
    body: { comment },
  },
);
