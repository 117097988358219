import { Icon, Text } from '@chakra-ui/react';
import {
  isNumber, orderBy,
} from 'lodash';
import React from 'react';
import { ReactComponent as Water } from '~/assets/live_geo_water_icon.svg';
import { CountSectionAccordionItem } from '~/components/CountSectionAccordionItem';
import ExpandableText from '~/components/ExpandableText';
import LogRow from '~/pages/LiveGeo/components/LogRow';

const WaterLogsRow = ({
  waterLevel, waterLevelNote,
} : {
  waterLevel: number;
  waterLevelNote?: string;
}) => {
  const hasWaterLevel = isNumber(waterLevel);

  const rows = [
    ...(hasWaterLevel ? [{
      depth: waterLevel,
      component: (
        <LogRow key="water">
          <Text fontWeight="semibold" minW="62px">
            {waterLevel}
            m
          </Text>
          <Text>Water level</Text>
          <ExpandableText gridColumn="3 /  span 2" fontStyle="italic">
            {waterLevelNote || ''}
          </ExpandableText>
        </LogRow>),
    }] : []),
  ];

  return (
    <CountSectionAccordionItem
      title="Water"
      icon={<Icon boxSize={8} as={Water} />}
    >
      {orderBy(rows, (r) => r.depth).map((r) => r.component)}
    </CountSectionAccordionItem>
  );
};

export default WaterLogsRow;
