import { Flex, Text } from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import { round, sumBy, uniq } from 'lodash';
import React, { useMemo } from 'react';
import CenteredFlex from '~/components/CenteredFlex';
import { TimeRecordWithStaffAndPrice } from '../helpers';
import { LineItem } from '~/types/lineItem';
import { getTimeRecordTotalMinutes } from '~/types/time';
import QuoteLineItemCard from './QuoteLineItemCard';
import ReconcileButtons from '~/pages/Job/components/invoices/ReconciliationManager/components/ReconcileButtons';

export const TimeRecordsCard = (
  { timeRecords, overBudget } :
  { timeRecords : TimeRecordWithStaffAndPrice[], overBudget: boolean },
) => {
  const totalMinutes = useMemo(() => (
    sumBy(timeRecords, (tr) => getTimeRecordTotalMinutes(tr))
  ), [timeRecords]);
  const totalTravelTime = useMemo(() => (
    sumBy(timeRecords, (tr) => tr.travelTimeMinutes)
  ), [timeRecords]);

  return (
    <Flex
      flex={1}
      boxShadow="0px 1px 2px rgba(0, 0, 0, 0.1)"
      borderRadius="2"
      backgroundColor={overBudget ? 'rgba(255, 138, 0, 0.05)' : 'rgba(53,155,75, 0.05)'}
      borderWidth={1}
      borderColor="gray.200"
      my={5}
      py={5}
      px={4}
      direction="column"
    >
      <Text fontWeight="bold">{timeRecords[0].price ? timeRecords[0].price.name : ''}</Text>
      {timeRecords.map((tr) => (
        <React.Fragment key={tr.id}>
          <CenteredFlex justifyContent="space-between">
            <Text>
              {tr.staff.name}
            </Text>
            <Text>
              {`${round(getTimeRecordTotalMinutes(tr) / 60, 2)}`}
            </Text>
          </CenteredFlex>
          {tr.travelTimeMinutes > 0 && (
          <CenteredFlex key={`${tr.id}-travel`} justifyContent="space-between">
            <Text>
              {`${tr.staff.name} (travel)`}
            </Text>
            <Text>
              {`${round(tr.travelTimeMinutes / 60, 2)}`}
            </Text>
          </CenteredFlex>
          )}
        </React.Fragment>
      ))}
      <CenteredFlex mt={4} flex={0} justifyContent="flex-end" fontWeight="600">
        {`${round((totalMinutes + totalTravelTime) / 60, 2)} hrs`}
      </CenteredFlex>
    </Flex>
  );
};

const TimeRecordReconciliationRow = (
  {
    quoted, alreadyInvoiced, logged, onItemAdded, onItemIgnored, isDisabled,
  } : {
    quoted: LineItem[];
    alreadyInvoiced: LineItem[];
    logged: TimeRecordWithStaffAndPrice[];
    onItemAdded: (lineItem: LineItem) => void;
    onItemIgnored: () => void;
    isDisabled: boolean;
  },
) => {
  const hasQuoted = quoted.length > 0;

  const totalLoggedHours = round(
    sumBy(
      logged, (l) => getTimeRecordTotalMinutes(l),
    ) / 60,
    2,
  );
  const totalQuotedQuantity = sumBy(quoted, (q) => q.quantity);
  const totalAlreadyInvoicedQuantity = sumBy(alreadyInvoiced, (q) => q.quantity);

  const quotedMatchesLogged = totalLoggedHours === totalQuotedQuantity;

  const addToInvoice = (useQuoted: boolean) => {
    const staffNames = uniq(logged.map((l) => l.staff.name)).join(', ');

    if (useQuoted) {
      const quantity = Math.max(totalQuotedQuantity - totalAlreadyInvoicedQuantity, 0);
      if (quantity > 0) {
        onItemAdded({
          id: nanoid(),
          ...(quoted[0].priceId ? {
            type: 'price',
            priceId: quoted[0].priceId,
          } : {
            type: 'custom',
          }),
          name: quoted[0].name,
          order: 0,
          quantity,
          unitPriceCents: quoted[0].unitPriceCents,
          description: quoted[0].description,
        });
      } else {
        onItemIgnored();
      }
    } else if (logged[0].price) {
      onItemAdded({
        id: nanoid(),
        type: 'price',
        name: logged[0].price.name,
        order: 0,
        quantity: totalLoggedHours,
        priceId: logged[0].price.id,
        unitPriceCents: logged[0].price.unitPriceCents,
        description: logged[0].price.description,
      });
    } else {
      onItemAdded({
        id: nanoid(),
        type: 'custom',
        name: staffNames,
        order: 0,
        quantity: totalLoggedHours,
        unitPriceCents: 0,
      });
    }
  };

  return (
    <Flex>
      {hasQuoted
        ? (
          <QuoteLineItemCard
            name={quoted[0].name}
            unitPriceCents={quoted[0].unitPriceCents}
            unit={quoted[0].unit}
            quotedQuantity={totalQuotedQuantity}
            alreadyInvoicedQuantity={totalAlreadyInvoicedQuantity}
          />
        )
        : <Flex flex={1} px={2} />}
      <Flex mx={6} alignItems="center" justifyContent="center">
        <ReconcileButtons
          isDisabled={isDisabled}
          onLeftClick={() => {
            addToInvoice(true);
          }}
          onRightClick={() => {
            addToInvoice(false);
          }}
          onIgnoreClick={onItemIgnored}
          hasQuoted={hasQuoted}
          quotedMatchesLogged={quotedMatchesLogged}
          invoicedMoreThanQuoted={totalQuotedQuantity - totalAlreadyInvoicedQuantity < 0}
          loggedMoreThanQuoted={
            totalQuotedQuantity - totalAlreadyInvoicedQuantity - totalLoggedHours < 0
          }
        />
      </Flex>
      <TimeRecordsCard
        timeRecords={logged}
        overBudget={totalQuotedQuantity - totalAlreadyInvoicedQuantity - totalLoggedHours < 0}
      />
    </Flex>
  );
};

export default TimeRecordReconciliationRow;
