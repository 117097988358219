import React, { useState } from 'react';
import {
  Button, HStack, Modal, ModalBody, ModalCloseButton,
  ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Text,
} from '@chakra-ui/react';
import { addDays, format, startOfDay } from 'date-fns';
import { max } from 'lodash';
import useTrackedAction from '~/hooks/useTrackedAction';
import { addInvoiceToJob } from '~/redux/jobs/actions';
import SmartDatePicker from '~/components/SmartDatePicker';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import { selectInvoicesByJobId } from '~/redux/invoices/selectors';
import { InvoiceActions } from '~/redux/invoices/actions';
import { useLoggedStats } from '~/hooks/useLoggedStats';
import CurrencyText from '~/components/CurrencyText';
import { parseISODateLocal } from '~/utils/parseTime';
import QuotedVsInvoiced from '~/pages/Job/components/invoices/QuotedVsInvoiced';
import PriceActions from '~/redux/prices/actions';
import { selectPrices } from '~/redux/prices/selectors';
import { Price } from '~/types/price';

const CreateProgressInvoiceModal = ({ jobId, onClose } : { jobId : string
  onClose: () => void; }) => {
  const { data: invoices } = useTrackedFetch({
    key: `job-invoices-${jobId}`,
    trigger: () => InvoiceActions.fetchForJob({ jobId }),
    selector: (state) => selectInvoicesByJobId(state, jobId),
  });

  const { data: prices } = useTrackedFetch<Price[]>({
    key: 'prices',
    selector: (state) => selectPrices(state),
    trigger: () => PriceActions.fetch(),
    equalityMode: 'deep',
  });

  const datedInvoices = invoices.filter((i) => i.toDate);

  const fromDate = datedInvoices.length > 0
    ? max(datedInvoices.map((i) => addDays(parseISODateLocal(i.toDate), 1)))
    : null;
  const [toDate, setToDate] = useState(max([fromDate, startOfDay(new Date())]));
  const interval = fromDate && toDate && fromDate < toDate
    ? { start: fromDate, end: toDate }
    : null;

  const { total, count, earliestEntry } = useLoggedStats({
    prices,
    jobId,
    interval,
  });

  const totalLogs = total;
  const workStarted = earliestEntry;
  const logs = count;
  let fromText = 'From today (no logs)';
  if (fromDate) {
    fromText = `last invoice ${format(fromDate, 'd MMM yyyy')}`;
  } else if (workStarted) {
    fromText = `work started ${format(workStarted, 'd MMM yyyy')}`;
  }

  const { isLoading: isCreateInvoiceLoading, trigger: createInvoice } = useTrackedAction({
    trigger: () => addInvoiceToJob({
      jobId,
      fromDate: format(fromDate || workStarted || new Date(), 'yyyy-MM-dd'),
      toDate: format(toDate, 'yyyy-MM-dd'),
    }),
    onSuccess: onClose,
  });

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen
      onClose={onClose}
      size="3xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Create Progress Invoice
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <QuotedVsInvoiced jobId={jobId} />

          <HStack justify="right" mb={5}>
            <Text>{fromText}</Text>
            <Text>to</Text>
            <SmartDatePicker
              minDate={fromDate ? addDays(fromDate, 1) : workStarted}
              value={toDate.toISOString()}
              selected={toDate}
              onChange={setToDate}
            />
          </HStack>
          <HStack justify="right">
            <Text color="magnetize.text-3" fontWeight="bold">{`${logs} log${logs === 1 ? '' : 's'} accrued`}</Text>
          </HStack>
          <HStack justify="right">
            <Text color="magnetize.text-3">
              est.
              {' '}
              <CurrencyText value={totalLogs} />
            </Text>
          </HStack>
        </ModalBody>
        <ModalFooter>
          <Button
            size="lg"
            colorScheme="brandDark"
            isLoading={isCreateInvoiceLoading}
            onClick={createInvoice}
            disabled={isCreateInvoiceLoading}
          >
            { isCreateInvoiceLoading && <Spinner mr={5} />}
            NEXT
          </Button>
          <Button variant="ghost" onClick={onClose}>cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateProgressInvoiceModal;
