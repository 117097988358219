import { Text } from '@chakra-ui/react';
import { intlFormat } from 'date-fns';
import React, { useContext, useMemo } from 'react';
import { CalendarContext } from '~/components/Calendar/CalendarContext';
import CalendarGrid from '~/components/Calendar/CalendarGrid';
import CalendarGridItem from '~/components/Calendar/CalendarGridItem';

const CalendarHeader = () => {
  const calendarContext = useContext(CalendarContext);
  const daysInSpan = useMemo(
    () => calendarContext.days
      .map((day) => ({
        ...day,
        weekday: intlFormat(day.start, { weekday: 'short' }),
        day: intlFormat(day.start, { day: 'numeric' }),
        month: intlFormat(day.start, { month: 'short' }),
      })),
    [calendarContext],
  );

  const isCompactMode = daysInSpan.length > 14;

  return (
    <CalendarGrid subdivisions={1} rowGap="4" justifyContent="center">
      {daysInSpan.map((d) => (
        <CalendarGridItem key={d.id} start={d.start} end={d.end} py="2">
          <Text
            textAlign="center"
            color={d.isToday ? 'magnetize.brand-4' : 'magnetize.text-2'}
            fontWeight={d.isToday ? 'bold' : undefined}
            fontSize="12px"
            textTransform="uppercase"
          >
            { isCompactMode ? d.day : `${d.weekday} ${d.day}`}
          </Text>
        </CalendarGridItem>
      ))}
    </CalendarGrid>
  );
};

export default CalendarHeader;
