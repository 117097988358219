import {
  FormControl, FormLabel, Grid, GridItem, Icon,
} from '@chakra-ui/react';
import { AddOutlined } from '@material-ui/icons';
import {
  getHours,
  getMinutes, getTime, parseISO, set,
} from 'date-fns';
import { isNil } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import LinkButton from '~/components/LinkButton';
import SmartDatePicker from '~/components/SmartDatePicker';
import StaffAndEquipmentSelect from '~/components/StaffAndEquipmentSelect';
import TimePicker from '~/components/TimePicker';
import { getTimeRecordTotalMinutes, makeTimeId, TimeRecord } from '~/types/time';
import { getIsoDate } from '~/utils/calendarHelpers';
import { minutesToDuration, parseDuration, Time } from '~/utils/parseTime';

const timeIsBefore = (a: Time, b: Time) => {
  if (a.hours === b.hours) {
    return a.minutes < b.minutes;
  }
  return a.hours < b.hours;
};

const GRID_AREAS = `
  "date user user user"
  "start end down total"
  "travel empty empty empty"
`;

interface TimeRecordFormProps {
  value: TimeRecord;
  jobId: string;
  onChange: (value: TimeRecord) => void;
}

const TimeRecordRowForm = ({
  jobId, value, onChange,
}: TimeRecordFormProps) => {
  const [date, setDate] = useState<Date>();
  const [staffId, setStaffId] = useState<string>();
  const [startTime, setStartTime] = useState<Time>(null);
  const [endTime, setEndTime] = useState<Time>(null);
  const [downtime, setDowntime] = useState<Time>(parseDuration('0:00'));
  const [travelTime, setTravelTime] = useState<Time>(parseDuration('0:00'));
  const [showTravelTime, setShowTravelTime] = useState(false);

  const [isStartTimeTouched, setStartTimeTouched] = useState(false);
  const [isEndTimeTouched, setEndTimeTouched] = useState(false);
  const [isDowntimeTouched, setDowntimeTouched] = useState(false);
  const [isDowntimeInvalid, setIsDowntimeInvalid] = useState(false);
  const [isTravelTimeTouched, setTravelTimeTouched] = useState(false);
  const [isTravelTimeInvalid, setIsTravelTimeInvalid] = useState(false);

  const [needsUpdate, setNeedsUpdate] = useState(false);

  useEffect(() => {
    const parsedDate = value
      ? parseISO(value?.date)
      : new Date();

    setDate(parsedDate);
    setStaffId(value?.staffId);
    setStartTime(value && value.start ? {
      hours: getHours(value.start),
      minutes: getMinutes(value.start),
    } : null);

    setEndTime(value && value.end ? {
      hours: getHours(value.end),
      minutes: getMinutes(value.end),
    } : null);

    setDowntime(value && !isNil(value.downtimeMinutes) ? {
      hours: Math.floor(value.downtimeMinutes / 60),
      minutes: value.downtimeMinutes % 60,
    } : parseDuration('0:00'));

    setTravelTime(value && !isNil(value.travelTimeMinutes) ? {
      hours: Math.floor(value.travelTimeMinutes / 60),
      minutes: value.travelTimeMinutes % 60,
    } : parseDuration('0:00'));
  }, [value]);

  const timezone = useMemo(() => Intl.DateTimeFormat().resolvedOptions().timeZone, []);

  const isStartTimeValid = !!startTime;
  const isEndTimeValid = !!endTime && (!startTime || timeIsBefore(startTime, endTime));

  const getTimestamp = (d: Date, t: Time) => (
    d && t ? getTime(set(d, t)) : null
  );

  const mkTimeRecord = (): TimeRecord => {
    const formattedDate = date ? getIsoDate(date) : null;
    return {
      id: makeTimeId({ date: formattedDate, jobId, staffId }),
      jobId,
      staffId,
      date: formattedDate,
      start: getTimestamp(date, startTime),
      end: getTimestamp(date, endTime),
      downtimeMinutes: downtime ? (60 * downtime.hours + downtime.minutes) : null,
      travelTimeMinutes: downtime ? (60 * travelTime.hours + travelTime.minutes) : null,
      timezone,
    };
  };

  const propagateUpdate = () => {
    onChange?.(mkTimeRecord());
  };

  const duration = useMemo(() => {
    const r = mkTimeRecord();
    const totalMinutes = r && r.date && r.start && r.end ? getTimeRecordTotalMinutes(r) : 0;
    return minutesToDuration(totalMinutes);
  }, [date, startTime, endTime, downtime]);

  useEffect(() => {
    if (needsUpdate) {
      propagateUpdate();
      setNeedsUpdate(false);
    }
  }, [needsUpdate]);

  const updateFormValue = () => setNeedsUpdate(true);

  return (
    <Grid
      gridTemplateColumns="3fr 3fr 2fr 2fr"
      gridTemplateAreas={GRID_AREAS}
      columnGap="2"
      rowGap="8"
      sx={{ '& > *': { minWidth: 0 } }}
    >
      <GridItem gridArea="date">
        <FormControl isInvalid>
          <FormLabel>Date</FormLabel>
          <SmartDatePicker
            selected={date}
            onChange={(d: Date) => { setDate(d); updateFormValue(); }}
            selectsStart
          />
        </FormControl>
      </GridItem>
      <GridItem gridArea="user">
        <FormControl>
          <FormLabel>Staff</FormLabel>
          <StaffAndEquipmentSelect
            limitTo="staff"
            value={staffId}
            placeholder="Search staff"
            onChange={(s) => {
              setStaffId(s?.id);
              updateFormValue();
            }}
          />
        </FormControl>
      </GridItem>

      <GridItem gridArea="start">
        <FormControl isInvalid={isStartTimeTouched && !isStartTimeValid}>
          <FormLabel>Start Time</FormLabel>
          <TimePicker
            placeholder="0:00 am"
            time={startTime}
            onFocus={() => setStartTimeTouched(true)}
            onChange={(time) => {
              setStartTime(time);
              setStartTimeTouched(true);
              updateFormValue();
            }}
          />
        </FormControl>
      </GridItem>

      <GridItem gridArea="end">
        <FormControl isInvalid={isEndTimeTouched && !isEndTimeValid}>
          <FormLabel>Finish Time</FormLabel>
          <TimePicker
            time={endTime}
            placeholder="0:00 pm"
            onFocus={() => setEndTimeTouched(true)}
            onChange={(time) => {
              setEndTime(time);
              setEndTimeTouched(true);
              updateFormValue();
            }}
          />
        </FormControl>
      </GridItem>

      <GridItem gridArea="down">
        <FormControl isInvalid={isDowntimeTouched && (!downtime || isDowntimeInvalid)}>
          <FormLabel>Downtime</FormLabel>
          <TimePicker
            mode="duration"
            time={downtime}
            onChange={(d, isValid) => {
              setDowntime(d);
              setDowntimeTouched(true);
              setIsDowntimeInvalid(!isValid);
              updateFormValue();
            }}
          />
        </FormControl>
      </GridItem>

      <GridItem gridArea="total">
        <FormControl pl="4">
          <FormLabel>Total</FormLabel>
          <TimePicker
            variant="ghost"
            fontWeight="semibold"
            paddingLeft="0"
            mode="duration"
            time={duration}
            isReadOnly
          />
        </FormControl>
      </GridItem>

      <GridItem gridArea="travel" mt={!showTravelTime ? -6 : undefined}>
        {showTravelTime ? (
          <FormControl isInvalid={isTravelTimeTouched && (!travelTime || isTravelTimeInvalid)}>
            <FormLabel>Travel Time</FormLabel>
            <TimePicker
              mode="duration"
              time={travelTime}
              onChange={(d, isValid) => {
                setTravelTime(d);
                setTravelTimeTouched(true);
                setIsTravelTimeInvalid(!isValid);
                updateFormValue();
              }}
            />
          </FormControl>
        )
          : (
            <LinkButton
              onClick={() => {
                setShowTravelTime(true);
              }}
              leftIcon={<Icon fontSize="md" as={AddOutlined} />}
              isGreen
            >
              Add travel time
            </LinkButton>
          )}
      </GridItem>
    </Grid>
  );
};

export default TimeRecordRowForm;
