import { RootState } from '~/redux/reducer';
import { Tag } from '~/types/tag';

export const selectTagsByType = (
  state: RootState, type: string,
) : Tag[] => state.tags.data?.byType?.[type] || [];

export const getTagById = (
  state: RootState, id: string,
) : Tag => state.tags.data?.byId?.[id];
