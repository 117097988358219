import {
  Container, Heading, HStack, Link, Stack, Text,
} from '@chakra-ui/react';
import React from 'react';
import { Tenant } from '~/types/tenant';

interface QuoteViewTermsAndConditionsProps {
  termsAndConditions: string;
  tenant: Tenant;
}

const QuoteViewTermsAndConditions = (
  {
    termsAndConditions,
    tenant,
  } : QuoteViewTermsAndConditionsProps,
) => (
  <Stack spacing="8">
    {termsAndConditions && (
    <Stack spacing={2}>
      <Heading size="xs">Terms &amp; Conditions</Heading>
      <Container fontSize="xs" pl={0}>
        {termsAndConditions}
      </Container>
    </Stack>
    )}
    <Stack spacing="2">
      <Text fontSize="xs">Thank you for your business</Text>
      <Stack spacing="0">
        <HStack>
          <Text fontSize="xs" color="gray.600">
            {tenant?.phone}
          </Text>
          <Text fontSize="xs" color="gray.600">
            <Link href={`mailto:${tenant?.email}`} isExternal>
              {tenant?.email}
            </Link>
          </Text>
        </HStack>
        <Text
          fontSize="xs"
          color="gray.600"
        >
          <Link
            isExternal
            href={(tenant?.website?.indexOf('://') === -1)
              ? `http://${tenant?.website}`
              : tenant?.website}
            rel="noreferrer"
          >
            {tenant?.website}
          </Link>
        </Text>
      </Stack>
    </Stack>
  </Stack>
);

export default QuoteViewTermsAndConditions;
