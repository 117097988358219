import { Heading, Text } from '@chakra-ui/react';
import { differenceInDays, format } from 'date-fns';
import React from 'react';

import TimelineItem, { TimelineItemProps } from '~/components/TimelineItem';
import { selectEquipmentNames } from '~/redux/equipment/selectors';
import { selectStaffNames } from '~/redux/staff/selectors';
import { useAppSelector } from '~/redux/store';
import { JobTimelineScheduleChanged } from '~/types/job';
import { makeRangeTime } from '~/components/TimelineSchedule/helpers';
import StatusBadge from '~/components/JobStatusBadge';

const TimelineScheduleChanged = ({
  event, ...rest
}: {
  event: JobTimelineScheduleChanged;
  hideHeading?: boolean;
} & TimelineItemProps) => {
  const staffNames = useAppSelector((s) => selectStaffNames(s, event.staffIds || []));
  const equipmentNames = useAppSelector((s) => selectEquipmentNames(s, event.equipmentIds || []));
  let assigned = 'No resources currently assigned to schedule';
  let header = '';

  const from = event.from * 1000;
  const to = event.to * 1000;
  const daysDiff = differenceInDays(to, from);
  const headerInfo = `${daysDiff + 1} day${daysDiff > 0 ? 's' : ''}, ${makeRangeTime(from, to)} `;

  const note = event.notes || '';
  if (staffNames.length > 0 || equipmentNames.length > 0) {
    assigned = `Assigned to ${([...staffNames, ...equipmentNames]).join(', ')}`;
  }

  if (event.from !== event.previousFrom) {
    header = `JOB RESCHEDULED: ${headerInfo}`;
  } else if (event.to > event.previousTo) {
    header = `JOB EXTENDED: End date ${format(to, 'd MMM yyyy')}`;
  } else {
    header = `JOB SCHEDULE CHANGED: ${headerInfo}`;
  }

  return (
    <TimelineItem
      {...rest}
      event={event}
    >
      {event.status && <StatusBadge status={event.status} />}
      <Heading textTransform="none" size="sm" mb={1}>{header}</Heading>
      {assigned && (
      <Text>
        {assigned}
      </Text>
      )}
      {note && (
      <Text as="i">
        {note}
      </Text>
      )}

    </TimelineItem>
  );
};

export default TimelineScheduleChanged;
