import { createAction } from '@reduxjs/toolkit';
import { TaxRate } from '~/types/taxRate';

export default {
  fetch: createAction('taxRates/fetch'),
  save: createAction<{ taxRate: TaxRate }>('taxRate/save'),
  updated: createAction<{ taxRates: TaxRate[] }>('taxRate/updated'),
  setDefault: createAction<{ taxRateId: string }>('taxRate/setDefault'),
  delete: createAction<{ taxRateId: string }>('taxRate/delete'),
};
