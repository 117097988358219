import { TextProps } from '@chakra-ui/react';
import React from 'react';
import CurrencyText from '~/components/CurrencyText';
import CenteredFlex from '~/components/LineItemsEditor/components/CenteredFlex';

interface LineItemTotalProps {
  hide?: boolean;
  value?: number;
}

const LineItemTotal = ({ hide, value, ...props } : LineItemTotalProps & TextProps) => (
  <CenteredFlex flex="0 0 105px" justifyContent="flex-end">
    {!hide && (
      <CurrencyText size="lg" color="magnetize.text-3" value={value} {...props} />
    )}
  </CenteredFlex>
);

export default LineItemTotal;
