import React from 'react';
import {
  Textarea, Box, TextareaProps, Flex,
} from '@chakra-ui/react';

interface LineItemDescriptionProps {
  value: string;
  onChange: (value: string) => void;
}

const LineItemDescription = ({
  placeholder = 'Add description', value, onChange, ...props
} : LineItemDescriptionProps & TextareaProps) => (
  <Flex flex={1}>
    <Textarea
      autoFocus
      flex={1}
      pl={0}
      pt={0}
      maxLength={200}
      variant="ghost"
      fontStyle="italic"
      color="magnetize.text-4"
      rows={1}
      value={value}
      onChange={(e) => {
        const newValue = e.target.value;
        if (newValue !== value) {
          onChange(newValue);
        }
      }}
      placeholder={placeholder}
      {...props}
    />
    <Box width="316px" />
  </Flex>
);

export default LineItemDescription;
