import {
  Stack, FormLabel, RadioGroup, Radio,
} from '@chakra-ui/react';
import React from 'react';

/*
  JobSchedulingWorkWeek allows the user to select the number of days of the
  week that resources can be scheduled for a job. The work week days are
  stored as an numeric array where the numbers represent the days of the week,
  with 0 being Sunday, because that's how week days are numbered in the US.
 */
const JobSchedulingWorkWeek = ({ value = [], onChange }:
{ value: number[], onChange: (value: number[]) => void }) => {
  const days = value.length;

  /*
    Interpretation of the number of days selected by the radio controls to an array
    is done here, to handle the possible future cases where the days of the week may
    not be the standard weekends.
   */
  const updateValue: (change: number) => void = (change) => {
    let workWeek = [];
    switch (change) {
      case 5:
        workWeek = [1, 2, 3, 4, 5]; // Mon - Fri
        break;
      case 6:
        workWeek = [1, 2, 3, 4, 5, 6]; // Mon - Sat
        break;
      default:
        workWeek = [0, 1, 2, 3, 4, 5, 6]; // Mon - Sun
    }
    onChange(workWeek);
  };

  return (
    <>
      <FormLabel>Job Scheduling</FormLabel>
      <RadioGroup onChange={(e) => updateValue(parseInt(e, 10))} value={days}>
        <Stack direction="column">
          <Radio value={5}>5 days per week, Mon - Fri (exclude Saturday and Sunday)</Radio>
          <Radio value={6}>6 days per week, Mon - Sat (exclude Sunday)</Radio>
          <Radio value={7}>7 days per week, Mon - Sun</Radio>
        </Stack>
      </RadioGroup>
    </>
  );
};

export default JobSchedulingWorkWeek;
