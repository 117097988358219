import React from 'react';
import { CheckCircleIcon, WarningIcon, SmallCloseIcon } from '@chakra-ui/icons';
import {
  createStandaloneToast, UseToastOptions, Flex, Text, RenderProps,
} from '@chakra-ui/react';
import { omit } from 'lodash';
import * as Sentry from '@sentry/react';
import theme from '~/theme';

/**
 * This allows the creation of toast notifications from outside the view tree --
 * e.g. from sagas
 */
export const toast = createStandaloneToast({
  theme,
});

/**
 * A helper to show or update an existing toast.
 *
 * If the toast has an ID, and there is an existing toast matching this id, then
 * it will be updated.
 *
 * @param options
 */
export function showToast(options: UseToastOptions) {
  const isSuccess = options.status === 'success' || options.status === 'info';
  const bg = isSuccess ? 'magnetize.brand-5' : '#e53e3e';
  const icon = isSuccess ? <CheckCircleIcon /> : <WarningIcon />;
  const hasBody = options.title && options.description;

  const optionsWithDefaults = {
    isClosable: true,
    render: ({ onClose } : RenderProps) => (
      <Flex color="white" p={3} bg={bg} direction="column">
        <Flex direction="row" justifyContent="space-between" alignItems="center">
          {icon}
          <Text mx={3} flex={1} fontWeight="semibold">{options.title ?? options.description}</Text>
          <SmallCloseIcon cursor="pointer" onClick={onClose} />
        </Flex>
        {hasBody && (
        <Flex mt={2} ml={7} mr={3}>
          <Text>{options.description}</Text>
        </Flex>
        )}
      </Flex>
    ),
    ...options,
  };

  if (options.id && toast.isActive(options.id)) {
    toast.update(options.id, omit(optionsWithDefaults, 'id'));
  } else {
    toast(optionsWithDefaults);
  }
}

export function showErrorToast(
  error: unknown,
  options?: Partial<UseToastOptions>,
) {
  let description: string;
  if (process.env.NODE_ENV === 'development') {
    const message = error instanceof Error ? error.message : String(error);
    description = `Dev: ${message}`;
  } else {
    description = 'Sorry! If this keeps happening, try refreshing your browser or check your internet connection.';
  }

  showToast({
    status: 'error',
    title: 'Something went wrong',
    description,
    ...options,
  });

  // eslint-disable-next-line no-console
  console.error(error);
  Sentry.captureException(error);
}

export function showSuccessToast(
  description: string,
  options?: Partial<UseToastOptions>,
) {
  showToast({
    ...options,
    description,
    status: 'success',
  });
}
