import {
  Box,
  Flex,
  Grid, HStack, Icon, Link, Text, Tooltip,
} from '@chakra-ui/react';
import { Cancel } from '@material-ui/icons';
import React, { useState } from 'react';
import FileTypeIcon from '~/components/FileTypeIcon';
import UploadedFile from '~/types/uploaded-file';

interface FileProps {
  file: UploadedFile;
  onFileRemoved?: (file: UploadedFile) => void;
}

const File = ({ file, onFileRemoved }: FileProps) => {
  const [showRemove, setShowRemove] = useState(false);
  return (
    <Flex
      position="relative"
      onMouseEnter={() => {
        setShowRemove(true);
      }}
      onMouseLeave={() => {
        setShowRemove(false);
      }}
    >
      {onFileRemoved && showRemove && (
      <Tooltip openDelay={500} hasArrow placement="top" label="Remove file">
        <Icon
          onClick={() => {
            onFileRemoved(file);
          }}
          zIndex="1"
          position="absolute"
          backgroundColor="white"
          borderRadius="50%"
          top={-2}
          right={-2}
          as={Cancel}
        />
      </Tooltip>
      )}
      <Link
        backgroundColor="magnetize.ui-2"
        flex="1"
        href={file.url}
        download={file.fileName}
        border="1px"
        borderColor="gray.200"
        p={2}
        borderRadius="sm"
        transition="all 0.2s"
        _hover={{ color: 'green.800', cursor: 'pointer', borderColor: 'green.700' }}
      >
        <HStack overflow="hidden">
          <Box>
            <FileTypeIcon contentType={file.contentType} fontSize="xl" />
          </Box>
          <Tooltip openDelay={1000} hasArrow placement="top" label={file.fileName}>
            <Text wordBreak="break-word" fontSize="md" fontWeight="bold" noOfLines={1}>
              {file.fileName}
            </Text>
          </Tooltip>
        </HStack>
      </Link>
    </Flex>
  );
};

interface FileListProps {
  files: UploadedFile[];
  onFileRemoved?: (file: UploadedFile) => void;
  compact?: boolean;
}

const UploadedFileList = ({ compact = false, files, onFileRemoved }: FileListProps) => (
  <Grid gap={2} gridTemplateColumns={`repeat(${compact ? '1' : '3'}, 1fr)`}>
    {
      files.map((file) => (
        <Flex key={file.s3Key}>
          {' '}
          <File file={file} onFileRemoved={onFileRemoved} />
        </Flex>
      ))
    }
  </Grid>
);

export default UploadedFileList;
