import {
  Button, Container, Flex, FormControl, FormLabel, Grid, Input,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import {
  MutationStatus, useMutation,
} from 'react-query';
import { useHistory } from 'react-router-dom';
import LoginBackground from '~/components/LoginBackground';
import LoginCard from '~/components/LoginCard';
import WarningLabel from '~/components/WarningLabel';
import { forgotPassword } from '~/helpers/auth';

export default () => {
  const history = useHistory();
  const [email, setEmail] = useState('');

  const { status, mutate, error }
  : { status: MutationStatus, mutate: any, error: Error } = useMutation(forgotPassword, {
    onSuccess: () => {
      history.push(`reset-password?action=forgot-password&email=${encodeURIComponent(email.trim())}`);
    },
  });

  const sanitizeErrorMessage = (err: Error) => {
    if (err.name === 'InvalidParameterException') return 'Please enter a valid email address.';
    if (err.name === 'UserNotFoundException') return 'No account found with that email address.';
    return 'An error occurred. Please try again.';
  };

  const isLoading = status === 'loading';

  return (
    <LoginBackground>
      <LoginCard title="Reset your password" showBackButton>
        <Grid
          gap={6}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !isLoading) {
              mutate(email);
            }
          }}
        >
          <Container pl={0} maxW="md" ml={0}>
            Enter the email address you use to login to Magnetize
            and we&apos;ll send you a code to reset your password.
          </Container>
          {error && <WarningLabel message={sanitizeErrorMessage(error)} />}
          <FormControl>
            <FormLabel>Email</FormLabel>
            <Input
              isDisabled={isLoading}
              value={email}
              onChange={(e) => { setEmail(e.target.value); }}
            />
          </FormControl>
          <Flex>
            <Button
              isDisabled={!email}
              isLoading={isLoading}
              size="lg"
              onClick={async () => {
                mutate(email.trim());
              }}
            >
              Send Code
            </Button>
          </Flex>
        </Grid>
      </LoginCard>
    </LoginBackground>
  );
};
