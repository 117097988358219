import React, { useMemo } from 'react';
import {
  createColumnHelper,
  SortingState,
} from '@tanstack/react-table';
import { orderBy } from 'lodash';
import { PriceBundleViewModel } from '~/pages/PriceBook/BundleViewModel';
import BundleRow from '~/pages/PriceBook/PriceList/BundleRow';
import PriceRow from '~/pages/PriceBook/PriceList/PriceRow';
import PriceBundleActions from '~/redux/priceBundle/actions';
import PriceActions from '~/redux/prices/actions';
import { useAppDispatch } from '~/redux/store';
import { Price } from '~/types/price';
import { SortableTable } from '~/components/SortableTable';
import calculateQuoteTotals from '~/utils/calculateQuoteTotals';

interface PriceListProps {
  prices: Price[];
  bundles: PriceBundleViewModel[];
  onEditPrice: (item: Price) => void;
  onDuplicatePrice: (item: Price) => void;
  onEditPriceBundle: (item: PriceBundleViewModel) => void;
  onDuplicatePriceBundle: (item: PriceBundleViewModel) => void;
}

const PriceList = ({
  prices, bundles, onEditPrice, onDuplicatePrice, onEditPriceBundle, onDuplicatePriceBundle,
}: PriceListProps) => {
  const dispatch = useAppDispatch();
  const defaultSort = useMemo(() => [{ id: 'name', desc: false }], []);
  const [sorting, setSorting] = React.useState<SortingState>(defaultSort || []);

  const columnHelper = createColumnHelper<Price|PriceBundleViewModel>();
  const columns = React.useMemo(
    () => [
      columnHelper.accessor((x) => ('code' in x ? x.code : ''), {
        id: 'code',
        header: 'Code',
        meta: {
          headerProps: {
            width: '10%',
          },
        },
      }),
      columnHelper.accessor((x) => x.name, {
        id: 'name',
        header: 'Name',
        meta: {
          headerProps: {
            paddingLeft: 0,
            width: '25%',
          },
        },

      }),
      columnHelper.accessor((x) => x.description, {
        id: 'description',
        header: 'Description',
        meta: {
          headerProps: {
            width: '35%',
          },
        },
      }),
      columnHelper.accessor((x) => ('category' in x ? x.category : 'bundle'), {
        id: 'category',
        header: 'Category',
        meta: {
          headerProps: {
            width: '15%',
          },
        },
      }),
      columnHelper.accessor((x) => ('unitPriceCents' in x ? x.unitPriceCents : ''), {
        id: 'unitPriceCents',
        header: 'Price',
        sortDescFirst: true,
        meta: {
          headerProps: {
            width: '10%',
          },
        },
      }),
      columnHelper.display({
        id: 'kebab-menu',
        header: '',
        meta: {
          headerProps: {
            width: '5%',
          },
        },
      }),
    ],
    [],
  );

  return (
    <>
      <SortableTable
        data={[
          ...orderBy(bundles, sorting.map((s) => {
            switch (s.id) {
              case 'code':
                return (x) => Object.keys(x.lineItems).length;
              case 'unitPriceCents':
                return (x) => {
                  const { subtotalCents } = calculateQuoteTotals(Object.values(x.lineItems));
                  return subtotalCents;
                };
              default:
                return s.id;
            }
          }) || ['name'], sorting.map((s) => (s.desc ? 'desc' : 'asc')) || ['asc']),
          ...orderBy(prices, sorting.map((s) => s.id) || ['name'], sorting.map((s) => (s.desc ? 'desc' : 'asc')) || ['asc']),
        ]}
        columns={columns}
        defaultSort={defaultSort}
        manualSorting
        onSortingChange={setSorting}
        renderRow={(row) => ('category' in row.original
          ? (
            <PriceRow
              key={row.original.id}
              price={row.original}
              onEdit={onEditPrice}
              onDuplicate={onDuplicatePrice}
              onDelete={() => dispatch(PriceActions.archive({ priceId: row.original.id }))}
            />
          )
          : (
            <BundleRow
              key={row.original.id}
              bundle={row.original as PriceBundleViewModel}
              prices={prices}
              onEdit={onEditPriceBundle}
              onDuplicate={onDuplicatePriceBundle}
              onDelete={() => dispatch(PriceBundleActions.archive({
                priceBundleId: row.original.id,
              }))}
            />
          ))}

      />
    </>
  );
};

export default PriceList;
