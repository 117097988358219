import {
  AlertDialog, AlertDialogBody, AlertDialogCloseButton, AlertDialogContent, AlertDialogFooter,
  AlertDialogHeader, AlertDialogOverlay, Button,
} from '@chakra-ui/react';
import React, { useRef } from 'react';

const noop = () => {};

interface ConfirmationDialogProps {
  isOpen?: boolean;
  title: string;
  children: React.ReactNode;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  colorScheme?: string;
  onClose?: () => void;
  onConfirm?: () => void;
}

const ConfirmationDialog = ({
  isOpen, children, title,
  onConfirm = noop,
  onClose = noop,
  colorScheme = 'red',
  confirmButtonLabel = 'OK',
  cancelButtonLabel = 'cancel',
}: ConfirmationDialogProps) => {
  const cancelRef = useRef<HTMLButtonElement>(null);
  const confirmAndClose = () => { onConfirm?.(); onClose?.(); };

  return (
    <AlertDialog
      isOpen={isOpen ?? false}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      size="xl"
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontWeight="bold" textAlign="left" paddingBottom={0}>
            {title}
          </AlertDialogHeader>
          <AlertDialogCloseButton tabIndex={-1} />
          <AlertDialogBody>
            {children}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button size="lg" colorScheme={colorScheme} onClick={confirmAndClose}>
              {confirmButtonLabel}
            </Button>
            <Button ref={cancelRef} variant="ghost" onClick={onClose}>
              {cancelButtonLabel}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default ConfirmationDialog;
