import {
  Box, useDisclosure,
} from '@chakra-ui/react';
import React, { useCallback } from 'react';
import ConfirmationDialog from '~/components/ConfirmationDialog';

interface EditRemoveProps {
  confirmationTitle: string;
  confirmationMessage: React.ReactNode;
  confirmButtonLabel?: string;
  skipConfirmation?: boolean;
  onRemoveConfirmed?: () => void;
  children: React.ReactNode | ((props: { onClick: () => void }) => React.ReactNode);
}

const RemoveButtonWithConfirmation = ({
  confirmationTitle, confirmationMessage,
  onRemoveConfirmed, skipConfirmation = false,
  confirmButtonLabel = 'Remove',
  children,
}: EditRemoveProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const onClick = useCallback(() => {
    if (skipConfirmation) {
      onRemoveConfirmed();
    } else {
      onOpen();
    }
  }, [skipConfirmation, onOpen, onRemoveConfirmed]);

  return (
    <>
      {typeof children === 'function'
        ? children({ onClick })
        : <Box onClick={onClick}>{children}</Box>}

      <ConfirmationDialog
        title={confirmationTitle}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={onRemoveConfirmed}
        colorScheme="brandDark"
        confirmButtonLabel={confirmButtonLabel}
      >
        {confirmationMessage}
      </ConfirmationDialog>
    </>
  );
};

export default RemoveButtonWithConfirmation;
