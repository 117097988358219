export const MAGNETIZE_COLOURS = {
  'brand-1': '#10AC6E',
  'brand-2': '#3AC6AA',
  'brand-3': '#266745',
  'brand-4': '#359B4B',
  'brand-5': '#2B7B3B',

  'ui-1': '#ffffff',
  'ui-2': '#F8F7F7',
  'ui-3': '#F2F0EE',
  'ui-4': '#CCCCCC',
  'ui-5': '#bcbcbc',
  'ui-6': '#1B282B',
  'ui-7': '#171913',
  'ui-8': '#359B4B',
  'ui-9': '#DFDEDD',

  'text-1': '#171913',
  'text-2': '#1B282B',
  'text-3': '#47595E',
  'text-4': '#939498',

  // This is an alphablended version of ui-4 at ~67% Many of the figma flats use
  // #ccc but with a 0.25px border radius, and the linear filtering used by SVG
  // (but not HTML/CSS) means that this colour is effectively just getting
  // blended with the background.
  'ui-4.a67': '#CCCCCCAA',

  'support-warning': '#ff8a00',
  'support-warning-tint': '#fff9f2',
  'support-error': '#E40707',
  'extra-inclo': '#E3DE68',
  'extra-inclo-text': '#96933F',
};

/**
 * The colour to use for focused control outlines, such as form controls.
 * This should be a valid CSS colour, as we need to do some manipulations
 * with it and can't always do a theme lookup.
 */
export const FORM_CONTROL_FOCUS_BORDER_COLOUR = MAGNETIZE_COLOURS['brand-4'];

export const SWITCH_RATIO = 2;
