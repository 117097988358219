import { Icon, Text, VStack } from '@chakra-ui/react';
import {
  isNumber, orderBy, values,
} from 'lodash';
import React from 'react';
import { ReactComponent as Installs } from '~/assets/live_geo_installs.svg';
import { CountSectionAccordionItem } from '~/components/CountSectionAccordionItem';
import ExpandableText from '~/components/ExpandableText';
import LogRow from '~/pages/LiveGeo/components/LogRow';
import { PiezoLog } from '~/types/geotechnical';
import { SurfaceType } from '~/types/surfaceType';

export const InstallLogsRow = ({
  piezoLogs,
  incloEndDepth,
  inclolNote,
  surfaceTypes,
  piezoTypes,
  surfaceTypeNote,
  surfaceTypeId,
} : {
  piezoLogs: { [id: string]: PiezoLog };
  incloEndDepth?: number;
  inclolNote?: string;
  surfaceTypes: { [key: string]: SurfaceType };
  piezoTypes: { [key: string]: SurfaceType };
  surfaceTypeId?: string;
  surfaceTypeNote?: string;
}) => {
  const hasSurface = !!surfaceTypeId;
  const hasInclo = isNumber(incloEndDepth);

  const rows = [
    ...(hasSurface ? [{
      depth: 0,
      component: (
        <LogRow key="surface">
          <Text fontWeight="semibold" minW="62px">
            0 m
          </Text>
          <Text>
            {surfaceTypes[surfaceTypeId]?.name}
          </Text>
          <ExpandableText gridColumn="3 /  span 2" fontStyle="italic">
            {surfaceTypeNote || ''}
          </ExpandableText>
        </LogRow>),
    }] : []),
    ...(hasInclo ? [{
      depth: incloEndDepth,
      component: (
        <LogRow key="inclo">
          <Text fontWeight="semibold" minW="62px">
            {incloEndDepth}
            m
          </Text>
          <Text>Inclo</Text>
          <ExpandableText gridColumn="3 /  span 2" fontStyle="italic">
            {inclolNote || ''}
          </ExpandableText>
        </LogRow>),
    }] : []),
    ...orderBy(values(piezoLogs), (x) => x.startDepth).map((log, index) => ({
      depth: log.startDepth,
      component: (
        // eslint-disable-next-line react/no-array-index-key
        <LogRow key={`piezo-log-${index}`}>
          <VStack alignItems="flex-start">
            <Text fontWeight="semibold" whiteSpace="nowrap" minW="62px">
              {`${log.startDepth}m - ${log.endDepth}m`}
            </Text>
          </VStack>
          <Text gridColumn="span 2">{`Piezo ${index + 1}${piezoTypes[log.piezoTypeId] ? ` (${piezoTypes[log.piezoTypeId].name})` : ''}`}</Text>
          <ExpandableText fontStyle="italic">
            {log.note || ''}
          </ExpandableText>
        </LogRow>),
    })),
  ];

  return (
    <CountSectionAccordionItem
      title="Installs"
      icon={<Icon boxSize={8} as={Installs} />}
    >
      {orderBy(rows, (r) => r.depth).map((r) => r.component)}
    </CountSectionAccordionItem>
  );
};
