import { createReducer } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';
import PriceActions from '~/redux/prices/actions';
import { Dictionary } from '~/types/helpers';
import { Price } from '~/types/price';

interface PricesState {
  entities: Dictionary<Price>;
}

const priceReducer = createReducer<PricesState>({
  entities: {},
}, (builder) => {
  builder.addCase(PriceActions.updated, (state, action) => {
    const { prices } = action.payload;
    return ({
      entities: {
        ...state.entities,
        ...keyBy(prices, (p) => p.id),
      },
    });
  });
});

export default priceReducer;
