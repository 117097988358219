import {
  Flex, FlexProps,
} from '@chakra-ui/react';
import React from 'react';

const CenteredFlex = (
  { children, ...props }
  : FlexProps,
) => (
  <Flex
    flex={1}
    alignItems="center"
    justifyContent="center"
    {...props}
  >
    {children}
  </Flex>
);

export default CenteredFlex;
