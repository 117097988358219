import { createSelector } from '@reduxjs/toolkit';
import selectLocalEdits from '~/redux/localEdits/selectors';
import { RootState } from '~/redux/store';
import { Quote } from '~/types/quote';
import applyLocalEdits from '~/utils/applyLocalEdits';

export const selectQuoteById = (
  state: RootState, quoteId: string,
) : Quote => state.quotes.quotesById[quoteId];

export const selectQuotesByJobId = (
  state: RootState, jobId: string,
) : Quote[] => {
  const quoteIds: string[] = state.quotes.quoteIdsByJobId[jobId] ?? [];
  return quoteIds
    .map((quoteId) => state.quotes.quotesById[quoteId])
    .filter((q) => q) ?? [];
};

export const selectQuoteWithEdits = createSelector(
  selectQuoteById,
  selectLocalEdits,
  (quote, edits) => applyLocalEdits(quote, edits),
);
