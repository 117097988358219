import { differenceInMinutes, isAfter } from 'date-fns';
import { getIsoDate } from '~/utils/calendarHelpers';

export interface TimeRecord {
  id: string;
  staffId: string;
  jobId: string;

  /** Unix timestamps */
  start: number;
  end: number;
  timezone?: string;

  downtimeMinutes: number;
  travelTimeMinutes: number;

  /** ISO 8601 date string */
  date: string;
  type?: string;

  note?: string;
  priceId?: string;
}

export const isTimeRecordValid = (timeRecord: TimeRecord): boolean => {
  if (!timeRecord) {
    return false;
  }

  const {
    date, jobId, staffId, start, end, type,
  } = timeRecord;

  if (!date || (!jobId && !type) || !staffId || !start || !end) {
    return false;
  }

  if (isAfter(start, end)) {
    return false;
  }

  if (date !== getIsoDate(start) || date !== getIsoDate(end)) {
    return false;
  }

  return true;
};

export const makeTimeId = ({
  date, jobId, staffId,
}: { date: string, jobId: string, staffId: string }) => (
  `${date}-${jobId}-${staffId}`
);

export const getTimeRecordTotalMinutes = (r: TimeRecord) => (
  differenceInMinutes(r.end, r.start) - (r.downtimeMinutes || 0)
);
export const timeTypes = [
  { type: 'yard', label: 'Yard time' },
  { type: 'training', label: 'Training' },
  { type: 'annual', label: 'Annual Leave' },
  { type: 'sick', label: 'Sick leave' },
  { type: 'travel', label: 'Travel' },
  { type: 'bereavement', label: 'Bereavement Leave' },
  { type: 'health-and-safety', label: 'Health & Safety' },
  { type: 'reports', label: 'Reports' },
  { type: 'other', label: 'Other' },
];
