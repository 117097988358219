import {
  Accordion, SkeletonText, Text,
} from '@chakra-ui/react';
import { round } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { DnsOutlined } from '@material-ui/icons';
import MagnetizeAccordionItem from '~/components/MagnetizeAccordionItem';
import { ReactComponent as Drill } from '~/assets/live_geo_drill_icon.svg';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import JobLegacyConsumablesTable from '~/pages/Job/components/logs/JobLegacyConsumablesTable';
import JobEquipmentLogsTable from '~/pages/Job/components/logs/JobEquipmentLogsTable';
import JobTimesheetsTable from '~/pages/Job/components/logs/JobTimesheetsTable';
import ConsumablesActions from '~/redux/consumables/actions';
import { selectConsumablesForJob } from '~/redux/consumables/selectors';
import { EquipmentActions } from '~/redux/equipment/actions';
import { LogsActions } from '~/redux/logs/actions';
import PrefsActions from '~/redux/prefs/actions';
import PriceActions from '~/redux/prices/actions';
import { QuoteActions } from '~/redux/quote/actions';
import { ScheduleEventActions } from '~/redux/schedule/actions';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import TimesheetActions from '~/redux/timesheets/actions';
import { selectTimeRecordsForJob } from '~/redux/timesheets/selectors';
import { getTimeRecordTotalMinutes } from '~/types/time';
import { selectTenantPowerUps } from '~/redux/currentUser/selectors';
import JobLiveGeoLogsTable from '~/pages/Job/components/logs/JobLiveGeoLogsTable';
import { LiveGeoActions } from '~/redux/liveGeo/actions';
import { selectJobById } from '~/redux/jobs/selectors';
import JobConsumablesTable from '~/pages/Job/components/logs/JobConsumablesTable';
import { ReactComponent as SnowPlow } from '~/assets/icon-snowplow.svg';
import { ReactComponent as UserHelmet } from '~/assets/icon-user-helmet.svg';

const JobLogsTab = ({ jobId }: { jobId: string }) => {
  const dispatch = useAppDispatch();

  const powerUps = useAppSelector((state) => selectTenantPowerUps(state));

  const { consumablesVersion } = useAppSelector((state) => selectJobById(state, jobId));
  const { data: timeRecords, isLoading: isLoadingTimes } = useTrackedFetch({
    key: `timesheets-${jobId}`,
    selector: (state) => selectTimeRecordsForJob(state, jobId),
    trigger: () => TimesheetActions.fetchForJob({ jobId }),
  });

  const { isLoading: isLoadingEquipment } = useTrackedFetch({
    key: 'equipment',
    selector: () => null,
    trigger: () => EquipmentActions.fetch(),
  });

  const { isLoading: isLoadingConsumables } = useTrackedFetch({
    key: `consumables-${jobId}`,
    trigger: () => ConsumablesActions.fetchForJob({ jobId }),
    selector: (state) => selectConsumablesForJob(state, jobId),
  });

  const { isLoading: isLoadingQuotes } = useTrackedFetch({
    key: `job-quotes-${jobId}`,
    trigger: () => QuoteActions.fetchForJob({ jobId }),
    selector: () => null,
  });

  const { isLoading: isLoadingLogs } = useTrackedFetch({
    key: `job-logs-${jobId}`,
    trigger: () => LogsActions.fetchForJob({ jobId }),
    selector: () => null,
  });

  const { isLoading: isLoadingJobEvents } = useTrackedFetch({
    key: `schedule-for-job-${jobId}`,
    trigger: () => ScheduleEventActions.fetchForJob({ jobId }),
    selector: () => null,
  });

  const { isLoading: isLoadingBoreholes } = useTrackedFetch({
    key: `borehole-${jobId}`,
    trigger: () => LiveGeoActions.fetchForJob({ jobId }),
    selector: () => null,
    enabled: powerUps?.geotechnical,
  });

  useEffect(() => {
    dispatch(PriceActions.fetch());
  }, []);

  const totalMinutes = useMemo(() => (
    timeRecords
      .map(getTimeRecordTotalMinutes)
      .reduce((total, minutes) => total + minutes, 0)
  ), [timeRecords]);

  // Chakra uses indexes to track the open/closed state of the accordion.
  // This array represents the order the accordions appears so that we
  // can persist their state logically rather than by storing indexes.
  const ACCORDION_ITEMS = [
    'time',
    'equipment',
    'consumables',
    'geotechnical',
  ];

  const expandedTabs = useAppSelector((s) => s.prefs.jobLogsExpandedSections ?? []);

  const ConsumablesTableComponent = consumablesVersion === 2
    ? JobConsumablesTable
    : JobLegacyConsumablesTable;

  return (
    <>
      <Accordion
        allowMultiple
        allowToggle
        defaultIndex={expandedTabs.map((tab) => ACCORDION_ITEMS.indexOf(tab))}
        onChange={(expandedIndexes: number[]) => {
          dispatch(PrefsActions.set({
            prefs: {
              jobLogsExpandedSections: expandedIndexes.map((i) => ACCORDION_ITEMS[i]),
            },
          }));
        }}
      >
        <MagnetizeAccordionItem
          heading="Time logs"
          icon={UserHelmet}
          rightContent={(
            isLoadingTimes
              ? <SkeletonText noOfLines={1} />
              : (
                <Text fontWeight="semibold">
                  {`${round(totalMinutes / 60, 2)} hrs`}
                </Text>
              )
          )}
        >
          {isLoadingTimes
            ? <SkeletonText noOfLines={2} />
            : <JobTimesheetsTable jobId={jobId} />}
        </MagnetizeAccordionItem>

        <MagnetizeAccordionItem heading="Equipment Logs" icon={SnowPlow}>
          {isLoadingLogs || isLoadingJobEvents
            ? <SkeletonText noOfLines={2} />
            : <JobEquipmentLogsTable jobId={jobId} />}
        </MagnetizeAccordionItem>

        <MagnetizeAccordionItem heading="Consumables" icon={DnsOutlined}>

          {isLoadingConsumables || isLoadingQuotes
            ? <SkeletonText noOfLines={2} />
            : <ConsumablesTableComponent jobId={jobId} />}
        </MagnetizeAccordionItem>

        {powerUps?.geotechnical && (
        <MagnetizeAccordionItem heading="Live Geo" icon={Drill}>
          {isLoadingEquipment || isLoadingBoreholes
            ? <SkeletonText noOfLines={2} />
            : <JobLiveGeoLogsTable jobId={jobId} />}
        </MagnetizeAccordionItem>
        )}
      </Accordion>
    </>
  );
};

export default JobLogsTab;
