import {
  Box,
} from '@chakra-ui/react';
import React from 'react';

const CappedWidthLayout = (
  { children, maxWidth = '1400px' }:
  { children: React.ReactNode, maxWidth?: string },
) => (
  <Box px="12" maxWidth={maxWidth} flex={1}>
    {children}
  </Box>
);

export default CappedWidthLayout;
