import useTrackedFetch from '~/hooks/useTrackedFetch';
import { QuoteActions } from '~/redux/quote/actions';
import { selectQuotesByJobId } from '~/redux/quote/selectors';
import { selectJobById, selectJobWithEdits } from '~/redux/jobs/selectors';
import { triggerJobFetch } from '~/redux/jobs/actions';
import { Job } from '~/types/job';
import { useAppSelector } from '~/redux/store';
import { getQuoted } from '~/helpers/job';

export const useQuoted = (
  { jobId, taxInclusive = false } : { jobId : string, taxInclusive?: boolean },
) => {
  const job = useAppSelector((state) => selectJobById(state, jobId));
  return getQuoted({ job, taxInclusive });
};

export const useQuotedLineItems = (
  { jobId } : { jobId : string, taxInclusive?: boolean },
) => {
  const { data: job, isLoading: isLoadingJob } = useTrackedFetch<Job>({
    key: `job:${jobId}`,
    selector: (state) => selectJobWithEdits(state, jobId),
    trigger: () => triggerJobFetch({ jobId }),
    equalityMode: 'deep',
  });

  const { data: quotes, isLoading: isLoadingQuotes } = useTrackedFetch({
    key: `job-quotes-${jobId}`,
    trigger: () => QuoteActions.fetchForJob({ jobId }),
    selector: (state) => selectQuotesByJobId(state, jobId),
  });

  if (isLoadingJob || isLoadingQuotes) return { isLoading: true };

  const isBudget = !job?.needsQuote;

  const acceptedQuotes = quotes?.filter((q) => q.status === 'accepted');

  return {
    lineItems: isBudget
      ? quotes.flatMap((q) => Object.values(q.lineItems))
      : acceptedQuotes.flatMap((q) => Object.values(q.lineItems)),
    taxRate: quotes[0]?.taxRate,
    taxRateName: quotes[0]?.taxRateName,
    isLoading: false,
  };
};
