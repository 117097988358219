import { Placement } from '@popperjs/core';

export interface TourStep {
  title: string;
  description: string;
  targetElement: string;
  placement: Placement;
  offset: [number, number];
}

export const calendarTourSteps : TourStep[] = [
  {
    title: 'Click to schedule a job',
    description: 'Have a go with the demo data! To schedule a job, click on a day. ',
    targetElement: 'calendar-add-event',
    placement: 'bottom',
    offset: [0, 10],
  },
  {
    title: 'Search for a job',
    description: 'All your jobs in Magnetize will show here. Simply add the job you want to schedule.',
    targetElement: 'calendar-add-job',
    placement: 'left',
    offset: [8, 30],
  },
  {
    title: 'Add people and equipment',
    description: 'Next, add people and equipment who you want to schedule on the job. ',
    targetElement: 'calendar-add-people-and-equipment',
    placement: 'left',
    offset: [8, 50],
  },
  {
    title: 'Save!',
    description: 'Save the scheduled event. You can extend, reschedule or delete the job later.',
    targetElement: 'calendar-save',
    placement: 'left',
    offset: [8, 150],
  },
  {
    title: 'Click & drag to extend across days',
    description: 'To extend the job across multiple days, click and drag from the right hand edge.',
    targetElement: 'calendar-event',
    placement: 'left',
    offset: [0, 10],
  },
  {
    title: 'Move the job to reschedule',
    description: 'If you need to reschedule a job, no worries! Just click and drag the job onto a new column.',
    targetElement: 'calendar-event',
    offset: [0, 10],
    placement: 'bottom',
  },
  {
    title: 'Filter jobs by status',
    description: 'Use the circles to filter jobs displayed by their status.',
    targetElement: 'calendar-filter',
    offset: [0, 10],
    placement: 'bottom',
  },
  {
    title: 'See equipment and staff',
    description: 'Use the Resources View to see what jobs your team are scheduled for.',
    targetElement: 'calendar-tabs',
    offset: [20, 10],
    placement: 'bottom-start',
  },
];
