import { createSelector } from '@reduxjs/toolkit';
import { filter, sortBy } from 'lodash';
import { RootState } from '~/redux/store';

export const selectAllPriceBundles = createSelector(
  (state: RootState) => state.priceBundles.entities,
  (priceBundles) => sortBy(Object.values(priceBundles), 'name'),
);

export const selectPriceBundles = createSelector(
  selectAllPriceBundles,
  (priceBundles) => priceBundles.filter((p) => !p.isDeleted),
);

export const selectAvailablePriceBundles = createSelector(
  selectPriceBundles,
  (priceBundles) => filter(priceBundles, (p) => p.isAvailable),
);

export const selectPriceBundleById = (
  state: RootState, id: string,
) => state.priceBundles.entities[id];
