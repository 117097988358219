export const MenuStyles = {
  baseStyle: {
    list: {
      mt: 1,
      py: 0,
      borderRadius: 'sm',
      // this conflicts with https://github.com/chakra-ui/chakra-ui/blob/8705372a014bfd7073fe8012a46d7aa22904370b/packages/components/css-reset/src/css-reset.tsx#L299
      // This is chakra box shadow large, forced on because of the above
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1),0 50px 40px 50px rgba(68, 68, 68, 0.05) !important',
      minW: '3xs',
      borderWidth: 0,
    },
    divider: {
      my: 0,
    },

    item: {
      _hover: {
        backgroundColor: 'magnetize.ui-2',
      },
      px: 5,
      py: 4,
      fontWeight: '600',
    },
  },
};
