import {
  Box,
  Flex, Popover, PopoverArrow, PopoverBody,
  PopoverCloseButton, PopoverContent, PopoverTrigger, Portal, Stack, Text,
} from '@chakra-ui/react';
import format from 'date-fns/format';
import React, { useEffect } from 'react';
import LinkButton from '~/components/LinkButton';
import { formatJobCode, formatJobName } from '~/helpers/job';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import { CustomerActions } from '~/redux/customers/actions';
import { selectCustomerById } from '~/redux/customers/selectors';
import { selectDoesNeedRefetch, selectFetchState } from '~/redux/fetch/selectors';
import JobCalendarActions from '~/redux/jobCalendar/jobCalendar';
import { triggerJobFetch } from '~/redux/jobs/actions';
import { selectJobById } from '~/redux/jobs/selectors';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import { JobStatusInfo } from '~/types/job';
import { JobEvent } from '~/types/ScheduleEvent';
import formatShortAddress from '~/utils/formatShortAddress';

const JobInfo = ({ jobId }: { jobId: string }) => {
  const dispatch = useAppDispatch();
  const job = useAppSelector((state) => selectJobById(state, jobId));
  const { isLoading } = useAppSelector(
    (state) => selectFetchState(state, jobId),
  );
  const needsRefetch = useAppSelector((state) => (
    selectDoesNeedRefetch(state, jobId, { staleTime: 10000 })
  ));

  const {
    data: customer,
  } = useTrackedFetch({
    trigger: () => CustomerActions.fetch({ customerId: job?.customerId }),
    selector: (s) => selectCustomerById(s, job?.customerId),
    key: `customer-${job?.customerId}`,
  });

  useEffect(() => {
    if (jobId && needsRefetch) {
      dispatch(triggerJobFetch({ jobId }));
    }
  }, [jobId, needsRefetch]);

  if (isLoading || !job) {
    return <></>;
  }
  const statusColor = JobStatusInfo[job.status]?.color ?? 'transparent';

  const address = formatShortAddress(job.siteAddress ?? customer?.companyAddress);

  return (
    <Flex
      fontSize="12px"
      borderLeft="4px"
      borderLeftColor={statusColor}
      borderBottomColor="magnetize.ui-3"
      borderBottomWidth={1}
      py="2"
    >
      <Box
        minWidth="4rem"
        maxWidth="4rem"
        px={2}
        textAlign="center"
      >
        {formatJobCode(job.code)}
      </Box>
      <Box px={1}>
        <Text>{formatJobName(job)}</Text>
        <Text
          opacity="0.7"
          fontSize="10px"
          height="30px"
        >
          {address}
        </Text>
      </Box>
    </Flex>
  );
};

const MonthCalendarJobPopover = (
  { date, jobs }: { date: number | Date, jobs: JobEvent[] },
) => {
  const dispatch = useAppDispatch();

  return (
    <Popover
      closeOnBlur
      closeOnEsc
      isLazy
    >
      <PopoverTrigger>
        <Flex justifyContent="center">
          <LinkButton isGreen noUnderline>
            {jobs.length}
            {' '}
            job
            {jobs.length > 1 ? 's' : ''}
          </LinkButton>
        </Flex>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton top={1} right={1} />
          <PopoverBody>
            <Stack>
              <Text textAlign="center" fontWeight="600">
                {format(date, 'EEE, dd MMM yyyy')}
              </Text>
              <LinkButton
                isGreen
                noUnderline
                onClick={() => {
                  dispatch(JobCalendarActions.move({ focusedDate: format(date, 'yyyy-MM-dd') }));
                  dispatch(JobCalendarActions.setRangeMode({ rangeMode: 'week' }));
                }}
              >
                View Day
              </LinkButton>
              <Flex
                display="column"
                justifyContent="center"
                alignItems="center"
                overflowY="scroll"
                maxH="200px"
                borderColor="magnetize.ui-3"
                borderWidth={1}
              >
                {jobs.map((j) => <JobInfo jobId={j.jobId} key={j.id} />)}
              </Flex>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default MonthCalendarJobPopover;
