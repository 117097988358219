import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Flex, IconButton } from '@chakra-ui/react';
import React from 'react';

interface CarouselButtonsProps {
  onForward?: () => void;
  onBack?: () => void;
  children?: JSX.Element;
}

const CarouselButtons = ({ onForward, onBack, children }: CarouselButtonsProps) => (
  <Flex direction="row" alignItems="center">
    <IconButton
      variant="ghost"
      icon={<ChevronLeftIcon fontSize="2rem" />}
      onClick={onBack}
      aria-label="Go to previous image"
    />

    <Box flex="1 1 auto">{children}</Box>

    <IconButton
      variant="ghost"
      icon={<ChevronRightIcon fontSize="2rem" />}
      onClick={onForward}
      aria-label="Go to next image"
    />
  </Flex>
);

export default CarouselButtons;
