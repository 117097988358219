export const downloadBlob = (blob: Blob, filename: string) => {
  const url = URL.createObjectURL(blob);
  const element = document.createElement('a');
  element.href = url;
  element.style.display = 'none';
  element.download = filename;
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const downloadAsJson = (data: any, filename) => {
  downloadBlob(
    new Blob(
      [JSON.stringify(data, undefined, 2)],
      { type: 'application/json' },
    ), filename,
  );
};
