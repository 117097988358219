import React from 'react';
import CentsEditor from '~/components/CentsEditor';
import CenteredFlex from '~/components/LineItemsEditor/components/CenteredFlex';

interface LineItemUnitPriceProps {
  hide?: boolean;
  value: number;
  onChange: (value: number) => void;
}

const LineItemUnitPrice = ({ hide, value, onChange } : LineItemUnitPriceProps) => (
  <CenteredFlex flex="0 0 105px">
    {!hide && (
    <CentsEditor
      numberInputFieldProps={{
        padding: 2,
      }}
      value={value}
      onChange={(newValue) => {
        if (newValue !== value) {
          onChange(newValue);
        }
      }}
    />
    )}
  </CenteredFlex>
);

export default LineItemUnitPrice;
