import {
  AccordionButton,
  AccordionIcon, AccordionItem, AccordionItemProps,
  AccordionPanel, Box, Flex, Heading, Icon, Image, Spacer,
} from '@chakra-ui/react';
import React from 'react';
import { IconType } from 'react-icons';

interface MagnetizeAccordionProps extends AccordionItemProps {
  icon?: IconType,
  imageSrc?: string;
  heading: string | React.ReactNode;
  rightContent?: React.ReactNode;
  children: React.ReactNode;
  removePadding?: boolean;
}

const MagnetizeAccordionItem = ({
  icon, imageSrc, heading, children, rightContent, removePadding = false, css = {}, ...props
}: MagnetizeAccordionProps) => (
  <AccordionItem css={css} {...props}>
    <AccordionButton>
      <Flex flex="1" alignItems="center">
        {!!icon && <Icon color="black" fontSize={20} mt={-1} as={icon} mr={6} />}
        {!!imageSrc && <Image src={imageSrc} height="33px" mr={6} />}
        <Box textAlign="left">
          <Heading as="h3" size="md">
            {heading}
          </Heading>
        </Box>
        <Spacer />
        {rightContent && (
        <Box textAlign="right" mr="4" fontWeight="regular">
          {rightContent}
        </Box>
        )}
      </Flex>
      <AccordionIcon />
    </AccordionButton>
    <AccordionPanel py={removePadding ? 0 : undefined}>
      {children}
    </AccordionPanel>
  </AccordionItem>
);

export default MagnetizeAccordionItem;
