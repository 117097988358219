import {
  call, takeEvery,
} from 'redux-saga/effects';
import { putTimelineNote } from '~/api/jobTimelineApi';
import { jobTimelineQuery } from '~/queries/jobTimeline';
import queryClient from '~/queryClient';
import { addJobNote } from '~/redux/jobs/actions';

const watchAddNoteToJob = takeEvery(
  addJobNote,
  function* handle({ payload: { jobId, note } }) {
    yield call(() => putTimelineNote(jobId, note));
    yield call(() => queryClient.invalidateQueries(jobTimelineQuery(jobId)));
  },
);

export default watchAddNoteToJob;
