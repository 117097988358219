import { Recipient, SentInfo } from '~/types/quote';
import { JobTag } from '~/types/tag';
import UploadedFile from '~/types/uploaded-file';
import { Address } from './address';

export const JobStatusInfo = {
  draft: {
    color: 'rgba(23,25,19,0.2)',
    textColor: '#000000',
    text: 'Draft',
  },
  quote: {
    color: '#ffe8cc',
    textColor: '#000000',
    text: 'Quote required',
  },
  quoted: {
    color: '#ffb966',
    textColor: '#000000',
    text: 'Quote sent',
  },
  schedule: {
    color: '#c3e2ca',
    textColor: '#000000',
    text: 'Ready to schedule',
  },
  scheduled: {
    color: 'brand.300',
    textColor: '#FFFFFF',
    text: 'Scheduled',
  },
  inProgress: {
    color: 'brand.500',
    textColor: '#FFFFFF',
    text: 'In progress',
  },
  complete: {
    color: '#171a12',
    textColor: '#FFFFFF',
    text: 'Complete',
  },
  closed: {
    color: '#d1d1d0',
    textColor: '#000000',
    text: 'Closed',
  },
  onHold: {
    color: '#f49c9c',
    textColor: '#000000',
    text: 'On hold',
  },
  archived: {
    color: 'rgba(23,25,19,0.2)',
    textColor: '#000000',
    text: 'Archived',
  },
};

export type JobStatusId = keyof typeof JobStatusInfo;

export interface JobLog {
  date: string;
  priceId: string;
  quantity: number;
}
export interface QuoteTotals {
  subTotal: number;
  tax: number;
}

export interface Job {
  /** The API id for this job */
  id: string;

  /** The 'user facing' ID for this job */
  code: number;

  customerId?: string;

  siteAddress?: Address;
  siteContactId?: string;
  jobContactId?: string;

  name?: string;
  serviceType?: string;
  description?: string;
  referenceNumber?: string;
  startDate?: string;
  endDate?: string;

  status: JobStatusId;
  preArchiveStatus?: JobStatusId;

  needsQuote: boolean;

  files: UploadedFile[];

  modifiedTimestamp?: number;

  isDeleted?: boolean;
  isDemo?: boolean;

  liveGeoSent?: SentInfo;

  liveGeoRecipients?: {
    [email: string]: Recipient
  };
  liveGeoAutoSend?:{
    enabled: boolean;
    setByStaffId: string;
  };
  consumablesVersion?: number;
  tags: string[];
  quoteNumbers?: string[];

  // System generated fields
  logs?: JobLog[];

  draftQuotesValue?: QuoteTotals;
  sentQuotesValue?: QuoteTotals;
  acceptedQuotesValue?: QuoteTotals;
  expiredQuotesValue?: QuoteTotals;

  hasDraftInvoices?: boolean;
  draftInvoicesValue?: QuoteTotals;
  sentInvoicesValue?: QuoteTotals;

  invoicedProportion?: number;
  invoicedTo?: string;
  isNewJob?: number;
}

export type JobTimelineItem = JobTimelineNote | JobTimelineStatusChange;

export interface JobLoggedStats {
  total: number,
  count: number,
  earliestEntry: Date
}
export interface JobInvoiceStats {
  invoiced: number,
  hasDraftInvoices: boolean,
  invoicedTo: Date,
  invoicedProportion: number
}

export interface JobQuoteStats { quoted: number }
export interface ExtendedJob extends Job {
  customerName: string;
  address: string;
  jobNumber: string;
  jobTags: JobTag[];
}

export interface JobTimelineItemCommon {
  id: string;
  at: number;
  user: string;
  staffId?: string;
}

interface JobTimelineWithStatusCommon extends JobTimelineItemCommon {
  status?: JobStatusId; // used if the schedule triggers a status change
  statusReason?: string;
}

export interface JobTimelineNote extends JobTimelineItemCommon {
  type: 'note',
  message: string;

  files?: UploadedFile[];
}

export interface JobTimelineJobTagsChanged extends JobTimelineWithStatusCommon {
  type: 'job-tags-changed';
  previous: string[];
  next: string[];
}
export interface JobTimelineJobDuplicated extends JobTimelineItemCommon {
  type: 'job-duplicated';
  jobId: string;
  originalJobId: string;
  originalJobCode: number;
  originalJobName: string;
}

export interface JobTimelineStatusChange extends JobTimelineWithStatusCommon {
  type: 'job-status' | 'job-status-manual' | 'job-status-automated',
  status: JobStatusId;
  title?: string;
  message?: string;
}

export interface JobTimelineScheduleAdded extends JobTimelineWithStatusCommon {
  type: 'job-schedule-added',
  notes?: string;
  from: number;
  to: number;
  staffIds: string[],
  equipmentIds: string[],
}

export interface JobTimelineScheduleChanged extends JobTimelineWithStatusCommon {
  type: 'job-schedule-changed',
  notes?: string;
  previousFrom: number;
  previousTo: number;
  from: number;
  to: number;
  previousStaffIds: string[];
  previousEquipmentIds: string[];
  staffIds: string[];
  equipmentIds: string[];
}

export interface JobTimelineScheduleRemoved extends JobTimelineWithStatusCommon {
  type: 'job-schedule-removed',
}

export interface JobTimelineJobCreated extends JobTimelineWithStatusCommon {
  type: 'job-created',
  code: number;
  name: string;
  description?: string;
}

export interface JobTimelineQuoteQuery extends JobTimelineItemCommon {
  type: 'quote-query',
  code: number;
  quoteId: number;
  email: string;
  message: string;
}

export interface JobTimelineQuoteAccepted extends JobTimelineWithStatusCommon {
  type: 'quote-accepted',
  code: number;
  quoteId: number;
  email: string;
}
export interface JobTimelineQuoteAcceptedOnBehalf extends JobTimelineWithStatusCommon {
  type: 'quote-accepted-on-behalf',
  code: number;
  quoteId: number;
}
export interface JobTimelineQuoteAcceptedReverted extends JobTimelineWithStatusCommon {
  type: 'quote-accepted-reveted',
  code: number;
  quoteId: number;
}
export interface JobTimelineQuoteExpired extends JobTimelineWithStatusCommon {
  type: 'quote-query',
  code: number;
  quoteId: number;
}
