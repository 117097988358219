import {
  Box, Button, FormControl, Input, Modal, ModalBody,
  ModalCloseButton, ModalContent, ModalFooter,
  ModalHeader, ModalOverlay, Text, VStack, Textarea,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';

interface SendConfirmationModalProps {
  onClose: () => void;
  onConfirm: ({ name, email, message }:{ name: string, email: string, message: string }) => void;
  contact: {
    name: string;
    email?: string;
  };
  title: string;
  subtitle: string;
  defaultMessage?: string;
  enableMessage?: boolean;
}

const SendConfirmationModal = ({
  onClose, onConfirm, contact, title, subtitle, defaultMessage = '', enableMessage = false,
}: SendConfirmationModalProps) => {
  const [name, setName] = useState(contact?.name ?? '');
  const [nameTouched, setNameTouched] = useState(!!name);
  const nameIsValid = yup.string().required().isValidSync(name);

  const [email, setEmail] = useState(contact?.email ?? '');
  const [emailTouched, setEmailTouched] = useState(!!email);
  const [messageTouched, setMessageTouched] = useState(false);
  const emailIsValid = yup.string().email().required().isValidSync(email);
  const defaultMessageWithTo = `Hi ${(name || '').split(' ')[0]}\n\n${defaultMessage || ''}`;

  const [message, setMessage] = useState(defaultMessageWithTo);

  useEffect(() => {
    if (!messageTouched) {
      setMessage(defaultMessageWithTo);
    }
  }, [name, defaultMessage]);

  return (
    <Modal isOpen onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {title}
        </ModalHeader>
        <ModalCloseButton tabIndex={-1} />
        <ModalBody>
          <VStack mt={-8} spacing={8} align="stretch">
            <Text textAlign="center">
              {subtitle}
            </Text>
            <VStack spacing={2} align="stretch">
              <Text fontWeight="semibold">SEND TO:</Text>
              <FormControl isInvalid={nameTouched && !nameIsValid}>
                <Input
                  value={name}
                  onFocus={() => setNameTouched(true)}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  placeholder="Full name"
                />
              </FormControl>

              <FormControl isInvalid={emailTouched && !emailIsValid}>
                <Input
                  value={email}
                  onFocus={() => setEmailTouched(true)}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  placeholder="Email address"
                />
              </FormControl>
              {enableMessage
              && (
                <>
                  <Text fontWeight="semibold" pt={3}>MESSAGE:</Text>
                  <FormControl>
                    <Textarea
                      rows={6}
                      value={message}
                      onChange={(e) => {
                        setMessageTouched(true);
                        setMessage(e.target.value);
                      }}
                    />
                  </FormControl>

                </>
              )}
            </VStack>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Box>
            <Button
              onClick={() => onConfirm({ name, email, message })}
              isDisabled={!nameIsValid || !emailIsValid}
            >
              Send
            </Button>
            <Button variant="ghost" onClick={onClose}>cancel</Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default SendConfirmationModal;
