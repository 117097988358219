import {
  FormControl, FormLabel, Grid, Input,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { CasingType } from '~/types/casingType';

export const CasingTypeForm = (
  {
    value = {},
    onChange = () => {},
  }
  : {
    value?: Partial<CasingType>;
    onChange?: (technique: Partial<CasingType>) => void
  },
) => {
  const [name, setName] = useState(value.name ?? '');

  const nameRef = useRef(null);

  useEffect(() => {
    onChange({
      ...(value || {}),
      name,
    });
  }, [
    name,
  ]);

  return (
    <Grid gap={6}>
      <FormControl isRequired>
        <FormLabel>Name</FormLabel>
        <Input
          autoFocus
          size="lg"
          ref={nameRef}
          placeholder="Enter the name for the casing type"
          value={name}
          onChange={(e) => { setName(e.target.value); }}
        />
      </FormControl>
    </Grid>
  );
};
