import { createReducer } from '@reduxjs/toolkit';
import { beginQueuedWork, queuedWorkComplete } from '~/redux/queuedTasks/actions';

const syncStateReducer = createReducer(
  {
    isSyncing: false,
  },

  (builder) => (builder
    .addCase(beginQueuedWork, (state) => ({
      ...state,
      isSyncing: true,
    }))

    .addCase(queuedWorkComplete, (state) => ({
      ...state,
      isSyncing: false,
    }))
  ),
);

export default syncStateReducer;
