import { RootState } from '~/redux/store';

export const selectCustomerTenantByToken = (
  state: RootState,
  token: string,
) => state.customerTenant[token]?.data;

export const selectCustomerTenantFetchState = (state: RootState, token: string) => {
  const tenantState = state.customerTenant[token];
  const status = tenantState?.status ?? 'idle';
  return {
    status,
    isInvalid: tenantState ? !!tenantState?.isInvalid : true,
    isLoading: !tenantState?.data && status === 'loading',
    isError: status === 'error',
  };
};
