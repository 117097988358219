import {
  Box,
  Icon,
  IconButton,
  Popover, PopoverArrow, PopoverBody, PopoverContent,
  PopoverHeader, PopoverTrigger, Portal, Tooltip, VStack,
} from '@chakra-ui/react';
import React from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';
import AddSchedulingNoteWithEditIcon from '~/components/AddCalendarEventPopover/AddSchedulingNote/withEditIcon';
import CalendarCard from '~/components/Calendar/CalendarCard';
import CalendarDateIntervalEditor from '~/components/JobCalendar/CalendarDateIntervalEditor';
import RemoveButtonWithConfirmation from '~/components/RemoveButtonWithConfirmation';
import ResourceAvatar from '~/components/ResourceAvatar';
import { useResourceSummary } from '~/queries/useResourcesQuery';
import { ScheduleEventActions } from '~/redux/schedule/actions';
import { useAppDispatch } from '~/redux/store';
import {
  EquipmentStatusEvent, StaffLeaveEvent,
} from '~/types/ScheduleEvent';
import eventStopper from '~/utils/eventStopper';

interface AdminEventCalendarCardProps {
  event: StaffLeaveEvent | EquipmentStatusEvent;
  hideResource?: boolean;
  isSelected?: boolean;
  onSpanChange: (span: { start: Date, end: Date }) => void;
}

const AdminEventCalendarCard = (
  {
    event, hideResource, isSelected, onSpanChange,
  }: AdminEventCalendarCardProps,
) => {
  const dispatch = useAppDispatch();
  const { data: resource, isLoading } = useResourceSummary({
    id: event.resourceId,
    type: event.type === 'staff-leave' ? 'staff' : 'equipment',
  });

  const genericLabel = event.type === 'staff-leave' ? 'Leave' : 'Equipment Status';

  const updateNotes = (notes: string | undefined) => {
    dispatch(ScheduleEventActions.updateEvent({
      ...event,
      notes: notes ?? null,
    }));
  };

  const deleteEvent = () => {
    dispatch(ScheduleEventActions.deleteEvent({
      eventId: event.id,
    }));
  };

  return (
    <Popover
      isLazy
      isOpen={isSelected}
      placement="bottom-start"
      closeOnBlur={false}
      openDelay={500}
    >
      <PopoverTrigger>
        <Box>
          <CalendarCard
            isLoading={isLoading}
            mainText={resource?.displayName ?? ' '}
            secondaryText={event.label ?? genericLabel}
            leftContent={!hideResource && <ResourceAvatar mr="2" size="sm" resource={resource} />}
          />
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent {...eventStopper()} tabIndex={-1} width="30rem">
          <PopoverArrow />
          <PopoverHeader tabIndex={-1} position="relative">
            {event.label ?? genericLabel}
          </PopoverHeader>
          <Box position="absolute" right="3" top="3">
            <Tooltip
              label="Delete this scheduled event?"
              placement="top"
              hasArrow
            >
              <RemoveButtonWithConfirmation
                confirmationTitle="Remove schedule"
                confirmationMessage="Are you sure you want to remove this schedule?"
                onRemoveConfirmed={deleteEvent}
              >
                <IconButton
                  size="sm"
                  tabIndex={-1}
                  variant="ghost"
                  icon={<Icon boxSize="14px" as={FaRegTrashAlt} />}
                  aria-label="Delete calendar event"
                />
              </RemoveButtonWithConfirmation>
            </Tooltip>
          </Box>
          <PopoverBody tabIndex={-1}>
            <VStack
              spacing={8}
              align="stretch"
            >
              <CalendarDateIntervalEditor
                value={event}
                onChange={onSpanChange}
              />

              <AddSchedulingNoteWithEditIcon value={event.notes} onChange={updateNotes} />
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default AdminEventCalendarCard;
