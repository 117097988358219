import { useControllableState } from '@chakra-ui/react';
import { keyBy } from 'lodash';
import React from 'react';
import SearchSelect from '~/components/SearchSelect';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import { triggerTagsFetch } from '~/redux/tags/actions';
import { selectTagsByType } from '~/redux/tags/selectors';

interface TagFilterSelectProps {
  selected?: string[];
  onSelectionChange?: (selected: string[]) => void;
}

const TagFilterSelect = ({
  selected: selectedProp, onSelectionChange,
}: TagFilterSelectProps) => {
  const [selected, setSelected] = useControllableState<string[]>({
    defaultValue: [],
    value: selectedProp,
    onChange: onSelectionChange,
  });

  const { isLoading, data: staffTags } = useTrackedFetch({
    key: 'tag',
    trigger: () => triggerTagsFetch({ force: false }),
    selector: (state) => selectTagsByType(state, 'staff'),
  });

  const options = staffTags?.map((t) => ({ label: t.name, value: t.id })) ?? [];
  const optionsById = keyBy(options, (o) => o.value);

  return (
    <SearchSelect
      isMulti
      hideSearchIcon
      value={selected.map((s) => optionsById[s])}
      options={options}
      placeholder="Filter by teams"
      size="sm"
      formatOptionLabel={(o) => o.label}
      onChange={(o) => {
        setSelected(o.map((i) => i.value));
      }}
      isLoading={isLoading}
    />
  );
};

export default TagFilterSelect;
