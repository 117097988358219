import { nanoid } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';
import {
  all, call, put, select, takeEvery,
} from 'redux-saga/effects';
import { CustomerActions } from '~/redux/customers/actions';
import { selectCustomerById } from '~/redux/customers/selectors';
import { showErrorToast, showToast } from '~/toast';
import { Customer } from '~/types/customer';
import { CustomerContact } from '~/types/customerContact';
import { getIsoDate } from '~/utils/calendarHelpers';
import { exportToSpreadsheet } from '~/utils/exportToSpreadsheet';
import fetchJson from '~/utils/fetchJson';
import formatAddress from '~/utils/formatAddress';
import { performFetchSaga } from '~/utils/performFetchSaga';
import { performTrackedSaga, takeEveryTracked } from '~/utils/performTrackedSaga';

const watchFetch = takeEvery(
  CustomerActions.fetch,
  function* handle(action) {
    const id = action?.payload?.customerId;
    if (id) {
      yield performFetchSaga({
        key: `customer-${id}`,
        staleTime: 5000,
        * saga() {
          const customer: Customer = yield call(() => fetchJson(`/api/customers/${id}`));
          yield put(CustomerActions.updated({ customers: [customer] }));
        },
      });
    }
  },
);

const watchFetchAll = takeEvery(
  CustomerActions.fetchAll,
  function* handle() {
    yield performFetchSaga({
      key: 'customers',
      staleTime: 30000,
      * saga() {
        const customers: Customer[] = yield call(() => fetchJson('/api/customers'));
        yield put(CustomerActions.updated({ customers }));
      },
    });
  },
);

const watchSave = takeEveryTracked(
  CustomerActions.save,
  function* handle(action) {
    const { customer } = action.payload.data;
    try {
      const updated: Customer = yield call(() => fetchJson(`/api/customers/${customer.id ?? nanoid()}`, {
        method: 'PUT',
        body: customer,
      }));
      yield put(CustomerActions.updated({ customers: [updated] }));
      showToast({
        status: 'success',
        title: 'Customer saved',
      });
    } catch (error) {
      showErrorToast(error);
    }
  },
);

const watchArchive = takeEvery(
  CustomerActions.archive,
  function* handle(action) {
    const { customerId } = action.payload;
    const existing: Customer = yield select((s) => selectCustomerById(s, customerId));
    if (!existing) {
      return;
    }

    try {
      const deleted = {
        ...existing,
        isDeleted: true,
      };
      yield put(CustomerActions.updated({ customers: [deleted] }));
      yield call(() => fetchJson(`/api/customers/${customerId}`, {
        method: 'PUT',
        body: deleted,
      }));
    } catch (e) {
      yield put(CustomerActions.updated({ customers: [existing] }));
      showErrorToast(e, {
        title: 'Could not delete customer',
      });
    }
  },
);

const watchExportCustomers = takeEvery(
  CustomerActions.exportCustomers,
  function* handler(action) {
    const { format } = action.payload.data;
    yield performTrackedSaga({
      key: action.payload.id,
      * saga() {
        const [customers, contacts]:
        [Customer[], CustomerContact[]] = yield all([
          call(() => fetchJson<Customer[]>('/api/customers')),
          call(() => fetchJson<CustomerContact[]>('/api/contacts')),
        ]);

        const customerContactsById = keyBy(contacts, (c) => c.id);

        yield call(() => exportToSpreadsheet({
          name: `Customers-${getIsoDate(Date.now())}`,
          format,
          sheets: [{
            headers: [
              'Name',
              'Main Contact Name',
              'Main Contact Phone',
              'Main Contact Email',
              'Address',
            ],
            columnWidths: [
              30, 30,
              30, 30,
              100,
            ],
            data: customers
              .filter((c) => !c.isDeleted)
              .map((customer) => {
                const customerMainContact = customerContactsById[customer.mainContactId];

                return [
                  customer.companyName,
                  customerMainContact?.name,
                  customerMainContact?.phone,
                  customerMainContact?.email,
                  formatAddress(customer.companyAddress),
                ];
              }),
          }],
        }));
      },
    });
  },
);

export default function* customerSaga() {
  yield all([
    watchFetch,
    watchFetchAll,
    watchSave,
    watchArchive,
    watchExportCustomers,
  ]);
}
