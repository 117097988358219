import {
  Grid, SkeletonText,
} from '@chakra-ui/react';
import {
  eachDayOfInterval,
  endOfMonth, endOfWeek, format, isFirstDayOfMonth,
  isPast, isWithinInterval, startOfMonth, startOfWeek,
} from 'date-fns';
import { isSameMonth, isToday, isWeekend } from 'date-fns/esm';
import { flatMap, uniqBy } from 'lodash';
import React, { useMemo } from 'react';
import { JobEventViewModel } from '~/components/JobCalendar/JobEventViewModel';
import Day from '~/components/JobCalendar/MonthCalendar/day';
import Header from '~/components/JobCalendar/MonthCalendar/header';
import { useResourcesQuery } from '~/queries/useResourcesQuery';
import {
  EquipmentStatusEvent, JobEvent, StaffLeaveEvent,
} from '~/types/ScheduleEvent';
import { roundIntervalToDays } from '~/utils/calendarHelpers';
import { useAppSelector } from '~/redux/store';
import { selectCurrentUser } from '~/redux/currentUser/selectors';

interface MonthCalendarProps {
  jobEvents: JobEventViewModel[];
  adminEvents: (StaffLeaveEvent | EquipmentStatusEvent)[];
  focusedDay?: string;
  hideTotals?: boolean;
}

export interface CalendarDay {
  date: number | Date;
  title: string;
  isToday: boolean;
  isFocusedMonth: boolean;
  isPast: boolean;
  isWeekend: boolean;
  isHoliday?: boolean;
  jobs: JobEvent[];
  staffCount: number;
  totalStaffCount: number;
  equipmentCount: number;
  totalEquipmentCount: number;
  isWorkDay: boolean;
}

const isOnDay = (
  event: { start: number | Date, end: number | Date },
  date: number | Date,
) => isWithinInterval(
  date, roundIntervalToDays({
    start: event.start,
    end: event.end,
  }),
);

const getCalendarDays = (
  focused: number | Date,
  start: number | Date,
  end: number | Date,
  jobEvents: JobEventViewModel[],
  adminEvents: (StaffLeaveEvent | EquipmentStatusEvent)[],
  totalStaffCount: number,
  totalEquipmentCount: number,
  schedulingWorkDays: number[],
) : CalendarDay[] => eachDayOfInterval({ start, end }).map((date) => {
  const matchingJobEvents = jobEvents.filter((j) => isOnDay(j, date));
  const matchingResources = flatMap(matchingJobEvents, (j) => j.resources)
    .filter((j) => j.resourceEvents.some((re) => isWithinInterval(date, re)));
  const matchingAdminEvents = adminEvents.filter((e) => isOnDay(e, date)) ?? [];
  const staffCount = uniqBy(matchingResources.filter((r) => r.type === 'staff'), (r) => r.id).length;
  const equipmentCount = uniqBy(matchingResources.filter((r) => r.type === 'equipment'), (e) => e.id).length;
  const staffOnLeaveCount = uniqBy(matchingAdminEvents.filter((a) => a.type === 'staff-leave'), (l) => l.resourceId).length;
  const equipmentOnLeaveCount = uniqBy(matchingAdminEvents.filter((a) => a.type === 'equipment-status' && a.isAvailable === false), (e) => e.resourceId).length;

  return {
    date,
    title: isFirstDayOfMonth(date) ? format(date, 'd MMM') : format(date, 'd'),
    isToday: isToday(date),
    isWeekend: isWeekend(date),
    isFocusedMonth: isSameMonth(date, focused),
    isPast: isPast(date),
    jobs: flatMap(matchingJobEvents, (j) => j.jobEvent) ?? [],
    staffCount,
    totalStaffCount: totalStaffCount - staffOnLeaveCount,
    equipmentCount,
    totalEquipmentCount: totalEquipmentCount - equipmentOnLeaveCount,
    isWorkDay: schedulingWorkDays?.includes(date.getDay()),
  };
});

const MonthCalendar = (
  {
    jobEvents, adminEvents, focusedDay, hideTotals,
  } : MonthCalendarProps,
) => {
  const focused = focusedDay ? new Date(focusedDay) : new Date();
  const start = startOfWeek(startOfMonth(focused), { weekStartsOn: 1 });
  const end = endOfWeek(endOfMonth(focused), { weekStartsOn: 1 });

  const { data: resourcesList, isLoading } = useResourcesQuery();

  const totalStaff = useMemo(
    () => (resourcesList?.filter((s) => s.type === 'staff') ?? []).length,
    [resourcesList],
  );
  const totalEquipment = useMemo(
    () => (resourcesList?.filter((e) => e.type === 'equipment') ?? []).length,
    [resourcesList],
  );

  const tenant = useAppSelector(
    (state) => selectCurrentUser(state)?.tenant,
  );

  const days = useMemo(() => getCalendarDays(
    focused,
    start,
    end,
    jobEvents,
    adminEvents,
    totalStaff,
    totalEquipment,
    tenant?.schedulingWeekDays,
  ), [focused, start, end, jobEvents, totalStaff, totalEquipment]);

  if (isLoading) {
    return <SkeletonText noOfLines={2} />;
  }

  return (
    <>
      <Header />
      <Grid
        gridTemplateColumns="repeat(7, 1fr)"
        borderLeftWidth={1}
        borderTopWidth={1}
        borderColor="magnetize.ui-4"
      >
        {days.map((d) => (
          <Day
            key={d.date.toString()}
            hideTotals={hideTotals}
            day={d}
          />
        ))}
      </Grid>
    </>
  );
};

export default MonthCalendar;
