import {
  all, call, put, takeEvery,
} from 'redux-saga/effects';
import { CustomerLiveGeoActions, CustomerLiveGeoData } from '~/redux/customerLiveGeo/actions';
import fetchJson from '~/utils/fetchJson';
import { performFetchSaga } from '~/utils/performFetchSaga';

const watchFetchForJob = takeEvery(
  CustomerLiveGeoActions.fetch,
  function* handle(action) {
    const { token } = action.payload;
    yield performFetchSaga({
      key: `borehole-${token}`,
      staleTime: 30000,
      * saga() {
        const data : CustomerLiveGeoData = yield call(
          () => fetchJson(`/api-public/geotechnical?token=${token}`),
        );
        yield put(CustomerLiveGeoActions.updated({ token, data }));
      },
    });
  },
);

export default function* customerLiveGeoSaga() {
  yield all([
    watchFetchForJob,
  ]);
}
