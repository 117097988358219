import { orderBy, first, values } from 'lodash';
import { RootState } from '~/redux/reducer';

export const selectBoreholesByJobId = (state: RootState, jobId: string) => {
  const boreholeIds: string[] = state.liveGeo.boreholeIdsByJobId[jobId] ?? [];

  const boreholes = boreholeIds
    .map((id) => state.liveGeo.boreholesById[id])
    .filter((bh) => bh) ?? [];

  const ordered = orderBy(boreholes, (bh) => {
    const start = first(orderBy(values(bh.drillLogs), (dl) => dl.startTime));
    return start?.startTime;
  });

  return ordered;
};

export const selectCPTByJobId = (state: RootState, jobId: string) => {
  const cptIds: string[] = state.liveGeo.cptsByJobId[jobId] ?? [];

  const cpts = cptIds
    .map((id) => state.liveGeo.cptsById[id])
    .filter((cpt) => cpt) ?? [];

  return cpts;
};
