import {
  Box,
} from '@chakra-ui/react';
import { PersonOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import CardTitle from '~/components/CardTitle';
import CustomerContactEditor from '~/components/CustomerContactEditor';
import LinkButton from '~/components/LinkButton';
import { CustomerContact } from '~/types/customerContact';

interface EditableContactInfoProps {
  customerId: string;
  value: string;
  title: string;
  addButtonIcon?: JSX.Element;
  showTitleWhenUnset?: boolean;
  onChange: (contact: CustomerContact) => void;
}

const JobContactInfoSection = ({
  title,
  value,
  customerId,
  onChange,
  addButtonIcon = <PersonOutlined />,
  showTitleWhenUnset = true,
}: EditableContactInfoProps) => {
  const titleComponent = showTitleWhenUnset ? <CardTitle>{title}</CardTitle> : <></>;
  const [showEditLink, setShowEditLink] = useState(!value);

  useEffect(() => {
    if (!customerId) {
      setShowEditLink(true);
    }
  }, [customerId]);

  if (showEditLink) {
    return (
      <Box>
        {titleComponent}
        <LinkButton
          noUnderline
          leftIcon={addButtonIcon}
          onClick={() => setShowEditLink(false)}
        >
          {`Add ${title.toLocaleLowerCase()}`}
        </LinkButton>
      </Box>
    );
  }

  return (
    <Box>
      {titleComponent}
      <CustomerContactEditor
        value={value}
        onChange={(contact) => {
          onChange(contact);
        }}
        customerId={customerId}
      />
    </Box>
  );
};

export default JobContactInfoSection;
