import {
  Link, SkeletonText, useBreakpointValue, Text,
} from '@chakra-ui/react';
import React from 'react';
import { createColumnHelper, CellContext } from '@tanstack/react-table';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import JobTagsSummary from '~/components/JobTagsSummary';
import { formatJobName } from '~/helpers/job';
import Routes from '~/pages/routes';
import { ExtendedJob, JobStatusId } from '~/types/job';
import eventStopper from '~/utils/eventStopper';
import StatusBadge from '~/components/JobStatusBadge';
import { DemoBadge, NewBadge } from '~/components/Badge';
import { VirtualisedSortableTable } from '~/components/VirtualisedSortableTable';
import { JobKebabMenu } from '~/pages/Jobs/KebabMenu';

const JobList = ({
  jobs,
  isLoadingCustomers,
}: {
  jobs: ExtendedJob[];
  isLoadingCustomers: boolean;
}) => {
  const history = useHistory();

  const isXl = useBreakpointValue({ base: false, xl: true });
  const is2Xl = useBreakpointValue({ base: false, '2xl': true });

  const columnHelper = createColumnHelper<ExtendedJob>();
  const columns = React.useMemo(
    () => [
      columnHelper.accessor((x) => x.jobNumber, {
        header: 'Job #',
        meta: {
          headerProps: {
            width: '8.5rem',
          },
        },
        cell: ({ row: { original } }: CellContext<ExtendedJob, string>) => (
          <>
            <Text pl={2}>
              {original.jobNumber}
              {' '}
&nbsp;
              {original.isDemo && <DemoBadge />}
              {original.isNewJob ? <NewBadge /> : undefined}
            </Text>
          </>
        ),
      }),
      columnHelper.accessor((x) => x.customerName, {
        header: 'Customer',
        cell: ({ getValue }: CellContext<ExtendedJob, string>) => (isLoadingCustomers ? (
          <SkeletonText noOfLines={1} />
        ) : (
          getValue() || ''
        )),
      }),
      columnHelper.accessor((x) => x.name, {
        header: 'Job Name',
        cell: ({ row: { original } }: CellContext<ExtendedJob, string>) => (
          <Link
            as={RouterLink}
            to={Routes.job({
              jobId: original.id,
            })}
          >
            {formatJobName(original)}
          </Link>
        ),
      }),
      columnHelper.accessor((x) => x.status, {
        header: 'Status',
        meta: {
          headerProps: {
            width: isXl ? '15rem' : '5.5rem',
          },
        },
        cell: ({ getValue }: CellContext<ExtendedJob, JobStatusId>) => (
          <StatusBadge status={getValue()} isCollapsed={!isXl} />
        ),
      }),
      columnHelper.accessor((x) => x.address, {
        header: 'Address',
      }),
      ...(is2Xl
        ? [
          columnHelper.accessor(
            (x) => (x.startDate ? new Date(x.startDate) : null),
            {
              header: 'Start Date',
              meta: {
                headerProps: {
                  width: '9rem',
                },
              },
              cell: ({ getValue }) => (getValue() ? format(getValue(), 'd MMM yyyy') : ''),
            },
          ),
          columnHelper.accessor(
            (x) => (x.endDate ? new Date(x.endDate) : null),
            {
              header: 'End Date',
              meta: {
                headerProps: {
                  width: '9rem',
                },
              },
              cell: ({ getValue }) => (getValue() ? format(getValue(), 'd MMM yyyy') : ''),
            },
          ),
        ]
        : []),
      columnHelper.display({
        id: 'job-tags',
        meta: {
          headerProps: {
            width: '12rem',
          },
        },
        cell: ({ row: { original } }: CellContext<ExtendedJob, unknown>) => (
          <JobTagsSummary tagIds={original.jobTags.map((t) => t.id)} />
        ),
      }),
      columnHelper.display({
        id: 'kebab-menu',
        meta: {
          headerProps: {
            width: '4rem',
          },
          cellProps: {
            textAlign: 'end',
            ...eventStopper(),
          },
        },
        cell: ({ row: { original } }: CellContext<ExtendedJob, unknown>) => (
          <JobKebabMenu job={original} />
        ),
      }),
    ],
    [isXl, is2Xl, isLoadingCustomers],
  );

  return (
    <VirtualisedSortableTable
      data={jobs}
      columns={columns}
      defaultSort={[{ id: 'Job #', desc: true }]}
      getRowProps={(row) => ({
        _hover: { bg: '#fafafa', cursor: 'pointer' },
        transition: 'color 0.1s ease-out',
        bgColor: row.original.isNewJob ? 'magnetize.support-warning-tint' : undefined,
        role: 'group',
        onClick: () => history.push(Routes.job({ jobId: row.original.id })),
      })}
    />
  );
};

export default JobList;
