import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
  Table, Tbody, Td, Th, Thead, Tr, Text, Flex,
} from '@chakra-ui/react';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
  SortingState,
  CellContext,
  createColumnHelper,
} from '@tanstack/react-table';
import {
  groupBy as lodashGroupBy, round, sumBy,
} from 'lodash';
import React, { useMemo, useState } from 'react';
import ExpandableText from '~/components/ExpandableText';
import LinkButton from '~/components/LinkButton';
import { FormattedTimeRecord } from '~/helpers/timeRecord';
import { useFormatInTimezone } from '~/hooks/useFormatInTimezone';

interface TimeRecordsTableProps {
  timeRecords: FormattedTimeRecord[];
  groupBy: 'staff' | 'job';
}

const TimeRecordsSubTable = ({ timeRecords, groupBy } : TimeRecordsTableProps) => {
  const timezone = useMemo(() => Intl.DateTimeFormat().resolvedOptions().timeZone, []);
  const formatInTimeZone = useFormatInTimezone();

  return (
    <Table size="md" borderBottomWidth="0px">
      <Tbody>
        {timeRecords.map((timeRecord) => (
          <Tr
            key={timeRecord.id}
          >
            <Td width="220px">
              <Text pl="60px">
                {`${formatInTimeZone(timeRecord.start, timeRecord.timezone || timezone, 'EEE dd MMM yyyy')} `}
                {timeRecord.timezone && timeRecord.timezone !== timezone && (
                <Text color="magnetize.text-4" as="span">
                  {formatInTimeZone(timeRecord.start, timeRecord.timezone, 'zzz') }
                </Text>
                )}
              </Text>
            </Td>
            <Td>
              <Text
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                overflow="hidden"
              >
                {groupBy === 'staff' ? timeRecord?.jobName : timeRecord?.staffName}
              </Text>
            </Td>
            <Td>
              <ExpandableText>
                {timeRecord.note || ''}
              </ExpandableText>
            </Td>
            <Td
              width="80px"
              px={0}
            >
              {timeRecord.travelTimeHours ? `${timeRecord.travelTimeHours} hrs` : ''}
            </Td>
            <Td
              width="80px"
              px={0}
            >
              {formatInTimeZone(timeRecord.start, timeRecord.timezone || timezone, 'h:mmaaa') }
            </Td>
            <Td
              width="80px"
              px={0}
            >
              {formatInTimeZone(timeRecord.end, timeRecord.timezone || timezone, 'h:mmaaa') }
            </Td>
            <Td
              width="80px"
              px={0}
            >
              {timeRecord.downtimeHours ? `- ${timeRecord.downtimeHours} hrs` : ''}
            </Td>
            <Td
              width="80px"
              px={0}
            >
              {timeRecord.totalHours}
              {' '}
              hrs
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

const TimeRecordsTable = ({ timeRecords, groupBy } : TimeRecordsTableProps) => {
  const grouped = lodashGroupBy(timeRecords,
    (tr) => (groupBy === 'staff' ? tr.staffId : tr.jobId));
  const [expandedGroups, setExpandedGroups] = useState([]);
  const [sorting, setSorting] = React.useState<SortingState>([{ id: 'Logs', desc: true }]);

  const columnHelper = createColumnHelper<FormattedTimeRecord[]>();
  const columns = React.useMemo(
    () => [
      columnHelper.accessor((x) => x.length, {
        header: 'Logs',
        meta: {
          headerProps: {
            width: '60px',
            px: '0',
          },
          cellProps: {
            px: '0',
          },
        },
        cell: ({ row: { original } }: CellContext<FormattedTimeRecord[], unknown>) => (
          <LinkButton isGreen justifyContent="start">
            {original.length}
          </LinkButton>
        ),
      }),
      columnHelper.accessor((x) => (groupBy === 'staff' ? x[0].staffName : x[0].jobName), {
        header: 'Name',
        meta: {
          headerProps: {
            width: '120px',
            px: '0',
          },
          cellProps: {
            px: '0',
          },
        },
        cell: ({ row: { original } }: CellContext<FormattedTimeRecord[], unknown>) => (
          <Text
            fontWeight="semibold"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            {(groupBy === 'staff' ? original[0].staffName : original[0].jobName)}
          </Text>
        ),
      }),
      columnHelper.display({
        header: 'Work',
        meta: {
          headerProps: {
          },
        },
      }),
      columnHelper.display({
        header: 'Note',
        meta: {
          headerProps: {
          },
        },
      }),
      columnHelper.display({
        header: 'Travel',
        meta: {
          headerProps: {
            width: '80px',
            px: '0',
          },
          cellProps: {
            px: '0',
          },
        },
      }),
      columnHelper.display({
        header: 'Start',
        meta: {
          headerProps: {
            width: '80px',
            px: '0',
          },
          cellProps: {
            px: '0',
          },
        },
      }),
      columnHelper.display({
        header: 'Finish',
        meta: {
          headerProps: {
            width: '80px',
            px: '0',
          },
          cellProps: {
            px: '0',
          },
        },
      }),
      columnHelper.display({
        header: 'Down',
        meta: {
          headerProps: {
            width: '80px',
            px: '0',
          },
          cellProps: {
            px: '0',
          },
        },
      }),
      columnHelper.accessor((x) => `${round(sumBy(x, (g) => g.totalHours), 2)} hrs`, {
        header: 'Total',
        meta: {
          headerProps: {
            width: '80px',
            px: '0',
          },
          cellProps: {
            px: '0',
          },
        },
      }),
    ], [],
  );

  const getRowProps = (row) => ({
    _hover: { bg: '#fafafa', cursor: 'pointer' },
    transition: 'color 0.1s ease-out',
    role: 'group',
    onClick: () => {
      if (expandedGroups.includes(row.id)) {
        setExpandedGroups(expandedGroups.filter((i) => i !== row.id));
      } else {
        setExpandedGroups([...expandedGroups, row.id]);
      }
    },
  });

  const {
    getFlatHeaders,
    getRowModel,
  } = useReactTable(
    {
      columns,
      data: Object.values(grouped),
      state: {
        sorting,
      },
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
    },
  );
  return (
    <Table>
      <Thead>
        <Tr>
          {getFlatHeaders().map((header) => (
            <Th
              key={header.id}
              userSelect="none"
              cursor={header.column.getCanSort() ? 'pointer' : undefined}
              {...(header.column.columnDef?.meta?.headerProps || {})}
              _hover={header.column.getCanSort() ? {
                color: 'magnetize.text-2',
              } : {}}
            >
              <Flex
                alignItems="center"
                onClick={header.column.getToggleSortingHandler()}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                {{
                  asc: (
                    <>
                      {header.column.columnDef?.meta?.sortText?.asc && (
                      <Text pl={1}>
                        (
                        {header.column.columnDef?.meta?.sortText?.asc}
                        )
                      </Text>
                      )}
                      <ChevronUpIcon ml={1} w={4} h={4} />
                    </>),
                  desc: (
                    <>
                      {header.column.columnDef?.meta?.sortText?.desc
                      && (
                      <Text pl={1}>
                        (
                        {header.column.columnDef?.meta?.sortText?.desc}
                        )
                      </Text>
                      )}
                      <ChevronDownIcon ml={1} w={4} h={4} />
                    </>),
                }[header.column.getIsSorted() as string] ?? null}
              </Flex>
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {getRowModel().rows.map((row) => (
          <React.Fragment key={row.id}>
            <Tr
              key={row.id}
              {...(getRowProps ? getRowProps(row) : {})}
            >
              {row.getVisibleCells().map((cell) => (
                <Td
                  key={cell.id}
                  {...(cell.column.columnDef?.meta?.cellProps || {})}
                >
                  {flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext(),
                  )}
                </Td>
              ))}
            </Tr>
            {expandedGroups.includes(row.id) && (
            <Tr>
              <Td colSpan={9} padding={0} borderBottomWidth={0}>
                <Flex>
                  <TimeRecordsSubTable timeRecords={row.original} groupBy={groupBy} />
                </Flex>
              </Td>
            </Tr>
            )}
          </React.Fragment>
        ))}
      </Tbody>
    </Table>
  );
};

export default TimeRecordsTable;
