import {
  Box, Icon, Text,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { isNumber } from 'lodash';
import React from 'react';
import { ReactComponent as Drill } from '~/assets/live_geo_drill_icon.svg';
import { CountSectionAccordionItem } from '~/components/CountSectionAccordionItem';
import ExpandableText from '~/components/ExpandableText';
import LogRow from '~/pages/LiveGeo/components/LogRow';
import { ClearanceTechnique } from '~/types/clearanceTechnique';
import { CPT } from '~/types/geotechnical';
import { RefusalReason } from '~/types/refusalReason';

interface CPTLogsRowProps {
  cpt: CPT;
  clearanceTechniques: { [key: string]: ClearanceTechnique };
  refusalReasons: { [key: string]: RefusalReason };
}

const CPTLogsRow = ({
  cpt,
  clearanceTechniques,
  refusalReasons,
} : CPTLogsRowProps) => {
  const hasUnderground = isNumber(cpt.undergroundServiceClearanceDepth);
  const hasStartDepth = isNumber(cpt.startDepth);

  return (
    <>
      {!!cpt.note
    && (
      <Text
        fontStyle="italic"
        color="magnetize.text-3"
        padding={3}
        borderBottomWidth={1}
        borderBottomColor="magnetize.ui-3"
      >
        {cpt.note}
      </Text>
    )}
      <CountSectionAccordionItem
        title="CPT Logs"
        icon={<Icon boxSize={8} as={Drill} />}
      >

        {hasUnderground && (
        <LogRow>
          <Text fontWeight="semibold" minW="62px">
            {cpt.undergroundServiceClearanceDepth}
            m
          </Text>
          <Text>{cpt.startTime ? format(cpt.startTime, 'dd MMM yyyy') : ''}</Text>
          <Box>
            <Text>Services Clearance</Text>
            <Text>
              {clearanceTechniques[cpt.undergroundServiceClearanceTechnique]?.name}
            </Text>
          </Box>
          <ExpandableText fontStyle="italic" lines={2}>
            {cpt.undergroundServiceNote || ''}
          </ExpandableText>
        </LogRow>
        )}
        {hasStartDepth && (
        <LogRow>
          <Text fontWeight="semibold" whiteSpace="nowrap" minW="62px">
            {`${cpt.startDepth}m - ${cpt.endDepth}m`}
          </Text>
          <Text>{cpt.startTime ? format(cpt.startTime, 'dd MMM yyyy') : ''}</Text>
          <Box>
            <Text>Refusal reason</Text>
            <Text>
              {refusalReasons[cpt.refusalReason]?.name}
            </Text>
            {cpt.coneSerialNumber && (
            <Text>
              {`Serial: ${cpt.coneSerialNumber}`}
            </Text>
            )}
          </Box>
          <ExpandableText fontStyle="italic" lines={2}>
            {cpt.logNote || ''}
          </ExpandableText>
        </LogRow>
        )}
      </CountSectionAccordionItem>
    </>
  );
};

export default CPTLogsRow;
