export const ModalStyles = {
  baseStyle: {
    header: {
      paddingTop: 12,
      paddingBottom: 6,
      px: 14,
      paddingLeft: 14,
      paddingRight: 14,
      textAlign: 'left',
      fontSize: '3xl',
      fontWeight: 600,
      fontFamily: 'heading',
    },
    closeButton: {
      top: 2,
      right: 2,
    },
    dialog: {
      borderRadius: 'md',
      marginTop: '10rem',
      bottom: '5rem',
    },
    body: {
      paddingLeft: 14,
      paddingRight: 14,
      paddingBottom: 14,
    },
    footer: {
      boxShadow: 'top',
      backgroundColor: 'gray.100',
      py: 8,
      px: 14,
      borderBottomRadius: 'md',
    },
    overlay: {
      background: '#171913CC',
    },
  },
};
