import {
  Flex, Switch, Text,
} from '@chakra-ui/react';
import React from 'react';

interface SwitchWithLabelProps {
  label: string;
  isChecked: boolean;
  isDisabled?: boolean;
  onChanged: (value: boolean) => void;
}

const SwitchWithLabel = ({
  label, isChecked, isDisabled, onChanged,
}: SwitchWithLabelProps) => (
  <Flex alignItems="center">
    <Switch
      isDisabled={isDisabled}
      size="lg"
      mr={2}
      onChange={() => {
        onChanged(!isChecked);
      }}
      isChecked={isChecked}
    />
    <Text
      cursor="pointer"
      onClick={() => {
        onChanged(!isChecked);
      }}
      lineHeight="12px"
      textTransform="none"
    >
      {label}
    </Text>
  </Flex>
);

export default SwitchWithLabel;
