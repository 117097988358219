import { Box } from '@chakra-ui/react';
import React from 'react';
import { isMobile } from 'react-device-detect';
import {
  Redirect, Route, Switch,
} from 'react-router-dom';
import TrialBanner from '~/components/TrialBanner';
import { useCheckRole } from '~/hooks/useCheckRole';
import SidebarLayout from '~/layouts/SidebarLayout';
import { DevPage, TenantPage } from '~/pages/Admin';
import { TenantDetailsPage } from '~/pages/Admin/Tenant/TenantDetailsPage';
import {
  ForgotPasswordPage,
  LoginPage, RegisterPage, ResetPasswordPage,
} from '~/pages/Authentication';
import MobileRedirectPage from '~/pages/Authentication/MobileRedirect';
import CustomerLiveGeoLogsPage from '~/pages/LiveGeo/CustomerLiveGeoLogs';
import CustomerQuotePage from '~/pages/CustomerQuote';
import CustomersPage from '~/pages/Customers';
import EquipmentPage from '~/pages/Equipment';
import Job from '~/pages/Job';
import JobCalendarPage from '~/pages/JobCalendar';
import Jobs from '~/pages/Jobs';
import LiveGeoLogsPage from '~/pages/LiveGeo/LiveGeoLogs';
import NewJob from '~/pages/NewJob';
import NotFoundPage from '~/pages/NotFoundPage';
import RedirectPage from '~/pages/RedirectPage';
import NoPermissionsPage from '~/pages/NoPermissionsPage';
import PlanExpiredModal from '~/pages/PlanExpiredModal';
import PriceBookPage from '~/pages/PriceBook';
import PrivacyPolicyPage from '~/pages/PrivacyPolicy';
import QuotePreviewPage from '~/pages/QuotePreview';
import StaffPage from '~/pages/Staff';
import TenantSettingsPage from '~/pages/TenantSettings';
import WelcomeModal from '~/pages/WelcomeModal';
import XeroAuthCallbackPage from '~/pages/XeroAuthCallback';
import { selectCurrentUser } from '~/redux/currentUser/selectors';
import { selectFetchState } from '~/redux/fetch/selectors';
import { useAppSelector } from '~/redux/store';
import { CognitoGroup } from '~/types/cognitoUser';

export default () => {
  const currentUser = useAppSelector((state) => selectCurrentUser(state));
  const isLoggingIn = useAppSelector((s) => selectFetchState(s, 'currentUser').isLoading);
  const isAuthenticated = !!currentUser?.cognito;
  const isSuperuser = useCheckRole(CognitoGroup.Superuser);
  const isAdmin = useCheckRole(CognitoGroup.Admin);

  const AdminRoutes = () => (
    <>
      <SidebarLayout>
        <Switch>
          <Route path="/jobs/new" component={NewJob} />
          <Route path="/job/:jobId/quote-preview/:quoteId" component={QuotePreviewPage} />
          <Route path="/job/:jobId/live-geo/:logId" component={LiveGeoLogsPage} />
          <Route path="/job/:jobId" component={Job} />
          <Route path="/jobs" component={Jobs} />
          <Route path="/calendar" component={JobCalendarPage} />
          <Route path="/equipment" component={EquipmentPage} />
          <Route path="/staff" component={StaffPage} />
          <Route path="/customers" component={CustomersPage} />
          <Route path="/price-book" component={PriceBookPage} />
          <Route path="/settings" component={TenantSettingsPage} />
          <Route path="/xero/connect" component={XeroAuthCallbackPage} />

          {isSuperuser && <Route path="/superuser/dev" component={DevPage} />}
          {isSuperuser && <Route path="/superuser/tenant/:tenantId" component={TenantDetailsPage} />}
          {isSuperuser && <Route path="/superuser/tenants" component={TenantPage} /> }

          <Route path="*">
            <NotFoundPage />
          </Route>
        </Switch>
      </SidebarLayout>
      {!isMobile && (
      <>
        <TrialBanner />
        <WelcomeModal />
        <PlanExpiredModal />
      </>
      )}
    </>
  );

  const NonAdminRoutes = () => (
    <>
      <Switch>
        <Route path="/jobs/new" component={NoPermissionsPage} />
        <Route path="/job/:jobId/quote-preview/:quoteId" component={NoPermissionsPage} />
        <Route path="/job/:jobId/live-geo/:logId" component={NoPermissionsPage} />
        <Route path="/job/:jobId" component={NoPermissionsPage} />
        <Route path="/jobs" component={NoPermissionsPage} />
        <Route path="/calendar" component={NoPermissionsPage} />
        <Route path="/equipment" component={NoPermissionsPage} />
        <Route path="/staff" component={NoPermissionsPage} />
        <Route path="/customers" component={NoPermissionsPage} />
        <Route path="/price-book" component={NoPermissionsPage} />
        <Route path="/settings" component={NoPermissionsPage} />
        <Route path="/xero/connect" component={NoPermissionsPage} />

        <Route path="*">
          <NotFoundPage />
        </Route>
      </Switch>
    </>
  );

  const UnauthenticatedRoutes = () => (
    <Switch>
      <Route path="*">
        {({ match }) => {
          if (isLoggingIn) {
            return <Box bg="gray.50" />;
          }
          return (
            <Redirect to={{
              pathname: '/login',
              state: {
                redirectTo: match.url,
              },
            }}
            />
          );
        }}
      </Route>
    </Switch>
  );

  const LandingRoute = () => {
    if (isMobile) {
      return (<Route exact path="/" render={() => <Redirect to="/mobile-redirect" />} />);
    }

    if (isAdmin) {
      return (<Route exact path="/" render={() => <Redirect to="/calendar" />} />);
    }

    return (<Route exact path="/" render={() => <Redirect to="/redirect" />} />);
  };

  const InternalRoutes = () => {
    if (isAuthenticated) {
      if (isAdmin) {
        return (
          <Route path="*">
            <LandingRoute />
            <AdminRoutes />
          </Route>
        );
      }
      return (
        <Route path="*">
          <LandingRoute />
          <NonAdminRoutes />
        </Route>
      );
    }

    return (<UnauthenticatedRoutes />);
  };

  return (
    <Switch>
      <Route path="/public/quote/:token" component={CustomerQuotePage} />
      <Route path="/public/live-geo/:token" component={CustomerLiveGeoLogsPage} />
      <Route path="/reset-password" component={ResetPasswordPage} />
      <Route path="/forgot-password" component={ForgotPasswordPage} />
      <Route path="/free-trial" component={RegisterPage} />
      <Route path="/privacy-policy" component={PrivacyPolicyPage} />
      <Route path="/login" component={LoginPage} />
      <Route path="/mobile-redirect*" component={MobileRedirectPage} />
      <Route path="/redirect" component={RedirectPage} />
      <Route path="/no-permissions" component={NoPermissionsPage} />
      <InternalRoutes />
    </Switch>
  );
};
