import { Icon, Text } from '@chakra-ui/react';
import { map, orderBy, values } from 'lodash';
import React from 'react';

import { ReactComponent as Backfill } from '~/assets/backfill.svg';
import { CountSectionAccordionItem } from '~/components/CountSectionAccordionItem';
import ExpandableText from '~/components/ExpandableText';
import LogRow from '~/pages/LiveGeo/components/LogRow';
import { BackfillLayer } from '~/types/geotechnical';
import { BackfillType } from '~/types/backfillType';

interface BackfillLogsRowProps {
  backfillLayers: { [id: string]: BackfillLayer };
  endDepth?: number;
  backfillTypes: { [key: string]: BackfillType };
}

export const BackfillLogsRow = ({
  backfillLayers, endDepth, backfillTypes,
} : BackfillLogsRowProps) => {
  const ordered = orderBy(values(backfillLayers), (sl) => sl.startDepth);

  return (
    <CountSectionAccordionItem
      title="Backfill"
      icon={<Icon boxSize={8} as={Backfill} />}
    >
      {map(ordered, (log, index) => (
        <LogRow key={`backfill-layer-${index}`}>
          <Text fontWeight="semibold" whiteSpace="nowrap" minW="62px">
            {`${log.startDepth}m - ${(ordered[index + 1]?.startDepth || endDepth) ? `${(ordered[index + 1]?.startDepth || endDepth)}m` : ''}`}
          </Text>
          <Text>
            {backfillTypes[log.typeId]?.name}
          </Text>
          <ExpandableText fontStyle="italic">
            {log.note || ''}
          </ExpandableText>
        </LogRow>
      ))}
    </CountSectionAccordionItem>
  );
};
