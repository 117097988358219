import { DragHandleIcon } from '@chakra-ui/icons';
import {
  Box,
  CloseButton, Flex, Grid, GridItem, SkeletonCircle, SkeletonText, Spacer, Text,
} from '@chakra-ui/react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { getTime, toDate } from 'date-fns';
import { isEqual } from 'lodash';
import React, {
  useContext, useMemo, useRef, useState,
} from 'react';
import { ScheduleEventActions } from '~/redux/schedule/actions';
import AdminEventCalendarCard from '~/components/AdminEventCalendarCard';
import { CalendarContext } from '~/components/Calendar/CalendarContext';
import CalendarGrid from '~/components/Calendar/CalendarGrid';
import ResizableCalendarItem from '~/components/Calendar/ResizableCalendarItem';
import { allocateRows } from '~/components/Calendar/rowAllocationAlgorithm';
import ResourceAvatar from '~/components/ResourceAvatar';
import ScheduleEventCard from '~/components/ResourceCalendar/ScheduleEventCard';
import ScheduleEventPopover from '~/components/ResourceCalendar/ScheduleEventPopover';
import { useResourceSummary } from '~/queries/useResourcesQuery';
import { selectEventsForResourceWithinRange } from '~/redux/schedule/selectors';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import { ScheduleEvent } from '~/types/ScheduleEvent';

interface ResourceCalendarRowProps {
  jobId?: string;
  resourceType: 'staff' | 'equipment';
  resourceId: string;
  isReadOnly?: boolean;
  onRemove: (resourceId: string) => void;
}

const EDITABLE_EVENT_TYPES: ScheduleEvent['type'][] = ['staff-leave', 'equipment-status'];

const ResourceCalendarRow = ({
  onRemove, jobId, resourceId, resourceType, isReadOnly,
} : ResourceCalendarRowProps) => {
  const calendarContext = useContext(CalendarContext);
  const dispatch = useAppDispatch();
  const portalContainerRef = useRef<HTMLDivElement>();

  const { data: resource, isLoading } = useResourceSummary({ id: resourceId, type: resourceType });

  const events = useAppSelector(
    (state) => selectEventsForResourceWithinRange(
      state,
      resourceId,
      getTime(calendarContext.start),
      getTime(calendarContext.end),
    ),
    (a, b) => isEqual(a, b),
  );

  const rowMap = useMemo(
    () => allocateRows(events),
    [events],
  );

  // TODO: this is a hack to hide the popup while editing, but this
  // should be per-timeline item state
  const [isEditing, setIsEditing] = useState(false);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: resourceId });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const updateCalendarEventSpan = (event: ScheduleEvent, start: Date, end: Date) => {
    if (getTime(start) !== getTime(event.start) || getTime(end) !== getTime(event.end)) {
      dispatch(ScheduleEventActions.updateEvent({
        ...event,
        start: getTime(start),
        end: getTime(end),
      }));
    }
  };

  return (
    <>
      <Grid
        templateColumns="2fr 5fr"
        borderBottomWidth="1px"
        borderBottomColor="magnetize.ui-3"
        py="2"
        ref={setNodeRef}
        style={style}
      >
        <GridItem minWidth={0} display="flex" flexDir="row">
          <Flex alignItems="center" flex="1">
            <Box p="2" {...attributes} {...listeners}>
              <DragHandleIcon />
            </Box>
            <Box mr="4">
              {isLoading
                ? <SkeletonCircle boxSize="28px" />
                : <ResourceAvatar resource={resource} size="sm" />}
            </Box>
            <Box flex="1 1 auto">
              {isLoading
                ? <SkeletonText noOfLines={2} />
                : (
                  <>
                    <Text as="div" fontSize="12px">
                      {resource?.displayName ?? ''}
                    </Text>
                    {resource?.subtitle && (
                    <Text as="div" fontSize="10px" color="magnetize.text-3">
                      {resource.subtitle}
                    </Text>
                    )}
                  </>
                )}
            </Box>
            <Spacer />
            <CloseButton size="sm" mr="2" onClick={() => onRemove(resourceId)} />
          </Flex>
        </GridItem>
        <GridItem
          minWidth={0}
        >
          <CalendarGrid
            minHeight="50px"
            subdivisions={1}
            rowGap={2}
            overflowX="hidden"
            overflow="visible"
          >
            {events.map((event) => (
              <ResizableCalendarItem
                key={event.id}
                start={toDate(event.start)}
                end={toDate(event.end)}
                row={rowMap[event.id]}
                canEdit={!isReadOnly
                  && EDITABLE_EVENT_TYPES.includes(event.type)
                  && (!jobId || event.jobId === jobId)}
                isEditingChange={(e) => setIsEditing(e)}
                onSpanChange={({ start, end }) => updateCalendarEventSpan(event, start, end)}
              >
                {event.type === 'job-resource' && (
                  <ScheduleEventPopover
                    eventId={event.id}
                    disablePopup={isEditing}
                    containerRef={portalContainerRef}
                  >
                    <ScheduleEventCard
                      event={event}
                      isActive={!jobId || event.jobId === jobId}
                    />
                  </ScheduleEventPopover>
                )}
                {(event.type === 'staff-leave' || event.type === 'equipment-status') && (
                  <AdminEventCalendarCard
                    event={event}
                    onSpanChange={({ start, end }) => updateCalendarEventSpan(event, start, end)}
                    hideResource
                  />
                )}
              </ResizableCalendarItem>
            ))}
          </CalendarGrid>
          <Box ref={portalContainerRef} />
        </GridItem>
      </Grid>
    </>
  );
};

export default ResourceCalendarRow;
