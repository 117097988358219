import React from 'react';
import { Box, Text } from '@chakra-ui/react';

const Badge = ({ backgroundColor, text }:{ backgroundColor: string, text: string }) => (
  <Box display="inline-block" paddingX="0.3rem" pb="0.05rem" borderRadius="7px" backgroundColor={backgroundColor} lineHeight="13px">
    <Text fontWeight="semibold" color="white" fontSize={8}>{text}</Text>
  </Box>
);

export const NewBadge = () => (
  <Badge backgroundColor="magnetize.support-warning" text="new" />
);

export const DemoBadge = () => (
  <Badge backgroundColor="magnetize.brand-2" text="demo" />
);

export default Badge;
