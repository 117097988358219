import React, { ReactNode } from 'react';
import {
  Flex,
} from '@chakra-ui/react';

const LoginBackground = ({ children }: { children: ReactNode }) => (
  <Flex
    backgroundImage="url('/images/login-bg.jpg')"
    backgroundPosition="top"
    backgroundRepeat="no-repeat"
    backgroundSize="cover"
    minH="100vh"
    minW="100vw"
    paddingY={{ base: '0px', md: '10vh' }}
    paddingLeft={{ base: '0px', md: '15vh' }}
  >
    {children}
  </Flex>
);

export default LoginBackground;
