import { GridItem, Heading, Text } from '@chakra-ui/react';
import { captureException } from '@sentry/react';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import PageHeader from '~/layouts/PageHeader';
import PrimarySecondaryColumns from '~/layouts/PrimarySecondaryColumns';

const NotFoundPage = () => {
  const location = useLocation();

  useEffect(() => {
    captureException(new Error(`Page not found: ${location.pathname}`));
  }, [location.pathname]);

  return (
    <PrimarySecondaryColumns>
      <PageHeader backButton>
        <Heading size="lg">
          Page not found
        </Heading>
      </PageHeader>
      <GridItem gridArea="primary">
        <Text fontSize="lg">
          We&apos;re sorry that you&apos;ve ended up here.
        </Text>
      </GridItem>
    </PrimarySecondaryColumns>

  );
};

export default NotFoundPage;
