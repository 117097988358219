import { AddIcon, SearchIcon } from '@chakra-ui/icons';
import {
  Box, Icon, useToken,
} from '@chakra-ui/react';
import { ExpandMore } from '@material-ui/icons';
import React, { useState } from 'react';
import Select, {
  components as selectComponents,
  ControlProps,
  OptionProps,
  OptionTypeBase,
  SingleValueProps,
  Theme,
} from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Props as SelectProps } from 'react-select/src/Select';
import { makeTransparent } from '~/theme';

interface OptionType extends OptionTypeBase {
  label:string;
  value: string;
}

function Control<TOptionType extends OptionTypeBase, TMulti extends boolean = false>({
  children, ...rest
}: ControlProps<TOptionType, TMulti>) {
  return (
    <selectComponents.Control {...rest}>
      {rest.selectProps.icon
    && (
    <Box
      color="magnetize.text-2"
    >
      {rest.selectProps.icon}
    </Box>
    )}
      {children}
      {!rest.selectProps.icon && !rest.selectProps.hideCaret && (
      <Box
        color="magnetize.text-2"
        marginRight="0.5rem"
      >
        <ExpandMore />
      </Box>
      )}
    </selectComponents.Control>
  );
}

function SingleValue<TOptionType extends OptionTypeBase>({
  children, ...rest
}: SingleValueProps<TOptionType>) {
  const { data } = rest;
  const {
    renderCurrentValue,
  } = rest.selectProps as any;

  return (
    <>
      {renderCurrentValue && !rest.selectProps.isMenuOpen
        ? renderCurrentValue({
          option: data as TOptionType, children,
        })
        : children}
    </>
  );
}

function Option<TOptionType extends OptionTypeBase, TMulti extends boolean = false>({
  children, ...rest
}: OptionProps<TOptionType, TMulti>) {
  // eslint-disable-next-line no-underscore-dangle
  const isNew = rest.data.__isNew__;
  const { data } = rest;
  const {
    createOptionText,
    renderOption,
  } = rest.selectProps as SearchSelectProps<TOptionType, TMulti>;

  return (
    <selectComponents.Option {...rest}>
      {isNew ? (
        <>
          <AddIcon mr="6px" mt="-2px" />
          {createOptionText ?? children}
        </>
      ) : (
        <>
          {renderOption
            ? renderOption({
              option: data as TOptionType, children,
            })
            : children}
        </>
      )}

    </selectComponents.Option>
  );
}

interface SelectRenderProps<TOptionType> {
  option: TOptionType;
  // innerRef: React.Ref<any>;
  // innerProps: any;
  children: React.ReactNode;
}

export interface SearchSelectProps<
  TOptionType extends OptionTypeBase,
  TMulti extends boolean,
> extends SelectProps<TOptionType, TMulti> {
  createable?: boolean;
  variant?: 'outline' | 'filled' | 'flushed' | 'link';
  theme?: (t: Theme) => Theme;
  hideSearchIcon?: boolean;
  renderOption?: (props: SelectRenderProps<TOptionType>) => void;
  renderCurrentValue?: (props: SelectRenderProps<TOptionType>) => void;
  noOptionPadding?: boolean;
  hideIndicators?: boolean;
  hideCaret?: boolean;
  icon?: React.ReactNode;
  selectRef?: React.Ref<Select>;
}

function SearchSelect<
  TOptionType extends OptionTypeBase = OptionType,
  TMulti extends boolean = false,
>({
  createable = false,
  styles = {},
  components = {},
  theme = (t) => t,
  variant = 'outline',
  hideSearchIcon = false,
  noOptionPadding = false,
  hideIndicators = true,
  hideCaret = false,
  icon = <Icon as={SearchIcon} ml="10px" mb="3px" />,
  selectRef,
  ...props
}: SearchSelectProps<TOptionType, TMulti>) {
  const [brand100, gray200, ui2] = useToken(
    'colors',
    ['magnetize.brand-4', 'gray.200', 'magnetize.ui-2'],
  );
  const [borderRadiusXs] = useToken('radii', ['xs']);
  const [dropdownShadow] = useToken('shadows', ['lg']);
  const [dropdownBorderColor] = useToken('colors', ['magnetize.ui-4']);
  const [currentValue, setCurrentValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const selectProps: SelectProps<TOptionType, TMulti> = {
    placeholder: '',
    createOptionPosition: 'first',
    components: {
      Control,
      Option,
      SingleValue: isOpen ? selectComponents.SingleValue : SingleValue,
      ...components,
    },
    onFocus: () => setIsOpen(true),
    onBlur: () => {
      setIsOpen(false);
      if (createable && currentValue && props.onCreateOption) {
        props.onCreateOption(currentValue);
      }
    },
    onInputChange: (e) => {
      setCurrentValue(e);
    },
    theme: (t) => theme({
      ...t,
      // This has to be a number, for ... reasons, so we'll apply it in the
      // style overrides
      borderRadius: 0,
      colors: {
        ...t.colors,
        primary: gray200,
        primary25: ui2,
        primary50: makeTransparent(gray200, 0.5),
        primary75: makeTransparent(gray200, 0.75),
      },
      spacing: {
        ...t.spacing,
      },
    }),
    styles: {
      indicatorsContainer: (provided) => ({
        ...provided,
        display: hideIndicators ? 'none' : 'flex',
      }),

      dropdownIndicator: (provided) => ({
        ...provided,
        padding: '2px 8px',
      }),

      indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none',
      }),

      loadingIndicator: (provided) => ({
        ...provided,
        display: 'none',
      }),

      option: (provided, state) => {
        const nextState = {
          ...provided,
          padding: 10,
          backgroundColor: state.isSelected || state.isFocused ? ui2 : null,
          color: null,
        };
        if (noOptionPadding) {
          nextState.padding = 0;
        }

        return nextState;
      },

      menu: (provided) => ({
        ...provided,
        borderBottomLeftRadius: '2px',
        borderBottomRightRadius: '2px',
        boxShadow: dropdownShadow,
        border: '0.5px solid',
        borderColor: dropdownBorderColor,
        fontSize: '12px',
        marginTop: 0,
        marginBottom: 0,
      }),
      valueContainer(base) {
        return {
          ...base,
          paddingLeft: 'var(--chakra-space-4)',
          paddingRight: 'var(--chakra-space-4)',
        };
      },

      multiValueLabel: (provided) => ({
        ...provided,
        fontSize: '12px',
        padding: '1px',
      }),
      placeholder(base) {
        return {
          ...base,
          color: 'var(--chakra-colors-gray-400)',
        };
      },

      menuList: (provided) => ({
        ...provided,
        paddingTop: 0,
        paddingBottom: 0,
      }),

      input: (provided) => ({
        ...provided,
        paddingBottom: 0,
      }),
      control: (provided, state) => {
        const base = {
          ...provided,
          fontSize: 'var(--chakra-fontSizes-lg)',
          minHeight: 'var(--chakra-space-12)',
          maxHeight: 'var(--chakra-space-12)',
          // Some hover state somewhere makes this gray :rage:
          borderColor: state.menuIsOpen || state.isFocused ? `${brand100} !important` : provided.borderColor,
        };

        switch (variant) {
          case 'flushed':
            return {
              ...base,
              borderTop: 'none',
              borderLeft: 'none',
              borderRight: 'none',
              outline: 'none',
              outlineWidth: 0,
              boxShadow: 'none',
            };

          case 'filled':
            return {
              ...base,
              backgroundColor: ui2,
              border: 'none',
              borderRadius: borderRadiusXs,
            };

          case 'link':
            return {
              ...base,
              backgroundColor: state.menuIsOpen || state.isFocused ? undefined : ui2,
              borderRadius: borderRadiusXs,
              borderWidth: '1px',
              border: state.menuIsOpen || state.isFocused ? undefined : 'none',
              minHeight: '38px',
              maxHeight: '38px',
            };
          case 'outline':
          default:
            return {
              ...base,
              boxShadow: 'none',
              borderWidth: '1px',
              borderRadius: borderRadiusXs,
            };
        }
      },
      ...styles,
    },
    variant,
    icon: hideSearchIcon ? null : icon,
    menuShouldScrollIntoView: false,
    hideCaret,
    ref: selectRef,
    ...props,
  };
  if (createable) {
    return (
      <CreatableSelect {...selectProps} />
    );
  }
  return (
    <Select {...selectProps} />
  );
}

export default SearchSelect;
