import { Button } from '@chakra-ui/react';
import React from 'react';
import queryClient from '~/queryClient';
import { showErrorToast } from '~/toast';
import fetchJson from '~/utils/fetchJson';

const RunSubscriptionWorkerButton = () => {
  const triggerSubscriptionEmails = async () => {
    try {
      await fetchJson('/api/tenant-admin/trigger-batch-email-job', {
        method: 'POST',
        body: {},
      });

      // Hacky, we don't know how long the background job
      // actually takes.
      setTimeout(() => {
        queryClient.invalidateQueries('admin-tenant');
      }, 5000);
    } catch (e) {
      showErrorToast(e);
    }
  };

  return (
    <Button onClick={triggerSubscriptionEmails}>
      Run subscription worker now
    </Button>
  );
};

export default RunSubscriptionWorkerButton;
