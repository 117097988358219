export const ButtonStyles = {
  baseStyle: {
    borderRadius: 2,
    fontFamily: 'heading',
    fontWeight: '600',
  },
  variants: {
    solid: {
      textTransform: 'uppercase',
    },

    link: {
      fontFamily: 'body',
      fontWeight: 'semibold',
      textTransform: 'none',
      textDecoration: 'none',
      fontSize: '12px',
      _hover: {
        textDecoration: 'underline',
      },
    },

    ghost: {
      fontFamily: 'body',
      fontWeight: 'semibold',
      textTransform: 'none',
      textDecoration: 'none',
      _hover: {
        textDecoration: 'underline',
      },
    },
    outline: {
      borderColor: 'magnetize.ui-4',
      fontWeight: 'semibold',
      fontFamily: 'body',
    },
  } as any,
  sizes: {
    lg: {
      height: '40px',
    },
    md: {
      paddingTop: 4,
      paddingBottom: 4,
      paddingLeft: 5,
      paddingRight: 5,
    },
  },
  defaultProps: {
    colorScheme: 'brandDark',
  },
};
