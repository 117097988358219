import { createReducer } from '@reduxjs/toolkit';
import { CustomerLiveGeoActions, CustomerLiveGeoData } from '~/redux/customerLiveGeo/actions';
import { Dictionary } from '~/types/helpers';

export type BoreholeState = {
  dataByToken: Dictionary<CustomerLiveGeoData>,
};

const liveGeoReducer = createReducer<BoreholeState>({
  dataByToken: {},
},
(builder) => {
  builder.addCase(CustomerLiveGeoActions.updated, (state, action) => ({
    ...state,
    dataByToken: {
      ...state.dataByToken,
      [action.payload.token]: action.payload.data,
    },
  }));
});

export default liveGeoReducer;
