import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { queryQuote } from '~/redux/customerQuotes/actions';
import useTrackedAction from '~/hooks/useTrackedAction';
import { showToast } from '~/toast';
import { PrimaryButton } from '~/components/CustomerFacingHeader/PrimaryButton';

export const RaiseQueryModal = ({
  title = 'Raise Query', token, isOpen, onClose, contactInfo,
} : any) => {
  const [comment, setComment] = useState('');
  const { isLoading, isDone, trigger: onQueryClicked } = useTrackedAction({
    trigger: () => queryQuote({
      token,
      comment,
    }),
  });

  useEffect(() => {
    if (isDone) {
      showToast({
        description: `Query sent to ${contactInfo?.name}`,
        status: 'success',
      });
      onClose();
    }
  }, [isDone]);
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton tabIndex={-1} />
        <ModalBody>
          <FormControl isRequired>
            <FormLabel>Query</FormLabel>
            <Textarea
              isDisabled={isLoading}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={4}>
            <PrimaryButton
              isDisabled={isLoading}
              isLoading={isLoading}
              onClick={onQueryClicked}
            >
              Send
            </PrimaryButton>
            <Button variant="ghost" size="lg" onClick={onClose}>cancel</Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
