import { createSelector } from '@reduxjs/toolkit';
import { isWithinInterval } from 'date-fns';
import { RootState } from '~/redux/reducer';
import { getIsoDate } from '~/utils/calendarHelpers';
import { parseISODateLocal } from '~/utils/parseTime';

export const selectLogIdsForJob = (s: RootState, jobId: string) => s.logs.byJobId[jobId];

export const selectLogsForJob = createSelector(
  selectLogIdsForJob,
  (s) => s.logs.byId,
  (logIds, logsById) => (logIds ?? []).map((id) => logsById[id]),
);

export const selectLogsForJobInDateRange = (
  s: RootState,
  jobId: string,
  interval: Interval,
) => {
  const logs = selectLogsForJob(s, jobId);
  return logs.filter((l) => isWithinInterval(parseISODateLocal(l.date), interval));
};

export const selectMatchingEquipmentLogEntry = (
  s: RootState, jobId: string, date: number | Date, resourceId: string,
) => {
  const isoDate = getIsoDate(date);
  return Object.values(s.logs.byId)
    .find((e) => e.type === 'equipment'
      && e.jobId === jobId
      && e.date === isoDate
      && e.resourceId === resourceId);
};
