import React from 'react';
import {
  Box,
  Heading, Text,
} from '@chakra-ui/react';

import TimelineItem, { TimelineItemProps } from '~/components/TimelineItem';
import { JobTimelineQuoteAcceptedOnBehalf } from '~/types/job';
import StatusBadge from '~/components/JobStatusBadge';
import { selectStaffName } from '~/redux/staff/selectors';
import { useAppSelector } from '~/redux/store';

export const TimelineQuoteAcceptedOnBehalf = ({
  event, ...rest
}: {
  event: JobTimelineQuoteAcceptedOnBehalf;
} & TimelineItemProps) => {
  const staffName = useAppSelector((s) => selectStaffName(s, event.staffId));
  return (
    <TimelineItem
      {...rest}
      event={event}
    >
      {event.status && <Box mb={2}><StatusBadge status={event.status} /></Box> }
      <Heading textTransform="none" size="sm" mb={1}>
        QUOTE ACCEPTED
      </Heading>
      <Text>
        {`QUOTE-${event.code} has been accepted on behalf of the customer by ${staffName}`}
      </Text>

    </TimelineItem>
  );
};
