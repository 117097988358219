/* eslint-disable no-restricted-syntax */
import { getTime } from 'date-fns';
import { max, sortBy } from 'lodash';
import { Dictionary } from '~/types/helpers';

interface CalendarEvent {
  id: string;
  start: number | Date;
  end: number | Date;
}

interface CalenderEventEdge {
  t: number;
  type: 'start' | 'end';
  event: CalendarEvent;
}

function makeEdges(events: CalendarEvent[]): CalenderEventEdge[] {
  return sortBy(
    events.flatMap((event) => [
      { type: 'start', t: getTime(event.start), event },
      { type: 'end', t: getTime(event.end), event },
    ]),
    // Sort the edges by their time...
    (edge) => edge.t,
    // ... but break ties by looking at the event's start time. This handles the
    // case where an event ends and another starts in the same instant. We want
    // the 'end' of the first event to be processed before the start of a new one.
    // Another way to express this would be to sort ends before starts.
    (edge) => getTime(edge.event.start),
  );
}

type RowAllocationMap = Dictionary<number>;

function getFirstAvailableRow(occupiedRows: number[]): number {
  const lastOccupiedRow = max(occupiedRows) ?? 0;
  for (let i = 1; i <= lastOccupiedRow + 1; i += 1) {
    if (!occupiedRows.includes(i)) {
      return i;
    }
  }
  return lastOccupiedRow + 1;
}

export function allocateRows(
  events: CalendarEvent[],
): RowAllocationMap {
  // A better algorithm would consider the previous row assignments too so that
  // stuff didn't jump around if you moved through time or rearranged events. A
  // potential solution for this could be to scan forward through the edges
  // until the end of the current event, considering any previous allocations in
  // that window as occupied rows too -- this would force newly found events to
  // find space. That algorithm would be worst case O(n^2), but the total number
  // of events and likelihood of overlaps means in practice the runtime is going
  // to be closer to O(n.log(n))
  //
  // When considering an event with previous allocations, it would need to be
  // looking for conflicts (as you could have moved/resized an event). In the
  // case of a conflict a new row will need to be allocated. A simple strategy
  // would be to delete any conflicting future allocations, but a better
  // approach might be to flag it as conflicted, then deal with that later. In
  // these cases the 'ideal' placement might be to create as little disruption
  // as possible.
  //
  // Question: would a two pass algorithm work better? Fix existing conflicts first,
  //    then find a home for unallocated events? The first pass could try to make the
  //    smallest change, then the next pass could consolidate, then fill in the gaps
  //    with new data?
  const rowAllocations: RowAllocationMap = {};
  const inProgressEvents = new Set<CalendarEvent>();
  for (const edge of makeEdges(events)) {
    if (edge.type === 'start') {
      const occupiedRows = Array.from(inProgressEvents).map((e) => rowAllocations[e.id]);
      rowAllocations[edge.event.id] = getFirstAvailableRow(occupiedRows);
      inProgressEvents.add(edge.event);
    } else {
      inProgressEvents.delete(edge.event);
    }
  }

  return rowAllocations;
}
