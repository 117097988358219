import {
  Button, Flex,
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { nanoid } from '@reduxjs/toolkit';
import FileGallery from '~/components/FileUpload/FileGallery';
import AutoSizingTextarea from '~/components/AutoSizingTextarea';
import { FileUploadRef } from '~/components/FileUpload';
import TimelineFileUpload from '~/components/FileUpload/TimelineFileUpload';
import OutlineWhenFocusWithin from '~/components/OutlineOnFocusWithin';
import { selectCurrentUser } from '~/redux/currentUser/selectors';
import { useAppSelector } from '~/redux/store';
import { JobTimelineNote } from '~/types/job';

const AddTimelineNote = ({ addNote }: { addNote: (note: JobTimelineNote) => void }) => {
  const [files, setFiles] = useState([]);
  const [message, setMessage] = useState('');
  const textareaRef = useRef<HTMLTextAreaElement>();
  const currentUser = useAppSelector((state) => selectCurrentUser(state));
  const fileUploadRef = useRef<FileUploadRef>();

  const addTimelineNote = () => {
    addNote({
      id: nanoid(),
      type: 'note',
      at: new Date().getTime(),
      user: currentUser.staff.name,
      files: files.map(({ uploaded, ...file }) => file),
      message,
    });
    setFiles([]);
    setMessage('');
    fileUploadRef.current.reset();
  };

  return (
    <>
      <OutlineWhenFocusWithin delegateFocusTo={textareaRef}>
        <AutoSizingTextarea
          px={1}
          py={1}
          ref={textareaRef}
          variant="unstyled"
          placeholder="Add a note"
          value={message}
          minLines={2}
          onChange={(e) => setMessage(e.target.value)}
        />
        <FileGallery
          onFileRemoved={(file) => {
            fileUploadRef.current.reset();
            const { s3Key } = file;
            const filtered = files.filter((f) => f.s3Key !== s3Key);
            setFiles(filtered);
          }}
          files={files}
        />
      </OutlineWhenFocusWithin>
      <Flex mt={2}>
        <TimelineFileUpload
          uploadRef={fileUploadRef}
          onFileUploaded={(file) => {
            setFiles([...files, file]);
          }}
        />
        <Button
          size="md"
          colorScheme="brandDark"
          isDisabled={!message && files.length === 0}
          onClick={addTimelineNote}
          marginLeft={3}
        >
          Add
        </Button>
      </Flex>
    </>
  );
};

export default AddTimelineNote;
