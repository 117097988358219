import { Icon, Text } from '@chakra-ui/react';
import { AddOutlined } from '@material-ui/icons';
import React from 'react';
import { JobEventViewModel } from '~/components/JobCalendar/JobEventViewModel';
import LinkButton from '~/components/LinkButton';
import JobScheduleTable from '~/pages/Job/components/schedule/JobScheduleTable';

interface ScheduledDatesAccordionProps {
  jobEvents: JobEventViewModel[];
  onSelectEvent: (jobEvent: JobEventViewModel) => void;
  onCreateEvent: () => void;
}

const ScheduledDatesAccordion = (
  { jobEvents, onSelectEvent, onCreateEvent } : ScheduledDatesAccordionProps,
) => (
  <>
    <JobScheduleTable
      jobEvents={jobEvents}
      onSelectEvent={onSelectEvent}
    />
    {jobEvents?.length === 0 && (
      <Text mx={2} mt={4}>
        There are no events to display.
      </Text>
    )}
    <LinkButton
      isGreen
      noUnderline
      leftIcon={<Icon fontSize="md" as={AddOutlined} />}
      mt="6"
      mb="2"
      onClick={onCreateEvent}
    >
      Schedule event
    </LinkButton>
  </>
);

export default ScheduledDatesAccordion;
