export const CheckboxStyles = {
  sizes: {
    sm: {
      icon: {
        fontSize: '0.35rem',
      },
    },
    md: {
      control: {
        padding: '0.0625rem',
        w: '1.125rem',
        h: '1.125rem',
      },
      icon: {
        fontSize: '0.6rem',
      },
    },
    lg: {
      icon: {
        fontSize: '0.625rem',
      },
    },
  },
  baseStyle: {
    icon: {
      color: 'white',
    },
    container: {
      borderColor: 'magnetize.brand-3',
    },
    control: {
      _checked: {
        backgroundColor: 'magnetize.brand-3',
      },
    },
  },
  defaultProps: {
    colorScheme: 'white',
  },
};
