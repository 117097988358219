import { selectCurrentUser } from '~/redux/currentUser/selectors';
import { useAppSelector } from '~/redux/store';
import formatCurrency from '~/utils/formatCurrency';

export const useCurrencyFormatter = (currency?: string) => {
  const currentUser = useAppSelector((state) => selectCurrentUser(state));
  const { tenant } = currentUser;

  return (valueInCents) => formatCurrency(valueInCents, currency || tenant?.currency || 'NZD');
};
