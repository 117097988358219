import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button, Flex, Grid, GridItem, Icon, Menu, MenuButton,
  MenuDivider, MenuItem, MenuList, useOutsideClick, Text, MenuButtonProps,
} from '@chakra-ui/react';
import { CalendarToday } from '@material-ui/icons';
import {
  format,
} from 'date-fns';
import React, { useMemo, useState } from 'react';
import DatePicker, { DatePickerProps } from '~/components/DatePicker';

interface SmartDatePickerProps extends DatePickerProps {
  suggestedDates?: DateWithLabel[][],
  selected?: Date,
  onChange: (d: Date) => void
  alwaysShowDatePicker?: boolean;
  hideIcon?: boolean;
  placeholder?: string;
  borderColor?: string;
  buttonProps?: MenuButtonProps;
}

interface DateWithLabel {
  date: Date;
  label: string;
}

const SmartDatePicker = ({
  suggestedDates = [],
  selected = null, onChange, minDate, maxDate,
  alwaysShowDatePicker = true,
  hideIcon,
  placeholder = '',
  buttonProps,
  borderColor = 'gray.200',
  ...props
} : SmartDatePickerProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const ref = React.useRef();
  useOutsideClick({
    ref,
    handler: () => setIsOpen(false),
  });

  const dateSelected = (d : Date) => {
    setIsOpen(false);
    onChange(d);
  };

  const validSuggestedDates = useMemo(
    () => suggestedDates
      .map((dates) => dates.filter((d) => {
        if (minDate && d.date < minDate) {
          return false;
        }
        if (maxDate && d.date > maxDate) {
          return false;
        }
        return true;
      }))
      .filter((dates) => dates.length > 0),
    [suggestedDates, minDate, maxDate],
  );

  const isShowingDatePicker = showDatePicker || alwaysShowDatePicker;

  return (
    <Flex ref={ref}>
      <Menu isOpen={isOpen} closeOnSelect={false} isLazy autoSelect={false}>
        <MenuButton
          size="lg"
          borderRadius="0"
          fontFamily="Open Sans"
          onClick={() => {
            setShowDatePicker(validSuggestedDates.length === 0);
            setIsOpen(!isOpen);
          }}
          width="100%"
          pl="1rem"
          pr="1rem"
          height="var(--chakra-space-12)"
          textAlign="left"
          as={Button}
          variant="outline"
          fontWeight="normal"
          borderColor={borderColor}
          _expanded={{
            bg: 'white',
          }}
          readOnly
          rightIcon={<ChevronDownIcon boxSize="18px" mr={-2} />}
          {...buttonProps}
        >
          {selected
            ? format(selected, 'd MMM yyyy') : <Text color="magnetize.text-4">{placeholder}</Text>}
        </MenuButton>
        <MenuList>
          {validSuggestedDates.map((dates, index) => {
            const isLast = index === validSuggestedDates.length - 1;
            return (
              <Flex direction="column" key={dates.map((d) => d.label).join('-')}>
                {dates.map((d) => (
                  <MenuItem
                    key={d.label}
                    onClick={() => {
                      dateSelected(d.date);
                    }}
                  >
                    <Grid flex="1" templateColumns="3fr 1fr 1fr">
                      <GridItem>
                        {d.label}
                      </GridItem>
                      <GridItem />
                      <GridItem>
                        {d.date && format(d.date, 'dd MMM')}
                      </GridItem>
                    </Grid>
                  </MenuItem>
                ))}
                <MenuDivider
                  visibility={!isShowingDatePicker || !isLast ? 'visible' : 'hidden'}
                />
              </Flex>
            );
          })}

          {isShowingDatePicker
            ? (
              <Box mb="-3">
                <DatePicker
                  size="lg"
                  variant="ghost"
                  onChange={dateSelected}
                  inline
                  minDate={minDate}
                  maxDate={maxDate}
                  selected={selected}
                  {...props}
                />
              </Box>
            )
            : (
              <>
                <MenuItem icon={<Icon as={CalendarToday} boxSize="16px" />} onClick={() => setShowDatePicker(true)}>
                  Pick another date
                </MenuItem>
              </>
            )}
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default SmartDatePicker;
