import { Address } from '~/types/address';

interface ScheduleEventBase {
  id: string;
  start: number;
  end: number;
  fromDate?: string;
  toDate?: string;
  timezone?: string;
  startTimeMinutes?: number;

  // A lot of the schedule event types either have a job ID or a resource ID.
  // Including these in the base type definition, then tightening them up in
  // more specific cases is just less faff, even if it's not strictly correct.
  jobId?: string;
  resourceId?: string;
}

export interface JobResourceEvent extends ScheduleEventBase {
  type: 'job-resource',
  jobId: string;
  parentId: string;
  resourceId: string;
  resourceType: string;
}

export interface JobEvent extends ScheduleEventBase {
  type: 'job',
  jobId: string;
  label?: string;
  notes?: string;
  children?: JobResourceEvent[];
}

export interface StaffLeaveEvent extends ScheduleEventBase {
  type: 'staff-leave',
  resourceId: string;
  label?: string;
  notes?: string;
}

export interface EquipmentStatusEvent extends ScheduleEventBase {
  type: 'equipment-status',
  resourceId: string;
  label?: string;
  notes?: string;
  isAvailable?: boolean;
  address?: Address;
}

export type ScheduleEvent = JobEvent | JobResourceEvent | StaffLeaveEvent | EquipmentStatusEvent;
export type ApiScheduleEvent = Omit<ScheduleEvent, 'start' | 'end'> & { from: number, to: number };

export const isJobCalendarEvent = (
  event: ScheduleEvent,
): event is JobEvent | JobResourceEvent => (
  event?.type === 'job' || event?.type === 'job-resource'
);

export const isLeaveEvent = (ev: ScheduleEvent): ev is StaffLeaveEvent => (
  ev?.type === 'staff-leave'
);

export const isEquipmentStatusEvent = (ev: ScheduleEvent): ev is EquipmentStatusEvent => (
  ev?.type === 'equipment-status'
);

export const isJobResourceEvent = (ev: ScheduleEvent): ev is JobResourceEvent => (
  ev?.type === 'job-resource'
);

export const isJobEvent = (ev: ScheduleEvent): ev is JobEvent => (
  ev?.type === 'job'
);
