import {
  Icon, Table, Text, Tbody, Tr, VStack, Td, Skeleton, MenuItem,
} from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
import React, { useState } from 'react';
import { ReactComponent as CasingIcon } from '~/assets/live_geo_casing_type.svg';
import ConfirmationDialog from '~/components/ConfirmationDialog';
import { ExpandableCountSection } from '~/components/ExpandableCountSection';
import { KebabOptionsMenu } from '~/components/KebabOptionsMenu';
import LinkButton from '~/components/LinkButton';
import { useModalManager } from '~/components/ModalManager';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import { CasingTypeEditModal } from './CasingTypeEditModal';
import { triggerCasingTypesFetch, deleteCasingType } from '~/redux/casingTypes/actions';
import { getActiveCasingTypes } from '~/redux/casingTypes/selectors';
import { useAppDispatch } from '~/redux/store';
import { CasingType } from '~/types/casingType';
import eventStopper from '~/utils/eventStopper';

export const CasingTypesList = () => {
  const dispatch = useAppDispatch();
  const modalManager = useModalManager();
  const { data: casingTypes, isLoading } = useTrackedFetch({
    key: 'casing-types',
    trigger: () => triggerCasingTypesFetch({ force: false }),
    selector: getActiveCasingTypes,
  });

  const [
    selectedCasingType,
    setSelectedCasingType,
  ] = useState<Partial<CasingType>>(null);
  const [casingTypeModalTitle, setCasingTypeModalTitle] = useState<string>();

  const addTechnique = () => {
    setSelectedCasingType({
      id: nanoid(),
    });
    setCasingTypeModalTitle('Add casing type');
  };

  const editTechnique = (casingType: CasingType) => {
    setSelectedCasingType(casingType);
    setCasingTypeModalTitle('Edit casing type');
  };

  const deleteTechnique = (casingTypeId) => {
    dispatch(deleteCasingType({
      id: casingTypeId,
    }));
  };

  return (
    <VStack
      align="stretch"
    >
      <ExpandableCountSection
        title="Casing types"
        icon={<Icon boxSize={8} as={CasingIcon} />}
        count={casingTypes?.length || 0}
      >
        <Table size="md">
          <Tbody>
            {isLoading ? (
              <Tr>
                <Td><Skeleton height={8} /></Td>
                <Td><Skeleton height={8} /></Td>
                <Td />
              </Tr>
            )
              : (orderBy(casingTypes, (t) => t.name).map((tr) => (
                <Tr
                  key={tr.id}
                  _hover={{ bg: '#fafafa', cursor: 'pointer' }}
                  transition="color 0.1s ease-out"
                  role="group"
                  onClick={() => editTechnique(tr)}
                >
                  <Td>
                    <Text pl={24}>
                      {tr.name}
                    </Text>
                  </Td>

                  <Td textAlign="end" {...eventStopper()}>
                    <KebabOptionsMenu>
                      <MenuItem onClick={() => editTechnique(tr)}>Edit</MenuItem>
                      <MenuItem
                        onClick={() => {
                          modalManager.open(ConfirmationDialog, {
                            title: 'Delete casing type',
                            children: 'This will remove the casing type from Magnetize, but will not remove it from any existing logs.',
                            onConfirm: () => deleteTechnique(tr.id),
                            confirmButtonLabel: 'Delete',
                          });
                        }}
                      >
                        Delete
                      </MenuItem>
                    </KebabOptionsMenu>
                  </Td>
                </Tr>
              )))}
          </Tbody>
        </Table>
        <LinkButton ml={24} mt={6} isGreen noUnderline onClick={addTechnique}>
          + add casing type
        </LinkButton>
        {!!selectedCasingType
      && (
      <CasingTypeEditModal
        title={casingTypeModalTitle}
        onClose={() => {
          setSelectedCasingType(null);
        }}
        casingType={selectedCasingType}
      />
      )}
      </ExpandableCountSection>
    </VStack>
  );
};
