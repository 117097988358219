import {
  all, call, put, takeEvery,
} from 'redux-saga/effects';
import { CustomerContactActions } from '~/redux/customerContacts/actions';
import { showErrorToast, showToast } from '~/toast';
import { CustomerContact } from '~/types/customerContact';
import fetchJson from '~/utils/fetchJson';
import { performFetchSaga } from '~/utils/performFetchSaga';
import { takeEveryTracked } from '~/utils/performTrackedSaga';

const watchSave = takeEveryTracked(
  CustomerContactActions.save,
  function* handle(action) {
    const { contact, hideSuccessToast } = action.payload.data;
    try {
      const updated: CustomerContact = yield call(
        () => fetchJson(`/api/contacts/${contact.id}`, {
          method: 'PUT',
          body: contact,
        }),
      );
      yield put(CustomerContactActions.updated({ contacts: [updated] }));
      if (!hideSuccessToast) {
        showToast({
          status: 'success',
          title: 'Customer contact saved',
        });
      }
    } catch (error) {
      showErrorToast(error);
      throw error;
    }
  },
);

const watchFetch = takeEvery(
  CustomerContactActions.fetch,
  function* handle(action) {
    const id = action?.payload?.id;
    if (id) {
      yield performFetchSaga({
        key: `contact-${id}`,
        staleTime: 5000,
        * saga() {
          const contact: CustomerContact = yield call(() => fetchJson(`/api/contacts/${id}`));
          yield put(CustomerContactActions.updated({ contacts: [contact] }));
        },
      });
    }
  },
);

const watchFetchAll = takeEvery(
  CustomerContactActions.fetchAll,
  function* handle() {
    yield performFetchSaga({
      key: 'contact',
      staleTime: 5000,
      * saga() {
        const contacts: CustomerContact[] = yield call(() => fetchJson('/api/contacts'));
        yield put(CustomerContactActions.updated({ contacts }));
      },
    });
  },
);

const watchFetchForCustomer = takeEvery(
  CustomerContactActions.fetchForCustomer,
  function* handle(action) {
    const customerId = action?.payload?.customerId;
    if (customerId) {
      yield performFetchSaga({
        key: `contact-${customerId}`,
        staleTime: 5000,
        * saga() {
          const contacts: CustomerContact[] = yield call(() => fetchJson(`/api/customers/${customerId}/contacts`));
          yield put(CustomerContactActions.updated({ contacts }));
        },
      });
    }
  },
);

export default function* customerContactSaga() {
  yield all([
    watchSave,
    watchFetch,
    watchFetchAll,
    watchFetchForCustomer,
  ]);
}
