import { createReducer } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';
import { CustomerContactActions } from '~/redux/customerContacts/actions';
import { CustomerContact } from '~/types/customerContact';
import { Dictionary } from '~/types/helpers';

interface CustomerContactState {
  byId: Dictionary<CustomerContact>;
}

const customerContactReducer = createReducer<CustomerContactState>({
  byId: {},
}, (builder) => {
  builder.addCase(CustomerContactActions.updated, (state, action) => ({
    ...state,
    byId: {
      ...state.byId,
      ...keyBy(action.payload.contacts, (c) => c.id),
    },
  }));
});

export default customerContactReducer;
