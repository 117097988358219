import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';

const Card = (props: BoxProps) => (
  <Box
    bg="white"
    boxShadow="base"
    padding={8}
    borderRadius={0}
    {...props}
  />

);

export default Card;
