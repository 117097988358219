import {
  Avatar, SkeletonCircle,
} from '@chakra-ui/react';
import React from 'react';
import { selectStaffById } from '~/redux/staff/selectors';
import { useAppSelector } from '~/redux/store';

const StaffAvatar = ({ staffId, ...rest } : { staffId: string; }) => {
  const staff = useAppSelector((s) => selectStaffById(s, staffId));

  if (!staff) {
    return <SkeletonCircle size="12" />;
  }
  return (
    <Avatar bg="magnetize.text-1" color="white" name={staff.name} src={staff.profilePicture?.thumbUrl || staff.profilePicture?.url} {...rest} />
  );
};

export default StaffAvatar;
