import {
  InputGroup, Skeleton,
} from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import React, { useState } from 'react';
import { OptionTypeBase } from 'react-select';
import { triggerTagsFetch, updateTag } from '~/redux/tags/actions';
import SearchSelect, { SearchSelectProps } from '~/components/SearchSelect';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import { selectTagsByType } from '~/redux/tags/selectors';
import { useAppDispatch } from '~/redux/store';

interface CreatableTagSelectProps {
  type: string;
  tags: string[];
  setTags: (tags: string[]) => void;
}

const CreatableTagSelect = ({
  type,
  tags,
  setTags,
  ...props
} : CreatableTagSelectProps & SearchSelectProps<OptionTypeBase, true>) => {
  const [inputValue, setInputValue] = useState('');
  const dispatch = useAppDispatch();
  const { isLoading, data: tagData } = useTrackedFetch({
    key: 'tag',
    trigger: () => triggerTagsFetch({ force: false }),
    selector: (state) => selectTagsByType(state, type),
  });

  return (
    <InputGroup display="block">
      <Skeleton isLoaded={!isLoading}>
        <SearchSelect
          {...props}
          createable
          isMulti
          isLoading={isLoading}
          options={tagData
            ?.map((t) => ({ value: t.id, label: t.name }))}
          onCreateOption={(name : string) => {
            const newTagId = nanoid();
            dispatch(updateTag({
              id: newTagId,
              type,
              name,
            }));
            setTags([...tags, newTagId]);
          }}
          inputValue={inputValue}
          onInputChange={(v) => setInputValue(v)}
          onBlur={() => {
            if (inputValue) {
              const newTagId = nanoid();
              dispatch(updateTag({
                id: newTagId,
                type,
                name: inputValue,
              }));
              setTags([...tags, newTagId]);
            }
          }}
          value={tags.map((id) => (
            {
              value: id,
              label: tagData.find((t) => t.id === id)?.name,
            }))}
          onChange={(_, a) => {
            if (a.action === 'select-option') {
              setTags([...tags, a.option.value]);
            } else if (a.action === 'remove-value') {
              const filtered = tags.filter((id) => id !== a.removedValue.value);
              setTags(filtered);
            }
          }}
          hideSearchIcon
        // Hide the select dropdown when there are no options
          noOptionsMessage={() => null}
        />
      </Skeleton>
    </InputGroup>
  );
};

export default CreatableTagSelect;
