import React, { useEffect, useState } from 'react';
import {
  Button, HStack, Input, Modal, ModalBody, ModalCloseButton, Box,
  ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Text, useDisclosure,
} from '@chakra-ui/react';
import { round } from 'lodash';
import useTrackedAction from '~/hooks/useTrackedAction';
import { addInvoiceToJob } from '~/redux/jobs/actions';
import CreateProgressInvoiceModal from '~/pages/Job/components/invoices/CreateProgressInvoiceModal';
import QuotedVsInvoiced from '~/pages/Job/components/invoices/QuotedVsInvoiced';
import CentsEditor from '~/components/CentsEditor';
import { useQuoted, useQuotedLineItems } from '~/hooks/useQuoted';
import { useInvoiced } from '~/hooks/useInvoiced';
import LineItemTotals from '~/components/LineItemTotals';
import CurrencyText from '~/components/CurrencyText';

const CreatePercentageInvoiceModal = ({ jobId, onClose } : { jobId : string
  onClose: () => void; }) => {
  const progressInvoiceModalState = useDisclosure();
  const { quoted } = useQuoted({ jobId });
  const {
    lineItems, taxRate: quoteTaxRate, taxRateName: quoteTaxRateName,
  } = useQuotedLineItems({ jobId });
  const [taxRate, setTaxRate] = useState(quoteTaxRate);
  const [taxRateName, setTaxRateName] = useState(quoteTaxRateName);
  const { invoicedProportion, invoiced } = useInvoiced({ jobId });
  const maxPercentage = Math.floor(Math.max((1 - (invoicedProportion || 0)) * 100, 0) * 10) / 10;
  const [percentageText, setPercentageText] = useState(maxPercentage.toString());
  const [value, setValue] = useState((quoted * maxPercentage) / 100);

  useEffect(() => {
    setPercentageText(maxPercentage.toString());
    setValue((quoted * maxPercentage) / 100);
  }, [maxPercentage, quoted]);

  const proportionOfQuote = value / quoted;
  const { isLoading: isCreateInvoiceLoading, trigger: createInvoice } = useTrackedAction({
    trigger: () => addInvoiceToJob({
      jobId,
      proportionOfQuote,
    }),
    onSuccess: onClose,
  });

  const percentageTextValid = !Number.isNaN(parseFloat(percentageText));
  const percentageOverMax = percentageTextValid && parseFloat(percentageText) > maxPercentage;

  if (progressInvoiceModalState.isOpen) {
    return (
      <CreateProgressInvoiceModal
        jobId={jobId}
        onClose={onClose}
      />
    );
  }
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen
      onClose={onClose}
      size="3xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Create Part Invoice
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <QuotedVsInvoiced jobId={jobId} />
          <HStack justify="right" mb={5}>
            <Text color="magnetize.text-3" fontWeight="bold">To invoice</Text>
            <Input
              isInvalid={!percentageTextValid || percentageOverMax}
              errorBorderColor={percentageOverMax ? 'magnetize.support-warning' : 'red.300'}
              width="7rem"
              value={percentageText}
              autoFocus
              onFocus={(event) => event.target.select()}
              onChange={(e) => {
                setPercentageText(e.target.value);
                if (parseFloat(e.target.value)) {
                  setValue(((parseFloat(e.target.value) / 100) * quoted));
                }
              }}
            />
            <Text>%</Text>

            <CentsEditor
              variant="outline"
              width="10rem"
              isInvalid={percentageOverMax}
              errorBorderColor="magnetize.support-warning"
              numberInputFieldProps={{
                padding: 2,
              }}
              value={value}
              onChange={(newValue) => {
                if (newValue !== value) {
                  setValue(newValue);
                  setPercentageText(((newValue / quoted) * 100).toFixed(1));
                }
              }}
            />
          </HStack>
          <HStack mr={2}>
            <Box flex={2} />
            <Box flex={3} fontSize="lg">
              <LineItemTotals
                lineItems={lineItems.reduce((acc, li) => ({
                  ...acc,
                  [li.id]: {
                    ...li,
                    quantity: round(li.quantity * proportionOfQuote, 3),
                  },
                }), {})}
                taxRate={taxRate}
                taxRateName={taxRateName}
                onTaxRateChange={({ taxRateName: trn, taxRate: tr }) => {
                  setTaxRate(tr);
                  setTaxRateName(trn);
                }}
              />
            </Box>
          </HStack>
        </ModalBody>
        <ModalFooter>
          {percentageOverMax && (
          <Text fontWeight="bold" color="magnetize.support-warning">
            {'Over budget by '}
            <CurrencyText value={invoiced + value - quoted} />
          </Text>
          )}
          <Box flex={1} />
          <Button
            size="lg"
            colorScheme="brandDark"
            isLoading={isCreateInvoiceLoading}
            onClick={createInvoice}
            disabled={isCreateInvoiceLoading || !percentageTextValid}
          >
            { isCreateInvoiceLoading && <Spinner mr={5} />}
            NEXT
          </Button>
          <Button variant="ghost" onClick={onClose}>cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreatePercentageInvoiceModal;
