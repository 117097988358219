import {
  Box, Table, Tbody, Td, Th, Thead, Tr,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import {
  first, last, map, orderBy, values,
} from 'lodash';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import LinkButton from '~/components/LinkButton';
import { Location } from '~/components/Location';
import Routes from '~/pages/routes';
import { selectCurrentUser } from '~/redux/currentUser/selectors';
import { selectBoreholesByJobId, selectCPTByJobId } from '~/redux/liveGeo/selectors';
import { useAppSelector } from '~/redux/store';
import { Borehole, CPT } from '~/types/geotechnical';
import { formatDateRange } from '~/utils/calendarHelpers';
import eventStopper from '~/utils/eventStopper';

interface BoreholeTableRowProps {
  borehole: Borehole;
  jobId: string;
  coordinateSystem: string;
}

const BoreholeTableRow = ({ borehole, jobId, coordinateSystem } : BoreholeTableRowProps) => {
  const history = useHistory();
  const formattedDate = useMemo(() => {
    const startTimes = orderBy(
      map(values(borehole?.drillLogs), (dl) => dl.startTime),
    );
    const start = new Date(first(startTimes));
    const end = new Date(last(startTimes));

    return formatDateRange({
      start,
      end,
    });
  }, [borehole.drillLogs]);

  const openLog = () => {
    history.push(Routes.jobLiveGeo({ jobId, logId: borehole.id }));
  };

  return (
    <Tr
      _hover={{ bg: '#fafafa' }}
      cursor="pointer"
      role="group"
      onClick={openLog}
    >
      <Td>{formattedDate}</Td>
      <Td fontWeight="semibold">{borehole.name}</Td>
      <Td>
        <Location coordinateSystem={coordinateSystem} location={borehole.location} />
      </Td>
      <Td
        textAlign="right"
        {...eventStopper()}
      >
        <LinkButton
          mr={3}
          isGreen
          noUnderline
          onClick={openLog}
        >
          view logs
        </LinkButton>
      </Td>
    </Tr>
  );
};

interface CPTTableRowProps {
  cpt: CPT;
  jobId: string;
  coordinateSystem: string;
}

const CPTTableRow = ({ cpt, jobId, coordinateSystem } : CPTTableRowProps) => {
  const history = useHistory();

  const openLog = () => {
    history.push(Routes.jobLiveGeo({ jobId, logId: cpt.id }));
  };

  return (
    <Tr
      _hover={{ bg: '#fafafa' }}
      cursor="pointer"
      role="group"
      onClick={openLog}
    >
      <Td>{cpt.startTime ? format(cpt.startTime, 'dd MMM yyyy') : ''}</Td>
      <Td fontWeight="semibold">{cpt.name}</Td>
      <Td>
        <Location coordinateSystem={coordinateSystem} location={cpt.location} />
      </Td>
      <Td
        textAlign="right"
        {...eventStopper()}
      >
        <LinkButton
          mr={3}
          isGreen
          noUnderline
          onClick={openLog}
        >
          view logs
        </LinkButton>
      </Td>
    </Tr>
  );
};

interface JobLiveGeoLogsTableProps {
  jobId: string;
}

const JobLiveGeoLogsTable = ({ jobId } : JobLiveGeoLogsTableProps) => {
  const boreholes = useAppSelector((s) => selectBoreholesByJobId(s, jobId));
  const cpts = useAppSelector((s) => selectCPTByJobId(s, jobId));

  const coordinateSystem = useAppSelector(
    (state) => selectCurrentUser(state)?.tenant?.coordinateSystem,
  );

  return (
    <Box mx={2}>
      <Table>
        <Thead>
          <Tr>
            <Th pr="0">Date</Th>
            <Th pr="0">Name</Th>
            <Th w="200px" pr="0">Location</Th>
            <Th w="140px" pr="0" />
          </Tr>
        </Thead>
        <Tbody>
          {orderBy(boreholes, (bh) => bh.name).map(
            (bh) => (
              <BoreholeTableRow
                key={bh.id}
                borehole={bh}
                jobId={jobId}
                coordinateSystem={coordinateSystem}
              />
            ),
          )}
          {orderBy(cpts, (cpt) => cpt.name).map(
            (cpt) => (
              <CPTTableRow
                key={cpt.id}
                cpt={cpt}
                jobId={jobId}
                coordinateSystem={coordinateSystem}
              />
            ),
          )}
        </Tbody>
      </Table>
    </Box>
  );
};

export default JobLiveGeoLogsTable;
