import { SearchIcon } from '@chakra-ui/icons';
import {
  Flex, Input, InputGroup, InputLeftElement,
} from '@chakra-ui/react';
import Fuse from 'fuse.js';
import React, { useEffect, useState } from 'react';
import { PriceBundleViewModel } from '~/pages/PriceBook/BundleViewModel';
import CategoryFilter from '~/pages/PriceBook/PriceList/CategoryFilter';
import { Price } from '~/types/price';

const priceFuseSettings = {
  includeScore: true,
  ignoreLocation: true,
  threshold: 0.3,
  keys: [
    { name: 'name', weight: 1 },
    { name: 'code', weight: 1 },
    { name: 'description', weight: 1 },
    { name: 'category', weight: 1 },
  ],
};

const priceBundleFuseSettings = {
  includeScore: true,
  ignoreLocation: true,
  threshold: 0.3,
  keys: [
    { name: 'name', weight: 1 },
    { name: 'description', weight: 1 },
  ],
};

interface PriceFilterProps {
  prices: Price[];
  bundles: PriceBundleViewModel[];
  onChange: (visiblePrices: Price[], visibleBundles: PriceBundleViewModel[]) => void;
}

const PriceFilter = ({ prices, bundles, onChange } : PriceFilterProps) => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const [fusedPrices] = useState(new Fuse(prices, priceFuseSettings));
  const [visiblePrices, setVisiblePrices] = useState(prices);

  const [fusedPriceBundles] = useState(new Fuse(bundles, priceBundleFuseSettings));
  const [visiblePriceBundles, setVisiblePriceBundles] = useState(bundles);

  useEffect(() => {
    let filteredPrices = prices;
    let filteredBundles = bundles;

    if (selectedCategories.length > 0) {
      filteredPrices = filteredPrices.filter((p) => selectedCategories.includes(p.category));

      if (!selectedCategories.includes('bundle')) {
        filteredBundles = [];
      }
    }

    fusedPrices.setCollection(filteredPrices);
    setVisiblePrices(searchTerm
      ? fusedPrices.search(searchTerm).map((s) => s.item)
      : filteredPrices);

    fusedPriceBundles.setCollection(filteredBundles);
    setVisiblePriceBundles(searchTerm
      ? fusedPriceBundles.search(searchTerm).map((s) => s.item)
      : filteredBundles);
  }, [prices, bundles, searchTerm, selectedCategories]);

  useEffect(() => {
    onChange(visiblePrices, visiblePriceBundles);
  }, [visiblePrices, visiblePriceBundles]);

  return (
    <>
      <Flex flex="1" flexDirection="column">
        <CategoryFilter
          selected={selectedCategories}
          onSelectionChange={(s) => setSelectedCategories(s)}
        />
      </Flex>
      <InputGroup flex="2">
        <InputLeftElement>
          <SearchIcon color="gray.300" />
        </InputLeftElement>
        <Input
          variant="flushed"
          placeholder="Search for bundles or rates"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </InputGroup>
    </>
  );
};

export default PriceFilter;
