import {
  Text, Flex, Icon, FlexProps, Container,
} from '@chakra-ui/react';
import { Error } from '@material-ui/icons';
import React from 'react';

interface WarningLabelProps {
  message: string;
}

const WarningLabel = ({ message, ...props } : WarningLabelProps & FlexProps) => (
  <Container pl={0} ml={0} maxW="md">
    <Flex color="#E40707" {...props}>
      <Icon mr={2} as={Error} />
      <Text>{message}</Text>
    </Flex>
  </Container>
);

export default WarningLabel;
