import {
  Button,
  Modal, ModalBody, ModalCloseButton,
  ModalContent, ModalFooter, ModalHeader, ModalOverlay,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useAppDispatch } from '~/redux/store';
import { updatePiezoType } from '~/redux/piezoTypes/actions';
import { PiezoType } from '~/types/piezoType';
import { PiezoTypeForm } from './PiezoTypeForm';

export const PiezoTypeEditModal = ({
  piezoType,
  title = 'Edit piezo type',
  onClose = () => {},
}: {
  piezoType: Partial<PiezoType>,
  title: string,
  onClose: () => void
}) => {
  const dispatch = useAppDispatch();
  const [editedPiezoType, setEditedPiezoType] = useState(piezoType);

  const canSave = editedPiezoType.name;
  const onSaveClicked = () => {
    dispatch(updatePiezoType(editedPiezoType as PiezoType));
    onClose();
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen
      onClose={onClose}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {title}
        </ModalHeader>
        <ModalCloseButton tabIndex={-1} />
        <ModalBody>
          <PiezoTypeForm
            value={editedPiezoType}
            onChange={(dt) => {
              setEditedPiezoType({
                ...editedPiezoType,
                ...dt,
              });
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={!canSave}
            colorScheme="brandDark"
            onClick={onSaveClicked}
            size="lg"
          >
            Save
          </Button>
          <Button tabIndex={-1} variant="ghost" onClick={onClose}>cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
