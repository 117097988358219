import {
  Box,
  Button,
  Flex, FormControl, Heading, Image, Text, VStack,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import SwitchWithLabel from '~/components/SwitchWithLabel';
import NextButton from '~/pages/WelcomeModal/components/NextButton';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import WelcomeModalActions from '~/redux/welcomeModal/actions';
import { selectWelcomeModalState } from '~/redux/welcomeModal/selectors';

export const LiveGeoSettings = (
  { totalSteps, stepNumber }
  : { totalSteps: number, stepNumber: number },
) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const welcomeState = useAppSelector((state) => selectWelcomeModalState(state));

  return (
    <Flex
      h="100%"
      flex={1}
    >
      <Flex
        direction="column"
        flex={1}
      >
        <VStack
          p={20}
          alignItems="start"
          spacing={8}
          flex={1}
        >
          <Flex display="column">
            <Heading textTransform="none">
              Try the Magnetize Live Geo
              <Text as="sup" mr={2} fontSize="1.2rem">TM</Text>
              power up for free?
            </Heading>
            <Heading
              pl={1}
              pt={2}
              textTransform="none"
              size="sm"
            >
              Live Geo
              <Text as="sup" mr={1}>TM</Text>
              is a magnetize power up built for drilling and geotechnical companies.
            </Heading>
            <Image my={8} src="/images/live-geo/live-geo-marketing-card.png" alt="Live Geo" />
          </Flex>
          <FormControl>
            <SwitchWithLabel
              isChecked={welcomeState.enableLiveGeo}
              onChanged={(checked) => {
                dispatch(WelcomeModalActions.update({
                  update: {
                    enableLiveGeo: checked,
                  },
                }));
              }}
              label="Yes! Try Live Geo on my free trial"
            />
          </FormControl>
          <Box flex={1} />
          <FormControl>
            <NextButton />
          </FormControl>
          <Text color="magnetize.text-4" mt={8} width="100%" textAlign="center">
            {`step ${stepNumber} of ${totalSteps}`}
          </Text>
        </VStack>
      </Flex>
      <Flex
        flex={1}
        backgroundImage="url('/images/login-bg.jpg')"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        alignItems="center"
        justifyContent="center"
      >
        <Button
          position="absolute"
          top="20px"
          right="20px"
          isLoading={loading}
          isDisabled={loading}
          onClick={() => {
            setLoading(true);
            dispatch(WelcomeModalActions.complete());
          }}
        >
          Skip all
        </Button>
        <Image
          pt={20}
          src="/images/welcomeModal/live-geo.png"
        />
      </Flex>
    </Flex>
  );
};
