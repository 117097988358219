import {
  Box, Button, Heading, HStack, SkeletonText, Text,
} from '@chakra-ui/react';
import React from 'react';
import ConfirmationDialog from '~/components/ConfirmationDialog';
import { useModalManager } from '~/components/ModalManager';
import useTrackedAction from '~/hooks/useTrackedAction';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import BudgetPreviewEditor from '~/pages/Job/components/budgets/BudgetPreviewEditor';
import { addQuoteToJob } from '~/redux/jobs/actions';
import { QuoteActions } from '~/redux/quote/actions';
import {
  selectQuotesByJobId,
} from '~/redux/quote/selectors';
import JobPrimaryActionButton from '~/pages/Job/components/JobPrimaryActionButton';

const JobQuoteTab = ({ jobId }: { jobId: string, }) => {
  const modalManager = useModalManager();
  const { isLoading, data: quotes } = useTrackedFetch({
    key: `job-quotes-${jobId}`,
    trigger: () => QuoteActions.fetchForJob({ jobId }),
    selector: (state) => selectQuotesByJobId(state, jobId),
  });

  const { trigger: triggerAdd } = useTrackedAction({
    trigger: () => addQuoteToJob({
      jobId,
    }),
  });

  const { isLoading: isDiscardingQuote, trigger: triggerDiscard } = useTrackedAction({
    trigger: (quoteId: string) => QuoteActions.discardDraft({
      jobId,
      quoteId,
    }),
  });

  const draftQuote = quotes.find((q) => q.status === 'draft');

  if (isLoading) {
    return (
      <SkeletonText noOfLines={2} />
    );
  }

  if (!draftQuote) {
    return (
      <Box>
        <Heading as="h3" size="md" mb={8}>CREATE BUDGET</Heading>
        <Text mb={10} maxW={360}>
          Create a budget from your ratebook for this job, use the budget
          to communicate expected materials to staff in the field.
        </Text>
        <Button
          size="lg"
          alignItems="center"
          onClick={() => {
            triggerAdd();
          }}
        >
          <Text>
            CREATE BUDGET
          </Text>
        </Button>
      </Box>
    );
  }
  return (
    <Box mt={7} pb={20}>
      <BudgetPreviewEditor
        quote={draftQuote.draft}
        quoteId={draftQuote.id}
        jobId={draftQuote.jobId}
      />
      <HStack mt="50px">
        <Box flex={1} />
        <Button
          size="lg"
          variant="outline"
          onClick={() => {
            modalManager.open(ConfirmationDialog, {
              title: 'Discard budget',
              children: 'Are you sure you want to discard this budget',
              onConfirm: () => triggerDiscard(draftQuote.id),
              confirmButtonLabel: 'Discard',
              colorScheme: 'brandDark',
            });
          }}
          color="magnetize.support-warning"
          disabled={isDiscardingQuote}
        >
          discard budget
        </Button>
        <JobPrimaryActionButton jobId={jobId} />
      </HStack>
    </Box>
  );
};

export default JobQuoteTab;
