import { createReducer } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';
import CurrenciesActions from '~/redux/currencies/actions';
import { Currency } from '~/types/currency';
import { Dictionary } from '~/types/helpers';

interface CurrenciesState {
  entities: Dictionary<Currency>;
}

const priceReducer = createReducer<CurrenciesState>({
  entities: {},
}, (builder) => {
  builder.addCase(CurrenciesActions.loaded, (state, action) => {
    const { currencies } = action.payload;
    return ({
      entities: keyBy(currencies, (p) => p.shortCode),
    });
  });
});

export default priceReducer;
