import {
  Box,
  Center,
  Grid, Icon, Text, VStack,
} from '@chakra-ui/react';
import React from 'react';
import { IconType } from 'react-icons';

interface Option {
  label: string;
  isSelected: boolean;
  icon?: IconType;
}

const Tile = ({
  label, icon, isSelected, onClick,
} : { label:string, icon: IconType, isSelected: boolean, onClick: ()=>void }) => (
  <Box
    cursor="pointer"
    backgroundColor={isSelected ? '#f5faf6' : 'magnetize.ui-2'}
    borderRadius={2}
    boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.10)"
    borderWidth="1px"
    borderColor={isSelected ? 'magnetize.brand-5' : 'magnetize.ui-4'}
    width="100%"
    paddingTop="100%" /* 1:1 Aspect Ratio */
    position="relative" /* If you want text inside of it */
    onClick={onClick}
    _hover={{
      backgroundColor: '#f5faf6',
      borderColor: 'magnetize.brand-4',
    }}
    color="magnetize.text-1"
  >
    <Center position="absolute" top={0} bottom={0} left={0} right={0}>
      <VStack p={2}>
        {icon && <Icon fontSize="24px" as={icon} />}
        <Text fontWeight="bold" textAlign="center">
          {label}
        </Text>
      </VStack>
    </Center>
  </Box>
);

const TileSelector = ({
  options, onClick,
} : {
  options: Option[],
  onClick: (option: Option) => void;
}) => (
  <Grid
    templateRows="repeat(2)"
    templateColumns="repeat(3, 1fr)"
    gridGap={4}
  >
    {options.map((option) => (
      <Tile
        key={option.label}
        label={option.label}
        icon={option.icon}
        isSelected={option.isSelected}
        onClick={() => {
          onClick(option);
        }}
      />
    ))}
  </Grid>
);
export default TileSelector;
