import { showToast } from '~/toast';

// SheetJS has the basic functionality and made this pretty straightforward
// however they have separated formatting off into a 'pro' feature.
//
// exceljs seems like a more serious stab at solving the formatting problem
// around xlsx spreadsheets, and supports the xlsx and csv outputs, but we'd
// have to provide our own objectUrl + download behaviours.
//
// We're only scratching the surface with SheetJS though so this change
// over shouldn't be too difficult.
//
// https://www.npmjs.com/package/exceljs

interface DataSheet {
  /**
   * Name for this data sheet, if not provided will default to 'Sheet 1', 'Sheet 2' etc
   */
  name?: string;
  /**
   * Column headers for the spreadsheet
   */
  headers: string[];

  /**
   * An array-of-arrays (row major) containing the data.
   *
   * These can be plain JS values (strings, numbers, Date objects) and
   * SheetJS will usually handle the format type correctly.
   *
   * If you want to be fancy, you can also put a SheetJS cell object in
   * here too with custom formatting/formulae etc. but that's a bit of
   * an advanced use case!
   */
  data: any[][];

  /**
   * Column width is roughly in units of the width of a 0 character.
   * The units are crazy across different spreadsheet formats.
   */
  columnWidths?: number[];
}

interface ExportToSpreadsheetProps {
  sheets: DataSheet[] | Promise<DataSheet>[],
  name: string,
  format: 'xlsx' | 'csv',
}

export const exportToSpreadsheet = async ({
  sheets: dataSheetsOrPromises,
  name,
  format,
}: ExportToSpreadsheetProps) => {
  if (format === 'csv' && dataSheetsOrPromises.length > 1) {
    showToast({
      title: 'Export Error',
      description: 'CSV Exports only support single sheets',
      status: 'error',
    });
    return;
  }

  const XLSX = await import('xlsx');
  const allData = await Promise.all(dataSheetsOrPromises);
  const wb = XLSX.utils.book_new();
  const worksheets = allData.map((d) => XLSX.utils.aoa_to_sheet([d.headers, ...d.data]));

  for (let i = 0; i < worksheets.length; i += 1) {
    const ws = worksheets[i];
    const data = allData[i];
    // Auto filtering is useful for reports, so include the entire dataset
    ws['!autofilter'] = {
      ref: XLSX.utils.encode_range(
        { c: 0, r: 0 },
        // The cell range end is inclusive and 0-indexed, so these values are both '-1'
        // The data length is +1 (for headers) -1 (because it's inclusive range)
        { c: data.headers.length - 1, r: data.data.length + 50 },
      ),
    };

    if (data.columnWidths) {
      if (data.columnWidths.length !== data.headers.length) {
        const errorMessage = 'Export spreadsheet: headers and column widths arrays must have same length';
        showToast({
          title: 'Export Error',
          description: errorMessage,
          status: 'error',
        });
        throw new Error(errorMessage);
      }
      ws['!cols'] = data.columnWidths.map((w) => ({
        width: w,
      }));
    }

    XLSX.utils.book_append_sheet(wb, ws, data.name ?? `Sheet ${i + 1}`);
  }
  XLSX.writeFile(wb, `${name}.${format}`, { bookType: format, compression: true });
};
