import {
  Button, Icon,
} from '@chakra-ui/react';
import { ChevronRight } from '@material-ui/icons';
import React from 'react';
import CenteredFlex from '~/components/CenteredFlex';
import LinkButton from '~/components/LinkButton';

const RightIgnoreButtons = (
  {
    onIgnoreClick,
    onRightClick,
    color = 'rgba(255, 138, 0, 0.05)',
    isDisabled,
  } : {
    onIgnoreClick: () => void;
    onRightClick: () => void;
    color?: string;
    isDisabled: boolean;
  },
) => (
  <CenteredFlex direction="column">
    <Button
      isDisabled={isDisabled}
      mt={2}
      backgroundColor={color}
      _hover={{
        backgroundColor: color,
        borderColor: 'gray.300',
      }}
      borderWidth={1}
      borderColor="gray.200"
      width="40px"
      height={5}
      onClick={onRightClick}
    >
      <Icon color="black" as={ChevronRight} />
      <Icon color="black" as={ChevronRight} ml={-4} />
    </Button>
    <LinkButton
      isDisabled={isDisabled}
      onClick={onIgnoreClick}
    >
      ignore
    </LinkButton>
  </CenteredFlex>
);

export default RightIgnoreButtons;
