import { nanoid } from '@reduxjs/toolkit';
import { omit } from 'lodash';
import {
  all, call, put, select, takeEvery,
} from 'redux-saga/effects';
import StaffActions from '~/redux/staff/actions';
import { selectAllStaff } from '~/redux/staff/selectors';
import { showErrorToast, showToast } from '~/toast';
import { Staff } from '~/types/staff';
import fetchJson from '~/utils/fetchJson';
import { performFetchSaga } from '~/utils/performFetchSaga';

const watchFetch = takeEvery(
  StaffActions.fetch,
  function* handle() {
    yield performFetchSaga({
      key: 'staff',
      * saga() {
        const staff: Staff[] = yield call(
          fetchJson,
          '/api/staff',
        );

        yield put(StaffActions.updated({
          staff,
        }));
      },
    });
  },
);

const watchSave = takeEvery(
  StaffActions.save,
  function* handler(action) {
    const { staff } = action.payload;
    const id = staff.id ?? nanoid();

    const existingStaff: Staff[] = yield select(selectAllStaff);

    yield put(StaffActions.updated({
      staff: [staff],
    }));

    try {
      const nextStaff = yield call(() => fetchJson(
        `/api/staff/${id}`, {
          method: 'PUT',
          body: omit(staff, 'id'),
        },
      ));
      yield put(StaffActions.updated({
        staff: [nextStaff],
      }));
      showToast({
        status: 'success',
        title: staff.inviteEmailId !== nextStaff?.inviteEmailId ? `Staff invite sent to ${staff.email}` : 'Staff saved!',
      });
    } catch (error) {
      yield put(StaffActions.updated({
        staff: existingStaff,
      }));

      showToast({
        status: 'error',
        title: 'Saving staff failed',
        description: String(error),
      });
    }
  },
);

const watchDeactivate = takeEvery(
  StaffActions.deactivate,
  function* handler(action) {
    const { staffId, archive = false } = action.payload;
    const allStaff: Staff[] = yield select(selectAllStaff);
    try {
      const existing = allStaff.find((s) => s.id === staffId);
      if (existing) {
        yield put(StaffActions.updated({
          staff: [{
            ...existing,
            isDeleted: archive ? true : existing.isDeleted,
          }],
        }));
      }
      const updated: Staff = yield call(() => fetchJson(
        `/api/staff/${staffId}/deactivate`, {
          method: 'POST',
          body: {
            archive,
          },
        },
      ));
      yield put(StaffActions.updated({ staff: [updated] }));
    } catch (e) {
      yield put(StaffActions.updated({ staff: allStaff }));
      showErrorToast(e, {
        title: 'Cannot archive staff member',
      });
    }
  },
);

export default function* handleStaff() {
  yield all([
    watchFetch,
    watchSave,
    watchDeactivate,
  ]);
}
