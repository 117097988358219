import { Icon, Text } from '@chakra-ui/react';
import { map, orderBy, values } from 'lodash';
import React from 'react';

import { ReactComponent as Soil } from '~/assets/live_geo_soil_icon.svg';
import { CountSectionAccordionItem } from '~/components/CountSectionAccordionItem';
import ExpandableText from '~/components/ExpandableText';
import LogRow from '~/pages/LiveGeo/components/LogRow';
import { SoilLayer } from '~/types/geotechnical';
import { SoilType } from '~/types/soilType';

interface SoilLogsRowProps {
  soilLayers: { [id: string]: SoilLayer };
  endDepth?: number;
  soilTypes: { [key: string]: SoilType };
}

const SoilLogsRow = ({
  soilLayers, endDepth, soilTypes,
} : SoilLogsRowProps) => {
  const ordered = orderBy(values(soilLayers), (sl) => sl.startDepth);
  return (
    <CountSectionAccordionItem
      title="Soil Logs"
      icon={<Icon boxSize={8} as={Soil} />}
    >
      {map(ordered, (log, index) => (
        <LogRow key={`soil-layer-${index}`}>
          <Text fontWeight="semibold" whiteSpace="nowrap" minW="62px">
            {`${log.startDepth}m - ${(ordered[index + 1]?.startDepth || endDepth) ? `${(ordered[index + 1]?.startDepth || endDepth)}m` : ''}`}
          </Text>
          <Text>
            {soilTypes[log.soilTypes[0]]?.name}
          </Text>
          <ExpandableText gridColumn="3 /  span 2" fontStyle="italic">
            {log.note || ''}
          </ExpandableText>
        </LogRow>
      ))}
    </CountSectionAccordionItem>
  );
};

export default SoilLogsRow;
