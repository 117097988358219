import { Img, ImgProps } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import UploadedFile from '~/types/uploaded-file';

interface LogoProps extends ImgProps{
  logo: UploadedFile
}

const TenantLogo = ({ logo, ...props } : LogoProps) => {
  const [url, setUrl] = useState(logo?.url ?? '');

  useEffect(() => {
    if (!url) {
      setUrl(logo?.url);
    }
  }, [logo]);

  if (!url) {
    return <></>;
  }

  return (
    <Img
      src={url}
      flex="1"
      width="auto"
      height="auto"
      fit="scale-down"
      {...props}
    />
  );
};

export default TenantLogo;
