import { useCallbackRef } from '@chakra-ui/react';
import { debounce } from 'lodash';
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';

interface UseDebouncedStateProps<T> {
  value: T;
  dispatchUpdate: (value: T) => void;
  delay?: number;
}

/**
 * Provides local state for editing, with a debounced update callback.
 *
 * The required props for this component are a `value` and `dispatchUpdate`
 * callback. It returns a [value, setValue] pair so can be substituted in for
 * React.useState
 *
 * When the setter is called it will immediately update the local state and
 * trigger a debounced update callback. While there are pending changes, the
 * local state will be used, overriding whatever the `value` prop is.
 *
 * Once the dispatchUpdate call has been made, the component will revert to
 * using the controlled `value` prop, rather than the local state.
 *
 * When the component is unmounted it will flush changes immediately.
 *
 * In addition to the value/setter, this also returns a flush function, which
 * can be used to immediately dispatch any unsaved changes, useful for onBlur
 * editing:
 *
 *     const [value, setValue, flushValue] = useDebouncedState({ ... });
 *     <input ... onBlur={flushValue} />
 *
 * delay and maxWait are passed to lodash debounce.
 *
 * @param param0
 * @returns
 */
export default function useDebouncedState<T>({
  value, dispatchUpdate, delay = 500,
}: UseDebouncedStateProps<T>): [T, (value: T) => void, () => void] {
  const [localValue, setLocalValue] = useState(value);

  const hasUncommittedChanges = useRef(false);
  const lastValueRef = useRef<T>();
  const [shouldShowLocalValue, setShouldShowLocalValue] = useState(false);
  const flushChanges = useCallbackRef(() => {
    if (hasUncommittedChanges.current) {
      dispatchUpdate?.(lastValueRef.current);
      hasUncommittedChanges.current = false;
    }
  });

  // After changes have been dispatched we should continue showing the local
  // value until the state prop updates.
  useEffect(() => {
    if (shouldShowLocalValue && !hasUncommittedChanges.current) {
      setShouldShowLocalValue(false);
    }
  }, [value]);

  const triggerDebounce = useMemo(
    () => debounce(() => flushChanges(), delay),
    [delay],
  );

  const setValue = useCallback((updatedValue: T) => {
    hasUncommittedChanges.current = true;
    lastValueRef.current = updatedValue;
    setLocalValue(updatedValue);
    setShouldShowLocalValue(true);
    triggerDebounce();
  }, [triggerDebounce]);

  // If the component is unmounted we will immediately try to dispatch any
  // unsaved changes.
  useEffect(() => () => {
    if (hasUncommittedChanges.current) {
      flushChanges();
    }
  }, []);

  // If there are local changes, then use this value instead of the
  // `value` prop.
  const displayValue = shouldShowLocalValue ? localValue : value;
  return [displayValue, setValue, flushChanges];
}
