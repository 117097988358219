import React from 'react';
import {
  Box,
  Heading, Text,
} from '@chakra-ui/react';

import TimelineItem, { TimelineItemProps } from '~/components/TimelineItem';
import { JobTimelineQuoteAcceptedReverted } from '~/types/job';
import StatusBadge from '~/components/JobStatusBadge';
import { selectStaffName } from '~/redux/staff/selectors';
import { useAppSelector } from '~/redux/store';

export const TimelineQuoteAcceptedReverted = ({
  event, ...rest
}: {
  event: JobTimelineQuoteAcceptedReverted;
} & TimelineItemProps) => {
  const staffName = useAppSelector((s) => selectStaffName(s, event.staffId));
  return (
    <TimelineItem
      {...rest}
      event={event}
    >
      {event.status && <Box mb={2}><StatusBadge status={event.status} /></Box> }
      <Heading textTransform="none" size="sm" mb={1}>
        QUOTE REVERTED TO NOT ACCEPTED
      </Heading>
      <Text>
        {`QUOTE-${event.code} has been reverted to not accepted by ${staffName}`}
      </Text>

    </TimelineItem>
  );
};
