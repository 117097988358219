import {
  Table, Tbody, Td, Th, Thead, Tr, Text,
} from '@chakra-ui/react';
import { orderBy } from 'lodash';
import React, { useMemo } from 'react';
import CurrencyText from '~/components/CurrencyText';
import { BundleLineItem, LineItem } from '~/types/lineItem';
import { sortLineItems } from '~/utils/lineItemHelpers';

const QuoteViewLineItemTable = ({ quoteLineItems, currency, boldHeaderRule = false }: {
  quoteLineItems: { [id: string]: LineItem };
  currency: string;
  boldHeaderRule?: boolean;
}) => {
  const lineItems = useMemo(() => sortLineItems(quoteLineItems), [quoteLineItems]);

  const cellPadding = '0.5rem';
  const lineItemRow = (lineItem: LineItem) => {
    const isSection = !lineItem.priceId && !lineItem.quantity && !lineItem.unitPriceCents;
    const isBundle = lineItem.type === 'bundle';
    const bundle = isBundle ? lineItem as BundleLineItem : null;
    return (
      <React.Fragment key={lineItem.id}>
        <Tr className="quote-table__row" fontSize="md">
          <Td>
            <Text fontWeight={isSection || isBundle ? 'bold' : undefined}>{lineItem.name}</Text>
            {lineItem.description && <Text fontSize="md" fontStyle="italic" mt={1}>{lineItem.description}</Text>}
          </Td>
          <Td textAlign="right" paddingInline={cellPadding}>
            {(lineItem.unit ?? '').slice(0, 9)}
          </Td>
          <Td textAlign="right" paddingInlineStart={cellPadding}>{!isSection && lineItem.quantity}</Td>
          <Td textAlign="right" paddingInline={cellPadding}>{!isSection && !bundle?.expanded && (<CurrencyText value={lineItem.unitPriceCents} currency={currency} />)}</Td>
          <Td textAlign="right" paddingInline={cellPadding}>{!isSection && !bundle?.expanded && (<CurrencyText value={lineItem.quantity * lineItem.unitPriceCents} currency={currency} />)}</Td>
        </Tr>
        <>
          {isBundle && bundle.expanded && orderBy(Object.values(bundle.lineItems), (li) => li.order)
            .map((bundleLineItem) => (
              <Tr key={bundleLineItem.id} fontSize="md">
                <Td _first={{ paddingLeft: '3em' }}>
                  {bundleLineItem.name}
                  <Text fontStyle="italic">{bundleLineItem.description}</Text>
                </Td>
                <Td textAlign="right" paddingInline={cellPadding}>
                  {(lineItem.unit ?? '').slice(0, 7)}
                </Td>
                <Td textAlign="right" paddingInlineStart={cellPadding}>{bundleLineItem.quantity}</Td>
                <Td textAlign="right" paddingInline={cellPadding}><CurrencyText value={bundleLineItem.unitPriceCents} currency={currency} /></Td>
                <Td textAlign="right" paddingInline={cellPadding}><CurrencyText value={bundleLineItem.quantity * bundleLineItem.unitPriceCents} currency={currency} /></Td>
              </Tr>
            ))}
        </>
      </React.Fragment>
    );
  };

  return (
    <Table variant="simple" className="quote-table">
      <Thead className="quote-table__thead" borderBottom={boldHeaderRule ? '2px solid #171913' : undefined}>
        <Tr>
          <Th minW="40%" color="magnetize.text-1">Item</Th>
          <Th w="75px" textAlign="right" paddingInline={cellPadding} color="magnetize.text-1">Unit</Th>
          <Th w="70px" textAlign="right" paddingInlineStart={cellPadding} color="magnetize.text-1">Qty</Th>
          <Th w="90px" textAlign="right" paddingInline={cellPadding} color="magnetize.text-1">Unit Price</Th>
          <Th w="90px" textAlign="right" paddingInline={cellPadding} color="magnetize.text-1">Price</Th>
        </Tr>
      </Thead>
      <Tbody>
        {lineItems.map(lineItemRow)}
      </Tbody>
    </Table>
  );
};

export default QuoteViewLineItemTable;
