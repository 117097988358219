import { orderBy } from 'lodash';
import {
  all, call, put, takeEvery,
} from 'redux-saga/effects';
import { createInvoice, getJobInvoices, duplicateInvoice as duplicateInvoiceApi } from '~/api/invoiceApi';
import {
  InvoiceActions,
} from '~/redux/invoices/actions';
import {
  addInvoiceToJob, duplicateInvoice,
} from '~/redux/jobs/actions';
import { showErrorToast } from '~/toast';
import { Invoice } from '~/types/invoice';
import fetchJson from '~/utils/fetchJson';
import { performFetchSaga } from '~/utils/performFetchSaga';
import { takeEveryTracked } from '~/utils/performTrackedSaga';
import queueWork from '~/utils/queueWork';

const watchAddInvoiceToJob = takeEveryTracked(
  addInvoiceToJob,
  function* handle(action) {
    const {
      jobId, fromDate, toDate, proportionOfQuote,
    } = action.payload.data;
    // TODO Take new invoice and put in store - don't reload
    // need to do same for quotes
    // const invoice: Invoice =
    yield call(() => createInvoice(jobId, fromDate, toDate, proportionOfQuote));
    yield put(InvoiceActions.fetchForJob({ jobId, force: true }));
  },
);

const watchDuplicateInvoice = takeEveryTracked(
  duplicateInvoice,
  function* handle(action) {
    const {
      jobId, invoiceId,
    } = action.payload.data;
    // TODO Take new invoice and put in store - don't reload
    // need to do same for quotes
    // const invoice: Invoice =
    yield call(() => duplicateInvoiceApi(jobId, invoiceId));
    yield put(InvoiceActions.fetchForJob({ jobId, force: true }));
  },
);

const handleFetchInvoices = takeEvery(
  InvoiceActions.fetchForJob,
  function* handle(action) {
    const { jobId, force } = action.payload;
    yield performFetchSaga({
      key: `job-invoices-${jobId}`,
      force: force ?? false,
      staleTime: 10000,
      * saga() {
        const invoices: Invoice[] = yield call(getJobInvoices, jobId);
        yield put(InvoiceActions.invoicesUpdated({ invoices }));
        yield put(InvoiceActions.jobInvoicesUpdated({
          jobId,
          invoiceIds: orderBy(invoices, (inv) => inv.code, 'desc')
            .map((inv) => inv.id),
        }));
      },
    });
  },
);

const watchSaveInvoice = takeEvery(
  InvoiceActions.saveInvoice,
  function* handle(action) {
    const { invoiceId, jobId, invoice } = action.payload.data;

    yield put(InvoiceActions.invoicesUpdated({
      invoices: [invoice],
    }));
    yield queueWork(function* worker() {
      try {
        yield call(() => fetchJson(`/api/jobs/${jobId}/invoices/${invoiceId}`, {
          method: 'PUT',
          body: invoice,
        }));
      } catch (error) {
        showErrorToast(error);
      }
    });
  },
);

const watchDiscardInvoice = takeEvery(
  InvoiceActions.discardDraft,
  function* handle(action) {
    const { invoiceId, jobId } = action.payload.data;

    yield queueWork(function* worker() {
      try {
        yield call(() => fetchJson(`/api/jobs/${jobId}/invoices/${invoiceId}`, {
          method: 'DELETE',
        }));
      } catch (error) {
        showErrorToast(error);
      }
    });
  },
);

export default function* handleInvoices() {
  yield all([
    watchAddInvoiceToJob,
    watchDuplicateInvoice,
    handleFetchInvoices,
    watchSaveInvoice,
    watchDiscardInvoice,
  ]);
}
