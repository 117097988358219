import {
  Box, Container, Flex, Text,
} from '@chakra-ui/react';
import React from 'react';
import { ReactComponent as LogoFullWhite } from '~/assets/logo-full-light.svg';

const OneColumnBottomBrand = ({ children }: { children: React.ReactNode }) => (
  <>
    <Container maxWidth="1100px" alignItems="center">
      {children}
    </Container>
    <Box
      bg="brand.400"
      backgroundImage="url('/images/light-bg.png')"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      height="150px"
      mt={20}
      borderTopColor="black"
      display="flex"
      justifyContent="center"
      alignContent="center"
    >
      <Flex direction="column" justifyContent="center">
        <Text pb={2} textAlign="center" color="white">Powered by</Text>
        <LogoFullWhite
          width={150}
        />
      </Flex>
    </Box>
  </>
);

export default OneColumnBottomBrand;
