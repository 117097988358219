import { SearchIcon } from '@chakra-ui/icons';
import {
  Flex, GridItem, Heading,
  HStack, Input, InputGroup,
  InputLeftElement, SkeletonText,
  Spacer, Text, VStack,
} from '@chakra-ui/react';
import { debounce, orderBy } from 'lodash';
import Fuse from 'fuse.js';
import React, {
  useEffect, useMemo, useState,
} from 'react';
import { Redirect, Route, Switch } from 'react-router';
import Card from '~/components/Card';
import JobList from './JobList';
import { InvoiceJobList } from './InvoiceJobList';
import PageTabs from '~/components/PageTabs';
import StatusAlert from '~/components/StatusAlert';
import { formatJobCode } from '~/helpers/job';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import PageHeader from '~/layouts/PageHeader';
import PrimarySecondaryColumns from '~/layouts/PrimarySecondaryColumns';
import ExportJobsMenu from '~/pages/Jobs/ExportJobsMenu';
import { CustomerActions } from '~/redux/customers/actions';
import { selectCustomers } from '~/redux/customers/selectors';
import { JobActions } from '~/redux/jobs/actions';
import { selectJobs } from '~/redux/jobs/selectors';
import { ExtendedJob } from '~/types/job';
import formatShortAddress from '~/utils/formatShortAddress';
import { selectTagsByType } from '~/redux/tags/selectors';
import { triggerTagsFetch } from '~/redux/tags/actions';
import { JobTag } from '~/types/tag';
import Routes from '~/pages/routes';

const fuseSettings = {
  includeScore: true,
  ignoreLocation: true,
  threshold: 0.1,
  tokenize: true,
  keys: [
    { name: 'name', weight: 1 },
    { name: 'serviceType', weight: 1 },
    { name: 'jobNumber', weight: 2 },
    { name: 'status', weight: 0.2 },
    { name: 'address', weight: 1 },
    { name: 'customerName', weight: 1 },
    { name: 'jobTags.name', weight: 1 },
    { name: 'quoteNumbers', weight: 1 },
  ],
};

const JobsPage = () => {
  const {
    data: jobs, isLoading, isPartiallyComplete, isError,
  } = useTrackedFetch({
    key: 'jobs',
    selector: (state) => selectJobs(state),
    trigger: () => JobActions.fetchAll(),
  });
  const { data: tagData } = useTrackedFetch({
    key: 'tag',
    trigger: () => triggerTagsFetch({ force: false }),
    selector: (state) => selectTagsByType(state, 'job'),
  });
  const {
    data: customers, isLoading: isLoadingCustomers,
  } = useTrackedFetch({
    trigger: () => CustomerActions.fetchAll(),
    selector: selectCustomers,
    key: 'customers',
  });

  const [searchTerm, setSearchTerm] = useState('');

  const extendedJobs = useMemo(() => (
    orderBy(jobs?.map((job) => ({
      ...job,
      jobNumber: formatJobCode(job.code, 'JOB-'),
      address: formatShortAddress(job.siteAddress),
      customerName: customers?.find((c) => c.id === job.customerId)?.companyName,
      jobTags: (job.tags || [])
        .map((t) => tagData.find((td) => td.id === t))
        .filter((x) => x) as JobTag[],
    } as ExtendedJob)), (j) => j.code, 'desc')
  ), [jobs, customers]);

  const filteredJobs = useMemo(() => ({
    open: extendedJobs?.filter((j) => ['schedule', 'scheduled', 'inProgress'].includes(j.status)) ?? [],
    leads: extendedJobs?.filter((j) => ['draft', 'quote', 'quoted'].includes(j.status)) ?? [],
    onHold: extendedJobs?.filter((j) => ['onHold'].includes(j.status)) ?? [],
    invoice: extendedJobs?.filter((j) => ['complete', 'inProgress'].includes(j.status)) ?? [],
    closed: extendedJobs?.filter((j) => ['closed'].includes(j.status)) ?? [],
    archived: extendedJobs?.filter((j) => ['archived'].includes(j.status)) ?? [],
  }), [jobs]);

  const tabs = [
    {
      title: 'Open Jobs', count: filteredJobs.open.length, path: Routes.jobsOpenList({}),
    },
    {
      title: 'Leads', count: filteredJobs.leads.length, path: Routes.jobsLeadsList({}),
    },
    {
      title: 'On Hold', count: filteredJobs.onHold.length, path: Routes.jobsOnHoldList({}),
    },
    {
      title: 'Invoice', count: filteredJobs.invoice.length, path: Routes.jobsInvoiceList({}),
    },
    {
      title: 'Closed', path: Routes.jobsClosedList({}),
    },
    {
      title: 'Archived', path: Routes.jobsArchivedList({}),
    },
  ];
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [fusedJobs] = useState(new Fuse(extendedJobs, fuseSettings));
  // const [visibleJobs, setVisibleJobs] = useState(extendedJobs);
  const debouncedSearch = useMemo(
    () => debounce((search) => {
      setDebouncedSearchTerm(search);
    }, 1000),
    [],
  );

  useEffect(() => fusedJobs.setCollection(extendedJobs), [extendedJobs]);

  const visibleJobs = useMemo(() => (
    debouncedSearchTerm
      ? fusedJobs.search(debouncedSearchTerm).map((s) => s.item)
      : extendedJobs
  ), [debouncedSearchTerm]);

  // URL parameter getter function.
  function getQueryParam(paramName: string) {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get(paramName);
  }

  // URL parameter setter function.
  function updateURLParameter(paramName: string, paramValue: string | null) {
    const url = new URL(window.location.href);
    if (paramValue === null || paramValue === '') {
      // Remove parameter if value is null or empty
      url.searchParams.delete(paramName);
    } else {
      // Set (or update) parameter value
      url.searchParams.set(paramName, paramValue);
    }
    window.history.replaceState({ path: url.href }, '', url.href);
  }

  // Grab search params from URL.
  const urlSearchParams = getQueryParam('search');

  // If search param exist, set search param back into searchbar.
  useEffect(() => {
    if (urlSearchParams) {
      setSearchTerm(urlSearchParams);
      debouncedSearch(urlSearchParams);
    }
  }, [urlSearchParams]);

  // Update URL with search param.
  useEffect(() => {
    updateURLParameter('search', debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  return (
    <PrimarySecondaryColumns>
      <PageHeader>
        <Heading size="lg">Jobs</Heading>
      </PageHeader>
      <GridItem gridArea="primary-start / primary-start / secondary-end / secondary-end">
        <Flex direction="column">
          <PageTabs
            tabs={tabs}
            isLoading={isLoading}
            isDisabled={!!searchTerm}
          >
            <ExportJobsMenu />
          </PageTabs>
          <Card>
            <SkeletonText isLoaded={isPartiallyComplete} noOfLines={3}>
              {isPartiallyComplete && !isError && (
                <VStack align="stretch" spacing="8">
                  <HStack align="stretch">
                    <Flex flex={1}>
                      <InputGroup>
                        <InputLeftElement pointerEvents="none">
                          <SearchIcon color="gray.300" />
                        </InputLeftElement>
                        <Input
                          variant="flushed"
                          placeholder="Search"
                          value={searchTerm}
                          onChange={(e) => {
                            debouncedSearch(e.target.value);
                            setSearchTerm(e.target.value);
                          }}
                        />
                      </InputGroup>
                    </Flex>
                    <Spacer flex={1} />
                  </HStack>
                  <Switch>
                    <Route path={Routes.jobsOpenList({})}>
                      <JobList
                        jobs={debouncedSearchTerm ? visibleJobs : filteredJobs.open}
                        isLoadingCustomers={isLoadingCustomers}
                      />
                    </Route>
                    <Route path={Routes.jobsLeadsList({})}>
                      <JobList
                        jobs={debouncedSearchTerm ? visibleJobs : filteredJobs.leads}
                        isLoadingCustomers={isLoadingCustomers}
                      />
                    </Route>
                    <Route path={Routes.jobsOnHoldList({})}>
                      <JobList
                        jobs={debouncedSearchTerm ? visibleJobs : filteredJobs.onHold}
                        isLoadingCustomers={isLoadingCustomers}
                      />
                    </Route>
                    <Route path={Routes.jobsInvoiceList({})}>
                      <InvoiceJobList
                        jobs={debouncedSearchTerm ? visibleJobs : filteredJobs.invoice}
                        isLoadingCustomers={isLoadingCustomers}
                      />
                    </Route>
                    <Route path={Routes.jobsClosedList({})}>
                      <JobList
                        jobs={debouncedSearchTerm ? visibleJobs : filteredJobs.closed}
                        isLoadingCustomers={isLoadingCustomers}
                      />
                    </Route>
                    <Route path={Routes.jobsArchivedList({})}>
                      <JobList
                        jobs={debouncedSearchTerm ? visibleJobs : filteredJobs.archived}
                        isLoadingCustomers={isLoadingCustomers}
                      />
                    </Route>
                    <Route path="*" render={() => <Redirect to={Routes.jobsOpenList({ })} />} />
                  </Switch>
                </VStack>
              )}
              {!isLoading && !isError && jobs?.length === 0 && (
              <Text mt={4}>
                There is no jobs to display.
              </Text>
              )}
              {isError && (
              <StatusAlert title="Failed to fetch jobs" />
              )}
            </SkeletonText>
          </Card>
        </Flex>
      </GridItem>
    </PrimarySecondaryColumns>
  );
};

export default JobsPage;
