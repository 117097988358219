import { createAction } from '@reduxjs/toolkit';
import { MagnetizePrefs } from '~/types/prefs';

const PrefsActions = {
  set: createAction <{ prefs: Partial<MagnetizePrefs> }>('prefs/set'),
  dismissAlert: createAction <{ id: string }>('prefs/dismiss-alert'),
  dismissTrialBanner: createAction <{ id: string }>('prefs/dismiss-trial-banner'),
};

export default PrefsActions;
