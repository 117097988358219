import {
  all,
  call, put, select, takeEvery,
} from 'redux-saga/effects';
import { getCustomerQuote, postAcceptCustomerQuote, postQueryCustomerQuote } from '~/api/customerQuoteApi';
import {
  acceptQuote,
  customerQuoteFetchError,
  customerQuoteFetchStart,
  customerQuoteFetchSuccess, queryQuote, triggerCustomerQuoteFetch,
} from '~/redux/customerQuotes/actions';
import { selectCustomerQuoteFetchState } from '~/redux/customerQuotes/selectors';
import { CustomerFacingQuote } from '~/types/quote';
import { takeEveryTracked } from '~/utils/performTrackedSaga';

const handleFetchQuote = takeEvery(
  triggerCustomerQuoteFetch,
  function* handle(action) {
    const { token } = action.payload;
    // TODO Get correct type here...
    const quoteState = yield select(
      (state) => selectCustomerQuoteFetchState(state, token),
    );
    if (quoteState?.status !== 'loading') {
      yield put(customerQuoteFetchStart({ token }));
      try {
        const quote: CustomerFacingQuote = yield call(getCustomerQuote, token);
        yield put(customerQuoteFetchSuccess({ token, data: quote }));
      } catch (error) {
        yield put(customerQuoteFetchError({ token, errorMessage: String(error) }));
      }
    }
  },
);

const handleQueryQuote = takeEveryTracked(
  queryQuote,
  function* handle(action) {
    const { token, comment } = action.payload.data;
    const nextQuote = yield call(() => postQueryCustomerQuote(token, comment));
    yield put(customerQuoteFetchSuccess({ token, data: nextQuote }));
  },
);

const watchAcceptQuote = takeEveryTracked(
  acceptQuote,
  function* handle(action) {
    const { token, comment } = action.payload.data;
    const nextQuote = yield call(() => postAcceptCustomerQuote(token, comment));
    yield put(customerQuoteFetchSuccess({ token, data: nextQuote }));
  },
);

export default function* customerQuoteSaga() {
  yield all([
    handleFetchQuote,
    handleQueryQuote,
    watchAcceptQuote,
  ]);
}
