import React from 'react';
import BudgetEditor from '~/pages/Job/components/budgets/BudgetEditor';
import { QuoteEdit } from '~/types/quote';

const BudgetPreviewEditor = (
  { quote, jobId, quoteId }:
  {
    quoteId: string;
    jobId: string;
    quote: QuoteEdit; },
) => (
  <>
    <BudgetEditor quote={quote} jobId={jobId} quoteId={quoteId} />
  </>
);

export default BudgetPreviewEditor;
