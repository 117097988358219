import { nanoid } from '@reduxjs/toolkit';
import React from 'react';
import SearchSelect from '~/components/SearchSelect';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import { CustomerContactActions } from '~/redux/customerContacts/actions';
import { selectAllCustomerContacts, selectCustomerContactByCustomerId } from '~/redux/customerContacts/selectors';
import { CustomerContact } from '~/types/customerContact';

interface CustomerContactSelectProps {
  value: string;
  customerId?: string;
  onChange: (contact: CustomerContact) => void;
  onCreateCustomerContact: (name: string, id: string) => void;
}

const CustomerContactSelect = (
  {
    value, onChange, onCreateCustomerContact, customerId,
  }: CustomerContactSelectProps,
) => {
  const {
    data: customerContacts, isLoading,
  } = useTrackedFetch({
    trigger: () => (customerId
      ? CustomerContactActions.fetchForCustomer({ customerId })
      : CustomerContactActions.fetchAll()),
    selector: (s) => (
      customerId
        ? selectCustomerContactByCustomerId(s, customerId)
        : selectAllCustomerContacts(s)),
    key: customerId ? `contact-${customerId}` : 'contact',
  });

  const selectedOption = (customerContacts ?? []).find((c) => c.id === value);
  return (
    <SearchSelect
      isLoading={isLoading}
      createable
      isClearable
      placeholder="Add or create contact"
      value={selectedOption}
      options={(customerContacts ?? [])}
      onChange={onChange}
      onCreateOption={(name) => {
        const id = nanoid();
        onCreateCustomerContact(name, id);
      }}
      getOptionLabel={(contact) => contact.name}
      createOptionText="Create new contact"
      isValidNewOption={() => true}
    />
  );
};

export default CustomerContactSelect;
