import {
  Button,
  Modal, ModalBody, ModalCloseButton,
  ModalContent, ModalFooter, ModalHeader, ModalOverlay,

  Box, FormControl, FormLabel, Grid, Input, Stack, Switch, HStack, Text,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import StaffActions from '~/redux/staff/actions';
import { useAppDispatch } from '~/redux/store';
import { Staff, StaffCognitoGroup } from '~/types/staff';

import CreatableTagSelect from '~/components/CreatableTagSelect';
import ProfileFileUpload from '~/components/FileUpload/ProfileFileUpload';
import RateSearchSelect from '~/components/RateSearchSelect';
import RoleSearchSelect from '~/components/RoleSearchSelect';

import StaffAndEquipmentSelect from '~/components/StaffAndEquipmentSelect';
import StaffLoginInfo from '~/pages/Staff/StaffLoginInfo';

const StaffEditModal = (
  {
    staff, isNewStaff, onClose = () => {},
  }: {
    staff: Staff,
    isNewStaff: boolean,
    onClose: () => void,
  },
) => {
  const dispatch = useAppDispatch();

  const [name, setName] = useState(staff.name ?? '');
  const [jobTitle, setJobTitle] = useState(staff.jobTitle ?? '');
  const [email, setEmail] = useState(staff.email ?? '');

  const [phone, setPhone] = useState(staff.phone ?? '');
  const [isAvailable, setIsAvailable] = useState(staff.isAvailable);
  const [allowCreateJob, setAllowCreateJob] = useState(staff.allowCreateJob);
  const [cognitoGroup, setCognitoGroup] = useState(staff.cognitoGroup);
  const [tags, setTags] = useState(staff.tags ?? []);
  const [priceId, setPriceId] = useState(staff.priceId);
  const [reportsToId, setReportsToId] = useState(staff.reportsToId);

  const [profilePicture, setProfilePicture] = useState(staff.profilePicture);
  const [emailTouched, setEmailTouched] = useState(false);

  const emailIsValid = yup.string().email().required().isValidSync(email);
  const isValid = !!name && emailIsValid && cognitoGroup !== undefined;
  const emailModified = staff.email !== email && emailTouched;

  const nameInput = useRef(null);
  useEffect(() => {
    nameInput.current?.focus();
  }, []);

  const onSaveClicked = () => {
    dispatch(StaffActions.save({
      staff: {
        ...staff,
        name,
        jobTitle,
        email,
        phone,
        isAvailable,
        hasCognitoUserAccount: !!cognitoGroup,
        cognitoGroup,
        tags,
        priceId,
        profilePicture,
        allowCreateJob,
        reportsToId,
      },
    }));
    onClose();
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen
      onClose={onClose}
      size="2xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader boxShadow="0 0.5rem 0.5rem -0.5rem rgba(227,225,225)">
          {isNewStaff ? 'Add new staff' : 'Edit staff'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody mt={4}>
          <Grid gap={8}>
            <Box
              position="relative"
              paddingRight="145px"
            >
              <FormControl isRequired>
                <FormLabel>Staff Details</FormLabel>
                <Stack spacing={3}>
                  <Input
                    id="staff-name"
                    placeholder="What's their full name?"
                    ref={nameInput}
                    value={name}
                    onChange={(e) => { setName(e.target.value); }}
                  />
                  <Input
                    id="staff-job-title"
                    placeholder="What's their job?"
                    value={jobTitle}
                    onChange={(e) => { setJobTitle(e.target.value); }}
                  />
                </Stack>
              </FormControl>
              <Box position="absolute" top={0} right={0}>
                <ProfileFileUpload
                  profilePicture={profilePicture}
                  size={130}
                  onFileUploaded={(file) => {
                    setProfilePicture(file);
                  }}
                  onFileRemoved={() => {
                    setProfilePicture(null);
                  }}
                />
              </Box>
            </Box>

            <FormControl isRequired>
              <FormLabel>Magnetize Permissions</FormLabel>
              <StaffLoginInfo
                staffId={staff.id}
                emailModified={emailModified}
                cognitoGroup={cognitoGroup}
              />
              <Stack spacing={3}>
                <RoleSearchSelect
                  placeholder="What kind of access do they need?"
                  value={cognitoGroup}
                  onChange={setCognitoGroup}
                />
                <HStack>
                  <FormControl isInvalid={emailModified && !emailIsValid} flex={2}>
                    <Input
                      id="staff-email"
                      placeholder="Email"
                      value={email}
                      onFocus={() => setEmailTouched(true)}
                      onChange={(e) => { setEmail(e.target.value); }}
                    />
                  </FormControl>
                  <Input
                    flex={1}
                    id="staff-phone"
                    placeholder="Phone number"
                    value={phone}
                    onChange={(e) => { setPhone(e.target.value); }}
                  />

                </HStack>
              </Stack>
            </FormControl>
            <FormControl>
              <FormLabel>Job Scheduling</FormLabel>
              <Stack spacing={3}>
                <Box mt={3}>
                  <FormControl display="flex" alignItems="center">
                    <Switch
                      name="is-required"
                      size="lg"
                      isChecked={isAvailable}
                      onChange={(e) => setIsAvailable(e.target.checked)}
                    />
                    <FormLabel
                      mb="0"
                      ml={2}
                      cursor="pointer"
                      textTransform="none"
                      onClick={() => setIsAvailable(!isAvailable)}
                    >
                      Available for scheduling on jobs
                    </FormLabel>
                  </FormControl>
                </Box>
                {isAvailable && (
                <Stack spacing={3}>
                  <CreatableTagSelect
                    type="staff"
                    tags={tags}
                    setTags={setTags}
                    placeholder="What teams do they belong to? e.g. field team"
                  />
                  {cognitoGroup === StaffCognitoGroup.Staff && (
                  <StaffAndEquipmentSelect
                    limitTo="admin"
                    value={reportsToId}
                    placeholder="Who do they report to?"
                    onChange={(x) => setReportsToId(x.id)}
                  />
                  )}
                </Stack>
                )}
              </Stack>
            </FormControl>

            {cognitoGroup === StaffCognitoGroup.Staff && (
            <FormControl>
              <Stack spacing={3}>
                <FormLabel>Job Permissions</FormLabel>
                <Box>
                  <FormControl display="flex" alignItems="center">
                    <Switch
                      name="is-required"
                      size="lg"
                      isChecked={allowCreateJob}
                      onChange={(e) => setAllowCreateJob(e.target.checked)}
                    />
                    <FormLabel
                      mb="0"
                      ml={2}
                      cursor="pointer"
                      textTransform="none"
                      onClick={() => setAllowCreateJob(!allowCreateJob)}
                    >
                      Can log jobs from the Magnetize mobile app
                    </FormLabel>
                  </FormControl>
                </Box>
                <Text>
                  When staff users create a job in Magnetize,
                  it will appear in a &quot;NEW&quot; state, and the manager
                  specified above will be alerted via email.

                </Text>
              </Stack>
            </FormControl>
            )}
            {isAvailable && (
            <FormControl>
              <FormLabel>Billing Rate</FormLabel>
              <RateSearchSelect
                category="labour"
                value={priceId}
                onChange={(pId) => setPriceId(pId)}
                menuPlacement="top"
                maxMenuHeight={200}
              />
            </FormControl>
            )}
          </Grid>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="brandDark"
            disabled={!isValid}
            onClick={onSaveClicked}
          >
            Save
          </Button>
          <Button variant="ghost" onClick={onClose}>cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default StaffEditModal;
