import {
  Button,
  Modal, ModalBody, ModalCloseButton,
  ModalContent, ModalFooter, ModalHeader, ModalOverlay,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useAppDispatch } from '~/redux/store';
import TaxRateActions from '~/redux/taxRates/actions';
import { TaxRate } from '~/types/taxRate';
import TaxRateForm from './TaxRateForm';

interface PriceEditModalProps {
  taxRate: TaxRate,
  title: string,
  onClose: () => void,
}

const TaxRateEditModal = ({
  taxRate,
  title = 'Edit rate',
  onClose = () => {},
}: PriceEditModalProps) => {
  const dispatch = useAppDispatch();
  const [editedTaxRate, setEditedTaxRate] = useState(taxRate);

  const onSaveClicked = () => {
    dispatch(TaxRateActions.save({
      taxRate: editedTaxRate,
    }));
    onClose();
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen
      onClose={onClose}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {title}
        </ModalHeader>
        <ModalCloseButton tabIndex={-1} />
        <ModalBody>
          <TaxRateForm
            value={editedTaxRate}
            onChange={(r) => {
              setEditedTaxRate({
                ...editedTaxRate,
                ...r,
              });
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="brandDark"
            onClick={onSaveClicked}
          >
            Save
          </Button>
          <Button tabIndex={-1} variant="ghost" onClick={onClose}>cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TaxRateEditModal;
