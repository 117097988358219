import {
  Box,
  Heading, MenuItem, Skeleton, Table, Tbody, Td, Text, Th, Thead, Tr,
} from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import React, { useState } from 'react';
import { orderBy } from 'lodash';
import LinkButton from '~/components/LinkButton';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import JobTagEditModal from '~/components/JobTagEditModal';
import { deleteTag, triggerTagsFetch } from '~/redux/tags/actions';
import { KebabOptionsMenu } from '~/components/KebabOptionsMenu';
import eventStopper from '~/utils/eventStopper';
import ConfirmationDialog from '~/components/ConfirmationDialog';
import JobTagComponent from '~/components/JobTag';
import { useModalManager } from '~/components/ModalManager';
import { useAppDispatch } from '~/redux/store';
import { JobTag } from '~/types/tag';
import { selectTagsByType } from '~/redux/tags/selectors';

const JobTagsTable = () => {
  const dispatch = useAppDispatch();
  const modalManager = useModalManager();
  const { data: jobTags, isLoading } = useTrackedFetch<JobTag[]>({
    key: 'jobTags',
    selector: (state) => selectTagsByType(state, 'job') as JobTag[],
    trigger: () => triggerTagsFetch({ force: false }),
    equalityMode: 'deep',
  });

  const [selectedJobTag, setSelectedJobTag] = useState<Partial<JobTag>>(null);
  const [jobTagModalTitle, setJobTagModalTitle] = useState<string>();

  const addJobTag = () => {
    setSelectedJobTag({
      id: nanoid(),
      type: 'job',
    });
    setJobTagModalTitle('Add Job Tag');
  };

  const editJobTag = (jobTag: JobTag) => {
    setSelectedJobTag(jobTag);
    setJobTagModalTitle('Edit job tag');
  };

  const deleteJobTag = (jobTagId) => {
    dispatch(deleteTag({
      id: jobTagId,
    }));
  };

  return (
    <Box>
      <Heading mt={6} pb={6} mb={6} size="sm" borderBottomColor="magnetize.text-5" borderBottomWidth={2}>Job Tags</Heading>
      <Table size="md">
        <Thead>
          <Tr>
            <Th width={150}>Name</Th>
            <Th>Description</Th>
            <Th width="50px" />
          </Tr>
        </Thead>
        <Tbody>
          {isLoading ? (
            <Tr>
              <Td><Skeleton height={8} /></Td>
              <Td><Skeleton height={8} /></Td>
              <Td />
            </Tr>
          )
            : (orderBy(jobTags, (t) => t.name).map((tr) => (
              <Tr
                key={tr.id}
                _hover={{ bg: '#fafafa', cursor: 'pointer' }}
                transition="color 0.1s ease-out"
                role="group"
                onClick={() => editJobTag(tr)}
              >
                <Td>
                  <JobTagComponent id={tr.id} />
                </Td>
                <Td>
                  <Text>
                    {tr.description}
                  </Text>
                </Td>

                <Td textAlign="end" {...eventStopper()}>
                  <KebabOptionsMenu>
                    <MenuItem onClick={() => editJobTag(tr)}>Edit</MenuItem>
                    <MenuItem
                      onClick={() => {
                        modalManager.open(ConfirmationDialog, {
                          title: 'Delete Job Tag',
                          children: 'This will remove the job tag from Magnetize',
                          onConfirm: () => deleteJobTag(tr.id),
                          confirmButtonLabel: 'Delete',
                        });
                      }}
                    >
                      Delete
                    </MenuItem>
                  </KebabOptionsMenu>
                </Td>
              </Tr>
            )))}
        </Tbody>
      </Table>
      <LinkButton mt={6} isGreen noUnderline onClick={addJobTag}>+ add job tag</LinkButton>
      {!!selectedJobTag
      && (
      <JobTagEditModal
        title={jobTagModalTitle}
        onClose={() => {
          setSelectedJobTag(null);
        }}
        jobTag={selectedJobTag}
      />
      )}
    </Box>
  );
};

export default JobTagsTable;
