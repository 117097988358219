import {
  all, call, put, select, takeEvery,
} from 'redux-saga/effects';
import { CustomerActions } from '~/redux/customers/actions';
import { fetchInvalidated } from '~/redux/fetch/actions';
import { InvoiceActions } from '~/redux/invoices/actions';
import { selectJobById } from '~/redux/jobs/selectors';
import { selectCustomerById } from '~/redux/customers/selectors';
import { TrackedActions } from '~/redux/tracked/actions';
import { XeroActions } from '~/redux/xero/actions';
import { showErrorToast, showToast } from '~/toast';
import { Invoice } from '~/types/invoice';
import {
  XeroAccount, XeroContact, XeroInvoice, XeroStatus,
} from '~/types/xero';
import fetchJson from '~/utils/fetchJson';
import { performFetchSaga } from '~/utils/performFetchSaga';
import { takeEveryTracked } from '~/utils/performTrackedSaga';
import SubscriptionActions from '~/redux/subscription/actions';
import { PowerUpId } from '~/types/subscription';

function* invalidateXeroState() {
  yield put(fetchInvalidated({
    at: Date.now(),
    keys: [
      'xero-status',
      'xero-accounts',
      'xero-contacts',
    ],
  }));
}

function* fetchXeroStatus({ force = false } = {}) {
  yield performFetchSaga({
    key: 'xero-status',
    force,
    staleTime: 5000,
    * saga() {
      const status: XeroStatus = yield call(() => fetchJson<XeroStatus>('/api/xero/status'));
      yield put(XeroActions.statusUpdated({ status }));
    },
  });
}

const watchXeroConnect = takeEveryTracked(
  XeroActions.connect,
  function* handler(action) {
    try {
      const params = new URL(action.payload.data.callbackUrl).searchParams;
      if (params.has('error')) {
        showToast({
          title: 'There was an error connecting to Xero',
          status: 'error',
        });
        return;
      }

      yield call(() => fetchJson('/api/xero/connect', {
        method: 'POST',
        body: {
          callbackUrl: action.payload.data.callbackUrl,
        },
      }));
      yield put(SubscriptionActions.powerUpAdded({ powerUpId: PowerUpId.xero }));
      yield call(fetchXeroStatus, { force: true });
    } finally {
      yield put(XeroActions.finishedConnecting());
    }
  },
);

const watchXeroDisconnect = takeEveryTracked(
  XeroActions.disconnect,
  function* handler() {
    yield call(invalidateXeroState);
    yield call(() => fetchJson('/api/xero/disconnect', { method: 'POST' }));
  },
);

const watchXeroRefreshStatus = takeEvery(
  XeroActions.refreshStatus,
  function* handler() {
    yield call(fetchXeroStatus);
  },
);

const watchFetchAccounts = takeEvery(
  XeroActions.fetchAccounts,
  function* handler() {
    yield performFetchSaga({
      key: 'xero-accounts',
      staleTime: 5000,
      * saga() {
        const accounts: XeroAccount[] = yield call(() => fetchJson('/api/xero/accounts'));
        yield put(XeroActions.accountsUpdated({ accounts }));
      },
    });
  },
);

const watchFetchContacts = takeEvery(
  XeroActions.fetchContacts,
  function* handler() {
    yield performFetchSaga({
      key: 'xero-contacts',
      staleTime: 5000,
      * saga() {
        const contacts: XeroContact[] = yield call(() => fetchJson('/api/xero/contacts'));
        yield put(XeroActions.contactsUpdated({ contacts }));
      },
    });
  },
);

interface CreateInvoiceResponseBody {
  magnetizeInvoice: Invoice;
  xeroInvoice: XeroInvoice;
}
const watchCreateInvoice = takeEveryTracked(
  XeroActions.createInvoice,
  function* handler(action) {
    const { xeroContactId, invoiceId, jobId } = action.payload.data;
    const job = yield select((state) => selectJobById(state, jobId));
    const customer = job.customerId
      ? yield select((state) => selectCustomerById(state, job.customerId))
      : null;
    const { magnetizeInvoice, xeroInvoice }: CreateInvoiceResponseBody = yield call(
      () => fetchJson('/api/xero/create-invoice', {
        method: 'POST',
        body: {
          jobId,
          xeroContactId,
          invoiceId,
        },
      }),
    );

    // update the customer so that it has the xero id for betterer matches in the future
    if (customer && customer.xeroId !== xeroContactId) {
      yield put(CustomerActions.save({ customer: { ...customer, xeroId: xeroContactId } }));
    }
    yield put(XeroActions.invoicesUpdated({ invoices: [xeroInvoice] }));
    yield put(InvoiceActions.invoicesUpdated({
      invoices: [magnetizeInvoice],
    }));
  },
);

const watchCreateContact = takeEveryTracked(
  XeroActions.createContact,
  function* handler(action) {
    try {
      const contact: XeroContact = yield call(() => fetchJson('/api/xero/create-contact', {
        method: 'POST',
        body: action.payload.data,
      }));

      yield put(XeroActions.contactsUpdated({ contacts: [contact] }));
      yield put(TrackedActions.setResult({
        trackId: action.payload.id,
        result: contact,
      }));
    } catch (e) {
      showErrorToast(e);
      throw e;
    }
  },
);

const watchChangeXeroOrg = takeEveryTracked(
  XeroActions.setOrganisation,
  function* handle(action) {
    try {
      yield call(() => fetchJson('/api/xero/set-organisation', {
        method: 'POST',
        body: {
          xeroTenantId: action.payload.data.xeroTenantId,
        },
      }));

      yield invalidateXeroState();
      yield put(XeroActions.refreshStatus());
    } catch (error) {
      showErrorToast(error);
    }
  },
);

const watchChangeInvoiceAccount = takeEveryTracked(
  XeroActions.setInvoiceAccounts,
  function* handle(action) {
    const accounts = action.payload.data;
    const mapAccount = (a: XeroAccount) => {
      if (!a) {
        return null;
      }
      return {
        accountID: a.accountID,
        code: a.code,
        name: a.name,
      };
    };
    try {
      yield call(() => fetchJson('/api/xero/set-invoice-accounts', {
        method: 'POST',
        body: {
          labour: mapAccount(accounts.labour),
          equipment: mapAccount(accounts.equipment),
          consumable: mapAccount(accounts.consumable),
          fee: mapAccount(accounts.fee),
        },
      }));

      yield invalidateXeroState();
      yield put(XeroActions.refreshStatus());
    } catch (error) {
      showErrorToast(error);
    }
  },
);

export default function* handleXero() {
  yield all([
    watchXeroConnect,
    watchXeroDisconnect,
    watchXeroRefreshStatus,
    watchFetchAccounts,
    watchFetchContacts,
    watchCreateInvoice,
    watchCreateContact,
    watchChangeXeroOrg,
    watchChangeInvoiceAccount,
  ]);
}
