import { nanoid } from '@reduxjs/toolkit';
import { isSameDay } from 'date-fns';
import { keyBy } from 'lodash';
import {
  all, call, put, select, takeEvery,
} from 'redux-saga/effects';
import { putScheduleEvent } from '~/api/scheduleApi';
import { updateJob } from '~/redux/jobs/actions';
import { selectJobById } from '~/redux/jobs/selectors';
import { RootState } from '~/redux/reducer';
import { ScheduleEventActions } from '~/redux/schedule/actions';
import { selectJobEventResources, selectScheduleEvent } from '~/redux/schedule/selectors';
import { showSuccessToast, showToast } from '~/toast';
import { Job } from '~/types/job';
import {
  isEquipmentStatusEvent, isLeaveEvent, JobEvent, JobResourceEvent, ScheduleEvent,
} from '~/types/ScheduleEvent';

const handleCreateJobScheduleEvent = takeEvery(
  ScheduleEventActions.createJobSchedule,
  function* handle(action) {
    const { event } = action.payload;

    const resourceEvents = action.payload.resourceEvents
      .map((re) => ({ ...re, jobId: event.jobId }));

    yield put(ScheduleEventActions.eventsUpdated({
      events: keyBy([event, ...resourceEvents], (e) => e.id),
    }));
    try {
      yield call(putScheduleEvent, {
        ...event,
        children: resourceEvents,
      });
      showSuccessToast('Job scheduled');
    } catch (error) {
      showToast({
        title: 'Error scheduling job',
        description: String(error),
        status: 'error',
      });
    }

    // This is a bit of a hack. It's possible the job isn't even populated into
    // Redux here so it might not work reliably.
    const job: Job = yield select((state) => selectJobById(state, event.jobId));
    if (job && ['draft', 'schedule', 'quote', 'quoted'].includes(job?.status)) {
      yield put(updateJob({ id: event.jobId, status: 'scheduled' }));
    }
  },
);

const getEventId = (event: ScheduleEvent) => {
  if (isLeaveEvent(event) || isEquipmentStatusEvent(event)) {
    return event.resourceId;
  }
  return event.jobId;
};

const showEventModifiedSuccess = (event: ScheduleEvent, existingEvent?: ScheduleEvent) => {
  let prefix = 'Job';
  const id = getEventId(event);
  if (isLeaveEvent(event)) {
    prefix = 'Leave';
  } else if (isEquipmentStatusEvent(event)) {
    prefix = 'Equipment out';
  }

  let postfix = 'scheduled';
  if (existingEvent && !isSameDay(event.start, existingEvent.start)) {
    postfix = 'rescheduled';
  }

  if (!postfix) {
    return;
  }

  const description = `${prefix} ${postfix}`;
  showSuccessToast(description, {
    id: id + description,
  });
};

const watchCreateOrUpdateEvent = takeEvery(
  [ScheduleEventActions.createEvent, ScheduleEventActions.updateEvent],
  function* handle(action) {
    const event = action.payload;

    const existingEvent: ScheduleEvent = yield select(
      (state) => selectScheduleEvent(state, event.id),
    );
    yield put(ScheduleEventActions.eventsUpdated({ events: { [event.id]: event } }));
    try {
      yield call(putScheduleEvent, event);
      showEventModifiedSuccess(event, existingEvent);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },
);

const watchUpdateJobEvent = takeEvery(
  ScheduleEventActions.updateJobEvent,
  function* handle(action) {
    const { jobEvent, events, eventsToRemove } = action.payload;

    try {
      yield put(ScheduleEventActions.eventsUpdated({
        events: {
          ...keyBy(events, (e) => e.id),
          [jobEvent.id]: jobEvent,
        },
      }));
      yield call(putScheduleEvent, {
        ...jobEvent,
        children: events,
      });
      yield all(eventsToRemove.map(
        (e) => put(ScheduleEventActions.eventDeleted({ eventId: e.id })),
      ));
      showEventModifiedSuccess(jobEvent);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },
);

const handleAddResourceToJob = takeEvery(
  ScheduleEventActions.addResourceToJob,
  function* handle(action) {
    const { jobEventId, resource } = action.payload;
    const jobEvent: JobEvent = yield select(
      (state: RootState) => state.scheduleEvents[jobEventId],
    );

    if (jobEvent) {
      yield put(ScheduleEventActions.createEvent({
        type: 'job-resource',
        id: nanoid(),
        start: jobEvent.start,
        end: jobEvent.end,
        jobId: jobEvent.jobId,
        parentId: jobEventId,
        resourceId: resource.id,
        resourceType: resource.type,
      }));
    }
  },
);

function* removeResourceFromJobEvent(jobEventId, resourceId) {
  const events: JobResourceEvent[] = yield select(
    (state) => selectJobEventResources(state, jobEventId),
  );
  yield all(
    events
      .filter((ev) => ev.resourceId === resourceId)
      .map((ev) => put(ScheduleEventActions.deleteEvent({ eventId: ev.id, hideToast: true }))),
  );
}

const handleRemoveResourceFromJobEvent = takeEvery(
  ScheduleEventActions.removeResourceFromJob,
  function* handle(action) {
    const { jobEventId, resourceId } = action.payload;
    yield call(removeResourceFromJobEvent, jobEventId, resourceId);
  },
);

const handleDeleteJobEvent = takeEvery(
  ScheduleEventActions.deleteJobEvent,
  function* handle(action) {
    const { jobEventId } = action.payload;

    yield put(ScheduleEventActions.deleteEvent({ eventId: jobEventId }));
  },
);

const handleEditResourceEvents = takeEvery(
  ScheduleEventActions.editResourceEvents,
  function* handle(action) {
    const { jobEventId, resource, intervals } = action.payload;
    const jobEvent: JobEvent = yield select(
      (state: RootState) => state.scheduleEvents[jobEventId],
    );

    if (jobEvent) {
      yield call(removeResourceFromJobEvent, jobEventId, resource.id);

      yield all(
        intervals.map((interval) => put(
          ScheduleEventActions.createEvent({
            type: 'job-resource',
            id: nanoid(),
            start: interval.start,
            end: interval.end,
            jobId: jobEvent.jobId,
            parentId: jobEventId,
            resourceId: resource.id,
            resourceType: resource.type,
          }),
        )),
      );
    }
  },
);

export default function* handleEventEditing() {
  yield all([
    handleCreateJobScheduleEvent,
    watchCreateOrUpdateEvent,
    handleAddResourceToJob,
    handleRemoveResourceFromJobEvent,
    handleDeleteJobEvent,
    watchUpdateJobEvent,
    handleEditResourceEvents,
  ]);
}
