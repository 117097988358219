import { AlertProps } from '@chakra-ui/react';
import React from 'react';
import { FaRegLightbulb } from 'react-icons/fa';
import StatusAlert, { StatusAlertProps } from '~/components/StatusAlert';
import PrefsActions from '~/redux/prefs/actions';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import { makeTransparent } from '~/theme';

interface DismissibleStatusAlertProps extends StatusAlertProps {
  id: string;
}

const DismissibleStatusAlert = (
  { id, children, ...props } : DismissibleStatusAlertProps & AlertProps,
) => {
  const dispatch = useAppDispatch();
  const dismissedAlerts = useAppSelector((s) => (
    (s.prefs.dismissedAlerts ?? {})
  ));

  if (dismissedAlerts[id]) {
    return <></>;
  }

  return (
    <StatusAlert
      fontSize="12px"
      icon={FaRegLightbulb}
      status="success"
      bg={makeTransparent('#359B4B', 0.1)}
      onClose={() => {
        dispatch(PrefsActions.dismissAlert({ id }));
      }}
      {...props}
    >
      {children}
    </StatusAlert>
  );
};

export default DismissibleStatusAlert;
