import { Icon, IconButton } from '@chakra-ui/react';
import { Close } from '@material-ui/icons';
import React from 'react';
import CenteredFlex from '~/components/LineItemsEditor/components/CenteredFlex';

interface LineItemRemoveProps {
  hide?: boolean;
  onClick: () => void;
}

const LineItemRemove = ({ hide, onClick } : LineItemRemoveProps) => (
  <CenteredFlex flex="0 0 24px" maxW="24px">
    {!hide && (
      <IconButton
        color="magnetize.ui-4"
        marginRight={-4}
        size="sm"
        variant="ghost"
        aria-label="remove row"
        icon={<Icon as={Close} boxSize="16px" />}
        tabIndex={-1}
        onClick={onClick}
      />
    )}
  </CenteredFlex>
);

export default LineItemRemove;
