import {
  Box,
  Button, FormLabel, Grid, GridItem, Icon, Modal, ModalBody, ModalCloseButton,
  ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Textarea,
} from '@chakra-ui/react';
import { Event } from '@material-ui/icons';
import { nanoid } from '@reduxjs/toolkit';
import { getTime } from 'date-fns';
import { startOfDay } from 'date-fns/esm';
import React, { useState } from 'react';
import { MdOutlineNewLabel } from 'react-icons/md';
import { ReactComponent as EditNote } from '~/assets/icon-edit-note.svg';
import { ReactComponent as UserHelmet } from '~/assets/icon-user-helmet.svg';
import { JobEventViewModel } from '~/components/JobCalendar/JobEventViewModel';
import ResourceList from '~/components/JobCalendar/ResourceList';
import { JobTagEditor } from '~/components/JobTagEditor';
import SmartDatePicker from '~/components/SmartDatePicker';
import { ScheduleEventActions } from '~/redux/schedule/actions';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import { JobEvent } from '~/types/ScheduleEvent';
import { endOfDaySeconds, roundIntervalToDays } from '~/utils/calendarHelpers';
import { ResourceEventSummary } from '~/types/resource';
import { calculateResourceEventIntervals } from '~/helpers/schedule';
import { selectCurrentUser } from '~/redux/currentUser/selectors';

const GRID_AREAS = `
  "empty1 label1 label2"
  "icon1 input1 input2"
  "tagIcon tag tag"
  "empty3 empty3 input3"
  "icon2 input4 input4"
  "icon3 input5 input5"
`;

interface AddScheduleEventModalProps {
  jobId: string;
  event: JobEventViewModel;
  onClose: () => void;
}

const AddScheduleEventModal = ({ jobId, onClose, event } : AddScheduleEventModalProps) => {
  const dispatch = useAppDispatch();
  const [isCreatingEvent] = useState(event === null);
  const interval = event ? roundIntervalToDays(event) : null;
  const [start, setStart] = useState(interval?.start);
  const [end, setEnd] = useState(interval?.end);
  const [resources, setResources] = useState(event?.resources ?? []);
  const [jobEventId] = useState(event?.id || nanoid());

  const [notes, setNotes] = useState(event?.jobEvent?.notes ?? '');

  const createEvent = () => {
    const newEvent: JobEvent = {
      type: 'job',
      id: jobEventId,
      jobId,
      start: getTime(startOfDay(start)),
      end: getTime(endOfDaySeconds(end)),
      notes: notes ?? null,
    };

    dispatch(ScheduleEventActions.createJobSchedule({
      event: newEvent,
      resourceEvents: resources.flatMap((r) => r.resourceEvents),
    }));
  };

  const modifyEvent = () => {
    const rounded = roundIntervalToDays({
      start,
      end,
    });

    const eventsToRemove = event.resources
      .flatMap((r) => r.resourceEvents)
      .filter((re) => !resources
        .some((r) => r.id === re.resourceId && r.resourceEvents.some((x) => x.id === re.id)));

    const startTime = getTime(rounded.start);
    const endTime = getTime(rounded.end);

    dispatch(ScheduleEventActions.updateJobEvent({
      jobEvent: {
        ...event.jobEvent,
        start: startTime,
        end: endTime,
        notes: notes ?? null,
      },
      events: resources.flatMap((r) => r.resourceEvents),
      eventsToRemove,
    }));
  };

  const tenant = useAppSelector(
    (state) => selectCurrentUser(state)?.tenant,
  );

  const createResourceEvents = (resource: ResourceEventSummary,
    intervals: any[]) => intervals.map((i) => ({
    id: nanoid(),
    type: 'job-resource',
    jobId,
    parentId: jobEventId,
    resourceId: resource.id,
    resourceType: resource.type,
    ...i,
  }));

  const canSave = !!start && !!end;

  return (
    <Modal
      isOpen
      size="xl"
      onClose={onClose}
      autoFocus={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader paddingBottom={5}>
          {isCreatingEvent ? 'Add ' : 'Edit '}
          {' '}
          schedule event
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Grid gridTemplateAreas={GRID_AREAS} templateColumns="1fr 5fr 5fr" rowGap={0} columnGap={2}>
            <GridItem gridArea="label1">
              <FormLabel>Start</FormLabel>
            </GridItem>
            <GridItem gridArea="label2">
              <FormLabel>Finish</FormLabel>
            </GridItem>
            <GridItem display="flex" alignItems="center" gridArea="icon1">
              <Spacer />
              <Icon w="20px" h="20px" as={Event} />
            </GridItem>
            <GridItem gridArea="input1">
              <SmartDatePicker
                hideIcon
                placeholder="Select date"
                selected={start}
                startDate={start}
                endDate={end}
                selectsStart
                maxDate={end}
                onChange={(s) => {
                  const nextStart = startOfDay(s);
                  setResources((existingResources) => existingResources.map((resource) => ({
                    ...resource,
                    resourceEvents: createResourceEvents(resource,
                      calculateResourceEventIntervals(
                        { start: nextStart, end },
                        tenant,
                        { start, end },
                        resource.resourceEvents.map((ev) => ({
                          start: ev.start,
                          end: ev.end,
                        })),
                      ))
                      .filter((ev) => !end || ev.end > ev.start),
                  })).filter((r) => r.resourceEvents.length > 0));
                  setStart(nextStart);
                }}
                buttonProps={{
                  width: '100%',
                  textAlign: 'left',
                }}
              />
            </GridItem>
            <GridItem mt={8} display="flex" gridArea="tagIcon">
              <Spacer />
              <Icon fontSize="20px" boxSize="20px" as={MdOutlineNewLabel} mr="2px" />
            </GridItem>
            <GridItem gridArea="tag" mt={6}>
              <JobTagEditor jobId={jobId} hideIcon />
            </GridItem>
            <GridItem gridArea="input2">
              <SmartDatePicker
                hideIcon
                placeholder="Select date"
                selected={end}
                startDate={start}
                endDate={end}
                selectsEnd
                minDate={start}
                onChange={(e) => {
                  const nextEnd = endOfDaySeconds(e);
                  setResources((existingResources) => existingResources.map((resource) => ({
                    ...resource,
                    resourceEvents: createResourceEvents(resource,
                      calculateResourceEventIntervals(
                        { start, end: nextEnd },
                        tenant,
                        { start, end },
                        resource.resourceEvents.map((ev) => ({
                          start: ev.start,
                          end: ev.end,
                        })),
                      ))
                      .filter((ev) => !ev.start || ev.end > ev.start),
                  })).filter((r) => r.resourceEvents.length > 0));
                  setEnd(nextEnd);
                }}
                buttonProps={{
                  width: '100%',
                  textAlign: 'left',
                }}
              />
            </GridItem>
            {/* Commenting out this exclude weekends checkbox for now as it adds
            complexity, we would need to split the event up into multiple
            schedule events, we would also need to consider long jobs being able to
            span multiple weekends */}
            {/* <GridItem display="flex" gridArea="input3">
              <Spacer />
              <Text mr={2} mt={3}>exclude weekends</Text>
              <Checkbox
                mt={2}
                checked={excludeWeekends}
                onChange={(e) => setExcludeWeekends(e.target.checked)}
              />
            </GridItem> */}
            <GridItem mt={8} display="flex" gridArea="icon2">
              <Spacer />
              <Icon fontSize="16px" boxSize="16px" as={UserHelmet} mr="2px" />
            </GridItem>
            <GridItem mt={6} gridArea="input4">
              <ResourceList
                showAvailability
                minDate={start}
                maxDate={end}
                resources={resources}
                onAddResource={(resource) => {
                  if (!resources.find((r) => r.id === resource.id)) {
                    setResources((existingResources) => [
                      ...existingResources,
                      {
                        ...resource,
                        resourceEvents: createResourceEvents(resource,
                          calculateResourceEventIntervals({ start, end }, tenant)),
                      },
                    ]);
                  }
                }}
                onRemoveResource={(removed) => {
                  setResources(resources.filter((r) => r.id !== removed.id));
                }}
                onEditResource={(resource, intervals) => {
                  setResources((existingResources) => ([
                    ...existingResources.filter((r) => r.id !== resource.id),
                    {
                      ...resource,
                      resourceEvents: createResourceEvents(resource, intervals),
                    },
                  ]));
                }}
              />
            </GridItem>
            <GridItem mt={7} display="flex" gridArea="icon3">
              <Spacer />
              <Icon mr={-1} fontSize="20px" boxSize="20px" as={EditNote} />
              {/* <Icon w="20px" h="20px" as={Edit} /> */}
            </GridItem>
            <GridItem mt={6} gridArea="input5">
              <Textarea
                placeholder="Add scheduling note"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </GridItem>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <Box>
            <Button
              disabled={!canSave}
              onClick={() => {
                if (isCreatingEvent) {
                  createEvent();
                } else {
                  modifyEvent();
                }
                onClose();
              }}
            >
              Save
            </Button>
            <Button onClick={onClose} variant="ghost">cancel</Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default AddScheduleEventModal;
