import { Flex, Icon, Text } from '@chakra-ui/react';
import { AddOutlined } from '@material-ui/icons';
import React, { useRef } from 'react';
import FileGallery from '~/components/FileUpload/FileGallery';
import FileUpload, { FileUploadRef } from '~/components/FileUpload';
import UploadedFile from '~/types/uploaded-file';

interface OrgSettingsFileUploadProps {
  files: UploadedFile[];
  onFileUploaded: (file: UploadedFile) => void;
  onFileRemoved: (file: UploadedFile) => void;
}

const OrgSettingsFileUpload = (
  { files, onFileUploaded, onFileRemoved } : OrgSettingsFileUploadProps,
) => {
  const fileUploadRef = useRef<FileUploadRef>();

  return (
    <Flex direction="column">
      <FileUpload
        ref={fileUploadRef}
        onFileUploaded={onFileUploaded}
      >
        <Flex
          color="magnetize.text-1"
          alignItems="center"
          justifyContent="center"
          px={4}
        >
          <Icon as={AddOutlined} />
        </Flex>
        <Text my={4} color="magnetize.text-3">
          Drag and drop
          <br />
          or select a file to upload
        </Text>
      </FileUpload>
      <FileGallery
        onFileRemoved={(file) => {
          fileUploadRef.current.reset();
          onFileRemoved(file);
        }}
        files={files}
      />
    </Flex>
  );
};

export default OrgSettingsFileUpload;
