import { createReducer } from '@reduxjs/toolkit';
import { startOfDay } from 'date-fns';
import ResourceCalendarActions from '~/redux/resourceCalendar/actions';
import { DateRangeMode } from '~/components/Calendar/getDateRange';
import { getIsoDate } from '~/utils/calendarHelpers';

interface ResourceCalendarState {
  focusedDay: string;
  rangeMode: DateRangeMode;
}

const resourceCalendarReducer = createReducer<ResourceCalendarState>(
  {
    focusedDay: getIsoDate(startOfDay(Date.now())),
    rangeMode: 'week',
  },
  (builder) => {
    builder.addCase(ResourceCalendarActions.move, (state, action) => ({
      ...state,
      focusedDay: action.payload.focusedDate,
    }));

    builder.addCase(ResourceCalendarActions.setRangeMode, (state, action) => ({
      ...state,
      rangeMode: action.payload.rangeMode,
    }));
  },
);

export default resourceCalendarReducer;
