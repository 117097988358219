export interface QuoteTotal {
  subtotalCents: number;
  taxCents: number;
  totalCents: number;
}

function calculateQuoteTotals(
  lineItems: { quantity: number, unitPriceCents: number }[],
  taxRate: number = 15,
): QuoteTotal {
  const subtotalCents = lineItems.reduce(
    (acc, item) => acc + Math.round(item.quantity * item.unitPriceCents), 0,
  );
  const taxCents = Math.round(subtotalCents * (taxRate / 100));
  const totalCents = subtotalCents + taxCents;
  return { subtotalCents, taxCents, totalCents };
}

export default calculateQuoteTotals;
