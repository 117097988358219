import {
  Box,
  GridItem,
} from '@chakra-ui/react';
import React from 'react';
import BackButton from '~/components/BackButton';

interface PageHeaderProps {
  backButton?: boolean;
  children?: React.ReactNode;
  gridArea?: string;
}

const PageHeader = ({ backButton, children, gridArea }: PageHeaderProps) => (
  <>
    <GridItem mt={-6} gridArea={gridArea ?? 'header'}>
      {backButton
        ? (
          <BackButton
            ml="-4"
            mb={2}
          />
        )
        : <Box minHeight="30px" />}
      {children}
    </GridItem>
    <GridItem gridArea="secondary-header" />
  </>
);
export default PageHeader;
