import { SWITCH_RATIO } from '~/theme/constants';

export const SwitchStyles = {
  sizes: {
    sm: {
      track: {
        w: `${0.75 * SWITCH_RATIO}rem`,
        h: '0.75rem',
      },
      thumb: {
        _checked: {
          transform: 'translateX(0.75rem)',
        },
      },
    },
    md: {
      track: {
        w: `${1 * SWITCH_RATIO}rem`,
        h: '1rem',
      },
      thumb: {
        _checked: {
          transform: 'translateX(1rem)',
        },
      },
    },
    lg: {
      track: {
        w: `${1.5 * SWITCH_RATIO}rem`,
        h: '1.5rem',
      },
      thumb: {
        _checked: {
          transform: 'translateX(1.5rem)',
        },
      },
    },
  },
  defaultProps: {
    colorScheme: 'brandGreen',
  },
};
