import { createReducer } from '@reduxjs/toolkit';
import {
  now, keyBy, omit,
} from 'lodash';
import { backfillTypeDeleted, backfillTypesLoaded, backfillTypeUpdated } from '~/redux/backfillTypes/actions';
import { FetchState } from '~/types/fetchState';
import { BackfillType } from '~/types/backfillType';

export const backfillTypesReducer = createReducer(
  { data: { byId: {} } } as FetchState<{
    byId: { [key:string]: BackfillType }
  }>,
  (builder) => (builder
    .addCase(backfillTypesLoaded, (state, action) => ({
      status: 'success',
      data: {
        byId: keyBy(action.payload, (x) => x.id),
      },
      isInvalid: false,
      lastUpdatedAt: now(),
    }))

    .addCase(backfillTypeUpdated, (state, action) => ({
      ...state,
      data: {
        byId: {
          ...state.data.byId,
          [action.payload.id]: action.payload,
        },
      },
    }))

    .addCase(backfillTypeDeleted, (state, action) => ({
      ...state,
      data: {
        byId: omit(state.data.byId, action.payload.id),
      },
    }))
  ),
);
