import { compact } from 'lodash';
import { Address } from '~/types/address';

const formatAddress = (address: Address, shortFormat = false) => {
  const toCompact = shortFormat
    ? [
      address?.line1,
      address?.line2,
    ] : [
      address?.line1,
      address?.line2,
      address?.city,
      address?.country,
    ];
  return compact(toCompact).join(', ');
};

export default formatAddress;
