import {
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure, MenuDivider, Box, Tooltip,
} from '@chakra-ui/react';
import { KeyboardArrowDownOutlined } from '@material-ui/icons';
import React, { useCallback, useState, useEffect } from 'react';
import { MdDeleteOutline } from 'react-icons/md';
import { useHistory } from 'react-router';
import ConfirmationDialog from '~/components/ConfirmationDialog';
import { useModalManager } from '~/components/ModalManager';
import { DuplicateJobModal } from '~/pages/Job/components/DuplicateJobModal';
import Routes from '~/pages/routes';
import {
  closeJob,
  completeJob,
  reopenJob,
  updateJob,
  holdJob,
  startJob,
  archiveJob,
} from '~/redux/jobs/actions';
import { QuoteActions } from '~/redux/quote/actions';
import { selectJobWithEdits } from '~/redux/jobs/selectors';
import { selectQuotesByJobId } from '~/redux/quote/selectors';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import { ReactComponent as Duplicate } from '~/assets/duplicate.svg';
import { ReactComponent as Archive } from '~/assets/archive.svg';
import { getIsArchivable, getJobOptionAffordance, formatJobCode } from '~/helpers/job';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import { MAGNETIZE_COLOURS } from '~/theme/constants';

const JobOptionsMenu = (
  { jobId }
  : { jobId: string; },
) => {
  const modalManager = useModalManager();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const job = useAppSelector((state) => selectJobWithEdits(state, jobId));
  const duplicateJobModalState = useDisclosure();

  const [canArchive, setCanArchive] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [archiveAffordance, setArchiveAffordance] = useState(null);
  const [deleteAffordance, setDeleteAffordance] = useState(null);

  const { data: quotes } = useTrackedFetch({
    key: `job-quotes-${jobId}`,
    trigger: () => QuoteActions.fetchForJob({ jobId }),
    selector: (state) => selectQuotesByJobId(state, jobId),
  });

  useEffect(() => {
    setCanArchive(getIsArchivable({ job, quotes }));
    setCanDelete((['draft', 'quote'].includes(job.status)) && !job.isDemo);
  }, [job, quotes]);

  useEffect(() => {
    if (!canArchive) {
      setArchiveAffordance(getJobOptionAffordance({ job, quotes, option: 'archive' }));
    }
    if (!canDelete) {
      setDeleteAffordance(getJobOptionAffordance({ job, quotes, option: 'delete' }));
    }
  }, [canArchive, canDelete]);

  const handleCompleteJob = useCallback(() => {
    dispatch(completeJob({ jobId }));
  }, [dispatch, jobId]);

  const handleReopenJob = useCallback(() => {
    dispatch(reopenJob({ jobId }));
  }, [dispatch, jobId]);

  const handleCloseJob = useCallback(() => {
    dispatch(closeJob({ jobId }));
  }, [dispatch, jobId]);

  const handleHoldJob = useCallback(() => {
    dispatch(holdJob({ jobId }));
  }, [dispatch, jobId]);

  const handleStartJob = useCallback(() => {
    dispatch(startJob({ jobId }));
  }, [dispatch, jobId]);

  const toggleArchiveJob = useCallback(() => {
    let modalTitle = 'Archive this job ';
    let modalChild = `${formatJobCode(job.code)} will be archived in Magnetize. You will still be able to find it later.`;
    let buttonLabel = 'Archive';

    if (job.status === 'archived') {
      modalTitle = 'Undo archive';
      modalChild = `${formatJobCode(job.code)} will be unarchived and restored to its previous status.`;
      buttonLabel = 'Undo Archive';
    }
    modalManager.open(ConfirmationDialog, {
      title: modalTitle,
      children: modalChild,
      onConfirm: () => dispatch(archiveJob({
        jobId: job.id,
      })),
      confirmButtonLabel: buttonLabel,
    });
  }, [dispatch, modalManager, job.id]);

  const deleteJob = useCallback(() => {
    modalManager.open(ConfirmationDialog, {
      title: 'Delete job',
      children: 'This job will be deleted from Magnetize. You will no longer be able to access it.',
      onConfirm: () => {
        dispatch(updateJob({
          id: job.id,
          isDeleted: true,
        }));
        history.push(Routes.jobList({ }));
      },
      confirmButtonLabel: 'Delete',
    });
  }, [dispatch, modalManager, job.id]);

  return (
    <>
      <Menu
        placement="bottom-end"
      >
        <MenuButton fontSize="12px">
          Options
          <Icon ml={2} w={5} h={5} as={KeyboardArrowDownOutlined} />
        </MenuButton>
        <MenuList zIndex="2">
          {['draft', 'quote', 'quoted', 'schedule', 'scheduled'].includes(job.status) && (
            <MenuItem
              isDisabled={['draft', 'quote', 'quoted'].includes(job.status)}
              onClick={handleStartJob}
            >
              Start job
            </MenuItem>
          ) }
          {['complete', 'closed', 'onHold'].includes(job.status) && (
            <MenuItem
              onClick={handleReopenJob}
            >
              {job.status === 'onHold' ? 'Back in progress' : 'Reopen job'}
            </MenuItem>
          )}
          {['inProgress'].includes(job.status) && (
          <MenuItem
            onClick={handleCompleteJob}
          >
            Complete job
          </MenuItem>
          )}
          {['complete', 'onHold'].includes(job.status) && (
          <MenuItem
            onClick={handleCloseJob}
          >
            Close job
          </MenuItem>
          )}
          {!(['complete', 'onHold', 'archived'].includes(job.status)) && (
          <MenuItem
            onClick={handleHoldJob}
            isDisabled={['draft', 'quote', 'quoted', 'closed'].includes(job.status)}
          >
            Put on hold
          </MenuItem>
          )}
          <MenuDivider />
          <MenuItem
            fontWeight="normal"
            icon={<Duplicate width="1.5rem" />}
            onClick={duplicateJobModalState.onOpen}
          >
            Duplicate
          </MenuItem>
          {job.status === 'archived'
            ? (
              <MenuItem
                fontWeight="normal"
                icon={<Archive width="1.5rem" />}
                onClick={toggleArchiveJob}
              >
                Undo Archive
              </MenuItem>
            ) : (
              <Tooltip
                shouldWrapChildren
                color="black"
                bg={MAGNETIZE_COLOURS['ui-9']}
                isDisabled={canArchive}
                label={archiveAffordance}
              >
                <MenuItem
                  fontWeight="normal"
                  isDisabled={!canArchive}
                  icon={<Archive width="1.5rem" />}
                  onClick={toggleArchiveJob}
                >
                  Archive
                </MenuItem>
              </Tooltip>
            )}
          <Tooltip
            shouldWrapChildren
            color="black"
            bg={MAGNETIZE_COLOURS['ui-9']}
            isDisabled={canDelete}
            label={deleteAffordance}
          >
            <MenuItem
              fontWeight="normal"
              isDisabled={!canDelete}
              icon={(
                <Box mr="2px" ml="-2px">
                  <MdDeleteOutline fontSize="1.8rem" />
                </Box>
  )}
              onClick={deleteJob}
            >
              Delete
            </MenuItem>
          </Tooltip>
        </MenuList>
      </Menu>
      {duplicateJobModalState.isOpen && (
        <DuplicateJobModal
          jobId={jobId}
          onCancel={duplicateJobModalState.onClose}
        />
      )}
    </>
  );
};

export default JobOptionsMenu;
