import {
  Box, Icon, Spinner, Text, Tooltip,
} from '@chakra-ui/react';
import React from 'react';
import { CloudDoneOutlined } from '@material-ui/icons';
import { useAppSelector } from '~/redux/store';

export const JobSyncStatusIndicator = () => {
  const isSyncing = useAppSelector((state) => state.syncState.isSyncing);

  if (isSyncing) {
    return (
      <Box h="18px" display="flex" color="magnetize.text-4">
        <Spinner />
        <Text ml={2}>saving...</Text>
      </Box>
    );
  }

  return (
    <Box h="18px" color="magnetize.text-3">
      <Tooltip
        label="Job saved to Magnetize"
        hasArrow
        placement="top"
      >
        <Icon as={CloudDoneOutlined} />
      </Tooltip>
    </Box>
  );
};

export default JobSyncStatusIndicator;
