import {
  Box,
  Button, Divider, Flex, GridItem,
  Heading, Icon, IconButton, List, ListItem, Menu, MenuButton, MenuItem, MenuList,
  SimpleGrid, SkeletonText, Spacer, Stack, Stat, StatGroup,
  StatHelpText,
  StatLabel, StatNumber, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure,
} from '@chakra-ui/react';
import { KeyboardArrowDownOutlined, UndoOutlined } from '@material-ui/icons';
import { intlFormat } from 'date-fns/esm';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { Redirect, Route, Switch } from 'react-router';
import Card from '~/components/Card';
import CardTitle from '~/components/CardTitle';
import PageTabs from '~/components/PageTabs';
import PageHeader from '~/layouts/PageHeader';
import PrimarySecondaryColumns from '~/layouts/PrimarySecondaryColumns';
import { AddToTenantForm } from '~/pages/Admin/Tenant/AddToTenantForm';
import { ChangeTrialPeriodModal } from '~/pages/Admin/Tenant/ChangeTrialPeriodModal';
import RunSubscriptionWorkerButton from '~/pages/Admin/Tenant/RunSubscriptionWorkerButton';
import Routes from '~/pages/routes';
import queryClient from '~/queryClient';
import { showErrorToast, showToast } from '~/toast';
import { AdminTenant } from '~/types/tenant';
import { getIsoDate } from '~/utils/calendarHelpers';
import { downloadAsJson } from '~/utils/downloadBlob';
import fetchJson from '~/utils/fetchJson';
import { useRouteParams } from '~/utils/RouteGenerator';

export const TenantDetailsPage = () => {
  const { tenantId } = useRouteParams(Routes.adminTenantDetails);
  const { isLoading, data: tenant } = useQuery({
    queryKey: ['admin-tenant', tenantId],
    queryFn: () => fetchJson<AdminTenant>(`/api/tenant-admin/${tenantId}`),
    refetchInterval: 10000,
  });

  const changeTrialModal = useDisclosure();
  const planExpiry = tenant?.planExpiry ? intlFormat(tenant.planExpiry) : 'None';
  const planExpiryTime = tenant?.planExpiry ? intlFormat(tenant.planExpiry, {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }) : '';
  const isEligibleForTrial = !tenant?.status
  || tenant?.status === 'trial'
  || tenant?.status === 'trialExpired'
  || tenant?.status === 'subscriptionExpired'
  || tenant?.status === 'subscriptionCancelled';

  const sentEmails = useMemo(
    () => (Object.entries(tenant?.sentEmails ?? {})
      .filter(([, value]) => !!value)
      .map(([key]) => key)
    ),
    [tenant],
  );

  const exportData = async ({ omitStaffWithLogins = false }) => {
    try {
      const exported = await fetchJson(`/api/tenant-admin/${tenantId}/data-export`, {
        method: 'POST',
        body: {
          omitStaffWithLogins,
        },
      });

      downloadAsJson(exported, `export-${getIsoDate(Date.now())}-${tenantId}.json`);
    } catch (error) {
      showErrorToast(error);
    }
  };

  const resetSentEmails = async (emailId) => {
    try {
      await fetchJson(`/api/tenant-admin/${tenantId}/reset-sent-emails`, {
        method: 'POST',
        body: {
          emailIds: [emailId],
        },
      });
      queryClient.invalidateQueries(['admin-tenant', tenantId]);

      showToast({
        title: `Can resend ${emailId}`,
        description: 'Magnetize will resend this email if the conditions are right',
        status: 'success',
      });
    } catch (e) {
      showErrorToast(e);
    }
  };

  if (isLoading) {
    return (
      <Card>
        <SkeletonText noOfLines={2} />
      </Card>
    );
  }

  return (
    <PrimarySecondaryColumns>
      <PageHeader backButton>
        <Heading size="lg">
          {tenant.name ?? 'Unnamed organisation'}
        </Heading>
      </PageHeader>
      <GridItem gridArea="primary">
        <PageTabs
          tabs={[
            {
              title: 'Subscription',
              path: `/superuser/tenant/${tenantId}/subscription`,
            },
            {
              title: 'Staff',
              path: `/superuser/tenant/${tenantId}/staff`,
            },
            {
              title: 'Survey Results',
              path: `/superuser/tenant/${tenantId}/welcome-survey`,
            },
          ]}
        >
          <Menu placement="bottom-end">
            <MenuButton fontSize="12px">
              Options
              <Icon ml={2} w={5} h={5} as={KeyboardArrowDownOutlined} />
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => exportData({ omitStaffWithLogins: false })}>
                Export data
              </MenuItem>
              <MenuItem onClick={() => exportData({ omitStaffWithLogins: true })}>
                Export data (omit login users)
              </MenuItem>
            </MenuList>
          </Menu>
        </PageTabs>
        <Switch>
          <Route path={`/superuser/tenant/${tenantId}/subscription`}>
            <Card>
              <CardTitle>Subscription</CardTitle>
              <Stack spacing="8">
                <StatGroup>
                  <Stat>
                    <StatLabel>Status</StatLabel>
                    <StatNumber>{tenant.status}</StatNumber>
                  </Stat>
                  <Stat>
                    <StatLabel>
                      <Text as="span" mr="2">Plan Expiry</Text>
                    </StatLabel>
                    <StatNumber>
                      {planExpiry}
                    </StatNumber>
                    {planExpiryTime && (
                    <StatHelpText>
                      {`at ${planExpiryTime}`}
                    </StatHelpText>
                    )}
                  </Stat>
                </StatGroup>

                <Box>
                  <Button
                    onClick={() => changeTrialModal.onOpen()}
                    disabled={!isEligibleForTrial}
                  >
                    Extend Trial
                  </Button>
                </Box>

                <Divider />
                <Box>
                  <CardTitle>Sent Emails</CardTitle>
                  <SimpleGrid columns={2} gap="8">
                    <Box>
                      {sentEmails.length > 0
                        ? (
                          <List>
                            {sentEmails?.map((e) => (
                              <ListItem
                                key={e}
                                borderBottomWidth="1px"
                                borderBottomColor="magnetize.ui-3"
                              >
                                <Flex alignItems="center">
                                  {e}
                                  <Spacer />
                                  <IconButton
                                    size="sm"
                                    variant="ghost"
                                    color="magnetize.text-4"
                                    icon={<Icon as={UndoOutlined} />}
                                    aria-label="Clear sent email"
                                    onClick={() => resetSentEmails(e)}
                                  />
                                </Flex>
                              </ListItem>
                            ))}
                          </List>
                        )
                        : (
                          <Text>No emails sent yet</Text>
                        )}
                    </Box>
                    <Box>
                      <Text fontSize="md" color="magnetize.text-4">
                        These are the automated/time based emails that have been sent to the
                        owner of this account. We keep track of them so that we don&apos;t send
                        them again, but for testing purposes it can be useful to clear these.
                      </Text>
                    </Box>
                  </SimpleGrid>
                </Box>
              </Stack>
            </Card>
          </Route>
          <Route path={`/superuser/tenant/${tenantId}/staff`}>
            <Card>
              <CardTitle>Staff</CardTitle>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th width="30%">Name</Th>
                    <Th width="40%">Email</Th>
                    <Th width="20%">Role</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {tenant.staff.map((s) => (
                    <Tr key={s.id}>
                      <Td>{s.name}</Td>
                      <Td>{s.email}</Td>
                      <Td>{s.isAdmin ? 'Admin' : 'Staff'}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Card>
          </Route>
          <Route path={`/superuser/tenant/${tenantId}/welcome-survey`}>
            <Card>
              <Box
                p="4"
                bg="gray.50"
                borderRadius="sm"
                borderWidth="1px"
                borderColor="magnetize.ui-4"
                boxShadow="inset 0.125rem 0.125rem 0.5rem rgb(0,0,0,0.05)"
                whiteSpace="pre-wrap"
              >
                {tenant?.welcomeSurvey
                  ? JSON.stringify(tenant?.welcomeSurvey, null, 2)
                  : 'No results (maybe they didn\'t start with a trial)'}
              </Box>
            </Card>
          </Route>

          <Route path="*" render={() => <Redirect to={`/superuser/tenant/${tenantId}/subscription`} />} />
        </Switch>
      </GridItem>
      <GridItem gridArea="secondary">
        <Stack spacing="8">
          <Card>
            Put some contact details here.
          </Card>
          <Card>
            <RunSubscriptionWorkerButton />
            <Text color="magnetize.text-4" mt="4">
              Use this to hurry along the background job that sends
              out emails and checks trial status.
              This affects all tenants, but it&apos;s convenient
              to have it here when testing emails 😁
            </Text>
          </Card>
          <Card>
            <CardTitle>Add user to tenant</CardTitle>
            <Text color="magnetize.text-4" my="4">
              This will invite someone to join this tenant. As a tester, you
              can use this functionality to join an existing tenant too.
            </Text>
            <AddToTenantForm
              tenantId={tenantId}
              buttonText="Add to tenant"
            />
          </Card>
        </Stack>
      </GridItem>
      {changeTrialModal.isOpen && (
        <ChangeTrialPeriodModal
          tenant={tenant}
          onClose={changeTrialModal.onClose}
        />
      )}
    </PrimarySecondaryColumns>
  );
};
