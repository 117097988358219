import {
  Button, ButtonProps, Icon, Text,
} from '@chakra-ui/react';
import { ArrowBack } from '@material-ui/icons';
import React from 'react';
import { useHistory } from 'react-router-dom';

const BackButton = ({ ...props }: ButtonProps) => {
  const history = useHistory();
  return (
    <Button
      textTransform="uppercase"
      textDecoration="none"
      leftIcon={<Icon as={ArrowBack} mr={-1} />}
      variant="ghost"
      size="sm"
      onClick={() => {
        history.goBack();
      }}
      {...props}
    >
      <Text mt={0.5}>
        Back
      </Text>
    </Button>
  );
};

export default BackButton;
