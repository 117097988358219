import {
  Box,
  Grid,
  Heading,
} from '@chakra-ui/react';
import React from 'react';
import CurrencySearchSelect from '~/components/CurrencySearchSelect';
import TaxRates from '~/components/TaxRates';
import { Tenant } from '~/types/tenant';

const FinanceTab = ({ tenant, onSave } : {
  tenant: Tenant,
  onSave: (tenant: Tenant) => void
}) => (
  <Grid gap={6}>
    <Box>
      <Heading pb={5} size="sm">Currency</Heading>
      <CurrencySearchSelect
        value={tenant.currency}
        onChange={(currency) => {
          onSave({
            ...tenant,
            currency: currency.shortCode,
          });
        }}
      />
    </Box>
    <TaxRates />
  </Grid>
);

export default FinanceTab;
