import { nanoid } from '@reduxjs/toolkit';
import { put, take } from 'redux-saga/effects';
import { beginQueuedWork, queuedWorkComplete, waitInQueue } from '~/redux/queuedTasks/actions';

/**
 * A Saga fragment to queue up a work item
 * @param id
 */
export default function* queueWork(worker: () => any) {
  const id = nanoid();
  yield put(waitInQueue({ id }));
  yield take((a) => beginQueuedWork.match(a) && a.payload.id === id);
  try {
    yield worker();
  } finally {
    yield put(queuedWorkComplete({ id }));
  }
}
