import { uniqBy } from 'lodash';
import {
  all, call, put, select, takeEvery,
} from 'redux-saga/effects';
import ConsumablesActions from '~/redux/consumables/actions';
import { addV2ConsumableQuantity, selectConsumablesForJob } from '~/redux/consumables/selectors';
import { showToast } from '~/toast';
import {
  ApiIncrementConsumable, ApiSetConsumable, SummaryConsumableRecord,
} from '~/types/consumable';
import fetchJson from '~/utils/fetchJson';
import { performFetchSaga } from '~/utils/performFetchSaga';

const watchFetchforJob = takeEvery(
  ConsumablesActions.fetchForJob,
  function* handle(action) {
    const { jobId } = action.payload;
    yield performFetchSaga({
      key: `consumables-${jobId}`,
      * saga() {
        const consumables: (SummaryConsumableRecord)[] = yield call(
          fetchJson,
          `/api/jobs/${jobId}/consumables`,
        );

        yield put(ConsumablesActions.consumablesUpdated({
          jobId,
          consumables: addV2ConsumableQuantity(consumables),
        }));
      },
    });
  },
);

const doIncrementConsumable = (jobId: string, consumable: ApiIncrementConsumable) => (
  fetchJson<SummaryConsumableRecord, ApiIncrementConsumable>(`/api/jobs/${jobId}/consumables/increment`, {
    method: 'POST',
    body: consumable,
  })
);

const doSetConsumable = (jobId: string, consumable: ApiSetConsumable) => (
  fetchJson<SummaryConsumableRecord, ApiSetConsumable>(`/api/jobs/${jobId}/consumables/set`, {
    method: 'POST',
    body: consumable,
  })
);

const watchAddToJob = takeEvery(
  ConsumablesActions.addToJob,
  function* handle(action) {
    const { jobId, consumables } = action.payload;
    try {
      // TODO: it would be nice to do optimistic updates here...
      const updates: SummaryConsumableRecord[] = yield all(
        consumables.map((c) => call(doIncrementConsumable, jobId, c)),
      );
      const existingConsumables: SummaryConsumableRecord[] = yield select((s) => (
        selectConsumablesForJob(s, jobId)));

      yield put(ConsumablesActions.consumablesUpdated({
        jobId,
        consumables: uniqBy([...updates, ...existingConsumables], (c) => c.id),
      }));

      showToast({
        status: 'success',
        title: `Added ${consumables.length} consumable${consumables.length !== 1 ? 's' : ''}`,
      });
    } catch (e) {
      // If something goes wrong maybe we should just refetch all the
      // consumables so that the UI is consistent with backend state...
      yield put(ConsumablesActions.fetchForJob({ jobId }));
      showToast({
        status: 'error',
        title: 'Adding consumables failed',
        description: String(e),
      });
    }
  },
);

const watchSetConsumable = takeEvery(
  ConsumablesActions.set,
  function* handle(action) {
    const { jobId, set } = action.payload;
    try {
      const updated: SummaryConsumableRecord = yield call(doSetConsumable, jobId, set);
      const existingConsumables: SummaryConsumableRecord[] = yield select((s) => (
        selectConsumablesForJob(s, jobId)));

      yield put(ConsumablesActions.consumablesUpdated({
        jobId,
        consumables: uniqBy([updated, ...existingConsumables], (c) => c.id),
      }));
    } catch (e) {
      showToast({
        status: 'error',
        title: 'Adding consumables failed',
        description: String(e),
      });
    }
  },
);

const watchSetConsumableForDate = takeEvery(
  ConsumablesActions.setForDate,
  function* handle(action) {
    const { jobId, id, setForDate } = action.payload;
    if (!id) {
      throw Error('ID missing');
    }
    try {
      const updated = yield call(() => (
        fetchJson<SummaryConsumableRecord, ApiSetConsumable>(`/api/jobs/${jobId}/consumables/${encodeURIComponent(id)}/for-date`, {
          method: 'PATCH',
          body: setForDate,
        })
      ));
      const existingConsumables: SummaryConsumableRecord[] = yield select((s) => (
        selectConsumablesForJob(s, jobId)));

      yield put(ConsumablesActions.consumablesUpdated({
        jobId,
        consumables: uniqBy([
          ...addV2ConsumableQuantity([updated]),
          ...existingConsumables,
        ], (c) => c.id),
      }));
    } catch (e) {
      showToast({
        status: 'error',
        title: 'Adding consumables failed',
        description: String(e),
      });
    }
  },
);
const watchDeleteConsumableForDate = takeEvery(
  ConsumablesActions.deleteForDate,
  function* handle(action) {
    const {
      jobId, id, date, staffId,
    } = action.payload;
    try {
      const updated = yield call(() => (
        fetchJson<SummaryConsumableRecord, ApiSetConsumable>(`/api/jobs/${jobId}/consumables/${encodeURIComponent(id)}/for-date?staffId=${staffId}&date=${date}`, {
          method: 'DELETE',
        })
      ));
      const existingConsumables: SummaryConsumableRecord[] = yield select((s) => (
        selectConsumablesForJob(s, jobId)));

      yield put(ConsumablesActions.consumablesUpdated({
        jobId,
        consumables: uniqBy([
          ...addV2ConsumableQuantity([updated]),
          ...existingConsumables,
        ], (c) => c.id),
      }));
    } catch (e) {
      showToast({
        status: 'error',
        title: 'Deleting consumables for date failed',
        description: String(e),
      });
    }
  },
);

const watchDeleteConsumable = takeEvery(
  ConsumablesActions.delete,
  function* handle(action) {
    const { jobId, consumableId } = action.payload;
    try {
      yield call(() => fetchJson(
        `/api/jobs/${jobId}/consumables/${encodeURIComponent(consumableId)}`, {
          method: 'DELETE',
        },
      ));

      const consumables: SummaryConsumableRecord[] = yield select(
        (s) => selectConsumablesForJob(s, jobId),
      );

      yield put(ConsumablesActions.consumablesUpdated({
        jobId,
        consumables: consumables.filter((c) => c.id !== consumableId),
      }));

      showToast({
        status: 'success',
        title: 'Removed consumable',
      });
    } catch (e) {
      showToast({
        status: 'error',
        title: 'Error deleting consumable',
        description: String(e),
      });
    }
  },
);

export default function* handleConsumables() {
  yield all([
    watchFetchforJob,
    watchAddToJob,
    watchSetConsumable,
    watchSetConsumableForDate,
    watchDeleteConsumableForDate,
    watchDeleteConsumable,
  ]);
}
