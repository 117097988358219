import {
  Box, Spacer, Stack, useMediaQuery,
  Container, Flex, GridItem, Heading, Icon, useDisclosure, Button,
} from '@chakra-ui/react';
import { Print, ForumOutlined } from '@material-ui/icons';
import React, { useEffect, useRef } from 'react';
import { parseISO } from 'date-fns/esm';
import { differenceInCalendarDays } from 'date-fns';
import FileGallery from '~/components/FileUpload/FileGallery';
import NotFound from '~/components/NotFound';
import PagePrintPreview from '~/components/PagePrintPreview';
import QuoteView from '~/components/QuoteView';
import QuoteViewTermsAndConditions from '~/components/QuoteView/QuoteViewTermsAndConditions';
import OneColumnBottomBrand from '~/layouts/OneColumnBottomBrand';
import SkeletonQuote from '~/components/SkeletonQuote';
import VisibleOnlyIn from '~/components/VisibleIn';
import { usePrintHelper } from '~/hooks/usePrintHelper';
import PrimarySecondaryColumns from '~/layouts/PrimarySecondaryColumns';
import Routes from '~/pages/routes';
import { triggerCustomerQuoteFetch } from '~/redux/customerQuotes/actions';
import { selectCustomerQuoteFetchState, selectCustomerQuoteWithEdits } from '~/redux/customerQuotes/selectors';
import { triggerCustomerTenantFetch } from '~/redux/customerTenant/actions';
import { selectCustomerTenantByToken, selectCustomerTenantFetchState } from '~/redux/customerTenant/selectors';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import { useRouteParams } from '~/utils/RouteGenerator';
import { CustomerFacingHeader } from '~/components/CustomerFacingHeader';
import { PrimaryButton } from '~/components/CustomerFacingHeader/PrimaryButton';
import { SecondaryButton } from '~/components/CustomerFacingHeader/SecondaryButton';
import PageHeader from '~/layouts/PageHeader';
import { RaiseQueryModal } from '~/pages/CustomerQuote/RaiseQueryModal';
import { AcceptQuoteModal } from '~/pages/CustomerQuote/AcceptQuoteModal';

const CustomerQuotePage = () => {
  const { token } = useRouteParams(Routes.customerQuote);

  const quote = useAppSelector((state) => selectCustomerQuoteWithEdits(state, token));
  const {
    isLoading: isQuoteLoading,
  } = useAppSelector((s) => selectCustomerQuoteFetchState(s, token));

  const tenant = useAppSelector((state) => selectCustomerTenantByToken(state, token));
  const {
    isLoading: isTenantLoading,
  } = useAppSelector((s) => selectCustomerTenantFetchState(s, token));

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(triggerCustomerQuoteFetch({ token }));
    dispatch(triggerCustomerTenantFetch({ token }));
  }, []);

  const [isPrintingMediaQuery] = useMediaQuery('print');
  const printRef = useRef<HTMLDivElement>();
  const { print, isPrinting: isPrintingViaHelper } = usePrintHelper({
    contentRef: printRef,
    documentTitle: `QUOTE-${quote?.code} ${quote?.title} - ${tenant?.name}`,
  });
  const { isOpen: isQueryOpen, onOpen: onQueryOpen, onClose: onQueryClose } = useDisclosure();
  const { isOpen: isAcceptOpen, onOpen: onAcceptOpen, onClose: onAcceptClose } = useDisclosure();

  const isPrinting = isPrintingMediaQuery || isPrintingViaHelper;
  const isLoading = isQuoteLoading || isTenantLoading;
  const hasLoaded = !isLoading && !!quote;
  const expiringInDays = quote
    ? differenceInCalendarDays(parseISO(quote.expiryDate), new Date())
    : 30;
  const quoteExpired = expiringInDays < 0;

  if (!isLoading && (!quote)) {
    return <NotFound>Quote not found</NotFound>;
  }

  let headerTitle = 'New Quote for Acceptance';
  let headerColor = 'magnetize.ui-1';
  let warningMessage;
  let headerTextColor;
  const actions = [];
  if (quote?.status === 'accepted') {
    headerTitle = 'Quote Accepted';
    headerColor = '#f5faf6';
    actions.push(<PrimaryButton key="accept" disabled>ACCEPT QUOTE</PrimaryButton>);
    actions.push(
      <SecondaryButton key="query" ml={4} onClick={onQueryOpen}>
        <Icon as={ForumOutlined} mr={2} />
        Raise query
      </SecondaryButton>,
    );
  } else if (quoteExpired) {
    headerTitle = 'Quote Expired';
    headerColor = '#fff9f2';
    warningMessage = 'Still need this work? Request a new quote';
    headerTextColor = 'magnetize.support-warning';
    actions.push(
      <SecondaryButton key="query" ml={4} onClick={onQueryOpen}>
        <Icon as={ForumOutlined} mr={2} />
        Request new quote
      </SecondaryButton>,
    );
  } else {
    actions.push(<PrimaryButton key="accept" onClick={onAcceptOpen}>ACCEPT QUOTE</PrimaryButton>);
    actions.push(
      <SecondaryButton key="query" ml={4} onClick={onQueryOpen}>
        <Icon as={ForumOutlined} mr={2} />
        Raise query
      </SecondaryButton>,
    );
    if (expiringInDays <= 2) {
      if (expiringInDays === 0) {
        warningMessage = 'expiring today';
      } else {
        warningMessage = `expiring in ${expiringInDays} day${expiringInDays !== 1 ? 's' : ''}`;
      }
    }
  }
  const header = (
    <CustomerFacingHeader
      headerTitle={headerTitle}
      headerColor={headerColor}
      warningMessage={warningMessage}
      headerTextColor={headerTextColor}
      sentBy={quote?.sentBy}
      actions={actions}
    />
  );

  if (hasLoaded && isPrinting) {
    return (
      <>
        <VisibleOnlyIn media="screen">
          {/* This shows the skeleton page while the print view is
              being prepared. It's possible to avoid this if we render
              the quote a third time, but doing this means we can use
              the same codepath for printing and avoid differences between
              the File - Print and react-to-print code paths */}
          <Container maxWidth="900px">
            <PageHeader>
              <Heading size="lg" pb={2} pt={12}>
                {headerTitle}
              </Heading>
            </PageHeader>
            <GridItem gridArea="primary">
              <SkeletonQuote isLoaded={false} />
            </GridItem>
          </Container>
        </VisibleOnlyIn>
        <VisibleOnlyIn ref={printRef} media="print">
          <QuoteView
            quote={quote}
            tenant={tenant}
            showTermsAndConditions
            referenceNumber={quote?.referenceNumber}
          />
        </VisibleOnlyIn>
      </>
    );
  }

  const quotePreview = (
    <Box maxWidth="900px" margin="auto">
      <Flex>
        <Spacer />
        <Box alignSelf="flex-end">
          <Button
            aria-label="Print quote"
            onClick={print}
            variant="ghost"
            colorScheme="brandDark"
          >
            <Icon as={Print} mr={3} />
            Print
          </Button>
        </Box>
      </Flex>
      <SkeletonQuote isLoaded={hasLoaded}>
        <Stack spacing="8">
          <PagePrintPreview>
            <QuoteView
              tenant={tenant}
              quote={quote}
              referenceNumber={quote?.referenceNumber}
            />
          </PagePrintPreview>
          {(tenant && quote)
    && (
    <QuoteViewTermsAndConditions
      tenant={tenant}
      termsAndConditions={quote?.termsAndConditions
        || tenant?.quoteTermsAndConditions}
    />
    )}
        </Stack>
      </SkeletonQuote>
    </Box>
  );

  return (
    <>
      {header}
      <Box pt={20} backgroundColor="magnetize.ui-3">
        <OneColumnBottomBrand>
          {quote?.files?.length > 0 ? (
            <PrimarySecondaryColumns>
              <GridItem gridArea="primary">
                {quotePreview}
              </GridItem>
              <GridItem gridArea="secondary" mt={24}>
                <Box>
                  <Heading size="sm" mb={2}>attachments</Heading>
                  <FileGallery files={quote.files} />
                </Box>
              </GridItem>
            </PrimarySecondaryColumns>
          )
            : quotePreview}
        </OneColumnBottomBrand>
      </Box>
      <RaiseQueryModal
        title={quoteExpired ? 'Request new quote' : 'Raise Query'}
        isOpen={isQueryOpen}
        onClose={onQueryClose}
        token={token}
        contactInfo={quote?.sent.by}
      />
      <AcceptQuoteModal
        isOpen={isAcceptOpen}
        hasAttachments={quote?.files?.length > 0}
        onClose={onAcceptClose}
        token={token}
      />
    </>
  );
};

export default CustomerQuotePage;
