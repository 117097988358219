import {
  Button,
  Checkbox,
  FormControl,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { acceptQuote } from '~/redux/customerQuotes/actions';
import useTrackedAction from '~/hooks/useTrackedAction';
import { showToast } from '~/toast';
import { PrimaryButton } from '~/components/CustomerFacingHeader/PrimaryButton';

export const AcceptQuoteModal = ({
  token, isOpen, onClose, hasAttachments,
} : { token:string,
  isOpen: boolean,
  onClose: ()=> void,
  hasAttachments: boolean }) => {
  const [agreedToQuote, setAgreedToQuote] = useState(false);
  const [hasSeenAttachments, setHasSeenAttachments] = useState(!hasAttachments);

  const goodToGo = agreedToQuote && hasSeenAttachments;

  const { isLoading, isDone, trigger: onAcceptClicked } = useTrackedAction({
    trigger: () => acceptQuote({
      token,
      comment: '',
    }),
  });

  useEffect(() => {
    if (isDone) {
      showToast({
        description: 'Quote accepted',
        status: 'success',
      });
      onClose();
    }
  }, [isDone]);
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Accept quote</ModalHeader>
        <ModalCloseButton tabIndex={-1} />
        <ModalBody>
          <FormControl>
            <Stack spacing={2}>
              <Checkbox
                checked={agreedToQuote}
                onChange={(e) => setAgreedToQuote(e.target.checked)}
              >
                I agree to this quote
              </Checkbox>
              {hasAttachments
              && (
              <Checkbox
                checked={hasSeenAttachments}
                onChange={(e) => setHasSeenAttachments(e.target.checked)}
              >
                I have viewed, read and understood the attachments
              </Checkbox>
              )}
            </Stack>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={4}>
            <PrimaryButton
              disabled={isLoading || !goodToGo}
              isLoading={isLoading}
              onClick={onAcceptClicked}
            >
              Accept Quote
            </PrimaryButton>
            <Button variant="ghost" size="lg" onClick={onClose}>cancel</Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
