import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import AddressComponent from '~/components/Address';
import { Tenant } from '~/types/tenant';

const TenantAddress = ({ tenant } : { tenant: Tenant }) => {
  if (!tenant) {
    return null;
  }
  return (
    <Box>
      <Text fontWeight="semibold">
        {tenant.name}
      </Text>
      {tenant.address && (
        <AddressComponent
          value={tenant.address}
          lineHeight="short"
        />
      )}
    </Box>
  );
};

export default TenantAddress;
