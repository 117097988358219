import {
  Link,
  Skeleton,
  SkeletonText, Text, useBreakpointValue,
} from '@chakra-ui/react';
import React from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { round } from 'lodash';
import { addYears } from 'date-fns';
import {
  createColumnHelper,
  CellContext,
} from '@tanstack/react-table';

import {
  formatJobName, getInvoiced, getLogged, getQuoted,
} from '~/helpers/job';
import Routes from '~/pages/routes';
import {
  ExtendedJob, JobInvoiceStats, JobLoggedStats, JobQuoteStats, JobStatusId,
} from '~/types/job';
import eventStopper from '~/utils/eventStopper';
import StatusBadge from '~/components/JobStatusBadge';
import CurrencyText from '~/components/CurrencyText';
import { parseISODateLocal } from '~/utils/parseTime';
import PriceActions from '~/redux/prices/actions';
import { selectPrices } from '~/redux/prices/selectors';
import { Price } from '~/types/price';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import { VirtualisedSortableTable } from '~/components/VirtualisedSortableTable';
import JobTagsSummary from '~/components/JobTagsSummary';

type RowType = ExtendedJob& JobInvoiceStats&JobLoggedStats& JobQuoteStats;
export const InvoiceJobList = (
  { jobs, isLoadingCustomers }:
  { jobs: ExtendedJob[], isLoadingCustomers: boolean },
) => {
  const { data: prices, isLoading: isPricesLoading } = useTrackedFetch<Price[]>({
    key: 'prices',
    selector: (state) => selectPrices(state),
    trigger: () => PriceActions.fetch(),
    equalityMode: 'deep',
  });

  const history = useHistory();
  const isXl = useBreakpointValue({ base: false, xl: true });
  const is3Xl = useBreakpointValue({ base: false, '3xl': true });

  const renderInvoiceButton = ({ job }:{ job: RowType }) => {
    if (job?.consumablesVersion < 2) {
      return null;
    }

    if (job.hasDraftInvoices) {
      return (
        <Link
          color="magnetize.brand-4"
          as={RouterLink}
          {...eventStopper()}
          to={{
            pathname: Routes.jobInvoice({ jobId: job.id }),
          }}
        >
          continue invoice
        </Link>
      );
    }

    if (job.status !== 'complete' && job.total === 0 && !job.invoicedProportion) {
      return null;
    }

    if (job.invoiced >= job.quoted && job.total === 0) {
      if (job.status === 'complete') {
        return (
          <Link
            color="magnetize.brand-4"
            as={RouterLink}
            {...eventStopper()}
            to={{
              pathname: Routes.jobInvoice({ jobId: job.id }),
              state: { close: true },
            }}
          >
            close job
          </Link>
        );
      }
      return null;
    }
    let linkTitle = 'bill progress';
    if (job.invoicedProportion) {
      linkTitle = 'bill percentage';
    }
    if (job.status === 'complete') {
      if (job.invoiced > 0) {
        linkTitle = 'bill remaining';
      } else {
        linkTitle = 'bill final';
      }
    }
    return (
      <Link
        color="magnetize.brand-4"
        as={RouterLink}
        {...eventStopper()}
        to={{
          pathname: Routes.jobInvoice({ jobId: job.id }),
          state: { create: true },
        }}
      >
        {linkTitle}
      </Link>
    );
  };

  const columnHelper = createColumnHelper<RowType>();
  const columns = React.useMemo(
    () => [
      columnHelper.accessor((x) => x.jobNumber, {
        header: 'Job #',
        meta: {
          headerProps: {
            width: '6.5rem',
          },
        },
      }),
      columnHelper.accessor((x) => x.customerName, {
        header: 'Customer',
        cell: ({ getValue }: CellContext<RowType, string>) => (isLoadingCustomers
          ? <SkeletonText noOfLines={1} />
          : getValue() || ''),
      }),
      columnHelper.accessor((x) => x.name, {
        header: 'Job Name',
        cell: ({ row: { original } }: CellContext<RowType, string>) => (
          <Link
            as={RouterLink}
            to={Routes.job({
              jobId:
          original.id,
            })}
          >
            {formatJobName(original)}
          </Link>
        ),
      }),
      columnHelper.accessor((x) => x.status, {
        header: 'Status',
        meta: {
          headerProps: {
            width: isXl ? '12rem' : '5.5rem',
          },
        },
        cell: ({ getValue }: CellContext<RowType, JobStatusId>) => (
          <StatusBadge status={getValue()} isCollapsed={!isXl} />
        ),
      }),
      ...(is3Xl ? [
        columnHelper.display({
          id: 'job-tags',
          meta: {
            headerProps: {
              width: '12rem',
            },
          },
          cell: ({ row: { original } }: CellContext<RowType, unknown>) => (
            <JobTagsSummary tagIds={original.jobTags.map((t) => t.id)} />
          ),
        }),
      ] : []),
      columnHelper.accessor((x) => x.quoted, {
        header: 'Budgeted',
        meta: {
          headerProps: {
            width: '10rem',
          },
        },
        cell: ({ getValue }: CellContext<RowType, number>) => (
          <Text color={getValue() > 0 ? 'magnetize.text-1' : 'magnetize.text-4'}>
            <CurrencyText value={getValue()} />
          </Text>
        ),
      }),
      columnHelper.accessor((x) => x.invoiced, {
        header: 'Invoiced',
        meta: {
          headerProps: {
            width: '10rem',
          },
        },
        cell: (
          { getValue, row: { original } }: CellContext<RowType, number>,
        ) => (
          <Text color={getValue() > 0 ? 'magnetize.text-1' : 'magnetize.text-4'}>
            {original.invoicedProportion ? `${round(original.invoicedProportion * 100, 2)}%` : <CurrencyText value={getValue()} />}
          </Text>
        ),
      }),
      ...(isXl ? [
        columnHelper.accessor((x) => x.total, {
          header: 'Accrued Est.',
          meta: {
            headerProps: {
              width: '10rem',
            },
          },
          cell: (
            { getValue, row: { original } }: CellContext<RowType, number>,
          ) => {
            if (original.invoicedProportion) return <></>;

            return (
              <Skeleton isLoaded={!isPricesLoading}>
                <Text color={getValue() > 0 ? 'magnetize.text-1' : 'magnetize.text-4'}>
                  <CurrencyText value={getValue()} />
                </Text>
              </Skeleton>
            );
          },
        }),
      ] : []),
      columnHelper.display({
        id: 'invoice-link',
        meta: {
          headerProps: {
            width: '10rem',
          },
        },
        cell: (
          { row: { original } }
          : { row: { original: RowType } },
        ) => renderInvoiceButton({ job: original }),
      }),
    ],
    [isXl, is3Xl, isPricesLoading, isLoadingCustomers],
  );

  const data = React.useMemo(() => jobs.map((job) => {
    const { invoicedTo, invoiced, invoicedProportion } = getInvoiced({ job });
    return {
      ...job,
      invoicedTo,
      invoicedProportion,
      invoiced,
      ...getQuoted({ job }),
      ...getLogged({
        job,
        prices,
        interval: {
          start: invoicedTo ? parseISODateLocal(invoicedTo) : new Date('2000-01-01'),
          end: addYears(new Date(), 1),
        },
      }),
    } as RowType;
  }), [jobs, prices]);

  return (
    <VirtualisedSortableTable
      data={data}
      columns={columns}
      defaultSort={[{ id: 'Job #', desc: true }]}
      getRowProps={(row) => ({
        _hover: { bg: '#fafafa', cursor: 'pointer' },
        transition: 'color 0.1s ease-out',
        role: 'group',
        onClick: () => history.push(Routes.job({ jobId: row.original.id })),
      })}
    />
  );
};
