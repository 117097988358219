import {
  ButtonGroup, Heading, Icon, IconButton, useControllableState,
} from '@chakra-ui/react';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import {
  intlFormat, isSameMonth, isSameYear, addDays,
} from 'date-fns';
import React, { useContext, useMemo } from 'react';
import { CalendarContext } from '~/components/Calendar/CalendarContext';
import CalendarRangeToggle from '~/components/Calendar/CalendarRangeToggle';
import { DateRangeMode, getCalendarDateRange, moveFocusedDay } from '~/components/Calendar/getDateRange';
import { getIsoDate } from '~/utils/calendarHelpers';

const formatMonthName = (date: Date) => intlFormat(date, { month: 'short' });
const formatYear = (date: Date) => intlFormat(date, { year: 'numeric' });

const formatMonthTitle = (start: Date, end: Date) => {
  if (isSameMonth(start, end)) {
    return `${formatMonthName(start)} ${formatYear(start)}`;
  } if (isSameYear(start, end)) {
    return `${formatMonthName(start)} - ${formatMonthName(end)} ${formatYear(end)}`;
  }
  return `${formatMonthName(start)} ${formatYear(start)} - ${formatMonthName(end)} ${formatYear(end)}`;
};

interface CalendarNavProps {
  dateRangeModes?: DateRangeMode[];
  onDatesChanged?: (start: Date, end: Date) => void;
  focusedDay?: string;
  onFocusedDayChange?: (focusedDay: string) => void;
  mode?: DateRangeMode;
  onModeChange?: (mode: DateRangeMode) => void;
  headingTextAlign?: 'left' | 'right' | 'center';
}

const CalendarNav = ({
  focusedDay: focusedDayProp,
  onFocusedDayChange,
  mode: modeProp,
  onModeChange,
  onDatesChanged,
  dateRangeModes = ['3-day', 'week', '2-week'],
  headingTextAlign = 'center',
}: CalendarNavProps) => {
  const calendarContext = useContext(CalendarContext);
  const [focusedDay, setFocusedDay] = useControllableState<string>({
    defaultValue: getIsoDate(calendarContext.start),
    value: focusedDayProp,
    onChange: onFocusedDayChange,
  });

  const [mode, setMode] = useControllableState<DateRangeMode>({
    defaultValue: 'week',
    value: modeProp,
    onChange: onModeChange,
  });

  const { start: startDate, end: endDate } = useMemo(() => (
    getCalendarDateRange(focusedDay, mode)
  ), [focusedDay, mode]);

  const monthTitle = useMemo(() => formatMonthTitle(
    // In month mode We over-fetch by 6 days either side to render calendar blocks
    // normalize dates to within current to display correct month title
    addDays(startDate, mode === 'month' ? 6 : 0), addDays(endDate, mode === 'month' ? -6 : 0),
  ), [startDate, endDate]);

  const updateDateRange = (day: string, currentMode: DateRangeMode) => {
    // Just to be sure, we want to make sure our dates are aligned to day
    // boundaries.
    const { start, end } = getCalendarDateRange(day, currentMode);
    onDatesChanged?.(start, end);
  };

  const move = (direction: 1 | -1) => {
    const newDay = moveFocusedDay(focusedDay, mode, direction);
    setFocusedDay(newDay);
    updateDateRange(newDay, mode);
  };

  const changeMode = (newMode: DateRangeMode) => {
    setMode(newMode);
    updateDateRange(focusedDay, newMode);
  };

  return (
    <>
      <Heading size="md" textAlign={headingTextAlign} mr={4} flex="1">{monthTitle}</Heading>
      <ButtonGroup variant="outline" spacing="1" size="md">
        <IconButton
          icon={<Icon as={NavigateBefore} />}
          borderRadius="sm"
          height={12}
          width={12}
          onClick={() => move(-1)}
          aria-label="Previous"
          borderColor="magnetize.ui-4"
          onMouseDown={(e) => e.preventDefault()}
        />
        <IconButton
          icon={<Icon as={NavigateNext} />}
          borderRadius="sm"
          height={12}
          width={12}
          borderColor="magnetize.ui-4"
          onClick={() => move(1)}
          aria-label="Next"
          onMouseDown={(e) => e.preventDefault()}
        />
      </ButtonGroup>

      {dateRangeModes.length > 0 && (
      <CalendarRangeToggle
        ml="2"
        mode={mode}
        availableModes={dateRangeModes}
        onChangeMode={changeMode}
      />
      )}
    </>
  );
};

export default CalendarNav;
