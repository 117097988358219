import {
  Box,
  Flex,
  GridItem, Skeleton, SkeletonText, Stack,
} from '@chakra-ui/react';
import React from 'react';
import Card from '~/components/Card';
import PageTabs from '~/components/PageTabs';
import PageHeader from '~/layouts/PageHeader';
import PrimarySecondaryColumns from '~/layouts/PrimarySecondaryColumns';

const SkeletonJobPage = () => (
  <PrimarySecondaryColumns>
    <PageHeader backButton>
      <Flex direction="column">
        <Skeleton width="50%" height="6" mt={2} mb={4} />
        <Flex height={12} justifyContent="space-between">
          <Skeleton borderRadius="xl" width="15%" height="6" />
        </Flex>
        <Box mb={-6}>
          <PageTabs
            isDisabled
            tabs={[
              { title: 'Job Details' },
              { title: 'Quote' },
              { title: 'Schedule' },
              { title: 'Files' },
              { title: 'Logs' },
              { title: 'Invoice' }]}
          />
        </Box>
      </Flex>
    </PageHeader>
    <Flex direction="column">
      <GridItem gridArea="primary">
        <Card height={300}>
          <SkeletonText noOfLines={2} />
        </Card>
      </GridItem>
    </Flex>
    <GridItem gridArea="secondary">
      <Stack spacing={8}>
        <Card height={120}>
          <SkeletonText noOfLines={2} />
        </Card>
        <Card height={240}>
          <SkeletonText noOfLines={2} mb={4} />
        </Card>
      </Stack>
    </GridItem>
  </PrimarySecondaryColumns>
);

export default SkeletonJobPage;
