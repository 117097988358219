import {
  call, put, select, takeEvery,
} from 'redux-saga/effects';
import { ScheduleEventActions } from '~/redux/schedule/actions';
import { deleteScheduleEvent as apiDeleteEvent } from '~/api/scheduleApi';
import { selectScheduleEvent } from '~/redux/schedule/selectors';
import { showSuccessToast, showToast } from '~/toast';
import { ScheduleEvent } from '~/types/ScheduleEvent';

const handleDeleteScheduleEvent = takeEvery(
  ScheduleEventActions.deleteEvent,
  function* handle(action) {
    const { eventId, hideToast } = action.payload;
    const eventToDelete: ScheduleEvent = yield select(
      (state) => selectScheduleEvent(state, eventId),
    );
    try {
      yield put(ScheduleEventActions.eventDeleted({ eventId }));
      yield call(apiDeleteEvent, eventId);
      if (!hideToast) {
        showSuccessToast('Scheduled event deleted', { id: eventId });
      }
    } catch (error) {
      yield call(showToast, {
        title: 'Could not delete schedule event',
        description: String(error),
        status: 'error',
      });
      yield put(ScheduleEventActions.eventsUpdated({
        events: { [eventId]: eventToDelete },
      }));
    }
  },
);

export default handleDeleteScheduleEvent;
