import {
  Button,
  Modal, ModalBody, ModalCloseButton,
  ModalContent, ModalFooter, ModalHeader, ModalOverlay,
  Box,
  FormControl, FormLabel, Grid, HStack, Input, InputGroup, InputRightAddon, Switch, Textarea,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import useTrackedAction from '~/hooks/useTrackedAction';
import { EquipmentActions } from '~/redux/equipment/actions';
import { Equipment } from '~/types/equipment';
import CreatableTagSelect from '~/components/CreatableTagSelect';
import LinkButton from '~/components/LinkButton';
import RateSearchSelect from '~/components/RateSearchSelect';
import { selectTenantPowerUps } from '~/redux/currentUser/selectors';
import { useAppSelector } from '~/redux/store';
import ProfileFileUpload from '~/components/FileUpload/ProfileFileUpload';

const EquipmentEditModal = ({
  equipment, onClose,
  title = 'Edit equipment',
}: {
  equipment: Equipment;
  title?: string;
  onClose: () => void;
}) => {
  const isDirty = !isEqual(null, equipment);
  const powerUps = useAppSelector((state) => selectTenantPowerUps(state));

  const [name, setName] = useState(equipment.name ?? '');
  const [nickname, setNickname] = useState(equipment.nickname ?? '');
  const [profilePicture, setProfilePicture] = useState(equipment.profilePicture);
  const [code, setCode] = useState(equipment.code ?? '');
  const [description, setDescription] = useState(equipment.description ?? '');
  const [isAvailable, setIsAvailable] = useState(equipment.isAvailable ?? false);
  const [tags, setTags] = useState(equipment.tags ?? []);
  const [priceId, setPriceId] = useState(equipment.priceId);
  const [hammerEfficiency, setHammerEfficiency] = useState(equipment.hammerEfficiency?.toString() || '');
  const [
    hammerEfficiencyEnabled,
    setHammerEfficiencyEnabled,
  ] = useState(!!equipment.hammerEfficiency);

  const hammerEfficiencyValue = Number(hammerEfficiency);
  const isValid = name
    && (!hammerEfficiency || (
      !Number.isNaN(hammerEfficiencyValue)
      && hammerEfficiencyValue >= 0 && hammerEfficiencyValue <= 100
    ));

  const nameInput = useRef(null);
  useEffect(() => {
    nameInput.current?.focus();
  }, []);

  const { trigger: onSaveClicked, isLoading: isSaving } = useTrackedAction({
    trigger: () => EquipmentActions.save({
      equipment: {
        ...equipment,
        name,
        nickname,
        code,
        description,
        isAvailable,
        tags,
        priceId,
        profilePicture,
        ...(equipment.hammerEfficiency || (hammerEfficiency && !Number.isNaN(hammerEfficiencyValue))
          ? { hammerEfficiency: hammerEfficiencyValue }
          : {}),
      },
    }),
    onSuccess: () => {
      onClose();
    },
  });

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen
      onClose={onClose}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {title}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Grid gap={8}>
            <Box
              position="relative"
              paddingRight="145px"
            >
              <FormControl flex="2" isRequired>
                <FormLabel>Equipment Name</FormLabel>
                <Input
                  placeholder="Enter the name of the equipment"
                  ref={nameInput}
                  value={name}
                  onChange={(e) => { setName(e.target.value); }}
                />
              </FormControl>
              <HStack mt={4}>
                <FormControl flex="1">
                  <FormLabel>Code</FormLabel>
                  <Input
                    placeholder="e.g. E00-01"
                    value={code}
                    onChange={(e) => { setCode(e.target.value); }}
                  />
                </FormControl>

                <FormControl flex="1">
                  <FormLabel>Nickname</FormLabel>
                  <Input
                    placeholder="Add a nickname"
                    value={nickname}
                    onChange={(e) => { setNickname(e.target.value); }}
                  />
                </FormControl>
              </HStack>
              <Box position="absolute" top={0} right={0}>
                <ProfileFileUpload
                  profilePicture={profilePicture}
                  size={130}
                  onFileUploaded={(file) => {
                    setProfilePicture(file);
                  }}
                  onFileRemoved={() => {
                    setProfilePicture(null);
                  }}
                />
              </Box>
            </Box>
            <HStack mt={4}>
              <FormControl flex={2}>
                <FormLabel>Equipment Type</FormLabel>
                <CreatableTagSelect
                  placeholder="Add or create the equipment type"
                  type="equipment"
                  tags={tags}
                  setTags={setTags}
                />
              </FormControl>
              {powerUps.geotechnical
        && (
        <FormControl
          flex={1}
        >
          {hammerEfficiencyEnabled ? (
            <>
              <FormLabel>Hammer Efficiency</FormLabel>
              <InputGroup>
                <Input
                  value={hammerEfficiency}
                  onChange={(e) => {
                    setHammerEfficiency(e.target.value);
                  }}
                  textAlign="right"
                />
                <InputRightAddon>%</InputRightAddon>
              </InputGroup>
            </>
          ) : (
            <LinkButton
              isGreen
              onClick={() => setHammerEfficiencyEnabled(true)}
              noUnderline
              mt={11}
              pt={4}
              pl={4}
            >
              + hammer efficiency
            </LinkButton>
          )}
        </FormControl>
        )}
            </HStack>
            <FormControl>
              <FormLabel>Description</FormLabel>
              <Textarea
                placeholder="Enter key details about the equipment"
                value={description}
                onChange={(e) => { setDescription(e.target.value); }}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Scheduling</FormLabel>
              <FormControl display="flex" alignItems="center">
                <Switch
                  name="is-required"
                  size="lg"
                  isChecked={isAvailable}
                  onChange={(e) => setIsAvailable(e.target.checked)}
                />
                <FormLabel
                  htmlFor="quote-required"
                  mb="0"
                  ml={2}
                  cursor="pointer"
                  textTransform="none"
                  onClick={() => setIsAvailable(!isAvailable)}
                >
                  Available for scheduling
                </FormLabel>
              </FormControl>
            </FormControl>
            <FormControl>
              <RateSearchSelect
                category="equipment"
                value={priceId}
                onChange={(r) => setPriceId(r)}
                placeholder="Select a rate to bill this equipment at"
              />
            </FormControl>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="brandDark"
            isLoading={isSaving}
            disabled={!isDirty || !isValid || isSaving}
            onClick={onSaveClicked}
            size="lg"
          >
            Save
          </Button>
          <Button variant="ghost" onClick={onClose} disabled={isSaving}>cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EquipmentEditModal;
