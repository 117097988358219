import { Flex, Text } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { createFilter } from 'react-select';
import SearchSelect from '~/components/SearchSelect';
import { formatJobCode, formatJobName } from '~/helpers/job';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import { CustomerActions } from '~/redux/customers/actions';
import { selectCustomers } from '~/redux/customers/selectors';
import { JobActions } from '~/redux/jobs/actions';
import { selectJobs } from '~/redux/jobs/selectors';
import { JobStatusInfo, Job } from '~/types/job';
import formatShortAddress from '~/utils/formatShortAddress';

const JOB_STATUSES_TO_SHOW = [
  'quote',
  'quoted',
  'schedule',
  'scheduled',
  'inProgress',
];

export interface JobOption extends Job {
  address: string;

  customerName: string;
}

const getJobName = (option: JobOption) => (option.customerName
  ? `${option.customerName} – ${formatJobName(option)}`
  : formatJobName(option));

const JobDropdownOption = ({ option }: { option: JobOption }) => {
  const statusColor = JobStatusInfo[option.status]?.color ?? 'transparent';

  return (
    <Flex
      fontSize="12px"
      borderLeft="4px"
      borderLeftColor={statusColor}
    >
      <Flex
        py="2"
        px="2"
        textAlign="center"
        borderBottom="1px"
        borderBottomColor="magnetize.ui-2"
      >
        <Text>{formatJobCode(option.code)}</Text>
      </Flex>
      <Flex
        py="2"
        borderBottom="1px"
        borderBottomColor="magnetize.ui-2"
        flex="1"
        direction="column"
        pr="1"
      >
        <Text>{getJobName(option)}</Text>
        <Text opacity="0.7" fontSize="10px">{option.address}</Text>
      </Flex>
    </Flex>
  );
};

interface JobSearchSelectProps {
  value: string;
  onChange: (jobId: string, jobOption: JobOption) => void;
  variant?: 'outline' | 'filled' | 'flushed';
  size?: 'sm' | 'md';
  placeholder?: string;
  autoFocus?: boolean;
  selectRef?: React.Ref<any>;
}

const JobSearchSelect = ({
  value, onChange, variant, size, placeholder, autoFocus, selectRef,
}: JobSearchSelectProps) => {
  const { data: jobs, isLoading } = useTrackedFetch({
    key: 'jobs',
    selector: (state) => selectJobs(state),
    trigger: () => JobActions.fetchAll(),
  });

  const {
    data: customers,
  } = useTrackedFetch({
    trigger: () => CustomerActions.fetchAll(),
    selector: selectCustomers,
    key: 'customers',
  });

  const jobList = useMemo(() => (jobs ?? [])
    .filter((j) => JOB_STATUSES_TO_SHOW.includes(j.status)),
  [jobs]);

  const jobOptions: JobOption[] = useMemo(() => (jobList ?? []).map((job) => ({
    ...job,
    address: formatShortAddress(job.siteAddress),
    customerName: customers?.find((c) => c.id === job.customerId)?.companyName,
  })), [jobList, customers]);

  const selectedJob = useMemo(
    () => jobOptions.find((j) => j.id === value),
    [value, jobOptions],
  );

  return (
    <SearchSelect
      variant={variant}
      size={size}
      options={jobOptions}
      isLoading={isLoading}
      value={selectedJob}
      onChange={(o) => onChange?.(o?.id, o)}
      getOptionLabel={(o) => getJobName(o)}
      getOptionValue={(o) => o.id}
      placeholder={placeholder}
      autoFocus={autoFocus}
      noOptionPadding
      renderOption={JobDropdownOption}
      selectRef={selectRef}
      filterOption={createFilter({
        trim: true,
        matchFrom: 'any',
        ignoreAccents: true,
        ignoreCase: true,
        stringify: (o) => {
          const job = o.data as JobOption;
          return `${formatJobCode(job.code)} ${formatJobName(job)} ${job.customerName} ${job.address}`;
        },
      })}
    />
  );
};

export default JobSearchSelect;
