// This is a little nasty but it's not exported by the index.js of this module.
// We just want the types to define the options.
import { UseLoadScriptOptions } from '@react-google-maps/api/dist/useLoadScript';

const stripe = {
  publishableKey: 'pk_test_51JRQOKBlg8IjIPcZO0DDQFVz8OPeVP6I6Rim50H9bmEbI13tJHb3VYb0w3F7aCdEKAM6y0bZsZyEvOSi4gAXbLHq00LrXRsgWB',
};
const magnetizePhoneNumber = '0800 131 110';
const magnetizeWebsiteUrl = 'https://www.magnetize.co.nz/';
const magnetizeDocsUrl = 'https://help.magnetize.co.nz/';
// Pulling this out ensures referential identity between different calls to the
// hook. The docs for react-google-maps suggest doing this for the libraries array
const googleMaps: UseLoadScriptOptions = {
  // TODO: This only has access to the maps and places APIs.
  googleMapsApiKey: 'AIzaSyB-1BBnR3XlZCkf-RQ4FpZF-NjWTDeduHY',

  // What version should we use?
  version: 'beta',
  libraries: ['places'],
};

const googleRecaptcha = {
  key: '6LcfkfofAAAAAKZd-aMOju8NElaSkZT5yKZ3eAXf',
};
const activeEnvironment = process.env.REACT_APP_FORCE_ENVIRONMENT || window.location.hostname;

// @ts-ignore
window.recaptchaOptions = {
  useRecaptchaNet: true,
};

switch (activeEnvironment) {
  case 'local.magnetize.companyx.nz':
  case 'localhost':
    googleMaps.googleMapsApiKey = 'AIzaSyBEAexMaCwOTF2hEV_Q5CDotFByxAv9aGQ';
    break;
  case 'development.magnetize.companyx.nz':
  case 'luke.magnetize.companyx.nz':
  case 'staging.magnetize.companyx.nz':
    break;
  case 'app.magnetize.co.nz':
    stripe.publishableKey = 'pk_live_51JXGU0FipDClisF3crNR6wRXtnNFxLOkUj1zX8XKIJ2G6MNNpNfcnms6SZqRQPdZlbGyMMDpM8FHUL6I19Oj1AKf00ShrNp1Xg';
    googleMaps.googleMapsApiKey = 'AIzaSyDSnF-vUg6xhF0opIYCFueI2hcHoZfFdmU';
    googleRecaptcha.key = '6Le2qvofAAAAAD3kP3q2xrM901Pn56bqWc1WJnbx';
    break;
  default:
    throw new Error(`Cannot initialize config: unknown environment '${activeEnvironment}`);
}

const mobileStoreLinks = {
  android: 'https://play.google.com/store/apps/details?id=com.magnetize.app',
  ios: 'https://apps.apple.com/us/app/magnetize/id1580875145',
};

export {
  stripe,
  magnetizePhoneNumber,
  magnetizeWebsiteUrl,
  magnetizeDocsUrl,
  googleMaps,
  googleRecaptcha,
  mobileStoreLinks,
};
