import React, { useMemo } from 'react';
import { Box, Text } from '@chakra-ui/react';

import SearchSelect from '~/components/SearchSelect';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import SubscriptionActions from '~/redux/subscription/actions';
import { StaffCognitoGroup } from '~/types/staff';
import { useAppSelector } from '~/redux/store';
import { selectIsTenantTrial } from '~/redux/currentUser/selectors';

interface RateSearchSelectProps {
  value: StaffCognitoGroup;
  placeholder?: string;
  isDisabled?: boolean;
  onChange: (value: StaffCognitoGroup) => void;
}

interface RoleOption {
  value: string;
  label: string;
  cognitoGroup: StaffCognitoGroup;
  description: string;
  priceCents: number;
}

const RateOption = (
  { option: { label, priceCents, description } }
  : { option: Partial<RoleOption> },
) => {
  const isTrial = useAppSelector(selectIsTenantTrial);

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box flexGrow={1}>
        <Text fontWeight="600">{label}</Text>
        <Text fontSize={10}>{description}</Text>
      </Box>
      {!isTrial && <Text>{priceCents === 0 ? 'FREE' : `$${(priceCents / 100).toFixed(0)} / month`}</Text>}
    </Box>
  );
};

const RoleSearchSelect = ({
  placeholder = '', value, isDisabled = false, onChange,
} : RateSearchSelectProps) => {
  const { data: prices, isLoading } = useTrackedFetch({
    key: 'subscriptionPrices',
    trigger: () => SubscriptionActions.fetchPrices(),
    selector: (state) => state.subscription.prices,
  });

  const options = useMemo(
    () => [
      {
        value: 'admin',
        label: 'Full user',
        cognitoGroup: StaffCognitoGroup.Admin,
        description: 'Full access to Magnetize software on web and mobile',
        priceCents: prices.admin.priceCents,
      },
      ...(prices ? [{
        value: 'staff',
        label: 'Staff',
        cognitoGroup: StaffCognitoGroup.Staff,
        description: 'Access to the Magnetize mobile app',
        priceCents: prices.staff.priceCents,
      },
      {
        value: 'contractor',
        label: 'Contractor',
        cognitoGroup: null,
        description: 'No access to magnetize software',
        priceCents: 0,
      }] : []),
    ],
    [prices],
  );

  const selectedValue = useMemo(
    () => options.find((p) => p.cognitoGroup === value),
    [options, value],
  );

  return (
    <SearchSelect
      isDisabled={isDisabled}
      isLoading={isLoading}
      hideSearchIcon
      value={selectedValue}
      onChange={({ cognitoGroup }) => {
        onChange(cognitoGroup);
      }}
      options={options}
      renderOption={RateOption}
      placeholder={placeholder}
    />
  );
};

export default RoleSearchSelect;
