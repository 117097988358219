export const soilTypeImageIds = <const> [
  'basalt',
  'boulders',
  'chalk',
  'clay',
  'conglomerate',
  'gravel',
  'limestone',
  'madeground',
  'mudstone',
  'peat',
  'sand',
  'sandstone',
  'shale',
  'silt',
  'siltstone',
  'greywacke',
  'schist',
  'marineSediment',
  'ecbf',
  'lahar',
  'void',
  'tuff',
  'chaos',
  'custom-1',
  'custom-2',
  'custom-3',
  'custom-4',
  'custom-5',
  'custom-6',
  'custom-7',
  'custom-8',
  'custom-9',
  'custom-10',
  'custom-11',
  'custom-12',
  'blank',
];

export type SoilTypeImageId = typeof soilTypeImageIds[number];

export interface SoilType {
  id: string;
  name: string;
  imageId: SoilTypeImageId;
  isDeleted: boolean;
}
