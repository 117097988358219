import {
  Button, Checkbox, Container, FormControl,
  FormLabel, Grid, Input, Text,
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { MutationStatus, useMutation } from 'react-query';
import * as yup from 'yup';

import LinkButton from '~/components/LinkButton';
import LoginBackground from '~/components/LoginBackground';
import LoginCard from '~/components/LoginCard';
import { PrivacyPolicyLink, TermsOfUseLink } from '~/components/SupportLinks';
import WarningLabel from '~/components/WarningLabel';
import { register, resendWelcomeEmail } from '~/helpers/auth';
import { showToast } from '~/toast';
import { googleRecaptcha } from '~/helpers/config';
import { CountrySearchSelect } from '~/components/CountrySearchSelect';

const SuccessScreen = ({ email } : { email: string }) => {
  const recaptchaRef = useRef<{
    reset:() => void,
    executeAsync: () => Promise<{ token: string }>
  }>();
  const { status, mutate }
  : { status: MutationStatus, mutate: any, error: Error } = useMutation(
    resendWelcomeEmail, {
      onSuccess: () => {
        if (recaptchaRef?.current) {
          recaptchaRef.current.reset();
        }
        showToast({
          description: 'Email sent',
          status: 'success',
        });
      },
      onError: () => {
        if (recaptchaRef?.current) {
          recaptchaRef.current.reset();
        }
        showToast({
          description: 'Error sending email',
          status: 'error',
        });
      },
    },
  );

  const isLoading = status === 'loading';

  return (
    <LoginBackground>
      <LoginCard title="Thanks! We've just emailed you a link.">
        <Container fontSize="14px" width="md" pl={0} ml={0}>
          <Text>
            We&apos;ve just sent an email to
            {' '}
            <b>{email}</b>
            {' '}
            with a link to verify your account and login to Magnetize!
          </Text>

          <Text color="magnetize.text-3" mt={6}>
            Didn&apos;t get the email? Give us a call on
            0800 131 110 or click
            {' '}
            <LinkButton
              isDisabled={isLoading}
              isLoading={isLoading}
              onClick={() => {
                recaptchaRef.current.executeAsync().then((token) => {
                  mutate({ email, token });
                });
              }}
              size="lg"
              isGreen
              noUnderline
            >
              resend
            </LinkButton>
            .
          </Text>
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={googleRecaptcha.key}
          />
        </Container>
      </LoginCard>
    </LoginBackground>
  );
};

const Register = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState<string>();
  const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const recaptchaRef = useRef<{
    reset:() => void,
    executeAsync: () => Promise<{ token: string }>
  }>();

  const emailValid = yup.string().email().required().isValidSync(email);

  const { status, mutate, error }
  : { status: MutationStatus, mutate: any, error: Error } = useMutation(
    register, {
      onSuccess: () => {
        setShowSuccess(true);
        if (recaptchaRef?.current) {
          recaptchaRef.current.reset();
        }
      },
      onError: () => {
        if (recaptchaRef?.current) {
          recaptchaRef.current.reset();
        }
      },
    },
  );

  const isLoading = status === 'loading';
  const hasFilledInForm = name && emailValid && phone && countryCode && hasAgreedToTerms;

  const triggerSignUp = () => {
    if (isLoading || !hasFilledInForm || !recaptchaRef || !recaptchaRef.current) {
      return;
    }

    recaptchaRef.current.executeAsync().then((token) => {
      mutate({
        name, email, phone, countryCode, token,
      });
    });
  };

  if (showSuccess) {
    return <SuccessScreen email={email} />;
  }

  return (
    <LoginBackground>
      <LoginCard title="Try Magnetize for free">
        <Grid
          gap={6}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              triggerSignUp();
            }
          }}
        >
          <Container pl={0} maxW="md" ml={0}>
            <Text fontSize="14px">
              Activate your 30 day free trial
            </Text>
          </Container>
          {error && (
            <WarningLabel message={error?.message} />
          )}
          <FormControl>
            <FormLabel>Name</FormLabel>
            <Input
              autoComplete="off"
              isDisabled={isLoading}
              value={name}
              onChange={(e) => { setName(e.target.value); }}
            />
          </FormControl>
          <FormControl isInvalid={!!email && !emailValid}>
            <FormLabel>Email</FormLabel>
            <Input
              autoComplete="off"
              isDisabled={isLoading}
              value={email}
              onChange={(e) => { setEmail(e.target.value); }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Mobile</FormLabel>
            <Input
              autoComplete="off"
              isDisabled={isLoading}
              value={phone}
              onChange={(e) => { setPhone(e.target.value); }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Country</FormLabel>
            <CountrySearchSelect
              isDisabled={isLoading}
              value={countryCode}
              onChange={setCountryCode}
            />
          </FormControl>
          <FormControl>
            <Checkbox
              isChecked={hasAgreedToTerms}
              onChange={(e) => setHasAgreedToTerms(e.target.checked)}
            >
              <Container
                maxW="md"
                pl={0}
                pt={4}
                cursor="pointer"
              >
                I have read and agree to the
                {' '}
                <TermsOfUseLink />
                {' '}
                and
                {' '}
                <PrivacyPolicyLink />
                .
              </Container>
            </Checkbox>
          </FormControl>
          <FormControl>
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={googleRecaptcha.key}
            />
          </FormControl>
          <Container pl={0}>
            <Button
              isDisabled={!hasFilledInForm}
              isLoading={isLoading}
              size="lg"
              onClick={triggerSignUp}
            >
              Let&#39;s get started!
            </Button>
          </Container>
        </Grid>
      </LoginCard>
    </LoginBackground>
  );
};

export default Register;
