import {
  Box,
  Link, SkeletonText,
  Table,
  Tbody,
  Td, Text, Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/status-bar/dist/style.css';
import { format } from 'date-fns';
import { orderBy, partition } from 'lodash';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import Routes from '~/pages/routes';
import { AdminTenant, AdminTenantSummary } from '~/types/tenant';
import fetchJson from '~/utils/fetchJson';

const TenantRow = ({ summary }: { summary: AdminTenantSummary }) => {
  const { tenantId } = summary;
  const { data: tenant, isLoading } = useQuery({
    queryKey: ['admin-tenant', tenantId],
    queryFn: () => fetchJson<AdminTenant>(`/api/tenant-admin/${tenantId}`),
  });

  const [staff, admins] = useMemo(
    () => partition(tenant?.staff ?? [], (s) => !s.isAdmin),
    [tenant],
  );

  const owner = tenant?.staff?.find((s) => s.id === tenant?.ownerStaffId);

  return (
    <Tr>
      <Td>
        <Link
          as={RouterLink}
          to={Routes.adminTenantDetails({ tenantId: summary.tenantId })}
        >
          {summary.name ?? 'Unnamed organisation'}
        </Link>
        <Text fontSize="sm" color="magnetize.text-4">
          {summary.tenantId}
        </Text>
      </Td>
      <Td>
        {!isLoading
          ? `Staff: ${staff.length}, Admins: ${admins.length}`
          : <SkeletonText noOfLines={1} />}
      </Td>
      <Td>
        {!isLoading
          ? (
            owner && (
            <Box>
              {owner.name}
              <Text fontSize="sm" color="magnetize.text-4">
                {owner.email}
              </Text>
              <Text fontSize="sm" color="magnetize.text-4">
                {owner.phone}
              </Text>
            </Box>
            )
          ) : <SkeletonText noOfLines={2} />}
      </Td>
      <Td>
        <Text>{summary.signUpTimestamp ? format(summary.signUpTimestamp, 'dd-MM-yyyy') : 'unknown'}</Text>
      </Td>
    </Tr>

  );
};

export const TenantsTable = ({ tenants }: { tenants: AdminTenantSummary[] }) => (
  <Table size="md">
    <Thead>
      <Tr>
        <Th w="30%">Tenant Id</Th>
        <Th w="15%"># Users</Th>
        <Th w="40%">Owner</Th>
        <Th w="15%">Sign up</Th>
      </Tr>
    </Thead>
    <Tbody>
      {orderBy((tenants || []), (t) => t.signUpTimestamp || 0, 'desc').map((item) => (
        <TenantRow
          key={item.tenantId}
          summary={item}
        />
      ))}
    </Tbody>
  </Table>
);
