import { RootState } from '~/redux/store';

export const selectFetchState = (state: RootState, key: string) => {
  const fetchState = state.fetch[key];
  const status = fetchState?.status ?? 'idle';
  const isInvalid = fetchState?.isInvalid ?? true;
  return {
    status,
    isInvalid,
    isLoading: isInvalid && status === 'loading',
    isPartiallyComplete: fetchState?.isPartiallyComplete ?? false,
    isError: status === 'error',
    errorMessage: fetchState?.errorMessage ?? '',
    errorType: fetchState?.errorType ?? '',
    lastUpdatedAt: fetchState?.lastUpdatedAt ?? 0,
  };
};

interface DoesNeedRefetchOptions {
  staleTime?: number;
}

export const selectDoesNeedRefetch = (
  state: RootState, key: string,
  { staleTime }: DoesNeedRefetchOptions = {},
) => {
  const fetchState = state.fetch[key];
  if (!fetchState) {
    return true;
  }

  const status = fetchState?.status ?? 'idle';
  const isFreshEnough = !fetchState.isInvalid
    && staleTime && Date.now() - fetchState.lastUpdatedAt <= staleTime;

  return status !== 'loading' && !isFreshEnough;
};
