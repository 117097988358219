import { useMemo } from 'react';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import { EquipmentActions } from '~/redux/equipment/actions';
import { selectEquipment } from '~/redux/equipment/selectors';
import StaffActions from '~/redux/staff/actions';
import { selectAllStaff } from '~/redux/staff/selectors';
import { ResourceIdentifier, ResourceSummary } from '~/types/resource';

export const useResourcesQuery = () => {
  const { data: staff, isLoading: isLoadingStaff } = useTrackedFetch({
    key: 'staff',
    selector: selectAllStaff,
    trigger: () => StaffActions.fetch(),
  });

  const allStaff = useMemo(() => staff?.map((s) => (<ResourceSummary>{
    id: s.id,
    type: 'staff',
    name: s.name,
    displayName: s.name,
    subtitle: s.jobTitle,
    isAvailable: s.isAvailable,
    isDeleted: s.isDeleted,
    isAdmin: s.cognitoGroup === 'Admin',
    tags: s.tags ?? [],
  })), [staff]);

  const { data: equipment, isLoading: isLoadingEquipment } = useTrackedFetch({
    key: 'equipment',
    selector: selectEquipment,
    trigger: () => EquipmentActions.fetch(),
  });

  const allEquipment = useMemo(() => equipment?.map((eq) => (<ResourceSummary>{
    id: eq.id,
    type: 'equipment',
    name: eq.name,
    displayName: eq.nickname ? `${eq.nickname} (${eq.name})` : eq.name,
    nickname: eq.nickname,
    subtitle: undefined,
    isAvailable: eq.isAvailable,
    isDeleted: eq.isDeleted,
    tags: eq.tags ?? [],
  })), [equipment]);

  const combinedResources = useMemo(() => [
    ...allStaff ?? [],
    ...allEquipment ?? [],
  ], [allStaff, allEquipment]);

  return {
    data: combinedResources,
    isLoading: isLoadingStaff || isLoadingEquipment,
  };
};

export const useResourceSummary = (resource: ResourceIdentifier) => {
  // TODO: maybe this should be moved into Redux because these lookups need to
  // happen from a number of views and queries probably have more overhead than
  // a selector.
  //
  // Putting it into a custom hook was a first step to avoid duplicating the logic
  const { data, isLoading } = useResourcesQuery();
  const fetchedResource = useMemo(
    // Hack: don't match the resource type for now because the existing event data
    // doesn't have the type specified correctly
    () => data?.find((r) => resource && r.id === resource.id /* && r.type === resource.type */),
    [data, resource?.id, resource?.type],
  );

  return {
    data: fetchedResource,
    isLoading,
  };
};
