import {
  Alert, Box, Flex, GridItem,
  Heading, HStack, Stack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import Card from '~/components/Card';
import StatusBadge from '~/components/JobStatusBadge';
import JobSyncStatusIndicator from '~/components/JobSyncStatusIndicator';
import PageTabs from '~/components/PageTabs';
import SkeletonJobPage from '~/components/SkeletonJobPage';
import { formatJobCode } from '~/helpers/job';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import PageHeader from '~/layouts/PageHeader';
import PrimarySecondaryColumns from '~/layouts/PrimarySecondaryColumns';
import JobOptionsMenu from '~/pages/Job/components/JobOptionsMenu';
import JobSidePanel from '~/pages/Job/components/JobSidePanel';
import JobDetailsTab from '~/pages/Job/tabs/JobDetailsTab';
import JobFilesTab from '~/pages/Job/tabs/JobFilesTab';
import JobInvoiceTab from '~/pages/Job/tabs/JobInvoiceTab';
import JobLogsTab from '~/pages/Job/tabs/JobLogsTab';
import JobQuoteTab from '~/pages/Job/tabs/JobQuoteTab';
import JobBudgetTab from '~/pages/Job/tabs/JobBudgetTab';
import JobScheduleTab from '~/pages/Job/tabs/JobScheduleTab';
import Routes from '~/pages/routes';
import { triggerJobFetch } from '~/redux/jobs/actions';
import { selectJobWithEdits } from '~/redux/jobs/selectors';
import { QuoteActions } from '~/redux/quote/actions';
import { selectQuotesByJobId } from '~/redux/quote/selectors';
import { useAppDispatch } from '~/redux/store';
import { useRouteParams } from '~/utils/RouteGenerator';
import JobTimeline from './components/JobTimeline';
import { NewBadge } from '~/components/Badge';

const makeTabs = (jobId: string, isBudget:boolean) => [
  { title: 'Job Details', path: Routes.jobDetails({ jobId }) },
  isBudget
    ? { title: 'Budget', path: Routes.jobBudget({ jobId }) }
    : { title: 'Quote', path: Routes.jobQuote({ jobId }) },
  { title: 'Schedule', path: Routes.jobSchedule({ jobId }) },
  { title: 'Files', path: Routes.jobFiles({ jobId }) },
  { title: 'Logs', path: Routes.jobLogs({ jobId }) },
  { title: 'Invoice', path: Routes.jobInvoice({ jobId }) },
];

const JobPage = () => {
  const dispatch = useAppDispatch();
  const { jobId } = useRouteParams(Routes.job);

  const { data: job, isLoading, isError } = useTrackedFetch({
    key: jobId,
    trigger: () => triggerJobFetch({ jobId }),
    selector: (s) => selectJobWithEdits(s, jobId),
    equalityMode: 'deep',
  });

  const { data: quotes } = useTrackedFetch({
    key: `job-quotes-${jobId}`,
    trigger: () => QuoteActions.fetchForJob({ jobId }),
    selector: (state) => selectQuotesByJobId(state, jobId),
  });

  // Check if any quotes with status other than 'draft' exist:
  const hasOnlyDraftQuotes = quotes?.every((q) => q.status === 'draft');

  useEffect(() => {
    dispatch(QuoteActions.fetchForJob({ jobId }));
  }, []);

  const [tabs, setTabs] = useState(makeTabs(jobId, false));

  useEffect(() => {
    if (job?.needsQuote || !hasOnlyDraftQuotes) {
      setTabs(makeTabs(jobId, false));
    } else {
      setTabs(makeTabs(jobId, true));
    }
  }, [job, hasOnlyDraftQuotes]);

  if (!job && isError) {
    return <Alert>Job not found</Alert>;
  }

  if (!job || isLoading) {
    return <SkeletonJobPage />;
  }

  const isCreatingJob = job.status === 'draft';
  return (
    <>
      <PrimarySecondaryColumns maxWidth="1280px">
        <PageHeader backButton>
          <Flex mb={6} direction="column">
            <Flex direction="row" alignItems="center">
              <Heading size="lg" mb={2}>
                {isCreatingJob ? 'New Job' : `${formatJobCode(job.code)} - ${job.name}`}
              </Heading>
              <Flex pl={2} pb={2}>
                {job.isNewJob ? <NewBadge /> : undefined}
              </Flex>
            </Flex>
            <Flex justifyContent="space-between">
              <HStack>
                <StatusBadge status={job.status} />
                <JobSyncStatusIndicator />
              </HStack>
            </Flex>
          </Flex>
          <Box mb={-6}>
            <PageTabs
              tabs={tabs}
            >
              <JobOptionsMenu jobId={jobId} />
            </PageTabs>
          </Box>
        </PageHeader>
        <GridItem gridArea="primary">
          <Stack spacing={8}>
            <Card minH="345px" padding={12}>
              <Switch>
                <Route path={Routes.jobDetails({ jobId: job.id })}>
                  <JobDetailsTab jobId={jobId} />
                </Route>
                <Route path={Routes.jobQuote({ jobId: job.id })}>
                  <JobQuoteTab jobId={jobId} />
                </Route>
                <Route path={Routes.jobBudget({ jobId: job.id })}>
                  <JobBudgetTab jobId={jobId} />
                </Route>
                <Route path={Routes.jobSchedule({ jobId: job.id })}>
                  <JobScheduleTab jobId={job.id} />
                </Route>
                <Route path={Routes.jobLogs({ jobId: job.id })}>
                  <JobLogsTab jobId={job.id} />
                </Route>
                <Route path={Routes.jobInvoice({ jobId: job.id })}>
                  <JobInvoiceTab jobId={jobId} />
                </Route>
                <Route path={Routes.jobFiles({ jobId: job.id })}>
                  <JobFilesTab jobId={jobId} />
                </Route>
                <Route path="*" render={() => <Redirect to={Routes.jobDetails({ jobId: job.id })} />} />
              </Switch>
            </Card>
            {!isCreatingJob && (
              <Box>
                <Heading as="h3" size="md" mb={8} pl={16}>Job Timeline</Heading>
                <JobTimeline jobId={jobId} />
              </Box>
            )}
          </Stack>
        </GridItem>
        <GridItem gridArea="secondary">
          <JobSidePanel jobId={jobId} />
        </GridItem>
      </PrimarySecondaryColumns>
    </>
  );
};

export default JobPage;
