import { createAction } from '@reduxjs/toolkit';
import { Currency } from '~/types/currency';

const CurrenciesActions = {
  fetch: createAction('currencies/fetch'),
  loaded: createAction<{ currencies: Currency[] }>('currencies/loaded'),
  setDefault: createAction<{ currency: Currency }>('currencies/setDefault'),
};

export default CurrenciesActions;
