import { createAction } from '@reduxjs/toolkit';
import { ScheduleEvent } from '~/types/ScheduleEvent';

const CalendarTourActions = {
  startTour: createAction('calendarTour/start'),
  complete: createAction('calendarTour/complete'),

  setStep: createAction<{ step: number }>('calendarTour/setStep'),
  setEvent: createAction<{ event: ScheduleEvent }>('calendarTour/setEvent'),
  setHighlightDay: createAction<{ start: number, end: number }>('calendarTour/setHighlightDay'),

  eventPopoverOpened: createAction('calendarTour/eventPopoverOpened'),
  jobAssignedToEvent: createAction('calendarTour/jobAssignedToEvent'),
  resourceAssignedToEvent: createAction('calendarTour/resourceAssignedToEvent'),
  eventCreated: createAction<{ event: ScheduleEvent }>('calendarTour/eventCreated'),
  eventUpdated: createAction<{ event: ScheduleEvent }>('calendarTour/eventUpdated'),
  eventsFiltered: createAction<{ event: ScheduleEvent }>('calendarTour/eventsFiltered'),
  resourcesViewSelected: createAction<{ event: ScheduleEvent }>('calendarTour/resourcesViewSelected'),
};

export default CalendarTourActions;
