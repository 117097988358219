import { isEmpty, mapValues } from 'lodash';
import { call, put } from 'redux-saga/effects';
import { jobFetchSuccess } from '~/redux/jobs/actions';
import { patchJob } from '~/api/jobApi';
import { Job } from '~/types/job';

export default function* doPatchJob(jobId: string, patch: Partial<Job>) {
  if (!isEmpty(patch)) {
    const updatedEntity: Job = yield call(() => patchJob(
      jobId,
      mapValues(patch, (value) => value ?? null) as Partial<Job>,
    ));
    yield put(jobFetchSuccess({ jobId, data: updatedEntity }));
  }
}
