import { CloseIcon } from '@chakra-ui/icons';
import {
  Flex, IconButton, Stack, Text,
} from '@chakra-ui/react';
import React, { useMemo } from 'react';
import CalendarDayPicker from '~/components/CalendarDayPicker';
import ResourceAvatar from '~/components/ResourceAvatar';
import StaffAndEquipmentSelect from '~/components/StaffAndEquipmentSelect';
import { ResourceEventSummary } from '~/types/resource';

interface ResourceListProps {
  resources: ResourceEventSummary[];
  onAddResource: (resource: ResourceEventSummary) => void;
  onRemoveResource: (resource: ResourceEventSummary) => void;
  onEditResource?: (
    resource: ResourceEventSummary,
    intervals: { start: number, end: number }[]) => void;
  showAvailability?: boolean;
  minDate?: Date;
  maxDate?: Date;
}

const ResourceList = ({
  resources,
  onAddResource,
  onRemoveResource,
  onEditResource = () => {},
  showAvailability = false,
  minDate = null,
  maxDate = null,
}: ResourceListProps) => {
  const resourcesToHide = useMemo(() => resources.map((r) => r.id), [resources]);

  return (
    <>
      <div data-calendar-tour-id="calendar-add-people-and-equipment">
        <StaffAndEquipmentSelect
          showAvailability={showAvailability}
          availabilityStart={minDate}
          availabilityEnd={maxDate}
          hiddenResourceIds={resourcesToHide}
          onChange={onAddResource}
          closeMenuOnSelect={false}
          variant="filled"
          hideSearchIcon
          placeholder="Add people and equipment"
        />
      </div>
      {resources.length > 0 && (
      <Stack spacing="2" mt="4">
        {resources.sort((a, b) => a.displayName.localeCompare(b.displayName)).map((r) => (
          <Flex key={r.id} alignItems="center">
            <ResourceAvatar size="sm" resource={r} />
            <Text flex="1" ml="2">
              {r.displayName}
            </Text>
            {minDate && maxDate && (
            <CalendarDayPicker
              value={r.resourceEvents}
              onChange={(intervals) => {
                onEditResource(r, intervals);
              }}
              minDate={minDate}
              maxDate={maxDate}
            />
            )}
            <IconButton
              aria-label={`Remove ${r.name} from job event`}
              size="xs"
              mr="2"
              variant="ghost"
              icon={<CloseIcon color="gray.300" h="0.75em" w="0.75em" />}
              onClick={() => onRemoveResource(r)}
            />
          </Flex>
        ))}
      </Stack>
      )}
    </>
  );
};

export default ResourceList;
