import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftElement, Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { groupBy } from 'lodash';
import React, { Fragment, useMemo, useState } from 'react';
import Fuse from 'fuse.js';
import CurrencyText from '~/components/CurrencyText';
import { useAppSelector } from '~/redux/store';
import { PowerUpId } from '~/types/subscription';

const fuseSettings = {
  threshold: 0.3,
  keys: [
    { name: 'name', weight: 2 },
    { name: 'category', weight: 1 },
    { name: 'description', weight: 1 },
  ],
};

export const PowerUpBrowseModal = ({
  onClose,
  onSelected,
} : {
  onClose: () => void;
  onSelected: (powerUpId: PowerUpId) => void;
}) => {
  const prices = useAppSelector(
    (state) => ((state.subscription?.prices?.powerUps ?? [])).filter((p) => p.isBrowsable),
  );

  const [searchTerm, setSearchTerm] = useState('');

  const fusedPrices = useMemo(
    () => new Fuse(prices, fuseSettings),
    [prices, fuseSettings],
  );

  const visiblePrices = useMemo(
    () => {
      if (searchTerm.length === 0) {
        return prices;
      }
      return fusedPrices.search(searchTerm).map((p) => p.item);
    },
    [fusedPrices, searchTerm],
  );

  return (
    <Modal
      isOpen
      closeOnOverlayClick={false}
      onClose={onClose}
      size="2xl"
      scrollBehavior="inside"
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Browse all Magnetize power ups
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pt={0}>
          <Box
            position="sticky"
            top={0}
            zIndex={1}
            backgroundColor="white"
            pb={8}
            pt={1}
          >
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.300" />
              </InputLeftElement>
              <Input
                placeholder="Search equipment, labour and costs"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </InputGroup>
          </Box>
          <Flex direction="column" overflow="auto">
            { Object.entries(groupBy(visiblePrices, (p) => p.category)).map(
              ([category, categoryPrices]) => (
                <Fragment key={category}>
                  <Flex
                    borderColor="magnetize.ui-3"
                    borderBottomWidth={1}
                    py={4}
                  >
                    <Heading size="sm">{category}</Heading>
                  </Flex>
                  { categoryPrices.map((price) => (
                    <Flex
                      key={price.powerUpId}
                      cursor="pointer"
                      onClick={() => onSelected(price.powerUpId)}
                      alignItems="center"
                      py={3}
                      borderColor="magnetize.ui-3"
                      borderBottomWidth={1}
                      justifyContent="space-between"
                      _hover={{
                        backgroundColor: '#fafafa',
                      }}
                    >
                      <Image src={price.image} h="33px" w="33px" mr={5} />
                      <Box>
                        <Text size="lg" fontWeight="bold">{price.name}</Text>
                        <Text size="sm" color="magnetize.text-3">{price.description}</Text>
                      </Box>
                      <Spacer />
                      <Flex>
                        {price.isActive
                          ? <Text color="magnetize.brand-4">enabled</Text>
                          : (
                            <>
                              {price.priceCents > 0 ? (
                                <Text>
                                  <CurrencyText value={price.priceCents} />
                                  {' '}
                                  / month
                                </Text>
                              ) : 'free'}
                            </>
                          ) }

                      </Flex>
                    </Flex>
                  )) }
                </Fragment>
              ),
            )}
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button
            size="lg"
            onClick={onClose}
          >
            DONE
          </Button>
          <Button variant="ghost" onClick={onClose}>cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
