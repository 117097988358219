import React from 'react';

import {
  Text,
  Box,
  Spacer,
  Image,
} from '@chakra-ui/react';
import LinkButton from '~/components/LinkButton';
import { SubscriptionPaymentMethod } from '~/types/subscription';

const PaymentMethod = (
  { openPaymentMethodModal, paymentMethod }
  :{ openPaymentMethodModal: () => void, paymentMethod: SubscriptionPaymentMethod },
) => {
  let ccLogo;
  switch (paymentMethod?.brand) {
    case 'amex':
    case 'visa':
    case 'mastercard':
      ccLogo = paymentMethod.brand;
      break;
    default:
      ccLogo = 'unknown';
  }
  return (
    <Box
      borderWidth={1}
      borderColor="gray.200"
      padding={3}
      display="flex"
      flexDirection="row"
      alignItems="center"
    >
      {paymentMethod
        ? (
          <>
            <Image
              height="18px"
              src={`/images/cards/card-logo-${ccLogo}.svg`}
              alt={paymentMethod.brand}
              marginRight={3}
            />
            <Text>{`${paymentMethod.brand.replace(/^\w/, (c) => c.toUpperCase())} ending xxxx ${paymentMethod.last4}`}</Text>
            <Text marginLeft={5}>
              {`${paymentMethod.exp_month.toString().padStart(2, '0')}/${(paymentMethod.exp_year % 100).toString().padStart(2, '0')}`}
            </Text>
          </>
        )
        : <Text>No payment information entered yet</Text>}
      <Spacer />
      <LinkButton
        isGreen
        noUnderline
        onClick={() => { openPaymentMethodModal(); }}
      >
        {paymentMethod ? 'edit' : 'add payment method'}
      </LinkButton>
    </Box>
  );
};

export default PaymentMethod;
