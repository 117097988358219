import {
  MenuDivider, MenuItem, Table, Tbody,
  Td, Text, Th, Thead, Tr,
} from '@chakra-ui/react';
import React from 'react';
import Address from '~/components/Address';
import ConfirmationDialog from '~/components/ConfirmationDialog';
import { KebabOptionsMenu } from '~/components/KebabOptionsMenu';
import { useModalManager } from '~/components/ModalManager';
import { CustomerActions } from '~/redux/customers/actions';
import { useAppDispatch } from '~/redux/store';
import { CustomerWithContact } from '~/types/customer';
import eventStopper from '~/utils/eventStopper';

interface CustomerListRowProps {
  customer: CustomerWithContact;
  onSelected: (c : CustomerWithContact) => void;
  onArchived: (c: CustomerWithContact) => void;
  onAddContact: (c: CustomerWithContact) => void;
}

const CustomerListRow = React.memo((
  {
    customer, onSelected, onArchived, onAddContact,
  } :
  CustomerListRowProps,
) => {
  const modalManager = useModalManager();
  return (
    <Tr
      role="group"
      _hover={{ bg: '#fafafa', cursor: 'pointer' }}
      transition="color 0.1s ease-out"
      key={customer.id}
      onClick={() => {
        onSelected(customer);
      }}
    >
      <Td>
        <Text>
          {customer.companyName}
        </Text>
      </Td>
      <Td>
        <Text>
          {customer.mainContact?.name}
        </Text>
        <Text>
          {customer.mainContact?.email}
        </Text>
        <Text>
          {customer.mainContact?.phone}
        </Text>
      </Td>
      <Td>
        <Address value={customer.companyAddress} />
      </Td>
      <Td
        textAlign="end"
        {...eventStopper()}
      >
        <KebabOptionsMenu>
          <MenuItem onClick={() => onSelected(customer)}>Edit</MenuItem>
          <MenuItem onClick={() => onAddContact(customer)}>Add contact</MenuItem>
          <MenuDivider my={0} />
          <MenuItem onClick={() => {
            modalManager.open(ConfirmationDialog, {
              title: 'Delete customer',
              children: 'This customer will be deleted from Magnetize. You will no longer be able to add them to jobs.',
              onConfirm: () => {
                onArchived(customer);
              },
              confirmButtonLabel: 'Delete',
            });
          }}
          >
            Delete
          </MenuItem>
        </KebabOptionsMenu>
      </Td>
    </Tr>
  );
});

interface CustomerListProps {
  customers: CustomerWithContact[];
  setSelected: (customer: CustomerWithContact) => void;
  addContact: (customer: CustomerWithContact) => void;
}

const CustomerList = React.memo(({ customers, setSelected, addContact }: CustomerListProps) => {
  const dispatch = useAppDispatch();

  return (
    <Table size="md">
      <Thead borderColor="gray.200">
        <Tr>
          <Th>Company Name</Th>
          <Th>Main Contact</Th>
          <Th>Address</Th>
          <Th width="10%" />
        </Tr>
      </Thead>
      <Tbody>
        { customers.map((customer) => (
          <CustomerListRow
            key={customer.id}
            customer={customer}
            onSelected={setSelected}
            onAddContact={addContact}
            onArchived={({ id }) => {
              dispatch(CustomerActions.archive({
                customerId: id,
              }));
            }}
          />
        ))}
      </Tbody>
    </Table>
  );
});

export default CustomerList;
