import { omit } from 'lodash';
import { put, takeEvery, call } from 'redux-saga/effects';
import { jobFetchSuccess, saveJob } from '~/redux/jobs/actions';
import { showToast } from '~/toast';
import queueWithLocalEdit from '~/utils/queueWorkWithLocalEdit';
import { saveJob as doSaveJob } from '~/api/jobApi';
import queryClient from '~/queryClient';
import { jobTimelineQuery } from '~/queries/jobTimeline';

const handleSaveJob = takeEvery(
  saveJob,
  function* handle(action) {
    const jobId = action.payload.data.id;
    const dataToPatch = omit(action.payload.data, 'id');
    yield queueWithLocalEdit(jobId, dataToPatch,
      function* worker() {
        try {
          const updatedEntity = yield doSaveJob(jobId);
          yield put(jobFetchSuccess({ jobId, data: updatedEntity }));
          yield call(() => queryClient.invalidateQueries(jobTimelineQuery(jobId)));
        } catch (error) {
          showToast({
            id: jobId,
            title: 'Job Sync Error',
            description: error instanceof Error ? error.message : String(error),
            status: 'error',
            duration: 10000,
            isClosable: true,
          });
        }
      });
  },
);

export default handleSaveJob;
