import { without } from 'lodash';
import { RootState } from '~/redux/reducer';
import { backfillTypeImageIds } from '~/types/backfillType';

export const getActiveBackfillTypes = (state: RootState) => Object
  .values(state.backfillTypes.data.byId)
  .filter((x) => !x.isDeleted);

export const getAvailableBackfillImages = (state: RootState) => without(
  backfillTypeImageIds, 'blank', ...getActiveBackfillTypes(state).map((x) => x.imageId),
);
