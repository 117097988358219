import { omit } from 'lodash';
import { Job } from '~/types/job';
import fetchJson from '~/utils/fetchJson';

export const getJob = (jobId: string) => fetchJson<Job>(`/api/jobs/${jobId}`);

export const bulkFetchJobs = (jobIds: string[]) => (
  fetchJson<Job[]>(`/api/jobs/by-ids/${jobIds.join(',')}`)
);

export const patchJob = (jobId: string, changes: Partial<Job>) => {
  const omits = ['id', 'timeline', 'status'];

  const body : Partial<Job> = omit(changes, ...omits);
  if (Object.keys(body).length === 0) {
    return getJob(jobId);
  }

  return fetchJson<Job>(
    `/api/jobs/${jobId}`,
    {
      method: 'PATCH',
      body,
    },
  );
};

export const createJob = () => fetchJson<Job>('/api/jobs/', {
  method: 'POST',
});

export const saveJob = (jobId: string) => fetchJson<Job>(`/api/jobs/${jobId}/save`, {
  method: 'POST',
});

export const completeJob = (jobId: string) => fetchJson<Job>(`/api/jobs/${jobId}/complete?includeUpdated=true`, {
  method: 'POST',
});

export const reopenJob = (jobId: string) => fetchJson<Job>(`/api/jobs/${jobId}/reopen?includeUpdated=true`, {
  method: 'POST',
});

export const closeJob = (jobId: string) => fetchJson<Job>(`/api/jobs/${jobId}/close?includeUpdated=true`, {
  method: 'POST',
});

export const holdJob = (jobId: string) => fetchJson<Job>(`/api/jobs/${jobId}/hold?includeUpdated=true`, {
  method: 'POST',
});

export const startJob = (jobId: string) => fetchJson<Job>(`/api/jobs/${jobId}/start?includeUpdated=true`, {
  method: 'POST',
});

export const archiveJob = (jobId: string) => fetchJson<Job>(`/api/jobs/${jobId}/archive?includeUpdated=true`, {
  method: 'POST',
});
