import {
  Button,
  Modal, ModalBody, ModalCloseButton,
  ModalContent, ModalFooter, ModalHeader, ModalOverlay,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useAppDispatch } from '~/redux/store';
import { updateRefusalReason } from '~/redux/refusalReasons/actions';
import { RefusalReason } from '~/types/refusalReason';
import { RefusalReasonForm } from './RefusalReasonForm';

export const RefusalReasonEditModal = ({
  refusalReason,
  title = 'Edit refusal reason',
  onClose = () => {},
}: {
  refusalReason: Partial<RefusalReason>,
  title: string,
  onClose: () => void
}) => {
  const dispatch = useAppDispatch();
  const [editedRefusalReason, setEditedRefusalReason] = useState(refusalReason);

  const canSave = editedRefusalReason.name;
  const onSaveClicked = () => {
    dispatch(updateRefusalReason(editedRefusalReason as RefusalReason));
    onClose();
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen
      onClose={onClose}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {title}
        </ModalHeader>
        <ModalCloseButton tabIndex={-1} />
        <ModalBody>
          <RefusalReasonForm
            value={editedRefusalReason}
            onChange={(dt) => {
              setEditedRefusalReason({
                ...editedRefusalReason,
                ...dt,
              });
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={!canSave}
            colorScheme="brandDark"
            onClick={onSaveClicked}
            size="lg"
          >
            Save
          </Button>
          <Button tabIndex={-1} variant="ghost" onClick={onClose}>cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
