/**
 * The Prefs slice is for persistent app preferences.
 *
 * These will be saved into local storage using redux persist
 */

import { createReducer } from '@reduxjs/toolkit';
import CalendarTourActions from '~/redux/calendarTour/actions';
import JobCalendarActions from '~/redux/jobCalendar/jobCalendar';
import PrefsActions from '~/redux/prefs/actions';
import ResourceCalendarActions from '~/redux/resourceCalendar/actions';
import { MagnetizePrefs } from '~/types/prefs';

const prefsReducer = createReducer<MagnetizePrefs>({
  resourceCalendarResources: [],
  jobCalendarShowResources: false,
  jobLogsExpandedSections: ['time'],
  jobCalendarAdminRowExpanded: false,
  sideNavExpanded: true,
  dismissedAlerts: {},
  jobCalendarTourCompleted: false,
  trialBannerDismissedAt: null,
  jobCalendarFilterTags: null,
}, (builder) => (builder
  .addCase(ResourceCalendarActions.setResources, (state, action) => ({
    ...state,
    resourceCalendarResources: action.payload.resources,
  }))

  .addCase(JobCalendarActions.setShowResources, (state, action) => ({
    ...state,
    jobCalendarShowResources: action.payload.showResources,
  }))

  .addCase(JobCalendarActions.setFilterTags, (state, action) => ({
    ...state,
    jobCalendarFilterTags: action.payload.tags,
  }))

  .addCase(CalendarTourActions.complete, (state) => ({
    ...state,
    jobCalendarTourCompleted: true,
  }))

  .addCase(PrefsActions.set, (state, action) => ({
    ...state,
    ...action.payload.prefs,
  }))

  .addCase(PrefsActions.dismissAlert, (state, action) => ({
    ...state,
    dismissedAlerts: {
      ...state.dismissedAlerts,
      [action.payload.id]: true,
    },
  }))

  .addCase(PrefsActions.dismissTrialBanner, (state) => ({
    ...state,
    trialBannerDismissedAt: new Date().getTime(),
  }))
));

export default prefsReducer;
