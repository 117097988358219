import React, { } from 'react';
import {
  SkeletonText, Link, Table, Thead, Tr, Th, Tbody, Td, Text,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import SubscriptionActions from '~/redux/subscription/actions';

const SubscriptionInvoices = () => {
  const { data: invoices, isLoading } = useTrackedFetch({
    key: 'subscriptionInvoices',
    trigger: () => SubscriptionActions.fetchInvoices(),
    selector: (state) => state.subscription.invoices,
  });
  if (isLoading) {
    return <SkeletonText />;
  }
  return (
    <Table size="md">
      <Thead>
        <Tr>
          <Th width={100}>Date</Th>
          <Th>Description</Th>
          <Th width={120}>Total (NZD)</Th>
          <Th width={100} />
        </Tr>
      </Thead>
      <Tbody>
        { invoices.map((item) => (
          <Tr
            key={item.id}
          >
            <Td>
              <Text>
                {format(item.date, 'MMM dd, yyyy')}
              </Text>
            </Td>
            <Td>
              <Text>
                Magnetize
              </Text>
            </Td>
            <Td>
              <Text>
                {`$${(item.amount / 100).toFixed(2)}`}
              </Text>
            </Td>
            <Td textAlign="right">
              <Link isExternal href={item.url}>view</Link>
            </Td>
          </Tr>
        ))}
        {invoices.length === 0 && (
        <Tr><Td colSpan={4}><Text color="gray.300">Invoices will appear here</Text></Td></Tr>
        )}
      </Tbody>
    </Table>
  );
};

export default SubscriptionInvoices;
