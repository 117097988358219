import { all, delay } from 'redux-saga/effects';
import handleConsumables from '~/redux/consumables/saga';
import handleCognitoUser from '~/redux/currentUser/saga';
import customerQuoteSaga from '~/redux/customerQuotes/saga';
import fetchCustomerTenant from '~/redux/customerTenant/saga';
import equipmentSaga from '~/redux/equipment/saga';
import handleCustomers from '~/redux/customers/saga';
import handleInvoices from '~/redux/invoices/saga';
import handleJobs from '~/redux/jobs/saga';
import { handleLogEntries } from '~/redux/logs/saga';
import handlePrices from '~/redux/prices/saga';
import handlePriceBundles from '~/redux/priceBundle/saga';
import queueDispatcher from '~/redux/queuedTasks/saga';
import handleQuotes from '~/redux/quote/saga';
import handleScheduleActions from '~/redux/schedule/saga';
import handleStaff from '~/redux/staff/saga';
import tagsSaga from '~/redux/tags/saga';
import handleTime from '~/redux/timesheets/saga';
import handleXero from '~/redux/xero/saga';
import handleSubscription from '~/redux/subscription/saga';
import handleWelcomeModal from '~/redux/welcomeModal/saga';
import handleCalendarTour from '~/redux/calendarTour/saga';
import handleLiveGeo from '~/redux/liveGeo/saga';
import handleCustomerLiveGeo from '~/redux/customerLiveGeo/saga';
import handleTaxRates from '~/redux/taxRates/saga';
import handleCurrencies from '~/redux/currencies/saga';
import handleDrillingTechniques from '~/redux/drillingTechniques/saga';
import handleSoilTypes from '~/redux/soilTypes/saga';
import handleClearanceTechniques from '~/redux/clearanceTechniques/saga';
import handleCasingTypes from '~/redux/casingTypes/saga';
import handleRefusalReasons from '~/redux/refusalReasons/saga';
import handleSurfaceTypes from '~/redux/surfaceTypes/saga';
import handleBackfillTypes from '~/redux/backfillTypes/saga';
import handlePiezoTypes from '~/redux/piezoTypes/saga';

import { showToast } from '~/toast';
import watchInit from './init.saga';
import customerContactSaga from '~/redux/customerContacts/saga';

export default function* rootSaga() {
  while (true) {
    try {
      yield all([
        watchInit(),
        queueDispatcher(),
        handleJobs(),
        handleQuotes(),
        handleScheduleActions(),
        handleCognitoUser(),
        tagsSaga(),
        handleTime(),
        handleLogEntries(),
        handleConsumables(),
        customerQuoteSaga(),
        fetchCustomerTenant(),
        handleInvoices(),
        handlePrices(),
        equipmentSaga(),
        handleCustomers(),
        handleStaff(),
        handleXero(),
        handleSubscription(),
        handleWelcomeModal(),
        handleCalendarTour(),
        handlePriceBundles(),
        customerContactSaga(),
        handleLiveGeo(),
        handleCustomerLiveGeo(),
        handleTaxRates(),
        handleCurrencies(),
        handleDrillingTechniques(),
        handleSoilTypes(),
        handleClearanceTechniques(),
        handleCasingTypes(),
        handleRefusalReasons(),
        handleSurfaceTypes(),
        handleBackfillTypes(),
        handlePiezoTypes(),
      ]);
    } catch (error) {
      showToast({
        title: 'Bug: unhandled error in saga',
        description: `Check the browser console for more details. ${String(error)}`,
        status: 'error',
        isClosable: true,
        duration: 10000,
      });

      // eslint-disable-next-line no-console
      console.error('Unhandled error in root saga', error);

      // A little delay here to avoid busy looping if something goes really wrong.
      yield delay(1000);
    }
  }
}
