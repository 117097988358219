import { createAction, createReducer, nanoid } from '@reduxjs/toolkit';
import { Dictionary } from '~/types/helpers';
import { LocalEdit } from '~/types/localEdit';

interface LocalChangesState {
  [key: string]: LocalEdit[];
}

export const addLocalEdit = createAction(
  'localEdits/add',
  ({ id, patch }: { id: string, patch: Dictionary<any> }) => ({
    payload: {
      id,
      patch,
      editId: nanoid(),
    },
  }),
);

export const removeLocalEdit = createAction<{ id: string, editId: string }>('localEdits/remove');

export const localEditsReducer = createReducer<LocalChangesState>(
  {},

  (builder) => (builder
    .addCase(addLocalEdit, (state, action) => {
      const { id, patch, editId } = action.payload;
      return ({
        ...state,
        [id]: [...state[id] ?? [], { editId, patch }],
      });
    })

    .addCase(removeLocalEdit, (state, action) => {
      const { id, editId } = action.payload;
      const updatedEdits = (state[id] ?? []).filter((e) => e.editId !== editId);
      return {
        ...state,
        [id]: updatedEdits.length > 0 ? updatedEdits : undefined,
      };
    })
  ),
);
