import { HStack, Text } from '@chakra-ui/react';
import { getUnixTime } from 'date-fns';
import { areIntervalsOverlapping } from 'date-fns/esm';
import { groupBy, mapValues, sortBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import { GroupTypeBase } from 'react-select';
import SearchSelect, { SearchSelectProps } from '~/components/SearchSelect';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import { useResourcesQuery } from '~/queries/useResourcesQuery';
import { ScheduleEventActions } from '~/redux/schedule/actions';
import { selectEventsWithinRange } from '~/redux/schedule/selectors';
import { ResourceSummary } from '~/types/resource';

type SupportedSelectProps = Pick<SearchSelectProps<any, any>,
'icon' | 'variant' | 'hideSearchIcon' | 'placeholder' | 'autoFocus' | 'selectRef'
>;

interface StaffAndEquipmentSelectProps extends SupportedSelectProps {
  value?: string | ResourceSummary;
  limitTo?: 'staff' | 'admin' | 'equipment';
  hiddenResourceIds?: string[];
  onChange?: (option: ResourceSummary) => void;
  showAvailability?: boolean;
  availabilityStart?: Date;
  availabilityEnd?: Date;
  closeMenuOnSelect?: boolean;
}

const StaffAndEquipmentSelect = ({
  value = null,
  limitTo = undefined,
  onChange,
  hiddenResourceIds,
  placeholder = 'Add people or equipment',
  showAvailability = false,
  availabilityStart = null,
  availabilityEnd = null,
  ...selectProps
} : StaffAndEquipmentSelectProps) => {
  const { data, isLoading } = useResourcesQuery();
  const { staff, equipment } = useMemo(() => {
    const sorted = sortBy(
      (data ?? []).filter((r) => r.isAvailable
      && !r.isDeleted
      && !hiddenResourceIds?.includes(r.id)),
      (r) => r.displayName,
    );
    return {
      staff: sorted.filter((x) => x.type === 'staff'),
      equipment: sorted.filter((x) => x.type === 'equipment'),
      allResources: sorted,
    };
  }, [data, hiddenResourceIds]);
  const adminStaff = staff.filter((s) => s.isAdmin);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const availabilityEnabled = showAvailability
  && !!availabilityStart && !!availabilityEnd;

  const from = availabilityStart ? getUnixTime(availabilityStart) : null;
  const to = availabilityEnd ? getUnixTime(availabilityEnd) : null;

  // We need to be careful with this fetch, if its run at the wrong time
  // it can cause events on the calendar to jump around when they're being
  // resized, hence the menuIsOpen logic
  const {
    data: scheduleEvents, isLoading: isLoadingScheduleEvents,
  } = useTrackedFetch({
    trigger: () => ScheduleEventActions.fetchForRange({
      from,
      to,
    }),
    selector: (s) => (availabilityEnabled ? selectEventsWithinRange(
      s, availabilityStart, availabilityEnd,
    ) : []),
    key: `schedule-for-range-${from}-${to}`,
    enabled: availabilityEnabled && menuIsOpen,
  });

  const resourceClashes = useMemo(() => {
    if (!availabilityEnabled) {
      return {};
    }

    const grouped = groupBy(
      scheduleEvents.filter((e) => e.resourceId),
      (e) => e.resourceId,
    );

    const mapped = mapValues(grouped, (events) => {
      if (events.some((e) => e.type === 'staff-leave')) {
        return 'on leave';
      }
      if (events.some((e) => e.type === 'equipment-status' && !e.isAvailable)) {
        return 'equipment out';
      }

      const eventCount = events
        .filter((e) => e.type === 'job-resource' && areIntervalsOverlapping(e, { start: availabilityStart, end: availabilityEnd }))
        .length;

      if (eventCount > 0) {
        return `${eventCount} other job${eventCount > 1 ? 's' : ''}`;
      }

      return null;
    });
    return mapped;
  }, [scheduleEvents]);

  const selectedResourceId = typeof value === 'string' ? value : value?.id;
  const selectedResource = value === null ? null : data?.find((r) => r.id === selectedResourceId);

  let options: ResourceSummary[] | GroupTypeBase<ResourceSummary>[];
  if (limitTo === 'staff') {
    options = staff;
  } else if (limitTo === 'admin') {
    options = adminStaff;
  } else if (limitTo === 'equipment') {
    options = equipment;
  } else {
    options = [{
      label: 'Staff',
      options: staff,
    }, {
      label: 'Equipment',
      options: equipment,
    }];
  }

  return (
    <SearchSelect
      placeholder={placeholder}
      options={options}
      isLoading={isLoading}
      value={selectedResource}
      menuPlacement="auto"
      onMenuOpen={() => setMenuIsOpen(true)}
      onMenuClose={() => setMenuIsOpen(false)}
      getOptionLabel={(r: ResourceSummary) => r.displayName}
      getOptionValue={(r: ResourceSummary) => r.id}
      renderOption={({ option }) => {
        const clash = resourceClashes[option.id];
        return (
          <HStack justifyContent="space-between" align="stretch">
            <Text flex={1}>{option.name}</Text>
            {availabilityEnabled
            && (
            <Text
              flex={0.7}
              textAlign="right"
              color={clash ? 'magnetize.text-4' : 'magnetize.brand-4'}
            >
              {isLoadingScheduleEvents ? '' : `${clash ?? 'available'}`}
            </Text>
            )}
          </HStack>
        );
      }}
      onChange={(o) => onChange?.(o)}
      {...selectProps}
    />
  );
};

export default StaffAndEquipmentSelect;
