import { createAction } from '@reduxjs/toolkit';
import createTrackedAction from '~/redux/tracked/createTrackedAction';
import { Borehole, CPT } from '~/types/geotechnical';
import { ContactInfo } from '~/types/contact-info';
import { Customer } from '~/types/customer';
import { Job } from '~/types/job';
import { Tenant } from '~/types/tenant';
import { CustomerContact } from '~/types/customerContact';
import { DrillingTechnique } from '~/types/drillingTechnique';
import { SoilType } from '~/types/soilType';
import { ClearanceTechnique } from '~/types/clearanceTechnique';
import { CasingType } from '~/types/casingType';
import { RefusalReason } from '~/types/refusalReason';
import { BackfillType } from '~/types/backfillType';
import { SurfaceType } from '~/types/surfaceType';
import { PiezoType } from '~/types/piezoType';

export const LiveGeoActions = {
  fetchForJob: createAction<{ jobId: string }>('geotechnical/fetch/forJob'),
  updated: createAction<{ boreholes: Borehole[], cpts: CPT[] }>('geotechnical/updated'),
  updatedForJob: createAction<{
    jobId: string,
    boreholeIds: string[],
    cptIds: string[]
  }>('geotechnical/updated/forJob'),
  send: createTrackedAction<{
    jobId: string,
    recipients: ContactInfo[],
  }>('geotechnical/send'),
  downloadPDF: createTrackedAction<{
    timezone: string,
    tenant: Tenant,
    customer: Customer,
    contact: CustomerContact
    job: Partial<Job>,
    equipment: {
      id: string,
      name: string,
      nickname?: string
    }[],
    boreholes: Borehole[],
    cpts: CPT[],
    coordinateSystem: string,
    drillingTechniques: DrillingTechnique[],
    soilTypes: SoilType[],
    casingTypes: CasingType[],
    clearanceTechniques: ClearanceTechnique[],
    refusalReasons: RefusalReason[],
    backfillTypes: BackfillType[],
    surfaceTypes: SurfaceType[],
    piezoTypes: PiezoType[],
  }>('geotechnical/download'),
};
