import {
  Flex, HStack, SkeletonText, Spacer, Text, VStack,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import StaffActions from '~/redux/staff/actions';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import { Staff } from '~/types/staff';
import { selectStaff } from '~/redux/staff/selectors';
import StaffList from './StaffList';
import FusedSearch from '~/components/FusedSearch';

const fuseSettings = {
  includeScore: true,
  ignoreLocation: true,
  threshold: 0.3,
  keys: [
    { name: 'name', weight: 1 },
    { name: 'jobTitle', weight: 1 },
    { name: 'phone', weight: 1 },
    { name: 'email', weight: 1 },
  ],
};

interface StaffTabProps {
  setSelected: (staff: Staff) => void;
}

const StaffTab = ({ setSelected } : StaffTabProps) => {
  const { data: staff, isLoading } = useTrackedFetch({
    key: 'staff',
    selector: (state) => selectStaff(state),
    trigger: () => StaffActions.fetch(),
  });
  const [filteredStaff, setFilteredStaff] = useState(staff);

  const isLoaded = !!staff && !isLoading;

  return (
    <VStack align="stretch" spacing={8}>
      <HStack align="stretch">
        <Flex flex={1}>
          <FusedSearch
            items={staff}
            onChange={(s) => setFilteredStaff(s)}
            fuseSettings={fuseSettings}
          />
        </Flex>
        <Spacer flex={1} />
      </HStack>

      <SkeletonText isLoaded={isLoaded} noOfLines={3}>
        {isLoaded && (
        <StaffList staff={filteredStaff} setSelected={setSelected} />
        )}
        {isLoaded && filteredStaff?.length === 0 && (
        <Text mt={4}>
          There are no staff to display.
        </Text>
        )}
      </SkeletonText>
    </VStack>
  );
};

export default StaffTab;
