import { Grid } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import CappedWidthLayout from '~/layouts/CappedWidthLayout';

const GRID_AREAS = `
  "header secondary-header"
  "primary secondary"
  "footer footer"
`;

const PrimarySecondaryColumns = (
  { children, maxWidth }:
  { children: ReactNode, maxWidth?: string },
) => (
  <CappedWidthLayout maxWidth={maxWidth}>
    <Grid gap={6} templateAreas={GRID_AREAS} templateColumns="minmax(500px, 5fr) minmax(250px, 2fr)">
      {children}
    </Grid>
  </CappedWidthLayout>
);

export default PrimarySecondaryColumns;
