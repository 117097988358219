import React, { useCallback } from 'react';
import {
  Box,
  Button, Icon, Modal, ModalBody, ModalCloseButton,
  ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text,
} from '@chakra-ui/react';
import { AiFillDollarCircle } from 'react-icons/ai';
import { closeJob } from '~/redux/jobs/actions';
import QuotedVsInvoiced from '~/pages/Job/components/invoices/QuotedVsInvoiced';
import { useAppDispatch } from '~/redux/store';
import CurrencyText from '~/components/CurrencyText';
import { useQuoted } from '~/hooks/useQuoted';
import { useInvoiced } from '~/hooks/useInvoiced';

const CloseJobModal = ({ jobId, onClose } : { jobId : string
  onClose: () => void; }) => {
  const dispatch = useAppDispatch();
  const { quoted } = useQuoted({ jobId });
  const { invoiced } = useInvoiced({ jobId });
  const handleCloseJob = useCallback(() => {
    dispatch(closeJob({ jobId }));
    onClose();
  }, [dispatch, jobId, onClose]);
  const underBudget = invoiced < quoted;

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen
      onClose={onClose}
      size="3xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Confirm close job
          <Text fontSize={14} fontWeight="normal" mt={3} fontFamily="body">
            Do you want to close this job in Magnetize?
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <QuotedVsInvoiced jobId={jobId} quotedLabel="Total quoted: " quotedIcon={underBudget ? <Icon as={AiFillDollarCircle} mr={1} mb="2.5px" fontSize="1.5em" /> : undefined} />
        </ModalBody>
        <ModalFooter>
          {underBudget && (
          <Text fontWeight="bold" color="magnetize.support-warning">
            {'Budget remaining: '}
            <CurrencyText value={quoted - invoiced} />
          </Text>
          )}
          <Box flex={1} />
          <Button size="lg" colorScheme="brandDark" onClick={handleCloseJob}>
            Close job
          </Button>
          <Button variant="ghost" onClick={onClose}>cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CloseJobModal;
