import {
  Box, Flex, Image, Text, useDisclosure,
} from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { groupBy } from 'lodash';
import SearchSelect from '~/components/SearchSelect';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import SubscriptionActions from '~/redux/subscription/actions';
import formatCurrency from '~/utils/formatCurrency';
import { PowerUpBrowseModal } from '~/pages/TenantSettings/PowerUpsTab/PowerUpsSearch/PowerUpBrowseModal';
import LinkButton from '~/components/LinkButton';
import { PowerUpId } from '~/types/subscription';

export const PowerUpsSearch = (
  { onSelected }: { onSelected: (powerUpId: PowerUpId) => void; },
) => {
  const { data: prices, isLoading } = useTrackedFetch({
    key: 'subscriptionPrices',
    trigger: () => SubscriptionActions.fetchPrices(),
    selector: (state) => state.subscription.prices,
  });
  const powerUpBrowseModalState = useDisclosure();

  const powerUpOptions = useMemo(() => Object.entries(
    groupBy((prices?.powerUps || []).filter((x) => !x.isActive),
      (x) => x.category || ''),
  ).map(([label, options]) => ({
    label,
    options: options.map((x) => ({
      ...x,
      label: x.name,
      value: x.powerUpId,
    })),
  })), [prices]);

  return (
    <Box flex="1" position="relative">
      <LinkButton
        zIndex="1"
        right="10px"
        top="9.5px"
        textDecoration="none"
        isGreen
        position="absolute"
        onClick={() => {
          powerUpBrowseModalState.onOpen();
        }}
      >
        Browse all
      </LinkButton>
      <SearchSelect
        placeholder="Search for apps, tasks"
        options={powerUpOptions}
        isLoading={isLoading}
        value={null}
        noOptionsMessage={() => "You got 'em all. No other power ups to display"}
        renderOption={({ option }) => (
          <Flex justifyContent="space-between" position="relative" alignItems="center">
            {option.image && (<Image src={option.image} h="33px" w="33px" position="absolute" top={0} bottom={0} margin="auto" left="18px" />)}
            <Box flex="1" ml="70px">
              <Text size="lg" fontWeight="bold">
                {option.name}
              </Text>
              <Text>
                {option.description}
              </Text>
            </Box>
            <Flex height="100%">
              <Text>
                {option.priceCents && option.priceCents > 0 ? `${formatCurrency(option.priceCents)} / month` : 'free'}
              </Text>
            </Flex>
          </Flex>
        )}
        onChange={(option) => {
          onSelected(option.powerUpId);
        }}
      />
      {powerUpBrowseModalState.isOpen && (
      <PowerUpBrowseModal
        onClose={powerUpBrowseModalState.onClose}
        onSelected={(powerUpId) => {
          onSelected(powerUpId);
          powerUpBrowseModalState.onClose();
        }}
      />
      )}
    </Box>
  );
};
