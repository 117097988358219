import {
  Circle,
  FormControl, FormLabel, Grid, HStack, Input,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Textarea,
} from '@chakra-ui/react';
import { sample, without } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { CirclePicker } from 'react-color';
import { useAppSelector } from '~/redux/store';
import { selectTagsByType } from '~/redux/tags/selectors';
import { JobTag } from '~/types/tag';

const colors = [
  '#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5',
  '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50',
  '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800',
  '#ff5722', '#795548', '#607d8b'];

const JobTagForm = (
  {
    value = {},
    onChange = () => {},
  }
  : {
    value?: Partial<JobTag>;
    onChange?: (price: Partial<JobTag>) => void
  },
) => {
  const existingTags = useAppSelector((state) => selectTagsByType(state, 'job') as JobTag[]);
  const [name, setName] = useState(value.name ?? '');
  const [description, setDescription] = useState(value.description ?? '');
  const [color, setColor] = useState(
    value.color ?? (sample(without(colors, ...existingTags.map((t) => t.color))) || sample(colors)),
  );
  const nameRef = useRef(null);

  useEffect(() => {
    onChange({
      ...(value || {}),
      name,
      description,
      color,
    });
  }, [
    name,
    description,
    color,
  ]);

  return (
    <Grid gap={6}>
      <HStack gridGap={6}>
        <FormControl flex="2" isRequired>
          <FormLabel>Name</FormLabel>
          <Input
            autoFocus
            size="lg"
            ref={nameRef}
            placeholder="Enter the name for the job tag"
            value={name}
            onChange={(e) => { setName(e.target.value); }}
          />
        </FormControl>
        <FormControl w="50px">
          <FormLabel textAlign="center" marginRight="0px">COLOR</FormLabel>
          <Popover>
            {({ onClose }) => (
              <>
                <PopoverTrigger>
                  <Circle size="36px" bg={color} margin="auto" />
                </PopoverTrigger>
                <PopoverContent padding={3} w="216px">
                  <PopoverArrow />
                  <CirclePicker
                    width="210px"
                    color={color}
                    colors={colors}
                    onChange={({ hex }) => {
                      setColor(hex);
                      onClose();
                    }}
                  />
                </PopoverContent>
              </>
            )}
          </Popover>
        </FormControl>
      </HStack>
      <FormControl>
        <FormLabel>Description</FormLabel>
        <Textarea
          size="lg"
          resize="none"
          placeholder="Enter a description"
          value={description}
          onChange={(e) => { setDescription(e.target.value); }}
        />
      </FormControl>
    </Grid>
  );
};

export default JobTagForm;
