import { Box } from '@chakra-ui/react';
import React from 'react';
import Card from '~/components/Card';

const PagePrintPreview = ({
  children, fixedSize = false,
}: { fixedSize?: boolean, children: React.ReactNode }) => (
  <Card
    padding={12}
    alignItems="start"
    width={fixedSize ? '210mm' : undefined}
    minHeight={fixedSize ? '297mm' : '80vh'}
  >
    <Box overflow="hidden">
      {children}
    </Box>
  </Card>
);

export default PagePrintPreview;
