import React from 'react';
import { Button } from '@chakra-ui/react';

const OkButton = ({
  onClick,
}: { onClick: () => void }) => (
  <Button
    width="40px"
    height={5}
    onClick={onClick}
  >
    Ok
  </Button>
);

export default OkButton;
