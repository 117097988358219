export const AccordionStyles = {
  variants: {
    magnetize: {
      button: {
        bg: 'gray.50',
        border: 'none',
        borderRadius: 'sm',
        py: '6',
        fontWeight: 'semibold',
        mb: '1',
        _hover: {
          bg: 'gray.100',
        },
      },

      container: {
        borderTopWidth: 0,
        _last: {
          borderBottomWidth: 0,
        },
      },

      panel: {
        py: '4',
        px: '0',
      },
    },
  },

  defaultProps: {
    variant: 'magnetize',
  },
};
