import {
  call, put, takeEvery,
} from 'redux-saga/effects';
import {
  completeJob as postCompleteJob,
  reopenJob as postReopenJob,
  closeJob as postCloseJob,
  holdJob as postHoldJob,
  startJob as postStartJob,
  archiveJob as postArchiveJob,
} from '~/api/jobApi';
import { jobTimelineQuery } from '~/queries/jobTimeline';
import queryClient from '~/queryClient';
import {
  completeJob, closeJob, reopenJob, jobFetchSuccess, holdJob, startJob, archiveJob,
} from '~/redux/jobs/actions';
import { showToast, showSuccessToast } from '~/toast';
import { performTrackedSaga } from '~/utils/performTrackedSaga';
import queueWithLocalEdit from '~/utils/queueWorkWithLocalEdit';

export const watchCompleteJob = takeEvery(
  completeJob,
  function* handle({ payload: { data: { jobId }, id } }) {
    yield queueWithLocalEdit(jobId, { status: 'complete' },
      function* worker() {
        yield performTrackedSaga({
          key: id,
          * saga() {
            try {
              const nextJob = yield postCompleteJob(jobId);
              if (nextJob) {
                yield put(jobFetchSuccess({ jobId, data: nextJob }));
                yield call(() => queryClient.invalidateQueries(jobTimelineQuery(jobId)));
              }
            } catch (error) {
              showToast({
                id: jobId,
                title: 'Job Status Error',
                description: error instanceof Error ? error.message : String(error),
                status: 'error',
                duration: 10000,
                isClosable: true,
              });
            }
          },
        });
      });
  },
);
export const watchReopenJob = takeEvery(
  reopenJob,
  function* handle({ payload: { data: { jobId }, id } }) {
    yield queueWithLocalEdit(jobId, { status: 'schedule' },
      function* worker() {
        yield performTrackedSaga({
          key: id,
          * saga() {
            try {
              const nextJob = yield postReopenJob(jobId);
              if (nextJob) {
                yield put(jobFetchSuccess({ jobId, data: nextJob }));
                yield call(() => queryClient.invalidateQueries(jobTimelineQuery(jobId)));
              }
            } catch (error) {
              showToast({
                id: jobId,
                title: 'Job Status Error',
                description: error instanceof Error ? error.message : String(error),
                status: 'error',
                duration: 10000,
                isClosable: true,
              });
            }
          },
        });
      });
  },
);

export const watchCloseJob = takeEvery(
  closeJob,
  function* handle({ payload: { data: { jobId }, id } }) {
    yield queueWithLocalEdit(jobId, { status: 'closed' },
      function* worker() {
        yield performTrackedSaga({
          key: id,
          * saga() {
            try {
              const nextJob = yield postCloseJob(jobId);
              if (nextJob) {
                yield put(jobFetchSuccess({ jobId, data: nextJob }));
                yield call(() => queryClient.invalidateQueries(jobTimelineQuery(jobId)));
              }
            } catch (error) {
              showToast({
                id: jobId,
                title: 'Job Status Error',
                description: error instanceof Error ? error.message : String(error),
                status: 'error',
                duration: 10000,
                isClosable: true,
              });
            }
          },
        });
      });
  },
);

export const watchHoldJob = takeEvery(
  holdJob,
  function* handle({ payload: { data: { jobId }, id } }) {
    yield queueWithLocalEdit(jobId, { status: 'onHold' },
      function* worker() {
        yield performTrackedSaga({
          key: id,
          * saga() {
            try {
              const nextJob = yield postHoldJob(jobId);
              if (nextJob) {
                yield put(jobFetchSuccess({ jobId, data: nextJob }));
                yield call(() => queryClient.invalidateQueries(jobTimelineQuery(jobId)));
              }
            } catch (error) {
              showToast({
                id: jobId,
                title: 'Job Status Error',
                description: error instanceof Error ? error.message : String(error),
                status: 'error',
                duration: 10000,
                isClosable: true,
              });
            }
          },
        });
      });
  },
);

export const watchStartJob = takeEvery(
  startJob,
  function* handle({ payload: { data: { jobId }, id } }) {
    yield queueWithLocalEdit(jobId, { status: 'inProgress' },
      function* worker() {
        yield performTrackedSaga({
          key: id,
          * saga() {
            try {
              const nextJob = yield postStartJob(jobId);
              if (nextJob) {
                yield put(jobFetchSuccess({ jobId, data: nextJob }));
                yield call(() => queryClient.invalidateQueries(jobTimelineQuery(jobId)));
              }
            } catch (error) {
              showToast({
                id: jobId,
                title: 'Start job error',
                description: error instanceof Error ? error.message : String(error),
                status: 'error',
                duration: 10000,
                isClosable: true,
              });
            }
          },
        });
      });
  },
);

export const watchArchiveJob = takeEvery(
  archiveJob,
  function* handle({ payload: { data: { jobId }, id } }) {
    yield queueWithLocalEdit(jobId, {},
      function* worker() {
        yield performTrackedSaga({
          key: id,
          * saga() {
            try {
              const nextJob = yield postArchiveJob(jobId);
              if (nextJob) {
                yield put(jobFetchSuccess({ jobId, data: nextJob }));
                yield call(() => queryClient.invalidateQueries(jobTimelineQuery(jobId)));
                // @ts-ignore
                if (nextJob.status === 'archived') {
                  showSuccessToast('Job archived.');
                } else {
                  showSuccessToast('Job unarchived.');
                }
              }
            } catch (error) {
              showToast({
                id: jobId,
                title: 'Archive job error',
                description: error instanceof Error ? error.message : String(error),
                status: 'error',
                duration: 10000,
                isClosable: true,
              });
            }
          },
        });
      });
  },
);
