import { createSelector } from '@reduxjs/toolkit';
import selectLocalEdits from '~/redux/localEdits/selectors';
import { RootState } from '~/redux/store';
import { Invoice } from '~/types/invoice';
import applyLocalEdits from '~/utils/applyLocalEdits';

export const selectInvoiceById = (
  state: RootState, invoiceId: string,
) : Invoice => state.invoices.invoicesById[invoiceId];

export const selectInvoicesByJobId = (state: RootState, jobId: string) => (
  (state.invoices.invoicesByJobId[jobId] ?? [])
    .map((invoiceId) => state.invoices.invoicesById[invoiceId])
    .filter((q) => q)
);

export const selectInvoiceWithEdits = createSelector(
  selectInvoiceById,
  selectLocalEdits,
  (invoice, edits) => applyLocalEdits(invoice, edits),
);

export const selectDraftInvoiceWithEdits = (state: RootState, jobId: string) => {
  const invoices = selectInvoicesByJobId(state, jobId);
  const draftInvoiceId = invoices.find((i) => i.status === 'draft')?.id;
  if (draftInvoiceId) {
    return selectInvoiceWithEdits(state, draftInvoiceId);
  }
  return null;
};
