import {
  Button, Flex, GridItem, Heading,
} from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import React, { useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import Card from '~/components/Card';
import PageTabs from '~/components/PageTabs';
import PageHeader from '~/layouts/PageHeader';
import PrimarySecondaryColumns from '~/layouts/PrimarySecondaryColumns';
import StaffTab from '~/pages/Staff/StaffTab';
import TimeRecordsTab from '~/pages/Staff/TimeRecordsTab';
import { Staff } from '~/types/staff';
import StaffEditModal from './StaffEditModal';

const StaffPage = () => {
  const [selected, setSelected] = useState<Staff>(null);
  const addStaff = () => {
    setSelected({
      id: nanoid(),
      name: '',
      isAvailable: true,
      tags: [],
    });
  };

  return (
    <>
      <PrimarySecondaryColumns>
        <PageHeader>
          <Flex justifyContent="space-between">
            <Heading size="lg">
              Team
            </Heading>
          </Flex>
        </PageHeader>
        <GridItem gridArea="primary-start / primary-start / secondary-end / secondary-end">
          <Flex direction="column">
            <PageTabs
              tabs={[
                { title: 'View staff', path: '/staff/view' },
                { title: 'Time logs', path: '/staff/time' },
              ]}
            >
              <Button mb="1" onClick={addStaff}>
                Add Staff
              </Button>
            </PageTabs>
            <Card>
              <Switch>
                <Route path="/staff/view">
                  <StaffTab setSelected={setSelected} />
                </Route>
                <Route path="/staff/time">
                  <TimeRecordsTab />
                </Route>
                <Route path="*" render={() => <Redirect to="/staff/view" />} />
              </Switch>
            </Card>
          </Flex>
        </GridItem>
      </PrimarySecondaryColumns>
      {selected !== null
      && (
      <StaffEditModal
        staff={selected}
        isNewStaff={!selected.name}
        onClose={() => {
          setSelected(null);
        }}
      />
      )}
    </>
  );
};

export default StaffPage;
