import { Grid, useCallbackRef } from '@chakra-ui/react';
import {
  closestCenter, DndContext, PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';
import {
  SortableContext, verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { nanoid } from '@reduxjs/toolkit';
import { omitBy } from 'lodash';
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import BundleLineItemEditRow from '~/components/LineItemsEditor/components/BundleLineItemEditRow';
import LineItemEditRow from '~/components/LineItemsEditor/components/LineItemEditRow';
import { Dictionary } from '~/types/helpers';
import { CustomLineItem, LineItem } from '~/types/lineItem';
import { addLineItems, reorderLineItems, sortLineItems } from '~/utils/lineItemHelpers';

type LineItemDictionary = Dictionary<LineItem>;

interface LineItemsEditorProps {
  lineItems: LineItemDictionary;
  onChange: (lineItems: LineItemDictionary) => void;
  disableAddNew?: boolean;
  disableModifyLineItem?: boolean;
  hideBottomBorder?: boolean;
}

const LineItemsEditor = ({
  lineItems,
  onChange,
  disableAddNew = false,
  disableModifyLineItem = false,
  hideBottomBorder = false,
}: LineItemsEditorProps) => {
  const [newLineItemId, setNewLineItemId] = useState(nanoid());
  const sortedLineItems = useMemo(
    () => sortLineItems(lineItems),
    [lineItems],
  );

  const focusRef = useRef<HTMLInputElement>();
  const [previousId, setPreviousId] = useState('');
  const [previousLength, setPreviousLength] = useState(sortedLineItems.length);

  useEffect(() => {
    const id = sortedLineItems[0]?.id;
    const { length } = sortedLineItems;
    // A item has been added to the top of the list - focus it
    if (previousId && id !== previousId && length > previousLength) {
      focusRef?.current?.select();
    }
    setPreviousId(id);
    setPreviousLength(length);
  }, [sortedLineItems[0]?.id]);

  const sensors = useSensors(
    useSensor(PointerSensor),
  );

  const onLineItemChanged = useCallbackRef((li: LineItem) => {
    if (li.id === newLineItemId) {
      onChange({
        ...lineItems,
        ...addLineItems('end', sortedLineItems, [li]),
      });
      setNewLineItemId(nanoid());
    } else {
      onChange({
        ...lineItems,
        [li.id]: li,
      });
    }
  });

  const onLineItemRemoved = useCallbackRef((itemId: string) => {
    onChange(omitBy(lineItems, (li, key) => key === itemId || li.id === itemId));
  });

  const handleDragEnd = useCallbackRef((event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = sortedLineItems.findIndex((i) => i.id === active.id);
      const newIndex = sortedLineItems.findIndex((i) => i.id === over.id);

      if (oldIndex !== newIndex) {
        onChange({
          ...lineItems,
          ...reorderLineItems(sortedLineItems, oldIndex, newIndex),
        });
      }
    }
  });

  const newItem : CustomLineItem = {
    id: newLineItemId,
    name: '',
    type: 'custom',
    priceId: undefined,
    unitPriceCents: 0,
    quantity: 1,
    order: 0,
  };

  const items = useMemo(() => {
    if (disableAddNew) {
      return sortedLineItems;
    }
    return [...sortedLineItems, newItem];
  }, [newLineItemId, sortedLineItems]);

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis]}
      >
        <SortableContext
          items={items}
          strategy={verticalListSortingStrategy}
        >
          <Grid
            borderColor="gray.100"
            borderBottomWidth={hideBottomBorder ? 0 : 1}
          >
            {items.map((lineItem, index) => (
              lineItem.type === 'bundle' ? (
                <BundleLineItemEditRow
                  key={lineItem.id}
                  lineItem={lineItem}
                  onLineItemChanged={onLineItemChanged}
                  onLineItemRemoved={onLineItemRemoved}
                />
              )
                : (
                  <LineItemEditRow
                    key={lineItem.id}
                    focusRef={index === 0 ? focusRef : null}
                    isNew={lineItem.id === newLineItemId}
                    disableModifyLineItem={disableModifyLineItem}
                    lineItem={lineItem}
                    onLineItemChanged={onLineItemChanged}
                    onLineItemRemoved={onLineItemRemoved}
                  />
                )
            ))}
          </Grid>
        </SortableContext>
      </DndContext>
    </>
  );
};
export default LineItemsEditor;
