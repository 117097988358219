import { isWithinInterval } from 'date-fns';
import { RootState } from '~/redux/store';

export const selectTimeRecordsForJob = (state: RootState, jobId: string) => {
  const indexes = state.time.byJob[jobId] ?? [];
  return indexes.map((x) => state.time.entities[x]);
};
export const selectTimeRecordsForJobInDateRange = (
  state: RootState,
  jobId: string,
  interval: Interval,
) => {
  const timeRecords = selectTimeRecordsForJob(state, jobId);
  return timeRecords.filter((l) => isWithinInterval(l.start, interval));
};

export const selectTimeRecordsForRange = (state: RootState, from: string, to: string) => {
  const indexes = state.time.byRange[`${from}_${to}`] ?? [];
  return indexes.map((x) => state.time.entities[x]);
};
