import {
  Icon, Table, Text, Tbody, Tr, VStack, Td, Skeleton, MenuItem,
} from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
import React, { useState } from 'react';
import { ReactComponent as DrillIcon } from '~/assets/live_geo_drill_icon.svg';
import ConfirmationDialog from '~/components/ConfirmationDialog';
import { ExpandableCountSection } from '~/components/ExpandableCountSection';
import { KebabOptionsMenu } from '~/components/KebabOptionsMenu';
import LinkButton from '~/components/LinkButton';
import { useModalManager } from '~/components/ModalManager';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import { DrillingTechniqueEditModal } from './DrillingTechniqueEditModal';
import { triggerDrillingTechniquesFetch, deleteDrillingTechnique } from '~/redux/drillingTechniques/actions';
import { getActiveDrillingTechniques } from '~/redux/drillingTechniques/selectors';
import { useAppDispatch } from '~/redux/store';
import { DrillingTechnique } from '~/types/drillingTechnique';
import eventStopper from '~/utils/eventStopper';

export const DrillingTechniquesList = () => {
  const dispatch = useAppDispatch();
  const modalManager = useModalManager();
  const { data: drillingTechniques, isLoading } = useTrackedFetch({
    key: 'drilling-techniques',
    trigger: () => triggerDrillingTechniquesFetch({ force: false }),
    selector: getActiveDrillingTechniques,
  });

  const [
    selectedDrillingTechnique,
    setSelectedDrillingTechnique,
  ] = useState<Partial<DrillingTechnique>>(null);
  const [drillingTechniqueModalTitle, setDrillingTechniqueModalTitle] = useState<string>();

  const addTechnique = () => {
    setSelectedDrillingTechnique({
      id: nanoid(),
    });
    setDrillingTechniqueModalTitle('Add drilling technique');
  };

  const editTechnique = (drillingTechnique: DrillingTechnique) => {
    setSelectedDrillingTechnique(drillingTechnique);
    setDrillingTechniqueModalTitle('Edit drilling technique');
  };

  const deleteTechnique = (drillingTechniqueId) => {
    dispatch(deleteDrillingTechnique({
      id: drillingTechniqueId,
    }));
  };

  return (
    <VStack
      align="stretch"
    >
      <ExpandableCountSection
        title="Drilling techniques"
        icon={<Icon boxSize={8} as={DrillIcon} />}
        count={drillingTechniques?.length || 0}
      >
        <Table size="md">
          <Tbody>
            {isLoading ? (
              <Tr>
                <Td><Skeleton height={8} /></Td>
                <Td><Skeleton height={8} /></Td>
                <Td />
              </Tr>
            )
              : (orderBy(drillingTechniques, (t) => t.name).map((tr) => (
                <Tr
                  key={tr.id}
                  _hover={{ bg: '#fafafa', cursor: 'pointer' }}
                  transition="color 0.1s ease-out"
                  role="group"
                  onClick={() => editTechnique(tr)}
                >
                  <Td>
                    <Text pl={24}>
                      {tr.name}
                    </Text>
                  </Td>

                  <Td textAlign="end" {...eventStopper()}>
                    <KebabOptionsMenu>
                      <MenuItem onClick={() => editTechnique(tr)}>Edit</MenuItem>
                      <MenuItem
                        onClick={() => {
                          modalManager.open(ConfirmationDialog, {
                            title: 'Delete drilling technique',
                            children: 'This will remove the drilling technique from Magnetize, but will not remove it from any existing logs.',
                            onConfirm: () => deleteTechnique(tr.id),
                            confirmButtonLabel: 'Delete',
                          });
                        }}
                      >
                        Delete
                      </MenuItem>
                    </KebabOptionsMenu>
                  </Td>
                </Tr>
              )))}
          </Tbody>
        </Table>
        <LinkButton ml={24} mt={6} isGreen noUnderline onClick={addTechnique}>
          + add drilling technique
        </LinkButton>
        {!!selectedDrillingTechnique
      && (
      <DrillingTechniqueEditModal
        title={drillingTechniqueModalTitle}
        onClose={() => {
          setSelectedDrillingTechnique(null);
        }}
        drillingTechnique={selectedDrillingTechnique}
      />
      )}
      </ExpandableCountSection>
    </VStack>
  );
};
