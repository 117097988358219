import {
  Flex, Grid, MenuDivider, MenuItem, Td, Text, Tr,
} from '@chakra-ui/react';
import React from 'react';
import ConfirmationDialog from '~/components/ConfirmationDialog';
import CurrencyText from '~/components/CurrencyText';
import { KebabOptionsMenu } from '~/components/KebabOptionsMenu';
import { useModalManager } from '~/components/ModalManager';
import { Price } from '~/types/price';
import eventStopper from '~/utils/eventStopper';

interface PriceRowProps {
  price: Price;
  onEdit: (p: Price) => void;
  onDuplicate: (p: Price) => void;
  onDelete: (p: Price) => void;
}

const PriceRow = ({
  price, onEdit, onDuplicate, onDelete,
}: PriceRowProps) => {
  const modalManager = useModalManager();

  return (
    <Tr
      _hover={{ bg: '#fafafa', cursor: 'pointer' }}
      transition="color 0.1s ease-out"
      role="group"
      onClick={() => {
        onEdit(price);
      }}
    >
      <Td>
        <Text>
          {price.code}
        </Text>
      </Td>
      <Td px={0}>
        <Text>
          {price.name}
        </Text>
      </Td>
      <Td>
        <Text color="magnetize.text-4" noOfLines={2}>
          {price.description}
        </Text>
      </Td>
      <Td>
        <Grid templateColumns="repeat(3, 1fr)" gap={1}>
          {price.category}
        </Grid>
      </Td>
      <Td>
        <Flex>
          <CurrencyText value={price.unitPriceCents} />
          {price.unit && (
          <Text color="magnetize.text-4" pl={1}>
            &#47;
            {' '}
            {price.unit}
          </Text>
          )}
        </Flex>
      </Td>
      <Td textAlign="end" {...eventStopper()}>
        <KebabOptionsMenu>
          <MenuItem onClick={() => onEdit(price)}>Edit</MenuItem>
          <MenuItem onClick={() => onDuplicate(price)}>Duplicate</MenuItem>
          <MenuDivider my={0} />
          <MenuItem onClick={() => {
            modalManager.open(ConfirmationDialog, {
              title: 'Delete rate',
              children: 'This will remove the rate from Magnetize, but existing quotes and invoices will be unaffected',
              onConfirm: () => onDelete(price),
              confirmButtonLabel: 'Delete',
            });
          }}
          >
            Delete
          </MenuItem>
        </KebabOptionsMenu>
      </Td>
    </Tr>
  );
};

export default PriceRow;
