import { nanoid } from '@reduxjs/toolkit';
import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { Dictionary } from '~/types/helpers';

// This is a pretty straightforward modal manager. It lets you imperatively open
// modal dialogs which are mounted outside of your component.
//
// This can be an effective pattern for confirmations and other out-of-band
// operations, and it means much less boilerplate in the component triggering
// the action.
//
// This is intended to be used with the Chakra <Modal> component. It will
// control the isOpen and onClose props (so you should pass those through!) so
// that the modal animates in and out, even if the modal causes an action that
// would immediately unmount the parent (e.g. navigation, or deleting a row in a
// table).
//
// It has some drawbacks though: once you open the modal the props are fixed --
// it's an imperative call, and doesn't get re-rendered along with your
// component. You'll need to take care with any callbacks passed in the modal
// props as they will not be able to access the latest component state without
// trickery like refs.
//
// I think this could be improved further so that the modal manager actually
// handles the <Modal>, <ModalOverlay>, <ModalContent> and you just provide the
// filling. Putting the content into a separate component is a more effective
// pattern with Chakra modals, as the content will only be rendered when the
// modal is open.

interface ModalManagerApi {
  open<TProps extends {}>(
    component: React.FC<TProps & ModalDisclosureProps>,
    props: Omit<TProps, keyof ModalDisclosureProps>,
  ): string;
  close(id: string);
}

const ModalManagerContext = React.createContext<ModalManagerApi>(undefined);

interface ModalManagerProps {
  children: React.ReactNode;
}

interface ModalDisclosureProps {
  isOpen: boolean;
  onClose: () => void;
}

type ModalPropsAndMore = Dictionary<any> & ModalDisclosureProps;
interface ModalState {
  id: string;
  component: React.FC<ModalPropsAndMore>;
  props: ModalPropsAndMore;
  isOpen: boolean;
  closedAt?: number;
  close: () => void;
}

export const ModalManager = ({ children }: ModalManagerProps) => {
  const [modals, setModals] = useState<ModalState[]>([]);

  const close = useCallback((id) => {
    setModals((existing) => existing.map((m) => (
      m.id === id ? { ...m, isOpen: false, closedAt: Date.now() } : m
    )));
  }, []);

  const open = useCallback((component, props) => {
    const id = nanoid();
    setModals((existing) => [
      ...existing,
      {
        id,
        component,
        props,
        isOpen: true,
        close: () => close(id),
      },
    ]);
    return id;
  }, [close]);

  // Once a modal is closed, we keep it around in the component tree long
  // enough for its closing animation to finish.
  const cleanupClosedModals = useCallback(() => {
    setModals((existingModals) => (
      existingModals.filter((m) => m.isOpen || (Date.now() - m.closedAt) <= 500)));
  }, []);

  // Provided there are some closed modals, we'll run an interval timer to
  // clean them up. Using an interval here avoids edge cases that could happen
  // if modals were being opened and closed frequenly -- once all the modals have
  // aged past ~500ms they get removed and the timer gets cleared.
  const hasClosedModals = modals.some((m) => !m.isOpen);
  useEffect(() => {
    const timer = setInterval(cleanupClosedModals, 250);
    return () => {
      clearInterval(timer);
    };
  }, [cleanupClosedModals, hasClosedModals]);

  const modalManagerApi = useMemo(() => ({
    open,
    close,
  }), [open, close]);

  return (
    <ModalManagerContext.Provider value={modalManagerApi}>
      {children}
      <>
        {modals.map((modal) => React.createElement(
          modal.component,
          {
            ...modal.props,
            isOpen: modal.isOpen,
            onClose: modal.close,
            key: modal.id,
          },
        ))}
      </>
    </ModalManagerContext.Provider>
  );
};

export const useModalManager = () => {
  const value = useContext(ModalManagerContext);
  if (!value) {
    throw Error('useModalManager must be used within a context. Maybe you forgot to provid it?');
  }
  return value;
};
