import {
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import React from 'react';
import PasswordInput from '~/components/PasswordInput';
import PasswordStrengthBar from '~/pages/Authentication/components/PasswordStrengthBar';

const isValid = (password: string) => /\d/.test(password) && password.length >= 6;

interface PasswordFormControlProps {
  isDisabled?: boolean;
  password: string;
  onChange: (password: string, isValid: boolean) => void;
}

const PasswordFormControl = ({ isDisabled, password, onChange } : PasswordFormControlProps) => (
  <FormControl isInvalid={password.length > 0 && !isValid(password)}>
    <FormLabel>Password</FormLabel>
    <PasswordInput
      isDisabled={isDisabled}
      value={password}
      onChange={(e) => {
        const newPassword = e.target.value;
        onChange(newPassword, isValid(newPassword));
      }}
    />
    <PasswordStrengthBar
      isInvalid={!isValid(password)}
      password={password}
    />
  </FormControl>
);

export default PasswordFormControl;
