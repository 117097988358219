import {
  Box, Button, Flex, GridItem,
  Heading, Icon, Menu,
  MenuButton, MenuDivider, MenuItem,
  SkeletonText, MenuList,
  Spacer, Stack, Text, useMediaQuery,
} from '@chakra-ui/react';
import { KeyboardArrowDownOutlined, LinkOutlined, Print } from '@material-ui/icons';
import { last, orderBy, values } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Card from '~/components/Card';
import CustomerRepDetails from '~/components/CustomerRepDetails';
import DismissibleStatusAlert from '~/components/DismissibleStatusAlert';
import QuoteFileUpload from '~/components/FileUpload/QuoteFileUpload';
import LinkButton from '~/components/LinkButton';
import NotFound from '~/components/NotFound';
import CappedWidthLayout from '~/layouts/CappedWidthLayout';
import PagePrintPreview from '~/components/PagePrintPreview';
import QuoteView from '~/components/QuoteView';
import QuoteViewTermsAndConditions from '~/components/QuoteView/QuoteViewTermsAndConditions';
import SendConfirmationModal from '~/components/SendConfirmationModal';
import SkeletonQuote from '~/components/SkeletonQuote';
import VisibleOnlyIn from '~/components/VisibleIn';
import { formatJobName } from '~/helpers/job';
import { useCheckRole } from '~/hooks/useCheckRole';
import { usePrintHelper } from '~/hooks/usePrintHelper';
import useTrackedAction from '~/hooks/useTrackedAction';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import PageHeader from '~/layouts/PageHeader';
import PrimarySecondaryColumns from '~/layouts/PrimarySecondaryColumns';
import Routes from '~/pages/routes';
import { selectCurrentUser } from '~/redux/currentUser/selectors';
import { CustomerContactActions } from '~/redux/customerContacts/actions';
import { selectCustomerContactById } from '~/redux/customerContacts/selectors';
import { CustomerActions } from '~/redux/customers/actions';
import { selectCustomerById } from '~/redux/customers/selectors';
import { selectFetchState } from '~/redux/fetch/selectors';
import { triggerJobFetch } from '~/redux/jobs/actions';
import { selectJobWithEdits } from '~/redux/jobs/selectors';
import { QuoteActions, sendQuote } from '~/redux/quote/actions';
import { selectQuoteWithEdits } from '~/redux/quote/selectors';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import { CognitoGroup } from '~/types/cognitoUser';
import UploadedFile from '~/types/uploaded-file';
import { useRouteParams } from '~/utils/RouteGenerator';
import { useModalManager } from '~/components/ModalManager';
import ConfirmationDialog from '~/components/ConfirmationDialog';

const QuotePreviewPage = () => {
  const [isPrinting] = useMediaQuery('print');
  const currentUser = useAppSelector((state) => selectCurrentUser(state));
  const isSuperuser = useCheckRole(CognitoGroup.Superuser);
  const { jobId, quoteId } = useRouteParams(Routes.jobQuotePreview);
  const job = useAppSelector((state) => selectJobWithEdits(state, jobId));
  const quote = useAppSelector((state) => selectQuoteWithEdits(state, quoteId));
  const { isLoading: isLoadingJob } = useAppSelector((s) => selectFetchState(s, jobId));
  const { isLoading: isLoadingQuote } = useAppSelector((s) => selectFetchState(s, `quote-${quoteId}`));
  const {
    data: customer, isLoading: isLoadingCustomer,
  } = useTrackedFetch({
    trigger: () => CustomerActions.fetch({ customerId: job?.customerId }),
    selector: (s) => selectCustomerById(s, job?.customerId),
    key: `customer-${job?.customerId}`,
  });
  const {
    data: customerMainContact, isLoading: isLoadingCustomerContact,
  } = useTrackedFetch({
    trigger: () => CustomerContactActions.fetch({ id: customer?.mainContactId }),
    selector: (s) => selectCustomerContactById(s, customer?.mainContactId),
    key: `contact-${customer?.mainContactId}`,
    enabled: !!customer?.mainContactId,
  });
  const {
    data: jobContact, isLoading: isLoadingJobContact,
  } = useTrackedFetch({
    trigger: () => CustomerContactActions.fetch({ id: job?.jobContactId }),
    selector: (s) => selectCustomerContactById(s, job?.jobContactId),
    key: `contact-${job?.jobContactId}`,
    enabled: !!job?.jobContactId,
  });
  const modalManager = useModalManager();

  const { trigger: onAcceptClicked, isLoading: isSavingAccepted } = useTrackedAction({
    trigger: () => QuoteActions.accept({
      jobId,
      quoteId,
    }),
  });

  const { trigger: onRevertAcceptClicked, isLoading: isSavingRevert } = useTrackedAction({
    trigger: () => QuoteActions.revertAccept({
      jobId,
      quoteId,
    }),
  });

  const defaultEmailMessage = `Your quote from ${currentUser.tenant?.name || ''} #QUOTE-${quote?.code} ${quote?.title || job?.name} is now ready for approval.\n\nFollow the link below to view your quote.`;

  const recipient = last(orderBy(values(quote?.recipients), (r) => r.sent));

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(triggerJobFetch({ jobId }));
    dispatch(QuoteActions.fetch({ jobId, quoteId }));
  }, []);

  const updateQuoteFiles = (files: UploadedFile[]) => {
    dispatch(QuoteActions.edit({
      jobId,
      quoteId,
      edits: {
        files,
      },
    }));
  };

  const printRef = useRef<HTMLDivElement>();
  const { print, isPrinting: isPrintingState } = usePrintHelper({
    contentRef: printRef,
    documentTitle: `QUOTE-${quote?.code} ${job?.name} - ${currentUser.tenant?.name}`,
  });

  const [showSendModal, setShowSendModal] = useState(false);
  const { trigger: onSendClicked, isLoading: isSendingQuote } = useTrackedAction({
    trigger: ({ name, email, message }) => sendQuote({
      jobId,
      quoteId,
      recipients: [{
        email,
        name,
      }],
      message,
    }),
  });

  const isLoading = isLoadingJob
  || isLoadingQuote
  || isLoadingCustomer
  || isLoadingCustomerContact
  || isLoadingJobContact;

  if (!isLoading) {
    if (!job) {
      return <NotFound backTo={Routes.jobList({})}>Job not found</NotFound>;
    } if (!quote) {
      return <NotFound backTo={Routes.job({ jobId })}>Job has no quote</NotFound>;
    }
  }

  const quoteStatus = quote?.status;
  const hasSentQuote = quoteStatus !== 'draft';
  const hasAcceptedQuote = quoteStatus === 'accepted';
  const termsAndConditions = quote?.termsAndConditions
  || currentUser?.tenant?.quoteTermsAndConditions;

  const previewQuote = {
    title: formatJobName(job),
    ...quote,
    ...(quote?.draft ? {
      draft: {
        ...quote.draft,
        title: formatJobName(job),
      },
    } : {}),
  };

  if (isPrinting || isPrintingState) {
    if (quote) {
      return (
        <CappedWidthLayout>
          <VisibleOnlyIn media="screen">
            {/* This shows the skeleton page while the print view is
              being prepared. It's possible to avoid this if we render
              the quote a third time, but doing this means we can use
              the same codepath for printing and avoid differences between
              the File - Print and react-to-print code paths */}
            <PrimarySecondaryColumns>
              <GridItem gridArea="primary">
                <SkeletonQuote isLoaded={false} />
              </GridItem>
            </PrimarySecondaryColumns>
          </VisibleOnlyIn>
          <VisibleOnlyIn ref={printRef} media="print">
            <QuoteView
              quote={previewQuote}
              referenceNumber={quote.referenceNumber || job.referenceNumber}
              customer={customer}
              tenant={currentUser.tenant}
              showTermsAndConditions
            />
          </VisibleOnlyIn>
        </CappedWidthLayout>
      );
    }
    return <Text>Printing error: Failed to load quote</Text>;
  }

  let buttonText = 'Resend';
  if (quote) {
    if (quote.accepted) {
      buttonText = 'Accepted';
    } else if (quote.status === 'draft') {
      buttonText = 'Send';
    } else if (quote.draft) {
      buttonText = 'Send edited quote';
    }
  }
  if (isLoading) {
    return <SkeletonText noOfLines={2} />;
  }

  const { expiryDate } = quote.draft || quote;
  const expired = expiryDate ? new Date(expiryDate) < new Date() : false;
  const files = quote?.draft?.files || quote?.files;
  return (
    <>
      <PrimarySecondaryColumns>
        <PageHeader backButton>
          {
            quote?.status === 'accepted' && (
            <Heading size="lg" color="magnetize.brand-5">
              Quote Accepted
            </Heading>
            )
          }
          {
            expired && quote?.status !== 'accepted' && (
            <Heading size="lg" color="magnetize.support-warning">
              Quote Expired
            </Heading>
            )
          }
          {
            !expired && quote?.status !== 'accepted' && (
            <Heading size="lg">
              Quote Preview
            </Heading>
            )
          }
          <Flex mt={4}>
            <CustomerRepDetails rep={quote?.sent?.by ?? currentUser?.staff} />
            <Spacer />
            <Box alignSelf="flex-end">
              <Menu placement="bottom-end" variant="magnetize">
                <MenuButton fontSize="12px">
                  Quote options
                  <Icon ml={2} w={5} h={5} as={KeyboardArrowDownOutlined} />
                </MenuButton>
                <MenuList>
                  {quote?.status !== 'accepted' && (
                  <MenuItem
                    fontWeight="bold"
                    size="lg"
                    onClick={() => {
                      modalManager.open(ConfirmationDialog, {
                        title: 'Mark quote as accepted',
                        children: `On behalf of your customer${customer?.companyName ? ` ${customer.companyName}` : ''}, would you like to manually mark this quote in Magnetize as accepted?`,
                        onConfirm: () => onAcceptClicked(),
                        colorScheme: 'brandDark',
                        confirmButtonLabel: 'MARK AS ACCEPTED',
                      });
                    }}
                    disabled={isSavingAccepted}
                  >
                    Mark as accepted
                  </MenuItem>
                  )}
                  {quote?.status === 'accepted' && <MenuItem fontWeight="bold" size="lg" onClick={onRevertAcceptClicked} disabled={isSavingRevert}>Revert to not accepted</MenuItem>}

                  <MenuDivider my={0} />
                  <MenuItem
                    size="lg"
                    onClick={print}
                  >
                    <Icon fontSize="16px" boxSize="16px" as={Print} mr={3} />
                    Print/Save as PDF
                  </MenuItem>
                </MenuList>
              </Menu>
              <Button
                ml="2"
                size="lg"
                onClick={() => setShowSendModal(true)}
                isLoading={isSendingQuote}
                isDisabled={hasAcceptedQuote}
              >
                {buttonText}
              </Button>
            </Box>
          </Flex>
        </PageHeader>
        <GridItem gridArea="primary">
          <SkeletonQuote isLoaded={!isLoading}>
            {() => (
              <Stack spacing="8">
                <PagePrintPreview fixedSize={false}>
                  <QuoteView
                    quote={previewQuote}
                    referenceNumber={quote.referenceNumber || job.referenceNumber}
                    customer={customer}
                    tenant={currentUser.tenant}
                    showTermsAndConditions={false}
                  />
                </PagePrintPreview>
                <Stack pl={10} spacing="8">
                  {!termsAndConditions && (
                  <Stack spacing={2}>
                    <Heading size="xs">Terms &amp; Conditions</Heading>
                    <DismissibleStatusAlert
                      maxW="70%"
                      id="add-terms-to-quote"
                      title="Setting up your Terms &#38; Conditions"
                      mb={8}
                    >
                      <Text>
                        If there&#39;s some Terms &#38; Conditions for your business that you need
                        added to all quotes, you can set this in the
                        {' '}
                        <LinkButton
                          textDecoration="none"
                          isGreen
                          to={Routes.quoteSettings({})}
                        >
                          organisation settings
                        </LinkButton>
                        .
                      </Text>
                    </DismissibleStatusAlert>
                  </Stack>
                  )}
                  <QuoteViewTermsAndConditions
                    tenant={currentUser.tenant}
                    termsAndConditions={termsAndConditions}
                  />
                  {
                  isSuperuser && quote.recipients && Object.values(quote.recipients).map((r) => (
                    r.token && (
                    <Button
                      key={r.email}
                      as={Link}
                      variant="link"
                      size="xs"
                      leftIcon={<LinkOutlined />}
                      to={Routes.customerQuote({ token: r.token })}
                    >
                      {`Go to ${r.email}'s Quote link (for testing!)`}
                    </Button>
                    )
                  ))
                }
                </Stack>
              </Stack>
            )}
          </SkeletonQuote>
        </GridItem>
        <GridItem gridArea="secondary">
          <Card width={240}>
            <Heading size="sm" mb={2}>attachments</Heading>
            {isLoading ? <SkeletonText noOfLines={2} />
              : (
                <QuoteFileUpload
                  files={files}
                  onFileUploaded={(file) => {
                    updateQuoteFiles([...files, file]);
                  }}
                  onFileRemoved={(file) => {
                    const { s3Key } = file;
                    const filtered = files.filter((f) => f.s3Key !== s3Key);
                    updateQuoteFiles(filtered);
                  }}
                />
              )}
          </Card>
        </GridItem>
      </PrimarySecondaryColumns>
      {showSendModal && (
      <SendConfirmationModal
        title={hasSentQuote ? 'Resend quote email' : 'Send quote email'}
        subtitle="A link will be emailed to your customer for them to view and accept your quote online."
        onClose={() => setShowSendModal(false)}
        enableMessage
        onConfirm={({ name, email, message }) => {
          setShowSendModal(false);
          onSendClicked({ name, email, message });
        }}
        defaultMessage={defaultEmailMessage}
        contact={recipient || jobContact || customerMainContact}
      />
      )}
    </>
  );
};

export default QuotePreviewPage;
