import {
  Button, Flex, FormControl, FormLabel, Grid, Modal, ModalBody,
  ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay,
  NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react';
import { startOfDay } from 'date-fns';
import React, { useEffect, useState } from 'react';
import SmartDatePicker from '~/components/SmartDatePicker';
import StaffAndEquipmentSelect from '~/components/StaffAndEquipmentSelect';
import { selectEquipmentRate } from '~/redux/equipment/selectors';
import { LogsActions } from '~/redux/logs/actions';
import { selectMatchingEquipmentLogEntry } from '~/redux/logs/selectors';
import { selectPriceById } from '~/redux/prices/selectors';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import { getIsoDate } from '~/utils/calendarHelpers';

interface AddEquipmentLogModalProps {
  jobId: string;
  onClose: () => void;
}

export const AddEquipmentLogModal = ({ jobId, onClose }: AddEquipmentLogModalProps) => {
  const dispatch = useAppDispatch();
  const [resourceId, setResourceId] = useState<string>();
  const [date, setDate] = useState<Date>(startOfDay(Date.now()));
  const [quantityString, setQuantityString] = useState('0');
  const quantity = Number(quantityString);

  const isValid = !!date && !!resourceId && !Number.isNaN(quantity);

  const existingEntry = useAppSelector(
    (s) => selectMatchingEquipmentLogEntry(s, jobId, date, resourceId),
    'deep',
  );

  const equipmentPrice = useAppSelector(
    (s) => (existingEntry
      ? selectPriceById(s, existingEntry.priceId)
      : selectEquipmentRate(s, resourceId)),
  );

  useEffect(() => {
    setQuantityString(existingEntry?.quantity.toString() ?? '0');
  }, [existingEntry]);

  const handleSaveClicked = () => {
    dispatch(LogsActions.save({
      jobId,
      log: {
        type: 'equipment',
        jobId,
        resourceId,
        date: getIsoDate(date),
        quantity,
        priceId: equipmentPrice?.id,
      },
    }));
    onClose();
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen
      onClose={onClose}
      size="2xl"
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Add Equipment Log
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Grid gridTemplateColumns="3fr 7fr" columnGap="2" rowGap="8">
            <FormControl>
              <FormLabel>Date</FormLabel>
              <SmartDatePicker selected={date} onChange={(x: Date) => setDate(x)} />
            </FormControl>
            <FormControl>
              <FormLabel>Equipment</FormLabel>
              <StaffAndEquipmentSelect
                limitTo="equipment"
                placeholder="Select equipment to log"
                value={resourceId}
                onChange={(r) => setResourceId(r?.id)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Quantity</FormLabel>
              <NumberInput
                onFocus={(e) => {
                  e.target.select();
                }}
                onChange={(e) => {
                  setQuantityString(e);
                }}
                value={quantityString}
              >
                <NumberInputField textAlign="left" />
                <NumberInputStepper>
                  <NumberIncrementStepper
                    fontSize="0.7rem"
                    border="hidden"
                    pt="1"
                  />
                  <NumberDecrementStepper
                    fontSize="0.7rem"
                    border="hidden"
                    pb="1"
                  />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
            <FormControl>
              <FormLabel>&nbsp;</FormLabel>
              <Flex h="10" alignItems="center" color="magnetize.text-4">
                {equipmentPrice?.unit}
              </Flex>
            </FormControl>
          </Grid>

        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="brandDark"
            isLoading={false}
            disabled={!isValid}
            onClick={handleSaveClicked}
          >
            Save
          </Button>
          <Button variant="ghost" onClick={onClose}>cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
