import React from 'react';
import {
  Heading, HeadingProps, Box, Flex, Center, useStyleConfig,
} from '@chakra-ui/react';

import { ReactComponent as LogoDark } from '~/assets/logo-dark.svg';

const CardTitle = ({
  size = 'sm', variant = 'default', children, ...props
}: HeadingProps) => {
  const styles = useStyleConfig('CardTitle', { size, variant });
  return (
    <Box
      borderBottomWidth={size === 'md' ? 1 : 0}
      borderColor="gray.100"
      sx={styles}
    >
      <Flex alignContent="center">
        {variant === 'magnetize-icon' && (
          <Center marginRight={4}>
            <LogoDark height="17px" />
          </Center>
        )}
        <Heading
          flex={1}
          size={size}
          color="gray.900"
          textTransform="uppercase"
          {...props}
        >
          {children}
        </Heading>

      </Flex>
    </Box>
  );
};

export default CardTitle;
