import {
  Grid, GridItem, InputProps,
} from '@chakra-ui/react';
import React from 'react';
import { Address } from '~/types/address';
import InputNoAutocomplete from '~/components/InputNoAutocomplete';
import PlacesAutocomplete from '~/components/PlacesAutocomplete';

const ADDRESS_GRID_AREAS = `
  "autocomplete autocomplete"
  "street street"
  "suburb suburb"
  "city postcode"
  "country country"
`;

const AddressForm = ({
  value = {},
  onChange,
  size = 'lg',
  enableAutocomplete = false,
}: {
  value: Partial<Address>;
  size?: InputProps['size'];
  onChange: (value: Address) => void;
  enableAutocomplete?: boolean
}) => {
  const blankAddress : Address = {
    line1: '',
    line2: '',
    city: '',
    postCode: '',
    country: '',
  };
  const updateValue = (changes: Partial<Address>) => {
    const { latitude, longitude, ...restOfAddress } = value;
    onChange({
      ...blankAddress,
      ...restOfAddress,
      ...changes,
    });
  };

  return (
    <Grid gridTemplateAreas={ADDRESS_GRID_AREAS} templateColumns="3fr 2fr" gap={2}>
      <GridItem gridArea="autocomplete">
        <PlacesAutocomplete
          size={size}
          onAddressChange={(address) => updateValue(address ?? {})}
          enableAutocomplete={enableAutocomplete}
        />
      </GridItem>
      <GridItem gridArea="street">
        <InputNoAutocomplete
          placeholder="Street"
          autoComplete={enableAutocomplete ? 'address-line1' : 'off'}
          size={size}
          value={value.line1 ?? ''}
          onChange={(e) => updateValue({ line1: e.target.value })}
        />
      </GridItem>
      <GridItem gridArea="suburb">
        <InputNoAutocomplete
          placeholder="Suburb"
          autoComplete={enableAutocomplete ? 'address-line2' : 'off'}
          size={size}
          value={value.line2 ?? ''}
          onChange={(e) => updateValue({ line2: e.target.value })}
        />
      </GridItem>
      <GridItem gridArea="city">
        <InputNoAutocomplete
          placeholder="City"
          autoComplete={enableAutocomplete ? 'address-line3' : 'off'}
          size={size}
          value={value.city ?? ''}
          onChange={(e) => updateValue({ city: e.target.value })}
        />
      </GridItem>
      <GridItem gridArea="postcode">
        <InputNoAutocomplete
          placeholder="Postcode"
          autoComplete={enableAutocomplete ? 'postal-code' : 'off'}
          size={size}
          value={value.postCode ?? ''}
          onChange={(e) => updateValue({ postCode: e.target.value })}
        />
      </GridItem>
      <GridItem gridArea="country">
        <InputNoAutocomplete
          placeholder="Country"
          autoComplete={enableAutocomplete ? 'country' : 'off'}
          size={size}
          value={value.country ?? ''}
          onChange={(e) => updateValue({ country: e.target.value })}
        />
      </GridItem>
    </Grid>
  );
};

export default AddressForm;
