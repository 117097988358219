import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
} from '@chakra-ui/react';
import React from 'react';
import { LinkProps } from 'react-router-dom';
import BackButton from '~/components/BackButton';

interface NotFoundProps {
  backTo?: LinkProps['to'],
  title?: string;
  children?: React.ReactNode;
}

const NotFound = ({ children, backTo, title = 'Not Found' }: NotFoundProps) => (
  <Alert status="error" variant="left-accent" alignItems="flex-start">
    <AlertIcon boxSize="32px" ml={4} mt={4} mr={6} />
    <Box>
      <AlertTitle>{title}</AlertTitle>
      <AlertDescription>{children}</AlertDescription>
      {backTo
        && (
        <Box mt="4">
          <BackButton colorScheme="red" variant="outline" />
        </Box>
        )}
    </Box>
  </Alert>
);

export default NotFound;
