import {
  Button, FormControl, FormLabel, Grid, Input, Modal, ModalBody,
  ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay,
  NumberInput, NumberInputField,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import React, { useState } from 'react';
import ConsumablesActions from '~/redux/consumables/actions';
import { selectStaffName } from '~/redux/staff/selectors';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import { SummaryConsumableRecord } from '~/types/consumable';

interface JobConsumableViewModel extends SummaryConsumableRecord {
  jobId: string;
}

export const EditConsumableEntryForDateModal = ({
  consumable, staffId, date, initialQuantity, onClose,
}: {
  consumable: JobConsumableViewModel,
  staffId: string,
  date: string, initialQuantity: number,
  onClose: () => void;
}) => {
  const dispatch = useAppDispatch();
  const name = useAppSelector((state) => selectStaffName(state, staffId));
  const [quantityString, setQuantityString] = useState(initialQuantity.toString());
  const quantity = Number(quantityString);

  const isValid = !Number.isNaN(quantity);

  const handleSaveClicked = () => {
    dispatch(ConsumablesActions.setForDate({
      jobId: consumable.jobId,
      id: consumable.id || `${consumable.jobId}_${consumable.name}`,
      setForDate: {
        name: consumable.name,
        priceId: consumable.priceId,
        unit: consumable.unit,
        staffId,
        date,
        quantity,
      },
    }));
    onClose();
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen
      onClose={onClose}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {`Log ${consumable.name}`}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Grid gridTemplateColumns="3fr 5fr 2fr" columnGap="2" rowGap="8">
            <FormControl>
              <FormLabel>Date</FormLabel>
              <Input value={format(new Date(date), 'dd MMM yyyy')} disabled />
            </FormControl>
            <FormControl>
              <FormLabel>Staff</FormLabel>
              <Input value={name} disabled />
            </FormControl>
            <FormControl>
              <FormLabel>Quantity</FormLabel>
              <NumberInput
                onFocus={(e) => {
                  e.target.select();
                }}
                onChange={(e) => {
                  setQuantityString(e);
                }}
                value={quantityString}
              >
                <NumberInputField textAlign="left" autoFocus />
              </NumberInput>
            </FormControl>
          </Grid>

        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="brandDark"
            isLoading={false}
            disabled={!isValid}
            onClick={handleSaveClicked}
          >
            Save
          </Button>
          <Button variant="ghost" onClick={onClose}>cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
