import {
  Box,
  Flex,
  Grid,
  GridItem,
  useDisclosure,
  Heading,
} from '@chakra-ui/react';
import React from 'react';
import LineItemsEditor from '~/components/LineItemsEditor';
import LineItemTotals from '~/components/LineItemTotals';
import LinkButton from '~/components/LinkButton';
import useDebouncedState from '~/hooks/useDebouncedState';
import PriceBookBrowseModal from '~/pages/Job/components/PriceBook/PriceBookBrowseModal';
import PriceBookSearch from '~/pages/Job/components/PriceBook/PriceBookSearch';
import { QuoteActions } from '~/redux/quote/actions';
import { useAppDispatch } from '~/redux/store';
import { LineItem } from '~/types/lineItem';
import { QuoteEdit } from '~/types/quote';
import { sortLineItems, addLineItems } from '~/utils/lineItemHelpers';

const BudgetEditor = ({ quote: quoteProp, quoteId, jobId }: {
  quoteId: string;
  jobId: string;
  quote: QuoteEdit;
}) => {
  const dispatch = useAppDispatch();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [quote, setQuote] = useDebouncedState({
    value: quoteProp,
    delay: 1500,
    dispatchUpdate: (updated) => {
      dispatch(
        QuoteActions.edit({
          jobId,
          quoteId,
          edits: updated,
        }),
      );
    },
  });

  const addLineItemsToQuote = (lineItems: LineItem[]) => {
    setQuote({
      ...quote,
      lineItems: {
        ...quote.lineItems,
        ...addLineItems(
          'start',
          sortLineItems(quote.lineItems),
          lineItems.map((item) => ({ ...item })),
        ),
      },
    });
  };

  const lineItemsChanged = (lineItems) => {
    setQuote({ ...quote, lineItems });
  };

  return (
    <>
      <Flex flexDirection="column" marginBottom={8}>
        <Heading color="black" size="sm" mb={4}>
          ADD TO JOB BUDGET
        </Heading>

        <Box flex="1" position="relative">
          <LinkButton
            zIndex="1"
            right="10px"
            top="9.5px"
            textDecoration="none"
            isGreen
            position="absolute"
            onClick={onOpen}
          >
            Browse all
          </LinkButton>
          <PriceBookSearch
            showBundles
            onSelected={(lineItem) => addLineItemsToQuote([lineItem])}
          />
        </Box>
      </Flex>
      <Grid templateColumns="repeat(24, 1fr)" gap={6}>
        <GridItem colSpan={24}>
          <LineItemsEditor
            lineItems={quote.lineItems}
            onChange={lineItemsChanged}
          />
        </GridItem>
        <GridItem colStart={2} colSpan={14} />
        <GridItem colSpan={9} fontSize="lg">
          <LineItemTotals
            lineItems={quote.lineItems}
            taxRate={quote.taxRate}
            taxRateName={quote.taxRateName}
            onTaxRateChange={({ taxRate, taxRateName }) => {
              setQuote({ ...quote, taxRate, taxRateName });
            }}
          />
        </GridItem>
      </Grid>
      {isOpen && (
        <>
          <PriceBookBrowseModal
            titleText="Add to budget"
            addButtonText="Add to budget"
            showBundles
            onAdd={addLineItemsToQuote}
            onClose={onClose}
          />
        </>
      )}
    </>
  );
};
export default BudgetEditor;
