export enum CognitoGroup {
  Superuser = 'Superuser',
  Admin = 'Admin',
  Staff = 'Staff',
}

export interface CognitoUser {
  username: string;
  token: string;
  groups: CognitoGroup[]
}
