import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  GridItem,
  HStack,
  Icon,
  useDisclosure,
} from '@chakra-ui/react';
import { ReactComponent as Duplicate } from '~/assets/duplicate.svg';
import useTrackedAction from '~/hooks/useTrackedAction';
import ConfirmationDialog from '~/components/ConfirmationDialog';
import { useModalManager } from '~/components/ModalManager';
import { Invoice } from '~/types/invoice';
import InvoiceEditor from '~/pages/Job/components/invoices/InvoiceEditor';
import InvoiceLineItemsTable from '~/pages/Job/components/invoices/InvoiceLineItemsTable';
import LineItemTotals from '~/components/LineItemTotals';
import { InvoiceActions } from '~/redux/invoices/actions';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import XeroCreateInvoiceModal from '~/components/XeroCreateInvoiceModal';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import { XeroActions } from '~/redux/xero/actions';
import { selectJobWithEdits } from '~/redux/jobs/selectors';
import InvoiceTypeModal from '~/pages/Job/components/invoices/InvoiceTypeModal';
import CloseJobModal from '~/pages/Job/components/invoices/CloseJobModal';
import { completeJob, duplicateInvoice } from '~/redux/jobs/actions';
import { showToast } from '~/toast';

const InvoicePanel = ({ invoice, draftInvoicesExist }
: {
  invoice : Invoice,
  draftInvoicesExist: boolean,
}) => {
  const modalManager = useModalManager();
  const dispatch = useAppDispatch();
  const createInvoiceModalState = useDisclosure();
  const closeJobModalState = useDisclosure();
  const [exportingInvoiceId, setExportingInvoiceId] = useState<string>();

  const { isLoading: isDiscardingInvoice, trigger: triggerDiscard } = useTrackedAction({
    trigger: (invoiceId: string) => InvoiceActions.discardDraft({
      jobId: invoice.jobId,
      invoiceId,
    }),
  });

  const job = useAppSelector((state) => selectJobWithEdits(state, invoice.jobId));

  const { data: xeroConnectionStatus, isLoading: isCheckingXeroStatus } = useTrackedFetch({
    key: 'xero-status',
    trigger: () => XeroActions.refreshStatus(),
    selector: (s) => s.xero.status?.status,
  });
  const isConnectedToXero = xeroConnectionStatus === 'ready';

  const xeroModalState = useDisclosure();

  const sendToXero = (invoiceId: string) => {
    setExportingInvoiceId(invoiceId);
    xeroModalState.onOpen();
  };
  const markSent = (inv: Invoice) => {
    dispatch(InvoiceActions.saveInvoice({
      jobId: invoice.jobId,
      invoiceId: inv.id,
      invoice: {
        ...inv,
        sentDate: new Date().toISOString(),
        status: 'sent',
      },
    }));
  };

  const {
    isLoading: isDuplicateInvoiceLoading,
    trigger: triggerDuplicateInvoice,
  } = useTrackedAction({
    trigger: () => duplicateInvoice({
      jobId: invoice.jobId,
      invoiceId: invoice.id,
    }),
  });

  const { trigger: triggerCompleteJob } = useTrackedAction({
    trigger: () => completeJob({
      jobId: invoice.jobId,
    }),
    onSuccess: () => {
      showToast({
        description: 'Job marked complete',
        status: 'success',
      });
    },
  });

  const canSendInvoice = Object.keys(invoice.lineItems).length > 0;
  const isNewConsumables = job?.consumablesVersion === 2;

  if (invoice) {
    return (
      <>
        <Box pb={20} pt={8}>
          {invoice.status === 'draft'
            ? (
              <InvoiceEditor jobId={invoice.jobId} invoiceId={invoice.id} />
            )
            : (
              <Grid templateColumns="repeat(24, 1fr)" gap={6}>
                <GridItem colSpan={24}>
                  <InvoiceLineItemsTable lineItems={invoice.lineItems} />
                </GridItem>
                <GridItem colStart={15} colSpan={10} pt={4} fontSize="lg">
                  <LineItemTotals
                    lineItems={invoice.lineItems}
                    taxRate={invoice.taxRate}
                    taxRateName={invoice.taxRateName}
                  />
                </GridItem>
              </Grid>
            )}
          <HStack mt="50px">
            <Box flex={1} />
            {!draftInvoicesExist && (
              <Button
                disabled={isDuplicateInvoiceLoading}
                size="lg"
                variant="outline"
                onClick={triggerDuplicateInvoice}
              >
                <Icon as={Duplicate} fontSize={20} mr={2} />
                duplicate invoice
              </Button>
            )}
            {invoice.status === 'draft'
              ? (
                <Button
                  size="lg"
                  variant="outline"
                  onClick={() => {
                    modalManager.open(ConfirmationDialog, {
                      title: 'Discard invoice',
                      children: 'Are you sure you want to discard this invoice?',
                      onConfirm: () => triggerDiscard(invoice.id),
                      confirmButtonLabel: 'Discard',
                      colorScheme: 'brandDark',
                    });
                  }}
                  color="magnetize.support-warning"
                  disabled={isDiscardingInvoice}
                >
                  discard invoice
                </Button>
              ) : (
                // Only allow progress invoices on v2 consumables
                !draftInvoicesExist && isNewConsumables
                && (
                <Button
                  size="lg"
                  variant="outline"
                  onClick={createInvoiceModalState.onOpen}
                  color="magnetize.brand-4"
                >
                  + add invoice
                </Button>
                )
              )}
            {job.status === 'complete' && invoice.status === 'sent' && (
              <Button
                size="lg"
                onClick={closeJobModalState.onOpen}
              >
                Close Job
              </Button>
            )}
            {job.status !== 'complete' && job.status !== 'closed' && invoice.status === 'sent' && (
              <Button
                size="lg"
                onClick={triggerCompleteJob}
              >
                Complete Job
              </Button>
            )}
            {!isCheckingXeroStatus && invoice.status === 'draft' && (
            <Button
              size="lg"
              onClick={() => {
                if (isConnectedToXero) {
                  sendToXero(invoice.id);
                } else {
                  markSent(invoice);
                }
              }}
              disabled={!canSendInvoice}
            >
              {isConnectedToXero ? 'Send to Xero' : 'Mark sent'}
            </Button>
            )}
          </HStack>
        </Box>
        {xeroModalState.isOpen && (
        <XeroCreateInvoiceModal
          jobId={invoice.jobId}
          invoiceId={exportingInvoiceId}
          isOpen={xeroModalState.isOpen}
          onClose={xeroModalState.onClose}
        />
        )}
        {createInvoiceModalState.isOpen && (
        <InvoiceTypeModal
          jobId={invoice.jobId}
          onClose={createInvoiceModalState.onClose}
        />
        )}
        {closeJobModalState.isOpen && (
        <CloseJobModal
          jobId={invoice.jobId}
          onClose={closeJobModalState.onClose}
        />
        )}
      </>
    );
  }
  return null;
};

export default InvoicePanel;
