import React from 'react';
import RightIgnoreButtons from '~/pages/Job/components/invoices/ReconciliationManager/components/RightIgnoreButtons';
import OkButton from '~/pages/Job/components/invoices/ReconciliationManager/components/OkButton';
import LeftRightArrowButtons from '~/pages/Job/components/invoices/ReconciliationManager/components/LeftRightArrowButton';
import AddIgnoreButtons from '~/pages/Job/components/invoices/ReconciliationManager/components/AddIgnoreButtons';

const ReconcileButtons = (
  {
    onLeftClick,
    onRightClick,
    onIgnoreClick,
    isDisabled,
    hasQuoted,
    quotedMatchesLogged,
    invoicedMoreThanQuoted,
    loggedMoreThanQuoted,
  } : {
    onLeftClick: () => void;
    onRightClick: () => void;
    onIgnoreClick: () => void;
    isDisabled: boolean;
    hasQuoted: boolean;
    quotedMatchesLogged: boolean;
    invoicedMoreThanQuoted: boolean;
    loggedMoreThanQuoted: boolean;
  },
) => {
  if (invoicedMoreThanQuoted) {
    return (
      <RightIgnoreButtons
        onIgnoreClick={onIgnoreClick}
        onRightClick={onRightClick}
        isDisabled={isDisabled}
      />
    );
  }
  if (quotedMatchesLogged) {
    return <OkButton onClick={onLeftClick} />;
  }
  if (hasQuoted) {
    return (
      <LeftRightArrowButtons
        isDisabled={isDisabled}
        onLeftClick={onLeftClick}
        onRightClick={onRightClick}
        color={loggedMoreThanQuoted ? 'rgba(255, 138, 0, 0.05)' : 'rgba(53,155,75, 0.05)'}
      />
    );
  }
  return (
    <AddIgnoreButtons
      onAddClick={hasQuoted ? onLeftClick : onRightClick}
      onIgnoreClick={onIgnoreClick}
      isDisabled={isDisabled}
    />
  );
};

export default ReconcileButtons;
