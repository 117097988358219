import {
  Input, InputProps,
} from '@chakra-ui/react';
import React from 'react';
import CenteredFlex from '~/components/LineItemsEditor/components/CenteredFlex';

interface LineItemNameProps {
  value: string;
  onChange: (value: string) => void;
}

const LineItemName = ({
  placeholder = '', value, onChange, ...props
} : LineItemNameProps & InputProps) => (
  <CenteredFlex flex="1">
    <Input
      pl={0}
      variant="ghost"
      value={value}
      onChange={(e) => {
        const newValue = e.target.value;
        if (newValue !== value) {
          onChange(newValue);
        }
      }}
      placeholder={placeholder}
      {...props}
    />
  </CenteredFlex>
);

export default LineItemName;
