import React, { useState } from 'react';

// Thresholds used to determine if event is classified as a mouse click
const CLICK_DISTANCE_THRESHOLD_PX = 6;
const CLICK_TIME_THRESHOLD_MS = 1000;

interface UseMouseEventsProps {
  onMouseDown?: (e: React.MouseEvent) => void;
  onClick?: (e: React.MouseEvent) => void;
  onMouseMove?: (e: React.MouseEvent) => void;
}

function useMouseEvents(
  {
    onMouseDown = () => {},
    onClick = () => {},
    onMouseMove = () => {},
  }
  : UseMouseEventsProps,
) {
  const [mouseDownPos, setMouseDownPos] = useState({
    x: 0,
    y: 0,
  });
  const [mouseDownTime, setMouseDownTime] = useState(null);
  const [target, setTarget] = useState(null);

  return {
    onMouseDown: (e: React.MouseEvent) => {
      setMouseDownPos({
        x: e.clientX,
        y: e.clientY,
      });
      setMouseDownTime(new Date().getTime());
      setTarget(e.target);
      onMouseDown(e);
    },
    onMouseUp: (e: React.MouseEvent) => {
      const x = e.clientX;
      const y = e.clientY;

      const distanceDelta = Math.abs(x - mouseDownPos?.x) + Math.abs(y - mouseDownPos?.y);
      const timeDelta = new Date().getTime() - mouseDownTime;

      if (timeDelta <= CLICK_TIME_THRESHOLD_MS
          && distanceDelta <= CLICK_DISTANCE_THRESHOLD_PX
          && e.target === target) {
        onClick(e);
      } else {
        onMouseMove(e);
      }
    },
  };
}

export default useMouseEvents;
