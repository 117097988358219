import { Flex, SkeletonText } from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import {
  groupBy, isEqual, sumBy, uniq,
} from 'lodash';
import React, { useMemo } from 'react';
import { endOfDay } from 'date-fns';
import CardTitle from '~/components/CardTitle';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import ConsumablesActions from '~/redux/consumables/actions';
import { selectConsumablesForJob, selectConsumablesForJobInDateRange } from '~/redux/consumables/selectors';
import { EquipmentActions } from '~/redux/equipment/actions';
import { selectEquipment } from '~/redux/equipment/selectors';
import { selectInvoicesByJobId } from '~/redux/invoices/selectors';
import { LogsActions } from '~/redux/logs/actions';
import { selectLogsForJob, selectLogsForJobInDateRange } from '~/redux/logs/selectors';
import PriceActions from '~/redux/prices/actions';
import { selectAllPrices } from '~/redux/prices/selectors';
import { QuoteActions } from '~/redux/quote/actions';
import { selectQuotesByJobId } from '~/redux/quote/selectors';
import StaffActions from '~/redux/staff/actions';
import { selectStaff } from '~/redux/staff/selectors';
import { useAppSelector } from '~/redux/store';
import TimesheetActions from '~/redux/timesheets/actions';
import { selectTimeRecordsForJob, selectTimeRecordsForJobInDateRange } from '~/redux/timesheets/selectors';
import { SummaryConsumableRecord } from '~/types/consumable';
import { Invoice } from '~/types/invoice';
import { LineItem } from '~/types/lineItem';
import ConsumableReconciliationRow from './components/ConsumableReconciliationRow';
import EquipmentReconciliationRow from './components/EquipmentReconciliationRow';
import QuotedOnlyReconciliationRow from './components/QuotedOnlyReconciliationRow';
import TimeRecordReconciliationRow from './components/TimeRecordReconciliationRow';
import {
  EquipmentLogWithEquipmentAndPrice,
  getConsumableReconciliationRows,
  getEquipmentReconciliationRows,
  getIds, getLeftoverReconciliationRows, getQuotesLineItems,
  getTimeReconciliationRows, TimeRecordWithStaffAndPrice,
} from './helpers';
import { triggerJobFetch } from '~/redux/jobs/actions';
import { selectJobWithEdits } from '~/redux/jobs/selectors';
import { parseISODateLocal } from '~/utils/parseTime';

interface ReconciliationManagerProps {
  isDisabled: boolean;
  jobId: string;
  invoice: Invoice;
  onChange: (invoice: Invoice) => void;
}

const ReconciliationManager = ({
  jobId, invoice, onChange, isDisabled,
} : ReconciliationManagerProps) => {
  const { data: quotes } = useTrackedFetch({
    key: `job-quotes-${jobId}`,
    trigger: () => QuoteActions.fetchForJob({ jobId }),
    selector: (state) => selectQuotesByJobId(state, jobId),
  });

  const isProgressInvoice = !!invoice.fromDate && !!invoice.toDate;

  const fromDate = invoice.fromDate && parseISODateLocal(invoice.fromDate);
  const toDate = invoice.toDate && parseISODateLocal(invoice.toDate);
  const toEod = endOfDay(toDate);

  const allInvoices = useAppSelector(
    (s) => selectInvoicesByJobId(s, jobId),
    (a, b) => isEqual(a, b),
  );

  const { data: consumables } = useTrackedFetch({
    key: `consumables-${jobId}`,
    trigger: () => ConsumablesActions.fetchForJob({ jobId }),
    selector: (state) => (isProgressInvoice
      ? selectConsumablesForJobInDateRange(state, jobId, { start: fromDate, end: toEod })
      : selectConsumablesForJob(state, jobId)),
  });

  const { data: timeRecords } = useTrackedFetch({
    key: `timesheets-${jobId}`,
    selector: (state) => (isProgressInvoice
      ? selectTimeRecordsForJobInDateRange(state, jobId, { start: fromDate, end: toEod })
      : selectTimeRecordsForJob(state, jobId)),
    trigger: () => TimesheetActions.fetchForJob({ jobId }),
  });
  const { data: equipmentLogs } = useTrackedFetch({
    key: `job-logs-${jobId}`,
    trigger: () => LogsActions.fetchForJob({ jobId }),
    selector: (s) => (isProgressInvoice
      ? selectLogsForJobInDateRange(s, jobId, { start: fromDate, end: toEod })
      : selectLogsForJob(s, jobId))
      .filter((e) => e.type === 'equipment'),
  });
  const { data: staff } = useTrackedFetch({
    key: 'staff',
    selector: (state) => selectStaff(state),
    trigger: () => StaffActions.fetch(),
  });
  const { data: prices } = useTrackedFetch({
    key: 'prices',
    selector: (state) => selectAllPrices(state),
    trigger: () => PriceActions.fetch(),
  });
  const { data: equipment } = useTrackedFetch({
    key: 'equipment',
    selector: selectEquipment,
    trigger: () => EquipmentActions.fetch(),
  });
  const { data: job } = useTrackedFetch({
    key: jobId,
    trigger: () => triggerJobFetch({ jobId }),
    selector: (s) => selectJobWithEdits(s, jobId),
    equalityMode: 'deep',
  });

  const isLoaded = !!quotes && !!invoice && !!consumables
  && !!timeRecords && !!staff && !!prices && !!equipment && !!equipmentLogs;

  // Check if any quotes with status other than 'draft' exist:
  const isBudget = !(job?.needsQuote) && quotes?.every((q) => q.status === 'draft');

  const previousInvoices = useMemo(
    () => allInvoices.filter((i) => i.id !== invoice.id),
    [allInvoices, invoice.id],
  );

  const previousInvoiceGroupedLineItems = useMemo(
    () => Object.values(groupBy(previousInvoices
      .flatMap((i) => Object.values(i.lineItems)), (li) => li.priceId || li.name))
      .map((values) => ({
        ...values[0],
        quantity: values.reduce((acc, li) => acc + li.quantity, 0),
      })),
    [previousInvoices],
  );

  const itemsToIgnore = useMemo(() => new Set([
    ...Object.values(invoice.lineItems).flatMap((li) => li.reconciledItems ?? []),
    ...invoice?.ignoredReconciledItems ?? [],
  ]), [allInvoices, invoice]);

  // // Get quote items which have not been reconciled or ignored
  const quotedLineItems = useMemo(
    () => getQuotesLineItems(quotes ?? [])
      .filter((li) => !itemsToIgnore.has(li.id)),
    [quotes, itemsToIgnore],
  );
  const remainingLineItems = useMemo(
    () => quotedLineItems.map((li) => ({
      ...li,
      quantity: li.quantity,
    })),
    [previousInvoiceGroupedLineItems, quotedLineItems],
  );

  // Match time records
  const timeResult = useMemo(() => (
    isLoaded
      ? getTimeReconciliationRows(
        remainingLineItems,
        timeRecords.filter((tr) => !itemsToIgnore.has(tr.id)),
        staff,
        prices,
      ) : null), [quotedLineItems, timeRecords, staff, prices, itemsToIgnore]);

  // Match equipment
  const equipmentResult = useMemo(() => (
    isLoaded
      ? getEquipmentReconciliationRows(
        timeResult.quoteLineItems,
        equipmentLogs.filter((el) => !itemsToIgnore.has(el.id)),
        equipment,
        prices,
      ) : null), [timeResult, timeRecords, staff, prices, itemsToIgnore]);

  // Match consumables
  const consumableResult = useMemo(
    () => (
      isLoaded
        ? getConsumableReconciliationRows(
          equipmentResult.quoteLineItems,
          consumables.filter((c) => !itemsToIgnore.has(c.id)),
        ) : null), [equipmentResult, consumables, itemsToIgnore],
  );

  // Get leftover rows (quoted only)
  const leftover = useMemo(() => (isLoaded
    ? getLeftoverReconciliationRows(consumableResult.quoteLineItems)
      .filter((li) => sumBy(li.quoted, 'quantity') > sumBy(previousInvoiceGroupedLineItems
        .filter((pli) => (li.quoted[0]?.priceId
          ? pli.priceId === li.quoted[0].priceId
          : pli.name === li.quoted[0]?.name)), 'quantity'))
    : null), [consumableResult]);

  if (!isLoaded) {
    return <SkeletonText noOfLines={2} />;
  }

  const addItemToInvoice = (newLineItem: LineItem, reconciledItems: string[]) => {
    // TODO currently onItemAdded inside ReconciliationRow's
    // is trying to figure out the unit price cents
    // this is necessary in some cases (custom line items) however we can clean up that code
    // by just working out the unit price cents here as we have easy access to the prices
    let { unitPriceCents } = newLineItem;
    if (unitPriceCents === undefined && newLineItem.priceId) {
      unitPriceCents = prices.find((p) => p.id === newLineItem.priceId)?.unitPriceCents;
    } else if (unitPriceCents === undefined) {
      unitPriceCents = 0;
    }
    const lineItem : LineItem = {
      ...newLineItem,
      unitPriceCents,
      id: nanoid(),
      reconciledItems,
      order: Object.keys(invoice.lineItems || {}).length,
    };

    onChange({
      ...invoice,
      lineItems: {
        ...invoice.lineItems,
        [lineItem.id]: lineItem,
      },
    });
  };

  const ignoreItemForInvoice = (reconciledItems: string []) => {
    onChange({
      ...invoice,
      ignoredReconciledItems: uniq([
        ...invoice.ignoredReconciledItems ?? [],
        ...reconciledItems,
      ]),
    });
  };

  const displayTitles = timeResult.reconciliationItems.length > 0
            || consumableResult.reconciliationItems.length > 0
            || equipmentResult.reconciliationItems.length > 0
            || leftover.length > 0;

  return (
    <Flex direction="column">
      {displayTitles
      && (
      <Flex flex={1}>
        <Flex flex={1}>
          <CardTitle>{isBudget ? 'Budgeted' : 'Quoted'}</CardTitle>
        </Flex>
        <Flex flex={1}>
          <CardTitle pl={8} flex={1}>Logs</CardTitle>
        </Flex>
      </Flex>
      )}
      {timeResult.reconciliationItems
        .map((item) => {
          const reconciledItems = getIds(item.quoted, item.logged);
          return (
            <TimeRecordReconciliationRow
              isDisabled={isDisabled}
              key={reconciledItems.join()}
              quoted={item.quoted}
              alreadyInvoiced={
                previousInvoiceGroupedLineItems
                  .filter((li) => (item.quoted[0]?.priceId
                    ? li.priceId === item.quoted[0].priceId
                    : li.name === item.quoted[0]?.name))
              }
              logged={item.logged as TimeRecordWithStaffAndPrice[]}
              onItemAdded={(newLineItem) => {
                addItemToInvoice(newLineItem, reconciledItems);
              }}
              onItemIgnored={() => {
                ignoreItemForInvoice(reconciledItems);
              }}
            />
          );
        })}
      {equipmentResult.reconciliationItems
        .map((item) => {
          const reconciledItems = getIds(item.quoted, item.logged);
          return (
            <EquipmentReconciliationRow
              isDisabled={isDisabled}
              key={reconciledItems.join()}
              quoted={item.quoted}
              alreadyInvoiced={
                  previousInvoiceGroupedLineItems
                    .filter((li) => (item.quoted[0]?.priceId
                      ? li.priceId === item.quoted[0].priceId
                      : li.name === item.quoted[0]?.name))
                }
              logged={item.logged as EquipmentLogWithEquipmentAndPrice[]}
              onItemAdded={(newLineItem) => {
                addItemToInvoice(newLineItem, reconciledItems);
              }}
              onItemIgnored={() => {
                ignoreItemForInvoice(reconciledItems);
              }}
            />
          );
        })}
      {consumableResult.reconciliationItems
        .map((item) => {
          const reconciledItems = getIds(item.quoted, item.logged);
          return (
            <ConsumableReconciliationRow
              isDisabled={isDisabled}
              key={reconciledItems.join()}
              quoted={item.quoted}
              alreadyInvoiced={
                previousInvoiceGroupedLineItems
                  .filter((li) => (item.quoted[0]?.priceId
                    ? li.priceId === item.quoted[0].priceId
                    : li.name === item.quoted[0]?.name))
              }
              logged={item.logged as SummaryConsumableRecord[]}
              onItemAdded={(newLineItem) => {
                addItemToInvoice(newLineItem, reconciledItems);
              }}
              onItemIgnored={() => {
                ignoreItemForInvoice(reconciledItems);
              }}
            />
          );
        })}
      {leftover.map((item) => {
        const reconciledItems = getIds(item.quoted);
        return (
          <QuotedOnlyReconciliationRow
            isDisabled={isDisabled}
            key={reconciledItems.join()}
            quoted={item.quoted}
            alreadyInvoiced={
              previousInvoiceGroupedLineItems
                .filter((li) => (item.quoted[0]?.priceId
                  ? li.priceId === item.quoted[0].priceId
                  : li.name === item.quoted[0]?.name))
            }
            onItemAdded={(newLineItem) => {
              addItemToInvoice(newLineItem, reconciledItems);
            }}
            onItemIgnored={() => {
              ignoreItemForInvoice(reconciledItems);
            }}
          />
        );
      })}
    </Flex>
  );
};
export default ReconciliationManager;
