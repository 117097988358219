const stopEvents = (e: React.SyntheticEvent) => e.stopPropagation();

/**
 * Event propagation with React portals will pass events through, ignoring the
 * underlying DOM structure. I'm sure there are some good reasons why you'd want
 * this to happen, but in many cases it's kind of silly -- e.g. keypresses and
 * mouse events on a popover will propagate through to the content that
 * triggered it, which can have some unexpected events.
 *
 * This helper will register an e => e.stopPropagation() event handler on a
 * bunch of events so you can spread it into the props of a container to sandbox
 * events inside
 * @returns
 */
const eventStopper = () => ({
  onMouseDown: stopEvents,
  onClick: stopEvents,
  onMouseUp: stopEvents,
  onKeyDown: stopEvents,
  onKeyUp: stopEvents,
  onKeyPress: stopEvents,
  onDoubleClick: stopEvents,
});

export default eventStopper;
