import { createReducer } from '@reduxjs/toolkit';
import {
  fetchCompleted,
  fetchErrored,
  fetchInvalidated,
  fetchPartiallyCompleted,
  fetchStarted,
  isFetchAction,
} from '~/redux/fetch/actions';
import { FetchState } from '~/types/fetchState';
import { Dictionary } from '~/types/helpers';

type FetchStateSansData = Omit<FetchState<never>, 'data'>;

const fetchStateReducer = createReducer<FetchStateSansData>(
  {
    status: 'idle',
    isInvalid: true,
    lastUpdatedAt: undefined,
    errorMessage: undefined,
    errorType: undefined,
  },

  (builder) => (builder
    .addCase(fetchStarted, (state) => ({
      ...state,
      status: 'loading',
      isPartiallyComplete: false,
      errorMessage: undefined,
      errorType: undefined,
    }))

    .addCase(fetchCompleted, (state, action) => ({
      ...state,
      status: 'success',
      errorMessage: undefined,
      errorType: undefined,
      isInvalid: false,
      lastUpdatedAt: action.payload.at,
    }))

    .addCase(fetchPartiallyCompleted, (state, action) => ({
      ...state,
      status: 'loading',
      isPartiallyComplete: true,
      errorMessage: undefined,
      errorType: undefined,
      isInvalid: false,
      lastUpdatedAt: action.payload.at,
    }))

    .addCase(fetchErrored, (state, action) => ({
      ...state,
      status: 'error',
      errorMessage: action.payload.errorMessage,
      errorType: action.payload.errorType,
      isInvalid: true,
    }))

    .addCase(fetchInvalidated, (state) => ({
      ...state,
      isInvalid: true,
    }))
  ),
);

const fetchReducer = createReducer(
  {} as Dictionary<FetchStateSansData>,
  (builder) => (builder
    .addMatcher(isFetchAction, (state, action) => action.payload.keys.reduce((s, key) => ({
      ...s,
      [key]: fetchStateReducer(s[key], action),
    }), state))
  ),
);

export default fetchReducer;
