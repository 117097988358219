import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal, ModalBody, ModalCloseButton,
  ModalContent, ModalFooter, ModalHeader, ModalOverlay,
  Spacer,
  Stack,
  Switch,
  Text,
  Textarea,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import CurrencyText from '~/components/CurrencyText';
import LineItemsEditor from '~/components/LineItemsEditor';
import PriceBookSearch from '~/pages/Job/components/PriceBook/PriceBookSearch';
import {
  makePriceBundle,
  PriceBundleViewModel,
} from '~/pages/PriceBook/BundleViewModel';
import PriceBundleActions from '~/redux/priceBundle/actions';
import { useAppDispatch } from '~/redux/store';
import calculateQuoteTotals from '~/utils/calculateQuoteTotals';
import { addLineItems, sortLineItems } from '~/utils/lineItemHelpers';

interface BundleEditModalProps {
  bundle: PriceBundleViewModel;
  title: string;
  onClose: () => void;
}

const BundleEditModal = ({
  bundle,
  title,
  onClose = () => {},
}: BundleEditModalProps) => {
  const dispatch = useAppDispatch();
  const [editedBundle, setEditedBundle] = useState(bundle);

  const onSaveClicked = () => {
    dispatch(PriceBundleActions.save({
      priceBundle: makePriceBundle(editedBundle),
    }));
    onClose();
  };

  const { subtotalCents } = calculateQuoteTotals(Object.values(editedBundle.lineItems));

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen
      onClose={onClose}
      size="3xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {title}
          <Text fontFamily="open sans" fontWeight="normal" fontSize="xl">
            Bundle rates together to quote faster
          </Text>
        </ModalHeader>
        <ModalCloseButton tabIndex={-1} />
        <ModalBody>
          <Stack spacing={8}>
            <HStack spacing={4}>
              <FormControl flex="2">
                <FormLabel>
                  Bundle Name
                </FormLabel>
                <Input
                  value={editedBundle.name}
                  onChange={(e) => {
                    setEditedBundle({
                      ...editedBundle,
                      name: e.target.value,
                    });
                  }}
                  flex="2"
                />
              </FormControl>
              <FormControl flex="1">
                <FormLabel>
                  Availability
                </FormLabel>
                <HStack>
                  <Switch
                    size="lg"
                    isChecked={editedBundle.isAvailable}
                    onChange={(e) => {
                      setEditedBundle({
                        ...editedBundle,
                        isAvailable: e.target.checked,
                      });
                    }}
                  />
                  <Text
                    mb="0"
                    cursor="pointer"
                    onClick={() => {
                      setEditedBundle({
                        ...editedBundle,
                        isAvailable: !editedBundle.isAvailable,
                      });
                    }}
                  >
                    Available for quotes
                  </Text>
                </HStack>
              </FormControl>
            </HStack>
            <FormControl>
              <FormLabel display="flex">
                Description
              </FormLabel>
              <Textarea
                resize="none"
                placeholder="Enter a description"
                value={editedBundle.description ?? ''}
                onChange={(e) => {
                  setEditedBundle({
                    ...editedBundle,
                    description: e.target.value,
                  });
                }}
              />
            </FormControl>
            <FormControl>
              <FormLabel>
                Add Rates
              </FormLabel>
              <PriceBookSearch
                onSelected={(newLineItem) => {
                  setEditedBundle({
                    ...editedBundle,
                    lineItems: {
                      ...editedBundle.lineItems,
                      ...addLineItems(
                        'start', sortLineItems(editedBundle.lineItems),
                        [newLineItem],
                      ),
                    },
                  });
                }}
              />
            </FormControl>
            <Flex direction="column" flex="1">
              <LineItemsEditor
                disableAddNew
                disableModifyLineItem
                lineItems={editedBundle.lineItems}
                onChange={(lineItems) => {
                  setEditedBundle({
                    ...editedBundle,
                    lineItems,
                  });
                }}
              />
              {Object.keys(editedBundle.lineItems).length === 0 && (
              <Box
                borderColor="gray.100"
                color="gray.400"
                borderBottomWidth={1}
              >
                <Text py={3}>
                  line items will appear here
                </Text>
              </Box>
              )}
            </Flex>
            <Flex>
              <Spacer />
              <HStack mt={2}>
                <Text display="flex" fontWeight="bold">
                  TOTAL
                  <Text ml={1} fontWeight="400">(ex TAX)</Text>
                </Text>
                <CurrencyText fontWeight="bold" value={subtotalCents} />
              </HStack>
            </Flex>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="brandDark"
            disabled={!editedBundle.name || Object.keys(editedBundle).length === 0}
            onClick={onSaveClicked}
          >
            Save
          </Button>
          <Button tabIndex={-1} variant="ghost" onClick={onClose}>cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BundleEditModal;
