import { generatePath, useParams } from 'react-router';

export type RouteGenerator<TParams> = (params: TParams) => string;

type BaseParamsType = { [key: string]: string | number | boolean };

/**
 * Creates a typed RouteGenerator function.
 *
 * This is a wrapper around generatePath from react-router, but allows us
 * to specify the params type once, when setting up the routes.
 * @param path
 * @returns
 */
export function makeRoute<TParams extends BaseParamsType>(path: string): RouteGenerator<TParams> {
  return (params) => generatePath(path, params);
}

/**
 * A more Typescript friendly version of the useParams hook that
 * can infer the types based on the route generator.
 *
 * @param route The route object. This is only used to infer the type
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useRouteParams<TParams>(route: RouteGenerator<TParams>) {
  return useParams() as TParams;
}
