import React from 'react';
import {
  Box, Container, Flex, Heading, HStack, Icon, Text,
} from '@chakra-ui/react';
import {
  FaInfoCircle,
} from 'react-icons/fa';
import CustomerRepDetails from '~/components/CustomerRepDetails';
import PageHeader from '~/layouts/PageHeader';
import { CustomerFacingSentBy } from '~/types/contact-info';

export const CustomerFacingHeader = ({
  headerTitle, headerTextColor, warningMessage, sentBy, headerColor = 'magnetize.ui-1', actions = [],
}: {
  headerTitle: React.ReactNode,
  headerColor?: string,
  headerTextColor?: string,
  warningMessage?:string,
  sentBy?: CustomerFacingSentBy,
  actions?:React.ReactNode[]
}) => (
  <>
    <Box
      pt={5}
      backgroundImage="url('/images/light-bg.png')"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
    />
    <Box
      backgroundColor={headerColor}
      borderBottomWidth={1}
      pb={10}
    >
      <Container maxWidth="1100px">
        <Flex flexDir="row" justifyContent="space-between">
          <Flex
            flexDir="column"
          >
            <PageHeader>
              <Flex flexDir="row" alignItems="center" pb={2} pt={12}>
                <Heading size="lg" color={headerTextColor}>
                  {headerTitle}
                </Heading>
                {warningMessage && (
                <Text size="md" ml={4} color="magnetize.support-warning" fontWeight="semibold">
                  <Icon as={FaInfoCircle} mr={2} />
                  {warningMessage}
                </Text>
                )}
              </Flex>
            </PageHeader>
            {sentBy
            && (
            <Flex>
              <CustomerRepDetails rep={sentBy} />
            </Flex>
            )}
          </Flex>
          <HStack spacing={4} alignItems="end">
            {actions}
          </HStack>
        </Flex>
      </Container>
    </Box>
  </>
);
