import { ContactInfo } from '~/types/contact-info';
import { Quote } from '~/types/quote';
import fetchJson from '~/utils/fetchJson';

export const getJobQuote = (jobId: string, quoteId: string) => fetchJson<Quote>(
  `/api/jobs/${jobId}/quotes/${quoteId}`,
);

export const getJobQuotes = (jobId: string) => fetchJson<Quote[]>(
  `/api/jobs/${jobId}/quotes`,
);

export const createQuote = (jobId: string) => (
  fetchJson<Quote>(`/api/jobs/${jobId}/quotes/`, {
    method: 'POST',
  })
);

export const getCustomerQuote = (token: string) => fetchJson<Quote>(
  `/api-public/quote?token=${token}`,
);

export const postSendQuote = (
  jobId: string,
  quoteId: string,
  recipients: ContactInfo[],
  subject?: string,
  message?: string,
) => fetchJson<Quote>(`/api/jobs/${jobId}/quotes/${quoteId}/send-quote`, {
  method: 'POST',
  body: {
    recipients,
    subject,
    message,
  },
});

export const postDiscardDraft = (
  jobId: string,
  quoteId: string,
) => fetchJson<Quote>(`/api/jobs/${jobId}/quotes/${quoteId}/discard-draft`, {
  method: 'POST',
});

export const postAcceptQuote = (
  jobId: string,
  quoteId: string,
) => fetchJson<Quote>(`/api/jobs/${jobId}/quotes/${quoteId}/accept`, {
  method: 'POST',
});

export const postRevertAcceptQuote = (
  jobId: string,
  quoteId: string,
) => fetchJson<Quote>(`/api/jobs/${jobId}/quotes/${quoteId}/revert-accept`, {
  method: 'POST',
});
