import {
  Button,
  Modal, ModalBody, ModalCloseButton,
  ModalContent, ModalFooter, ModalHeader, ModalOverlay,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useAppDispatch } from '~/redux/store';
import { updateBackfillType } from '~/redux/backfillTypes/actions';
import { BackfillType } from '~/types/backfillType';
import { BackfillTypeForm } from './BackfillTypeForm';

export const BackfillTypeEditModal = ({
  backfillType,
  title = 'Edit backfill type',
  onClose = () => {},
}: {
  backfillType: Partial<BackfillType>,
  title: string,
  onClose: () => void
}) => {
  const dispatch = useAppDispatch();
  const [editedBackfillType, setEditedBackfillType] = useState(backfillType);
  const [isValid, setIsValid] = useState(false);

  const onSaveClicked = () => {
    dispatch(updateBackfillType(editedBackfillType as BackfillType));
    onClose();
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen
      onClose={onClose}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {title}
        </ModalHeader>
        <ModalCloseButton tabIndex={-1} />
        <ModalBody>
          <BackfillTypeForm
            value={editedBackfillType}
            onChange={(st, v) => {
              setEditedBackfillType({
                ...editedBackfillType,
                ...st,
              });
              setIsValid(v);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={!isValid}
            colorScheme="brandDark"
            onClick={onSaveClicked}
            size="lg"
          >
            Save
          </Button>
          <Button tabIndex={-1} variant="ghost" onClick={onClose}>cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
