import {
  FormControl, FormLabel, Grid, HStack, Image, Input, Box,
  Popover, PopoverArrow, PopoverContent, PopoverTrigger, Text, Link, FormErrorMessage,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { uniq } from 'lodash';
import { getAvailableSoilImages } from '~/redux/soilTypes/selectors';
import { useAppSelector } from '~/redux/store';
import { SoilType, SoilTypeImageId } from '~/types/soilType';
import { magnetizeWebsiteUrl } from '~/helpers/config';

export const SoilTypeForm = (
  {
    value = {},
    onChange = () => {},
  }
  : {
    value?: Partial<SoilType>;
    onChange?: (technique: Partial<SoilType>, isValid) => void
  },
) => {
  const availableImageIds = useAppSelector((state) => uniq([
    ...getAvailableSoilImages(state),
    ...(value.imageId && value.imageId !== 'blank' ? [value.imageId] : []),
  ]));
  const [name, setName] = useState(value.name ?? '');
  const [imageId, setImageId] = useState<SoilTypeImageId>(value.imageId ?? availableImageIds[0] ?? 'blank');

  const nameValid = name.length <= 12;
  const nameRef = useRef(null);

  useEffect(() => {
    onChange({
      ...(value || {}),
      name,
      imageId,
    }, nameValid && name.length > 0 && imageId !== 'blank');
  }, [
    name,
    imageId,
  ]);

  return (
    <Grid gap={6}>

      <HStack gridGap={6}>
        <FormControl flex="2" isInvalid={!nameValid} isRequired>
          <FormLabel>Name</FormLabel>
          <Input
            autoFocus
            size="lg"
            ref={nameRef}
            placeholder="Enter the name for the soil type"
            value={name}
            onChange={(e) => { setName(e.target.value); }}
          />
          <FormErrorMessage>Maximum length is 12</FormErrorMessage>
        </FormControl>
        <FormControl w="50px" justifyContent="center">
          <FormLabel textAlign="center" marginRight="0px">IMAGE</FormLabel>
          <Popover>
            {({ onClose }) => (
              <>
                <PopoverTrigger>
                  <Box
                    margin="auto"
                    w="36px"
                    h="36px"
                    cursor="pointer"
                    borderWidth={1}
                    borderColor="magnetize.text-4"
                  >
                    <Image src={`/images/live-geo/soil/${imageId}.png`} margin="auto" />
                  </Box>
                </PopoverTrigger>
                {availableImageIds.length === 0 ? (
                  <PopoverContent padding={3}>
                    <PopoverArrow />
                    <Text>
                      There are no more soil layer image options to display.
                      {' '}
                      <Link href={`${magnetizeWebsiteUrl}contact`} isExternal color="magnetize.brand-4">
                        Contact us
                      </Link>
                      {' '}
                      if this is a problem for your business
                    </Text>

                  </PopoverContent>
                ) : (
                  <PopoverContent padding={3} w="350px" h="350px">
                    <PopoverArrow />
                    <Box overflow="scroll" padding={10}>

                      <Grid gap={6} templateColumns="repeat(4, 1fr)">
                        {availableImageIds.map((id) => (
                          <Box
                            key={id}
                            borderWidth={id === imageId ? 2 : 1}
                            borderColor={id === imageId ? 'magnetize.brand-4' : 'magnetize.ui-7'}
                          >
                            <Image
                              src={`/images/live-geo/soil/${id}.png`}
                              onClick={() => {
                                setImageId(id);
                                onClose();
                              }}
                              cursor="pointer"
                            />

                          </Box>
                        ))}
                      </Grid>
                    </Box>
                  </PopoverContent>
                )}
              </>
            )}
          </Popover>
        </FormControl>
      </HStack>

    </Grid>
  );
};
