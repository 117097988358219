import React from 'react';
import {
  Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter,
  ModalHeader, ModalOverlay, Icon, Box, Text, Heading, AccordionIcon,
  AccordionButton, AccordionPanel, AccordionItem, Accordion, Image, Spacer,
} from '@chakra-ui/react';
import {
  FaCheck,
} from 'react-icons/fa';
import { SubscriptionPrices } from '~/types/subscription';
import Routes from '~/pages/routes';
import { ReactComponent as LogoDark } from '~/assets/logo-dark.svg';
import CurrencyText from '~/components/CurrencyText';
import LinkButton from '~/components/LinkButton';
import { calculateStaffPrice, calculateSubscriptionTotalPrice } from '~/helpers/subscription';

const PlanModal = ({
  isPlanActive, onCancel, prices, actionButton,
}: {
  isPlanActive: boolean,
  actionButton: React.ReactNode,
  prices: SubscriptionPrices,
  onCancel: () => void,
}) => {
  const activePowerUps = (prices?.powerUps || []).filter((x) => x.isActive);

  const staffTotal = calculateStaffPrice(prices);
  const currentTotal = calculateSubscriptionTotalPrice(prices);

  return (
    <Modal isOpen onClose={onCancel} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader paddingBottom={5}>
          <Heading size="lg" paddingTop={5}>{isPlanActive ? 'Your subscription details' : 'Set your Magnetize plan'}</Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody paddingBottom={6}>
          <Box marginBottom={16} maxHeight="calc(80vh - 200px)" overflowY="scroll">
            <Accordion allowToggle mb={12} defaultIndex={isPlanActive ? undefined : 0}>
              <AccordionItem>
                <AccordionButton fontWeight="normal">
                  <LogoDark height="24px" />
                  <Box px={3} flex={1} textAlign="left">
                    <Text size="md" fontWeight="bold">Magnetize monthly subscription</Text>
                    <Text size="sm">
                      {`${prices.admin.currentCount} full users`}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {`${prices.staff.currentCount} staff`}
                    </Text>
                  </Box>
                  <CurrencyText value={staffTotal} paddingRight={3} currency="NZD" />
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel p="18px">
                  <Box>
                    <Text fontStyle="italic" mb={3}>
                      You can add more staff to Magnetize at anytime in
                      <LinkButton
                        mb={2}
                        pl={1}
                        to={Routes.teamSettings({})}
                        isGreen
                        noUnderline
                        fontStyle="italic"
                        fontWeight="normal"
                      >
                        Teams
                      </LinkButton>
                      .
                    </Text>
                    <Box>
                      <Box display="flex" flexDirection="row" alignItems="baseline">
                        <Text fontSize={14} fontWeight="extrabold">Full users&nbsp;</Text>
                        <Text color="gray.600">{`for $${(prices.admin.priceCents / 100).toFixed(0)} per user/month (NZD)`}</Text>
                      </Box>
                      <Box fontSize={12} fontWeight="600">
                        <Box display="flex" flexDirection="row" alignItems="baseline">
                          <Icon as={FaCheck} fontSize={10} />
                          <Text marginLeft={4}>Full access</Text>
                        </Box>
                        <Box display="flex" flexDirection="row" alignItems="baseline">
                          <Icon as={FaCheck} fontSize={10} />
                          <Text marginLeft={4}>Create and schedule jobs</Text>
                        </Box>
                        <Box display="flex" flexDirection="row" alignItems="baseline">
                          <Icon as={FaCheck} fontSize={10} />
                          <Text marginLeft={4}>Quote, invoice and edit rates</Text>
                        </Box>
                        <Box display="flex" flexDirection="row" alignItems="baseline">
                          <Icon as={FaCheck} fontSize={10} />
                          <Text marginLeft={4}>Manage equipment and consumables</Text>
                        </Box>
                      </Box>
                    </Box>
                    <Box marginTop={5}>
                      <Box display="flex" flexDirection="row" alignItems="baseline">
                        <Text fontSize={14} fontWeight="800">Staff users&nbsp;</Text>
                        <Text color="gray.600">{`for $${(prices.staff.priceCents / 100).toFixed(0)} per user/month (NZD)`}</Text>
                      </Box>
                      <Box fontSize={12} fontWeight="600">
                        <Box display="flex" flexDirection="row" alignItems="baseline">
                          <Icon as={FaCheck} fontSize={10} />
                          <Text marginLeft={4}>Mobile app</Text>
                        </Box>
                        <Box display="flex" flexDirection="row" alignItems="baseline">
                          <Icon as={FaCheck} fontSize={10} />
                          <Text marginLeft={4}>See assigned jobs</Text>
                        </Box>
                        <Box display="flex" flexDirection="row" alignItems="baseline">
                          <Icon as={FaCheck} fontSize={10} />
                          <Text marginLeft={4}>Log time, equipment, consumables</Text>
                        </Box>
                        <Box display="flex" flexDirection="row" alignItems="baseline">
                          <Icon as={FaCheck} fontSize={10} />
                          <Text marginLeft={4}>Log notes, files and photos</Text>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>

            <Box display="flex" flexDir="row" justifyContent="space-between" mb={5}>
              <Heading size="sm">POWER UPS</Heading>
            </Box>
            { activePowerUps.map((price, ix) => (
              <Flex
                key={price.powerUpId}
                cursor="pointer"
                onClick={() => {}}
                alignItems="center"
                py={3}
                borderColor="magnetize.ui-3"
                borderBottomWidth={1}
                borderTopWidth={ix === 0 ? 1 : 0}
                justifyContent="space-between"
                px={3}
              >
                <Image src={price.image} h="33px" w="33px" mr={5} />
                <Box>
                  <Text size="lg" fontWeight="bold">{price.name}</Text>
                  <Text size="sm" color="magnetize.text-3">{price.description}</Text>
                </Box>
                <Spacer />
                <Flex>
                  {price.priceCents > 0 ? (
                    <Text>
                      <CurrencyText value={price.priceCents} currency="NZD" />
                    </Text>
                  ) : 'free'}
                </Flex>
              </Flex>
            )) }
          </Box>
          <Box display="flex" flexDirection="row" borderTopWidth={1} paddingTop={3} justifyContent="flex-end" marginLeft="120px">
            <Box textAlign="right" marginRight={6} marginTop={2}>
              <Text fontWeight="bold">TOTAL MONTHLY</Text>
              <Text>+ GST</Text>
            </Box>
            <Box>
              <Box
                display="flex"
                alignItems="baseline"
                flexDirection="row"
              >
                <Text fontSize="28px" fontWeight="600">{`$${(currentTotal / 100).toFixed(0)}`}</Text>
                <Text fontSize="16px" fontWeight="900">{`.${(currentTotal / 100).toFixed(2).split('.')[1]}`}</Text>
              </Box>
            </Box>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Flex>
            {actionButton}
            <Button size="lg" variant="ghost" onClick={onCancel}>cancel</Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PlanModal;
