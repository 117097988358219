import React from 'react';
import {
  BoxProps, Button, ButtonGroup, ButtonProps,
} from '@chakra-ui/react';

interface RangeToggleButtonProps extends ButtonProps {
  mode: string;
  currentMode: string;
  onChangeMode: (mode: string) => void;
}

const RangeToggleButton = ({
  mode, currentMode, onChangeMode, ...buttonProps
}: RangeToggleButtonProps) => {
  const isActive = mode === currentMode;

  return (
    <Button
      {...buttonProps}
      onClick={() => onChangeMode(mode)}
      borderColor="magnetize.ui-4"
      variant="outline"
      fontFamily="body"
      fontWeight="bold"
      textTransform="uppercase"
      height={12}
      // Preventing default on mouse down stops the button taking focus from
      // clicking, so some semblence of keyboard navigability can be preserved
      // without triggering the outline effect on click
      onMouseDown={(e) => e.preventDefault()}
      sx={isActive ? {
        bg: 'magnetize.text-1',
        color: 'white',
        borderColor: 'magnetize.text-1',
        zIndex: 1, // raising z-index to ensure border covers the neighbouring button
        _hover: {
          bg: 'magnetize.text-1',
          color: 'white',
        },
      } : {}}
      borderRadius="sm"
    />
  );
};

const CALENDAR_RANGE_MODES = [
  { id: '3-day', name: '3 Day' },
  { id: 'week', name: 'Week' },
  { id: '2-week', name: '2 Week' },
  { id: 'month', name: 'Month' },
];

interface CalendarRangeToggleProps {
  mode: string;
  availableModes: string[];
  onChangeMode: (mode: string) => void;
}

const CalendarRangeToggle = ({
  availableModes, mode, onChangeMode, ...boxProps
}: CalendarRangeToggleProps & BoxProps) => (
  <ButtonGroup isAttached size="sm" {...boxProps}>
    {CALENDAR_RANGE_MODES
      .filter((m) => !availableModes || availableModes.includes(m.id))
      .map(({ id, name }, index) => (
        <RangeToggleButton
          key={id}
          onChangeMode={onChangeMode}
          mode={id}
          currentMode={mode}
          ml={index > 0 ? '-px' : undefined}
        >
          {name}
        </RangeToggleButton>
      ))}
  </ButtonGroup>
);

export default CalendarRangeToggle;
