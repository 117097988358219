import React from 'react';
import { DeactivatePowerUpModal } from '~/pages/TenantSettings/PowerUpsTab/DeactivatePowerUpModal';
import { PowerUpId } from '~/types/subscription';

export const DeactivateLiveGeoModal = ({
  onCancel, onDeactivated,
}: {
  onCancel: () => void,
  onDeactivated: () => void
}) => (
  <DeactivatePowerUpModal
    onCancel={onCancel}
    onDeactivated={onDeactivated}
    powerUpId={PowerUpId.liveGeo}
    powerUpName="Live Geo"
    deactivateMeansReasons={[
      'The features of this power up will no longer be available for your organisation, until it is reactivated.',
      'Charges for the days Live Geo was active this month will be added to the next invoice',
    ]}
  />
);
