import { createReducer } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';
import { XeroActions } from '~/redux/xero/actions';
import { Dictionary } from '~/types/helpers';
import {
  XeroAccount, XeroContact, XeroInvoice, XeroOrganization, XeroStatus,
} from '~/types/xero';

interface XeroIntegrationState {
  status: XeroStatus | undefined;
  organization: XeroOrganization;
  accounts: Dictionary<XeroAccount>;
  contacts: Dictionary<XeroContact>;
  invoices: Dictionary<XeroInvoice>;
  isConnecting: boolean;
}

const xeroReducer = createReducer<XeroIntegrationState>({
  status: undefined,
  accounts: {},
  contacts: {},
  invoices: {},
  organization: undefined,
  isConnecting: false,
}, (builder) => {
  builder.addCase(XeroActions.statusUpdated, (state, action) => {
    const { status } = action.payload;
    return ({
      ...state,
      status,
      organization: status?.xeroTenantId
        && status?.organizations.find((o) => o.organisationID === status?.xeroTenantId),
    });
  });

  builder.addCase(XeroActions.accountsUpdated, (state, action) => ({
    ...state,
    accounts: {
      ...state.accounts,
      ...keyBy(action.payload.accounts, (a) => a.accountID),
    },
  }));

  builder.addCase(XeroActions.contactsUpdated, (state, action) => ({
    ...state,
    contacts: {
      ...state.contacts,
      ...keyBy(action.payload.contacts, (c) => c.contactID),
    },
  }));

  builder.addCase(XeroActions.invoicesUpdated, (state, action) => ({
    ...state,
    invoices: {
      ...state.invoices,
      ...keyBy(action.payload.invoices, (i) => i.invoiceID),
    },
  }));

  builder.addCase(XeroActions.connect, (state) => ({
    ...state,
    isConnecting: true,
  }));

  builder.addCase(XeroActions.finishedConnecting, (state) => ({
    ...state,
    isConnecting: false,
  }));

  builder.addCase(XeroActions.disconnect, (state) => ({
    ...state,
    status: state.status ? {
      ...state.status,
      status: 'disconnected',
    } : undefined,
  }));
});

export default xeroReducer;
