import {
  Box, Button,
  Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverHeader,
  PopoverTrigger, Portal, SkeletonText,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import { triggerJobFetch } from '~/redux/jobs/actions';
import { ScheduleEventActions } from '~/redux/schedule/actions';
import JobContactAddressSummary from '~/components/JobContactAddressSummary';
import { selectJobById } from '~/redux/jobs/selectors';
import { selectScheduleEvent } from '~/redux/schedule/selectors';
import { selectDoesNeedRefetch } from '~/redux/fetch/selectors';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import eventStopper from '~/utils/eventStopper';
import { formatJobName } from '~/helpers/job';

interface ScheduleEventPopoverProps {
  eventId: string;
  children?: React.ReactNode;
  disablePopup?: boolean;
  containerRef?: React.RefObject<HTMLElement>;
}

const ScheduleEventPopover = ({
  eventId, children, disablePopup, containerRef,
}: ScheduleEventPopoverProps) => {
  const popoverFocusRef = useRef<HTMLDivElement>();
  const event = useAppSelector((state) => selectScheduleEvent(state, eventId));
  const job = useAppSelector((state) => selectJobById(state, event?.jobId));
  const needsRefetch = useAppSelector((state) => (
    event?.jobId && selectDoesNeedRefetch(state, event?.jobId)));
  const dispatch = useAppDispatch();

  const deleteEvent = () => {
    dispatch(ScheduleEventActions.deleteEvent({ eventId }));
  };

  const fetchJobWhenPopupOpens = () => {
    if (needsRefetch) {
      dispatch(triggerJobFetch({ jobId: event.jobId }));
    }
  };

  return (
    <Popover
      initialFocusRef={popoverFocusRef}
      trigger="hover"
      isOpen={disablePopup ? false : undefined}
      openDelay={500}
      placement="bottom-start"
      closeOnBlur
      closeOnEsc
      onOpen={fetchJobWhenPopupOpens}
      isLazy
    >
      <PopoverTrigger>
        <Box>{children}</Box>
      </PopoverTrigger>
      <Portal containerRef={containerRef} appendToParentPortal>
        <PopoverContent boxShadow="lg" {...eventStopper()}>
          <PopoverArrow />
          <PopoverHeader>
            {job
              ? formatJobName(job)
              : <SkeletonText noOfLines={1} />}
          </PopoverHeader>
          <PopoverBody ref={popoverFocusRef}>
            <JobContactAddressSummary jobId={event.jobId} />
            <Button variant="ghost" onClick={deleteEvent}>Delete</Button>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default ScheduleEventPopover;
