import {
  CloseButton,
  Flex, Text,
} from '@chakra-ui/react';
import { differenceInDays, differenceInHours } from 'date-fns';
import { first, split } from 'lodash';
import React from 'react';
import GreenButton from '~/components/GreenButton';
import { selectCurrentUser } from '~/redux/currentUser/selectors';
import PrefsActions from '~/redux/prefs/actions';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import { Tenant } from '~/types/tenant';

// Number of hours the banner will remain hidden for after being dismissed
const BANNER_COOL_DOWN_IN_HOURS = 6;

const TrialBanner = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => selectCurrentUser(state));
  const isOwner = currentUser.staff?.id
   && currentUser.staff?.id === currentUser.tenant?.ownerStaffId;
  const bannerDismissedAt = useAppSelector((s) => s.prefs.trialBannerDismissedAt);
  const bannerOnCoolDown = !bannerDismissedAt || differenceInHours(
    new Date(), bannerDismissedAt,
  ) > BANNER_COOL_DOWN_IN_HOURS;

  const tenant : Tenant = currentUser?.tenant;
  const hasActiveTrial = tenant?.status === 'trial' || tenant?.status === 'welcome';

  if (!isOwner
      || !tenant?.planExpiry
      || !tenant?.trialStarted
      || !hasActiveTrial
      || !bannerOnCoolDown) {
    return <></>;
  }

  const daysSinceTrialStarted = differenceInDays(new Date(), tenant.trialStarted);
  const daysUntilExpiry = differenceInDays(tenant.planExpiry, new Date());

  let message = '';
  if (daysSinceTrialStarted < 2) {
    const trialLength = differenceInDays(tenant.planExpiry, tenant.trialStarted);
    message = `Your ${trialLength} day free trial just started. Nice one!`;
  } else {
    const firstName = first(split(currentUser.staff?.name ?? '', ' '));
    message = `Hey ${firstName}, heads up your free trial expires in ${daysUntilExpiry} day${daysUntilExpiry !== 1 ? 's' : ''}`;
  }

  return (
    <Flex
      position="fixed"
      bottom={0}
      left={0}
      right={0}
      height="100px"
      bgColor="magnetize.text-2"
      color="white"
      alignItems="center"
      justifyContent="center"
    >
      <Text size="lg" ml={8}>
        {message}
      </Text>
      <GreenButton
        to="/settings/plans?showPlanModal=true"
        ml={8}
      >
        See Plans
      </GreenButton>
      <CloseButton
        opacity={0.7}
        size="sm"
        position="absolute"
        right="5"
        onClick={() => {
          dispatch(PrefsActions.dismissTrialBanner());
        }}
      />
    </Flex>
  );
};

export default TrialBanner;
