import {
  enAU, enCA, enNZ, enUS,
} from 'date-fns/locale';
import { useMemo } from 'react';
import { formatInTimeZone } from 'date-fns-tz';

// TZs suck, and shortcodes for TZ are different for each locale. IDK why but yeah... TZs suck.
// Example with en-NZ the tz shortcode for Australia/Perth is AWST but in en-US its GMT+8
export const useFormatInTimezone = () => {
  const timezone = useMemo(() => Intl.DateTimeFormat().resolvedOptions().timeZone, []);
  const settings = useMemo(() => {
    const locales = {
      'en-US': enUS, 'en-NZ': enNZ, 'en-AU': enAU, 'en-CA': enCA,
    };
    return locales[navigator.language] ? { locale: locales[navigator.language] } : {};
  }, []);

  return (date, format, tz) => formatInTimeZone(
    date,
    format,
    tz || timezone,
    settings,
  );
};
