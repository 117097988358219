import { createAction, nanoid } from '@reduxjs/toolkit';
import { put, take } from 'redux-saga/effects';

export interface QueuedWorkAction {
  id: string;
}

export const waitInQueue = createAction<QueuedWorkAction>('queue/wait');
export const beginQueuedWork = createAction<QueuedWorkAction>('queue/begin');
export const queuedWorkComplete = createAction<QueuedWorkAction>('queue/complete');

/**
 * A Saga fragment to queue up a work item
 * @param id
 */
export function* queueWork(worker: () => any) {
  const id = nanoid();
  yield put(waitInQueue({ id }));
  yield take((a) => beginQueuedWork.match(a) && a.payload.id === id);
  try {
    yield worker();
  } finally {
    yield put(queuedWorkComplete({ id }));
  }
}
