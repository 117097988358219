import {
  all, call, put, select, takeEvery,
} from 'redux-saga/effects';
import { putEquipment } from '~/api/equipmentApi';
import { EquipmentActions } from '~/redux/equipment/actions';
import { selectAllEquipment, selectEquipmentById } from '~/redux/equipment/selectors';
import { showErrorToast, showToast } from '~/toast';
import { Equipment } from '~/types/equipment';
import fetchJson from '~/utils/fetchJson';
import { performFetchSaga } from '~/utils/performFetchSaga';
import { takeEveryTracked } from '~/utils/performTrackedSaga';

const watchFetch = takeEvery(
  EquipmentActions.fetch,
  function* handle() {
    yield performFetchSaga({
      key: 'equipment',
      staleTime: 30000,
      * saga() {
        const equipment: Equipment[] = yield call(() => fetchJson('/api/equipment'));
        yield put(EquipmentActions.updated({ equipment }));
      },
    });
  },
);

const watchSave = takeEveryTracked(
  EquipmentActions.save,
  function* handle(action) {
    const { equipment } = action.payload.data;
    try {
      const updated: Equipment = yield call(() => putEquipment(equipment));
      yield put(EquipmentActions.updated({ equipment: [updated] }));
      showToast({
        status: 'success',
        title: 'Equipment saved',
      });
    } catch (error) {
      showErrorToast(error);
    }
  },
);

const watchArchive = takeEvery(
  EquipmentActions.archive,
  function* handle(action) {
    const { equipmentId } = action.payload;
    const allEquipment: Equipment[] = yield select(selectAllEquipment);
    const existing: Equipment = yield select((s) => selectEquipmentById(s, equipmentId));
    if (!existing) {
      return;
    }

    try {
      const deleted = {
        ...existing,
        isDeleted: true,
      };
      yield put(EquipmentActions.updated({ equipment: [deleted] }));
      const result: Equipment = yield call(() => fetchJson(`/api/equipment/${equipmentId}`, {
        method: 'PUT',
        body: deleted,
      }));
      yield put(EquipmentActions.updated({ equipment: [result] }));
    } catch (e) {
      yield put(EquipmentActions.updated({ equipment: allEquipment }));
      showErrorToast(e, {
        title: 'Could not delete equipment',
      });
    }
  },
);

export default function* equipmentSaga() {
  yield all([
    watchFetch,
    watchSave,
    watchArchive,
  ]);
}
