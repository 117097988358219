import {
  Button,
  Menu, MenuButton, MenuItem, MenuList,
} from '@chakra-ui/react';
import React from 'react';
import { DownloadIcon } from '@chakra-ui/icons';
import useTrackedAction from '~/hooks/useTrackedAction';
import { CustomerActions } from '~/redux/customers/actions';

const ExportCustomersMenu = () => {
  const { trigger, isLoading } = useTrackedAction({
    trigger: (format: 'csv' | 'xlsx') => CustomerActions.exportCustomers({ format }),
  });

  return (
    <Menu placement="bottom-end">
      <MenuButton
        leftIcon={<DownloadIcon />}
        as={Button}
        variant="ghost"
      >
        {isLoading ? 'Exporting...' : 'Export'}

      </MenuButton>
      <MenuList>
        <MenuItem onClick={() => trigger('xlsx')}>As Excel sheet</MenuItem>
        <MenuItem onClick={() => trigger('csv')}>As CSV</MenuItem>
      </MenuList>
    </Menu>
  );
};

export default ExportCustomersMenu;
