import {
  Box, Flex, useCallbackRef,
} from '@chakra-ui/react';
import AwsS3 from '@uppy/aws-s3';
import Uppy from '@uppy/core';
import '@uppy/core/dist/style.css';
import DropTarget from '@uppy/drop-target';
import '@uppy/drop-target/dist/style.css';
import { FileInput, StatusBar } from '@uppy/react';
import '@uppy/status-bar/dist/style.css';
import { uniqueId } from 'lodash';
import React, {
  forwardRef,
  ReactNode,
  useEffect, useImperativeHandle, useMemo, useState,
} from 'react';
import { showToast } from '~/toast';
import UploadedFile from '~/types/uploaded-file';
import fetchJson from '~/utils/fetchJson';
import './index.css';

interface FileUploadProps {
  singleImageOnly?: boolean;
  // To avoid drop zone conflicts, make sure that this is
  // only enabled when the page contains just ONE file upload
  fullPageDropZone?: boolean;
  onFileUploaded: (file: UploadedFile) => void;
  children: ReactNode;
  showBorder?: boolean;
}

export interface FileUploadRef {
  reset: () => void
}

const absolutePath = (href) => {
  const link = document.createElement('a');
  link.href = href;
  return link.href;
};

const FileUpload = (({
  singleImageOnly = false,
  fullPageDropZone = false,
  onFileUploaded,
  showBorder = true,
  children,
} : FileUploadProps, ref : React.Ref<FileUploadRef>) => {
  const [fileInputContainerId] = useState(uniqueId('file-input-container-'));
  const [dropZoneId] = useState(uniqueId('drop-zone-'));

  const wrappedOnFileUploaded = useCallbackRef((f: UploadedFile) => onFileUploaded(f));

  const uppy = useMemo(() => {
    const uploadedFiles = [];
    const u = Uppy({
      autoProceed: true,
      restrictions: singleImageOnly ? {
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        allowedFileTypes: ['image/*'],
      } : {},
    })
      .use(AwsS3, {
        limit: 0,
        getUploadParameters: async (file) => {
          const data : any = await fetchJson('/api/files/make-file-post-url', {
            method: 'POST',
            body: {
              fileExtension: file.extension,
            },
          });

          uploadedFiles.push({
            s3Key: data.signedUrl.fields.key,
            contentType: file.type,
            url: absolutePath(`/user-files/${data.signedUrl.fields.key}`),
            fileName: file.name,
          });

          return { method: 'POST', ...data.signedUrl };
        },
      });

    u.on('upload-success', () => {
      wrappedOnFileUploaded(uploadedFiles.pop());
    });

    u.on('upload-error', (file) => {
      showToast({
        title: 'File Upload Error',
        description: `Failed to upload ${file.name} please try again`,
        status: 'error',
      });
    });

    u.on('restriction-failed', (file, error) => {
      showToast({
        title: 'File Upload Error',
        description: error.message,
        status: 'error',
      });
    });

    return u;
  }, []);

  useImperativeHandle(ref, () => ({
    reset() {
      uppy.reset();
    },
  }));

  useEffect(() => {
    const interval = setInterval(() => {
      const target = fullPageDropZone ? document.body : document.querySelector(`#${dropZoneId}`);
      if (target && uppy) {
        uppy.use(DropTarget, {
          target,
        });
        clearInterval(interval);
      }
    }, 250);

    return () => {
      clearInterval(interval);
      uppy.close();
    };
  }, []);

  return (
    <Flex
      direction="column"
    >
      <Flex
        cursor="pointer"
        borderWidth={showBorder ? 1 : 0}
        borderColor="magnetize.ui-4"
        borderStyle="dashed"
        _hover={{
          borderColor: 'gray.300',
        }}
        flex="1"
        id={dropZoneId}
        onClick={() => {
          (document
            .querySelector(`#${fileInputContainerId} .uppy-FileInput-input`) as any)
            .click();
        }}
      >
        {children}
      </Flex>
      <StatusBar uppy={uppy} hideCancelButton />
      <Box
        display="none"
        id={fileInputContainerId}
      >
        <FileInput uppy={uppy} />
      </Box>
    </Flex>
  );
});

export default forwardRef(FileUpload);
