import { Flex, Text } from '@chakra-ui/react';
import React, { useRef } from 'react';
import FileUpload, { FileUploadRef } from '~/components/FileUpload';
import Thumbnail from '~/components/Thumbnail';
import UploadedFile from '~/types/uploaded-file';

export default (
  {
    onFileUploaded, onFileRemoved, size = 130, profilePicture,
  } : {
    profilePicture?: UploadedFile;
    onFileUploaded: (file: UploadedFile) => void;
    onFileRemoved: (file: UploadedFile) => void;
    size: number;
  },
) => {
  const fileUploadRef = useRef<FileUploadRef>();

  return (
    <FileUpload
      ref={fileUploadRef}
      singleImageOnly
      onFileUploaded={onFileUploaded}
      showBorder={false}
    >
      {profilePicture ? (
        <Flex borderWidth={1} borderRadius="50%" borderStyle="dashed">
          <Thumbnail
            isCircle
            imageWidth={size}
            file={profilePicture}
            onRemove={(file) => {
              fileUploadRef.current.reset();
              onFileRemoved(file);
            }}
          />
        </Flex>
      ) : (
        <Flex
          height={size}
          width={size}
          borderWidth={1}
          borderColor="magnetize.ui-4"
          borderStyle="dashed"
          _hover={{
            borderColor: 'gray.300',
          }}
          borderRadius="50%"
          padding={5}
          alignItems="center"
          justifyContent="center"
        >
          <Text color="magnetize.text-4" textAlign="center">
            Upload a
            <br />
            profile photo
          </Text>
        </Flex>
      )}
    </FileUpload>
  );
};
