import { createSelector } from '@reduxjs/toolkit';
import { filter, sortBy } from 'lodash';
import { RootState } from '~/redux/store';

export const selectAllPrices = createSelector(
  (state: RootState) => state.prices.entities || {},
  (prices) => sortBy(prices, (p) => p.name.toLowerCase()),
);

export const selectPrices = createSelector(
  selectAllPrices,
  (prices) => prices.filter((p) => !p.isDeleted),
);

export const selectAvailablePrices = createSelector(
  selectPrices,
  (prices) => filter(prices, (p) => p.isAvailable),
);

export const selectPriceById = (state: RootState, id: string) => state.prices.entities[id];
