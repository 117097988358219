import { Center, Grid, useDisclosure } from '@chakra-ui/react';
import React, { useState } from 'react';
import ImagePreviewModal from '~/components/ImagePreviewModal';
import Thumbnail from '~/components/Thumbnail';
import UploadedFile from '~/types/uploaded-file';

interface ImageGalleryProps {
  files: UploadedFile[];
  selectedImage?: UploadedFile;
  imageWidth?: number;
  onFileClicked?: (file: UploadedFile) => void;
  onFileRemoved?: (file: UploadedFile) => void;
}

const ImageGallery = ({
  files, imageWidth = 120, onFileClicked, selectedImage, onFileRemoved,
}: ImageGalleryProps) => {
  const imageWidthPx = `${imageWidth}px`;

  const [initialImage, setInitialImage] = useState<UploadedFile>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const openImageModal = (si: UploadedFile) => {
    setInitialImage(si);
    onOpen();
  };

  return (
    <>
      <Grid
        gridTemplateColumns={`repeat(auto-fill, ${imageWidthPx})`}
        gap={2}
        justifyContent="start"
        userSelect="none"
      >
        {files.map((file) => (
          <Center
            key={file.s3Key}
            width={imageWidthPx}
            height={imageWidthPx}
          >
            <Thumbnail
              file={file}
              imageWidth={imageWidth}
              imageHeight={imageWidth}
              isSelected={file === selectedImage}
              onClick={onFileClicked || openImageModal}
              onRemove={onFileRemoved}
            />
          </Center>
        ))}
      </Grid>
      {isOpen
        && <ImagePreviewModal images={files} initialImage={initialImage} onClose={onClose} />}

    </>
  );
};

export default ImageGallery;
