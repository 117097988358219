import {
  Button, Spinner,
} from '@chakra-ui/react';
import React from 'react';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import Routes from '~/pages/routes';
import { addQuoteToJob } from '~/redux/jobs/actions';
import { selectJobWithEdits } from '~/redux/jobs/selectors';
import { QuoteActions } from '~/redux/quote/actions';
import { useAppSelector } from '~/redux/store';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import { selectQuotesByJobId } from '~/redux/quote/selectors';
import useTrackedAction from '~/hooks/useTrackedAction';

const JobPrimaryActionButton = ({ jobId }: { jobId: string }) => {
  const history = useHistory();
  const job = useAppSelector((state) => selectJobWithEdits(state, jobId));

  const { data: quotes } = useTrackedFetch({
    key: `job-quotes-${jobId}`,
    trigger: () => QuoteActions.fetchForJob({ jobId }),
    selector: (state) => selectQuotesByJobId(state, jobId),
  });

  const { trigger: createQuote, isLoading: creatingQuote } = useTrackedAction({
    trigger: () => addQuoteToJob({
      jobId,
    }),
    onSuccess: () => {
      history.push(Routes.jobQuote({ jobId }));
    },

  });

  switch (job.status) {
    case 'quote':
      if (quotes.length === 0) {
        return (
          <Button
            size="lg"
            onClick={createQuote}
            disabled={creatingQuote}
          >
            { creatingQuote ? (
              <>
                <Spinner mr={5} />
                CREATING QUOTE
              </>
            ) : <>CREATE QUOTE</>}
          </Button>
        );
      }
      return (
        <Button
          size="lg"
          onClick={() => {
            history.push(Routes.jobQuote({ jobId }));
          }}
        >
          CONTINUE QUOTE
        </Button>
      );
    case 'quoted':
      if (quotes.length === 1) {
        // Single quote, take user directly to quote preview so they can review and accept it.
        return (
          <Button
            size="lg"
            as={RouterLink}
            to={Routes.jobQuotePreview({ jobId, quoteId: quotes[0].id })}
          >
            VIEW QUOTE
          </Button>
        );
      }
      // More than 1 quote, take user to list of quotes so user approves the correct quote.
      return (
        <Button
          size="lg"
          onClick={() => {
            history.push(Routes.jobQuote({ jobId }));
          }}
        >
          VIEW QUOTES
        </Button>
      );

    case 'schedule':
      return (
        <Button
          size="lg"
          onClick={() => {
            history.push(Routes.jobSchedule({ jobId }));
          }}
        >
          SCHEDULE NOW
        </Button>
      );

    case 'scheduled':
    case 'inProgress':
      return (
        <Button
          size="lg"
          onClick={() => {
            history.push(Routes.jobSchedule({ jobId }));
          }}
        >
          VIEW SCHEDULE
        </Button>
      );
    case 'complete':
      return (
        <Button
          size="lg"
          onClick={() => {
            history.push(Routes.jobInvoice({ jobId }));
          }}
        >
          INVOICE
        </Button>
      );

    default:
      return null;
  }
};

export default JobPrimaryActionButton;
