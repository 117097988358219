import {
  Box, Grid, GridItem, useDisclosure,
} from '@chakra-ui/react';
import { isNumber } from 'lodash';
import React from 'react';
import CardTitle from '~/components/CardTitle';
import LineItemsEditor from '~/components/LineItemsEditor';
import LineItemTotals from '~/components/LineItemTotals';
import LinkButton from '~/components/LinkButton';
import useTrackedAction from '~/hooks/useTrackedAction';
import ReconciliationManager from '~/pages/Job/components/invoices/ReconciliationManager';
import PriceBookBrowseModal from '~/pages/Job/components/PriceBook/PriceBookBrowseModal';
import PriceBookSearch from '~/pages/Job/components/PriceBook/PriceBookSearch';
import { InvoiceActions } from '~/redux/invoices/actions';
import { selectInvoiceWithEdits } from '~/redux/invoices/selectors';
import { useAppSelector } from '~/redux/store';
import { Invoice } from '~/types/invoice';
import { addLineItems, sortLineItems } from '~/utils/lineItemHelpers';

interface InvoiceEditorProps {
  jobId: string;
  invoiceId: string;
}

const InvoiceEditor = ({ invoiceId, jobId }: InvoiceEditorProps) => {
  const invoiceFromRedux = useAppSelector((s) => selectInvoiceWithEdits(s, invoiceId));
  const { onOpen, onClose, isOpen } = useDisclosure();

  const { trigger: onSave, isLoading: isSaving } = useTrackedAction({
    trigger: (invoice: Invoice) => InvoiceActions.saveInvoice({
      jobId,
      invoiceId,
      invoice,
    }),
  });

  const addLineItemsToInvoice = (lineItems) => onSave({
    ...invoiceFromRedux,
    lineItems: {
      ...invoiceFromRedux.lineItems,
      ...addLineItems(
        'start',
        sortLineItems(invoiceFromRedux.lineItems),
        lineItems.map((item) => ({ ...item })),
      ),
    },
  });

  return (
    <>
      {!isNumber(invoiceFromRedux.proportionOfQuote) && (
      <ReconciliationManager
        isDisabled={isSaving}
        jobId={jobId}
        invoice={invoiceFromRedux}
        onChange={onSave}
      />
      )}
      <Grid templateColumns="repeat(24, 1fr)" gap={6}>
        <GridItem colSpan={24}>
          <CardTitle pt={8} size="sm" mb={4}>Invoice Items</CardTitle>
          <Box flex="1" position="relative" mb={4}>
            <LinkButton
              zIndex="1"
              right="10px"
              top="9.5px"
              textDecoration="none"
              isGreen
              position="absolute"
              onClick={onOpen}
            >
              Browse all
            </LinkButton>
            <PriceBookSearch
              showBundles
              onSelected={(lineItem) => addLineItemsToInvoice([lineItem])}
            />
          </Box>
          <LineItemsEditor
            lineItems={invoiceFromRedux.lineItems}
            onChange={(lineItems) => onSave({ ...invoiceFromRedux, lineItems })}
          />
        </GridItem>
        <GridItem colStart={15} colSpan={10} pt={4} fontSize="lg">
          <LineItemTotals
            lineItems={invoiceFromRedux?.lineItems}
            taxRate={invoiceFromRedux.taxRate}
            taxRateName={invoiceFromRedux.taxRateName}
            onTaxRateChange={({ taxRateName, taxRate }) => {
              onSave({ ...invoiceFromRedux, taxRate, taxRateName });
            }}
          />
        </GridItem>
      </Grid>
      {isOpen && (
        <>
          <PriceBookBrowseModal
            titleText="Add to invoice"
            addButtonText="Add to invoice"
            onAdd={addLineItemsToInvoice}
            onClose={onClose}
          />
        </>
      )}
    </>
  );
};

export default InvoiceEditor;
