import { forwardRef, Textarea, TextareaProps } from '@chakra-ui/react';
import React from 'react';
import ReactTextareaAutosize from 'react-textarea-autosize';

interface AutoSizingTextareaProps {
  minLines?: number;
  maxLines?: number;
}

const AutoSizingTextarea = forwardRef<TextareaProps, 'textarea'>(({
  minLines = 3, maxLines, ...textareaProps
}: TextareaProps & AutoSizingTextareaProps,
ref) => (
  <Textarea
    as={ReactTextareaAutosize}
    ref={ref}
    minHeight="unset"
    minRows={minLines}
    maxRows={maxLines}
    resize="none"
    // We want to exclude size from animating as this screws up the
    // autosizing logic, causing the content to jump around a bit.
    // Chakra defaults to transitioning everything, but we'll support
    // the likely useful properties while omitting size.
    transitionDuration="0.2s"
    transitionProperty="border-color, background, color, opacity"
    {...textareaProps}
  />
));

export default AutoSizingTextarea;
