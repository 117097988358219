import { CheckIcon } from '@chakra-ui/icons';
import {
  Box, Center, Icon, Tooltip, Wrap,
} from '@chakra-ui/react';
import { uniq, without } from 'lodash';
import React from 'react';
import { JobStatusId, JobStatusInfo } from '~/types/job';

interface StatusDonutProps {
  color?: string;
  tooltip?: string;
  isChecked?: boolean;
  onClick?: () => void;
}

const StatusDonut = ({
  color = 'magnetize.brand-4', tooltip = '', isChecked, onClick,
}: StatusDonutProps) => (
  <Tooltip
    openDelay={250}
    label={tooltip}
    hasArrow
    placement="top"
    gutter={15}
    closeOnClick={false}
  >
    <Box
      cursor="pointer"
      boxSize="8"
      borderWidth="6px"
      borderColor={color}
      transform={isChecked ? 'scale(1.05)' : undefined}
      bg={isChecked ? color : undefined}
      transition="all 0.2s"
      borderRadius="full"
      onClick={onClick}
      position="relative"
    >
      <Center height="100%">
        <Icon
          as={CheckIcon}
          color="white"
          boxSize="100%"
          visibility={isChecked ? 'visible' : 'hidden'}
          display="block"
        />
      </Center>
    </Box>
  </Tooltip>
);

interface JobStatusDonutsProps {
  selected?: JobStatusId[];
  onSelectionChange?: (selected: JobStatusId[]) => void;
}

const statusGroups: { name: string, color: string, statuses: JobStatusId[] }[] = [
  {
    name: 'Leads',
    color: JobStatusInfo.quoted.color,
    statuses: ['draft', 'quote', 'quoted'],
  },
  {
    name: 'On hold',
    color: JobStatusInfo.onHold.color,
    statuses: ['onHold'],
  },
  {
    name: 'Open jobs',
    color: JobStatusInfo.inProgress.color,
    statuses: ['schedule', 'scheduled', 'inProgress'],
  },
  {
    name: 'Complete',
    color: JobStatusInfo.complete.color,
    statuses: ['complete', 'closed', 'archived'],
  },
];

const JobStatusDonuts = ({
  selected, onSelectionChange,
}: JobStatusDonutsProps) => (
  <Wrap spacing="1" userSelect="none">
    {statusGroups.map((s) => {
      const isChecked = selected.some((vs) => s.statuses.includes(vs));
      return (
        <StatusDonut
          key={s.name}
          color={s.color}
          tooltip={s.name}
          isChecked={isChecked}
          onClick={() => {
            if (isChecked) {
              onSelectionChange(without(selected, ...s.statuses));
            } else {
              onSelectionChange(uniq([...selected, ...s.statuses]));
            }
          }}
        />
      );
    })}
  </Wrap>
);

export default JobStatusDonuts;
