import {
  Box, FormControl, FormLabel, Grid, HStack, Input,
  NumberInput, NumberInputField, Switch, Text, Textarea,
} from '@chakra-ui/react';
import { isFinite } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { TaxRate } from '~/types/taxRate';

interface TaxRateFormProps {
  value?: Partial<TaxRate>;
  onChange?: (price: Partial<TaxRate>) => void
}

const TaxRateForm = (
  {
    value = {},
    onChange = () => {},
  }
  : TaxRateFormProps,
) => {
  const [name, setName] = useState(value.name ?? '');
  const [description, setDescription] = useState(value.description ?? '');
  const [rate, setRate] = useState(value.rate ?? 0);
  const [rateText, setRateText] = useState(`${value.rate ?? 0}%`);
  const [isAvailable, setIsAvailable] = useState(value.isAvailable ?? true);
  const nameRef = useRef(null);

  useEffect(() => {
    onChange({
      ...(value || {}),
      name,
      description,
      rate,
      isAvailable,
    });
  }, [
    name,
    description,
    rate,
    isAvailable,
  ]);

  useEffect(() => {
    nameRef.current.focus();
  }, []);

  return (
    <Grid gap={6}>
      <HStack>
        <FormControl flex="2">
          <FormLabel>TAX RATE NAME</FormLabel>
          <Input
            ref={nameRef}
            placeholder="Enter the name for the rate"
            value={name}
            onChange={(e) => { setName(e.target.value); }}
          />
        </FormControl>
        <FormControl flex="1">
          <FormLabel>RATE</FormLabel>
          <NumberInput
            variant="outline"
            minW="100%"
            onChange={(rt, r) => {
              setRateText(`${rt} %`);
              // For some reason if an invalid value is entered e.g. '.' and the user tabs away
              // the value of the input will be set to - Number.MAX_SAFE_INTEGER
              if (isFinite(r) && r !== -Number.MAX_SAFE_INTEGER) {
                setRate(r);
              }
            }}
            value={rateText}
          >
            <NumberInputField />
          </NumberInput>
        </FormControl>
      </HStack>
      <FormControl>
        <FormLabel>Description</FormLabel>
        <Textarea
          resize="none"
          placeholder="Enter a description"
          value={description}
          onChange={(e) => { setDescription(e.target.value); }}
        />
      </FormControl>
      <Box>
        <FormControl display="flex" alignItems="center">
          <Switch
            name="is-required"
            size="lg"
            isChecked={isAvailable}
            onChange={(e) => setIsAvailable(e.target.checked)}
          />
          <Text
            ml={2}
            htmlFor="quote-required"
            cursor="pointer"
            onClick={() => setIsAvailable(!isAvailable)}
          >
            Available for quoting and invoicing
          </Text>
        </FormControl>
      </Box>
    </Grid>
  );
};

export default TaxRateForm;
