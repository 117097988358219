import { createAction } from '@reduxjs/toolkit';
import { DateRangeMode } from '~/components/Calendar/getDateRange';
import { JobStatusId } from '~/types/job';

const JobCalendarActions = {
  move: createAction<{ focusedDate: string }>('jobCalendar/move'),
  setRangeMode: createAction<{ rangeMode: DateRangeMode }>('jobCalendar/setRangeMode'),
  setFilterTags: createAction<{ tags: string[] | null }>('jobCalendar/setFilterTags'),
  setFilterStatuses: createAction<{ statuses: JobStatusId[] | null }>('jobCalendar/setFilterStatuses'),
  setShowResources: createAction<{ showResources: boolean }>('jobCalendar/setShowResources'),
};

export default JobCalendarActions;
