import {
  Accordion, Box, HStack, Icon, Text,
} from '@chakra-ui/react';
import { CheckCircleRounded } from '@material-ui/icons';
import {
  findIndex, isNumber, max, orderBy,
} from 'lodash';
import React, { useEffect } from 'react';
import { Location } from '~/components/Location';
import MagnetizeAccordionItem from '~/components/MagnetizeAccordionItem';
import CPTLogsRow from '~/pages/LiveGeo/components/CPTLogsRow';
import CPTZeroingFiguresRow from '~/pages/LiveGeo/components/CPTZeroingFiguresRow';
import DrillLogsRow from '~/pages/LiveGeo/components/DrillLogsRow';
import SoilLogsRow from '~/pages/LiveGeo/components/SoilLogsRow';
import SPTTestsRow from '~/pages/LiveGeo/components/SPTTestsRow';
import WaterLogsRow from '~/pages/LiveGeo/components/WaterLogsRow';
import { Equipment } from '~/types/equipment';
import { Borehole, CPT } from '~/types/geotechnical';
import { ReactComponent as DrillIcon } from '~/assets/live_geo_drill_icon_white.svg';
import { DrillingTechnique } from '~/types/drillingTechnique';
import { SoilType } from '~/types/soilType';
import { CasingType } from '~/types/casingType';
import { ClearanceTechnique } from '~/types/clearanceTechnique';
import { RefusalReason } from '~/types/refusalReason';
import { InstallLogsRow } from '~/pages/LiveGeo/components/InstallLogsRow';
import { SurfaceType } from '~/types/surfaceType';
import { BackfillType } from '~/types/backfillType';
import { BackfillLogsRow } from '~/pages/LiveGeo/components/BackfillLogsRow';
import { PiezoType } from '~/types/piezoType';

interface LiveGeoLogsTableProps {
  boreholes: Borehole[];
  cpts: CPT[];
  equipment: Partial<Equipment>[];
  selectedId?: string;
  coordinateSystem: string;
  drillingTechniques: { [key: string]: DrillingTechnique };
  casingTypes: { [key: string]: CasingType };
  clearanceTechniques: { [key: string]: ClearanceTechnique };
  refusalReasons: { [key: string]: RefusalReason };
  soilTypes: { [key: string]: SoilType };
  surfaceTypes: { [key: string]: SurfaceType };
  backfillTypes: { [key: string]: BackfillType };
  piezoTypes: { [key: string]: PiezoType };
  onLogSelected?: (logId: string, type?: 'drill'|'install') => void;
}

const LiveGeoLogsTable = ({
  boreholes,
  cpts,
  equipment,
  selectedId = null,
  coordinateSystem,
  drillingTechniques,
  casingTypes,
  clearanceTechniques,
  refusalReasons,
  surfaceTypes,
  soilTypes,
  backfillTypes,
  piezoTypes,
  onLogSelected = () => {},
} : LiveGeoLogsTableProps) => {
  const orderedBoreholes = orderBy(boreholes, (bh) => bh.name);
  const orderedCpts = orderBy(cpts, (bh) => bh.name);

  const allLogs = [...orderedBoreholes, ...orderedCpts];
  const defaultIndex = max([findIndex(allLogs, (log) => log.id === selectedId), 0]);

  useEffect(() => {
    if (allLogs && allLogs[defaultIndex]) {
      onLogSelected(allLogs[defaultIndex].id);
    }
  }, [defaultIndex]);

  return (
    <Accordion
      defaultIndex={[defaultIndex]}
      onChange={(index : number) => {
        const log = isNumber(index) ? allLogs[index] : null;
        if (log) {
          onLogSelected(log.id);
        }
      }}
    >
      {orderedBoreholes.map((bh) => (
        <MagnetizeAccordionItem
          removePadding
          key={bh.id}
          heading={(
            <HStack>
              {bh.endDepth ? (<Icon color="magnetize.brand-4" boxSize="2rem" as={CheckCircleRounded} mb="2.5px" />)
                : (
                  <Box backgroundColor="magnetize.support-warning" h="2rem" w="2rem" borderRadius="1rem">
                    <Icon pt={1} boxSize={6} display="block" margin="auto" as={DrillIcon} />
                  </Box>
                )}
              <Text pl={3} pr={2}>
                {bh.name}
              </Text>
              {bh.endDepth ? (
                <Text fontSize={12} textTransform="none">
                  {bh.endDepth.toFixed(1)}
                  m
                </Text>
              ) : (
                <Text fontSize={12} fontWeight="normal" textTransform="none" fontStyle="italic" color="magnetize.text-3">
                  coreing in progress...
                </Text>
              )}
            </HStack>
)}
          rightContent={(<Location coordinateSystem={coordinateSystem} location={bh.location} />)}
        >
          {!!bh.note
      && (
        <Text
          fontStyle="italic"
          color="magnetize.text-3"
          padding={3}
          borderBottomWidth={1}
          borderBottomColor="magnetize.ui-3"
        >
          {bh.note}
        </Text>
      )}

          <Accordion
            onChange={(index : number) => {
              onLogSelected(selectedId, index > 3 ? 'install' : 'drill');
            }}
            allowToggle
          >
            <DrillLogsRow
              borehole={bh}
              equipment={equipment}
              drillingTechniques={drillingTechniques}
              casingTypes={casingTypes}
              clearanceTechniques={clearanceTechniques}
            />
            <SPTTestsRow
              sptTests={bh.sptTests}
              pushTubes={bh.pushTubes}
            />
            <SoilLogsRow
              soilTypes={soilTypes}
              endDepth={bh.endDepth}
              soilLayers={bh.soilLayers}
            />
            <WaterLogsRow
              waterLevel={bh.waterLevel}
              waterLevelNote={bh.waterLevelNote}
            />
            <InstallLogsRow
              piezoLogs={bh.piezoLogs}
              incloEndDepth={bh.inclo?.endDepth}
              inclolNote={bh.inclo?.note}
              surfaceTypeId={bh.surfaceTypeId}
              surfaceTypeNote={bh.surfaceTypeNote}
              surfaceTypes={surfaceTypes}
              piezoTypes={piezoTypes}
            />
            <BackfillLogsRow
              backfillTypes={backfillTypes}
              endDepth={bh.endDepth}
              backfillLayers={bh.backfillLayers}
            />
          </Accordion>
          {!!bh.note && (
          <Text
            fontStyle="italic"
            color="magnetize.text-3"
            padding={3}
            borderBottomWidth={1}
            borderBottomColor="magnetize.ui-3"
          >
            {bh.endNote}
          </Text>
          )}
        </MagnetizeAccordionItem>
      ))}
      {orderedCpts.map((cpt) => (
        <MagnetizeAccordionItem
          removePadding
          key={cpt.id}
          heading={(
            <HStack>
              {cpt.endDepth ? (<Icon color="magnetize.brand-4" boxSize="2rem" as={CheckCircleRounded} mb="2.5px" />)
                : (
                  <Box backgroundColor="magnetize.support-warning" h="2rem" w="2rem" borderRadius="1rem">
                    <Icon pt={1} boxSize={6} display="block" margin="auto" as={DrillIcon} />
                  </Box>
                )}
              <Text pl={3} pr={2}>
                {cpt.name}
              </Text>
              {cpt.endDepth ? (
                <Text fontSize={12} textTransform="none">
                  {cpt.endDepth.toFixed(1)}
                  m
                </Text>
              ) : (
                <Text fontSize={12} fontWeight="normal" textTransform="none" fontStyle="italic" color="magnetize.text-3">
                  push in progress...
                </Text>
              )}
            </HStack>
          )}
          rightContent={(<Location coordinateSystem={coordinateSystem} location={cpt.location} />)}
        >

          <Accordion allowToggle>
            <CPTLogsRow
              clearanceTechniques={clearanceTechniques}
              refusalReasons={refusalReasons}
              cpt={cpt}
            />
            <CPTZeroingFiguresRow
              cpt={cpt}
            />
            <WaterLogsRow
              waterLevel={cpt.waterLevel}
            />
          </Accordion>
        </MagnetizeAccordionItem>
      ))}
    </Accordion>
  );
};

export default LiveGeoLogsTable;
