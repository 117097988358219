import {
  Box, FormControl, FormLabel, Grid, HStack, Input, VStack,
} from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';
import { CustomerWithContact } from '~/types/customer';
import AddressForm from '../AddressForm';

interface CustomerEditFormProps {
  customer: CustomerWithContact;
  onChange: (customer: CustomerWithContact) => void;
}

const CustomerEditForm = (
  {
    customer,
    onChange,
  }
  : CustomerEditFormProps,
) => {
  const nameInput = useRef(null);

  useEffect(() => {
    nameInput?.current?.focus();
  }, []);

  return (
    <Grid gap={6}>
      <FormControl isRequired>
        <FormLabel>Company Name</FormLabel>
        <Input
          ref={nameInput}
          value={customer.companyName ?? ''}
          onChange={(e) => {
            const companyName = e.target.value;
            onChange({
              ...customer,
              companyName,
            });
          }}
          autoComplete="off"
          placeholder="Whats the name of the customer?"
        />
      </FormControl>
      <Box>
        <FormControl isRequired>
          <FormLabel>Billing Contact</FormLabel>
          <VStack align="stretch" gap={6}>
            <Input
              value={customer.mainContact?.name ?? ''}
              onChange={(e) => {
                const name = e.target.value;
                onChange({
                  ...customer,
                  mainContact: {
                    ...customer.mainContact,
                    name,
                  },
                });
              }}
              autoComplete="off"
              placeholder="Full name"
            />
            <HStack align="stretch" gap={6}>
              <Input
                value={customer.mainContact?.email ?? ''}
                onChange={(e) => {
                  const email = e.target.value;
                  onChange({
                    ...customer,
                    mainContact: {
                      ...customer.mainContact,
                      email,
                    },
                  });
                }}
                autoComplete="off"
                placeholder="Email address"
                flex={2}
              />
              <Input
                value={customer.mainContact?.phone ?? ''}
                onChange={(e) => {
                  const phone = e.target.value;
                  onChange({
                    ...customer,
                    mainContact: {
                      ...customer.mainContact,
                      phone,
                    },
                  });
                }}
                autoComplete="off"
                placeholder="Phone number"
                flex={1}
              />
            </HStack>
          </VStack>
        </FormControl>
      </Box>
      <Box>
        <FormLabel>Company Address</FormLabel>
        <AddressForm
          value={customer.companyAddress}
          onChange={(companyAddress) => {
            onChange({
              ...customer,
              companyAddress,
            });
          }}
        />
      </Box>
    </Grid>
  );
};

export default CustomerEditForm;
