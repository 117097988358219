import {
  Button,
  Modal, ModalBody, ModalCloseButton,
  ModalContent, ModalFooter, ModalHeader, ModalOverlay,
} from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import { omit } from 'lodash';
import React, { useEffect, useState } from 'react';
import CustomerEditForm from '~/components/CustomerForm';
import useTrackedAction from '~/hooks/useTrackedAction';
import { CustomerContactActions } from '~/redux/customerContacts/actions';
import { CustomerActions } from '~/redux/customers/actions';
import { CustomerWithContact } from '~/types/customer';

const CustomerEditModal = (
  {
    customer, isNewCustomer, onClose = () => {}, onSave = () => {},
  }: {
    customer: CustomerWithContact;
    isNewCustomer: boolean;
    onClose?: () => void;
    onSave?: (customer: CustomerWithContact) => void;
  },
) => {
  const [editedCustomer, setEditedCustomer] = useState<CustomerWithContact>(customer);

  // Because we're migrating to customer contacts not all customers will have one
  // this use effect ensures we create and set a contact against the customer
  // if one does not exist
  useEffect(() => {
    const customerWithContact = customer;
    if (!customerWithContact?.mainContactId) {
      const mainContactId = nanoid();
      customerWithContact.mainContactId = mainContactId;
      customerWithContact.mainContact = {
        id: mainContactId,
        customerId: customer.id,
        name: '',
      };
    }
    setEditedCustomer(customerWithContact);
  }, []);

  const { trigger: onContactSaved, isLoading: isSavingCustomer } = useTrackedAction({
    trigger: () => CustomerActions.save({ customer: omit(editedCustomer, 'mainContact') }),
    onSuccess: () => {
      onSave(editedCustomer);
      onClose();
    },
  });

  const { trigger: onSaveClicked, isLoading: isSavingContact } = useTrackedAction({
    trigger: () => CustomerContactActions.save(
      { contact: editedCustomer.mainContact, hideSuccessToast: true },
    ),
    onSuccess: () => {
      onContactSaved();
    },
  });

  const isSaving = isSavingContact || isSavingCustomer;
  const isValid = editedCustomer.companyName && editedCustomer?.mainContact?.name;

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen
      onClose={onClose}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {isNewCustomer ? 'Add new' : 'Edit'}
          {' '}
          customer
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <CustomerEditForm
            customer={editedCustomer}
            onChange={(c) => {
              setEditedCustomer(c);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            size="lg"
            colorScheme="brandDark"
            isLoading={isSaving}
            disabled={!isValid || isSaving}
            onClick={onSaveClicked}
          >
            Save
          </Button>
          <Button
            size="lg"
            variant="ghost"
            onClick={onClose}
            disabled={false}
          >
            cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CustomerEditModal;
