import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';

export const SecondaryButton = (
  {
    children, ...props
  }:ButtonProps,
) => (
  <Button
    size="lg"
    height="45px"
    px="25px"
    bg="transparent"
    color="magnetize.text-1"
    borderColor="magnetize.ui-4"
    borderWidth="1.5px"
    textTransform="none"
    fontFamily="body"
    fontWeight="bold"
    _hover={{
      bg: 'magnetize.ui-1',
    }}
    {...props}
  >
    {children}
  </Button>
);
