import {
  Icon, Table, Text, Tbody, Tr, VStack, Td, Skeleton, MenuItem,
} from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
import React, { useState } from 'react';
import { ReactComponent as PiezoIcon } from '~/assets/piezo.svg';
import ConfirmationDialog from '~/components/ConfirmationDialog';
import { ExpandableCountSection } from '~/components/ExpandableCountSection';
import { KebabOptionsMenu } from '~/components/KebabOptionsMenu';
import LinkButton from '~/components/LinkButton';
import { useModalManager } from '~/components/ModalManager';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import { PiezoTypeEditModal } from './PiezoTypeEditModal';
import { triggerPiezoTypesFetch, deletePiezoType } from '~/redux/piezoTypes/actions';
import { getActivePiezoTypes } from '~/redux/piezoTypes/selectors';
import { useAppDispatch } from '~/redux/store';
import { PiezoType } from '~/types/piezoType';
import eventStopper from '~/utils/eventStopper';

export const PiezoTypesList = () => {
  const dispatch = useAppDispatch();
  const modalManager = useModalManager();
  const { data: piezoTypes, isLoading } = useTrackedFetch({
    key: 'piezo-types',
    trigger: () => triggerPiezoTypesFetch({ force: false }),
    selector: getActivePiezoTypes,
  });

  const [
    selectedPiezoType,
    setSelectedPiezoType,
  ] = useState<Partial<PiezoType>>(null);
  const [piezoTypeModalTitle, setPiezoTypeModalTitle] = useState<string>();

  const addTechnique = () => {
    setSelectedPiezoType({
      id: nanoid(),
    });
    setPiezoTypeModalTitle('Add piezo type');
  };

  const editTechnique = (piezoType: PiezoType) => {
    setSelectedPiezoType(piezoType);
    setPiezoTypeModalTitle('Edit piezo type');
  };

  const deleteTechnique = (piezoTypeId) => {
    dispatch(deletePiezoType({
      id: piezoTypeId,
    }));
  };

  return (
    <VStack
      align="stretch"
    >
      <ExpandableCountSection
        title="Piezo types"
        icon={<Icon boxSize={8} as={PiezoIcon} />}
        count={piezoTypes?.length || 0}
      >
        <Table size="md">
          <Tbody>
            {isLoading ? (
              <Tr>
                <Td><Skeleton height={8} /></Td>
                <Td><Skeleton height={8} /></Td>
                <Td />
              </Tr>
            )
              : (orderBy(piezoTypes, (t) => t.name).map((tr) => (
                <Tr
                  key={tr.id}
                  _hover={{ bg: '#fafafa', cursor: 'pointer' }}
                  transition="color 0.1s ease-out"
                  role="group"
                  onClick={() => editTechnique(tr)}
                >
                  <Td>
                    <Text pl={24}>
                      {tr.name}
                    </Text>
                  </Td>

                  <Td textAlign="end" {...eventStopper()}>
                    <KebabOptionsMenu>
                      <MenuItem onClick={() => editTechnique(tr)}>Edit</MenuItem>
                      <MenuItem
                        onClick={() => {
                          modalManager.open(ConfirmationDialog, {
                            title: 'Delete piezo type',
                            children: 'This will remove the piezo type from Magnetize, but will not remove it from any existing logs.',
                            onConfirm: () => deleteTechnique(tr.id),
                            confirmButtonLabel: 'Delete',
                          });
                        }}
                      >
                        Delete
                      </MenuItem>
                    </KebabOptionsMenu>
                  </Td>
                </Tr>
              )))}
          </Tbody>
        </Table>
        <LinkButton ml={24} mt={6} isGreen noUnderline onClick={addTechnique}>
          + add piezo type
        </LinkButton>
        {!!selectedPiezoType
      && (
      <PiezoTypeEditModal
        title={piezoTypeModalTitle}
        onClose={() => {
          setSelectedPiezoType(null);
        }}
        piezoType={selectedPiezoType}
      />
      )}
      </ExpandableCountSection>
    </VStack>
  );
};
