import { Text, VStack } from '@chakra-ui/react';
import {
  groupBy, map, orderBy, uniq, values,
} from 'lodash';
import React, { useMemo } from 'react';
import { DepthLayer } from './DepthLayer';

import {
  Borehole,
} from '~/types/geotechnical';
import { BackfillType } from '~/types/backfillType';
import { SurfaceType } from '~/types/surfaceType';

export const InstallVisual = ({
  borehole, surfaceTypes, backfillTypes,
} : {
  borehole: Borehole;
  surfaceTypes: { [key: string]: SurfaceType };
  backfillTypes: { [key: string]: BackfillType };
}) => {
  const logsByDepth = useMemo(() => {
    if (!borehole || !borehole.undergroundServiceClearanceTechnique) {
      return [];
    }

    // Piezo logs need to be numbered so we know which top corresponds to which bottom
    const numberedPiezoLogs = map(
      orderBy(
        map(borehole.piezoLogs, (log, id) => ({
          id,
          ...log,
        })), (log) => log.startDepth,
      ), (log, index) => ({
        ...log,
        number: index + 1,
      }),
    );
    const logs = [
      ...(borehole.surfaceTypeId ? [{
        type: 'surface',
        depth: 0,
        id: 'surface',
        surfaceTypeId: borehole.surfaceTypeId,
      }] : []),
      ...map(borehole.backfillLayers, (log, id) => ({
        type: 'backfill', id, depth: log.startDepth, ...log,
      })),
      ...map(numberedPiezoLogs, (log) => ({
        type: 'piezo-start', depth: log.startDepth, ...log,
      })),
      ...map(numberedPiezoLogs, (log) => ({
        type: 'piezo-end', depth: log.endDepth, ...log,
      })),
      ...(borehole.inclo?.endDepth ? [{
        type: 'inclo',
        id: 'inclo',
        depth: borehole.inclo.endDepth,
      }] : []),
      ...(borehole.endDepth ? [{ type: 'end', id: 'end', depth: borehole.endDepth }] : []),
    ];
    const byDepth = groupBy(logs, (l) => l.depth);
    const depths = orderBy(uniq(map(logs, (l) => l.depth)));

    return map(depths, (depth) => ({
      depth,
      logs: byDepth[depth],
    }));
  }, [borehole]);

  const backfillLayers = useMemo(() => orderBy(
    values(borehole?.backfillLayers || {}),
    (l) => l.startDepth,
  ).reverse(), [borehole]);

  const piezoLayers = useMemo(() => orderBy(
    values(borehole?.piezoLogs || {}),
    (l) => l.startDepth,
  ).reverse(), [borehole]);

  if (!borehole) {
    return <></>;
  }

  return (
    <VStack spacing={2} align="stretch">
      {logsByDepth.map((dl) => {
        const currentBackfillLayer = backfillLayers.find((l) => l.startDepth <= dl.depth);
        const hasPiezo = piezoLayers.some((l) => l.startDepth <= dl.depth && l.endDepth > dl.depth);
        const hasInclo = borehole.inclo?.endDepth ? borehole.inclo.endDepth > dl.depth : false;
        const isEndOfHole = borehole.endDepth && dl.depth >= borehole.endDepth;

        return (
          <DepthLayer
            key={`depth-log-${dl.depth}`}
            depth={dl.depth}
            backfillImageId={
              currentBackfillLayer && backfillTypes[currentBackfillLayer.typeId]
                ? backfillTypes[currentBackfillLayer.typeId].imageId
                : null
}
            isEndOfHole={isEndOfHole}
            hasPiezo={hasPiezo}
            hasInclo={hasInclo}
            isPiezoStartOrEnd={piezoLayers
              .some((l) => l.endDepth === dl.depth || l.startDepth === dl.depth)}
            isIncloEnd={borehole.inclo?.endDepth === dl.depth}
          >
            {dl.logs.map((log : any) => {
              if (log.type === 'surface') {
                const surfaceType = surfaceTypes[log.surfaceTypeId];
                return (
                  <Text key={log.id}>
                    {surfaceType?.name}
                  </Text>
                );
              }

              if (log.type === 'backfill') {
                const prevLayer = orderBy(
                  Object.values(borehole.backfillLayers),
                  (x) => x.startDepth,
                ).reverse()
                  .find((l) => l.startDepth < log.startDepth);

                return (
                  <Text key={log.id} color="#A67619">
                    {`${prevLayer ? `${backfillTypes[prevLayer.typeId]?.name} → ` : ''}${backfillTypes[log.typeId]?.name}`}
                  </Text>
                );
              }

              if (log.type === 'piezo-start') {
                return (
                  <Text key={log.id} color="#6CAAC4">
                    {`Piezo ${log.number} (top)`}
                  </Text>
                );
              }

              if (log.type === 'piezo-end') {
                return (
                  <Text key={log.id} color="#6CAAC4">
                    {`Piezo ${log.number} (bottom)`}
                  </Text>
                );
              }

              if (log.type === 'inclo') {
                return (
                  <Text key={log.id} color="magnetize.extra-inclo-text">
                    Inclo (bottom)
                  </Text>
                );
              }

              if (log.type === 'end') {
                return (
                  <Text key={log.id}>
                    Hole ended
                  </Text>
                );
              }

              return undefined;
            })}
          </DepthLayer>
        );
      })}
    </VStack>
  );
};
