import { Flex, Text } from '@chakra-ui/react';
import { mapValues, round } from 'lodash';
import React from 'react';
import DndKitDragItem from '~/components/DndKitDragItem';
import LineItemsEditor from '~/components/LineItemsEditor';
import LineItemQuantity from '~/components/LineItemsEditor/components/LineItemQuantity';
import LineItemRemove from '~/components/LineItemsEditor/components/LineItemRemove';
import LineItemTotal from '~/components/LineItemsEditor/components/LineItemTotal';
import { Dictionary } from '~/types/helpers';
import { BundleLineItem, PriceBookLineItem } from '~/types/lineItem';

interface CalculateQuantityProps {
  oldQuantity: number;
  oldBundleQuantity: number;
  newBundleQuantity: number
}

const calculateLineItemQuantity = ({
  oldQuantity,
  oldBundleQuantity,
  newBundleQuantity,
} : CalculateQuantityProps) => {
  const newQuantity = oldQuantity
    * (newBundleQuantity / Math.max(oldBundleQuantity, 1));

  return round(newQuantity, 2);
};

interface BundleLineItemEditRowProps {
  lineItem: BundleLineItem;
  onLineItemChanged?: (nextLineItem: BundleLineItem) => void;
  onLineItemRemoved?: (lineItemId: string) => void;
}

const BundleLineItemEditRow = React.memo(({
  lineItem,
  onLineItemChanged,
  onLineItemRemoved,
} : BundleLineItemEditRowProps) => {
  const onChange = (li: BundleLineItem) => {
    // Recalculate total for bundle line item, as it is always
    // derived from the total of the child line items, use this to set the
    // bundles unit price cents
    const total = Object.values(li.lineItems).reduce(
      (acc, item) => acc + (item.unitPriceCents * item.quantity), 0,
    );
    onLineItemChanged({
      ...li,
      unitPriceCents: Math.round(total / li.quantity) || 0,
    });
  };
  return (

    <Flex>
      <DndKitDragItem
        id={lineItem.id}
      >
        <Flex
          flex="1"
          direction="column"
        >
          <Flex
            role="group"
            _hover={{ bg: '#fafafa', cursor: 'pointer' }}
            transition="color 0.1s ease-out"
            mt={-1}
            pt={1}
          >
            <Flex
              flexGrow={1}
              onClick={() => {
                onChange({ ...lineItem, expanded: !lineItem.expanded });
              }}
              alignItems="center"
            >
              <Text fontWeight="bold" fontSize="lg">{lineItem.name}</Text>

            </Flex>
            <LineItemQuantity
              fontWeight="bold"
              value={lineItem.quantity}
              onChange={(newQuantity) => {
                onChange({
                  ...lineItem,
                  quantity: newQuantity,
                  lineItems: mapValues(lineItem.lineItems, (li) => ({
                    ...li,
                    quantity: calculateLineItemQuantity({
                      oldQuantity: li.quantity,
                      newBundleQuantity: newQuantity,
                      oldBundleQuantity: lineItem.quantity,
                    }),
                  })),
                });
              }}
            />
            {lineItem.expanded ? <Flex flex="0 0 105px" /> : <LineItemTotal fontWeight="bold" value={lineItem.unitPriceCents * lineItem.quantity} />}

            <LineItemRemove onClick={() => { onLineItemRemoved(lineItem.id); }} />
          </Flex>
          {lineItem.expanded
        && (
        <LineItemsEditor
          hideBottomBorder
          lineItems={lineItem.lineItems}
          onChange={(lineItems: Dictionary<PriceBookLineItem>) => {
            onChange({
              ...lineItem,
              lineItems,
            });
          }}
          disableAddNew
        />
        )}
        </Flex>
      </DndKitDragItem>
    </Flex>
  );
});

export default BundleLineItemEditRow;
