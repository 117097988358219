import React from 'react';
import { Box, BoxProps, forwardRef } from '@chakra-ui/react';

interface VisibleOnlyProps extends BoxProps {
  media: 'screen' | 'print';
}

const PRINT_ONLY_SX = {
  '@media screen': {
    display: 'none',
  },
};

const SCREEN_ONLY_SX = {
  '@media print': {
    display: 'none',
  },
};

const VisibleOnlyIn = forwardRef(({
  media: inMedia, ...boxProps
}: VisibleOnlyProps, ref) => (
  <Box
    ref={ref}
    sx={inMedia === 'screen' ? SCREEN_ONLY_SX : PRINT_ONLY_SX}
    {...boxProps}
  />
));

export default VisibleOnlyIn;
