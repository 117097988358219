import {
  Flex, FormControl, FormLabel, Text,
} from '@chakra-ui/react';
import React, { useMemo } from 'react';
import SearchSelect from '~/components/SearchSelect';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import { CustomerActions } from '~/redux/customers/actions';
import { selectCustomers } from '~/redux/customers/selectors';
import { Customer } from '~/types/customer';

const Option = ({ option }: { option: Partial<Customer> }) => (
  <Flex>
    <Text>
      {option.companyName}
    </Text>
  </Flex>
);

interface ContactCustomerSelectProps {
  customerId: string;
  isDisabled?: boolean;
  onChange: (customerId: string) => void;
}

const ContactCustomerSelect = (
  { customerId, onChange, isDisabled = false } :
  ContactCustomerSelectProps,
) => {
  const {
    data: customers, isLoading,
  } = useTrackedFetch({
    trigger: () => CustomerActions.fetchAll(),
    selector: selectCustomers,
    key: 'customers',
  });

  const selectedValue = useMemo(
    () => customers.find((c) => c.id === customerId),
    [customers, customerId],
  );

  return (
    <FormControl>
      <FormLabel>Customer</FormLabel>
      <SearchSelect
        isDisabled={isDisabled}
        isLoading={isLoading}
        hideSearchIcon
        value={selectedValue}
        getOptionLabel={(c) => c.companyName}
        getOptionValue={(c) => c.id}
        onChange={({ id }) => {
          onChange(id);
        }}
        options={customers}
        renderOption={Option}
        placeholder="Select customer"
      />
    </FormControl>
  );
};

export default ContactCustomerSelect;
