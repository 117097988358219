import { isEqual } from 'lodash';
import { useEffect, useMemo } from 'react';
import { RootState } from '~/redux/reducer';
import { selectFetchState } from '~/redux/fetch/selectors';
import { useAppDispatch, useAppSelector } from '~/redux/store';

interface UseTrackedFetchProps<T> {
  key: string;
  trigger: () => void;
  selector: (state: RootState) => T;
  equalityMode?: 'strict' | 'deep';
  enabled?: boolean;
}

const useTrackedFetch = <T>({
  key, trigger, selector, equalityMode = 'strict', enabled = true,
}: UseTrackedFetchProps<T>) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((s) => selectFetchState(s, key), (a, b) => isEqual(a, b));
  const data = useAppSelector<T>(
    selector,
    // In strict mode pass in undefined to use the default equalityFn
    // which uses ===
    equalityMode === 'strict' ? undefined : (a: T, b: T) => isEqual(a, b),
  );

  useEffect(() => {
    if (enabled) {
      const result = trigger();
      dispatch(result);
    }
  }, [key, enabled]);

  return useMemo(() => ({
    ...state,
    data,
  }), [state, data]);
};

export default useTrackedFetch;
