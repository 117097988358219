export interface SubscriptionSetupIntent {
  clientSecret: string;
}

export enum PowerUpId {
  liveGeo = 'live-geo',
  xero = 'xero',
}

export interface SubscriptionPrice {
  powerUpId: PowerUpId;
  priceCents: number;
  name: string;
  description: string;
  image: string;
  currentCount: number;
  isActive: boolean;
  isBrowsable: boolean;
  category: string;
}

export interface SubscriptionPrices{
  admin: SubscriptionPrice,
  staff: SubscriptionPrice,
  powerUps: SubscriptionPrice[],
}

export interface SubscriptionPaymentMethod {
  id: string;
  brand: string;
  last4: string;
  exp_month: number;
  exp_year: number;
}

export interface SubscriptionInvoice {
  id: string;
  amount: number;
  url: string;
  date: number;
  description: string;
}
