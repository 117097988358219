import { ButtonProps, Button, Box } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';

interface LinkButtonProps extends ButtonProps {
  isGreen?: boolean;
  noUnderline?: boolean;
  to?: string;
}

const LinkButton = ({
  leftIcon = undefined, isGreen = false, noUnderline = false, to, ...props
}: LinkButtonProps) => (
  <Button
    size="sm"
    variant="link"
    fontWeight="600"
    textDecoration={noUnderline ? 'none' : 'underline'}
    color={isGreen ? 'magnetize.brand-4' : undefined}
    to={to}
    as={to ? RouterLink : undefined}
    leftIcon={leftIcon ? (<Box mb="1px">{leftIcon}</Box>) : undefined}
    {...props}
  />
);

export default LinkButton;
