import { createReducer } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';
import { LiveGeoActions } from '~/redux/liveGeo/actions';
import { Borehole, CPT } from '~/types/geotechnical';
import { Dictionary } from '~/types/helpers';

export type LiveGeoState = {
  boreholesById: Dictionary<Borehole>,
  boreholeIdsByJobId: Dictionary<string[]>
  cptsById: Dictionary<CPT>,
  cptsByJobId: Dictionary<string[]>
};

const liveGeoReducer = createReducer<LiveGeoState>({
  boreholesById: {},
  boreholeIdsByJobId: {},
  cptsById: {},
  cptsByJobId: {},
},
(builder) => {
  builder.addCase(LiveGeoActions.updated, (state, action) => ({
    ...state,
    boreholesById: {
      ...state.boreholesById,
      ...keyBy(action.payload.boreholes, (bh) => bh.id),
    },
    cptsById: {
      ...state.cptsById,
      ...keyBy(action.payload.cpts, (c) => c.id),
    },
  }));

  builder.addCase(LiveGeoActions.updatedForJob, (state, action) => ({
    ...state,
    boreholeIdsByJobId: {
      ...state.boreholeIdsByJobId,
      [action.payload.jobId]: action.payload.boreholeIds,
    },
    cptsByJobId: {
      ...state.cptsByJobId,
      [action.payload.jobId]: action.payload.cptIds,
    },
  }));
});

export default liveGeoReducer;
