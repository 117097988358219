import { createAction } from '@reduxjs/toolkit';
import createTrackedAction from '~/redux/tracked/createTrackedAction';
import { ContactInfo } from '~/types/contact-info';
import { Quote, QuoteEdit } from '~/types/quote';

export const QuoteActions = {
  fetch: createAction<{
    jobId: string,
    quoteId: string,
  }>('quotes/fetch'),

  fetchForJob: createAction<{
    jobId: string,
    force?: boolean,
  }>('quotes/fetchForJob'),

  edit: createTrackedAction<{
    jobId: string,
    quoteId: string,
    edits: Partial<QuoteEdit>,
  }>('quotes/edit'),

  quotesUpdated: createAction<{
    quotes: Quote[],
  }>('quotes/updated'),

  jobQuotesUpdated: createAction<{
    jobId: string,
    quoteIds: string[],
  }>('quotes/jobQuotesUpdated'),

  send: createTrackedAction<{
    jobId: string,
    quoteId: string,
    recipients: ContactInfo[],
    subject?: string,
    message?: string
  }>('quotes/send'),

  discardDraft: createTrackedAction<{
    jobId: string,
    quoteId: string
  }>('quotes/discard'),

  quoteRemoved: createAction<{
    jobId: string,
    quoteId: string,
  }>('quotes/removed'),

  accept: createTrackedAction<{
    jobId: string,
    quoteId: string,
  }>('quotes/accept'),

  revertAccept: createTrackedAction<{
    jobId: string,
    quoteId: string,
  }>('quotes/revertAccept'),
};

export const sendQuote = QuoteActions.send;
