import { omit } from 'lodash';
import { takeEvery, call } from 'redux-saga/effects';
import { updateJob } from '~/redux/jobs/actions';
import doPatchJob from '~/redux/jobs/saga/doPatchJob';
import { showToast } from '~/toast';
import queueWithLocalEdit from '~/utils/queueWorkWithLocalEdit';
import queryClient from '~/queryClient';
import { jobTimelineQuery } from '~/queries/jobTimeline';
import { performTrackedSaga } from '~/utils/performTrackedSaga';

const handleUpdateJob = takeEvery(
  updateJob,
  function* handle(action) {
    const jobId = action.payload.data.id;
    const dataToPatch = omit(action.payload.data, 'id');
    yield queueWithLocalEdit(jobId, dataToPatch,
      function* worker() {
        yield performTrackedSaga({
          key: action.payload.id,
          * saga() {
            try {
              yield doPatchJob(jobId, dataToPatch);
              if (dataToPatch.status) {
                yield call(() => queryClient.invalidateQueries(jobTimelineQuery(jobId)));
              }
            } catch (error) {
              showToast({
                id: jobId,
                title: 'Job Sync Error',
                description: error instanceof Error ? error.message : String(error),
                status: 'error',
                duration: 10000,
                isClosable: true,
              });
            }
          },
        });
      });
  },
);

export default handleUpdateJob;
