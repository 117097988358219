import { Divider } from '@chakra-ui/react';
import React from 'react';

const CardDivider = () => (
  <Divider
    marginTop={6}
    marginBottom={6}
    // we want the divider to span the entire card, so it needs to negate the
    // card's padding. The <hr> has width:100% but this doesn't work correctly
    // with the negative margins
    width="unset"
    marginLeft="-8"
    marginRight="-8"
    borderColor="gray.100"
  />
);

export default CardDivider;
