import { HStack, Text, Box } from '@chakra-ui/react';
import React, { useState } from 'react';
import LinkButton from '~/components/LinkButton';

export const ExpandableCountSection = ({
  title, icon, children, count = null, expand = false,
} : {
  title: string;
  expand?: boolean;
  icon: React.ReactNode;
  count?: number;
  children: React.ReactNode[];
}) => {
  const [expanded, setExpanded] = useState(expand);

  return (
    <Box>
      <HStack
        _hover={{ bg: '#fafafa' }}
        cursor="pointer"
        onClick={() => setExpanded(!expanded)}
        spacing={4}
        py={4}
        bg={expanded ? '#fafafa' : undefined}
        borderBottomWidth={1}
        borderBottomColor="magnetize.ui-3"
        align="stretch"
        alignItems="center"
      >
        <LinkButton isGreen noUnderline>
          {count !== null ? count : children.length}
        </LinkButton>
        {icon}
        <Text
          fontWeight="semibold"
          fontSize="md"
        >
          {title}
        </Text>
      </HStack>
      {expanded && (
      <Box
        pb={12}
        borderBottomWidth={1}
        borderBottomColor="magnetize.ui-3"
      >
        {children}
      </Box>
      )}
    </Box>
  );
};
