import { nanoid } from '@reduxjs/toolkit';
import { omit } from 'lodash';
import {
  all, call, put, takeEvery,
} from 'redux-saga/effects';
import {
  triggerCasingTypesFetch,
  casingTypesLoaded,
  deleteCasingType,
  casingTypeDeleted,
  casingTypeUpdated,
  updateCasingType,
} from '~/redux/casingTypes/actions';
import { showErrorToast, showToast } from '~/toast';
import { CasingType } from '~/types/casingType';
import fetchJson from '~/utils/fetchJson';
import { performFetchSaga } from '~/utils/performFetchSaga';

const watchFetch = takeEvery(
  triggerCasingTypesFetch,
  function* handle(action) {
    const { force } = action.payload;

    yield performFetchSaga({
      key: force ? nanoid() : 'casing-types',
      staleTime: 10000,
      * saga() {
        const tags: CasingType[] = yield call(
          fetchJson,
          '/api/geotechnical/casing-types',
        );
        yield put(casingTypesLoaded(tags));
      },
    });
  },
);
const watchUpdate = takeEvery(
  updateCasingType,
  function* handler(action) {
    const casingType = action.payload;
    const id = casingType.id ?? nanoid();

    try {
      const nextJobTag = yield call(() => fetchJson(
        `/api/geotechnical/casing-types/${id}`, {
          method: 'PUT',
          body: omit(casingType, 'id'),
        },
      ));
      yield put(casingTypeUpdated(nextJobTag));
      showToast({
        status: 'success',
        title: 'Casing type saved',
      });
    } catch (error) {
      showToast({
        status: 'error',
        title: 'Saving casing type failed',
        description: String(error),
      });
    }
  },
);

const watchDelete = takeEvery(
  deleteCasingType,
  function* handle(action) {
    const { id } = action.payload;

    try {
      yield call(() => fetchJson(`/api/geotechnical/casing-types/${id}`, {
        method: 'DELETE',
      }));
      yield put(casingTypeDeleted({ id }));
      showToast({
        status: 'success',
        title: 'Casing type deleted',
      });
    } catch (e) {
      showErrorToast(e, {
        title: 'Could not delete casing type',
      });
    }
  },
);

export default function* saga() {
  yield all([
    watchFetch,
    watchUpdate,
    watchDelete,
  ]);
}
