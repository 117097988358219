import {
  Flex, Text,
} from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import { sumBy, uniq } from 'lodash';
import React, { useMemo } from 'react';
import CenteredFlex from '~/components/CenteredFlex';
import QuoteLineItemCard from './QuoteLineItemCard';
import { EquipmentLogWithEquipmentAndPrice } from '../helpers';
import { LineItem } from '~/types/lineItem';
import ReconcileButtons from '~/pages/Job/components/invoices/ReconciliationManager/components/ReconcileButtons';

const EquipmentCard = (
  { equipmentLogs, overBudget } :
  { equipmentLogs : EquipmentLogWithEquipmentAndPrice[], overBudget: boolean },
) => {
  const totalHours = useMemo(() => (
    sumBy(equipmentLogs, (tr) => tr.quantity)
  ), [equipmentLogs]);
  return (
    <Flex
      flex={1}
      boxShadow="0px 1px 2px rgba(0, 0, 0, 0.1)"
      borderRadius="2"
      backgroundColor={overBudget ? 'rgba(255, 138, 0, 0.05)' : 'rgba(53,155,75, 0.05)'}
      borderWidth={1}
      borderColor="gray.200"
      my={5}
      py={5}
      px={4}
      direction="column"
    >
      <Text fontWeight="bold">{equipmentLogs[0].price ? equipmentLogs[0].price.name : ''}</Text>
      {equipmentLogs.map((el) => (
        <CenteredFlex key={el.id} justifyContent="space-between">
          <Text>
            {`${el.equipment.name}${el.equipment.nickname ? ` (${el.equipment.nickname})` : ''}`}
          </Text>
          <Text>
            {`${el.quantity}`}
          </Text>
        </CenteredFlex>
      ))}
      <CenteredFlex mt={4} flex={0} justifyContent="flex-end" fontWeight="600">
        {`${totalHours} ${equipmentLogs[0].price?.unit || ''}`}
      </CenteredFlex>
    </Flex>
  );
};

const EquipmentReconciliationRow = (
  {
    quoted, alreadyInvoiced, logged, onItemAdded, onItemIgnored, isDisabled,
  } : {
    quoted: LineItem[];
    alreadyInvoiced: LineItem[];
    logged: EquipmentLogWithEquipmentAndPrice[];
    onItemAdded: (lineItem: LineItem) => void;
    onItemIgnored: () => void;
    isDisabled: boolean;
  },
) => {
  const hasQuoted = quoted.length > 0;
  const totalLoggedQuantity = sumBy(logged, (l) => l.quantity);
  const totalQuotedQuantity = sumBy(quoted, (q) => q.quantity);
  const totalAlreadyInvoicedQuantity = sumBy(alreadyInvoiced, (q) => q.quantity);

  const quotedMatchesLogged = totalLoggedQuantity === totalQuotedQuantity;

  const addToInvoice = (useQuoted: boolean) => {
    const equipmentNames = uniq(logged.map((l) => l.equipment.name)).join(', ');
    if (useQuoted) {
      const quantity = Math.max(totalQuotedQuantity - totalAlreadyInvoicedQuantity, 0);
      if (quantity > 0) {
        onItemAdded({
          id: nanoid(),
          type: 'price',
          order: 0,
          name: quoted[0].name,
          description: quoted[0].description,
          quantity,
          unitPriceCents: quoted[0].unitPriceCents,
          priceId: quoted[0].priceId,
        });
      } else {
        onItemIgnored();
      }
    } else if (logged[0].price) {
      onItemAdded({
        id: nanoid(),
        type: 'price',
        name: `${logged[0].price.name} (${equipmentNames})`,
        order: 0,
        quantity: totalLoggedQuantity,
        priceId: logged[0].price.id,
        description: logged[0].price.description,
        unitPriceCents: logged[0].price.unitPriceCents,
      });
    } else {
      onItemAdded({
        id: nanoid(),
        type: 'custom',
        name: equipmentNames,
        order: 0,
        quantity: totalLoggedQuantity,
        unitPriceCents: 0,
      });
    }
  };

  return (
    <Flex>
      {hasQuoted
        ? (
          <QuoteLineItemCard
            name={quoted[0].name}
            unitPriceCents={quoted[0].unitPriceCents}
            unit={quoted[0].unit}
            quotedQuantity={totalQuotedQuantity}
            alreadyInvoicedQuantity={totalAlreadyInvoicedQuantity}
          />
        )
        : <Flex flex={1} px={2} />}
      <Flex mx={6} alignItems="center" justifyContent="center">
        <ReconcileButtons
          isDisabled={isDisabled}
          onLeftClick={() => {
            addToInvoice(true);
          }}
          onRightClick={() => {
            addToInvoice(false);
          }}
          onIgnoreClick={onItemIgnored}
          hasQuoted={hasQuoted}
          quotedMatchesLogged={quotedMatchesLogged}
          invoicedMoreThanQuoted={totalQuotedQuantity - totalAlreadyInvoicedQuantity < 0}
          loggedMoreThanQuoted={
            totalQuotedQuantity - totalAlreadyInvoicedQuantity - totalLoggedQuantity < 0
          }
        />
      </Flex>
      <EquipmentCard
        equipmentLogs={logged}
        overBudget={totalQuotedQuantity - totalAlreadyInvoicedQuantity - totalLoggedQuantity < 0}
      />
    </Flex>
  );
};
export default EquipmentReconciliationRow;
