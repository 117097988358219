import { QuoteEdit } from '~/types/quote';

export const getDraftFromQuote = (q:QuoteEdit) : QuoteEdit => {
  const {
    lineItems, title, description, files, isEstimate, taxRate, taxRateName, dateCreated, expiryDate,
  } = q;
  return {
    lineItems, title, description, files, isEstimate, taxRate, taxRateName, dateCreated, expiryDate,
  };
};
