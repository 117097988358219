import {
  Box, Flex,
  FormControl, FormLabel, Grid, GridItem, Spacer, Switch, Text,
} from '@chakra-ui/react';
import {
  areIntervalsOverlapping, getTime, getUnixTime, startOfDay,
} from 'date-fns';
import {
  flatten, isEmpty, isEqual, max,
} from 'lodash';
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { nanoid } from '@reduxjs/toolkit';
import AddJobEventPopover from '~/components/AddCalendarEventPopover';
import { CalendarContextProvider } from '~/components/Calendar/CalendarContext';
import CalendarDayDividers from '~/components/Calendar/CalendarDayDividers';
import CalendarGrid from '~/components/Calendar/CalendarGrid';
import CalendarGridItem from '~/components/Calendar/CalendarGridItem';
import CalendarHeader from '~/components/Calendar/CalendarHeader';
import CalendarNav from '~/components/Calendar/CalendarNav';
import { getCalendarDateRange } from '~/components/Calendar/getDateRange';
import ResizableCalendarItem from '~/components/Calendar/ResizableCalendarItem';
import { allocateRows } from '~/components/Calendar/rowAllocationAlgorithm';
import AdminEventsRow from '~/components/JobCalendar/AdminEventsRow';
import JobEventCard from '~/components/JobCalendar/JobEventCard';
import { JobEventViewModel, makeViewModelsForRealJobEvents } from '~/components/JobCalendar/JobEventViewModel';
import MonthCalendar from '~/components/JobCalendar/MonthCalendar';
import TagFilterSelect from '~/components/JobCalendar/TagFilterSelect';
import JobStatusDonuts from '~/components/JobStatusDonuts';
import { useResourcesQuery } from '~/queries/useResourcesQuery';
import CalendarTourActions from '~/redux/calendarTour/actions';
import { selectHighlightedDay, selectTourEvent } from '~/redux/calendarTour/selectors';
import { selectCurrentUser, selectIsTenantOwner } from '~/redux/currentUser/selectors';
import JobCalendarActions from '~/redux/jobCalendar/jobCalendar';
import { selectJobStatuses } from '~/redux/jobs/selectors';
import PrefsActions from '~/redux/prefs/actions';
import { ScheduleEventActions } from '~/redux/schedule/actions';
import { selectEventsWithinRange } from '~/redux/schedule/selectors';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import {
  EquipmentStatusEvent, isJobCalendarEvent, ScheduleEvent, StaffLeaveEvent,
} from '~/types/ScheduleEvent';
import { endOfDaySeconds, getIsoDate } from '~/utils/calendarHelpers';
import { useScrollbarWidth } from '~/hooks/useScrollbarWidth';
import { calculateResourceEventIntervals, getHolidaysForTenant } from '~/helpers/schedule';

const CALENDAR_GRID_AREAS = `
  "nav"
  "header"
  "body"
`;

const isAdminEvent = (ev: ScheduleEvent): ev is StaffLeaveEvent | EquipmentStatusEvent => (
  ev?.type === 'staff-leave' || ev?.type === 'equipment-status'
);

const JobCalendar = () => {
  const focusedDay = useAppSelector((s) => (
    s.jobCalendar.focusedDay ?? getIsoDate(startOfDay(Date.now()))
  ));

  const mode = useAppSelector((s) => s.jobCalendar.rangeMode ?? 'week');
  const tenant = useAppSelector((state) => selectCurrentUser(state)?.tenant);

  const selectedStatuses = useAppSelector((s) => (
    s.jobCalendar.filterStatuses ?? []
  ));
  const isOwner = useAppSelector((s) => selectIsTenantOwner(s));

  const selectedTags = useAppSelector((s) => (
    s.prefs.jobCalendarFilterTags ?? []
  ));

  const showResources = useAppSelector((s) => (
    s.prefs.jobCalendarShowResources ?? false
  ));

  const dateRange = useMemo(
    () => getCalendarDateRange(focusedDay, mode),
    [focusedDay, mode],
  );

  const holidays = useMemo(() => getHolidaysForTenant(tenant, dateRange), [tenant, dateRange]);

  const calendarElementRef = useRef<HTMLDivElement>();
  const dispatch = useAppDispatch();

  const scheduleEvents = useAppSelector((state) => (
    selectEventsWithinRange(state, dateRange.start, dateRange.end)
  ), (a, b) => isEqual(a, b));

  const jobIdsWithinRange = useMemo(
    () => scheduleEvents
      // gross.
      ?.filter(isJobCalendarEvent)
      ?.map((sev) => sev.jobId) ?? [],
    [scheduleEvents],
  );

  const { data: resourceViewModels } = useResourcesQuery();

  const jobStatusesById = useAppSelector(
    (state) => selectJobStatuses(state, jobIdsWithinRange),
    (a, b) => isEqual(a, b),
  );

  const allJobEvents = useMemo(() => (
    makeViewModelsForRealJobEvents(
      scheduleEvents ?? [],
      resourceViewModels,
    )),
  [scheduleEvents, resourceViewModels]);

  const jobEvents = useMemo(() => {
    let events = allJobEvents;

    if (!isEmpty(selectedStatuses)) {
      events = events.filter((jev) => selectedStatuses.includes(jobStatusesById?.[jev.jobId]));
    }

    if (!isEmpty(selectedTags)) {
      events = events.filter((ev) => (
        ev.resources?.some((r) => r.tags && r.tags.some((t) => selectedTags.includes(t)))
      ));
    }

    return events;
  }, [allJobEvents, jobStatusesById, selectedTags, selectedStatuses]);

  const adminEvents = useMemo(
    () => scheduleEvents.filter(isAdminEvent),
    [scheduleEvents],
  );

  const rowAllocations = useMemo(() => allocateRows([
    ...holidays.map(({ start, end, name }) => ({ start, end, id: name })),
    ...jobEvents,
  ]), [jobEvents, holidays]);

  useEffect(() => {
    dispatch(ScheduleEventActions.fetchForRange({
      from: getUnixTime(dateRange.start),
      to: getUnixTime(dateRange.end),
    }));
  }, [dateRange?.start.getTime(), dateRange?.end.getTime()]);

  const [newEventInterval, setNewEventInterval] = useState<{ start: Date, end: Date }>();
  const [selectedEventId, setSelectedEventId] = useState(null);
  const isAddingEvent = !!newEventInterval;

  const tourEvent = useAppSelector((s) => selectTourEvent(s));
  const highlightedDay = useAppSelector((s) => selectHighlightedDay(s));

  const eventShadowRow = useMemo(() => {
    const overlappingEvents = newEventInterval
      ? scheduleEvents.filter((ev) => areIntervalsOverlapping(ev, newEventInterval))
      : [];
    return (max(overlappingEvents.map((ev) => rowAllocations[ev.id])) ?? 0) + 1;
  }, [newEventInterval, rowAllocations, scheduleEvents]);

  const moveJobEvent = (jobEvent: JobEventViewModel, start: Date, end: Date) => {
    const nextResourceEvents = [];
    const eventsToRemove = [];
    const jobEventInterval = { start: jobEvent.start, end: jobEvent.end };

    // If the job is being moved, close resource calender.
    setSelectedEventId(null);

    // Recalculate the scheduling intervals for each resource and remove the old scheduled
    // resource events as new events will be written with the new intervals.
    jobEvent.resources.forEach((resource) => {
      const originalIntervals = resource.resourceEvents.map((ev) => ({
        start: ev.start,
        end: ev.end,
      }));
      const newIntervals = calculateResourceEventIntervals({
        start,
        end,
      },
      tenant,
      jobEventInterval,
      originalIntervals);

      newIntervals.forEach((interval) => {
        nextResourceEvents.push({
          id: nanoid(),
          type: 'job-resource',
          jobId: jobEvent.jobId,
          parentId: jobEvent.id,
          resourceId: resource.id,
          resourceType: resource.type,
          ...interval,
        });
      });
    });

    const resourceEvents = flatten(jobEvent.resources.map((resource) => resource.resourceEvents));
    eventsToRemove.push(...resourceEvents);

    dispatch(ScheduleEventActions.updateJobEvent({
      jobEvent: {
        ...jobEvent.jobEvent,
        start: getTime(start),
        end: getTime(end),
      },
      events: nextResourceEvents,
      eventsToRemove,
    }));

    if (jobEvent.jobEvent.id === tourEvent?.id) {
      const updated = {
        ...jobEvent.jobEvent,
        start: getTime(start),
        end: getTime(end),
      };
      dispatch(CalendarTourActions.eventUpdated({ event: updated }));
    }
  };

  const onCalendarBackgroundClick = (event: React.MouseEvent, date: Date) => {
    const isClickOnBackground = event.target === event.currentTarget;
    if (isClickOnBackground && selectedEventId) {
      setSelectedEventId(null);
      return;
    }
    if (isClickOnBackground && !isAddingEvent && !event.isDefaultPrevented()) {
      const hoverInterval = {
        start: startOfDay(date),
        end: endOfDaySeconds(date),
      };
      setNewEventInterval(hoverInterval);
    }
  };

  const onCreateEvent = (event: ScheduleEvent) => {
    if (event.type === 'equipment-status' || event.type === 'staff-leave') {
      dispatch(PrefsActions.set({
        prefs: {
          jobCalendarAdminRowExpanded: true,
        },
      }));
    }
  };

  const scrollbarWidth = useScrollbarWidth();

  return (
    <CalendarContextProvider
      start={dateRange.start}
      end={dateRange.end}
      holidays={holidays.map((h) => h.start)}
      calendarElementRef={calendarElementRef}
    >
      <Grid
        gridTemplateAreas={CALENDAR_GRID_AREAS}
        gridTemplateRows="min-content min-content 1fr"
        overflow="hidden"
        flex={1}
      >
        <GridItem gridArea="nav" pb="4">
          <Flex alignItems="center">
            <Flex alignItems="center" flexDirection="row">
              <Box minW="200px">
                <TagFilterSelect
                  selected={selectedTags}
                  onSelectionChange={(t) => (
                    dispatch(JobCalendarActions.setFilterTags({ tags: t })))}
                />
              </Box>
              <Box ml="4" data-calendar-tour-id="calendar-filter">
                <JobStatusDonuts
                  selected={selectedStatuses}
                  onSelectionChange={(s) => {
                    dispatch(JobCalendarActions.setFilterStatuses({ statuses: s }));
                    dispatch(CalendarTourActions.eventsFiltered());
                  }}
                />
              </Box>
            </Flex>
            <Flex alignItems="center" flex="1 1 auto">
              <CalendarNav
                dateRangeModes={['3-day', 'week', '2-week', 'month']}
                focusedDay={focusedDay}
                mode={mode}
                onFocusedDayChange={(d) => dispatch(JobCalendarActions.move({ focusedDate: d }))}
                onModeChange={(m) => dispatch(JobCalendarActions.setRangeMode({ rangeMode: m }))}
              />
            </Flex>
          </Flex>
        </GridItem>
        {mode === 'month' && (
          <MonthCalendar jobEvents={jobEvents} adminEvents={adminEvents} focusedDay={focusedDay} />
        )}
        {mode !== 'month' && (
        <>
          <GridItem
            gridArea="body"
            bg="magnetize.ui-2"
            ref={calendarElementRef}
            paddingRight={`${scrollbarWidth}px`}
          >
            <CalendarDayDividers />
          </GridItem>
          <GridItem
            gridArea="header"
            borderBottomColor="magnetize.ui-4"
            borderBottomWidth="2px"
            paddingRight={`${scrollbarWidth}px`}
          >
            <CalendarHeader />
          </GridItem>
          <GridItem
            gridArea="body"
            display="flex"
            flex={1}
            overflowY="hidden"
            flexDirection="column"
            borderLeftColor="magnetize.ui-4"
            borderLeftWidth="2px"
          >
            <CalendarGrid
              subdivisions={1}
              rowGap="2"
              columnGap="2"
              py="2"
              alignContent="flex-start"
              flex="1 0"
              overflowY="scroll"
              onClick={onCalendarBackgroundClick}
              pb={20}
            >

              {holidays.map((h) => (
                <CalendarGridItem
                  key={h.name}
                  start={h.start}
                  end={h.end}
                  row={rowAllocations[h.name]}
                >
                  <Box flex={1} backgroundColor="magnetize.brand-3" color="white" marginX={1} padding={3} borderRadius="3px">
                    <Text fontWeight="bold">{h.name}</Text>
                    <Text>Public holiday</Text>
                  </Box>
                </CalendarGridItem>

              ))}
              {jobEvents?.map((ev) => (
                <ResizableCalendarItem
                  data-calendar-tour-id={ev.id === tourEvent?.id ? 'calendar-event' : undefined}
                  key={ev.id}
                  start={ev.start}
                  end={ev.end}
                  row={rowAllocations[ev.id] ?? 1}
                  onSpanChange={({ start, end }) => moveJobEvent(ev, start, end)}
                  onClick={() => {
                    setNewEventInterval(undefined);
                    if (selectedEventId === ev.id) {
                      setSelectedEventId(null);
                    } else {
                      setSelectedEventId(ev.id);
                    }
                  }}
                >
                  <JobEventCard
                    onClose={() => setSelectedEventId(undefined)}
                    isSelected={ev.id === selectedEventId}
                    onSpanChange={({ start, end }) => moveJobEvent(ev, start, end)}
                    jobEvent={ev}
                    showResources={showResources}
                  />
                </ResizableCalendarItem>
              ))}
              {newEventInterval && (
                <CalendarGridItem
                  start={newEventInterval.start}
                  end={newEventInterval.end}
                  row={eventShadowRow}
                  minHeight="50px"
                >
                  <AddJobEventPopover
                    interval={newEventInterval}
                    onClose={() => setNewEventInterval(undefined)}
                    onCreateEvent={onCreateEvent}
                  />
                </CalendarGridItem>
              )}
              {highlightedDay && isOwner && (
                <CalendarGridItem
                  data-calendar-tour-id="calendar-add-event"
                  start={new Date(highlightedDay.start)}
                  end={new Date(highlightedDay.end)}
                  height="50px"
                  background="magnetize.brand-4"
                  opacity="0.25"
                  onClick={(e) => {
                    dispatch(CalendarTourActions.eventPopoverOpened());
                    onCalendarBackgroundClick(e, new Date(highlightedDay.start));
                  }}
                />
              )}
            </CalendarGrid>
            <AdminEventsRow
              selectedEventId={selectedEventId}
              setSelectedEventId={setSelectedEventId}
              adminEvents={adminEvents}
            />
          </GridItem>
        </>
        )}
      </Grid>
      {mode !== 'month' && (
      <Flex>
        <Spacer />
        <Flex alignItems="center" justifyContent="center">
          <FormControl display="flex" alignItems="center" mt="4">
            <FormLabel mb={0} textTransform="none">
              Show Resources
            </FormLabel>
            <Switch
              size="lg"
              isChecked={showResources}
              onChange={(e) => (
                dispatch(JobCalendarActions.setShowResources({ showResources: e.target.checked }))
              )}
            />
          </FormControl>
        </Flex>
      </Flex>
      )}
    </CalendarContextProvider>
  );
};

export default JobCalendar;
