import React, { useEffect } from 'react';
import TourPopover from '~/components/TourPopover';
import CalendarTourActions from '~/redux/calendarTour/actions';
import { selectCurrentStep, selectProgress } from '~/redux/calendarTour/selectors';
import { selectIsTenantOwner } from '~/redux/currentUser/selectors';
import { useAppDispatch, useAppSelector } from '~/redux/store';

const CalendarTour = () => {
  const dispatch = useAppDispatch();
  const currentStep = useAppSelector((state) => selectCurrentStep(state));
  const progress = useAppSelector((state) => selectProgress(state));
  const isOwner = useAppSelector((s) => selectIsTenantOwner(s));
  const tourCompleted = useAppSelector((s) => (
    s.prefs.jobCalendarTourCompleted ?? false
  ));

  useEffect(() => {
    if (!tourCompleted) {
      dispatch(CalendarTourActions.startTour());
    }
  }, [tourCompleted]);

  if (!isOwner || tourCompleted || !progress.isInProgress) {
    return <></>;
  }

  return <TourPopover currentStep={currentStep} progress={progress} />;
};

export default CalendarTour;
