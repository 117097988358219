import {
  Button,
  Modal, ModalBody, ModalCloseButton,
  ModalContent, ModalFooter, ModalHeader, ModalOverlay,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useAppDispatch } from '~/redux/store';
import { updateSoilType } from '~/redux/soilTypes/actions';
import { SoilType } from '~/types/soilType';
import { SoilTypeForm } from './SoilTypeForm';

export const SoilTypeEditModal = ({
  soilType,
  title = 'Edit soil type',
  onClose = () => {},
}: {
  soilType: Partial<SoilType>,
  title: string,
  onClose: () => void
}) => {
  const dispatch = useAppDispatch();
  const [editedSoilType, setEditedSoilType] = useState(soilType);
  const [isValid, setIsValid] = useState(false);

  const onSaveClicked = () => {
    dispatch(updateSoilType(editedSoilType as SoilType));
    onClose();
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen
      onClose={onClose}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {title}
        </ModalHeader>
        <ModalCloseButton tabIndex={-1} />
        <ModalBody>
          <SoilTypeForm
            value={editedSoilType}
            onChange={(st, v) => {
              setEditedSoilType({
                ...editedSoilType,
                ...st,
              });
              setIsValid(v);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={!isValid}
            colorScheme="brandDark"
            onClick={onSaveClicked}
            size="lg"
          >
            Save
          </Button>
          <Button tabIndex={-1} variant="ghost" onClick={onClose}>cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
