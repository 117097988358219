import { Flex, Text, Box } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import Flags from 'country-flag-icons/react/3x2';
import countries from 'i18n-iso-countries';
import countriesEn from 'i18n-iso-countries/langs/en.json';
import SearchSelect from '~/components/SearchSelect';

const CountryWithFlag = ({ name, code }: { name: string, code: string }) => {
  if (!name && !code) {
    return <></>;
  }
  const FlagComponent = Flags[code];
  if (!FlagComponent) {
    return <></>;
  }
  return (
    <Flex>
      <Box h={5} w={10}>
        {code && <FlagComponent /> }
      </Box>
      <Text ml={5} fontWeight="bold">
        {name}
      </Text>
    </Flex>
  );
};

const CurrencyOption = ({ option }: { option:{ value: string, label: string } }) => (
  <Flex>
    <CountryWithFlag name={option.label} code={option.value} />
  </Flex>
);

const CurrentValue = ({ option }: { option:{ value: string, label: string } }) => (
  <Flex justifyContent="space-between" flex={1}>
    <CountryWithFlag name={option.label} code={option.value} />
  </Flex>
);

export const CountrySearchSelect = ({
  value, onChange, isDisabled = false,
} : {
  value: string;
  isDisabled?: boolean;
  onChange: (value: string) => void;
}) => {
  const countriesOptions = useMemo(() => {
    countries.registerLocale(countriesEn);
    return Object.entries(countries.getNames('en', { select: 'official' }))
      .map(([code, label]) => ({ value: code, label }));
  }, []);

  const selectedValue = useMemo(
    () => {
      if (!countriesOptions) {
        return null;
      }
      return countriesOptions.find((p) => p.value === value);
    },
    [countriesOptions, value],
  );

  return (
    <SearchSelect
      hideSearchIcon
      value={selectedValue}
      isDisabled={isDisabled}
      onChange={(v) => onChange(v.value)}
      options={countriesOptions}
      renderOption={CurrencyOption}
      renderCurrentValue={CurrentValue}
      blurInputOnSelect
    />
  );
};
