import { createSelector } from '@reduxjs/toolkit';
import selectLocalEdits from '~/redux/localEdits/selectors';
import { RootState } from '~/redux/store';
import applyLocalEdits from '~/utils/applyLocalEdits';

export const selectCustomerQuoteByToken = (
  state: RootState,
  token: string,
) => state.customerQuotes[token]?.data;

export const selectCustomerQuoteWithEdits = createSelector(
  selectCustomerQuoteByToken,
  selectLocalEdits,
  (quote, edits) => applyLocalEdits(quote, edits),
);

export const selectCustomerQuoteFetchState = (state: RootState, token: string) => {
  const quoteState = state.customerQuotes[token];
  const status = quoteState?.status ?? 'idle';
  return {
    status,
    isInvalid: quoteState ? !!quoteState?.isInvalid : true,
    isLoading: !quoteState?.data && status === 'loading',
    isError: status === 'error',
  };
};
