import {
  AlertDialog, AlertDialogBody, AlertDialogCloseButton, AlertDialogContent, AlertDialogFooter,
  AlertDialogHeader, AlertDialogOverlay, Button, Text,
} from '@chakra-ui/react';
import React, { useRef } from 'react';

const noop = () => {};

const TurnOffDialog = ({
  isOpen,
  onTurnOff,
  onClose = noop,
}: {
  isOpen?: boolean;
  onClose?: () => void;
  onTurnOff: () => void;
}) => {
  const cancelRef = useRef<HTMLButtonElement>(null);

  return (
    <AlertDialog
      isOpen={isOpen ?? false}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      size="3xl"
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontWeight="bold">
            Turn off auto-send?
          </AlertDialogHeader>
          <AlertDialogCloseButton tabIndex={-1} />
          <AlertDialogBody>
            <Text size="lg">
              Turning off this default setting means that Live Geo reports wont&apos;t
              be automatically sent to the job contact.
            </Text>
            <Text size="lg" fontWeight="bold" mt={2}>
              NOTE: You will still be able to turn auto-send ON/OFF for specific jobs.
            </Text>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              size="lg"
              onClick={onTurnOff}
            >
              Turn Off
            </Button>
            <Button ref={cancelRef} variant="ghost" onClick={onClose}>
              cancel
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default TurnOffDialog;
