import React from 'react';
import { Box, BoxProps, Text } from '@chakra-ui/react';
import { useAppSelector } from '~/redux/store';
import { getTagById } from '~/redux/tags/selectors';
import { JobTag as JobTagType } from '~/types/tag';

const JobTag = ({
  id, ...props
}: { id: string } & BoxProps) => {
  const tag = useAppSelector((state) => getTagById(state, id)) as JobTagType;

  if (!tag) {
    return null;
  }

  return (
    <Box display="inline-block" paddingX="0.8rem" paddingY="0.3rem" borderRadius={3} backgroundColor={`${tag.color}22`} maxWidth="130px" {...props}>
      <Text fontWeight="semibold" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" color={tag.color}>{tag.name}</Text>
    </Box>
  );
};

export default JobTag;
