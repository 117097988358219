function formatCurrency(valueInCents: number, currency: string = 'NZD', excludeCents = false): string {
  const formatted = new Intl.NumberFormat(navigator.language || 'en-NZ', {
    style: 'currency',
    currency,
    currencyDisplay: 'narrowSymbol',
  }).format(valueInCents / 100);
  return excludeCents ? formatted.replace(/\.00$/, '') : formatted;
}

export default formatCurrency;
