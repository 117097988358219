import { Flex, Box } from '@chakra-ui/react';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import Card from '~/components/Card';
import JobCalendar from '~/components/JobCalendar';
import PageTabs from '~/components/PageTabs';
import ResourceCalendar from '~/components/ResourceCalendar';
import CalendarTour from '~/pages/JobCalendar/CalendarTour';
import CalendarTourActions from '~/redux/calendarTour/actions';
import { useAppDispatch } from '~/redux/store';

const JobCalendarPage = () => {
  const dispatch = useAppDispatch();

  return (
    <Box
      px="12"
      flex={1}
      display="flex"
      flexDir="column"
      overflow="hidden"
      pb="12"
    >
      <Flex
        direction="column"
        data-calendar-tour-id="calendar-tabs"
        onClick={() => {
          dispatch(CalendarTourActions.resourcesViewSelected());
        }}
      >
        <PageTabs
          tabs={[
            { title: 'Ready to Schedule', path: '/calendar/jobs' },
            { title: 'Resources View', path: '/calendar/resources' },
          ]}
        />
      </Flex>
      <Card
        flex={1}
        display="flex"
        flexDir="column"
        overflow="hidden"
      >
        <Switch>
          <Route path="/calendar/jobs">
            <JobCalendar />
          </Route>
          <Route path="/calendar/resources">
            <ResourceCalendar />
          </Route>
          <Route path="*" render={() => <Redirect to="/calendar/jobs" />} />
        </Switch>
      </Card>
      <CalendarTour />
    </Box>
  );
};

export default JobCalendarPage;
