import { createSelector } from '@reduxjs/toolkit';
import { sortBy } from 'lodash';
import { RootState } from '~/redux/reducer';

export const selectAllCustomerContacts = createSelector(
  (s: RootState) => s.customerContacts.byId,
  (customerContacts) => sortBy(customerContacts, 'name'),
);

export const selectCustomerContactById = (s: RootState, id: string) => (
  s.customerContacts.byId[id]
);

export const selectCustomerContactByCustomerId = (
  state: RootState, customerId: string,
) => {
  const contacts = selectAllCustomerContacts(state);
  return contacts.filter((c) => c.customerId === customerId);
};
