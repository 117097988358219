import { createAction } from '@reduxjs/toolkit';
import createTrackedAction from '~/redux/tracked/createTrackedAction';
import { Invoice } from '~/types/invoice';

export const InvoiceActions = {
  fetchForJob: createAction<{
    jobId: string,
    force?: boolean
  }>('invoices/fetchForJob'),

  saveInvoice: createTrackedAction<{
    invoiceId: string,
    jobId: string,
    invoice: Invoice
  }>('invoices/save'),

  invoicesUpdated: createAction<{
    invoices: Invoice[]
  }>('invoices/updated'),

  jobInvoicesUpdated: createAction<{
    jobId: string,
    invoiceIds: string[],
  }>('invoices/jobInvoicesUpdated'),

  discardDraft: createTrackedAction<{
    jobId: string,
    invoiceId: string
  }>('invoices/discard'),
  // sendInvoice: createTrackedAction<{
  //   jobId: string,
  //   invoiceId: string,
  //   recipients: ContactInfo[];
  //   subject?: string,
  //   message?: string
  // }>('invoices/send'),
};
