import * as Sentry from '@sentry/react';
import {
  all, call, put, takeEvery,
} from 'redux-saga/effects';
import { getStaffByCognitoUsername } from '~/api/staffApi';
import { getTenant } from '~/api/tenantApi';
import { getCurrentUser, signIn, signOut } from '~/helpers/auth';
import {
  currentUserActions,
} from '~/redux/currentUser/actions';
import { showErrorToast, showSuccessToast } from '~/toast';
import { CognitoUser } from '~/types/cognitoUser';
import { Staff } from '~/types/staff';
import { Tenant } from '~/types/tenant';
import fetchJson from '~/utils/fetchJson';
import { performFetchSaga } from '~/utils/performFetchSaga';
import { takeEveryTracked } from '~/utils/performTrackedSaga';
import history from '~/history';
import { reset } from '~/redux/reducer';
import StaffActions from '~/redux/staff/actions';

function* safe(effect) {
  try {
    return yield effect;
  } catch (err) {
    return null;
  }
}

function* loadTenantAndStaff(cognito: CognitoUser) {
  const [tenant, staff]: [Tenant, Staff] = yield all([
    safe(call(getTenant, false)),
    safe(call(getStaffByCognitoUsername, cognito.username)),
  ]);
  yield put(currentUserActions.updated({
    cognito: null, staff, tenant,
  }));
  yield put(StaffActions.updated({
    staff: [staff],
  }));
}

const handleCurrentUserFetch = takeEvery(
  currentUserActions.fetch,
  function* handle() {
    yield performFetchSaga({
      key: 'currentUser',
      force: true,
      * saga() {
        const cognito: CognitoUser = yield call(getCurrentUser);
        Sentry.setUser({
          id: cognito.username,
          cognitoGroups: cognito.groups,
        });
        yield put(currentUserActions.updated({
          cognito, staff: null, tenant: null,
        }));
        yield loadTenantAndStaff(cognito);
      },

    });
  },
);

const handleSignIn = takeEveryTracked(
  currentUserActions.signIn,
  function* handle(action) {
    const { username, password, redirectTo } = action.payload.data;
    yield performFetchSaga({
      key: 'currentUser',
      * saga() {
        const cognito: CognitoUser = yield signIn(username, password);
        Sentry.setUser({
          id: cognito.username,
          cognitoGroups: cognito.groups,
        });
        yield put(currentUserActions.updated({
          cognito, staff: null, tenant: null,
        }));
        yield loadTenantAndStaff(cognito);
        yield call(() => history.replace(redirectTo ?? '/'));
      },
    });
  },
);

const handleSignOut = takeEvery(
  currentUserActions.signOut,
  function* handle() {
    yield call(signOut);
    yield put(currentUserActions.clear());
    Sentry.configureScope((scope) => scope.setUser(null));
    yield put(reset());
  },
);

const handleTenantSave = takeEveryTracked(
  currentUserActions.saveTenant,
  function* handle(action) {
    const { tenant, showSuccess } = action.payload.data;
    yield put(currentUserActions.updated({
      cognito: null, staff: null, tenant,
    }));
    try {
      yield call(() => fetchJson<Tenant>('/api/tenant/', {
        method: 'PUT',
        body: tenant,
      }));
      if (showSuccess) {
        showSuccessToast('Saved organisation settings');
      }
    } catch (error) {
      showErrorToast(error);
    }
  },
);

const handleTenantDeleteTrialData = takeEveryTracked(
  currentUserActions.deleteTrialData,
  function* handle() {
    try {
      yield call(() => fetchJson('/api/tenant/trial-data', {
        method: 'DELETE',
      }));
    } catch (error) {
      showErrorToast(error);
    }
  },
);

export default function* handleCurrentUser() {
  yield all([
    handleCurrentUserFetch,
    handleSignOut,
    handleSignIn,
    handleTenantSave,
    handleTenantDeleteTrialData,
  ]);
}
