import { nanoid } from '@reduxjs/toolkit';
import { omit } from 'lodash';
import {
  all, call, put, select, takeEvery,
} from 'redux-saga/effects';
import PriceBundleActions from '~/redux/priceBundle/actions';
import { selectAllPriceBundles, selectPriceBundleById, selectPriceBundles } from '~/redux/priceBundle/selectors';
import { showErrorToast, showToast } from '~/toast';
import { PriceBundle } from '~/types/priceBundle';
import fetchJson from '~/utils/fetchJson';
import { performFetchSaga } from '~/utils/performFetchSaga';

const watchFetch = takeEvery(
  PriceBundleActions.fetch,
  function* handle() {
    yield performFetchSaga({
      key: 'price-bundles',
      staleTime: 30000,
      * saga() {
        const priceBundles: PriceBundle[] = yield call(
          fetchJson,
          '/api/price-bundles',
        );

        yield put(PriceBundleActions.updated({
          priceBundles,
        }));
      },
    });
  },
);

const watchSave = takeEvery(
  PriceBundleActions.save,
  function* handler(action) {
    const { priceBundle } = action.payload;
    const id = priceBundle.id ?? nanoid();

    const existingBundles: PriceBundle[] = yield select(
      (state) => selectPriceBundles(state),
    );

    yield put(PriceBundleActions.updated({
      priceBundles: [priceBundle],
    }));

    try {
      yield call(() => fetchJson(
        `/api/price-bundles/${id}`, {
          method: 'PUT',
          body: omit(priceBundle, 'id'),
        },
      ));
      showToast({
        status: 'success',
        title: 'Bundle saved',
      });
    } catch (error) {
      yield put(PriceBundleActions.updated({
        priceBundles: existingBundles,
      }));

      showToast({
        status: 'error',
        title: 'Saving bundle failed',
        description: String(error),
      });
    }
  },
);

const watchArchive = takeEvery(
  PriceBundleActions.archive,
  function* handle(action) {
    const { priceBundleId } = action.payload;
    const allPriceBundles: PriceBundle[] = yield select(selectAllPriceBundles);
    const existing: PriceBundle = yield select((s) => selectPriceBundleById(s, priceBundleId));
    if (!existing) {
      return;
    }

    try {
      const deletedPriceBundle = {
        ...existing,
        isDeleted: true,
      };
      yield put(PriceBundleActions.updated({ priceBundles: [deletedPriceBundle] }));
      const result: PriceBundle = yield call(() => fetchJson(`/api/price-bundles/${priceBundleId}`, {
        method: 'PUT',
        body: deletedPriceBundle,
      }));
      yield put(PriceBundleActions.updated({ priceBundles: [result] }));
    } catch (e) {
      yield put(PriceBundleActions.updated({ priceBundles: allPriceBundles }));
      showErrorToast(e, {
        title: 'Could not delete bundle',
      });
    }
  },
);
export default function* handlePriceBundles() {
  yield all([
    watchFetch,
    watchSave,
    watchArchive,
  ]);
}
