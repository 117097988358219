import {
  Grid, Heading, Skeleton, SkeletonText, Box,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import SubscriptionActions from '~/redux/subscription/actions';
import PaymentMethod from '~/components/PaymentMethod';
import SelectedPlan from '~/components/SelectedPlan';
import { Tenant, SubscriptionStatusInfo } from '~/types/tenant';
import PlanModal from '~/components/PlanModal';
import SelectPaymentMethodModal from '~/components/SelectPaymentMethodModal';
import CancelPlanModal from '~/components/CancelPlanModal';
import useTrackedAction from '~/hooks/useTrackedAction';
import LinkButton from '~/components/LinkButton';
import SubscriptionInvoices from '~/components/SubscriptionInvoices';
import GreenButton from '~/components/GreenButton';
import { DeleteExampleDataModal } from '~/components/DeleteExampleDataModal';

const PlansTab = ({ tenant }: { tenant: Tenant }) => {
  const location = useLocation();
  const [selectPlanVisible, setSelectPlanVisible] = useState(false);
  const [selectPaymentMethodVisible, setSelectPaymentMethodVisible] = useState(false);
  const [cancelPlanVisible, setCancelPlanVisible] = useState(false);
  const [deleteExampleDataVisible, setDeleteExampleDataVisible] = useState(false);
  const [pendingPlan, setPendingPlan] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    setSelectPlanVisible(query?.get('showPlanModal') === 'true' ?? false);
  }, [location]);

  const { data: prices, isLoading: isLoadingPrices } = useTrackedFetch({
    key: 'subscriptionPrices',
    trigger: () => SubscriptionActions.fetchPrices(),
    selector: (state) => state.subscription.prices,
  });
  const { data: paymentMethod, isLoading: isLoadingPaymentMethod } = useTrackedFetch({
    key: 'subscriptionPaymentMethod',
    trigger: () => SubscriptionActions.fetchPaymentMethod(),
    selector: (state) => state.subscription.paymentMethod,
  });

  const { trigger: onActivatePlan } = useTrackedAction({
    trigger: () => {
      setIsSaving(true);
      return SubscriptionActions.activatePlan({ });
    },
    onSuccess: () => {
      setIsSaving(false);
      setSelectPlanVisible(false);
      setSelectPaymentMethodVisible(false);
    },
  });
  const { trigger: onCancelPlan } = useTrackedAction({
    trigger: () => {
      setIsSaving(true);
      return SubscriptionActions.cancelPlan({});
    },
    onSuccess: () => {
      setIsSaving(false);
      setCancelPlanVisible(false);
    },
  });

  if (isLoadingPaymentMethod || isLoadingPrices) {
    return <SkeletonText />;
  }

  const statusInfo = SubscriptionStatusInfo[tenant.status];

  return (
    <Grid gap={6}>
      <Heading size="sm">CURRENT PLAN</Heading>
      {isLoadingPrices || !prices
        ? <Skeleton />
        : (
          <SelectedPlan
            tenant={tenant}
            openSelectPlanModal={() => { setSelectPlanVisible(true); }}
            reactivatePlan={() => { setSelectPlanVisible(true); }}
            prices={prices}
          />
        )}

      <Heading mt={6} size="sm">PAYMENT METHOD</Heading>
      {isLoadingPaymentMethod
        ? <Skeleton />
        : (
          <PaymentMethod
            paymentMethod={paymentMethod}
            openPaymentMethodModal={() => { setSelectPaymentMethodVisible(true); }}
          />
        )}

      {!statusInfo.isTrial && (
      <>
        <Heading mt={6} size="sm">Invoices</Heading>
        <SubscriptionInvoices />
      </>
      )}
      {statusInfo.isActive
      && !statusInfo.isTrial
      && (
      <Box>
        <LinkButton color="red" noUnderline size="lg" onClick={() => setCancelPlanVisible(true)}>Cancel Subscription</LinkButton>
      </Box>
      )}
      {tenant.trialDataType && (
      <Box>
        <LinkButton color="red" noUnderline size="lg" onClick={() => setDeleteExampleDataVisible(true)}>Delete example data</LinkButton>
      </Box>
      )}
      {selectPlanVisible && !isLoadingPrices && prices
      && (
      <PlanModal
        isPlanActive={tenant.status === 'subscribed' || tenant.status === 'subscriptionGrace' || tenant.status === 'subscriptionPending'}
        prices={prices}
        onCancel={() => { setSelectPlanVisible(false); }}
        actionButton={(
          <GreenButton
            size="lg"
            isLoading={isSaving}
            autoFocus
            onClick={() => {
              setSelectPlanVisible(false);
              if (paymentMethod) {
                onActivatePlan();
              } else {
                setPendingPlan(true);
                setSelectPaymentMethodVisible(true);
              }
            }}
          >
            Activate Plan
          </GreenButton>
        )}
      />
      )}
      {selectPaymentMethodVisible
      && (
      <SelectPaymentMethodModal
        onCancel={() => { setSelectPaymentMethodVisible(false); }}
        onSelectPaymentMethod={() => {
          if (pendingPlan) {
            onActivatePlan();
            setPendingPlan(null);
          } else {
            setSelectPaymentMethodVisible(false);
          }
        }}
      />
      )}
      {cancelPlanVisible
      && (
      <CancelPlanModal
        isSaving={isSaving}
        onCancel={() => { setCancelPlanVisible(false); }}
        onCancelSubscription={() => {
          onCancelPlan();
          if (pendingPlan) {
            setPendingPlan(false);
          }
        }}
      />
      )}
      {deleteExampleDataVisible
      && (
      <DeleteExampleDataModal
        onCancel={() => { setDeleteExampleDataVisible(false); }}
      />
      )}
    </Grid>
  );
};

export default PlansTab;
