import {
  Avatar, SkeletonCircle,
} from '@chakra-ui/react';
import React from 'react';
import { selectEquipmentById } from '~/redux/equipment/selectors';
import { useAppSelector } from '~/redux/store';

const EquipmentAvatar = ({ equipmentId, ...rest } : { equipmentId: string; }) => {
  const equipment = useAppSelector((s) => selectEquipmentById(s, equipmentId));

  if (!equipment) {
    return <SkeletonCircle size="12" />;
  }
  return (
    <Avatar bg="magnetize.text-1" color="white" name={equipment.name} src={equipment.profilePicture?.thumbUrl || equipment.profilePicture?.url} {...rest} />
  );
};

export default EquipmentAvatar;
