import {
  Alert, AlertDescription, AlertProps, AlertStatus, AlertTitle, CloseButton, HStack, Icon,
} from '@chakra-ui/react';
import { CheckCircleOutline, ErrorOutline, InfoOutlined } from '@material-ui/icons';
import React, { ReactNode } from 'react';

export interface StatusAlertProps {
  title: string;
  icon?: any;
  status?: AlertStatus;
  children?: ReactNode;
  onClose?: () => void;
}

const StatusAlert = ({
  title, status = 'warning', children, icon, onClose = null, ...props
} : StatusAlertProps & AlertProps) => {
  let alertIcon = icon;
  if (!alertIcon) {
    alertIcon = ErrorOutline;
    if (status === 'success') {
      alertIcon = CheckCircleOutline;
    } else if (status === 'info') {
      alertIcon = InfoOutlined;
    }
  }

  return (
    <Alert
      status={status}
      bg="gray.100"
      variant="left-accent"
      flexDirection="column"
      alignItems="start"
      {...props}
    >
      <HStack mt={1} align="stretch">
        <Icon w="16px" h="16px" as={alertIcon} />
        <AlertTitle>{title}</AlertTitle>
      </HStack>
      <AlertDescription mt={1} mx={8}>
        {children}
      </AlertDescription>
      {onClose && <CloseButton size="sm" onClick={onClose} position="absolute" right="7px" top="7px" />}
    </Alert>
  );
};

export default StatusAlert;
