import { makeRoute } from '~/utils/RouteGenerator';

const Routes = {
  newJob: makeRoute<{}>('/jobs/new'),

  jobList: makeRoute<{}>('/jobs'),
  jobsOpenList: makeRoute<{}>('/jobs/open'),
  jobsLeadsList: makeRoute<{}>('/jobs/leads'),
  jobsOnHoldList: makeRoute<{}>('/jobs/hold'),
  jobsInvoiceList: makeRoute<{}>('/jobs/invoice'),
  jobsClosedList: makeRoute<{}>('/jobs/closed'),
  jobsCancelledList: makeRoute<{}>('/jobs/cancelled'),
  jobsArchivedList: makeRoute<{}>('/jobs/archived'),

  job: makeRoute<{ jobId: string }>('/job/:jobId'),
  jobDetails: makeRoute<{ jobId: string }>('/job/:jobId/details'),
  jobInvoice: makeRoute<{ jobId: string }>('/job/:jobId/invoice'),
  jobQuote: makeRoute<{ jobId: string }>('/job/:jobId/quote'),
  jobBudget: makeRoute<{ jobId: string }>('/job/:jobId/budget'),
  jobSchedule: makeRoute<{ jobId: string }>('/job/:jobId/schedule'),
  jobFiles: makeRoute<{ jobId: string }>('/job/:jobId/files'),
  jobLogs: makeRoute<{ jobId: string }>('/job/:jobId/logs'),
  jobQuotePreview: makeRoute<{ jobId: string, quoteId: string }>('/job/:jobId/quote-preview/:quoteId'),
  jobLiveGeo: makeRoute<{ jobId: string, logId: string }>('/job/:jobId/live-geo/:logId'),

  jobCalendar: makeRoute<{}>('/calendar/jobs'),

  tenantSettings: makeRoute<{}>('/settings'),
  quoteSettings: makeRoute<{}>('/settings/quote'),
  jobSettings: makeRoute<{}>('/settings/job'),
  teamSettings: makeRoute<{}>('/staff/view'),
  financeSettings: makeRoute<{}>('/settings/finance'),
  powerUpsSettings: makeRoute<{}>('/settings/power-ups'),

  privacyPolicy: makeRoute<{}>('/privacy-policy'),

  customerQuote: makeRoute<{ token: string }>('/public/quote/:token'),
  customerJobLiveGeo: makeRoute<{ token: string }>('/public/live-geo/:token'),

  adminSettings: makeRoute<{}>('/superuser/tenants'),
  adminTenantDetails: makeRoute<{ tenantId: string }>('/superuser/tenant/:tenantId'),
};

export default Routes;
