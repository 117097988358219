export const PopoverStyles = {
  baseStyle: {
    content: {
      borderRadius: 'sm',
      boxShadow: 'md',
      _focus: {
        boxShadow: 'none',
      },
    },

    header: {
      color: 'magnetize.text-2',
      fontSize: 'lg',
      fontWeight: 'semibold',
      bg: 'gray.50',
      borderBottomWidth: 0,
      px: '8',
      py: '8',
    },

    body: {
      color: 'magnetize.text-2',
      px: '8',
      py: '8',
    },
  },
};
