import {
  Icon, Table, Text, Tbody, Tr, VStack, Td, Skeleton, MenuItem,
} from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
import React, { useState } from 'react';
import { ReactComponent as ClearanceIcon } from '~/assets/live_geo_clearance_technique.svg';
import ConfirmationDialog from '~/components/ConfirmationDialog';
import { ExpandableCountSection } from '~/components/ExpandableCountSection';
import { KebabOptionsMenu } from '~/components/KebabOptionsMenu';
import LinkButton from '~/components/LinkButton';
import { useModalManager } from '~/components/ModalManager';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import { ClearanceTechniqueEditModal } from './ClearanceTechniquesEditModal';
import { triggerClearanceTechniquesFetch, deleteClearanceTechnique } from '~/redux/clearanceTechniques/actions';
import { useAppDispatch } from '~/redux/store';
import { ClearanceTechnique } from '~/types/clearanceTechnique';
import eventStopper from '~/utils/eventStopper';
import { getActiveClearanceTechniques } from '~/redux/clearanceTechniques/selectors';

export const ClearanceTechniquesList = () => {
  const dispatch = useAppDispatch();
  const modalManager = useModalManager();
  const { data: clearanceTechniques, isLoading } = useTrackedFetch({
    key: 'clearance-techniques',
    trigger: () => triggerClearanceTechniquesFetch({ force: false }),
    selector: getActiveClearanceTechniques,
  });

  const [
    selectedClearanceTechnique,
    setSelectedClearanceTechnique,
  ] = useState<Partial<ClearanceTechnique>>(null);
  const [clearanceTechniqueModalTitle, setClearanceTechniqueModalTitle] = useState<string>();

  const addTechnique = () => {
    setSelectedClearanceTechnique({
      id: nanoid(),
    });
    setClearanceTechniqueModalTitle('Add clearance technique');
  };

  const editTechnique = (clearanceTechnique: ClearanceTechnique) => {
    setSelectedClearanceTechnique(clearanceTechnique);
    setClearanceTechniqueModalTitle('Edit clearance technique');
  };

  const deleteTechnique = (clearanceTechniqueId) => {
    dispatch(deleteClearanceTechnique({
      id: clearanceTechniqueId,
    }));
  };

  return (
    <VStack align="stretch">
      <ExpandableCountSection
        title="Clearance techniques"
        icon={<Icon boxSize={8} as={ClearanceIcon} />}
        count={clearanceTechniques?.length || 0}
      >
        <Table size="md">
          <Tbody>
            {isLoading ? (
              <Tr>
                <Td><Skeleton height={8} /></Td>
                <Td><Skeleton height={8} /></Td>
                <Td />
              </Tr>
            )
              : (orderBy(clearanceTechniques, (t) => t.name).map((tr) => (
                <Tr
                  key={tr.id}
                  _hover={{ bg: '#fafafa', cursor: 'pointer' }}
                  transition="color 0.1s ease-out"
                  role="group"
                  onClick={() => editTechnique(tr)}
                >
                  <Td>
                    <Text pl={24}>
                      {tr.name}
                    </Text>
                  </Td>

                  <Td textAlign="end" {...eventStopper()}>
                    <KebabOptionsMenu>
                      <MenuItem onClick={() => editTechnique(tr)}>Edit</MenuItem>
                      <MenuItem
                        onClick={() => {
                          modalManager.open(ConfirmationDialog, {
                            title: 'Delete clearance technique',
                            children: 'This will remove the clearance technique from Magnetize, but will not remove it from any existing logs.',
                            onConfirm: () => deleteTechnique(tr.id),
                            confirmButtonLabel: 'Delete',
                          });
                        }}
                      >
                        Delete
                      </MenuItem>
                    </KebabOptionsMenu>
                  </Td>
                </Tr>
              )))}
          </Tbody>
        </Table>
        <LinkButton ml={24} mt={6} isGreen noUnderline onClick={addTechnique}>
          + add clearance technique
        </LinkButton>
        {!!selectedClearanceTechnique
      && (
      <ClearanceTechniqueEditModal
        title={clearanceTechniqueModalTitle}
        onClose={() => {
          setSelectedClearanceTechnique(null);
        }}
        clearanceTechnique={selectedClearanceTechnique}
      />
      )}
      </ExpandableCountSection>
    </VStack>
  );
};
