import {
  Box, Flex, HStack, Text, VStack,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { makeTransparent } from '~/theme';
import { MAGNETIZE_COLOURS } from '~/theme/constants';

interface DepthLogProps {
  backfillImageId: string;
  hasPiezo: boolean;
  hasInclo: boolean;
  isEndOfHole: boolean;
  isPiezoStartOrEnd: boolean;
  isIncloEnd: boolean;
  depth: number;
  children: ReactNode;
}

export const DepthLayer = ({
  backfillImageId, depth, children, hasPiezo, hasInclo, isEndOfHole, isPiezoStartOrEnd, isIncloEnd,
} : DepthLogProps) => {
  const incloColor = makeTransparent(MAGNETIZE_COLOURS['extra-inclo'], 0.4);

  const isStartOfHole = depth === 0;
  const isStartOrEnd = isStartOfHole || isEndOfHole;

  let crossBar = (
    <Box
      borderColor="black"
      backgroundColor="black"
      borderWidth={1}
      width={10}
      mt={5}
    />
  );

  if (isStartOrEnd) {
    crossBar = (
      <Box
        borderColor="black"
        backgroundColor="black"
        borderWidth={1}
        width="76px"
        ml="-45px"
        mt={5}
      />
    );
  } else if (isPiezoStartOrEnd || isIncloEnd) {
    crossBar = (
      <Box
        borderColor="black"
        backgroundColor="black"
        borderWidth={1}
        width="41px"
        ml="-12px"
        mt={5}
      />
    );
  }

  return (
    <Flex
      align="stretch"
      spacing={0}
      alignItems="start"
      fontWeight="semibold"
    >
      {!isEndOfHole ? (
        <>
          <Flex
            alignSelf="stretch"
            width="35px"
            mb={-7}
            mt={5}
            backgroundColor="magnetize.ui-4"
            backgroundImage={backfillImageId ? `'/images/live-geo/backfill/${backfillImageId}-bar.png'` : undefined}
          />

          <Flex
            alignSelf="stretch"
            width="8px"
            mb={-7}
            mt={5}
            position="relative"
            borderLeftWidth={1}
            borderColor="black"
          >
            {hasPiezo
          && (
          <Box
            position="absolute"
            width="8px"
            top={0}
            bottom={0}
            left={0}
            right={0}
            backgroundImage="/images/live-geo/piezo-bg.png"
            backgroundRepeat="repeat-y"
          />
          )}
            {hasInclo
          && <Box position="absolute" top={0} bottom={0} left={0} right={0} backgroundColor={incloColor} />}
          </Flex>

          <Flex
            borderColor="black"
            backgroundColor="black"
            alignSelf="stretch"
            borderWidth={2}
            mb={-7}
            mt={5}
          />
        </>
      ) : (
        <Flex
          alignSelf="stretch"
          width="45px"
        />
      )}
      {crossBar}
      <HStack
        backgroundColor="magnetize.ui-2"
        p={2}
        flex={1}
        ml={1}
        align="stretch"
        spacing={4}
      >
        <Text fontWeight="semibold">
          {depth}
          m
        </Text>
        <VStack align="stretch" alignItems="start">
          {children}
        </VStack>
      </HStack>
    </Flex>
  );
};
