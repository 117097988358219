import {
  eachDayOfInterval, getTime, isToday,
} from 'date-fns';
import { isWeekend, startOfDay, toDate } from 'date-fns/esm';
import React, { useMemo } from 'react';
import { endOfDaySeconds, getIsoDate } from '~/utils/calendarHelpers';
import mapRange from '~/utils/mapRange';

export interface CalendarContextValue {
  start: Date;
  end: Date;
  days: CalendarDay[];

  isIntervalWithinView(interval: Interval): boolean;
  getDateFromPx(clientX: number): Date | undefined;
}

export interface CalendarDay {
  // An ISO8601 representation of this date that can be used as a persistent key
  // for this day.
  id: string;
  start: Date;
  end: Date;
  isToday?: boolean;
  isWeekend?: boolean;
  isHoliday?: boolean;
}

export const CalendarContext = React.createContext<CalendarContextValue>(undefined);

export const makeCalendarContextValue = (
  start: Date,
  end: Date,
  holidays: Date[],
  calendarElementRef: React.MutableRefObject<Element>,
) => {
  const actualStart = startOfDay(start);
  const actualEnd = endOfDaySeconds(end);

  const getDateFromPx = (clientX: number): Date => {
    const rect = calendarElementRef.current?.getBoundingClientRect();
    if (!rect) {
      return undefined;
    }

    return toDate(
      mapRange(
        clientX,
        [rect.left, rect.right],
        [actualStart.getTime(), actualEnd.getTime()],
      ),
    );
  };

  return ({
    start: actualStart,
    end: actualEnd,
    days: eachDayOfInterval({ start: actualStart, end: actualEnd }).map((d) => ({
      id: getIsoDate(d),
      start: d,
      end: endOfDaySeconds(d),
      isHoliday: holidays.some((h) => h.getTime() === d.getTime()),
      isToday: isToday(d),
      isWeekend: isWeekend(d),
    })),
    getDateFromPx,
    isIntervalWithinView: (interval) => (
      getTime(interval.start) <= getTime(actualEnd) && getTime(interval.end) >= getTime(actualStart)
    ),
  });
};

interface CalendarContextProps {
  start: Date;
  end: Date;
  holidays?: Date[];
  children?: React.ReactNode;
  calendarElementRef: React.MutableRefObject<Element>;
}

export const CalendarContextProvider = ({
  start, end, holidays = [], children, calendarElementRef,
}: CalendarContextProps) => {
  const contextValue = useMemo<CalendarContextValue>(
    () => makeCalendarContextValue(start, end, holidays, calendarElementRef),
    [start?.getTime(), end?.getTime(), calendarElementRef],
  );
  return (
    <CalendarContext.Provider value={contextValue}>
      {children}
    </CalendarContext.Provider>
  );
};
