import { createReducer } from '@reduxjs/toolkit';
import { now } from 'lodash';
import { customerTenantFetchError, customerTenantFetchStart, customerTenantFetchSuccess } from '~/redux/customerTenant/actions';
import { FetchState } from '~/types/fetchState';
import { Dictionary } from '~/types/helpers';
import { Tenant } from '~/types/tenant';

export type TenantState = FetchState<Tenant>;

export const customerTenantReducer = createReducer(
  {} as Dictionary<TenantState>,
  (builder) => (builder
    .addCase(customerTenantFetchSuccess, (state, action) => {
      const { token, data } = action.payload;
      return {
        ...state,
        [token]: {
          ...state[token],
          status: 'success',
          data,
          isInvalid: false,
          lastUpdatedAt: now(),
        },
      };
    })

    .addCase(customerTenantFetchError, (state, action) => {
      const { token, errorMessage } = action.payload;
      return {
        ...state,
        [token]: {
          ...state[token],
          status: 'error',
          errorMessage,
        },
      };
    })

    .addCase(customerTenantFetchStart, (state, action) => {
      const { token } = action.payload;
      return {
        ...state,
        [token]: {
          ...state[token],
          status: 'loading',
        },
      };
    })
  ),
);
