import { createReducer } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';
import { InvoiceActions } from '~/redux/invoices/actions';
import { Dictionary } from '~/types/helpers';
import { Invoice } from '~/types/invoice';

export type InvoiceState = {
  invoicesById: Dictionary<Invoice>,
  invoicesByJobId: Dictionary<string[]>
};

export const invoicesReducer = createReducer<InvoiceState>(
  {
    invoicesById: {},
    invoicesByJobId: {},
  },

  (builder) => {
    builder.addCase(InvoiceActions.invoicesUpdated, (state, action) => ({
      ...state,
      invoicesById: {
        ...state.invoicesById,
        ...keyBy(action.payload.invoices, (inv) => inv.id),
      },
    }));

    builder.addCase(InvoiceActions.jobInvoicesUpdated, (state, action) => ({
      ...state,
      invoicesByJobId: {
        ...state.invoicesByJobId,
        [action.payload.jobId]: action.payload.invoiceIds ?? [],
      },
    }));
    builder.addCase(InvoiceActions.discardDraft, (state, action) => ({
      ...state,
      invoicesById: {
        ...keyBy(
          Object.values(state.invoicesById)
            .filter((inv) => inv.id !== action.payload.data.invoiceId),
          (inv) => inv.id,
        ),
      },
      invoicesByJobId: {
        ...state.invoicesByJobId,
        [action.payload.data.jobId]: state.invoicesByJobId[action.payload.data.jobId]
          .filter((id) => id !== action.payload.data.invoiceId),
      },
    }));
  },
);
