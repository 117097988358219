import {
  Box,
  Button, FormControl, FormLabel, Grid, Icon, Input, Modal, ModalBody,
  ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay,
  NumberInput, NumberInputField, Text,
} from '@chakra-ui/react';
import { ArrowForward } from '@material-ui/icons';
import { format } from 'date-fns';
import { sumBy } from 'lodash';
import React, { useState } from 'react';
import ConsumablesActions from '~/redux/consumables/actions';
import { selectCurrentUser } from '~/redux/currentUser/selectors';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import { SummaryConsumableRecord } from '~/types/consumable';

interface JobConsumableViewModel extends SummaryConsumableRecord {
  jobId: string;
}

export const EditConsumableTotalModal = ({
  consumable, onClose,
}: {
  consumable: JobConsumableViewModel,
  onClose: () => void;
}) => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => selectCurrentUser(state));
  const currentTotal = consumable.entries
    ? sumBy(Object.values(consumable.entries), (e) => e.quantity)
    : 0;
  const [quantityString, setQuantityString] = useState(currentTotal.toString());
  const quantity = Number(quantityString);

  const isValid = !Number.isNaN(quantity);
  const date = format(new Date(), 'yyyy-MM-dd');
  const delta = quantity - currentTotal;

  const quantityToday = consumable.entries?.[`${currentUser.staff.id}|${date}`]?.quantity || 0;

  const handleSaveClicked = () => {
    dispatch(ConsumablesActions.setForDate({
      jobId: consumable.jobId,
      id: consumable.id || `${consumable.jobId}_${consumable.name}`,
      setForDate: {
        name: consumable.name,
        priceId: consumable.priceId,
        unit: consumable.unit,
        staffId: currentUser.staff.id,
        date,
        quantity: delta + quantityToday,
      },
    }));
    onClose();
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen
      onClose={onClose}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {`Log ${consumable.name}`}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Grid gridTemplateColumns="1fr 1fr 1fr" columnGap="2" rowGap="8">
            <FormControl>
              <FormLabel>Total</FormLabel>
              <Input value={currentTotal} disabled />
            </FormControl>
            <Box mt={7} alignItems="center" justifyContent="center" textAlign="center">
              <Icon as={ArrowForward} fontSize={20} />
              <Text fontWeight="bold" fontSize={14} color={delta >= 0 ? 'green.500' : 'red.500'}>{`${delta >= 0 ? '+ ' : '- '}${Math.abs(delta)}`}</Text>
            </Box>
            <FormControl>
              <FormLabel>New total</FormLabel>
              <NumberInput
                onFocus={(e) => {
                  e.target.select();
                }}
                onChange={(e) => {
                  setQuantityString(e);
                }}
                value={quantityString}
              >
                <NumberInputField textAlign="left" autoFocus />
              </NumberInput>
            </FormControl>
          </Grid>

        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="brandDark"
            isLoading={false}
            disabled={!isValid}
            onClick={handleSaveClicked}
          >
            Save
          </Button>
          <Button variant="ghost" onClick={onClose}>cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
