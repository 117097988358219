import {
  Box, Button, Flex, Icon, Image, Stack, Link,
} from '@chakra-ui/react';
import {
  ChevronLeft, ChevronRight, Event, HelpOutline, SettingsOutlined,
} from '@material-ui/icons';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ReactComponent as DollarCircle } from '~/assets/icon-dollar-circle.svg';
import { ReactComponent as JobsList } from '~/assets/icon-jobs-list.svg';
import { ReactComponent as SnowPlow } from '~/assets/icon-snowplow.svg';
import { ReactComponent as UserGroup } from '~/assets/icon-user-group.svg';
import { ReactComponent as UserHelmet } from '~/assets/icon-user-helmet.svg';
import { ReactComponent as LogoDark } from '~/assets/logo-dark.svg';
import CollapsibleText from '~/components/SideNav/CollapsibleText';
import SecondaryNavItem from '~/components/SideNav/SecondaryNavItem';
import { magnetizeDocsUrl, magnetizeWebsiteUrl } from '~/helpers/config';
import { useCheckRole } from '~/hooks/useCheckRole';
import Routes from '~/pages/routes';
import { selectCurrentUser, selectIsTenantActive } from '~/redux/currentUser/selectors';
import { useAppSelector } from '~/redux/store';
import { CognitoGroup } from '~/types/cognitoUser';
import NavItem from './NavItem';

interface SideNavProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const SideNav = ({ isOpen, setIsOpen } : SideNavProps) => {
  const currentUser = useAppSelector((state) => selectCurrentUser(state));
  const isTenantActive = useAppSelector(selectIsTenantActive);

  const nav = [
    {
      title: 'Calendar',
      path: '/calendar',
      icon: Event,
    },
    {
      title: 'Jobs',
      path: '/jobs',
      requiredGroup: CognitoGroup.Admin,
      icon: JobsList,
    },
    {
      title: 'Equipment',
      path: '/equipment',
      requiredGroup: CognitoGroup.Admin,
      icon: SnowPlow,
    },
    {
      title: 'Team',
      path: '/staff',
      requiredGroup: CognitoGroup.Admin,
      icon: UserHelmet,
    },
    {
      title: 'Customers',
      path: '/customers',
      requiredGroup: CognitoGroup.Admin,
      icon: UserGroup,
    },
    {
      title: 'Finance',
      path: '/price-book',
      requiredGroup: CognitoGroup.Admin,
      icon: DollarCircle,
    },
  ];

  const userGroups = currentUser?.cognito.groups ?? [];
  const filteredNav = nav
    .filter((link) => (!link.requiredGroup || userGroups.includes(link.requiredGroup)));

  const canCreateJob = useCheckRole(CognitoGroup.Admin);

  return (
    <Box
      bg="brand.400"
      backgroundImage="url('/images/nav-bg.png')"
      backgroundPosition="top"
      backgroundRepeat="no-repeat"
      backgroundSize="auto"
      role="group"
    >
      <Box
        position="absolute"
        top="70px"
        right="-14px"
        transition="opacity 0.3s"
        _groupHover={{
          opacity: 1,
        }}
        zIndex="1"
        opacity={0}
        borderRadius="50%"
        backgroundColor="black"
        width="30px"
        height="30px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        boxShadow="lg"
        cursor="pointer"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <Icon
          color="white"
          fontSize={20}
          as={isOpen ? ChevronLeft : ChevronRight}
        />
      </Box>
      <Flex
        direction="column"
        height="100vh"
        color="white"
        fontFamily="heading"
        fontSize="sm"
      >
        <Box
          ml="35px"
          justifyContent="center"
          marginTop={35}
          marginBottom={2}
          transition="opacity 0.5s"
          opacity={isOpen ? 1 : 0}
        >
          <Image width={150} src="/images/text-logo.png" />
        </Box>
        <Flex px={isOpen ? 12 : 4} transition="all 0.5s" my={8}>
          {canCreateJob && (
            <Button
              py={7}
              fontFamily="body"
              borderRadius="full"
              bg="rgba(53,155,75, 0.7)"
              _hover={{
                bg: 'magnetize.brand-4',
              }}
              _focus={{
                borderWidth: 0,
              }}
              transition="width 0.5s"
              boxShadow="md"
              as={RouterLink}
              to={Routes.newJob}
              display="flex"
              justifyContent="center"
              alignItems="center"
              disabled={!isTenantActive}
              tabIndex={!isTenantActive ? -1 : undefined}
              _disabled={{
                opacity: 0.5,
                pointerEvents: 'none',
              }}
            >
              <Icon
                height="18px"
                width="18px"
                as={LogoDark}
              />
              <CollapsibleText
                isOpen={isOpen}
                pl={isOpen ? 3 : 0}
                letterSpacing="0.07rem"
                fontSize="1.15rem"
              >
                New Job
              </CollapsibleText>
            </Button>
          )}
        </Flex>
        <Box flexGrow={1} overflowX="auto">
          <Stack spacing={0}>
            {filteredNav.map((item) => (
              <NavItem
                isOpen={isOpen}
                icon={item.icon}
                key={item.path}
                path={item.path}
                title={item.title}
                isDisabled={!isTenantActive}
              />
            ))}
          </Stack>
          <Stack spacing={0}>
            <SecondaryNavItem
              icon={SettingsOutlined}
              isOpen={isOpen}
              path="/settings"
              title="Org settings"
              isDisabled={!canCreateJob}
            />
            <SecondaryNavItem
              icon={HelpOutline}
              isOpen={isOpen}
              target="_blank"
              href={magnetizeDocsUrl}
              title="Support"
              isDisabled={!canCreateJob}
            />
          </Stack>

        </Box>
        <Box
          marginBottom={6}
          paddingLeft={12}
          opacity={0.4}
        >
          <Stack spacing={0} mb={10}>
            <CollapsibleText fontSize="xs" isOpen={isOpen}>
              <Link as={RouterLink} to={Routes.privacyPolicy({})}>Privacy Policy</Link>
              {' '}
              &bull;
              {' '}
              <Link href={`${magnetizeWebsiteUrl}eula/`}>Terms of Use</Link>
            </CollapsibleText>
            <CollapsibleText fontSize="xs" isOpen={isOpen}>
              Copyright &copy;
              {` ${new Date().getFullYear()} `}
              Magnetize Ltd.
            </CollapsibleText>
            <CollapsibleText fontSize="xs" isOpen={isOpen}>
              All Rights Reserved
            </CollapsibleText>
          </Stack>
        </Box>
      </Flex>
    </Box>
  );
};

export default SideNav;
