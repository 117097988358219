import { createReducer } from '@reduxjs/toolkit';
import { startOfDay } from 'date-fns';
import JobCalendarActions from '~/redux/jobCalendar/jobCalendar';
import { DateRangeMode } from '~/components/Calendar/getDateRange';
import { JobStatusId } from '~/types/job';
import { getIsoDate } from '~/utils/calendarHelpers';

interface JobCalendarState {
  focusedDay: string;
  rangeMode: DateRangeMode;
  filterStatuses: JobStatusId[] | null;
}

const jobCalendarReducer = createReducer<JobCalendarState>(
  {
    focusedDay: getIsoDate(startOfDay(Date.now())),
    rangeMode: 'week',
    filterStatuses: null,
  },
  (builder) => {
    builder.addCase(JobCalendarActions.move, (state, action) => ({
      ...state,
      focusedDay: action.payload.focusedDate,
    }));

    builder.addCase(JobCalendarActions.setRangeMode, (state, action) => ({
      ...state,
      rangeMode: action.payload.rangeMode,
    }));

    builder.addCase(JobCalendarActions.setFilterStatuses, (state, action) => ({
      ...state,
      filterStatuses: action.payload.statuses,
    }));
  },
);

export default jobCalendarReducer;
