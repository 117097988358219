import {
  Box, Tab, TabList, Tabs, Text,
} from '@chakra-ui/react';
import { isFinite } from 'lodash';
import React, { useMemo } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router';

interface TabProps {
  title: string;
  isDisabled?: boolean;
  path?: string;
  count?: number;
}

interface PageTabsProps {
  tabs: TabProps[];
  index?: number;
  onChange?: (tabIndex : number) => void;
  children?: React.ReactNode;
  isDisabled?: boolean;
  isLoading?: boolean;
}

const PageTabs = (
  {
    children, tabs, index, onChange, isDisabled, isLoading,
  }: PageTabsProps,
) => {
  const location = useLocation();
  const history = useHistory();
  const selectedTabByUrl = useMemo(() => (
    tabs.findIndex((t) => t.path && !!matchPath(location.pathname, { path: t.path }))
  ), [location.pathname]);

  const selectedTabIndex = selectedTabByUrl >= 0 ? selectedTabByUrl : index;
  const onTabChanged = (idx: number) => {
    if (tabs[idx]?.path) {
      history.replace(tabs[idx].path);
    }
    onChange?.(idx);
  };

  return (
    <Tabs
      isLazy
      isManual
      index={isDisabled ? null : selectedTabIndex}
      colorScheme="green"
      onChange={onTabChanged}
    >
      <TabList borderBottomColor="transparent">
        {tabs.map(
          (tab, i) => (tab.title === 'Archived' ? (
            <Tab
              fontSize="14px"
              color="gray.400"
              isDisabled={isDisabled || tab.isDisabled}
              key={tab.title}
            >
              {tab.title}

              {isFinite(tab.count) && (
                <Text
                  color={i !== selectedTabIndex ? 'gray.400' : undefined}
                  pl={1}
                >
                  (
                  {isLoading && '..'}
                  {!isLoading && tab.count > 99 && '99+'}
                  {!isLoading && tab.count <= 99 && tab.count}
                  )
                </Text>
              )}

            </Tab>
          ) : (
            <Tab
              fontSize="14px"
              isDisabled={isDisabled || tab.isDisabled}
              key={tab.title}
            >
              {tab.title}

              {isFinite(tab.count) && (
                <Text
                  color={i !== selectedTabIndex ? 'gray.400' : undefined}
                  pl={1}
                >
                  (
                  {isLoading && '..'}
                  {!isLoading && tab.count > 99 && '99+'}
                  {!isLoading && tab.count <= 99 && tab.count}
                  )
                </Text>
              )}

            </Tab>
          )),
        )}
        <Box ml="auto" display="flex" alignItems="center">
          {children}
        </Box>
      </TabList>
    </Tabs>
  );
};
export default PageTabs;
