import {
  Flex,
  GridItem, Icon, Spacer, Text, VStack,
} from '@chakra-ui/react';
import { People } from '@material-ui/icons';
import React, { useMemo } from 'react';
import { FaSnowplow } from 'react-icons/fa';
import { CalendarDay } from '~/components/JobCalendar/MonthCalendar';
import JobPopover from '~/components/JobCalendar/MonthCalendar/jobPopover';
import { makeTransparent } from '~/theme';

interface ResourceBarProps {
  type: 'staff' | 'equipment';
  count: number;
  total: number;
  hideTotal?: boolean;
}

const ResourceBar = ({
  type, count, total, hideTotal,
} : ResourceBarProps) => {
  const percentFull = hideTotal ? 1 : (count / Math.max(total, 1));
  const bgColor = useMemo(() => {
    if (percentFull >= (2 / 3)) {
      return makeTransparent('#359B4B', 0.1);
    }
    if (percentFull >= (1 / 3)) {
      return makeTransparent('#3AC6AA', 0.05);
    }
    return makeTransparent('#CCCCCC', 0.2);
  }, [percentFull]);

  return (
    <Flex
      bg={bgColor}
      p={1}
      flex={1}
      borderRadius="sm"
      justifyContent="center"
      alignItems="center"
    >
      {type === 'staff'
        ? <Icon fontSize="1.4em" ml="0.15em" as={People} />
        : <Icon as={FaSnowplow} fontSize="1.3em" ml="0.15em" />}
      <Text fontWeight="bold" ml={2}>
        {count}
      </Text>
      {!hideTotal && (
      <Text ml={1}>
        {`/${total}`}
      </Text>
      )}
    </Flex>
  );
};

interface MonthCalendarDayProps {
  day: CalendarDay;
  hideTotals?: boolean;
}

const MonthCalendarDay = ({ day, hideTotals } : MonthCalendarDayProps) => {
  let bg = 'magnetize.ui-2';
  if (day.isWeekend || day.isHoliday) {
    bg = 'magnetize.ui-3';
  }
  if (day.isToday) {
    bg = makeTransparent('#359B4B', 0.05);
  }
  return (
    <>
      <GridItem
        minH={hideTotals ? '100px' : '121px'}
        bg={bg}
        borderColor="magnetize.ui-4"
        borderRightWidth={1}
        borderBottomWidth={1}
      >
        <VStack fontSize="12px" align="stretch">
          <Spacer />
          <Text
            textAlign="center"
            fontWeight="bold"
            color={day.isFocusedMonth ? 'magnetize.text-1' : 'magnetize.text-4'}
          >
            {day.title}
          </Text>
          {day.jobs.length > 0 && (
          <VStack
            opacity={(day.isPast && !day.isToday)
            || (!day.isWorkDay && day.staffCount + day.equipmentCount === 0) ? 0.5 : 1}
            align="stretch"
            px={2}
          >
            <ResourceBar
              hideTotal={hideTotals}
              type="staff"
              count={day.staffCount}
              total={day.totalStaffCount}
            />
            <ResourceBar
              hideTotal={hideTotals}
              type="equipment"
              count={day.equipmentCount}
              total={day.totalEquipmentCount}
            />
            {!hideTotals && <JobPopover date={day.date} jobs={day.jobs} />}
          </VStack>
          )}
          <Spacer />
        </VStack>
      </GridItem>
    </>
  );
};

export default MonthCalendarDay;
