import { createAction } from '@reduxjs/toolkit';
import { WelcomeModalState } from '~/redux/welcomeModal/reducer';

const WelcomeModalActions = {
  incrementStep: createAction('welcomeModal/increment'),
  setStep: createAction<{ step: number }>('welcomeModal/setStep'),
  update: createAction<{ update: Partial<WelcomeModalState> }>('welcomeModal/update'),
  complete: createAction('welcomeModal/complete'),
};

export default WelcomeModalActions;
