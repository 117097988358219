import {
  Button, Checkbox, Container, Flex, FormControl, FormLabel, Grid,
  Heading, Icon, Input, Spinner, Text, VStack,
} from '@chakra-ui/react';
import { Check } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { MutationStatus, useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import LoginBackground from '~/components/LoginBackground';
import LoginCard from '~/components/LoginCard';
import PasswordInput from '~/components/PasswordInput';
import { PrivacyPolicyLink, TermsOfUseLink } from '~/components/SupportLinks';
import WarningLabel from '~/components/WarningLabel';
import { completeNewPassword, forgotPasswordSubmit } from '~/helpers/auth';
import PasswordFormControl from '~/pages/Authentication/components/PasswordFormControl';
import { currentUserActions } from '~/redux/currentUser/actions';
import { useAppDispatch } from '~/redux/store';

const AnticipationScreen = () => (
  <LoginBackground>
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="center"
      boxShadow="md"
      borderRadius="md"
      bgColor="white"
      minW="425px"
    >
      <VStack
        spacing={6}
        mt={-8}
      >
        <Spinner
          color="magnetize.brand-4"
          speed="0.65s"
          w={16}
          h={16}
        />
        <Heading pt={12} textTransform="none">Getting set up</Heading>
        <Text fontSize="16px">this may take just a moment!</Text>
      </VStack>
    </Flex>
  </LoginBackground>
);

export default () => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState('');
  const [temporaryPassword, setTemporaryPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [newPasswordRepeat, setNewPasswordRepeat] = useState('');
  const [showAnticipation, setShowAnticipation] = useState(false);
  // This screen supports both the 'forgot password' workflow
  // and the 'complete new password' workflow
  const [hasForgotPassword, setHasForgotPassword] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false);

  const submitDisabled = !email || !temporaryPassword
  || !newPassword || newPassword !== newPasswordRepeat
   || !passwordIsValid || (!hasForgotPassword && !hasAgreedToTerms);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    setEmail(query.get('email') ?? '');
    setTemporaryPassword(query.get('temporary-password') ?? '');
    setHasForgotPassword(query.get('action') === 'forgot-password');
  }, [location]);

  const { status, mutate, error }
  : { status: MutationStatus, mutate: any, error: Error } = useMutation(
    hasForgotPassword ? forgotPasswordSubmit : completeNewPassword, {
      onSuccess: () => {
        if (isMobile) {
          history.replace('/mobile-redirect?passwordUpdated=true');
        } else if (hasForgotPassword) {
          dispatch(currentUserActions.signIn({ username: email, password: newPassword }));
        } else {
          setShowAnticipation(true);
          setTimeout(() => {
            dispatch(currentUserActions.signIn({ username: email, password: newPassword }));
          }, 5000);
        }
      },
    },
  );

  if (showAnticipation) {
    return <AnticipationScreen />;
  }

  const isLoading = status === 'loading';

  return (
    <LoginBackground>
      <LoginCard title={`${hasForgotPassword ? 'Reset' : 'Set new'} password`} showBackButton>
        <Grid
          gap={6}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !isLoading) {
              mutate({ username: email, temporaryPassword, newPassword });
            }
          }}
        >
          {error && <WarningLabel message={error?.message} />}
          {hasForgotPassword && (
          <Text pl={0} maxWidth="290px">
            A verification code has been emailed to you.
          </Text>
          )}
          {hasForgotPassword && (
          <FormControl>
            <FormLabel>Verification Code</FormLabel>
            <Input
              value={temporaryPassword}
              onChange={(e) => { setTemporaryPassword(e.target.value); }}
            />
          </FormControl>
          )}
          <PasswordFormControl
            password={newPassword}
            onChange={(password, isValid) => {
              setNewPassword(password);
              setPasswordIsValid(isValid);
            }}
          />
          <FormControl>
            <FormLabel>Confirm Password</FormLabel>
            <PasswordInput
              isDisabled={isLoading}
              value={newPasswordRepeat}
              onChange={(e) => { setNewPasswordRepeat(e.target.value); }}
            />
            {newPassword && newPassword === newPasswordRepeat && (
              <Flex mt={2}>
                <Icon mr={1} color="magnetize.text-4" as={Check} />
                <Text color="magnetize.text-4">
                  passwords match
                </Text>
              </Flex>
            )}
          </FormControl>
          {!hasForgotPassword && (
          <FormControl>
            <Checkbox
              isChecked={hasAgreedToTerms}
              onChange={(e) => setHasAgreedToTerms(e.target.checked)}
            >
              <Container
                maxW="md"
                pl={0}
                pt={4}
                cursor="pointer"
              >
                I have read and agree to the
                {' '}
                <TermsOfUseLink />
                {' '}
                and
                {' '}
                <PrivacyPolicyLink />
                .
              </Container>
            </Checkbox>
          </FormControl>
          )}
          <Flex>
            <Button
              isDisabled={submitDisabled}
              isLoading={isLoading}
              size="lg"
              onClick={() => { mutate({ username: email, temporaryPassword, newPassword }); }}
            >
              {hasForgotPassword ? 'Reset' : 'Set'}
              {' '}
              password
            </Button>
          </Flex>
        </Grid>
      </LoginCard>
    </LoginBackground>
  );
};
