import { Flex } from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import { sumBy } from 'lodash';
import React from 'react';
import AddIgnoreButtons from './AddIgnoreButtons';
import QuoteLineItemCard from './QuoteLineItemCard';
import { LineItem } from '~/types/lineItem';

const QuotedOnlyReconciliationRow = (
  {
    quoted, alreadyInvoiced, isDisabled, onItemAdded, onItemIgnored,
  } : {
    quoted: LineItem[];
    alreadyInvoiced: LineItem[];
    onItemAdded: (lineItem: LineItem) => void;
    onItemIgnored: () => void;
    isDisabled: boolean;
  },
) => {
  const totalQuotedQuantity = sumBy(quoted, (q) => q.quantity);
  const totalAlreadyInvoicedQuantity = sumBy(alreadyInvoiced, (q) => q.quantity);

  if (totalQuotedQuantity <= totalAlreadyInvoicedQuantity) return null;
  return (
    <Flex>
      <QuoteLineItemCard
        name={quoted[0].name}
        unitPriceCents={quoted[0].unitPriceCents}
        unit={quoted[0].unit}
        quotedQuantity={totalQuotedQuantity}
        alreadyInvoicedQuantity={totalAlreadyInvoicedQuantity}
      />
      <Flex mx={6} alignItems="center" justifyContent="center">
        <AddIgnoreButtons
          isDisabled={isDisabled}
          onAddClick={() => {
            const quantity = Math.max(totalQuotedQuantity - totalAlreadyInvoicedQuantity, 0);
            if (quantity > 0) {
              onItemAdded({
                id: nanoid(),
                type: 'price',
                name: quoted[0].name,
                order: 0,
                description: quoted[0].description,
                unitPriceCents: quoted[0].unitPriceCents,
                priceId: quoted[0].priceId,
                quantity,
              });
            } else {
              onItemIgnored();
            }
          }}
          onIgnoreClick={onItemIgnored}
        />
      </Flex>
      <Flex flex={1} px={4} />
    </Flex>
  );
};

export default QuotedOnlyReconciliationRow;
