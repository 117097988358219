import {
  FormControl, FormLabel, HStack, Input,
} from '@chakra-ui/react';
import React from 'react';
import AutoSizingTextarea from '~/components/AutoSizingTextarea';
import useDebouncedState from '~/hooks/useDebouncedState';
import { updateJob } from '~/redux/jobs/actions';
import { useAppDispatch } from '~/redux/store';
import { Job } from '~/types/job';

const JobDetails = ({ job }: { job: Job }) => {
  const dispatch = useAppDispatch();

  const [name, setName] = useDebouncedState({
    value: job.name,
    dispatchUpdate: (n) => { dispatch(updateJob({ id: job.id, name: n })); },
  });

  const [referenceNumber, setReferenceNumber] = useDebouncedState({
    value: job.referenceNumber,
    dispatchUpdate: (r) => { dispatch(updateJob({ id: job.id, referenceNumber: r })); },
  });

  const [description, setDescription, flushDescription] = useDebouncedState({
    value: job.description,
    dispatchUpdate: (d) => { dispatch(updateJob({ id: job.id, description: d })); },
  });

  return (
    <>
      <HStack spacing={2}>
        <FormControl flex="2" isRequired>
          <FormLabel fontWeight="bold">Job name</FormLabel>
          <Input
            placeholder="What's the name of the job?"
            value={name ?? ''}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </FormControl>
        <FormControl flex="1">
          <FormLabel fontWeight="bold">Ref #</FormLabel>
          <Input
            placeholder="e.g. PO-001"
            value={referenceNumber ?? ''}
            onChange={(e) => {
              setReferenceNumber(e.target.value);
            }}
          />
        </FormControl>
      </HStack>
      <FormControl flex="2">
        <FormLabel fontWeight="bold">Job Notes</FormLabel>
        <AutoSizingTextarea
          value={description ?? ''}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="What does the customer need?"
          onBlur={flushDescription}
        />
      </FormControl>
    </>
  );
};

export default JobDetails;
