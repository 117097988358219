import {
  Box, Icon,
  MenuItem,
  Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure,
} from '@chakra-ui/react';
import { AddOutlined } from '@material-ui/icons';
import { format } from 'date-fns/esm';
import { isEqual, round, sortBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import ConfirmationDialog from '~/components/ConfirmationDialog';
import { KebabOptionsMenu } from '~/components/KebabOptionsMenu';
import LinkButton from '~/components/LinkButton';
import { useModalManager } from '~/components/ModalManager';
import { EditConsumableEntryForDateModal } from '~/pages/Job/components/logs/JobConsumablesTable/EditConsumableEntryForDateModal';
import { EditConsumableTotalModal } from '~/pages/Job/components/logs/JobConsumablesTable/EditConsumableTotalModal';
import PriceBookBrowseModal from '~/pages/Job/components/PriceBook/PriceBookBrowseModal';
import ConsumablesActions from '~/redux/consumables/actions';
import { selectConsumablesForJob, selectQuoteConsumables, selectBudgetConsumables } from '~/redux/consumables/selectors';
import { selectCurrentUser } from '~/redux/currentUser/selectors';
import { selectPriceById } from '~/redux/prices/selectors';
import { selectStaffName } from '~/redux/staff/selectors';
import { selectQuotesByJobId } from '~/redux/quote/selectors';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import { SummaryConsumableRecord } from '~/types/consumable';
import { LineItem } from '~/types/lineItem';
import eventStopper from '~/utils/eventStopper';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import { triggerJobFetch } from '~/redux/jobs/actions';
import { selectJobWithEdits } from '~/redux/jobs/selectors';

interface JobConsumableViewModel extends SummaryConsumableRecord {
  key: string;
  jobId: string;
  allocated: number | undefined;
  isFromQuote?: boolean;
}

interface ConsumableTableRowProps {
  consumable: JobConsumableViewModel;
}

const ConsumableEntryRow = (
  { entry, consumable }
  : {
    entry:{ staffId: string, date: string, quantity: number },
    consumable: JobConsumableViewModel
  },
) => {
  const name = useAppSelector((state) => selectStaffName(state, entry.staffId));
  const modalManager = useModalManager();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const dispatch = useAppDispatch();

  const friendlyDate = format(new Date(entry.date), 'dd MMM yyyy');
  return (
    <Tr
      role="group"
      _hover={{ bg: '#fafafa', cursor: 'pointer' }}
      onClick={() => setIsEditOpen(true)}
    >
      <Td display="flex" flexDirection="row">
        <Text pl="65px">{friendlyDate}</Text>
        <Text pl="20px">{name}</Text>
      </Td>
      <Td />
      <Td
        onClick={() => setIsEditOpen(true)}
        textAlign="center"
        fontWeight="regular"
      >
        {entry.quantity ? round(entry.quantity, 2) : '-'}
      </Td>
      <Td />
      <Td />
      <Td p={0} textAlign="end" {...eventStopper()}>
        <KebabOptionsMenu>
          <MenuItem onClick={() => setIsEditOpen(true)}>
            Edit
          </MenuItem>
          <MenuItem onClick={() => {
            modalManager.open(ConfirmationDialog, {
              title: `Delete ${consumable.name} log`,
              children: `The consumable log for ${name} on ${friendlyDate} will be deleted.`,
              onConfirm: () => dispatch(ConsumablesActions.deleteForDate({
                jobId: consumable.jobId,
                id: consumable.id,
                staffId: entry.staffId,
                date: entry.date,
              })),
              confirmButtonLabel: 'Delete',
            });
          }}
          >
            Delete
          </MenuItem>
        </KebabOptionsMenu>
        {isEditOpen && (
        <EditConsumableEntryForDateModal
          consumable={consumable}
          staffId={entry.staffId}
          date={entry.date}
          initialQuantity={entry.quantity}
          onClose={() => setIsEditOpen(false)}
        />
        )}
      </Td>
    </Tr>
  );
};

const ConsumableTableRow = ({ consumable }: ConsumableTableRowProps) => {
  const price = useAppSelector((state) => selectPriceById(state, consumable.priceId));

  const [isExpanded, setIsExpanded] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const modalManager = useModalManager();
  const dispatch = useAppDispatch();
  const remaining = (consumable.allocated !== undefined)
    ? round(consumable.allocated - consumable.quantity, 2)
    : undefined;

  const isOverAllocation = remaining !== undefined && remaining < 0;

  const count = Object.keys(consumable.entries || {}).length;
  const logsText = `${count} log${count !== 1 ? 's' : ''}`;
  return (
    <>
      <Tr
        fontSize="12px"
        role="group"
        _hover={{ bg: '#fafafa', cursor: 'pointer' }}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <Td>
          <LinkButton isGreen noUnderline w="60px" pl="5px" justifyContent="flex-start">
            {logsText}
          </LinkButton>
          {consumable.name}

        </Td>
        <Td
          textAlign="center"
          color={consumable.allocated === undefined ? 'magnetize.text-4' : undefined}
        >
          {consumable.allocated ? round(consumable.allocated, 2) : '0'}
        </Td>
        <Td
          onClick={() => setIsEditOpen(true)}
          textAlign="center"
          color={isOverAllocation ? 'magnetize.support-warning' : undefined}
          fontWeight={isOverAllocation ? 'semibold' : 'regular'}
        >
          {consumable.quantity ? round(consumable.quantity, 2) : '-'}
        </Td>
        <Td
          pl="0"
          textAlign="center"
          color={isOverAllocation ? 'magnetize.support-warning' : undefined}
          fontWeight={isOverAllocation ? 'semibold' : 'regular'}
        >
          {remaining ? round(remaining, 2) : '-'}
        </Td>
        <Td
          px={0}
          textAlign="center"
          color="magnetize.text-4"
        >
          {price?.unit || consumable.unit}
        </Td>
        <Td p={0} textAlign="end" {...eventStopper()}>
          <KebabOptionsMenu>
            <MenuItem onClick={() => setIsEditOpen(true)}>
              Edit total
            </MenuItem>
            <MenuItem onClick={() => {
              modalManager.open(ConfirmationDialog, {
                title: `Delete ${consumable.name}`,
                children: `The consumable and ${logsText} will be deleted from the job.`,
                onConfirm: () => dispatch(ConsumablesActions.delete({
                  jobId: consumable.jobId,
                  consumableId: consumable.id,
                })),
                confirmButtonLabel: 'Delete',
              });
            }}
            >
              Delete
            </MenuItem>
          </KebabOptionsMenu>
          {isEditOpen && (
          <EditConsumableTotalModal
            consumable={consumable}
            onClose={() => setIsEditOpen(false)}
          />
          )}
        </Td>
      </Tr>
      {isExpanded && consumable?.entries && Object.values(consumable.entries).map((entry) => (
        <ConsumableEntryRow consumable={consumable} entry={entry} key={`${entry.staffId}|${entry.date}`} />
      ))}
    </>
  );
};

const JobConsumablesTable = ({ jobId }: {
  jobId: string;
}) => {
  const quotes = useAppSelector((state) => selectQuotesByJobId(state, jobId));
  const { data: job } = useTrackedFetch({
    key: jobId,
    trigger: () => triggerJobFetch({ jobId }),
    selector: (s) => selectJobWithEdits(s, jobId),
    equalityMode: 'deep',
  });
  const isBudget = !(job?.needsQuote) && quotes?.every((q) => q.status === 'draft');

  const actualConsumables = useAppSelector(
    (s) => selectConsumablesForJob(s, jobId),
    (a, b) => isEqual(a, b),
  );
  const quoteConsumablesByKey = useAppSelector(
    (s) => (isBudget ? selectBudgetConsumables(s, jobId) : selectQuoteConsumables(s, jobId)),
    (a, b) => isEqual(a, b),
  );
  const currentUser = useAppSelector((state) => selectCurrentUser(state));

  const consumables = useMemo(() => {
    const actuals: JobConsumableViewModel[] = actualConsumables
      .filter((c) => c.entries)
      .map((c) => {
        const key = c.priceId || c.name;
        return ({
          ...c,
          ...quoteConsumablesByKey[key],
          isFromQuote: !!quoteConsumablesByKey[key],
          key,
          jobId,
        });
      });

    const quoted: JobConsumableViewModel[] = Object.entries(quoteConsumablesByKey)
      .filter(([key]) => !actuals.find((c) => c.key === key))
      .map(([key, consumable]) => ({
        ...consumable,
        quantity: 0,
        jobId,
        id: undefined,
        key,
      }));

    return sortBy(
      [...actuals, ...quoted],
      (c) => !c.isFromQuote,
      (c) => c.name,
    );
  }, [actualConsumables, quoteConsumablesByKey, jobId]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useAppDispatch();

  const addConsumables = (lineItems: LineItem[]) => {
    lineItems.forEach((li) => {
      dispatch(ConsumablesActions.setForDate({
        jobId,
        id: `${jobId}_${li.name}`,
        setForDate: {
          name: li.name,
          priceId: li.priceId,
          unit: li.unit,
          staffId: currentUser.staff.id,
          date: format(new Date(), 'yyyy-MM-dd'),
          quantity: 0,
        },
      }));
    });
  };

  return (
    <Box mx={2}>
      <Table>
        <Thead>
          <Tr>
            <Th>Consumable</Th>
            <Th w="70px" px="0" textAlign="center">{isBudget ? 'Budgeted' : 'Quoted'}</Th>
            <Th w="70px" px="0" textAlign="center">Logged</Th>
            <Th w="100px" px="0" textAlign="center">Remaining</Th>
            <Th w="70px" px="0" textAlign="center">Unit</Th>
            <Th w="30px" p="0" />
          </Tr>
        </Thead>
        <Tbody>
          {consumables.map((c) => (
            <ConsumableTableRow
              key={c.key}
              consumable={c}
            />
          ))}
        </Tbody>
      </Table>

      <LinkButton
        isGreen
        noUnderline
        onClick={() => onOpen()}
        leftIcon={<Icon fontSize="md" as={AddOutlined} />}
        mt="6"
        mb="2"
      >
        Add consumables
      </LinkButton>

      {isOpen && (
        <PriceBookBrowseModal
          titleText="Add consumables to job"
          addButtonText="Add"
          onAdd={addConsumables}
          onClose={onClose}
          filterCategories={['consumable', 'fee']}
          excludedPrices={consumables.map((c) => c.priceId).filter((i) => i)}
        />
      )}
    </Box>
  );
};

export default JobConsumablesTable;
