import {
  getTime, eachDayOfInterval, endOfDay, isSameDay, isWithinInterval, addSeconds, addDays,
} from 'date-fns';
import Holidays, { HolidaysTypes } from 'date-holidays';
import { range } from 'lodash';
import { Tenant } from '~/types/tenant';

function isDayAHoliday(day: Date, holidays: HolidaysTypes.Holiday[]) {
  // Holidays tend to be from 12 AM of the day of the holiday to 12 AM the next day.
  // So Christmas would be 25 December 2021 00:00:00 to 26 December 2021 00:00:00.
  // This trips up isWithinInterval as it believes that 26 December is a holiday.
  // 1 second is added to the day that is being checked to get around this.
  // An alternative would be to subtract a second from the end of the holiday interval.
  return holidays
    ? holidays.some((holiday) => isWithinInterval(addSeconds(day, 1), holiday))
    : false;
}

// Finds all the days that were added or removed manually from a resource's
// original schedule. This includes manually added holidays.
function getManuallyAdjustedDays(
  organisationWorkDays: number[],
  originalJobEventInterval: Interval,
  originalResourceScheduleIntervals: any[],
  holidays: HolidaysTypes.Holiday[],
) {
  // If there are no original intervals then there are no manually added or removed days.
  if (originalResourceScheduleIntervals === undefined
    || originalJobEventInterval === undefined) {
    return { manuallyAddedDays: [], manuallyExcludedDays: [] };
  }

  const manuallyAddedDays = [];
  const manuallyExcludedDays = [];

  // Loop from the earliest date in any interval to the latest date in any interval.
  // Any dates that are in an interval and are not a work day has been manually added.
  // Any dates that are not in an interval and are a work day has been manually removed.
  eachDayOfInterval(originalJobEventInterval).forEach((day) => {
    if (isDayAHoliday(day, holidays)
      && originalResourceScheduleIntervals.some((interval) => isWithinInterval(day, interval))) {
      manuallyAddedDays.push(day);
    } else if (organisationWorkDays?.includes(day.getDay())) {
      // Check if the day is a standard work day but was not in the previous intervals.
      if (originalResourceScheduleIntervals.length > 0
        && !originalResourceScheduleIntervals.some((interval) => isWithinInterval(day, interval))) {
        manuallyExcludedDays.push(day);
      }
    } else if (originalResourceScheduleIntervals.length > 0
      && originalResourceScheduleIntervals.some((interval) => isWithinInterval(day, interval))) {
      manuallyAddedDays.push(day);
    }
  });
  return { manuallyAddedDays, manuallyExcludedDays };
}

export const getHolidaysForTenant = (tenant: Tenant, dateRange: { start: Date; end: Date; }) => {
  if (!tenant?.publicHolidaysCountry) {
    return [];
  }

  const hd = tenant.publicHolidaysState
    ? new Holidays(tenant.publicHolidaysCountry, tenant.publicHolidaysState)
    : new Holidays(tenant.publicHolidaysCountry);
  const yearHolidays = hd
    .getHolidays(dateRange.start.getFullYear())
    .filter((h) => ['public', 'bank'].includes(h.type));

  return yearHolidays.filter((h) => h.start.getTime() >= dateRange.start.getTime()
    && h.start.getTime() <= dateRange.end.getTime());
};

/*
  Schedule the resource to days based on what is allowed by the organisation's
  work days settings and any holidays within the supplied interval.
  Intervals are created for spans of scheduled days.

  originalJobInterval is used to find the days that have been manually added
  or removed at either the very beginning or end of a job. Without this
  information there is no way to work this out. This is not needed when
  adding a new resource to a job.

  If originalResourceScheduleIntervals is supplied then normal work days that were
  not scheduled will continue to not be scheduled, and similarly, if a non-work day
  was scheduled then it will be scheduled. This applies to the specific dates
  in the originalIntervals. If the job has been shifted so that any of these manually
  add/removed days is no longer in range, then the normal rules for scheduling will be applied.
  This is not needed when adding a new resource to a job.
*/
export const calculateResourceEventIntervals = (
  eventInterval: Interval,
  tenant: Tenant,
  originalJobInterval?: Interval,
  originalResourceScheduleIntervals?: Interval[],
) => {
  // Without a start or end date nothing can be calculated
  if (!eventInterval.start || !eventInterval.end) {
    return [];
  }

  const startDate = eventInterval.start;
  const endDate = eventInterval.end;
  const workDays = tenant.schedulingWeekDays ?? range(7);
  const holidays = getHolidaysForTenant(tenant, {
    start: new Date(startDate),
    end: new Date(endDate),
  });
  const intervals = [];
  const dateRange = eachDayOfInterval({ start: startDate, end: endDate });
  const { manuallyAddedDays, manuallyExcludedDays } = getManuallyAdjustedDays(
    workDays,
    originalJobInterval,
    originalResourceScheduleIntervals,
    holidays,
  );
  let intervalStartDate = null;
  let previousDateAWorkDay = false;

  // Loop through each day of the supplied date range and determine if it is a work day.
  // A consecutive block of scheduled days creates an interval. A new interval is
  // created on the first work day after a non-work day.
  dateRange.forEach((currentDate) => {
    let intervalEndDate = null;
    const isStandardWorkDay = workDays.includes(currentDate.getDay());
    const isHoliday = isDayAHoliday(currentDate, holidays);
    const alwaysAddDay = manuallyAddedDays.some((day) => isSameDay(day, currentDate));
    const alwaysRemoveDay = manuallyExcludedDays.some((day) => isSameDay(day, currentDate));

    if (alwaysAddDay || (isStandardWorkDay && !isHoliday && !alwaysRemoveDay)) {
      if (!intervalStartDate) {
        intervalStartDate = new Date(currentDate);
      }

      // End the interval if we are at the end of the requested range
      if (isSameDay(currentDate, endDate)) {
        intervalEndDate = new Date(currentDate);
      }

      // We won't know that we have encountered an end of an interval until
      // after we have checked the first day of a non-work period. This
      // lets us keep track of the end of the interval.
      previousDateAWorkDay = true;
    } else {
      // We have encountered a non-work day. End the interval if needed.
      if (previousDateAWorkDay) {
        intervalEndDate = addDays(currentDate, -1);
      }
      previousDateAWorkDay = false;
    }
    if (intervalEndDate) {
      intervals.push({
        start: getTime(intervalStartDate),
        end: getTime(endOfDay(intervalEndDate)),
      });
      intervalStartDate = null;
    }
  });

  return intervals;
};

/*
  Finds the days within a range that are not in any of the supplied intervals.
  Useful for finding days where no one is scheduled.
 */
export const daysNotInIntervals = (start: Date, end: Date, intervals: Interval[]) => {
  const days = [];
  eachDayOfInterval({ start, end }).forEach((day) => {
    let foundInInterval = false;
    intervals.forEach((interval) => {
      foundInInterval = foundInInterval || isWithinInterval(day, interval);
    });

    if (!foundInInterval) {
      days.push(day);
    }
  });

  return days;
};
