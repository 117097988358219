import React, { useState } from 'react';
import {
  Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter,
  ModalHeader, ModalOverlay, FormControl, FormLabel, Input, Text, ListItem, UnorderedList,
} from '@chakra-ui/react';
import useTrackedAction from '~/hooks/useTrackedAction';
import SubscriptionActions from '~/redux/subscription/actions';
import { showSuccessToast } from '~/toast';
import { PowerUpId } from '~/types/subscription';

export const DeactivatePowerUpModal = ({
  onCancel, onDeactivated, powerUpId, powerUpName, deactivateMeansReasons,
}: {
  onCancel: () => void,
  onDeactivated: () => void
  powerUpId: PowerUpId
  powerUpName:string
  deactivateMeansReasons: string[]
}) => {
  const [confirmText, setConfirmText] = useState('');

  const { trigger: removePowerUp, isLoading } = useTrackedAction({
    trigger: () => SubscriptionActions.removePowerUp({ powerUpId }),
    onSuccess: () => {
      showSuccessToast(`${powerUpName} Power up deactivated`);
      onDeactivated();
    },
  });

  return (
    <Modal isOpen onClose={onCancel} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader paddingBottom={5}>
          <Text paddingTop={5}>{`Deactivate ${powerUpName} power up`}</Text>
          <Text marginTop={3} fontSize={14}>
            {`Are you sure you want to deactivate ${powerUpName}?`}
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody paddingBottom={6}>
          <Text>
            {`Deactivating your ${powerUpName} power up means:`}
          </Text>
          <UnorderedList marginLeft={10} marginTop={5}>
            {deactivateMeansReasons.map((reason) => (
              <ListItem key={reason}>
                {reason}
              </ListItem>
            ))}
          </UnorderedList>
          <FormControl marginTop={10}>
            <FormLabel>Type deactivate to confirm</FormLabel>
            <Input
              size="lg"
              autoFocus
              value={confirmText}
              onChange={(e) => { setConfirmText(e.target.value); }}
              placeholder="deactivate"
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Flex>
            <Button
              size="lg"
              disabled={isLoading || confirmText.toLocaleLowerCase() !== 'deactivate'}
              colorScheme="red"
              onClick={() => {
                removePowerUp();
              }}
              isLoading={isLoading}
            >
              Deactivate Power Up
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
