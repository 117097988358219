import React from 'react';
import {
  Box,
  Heading, Link, Text,
} from '@chakra-ui/react';

import TimelineItem, { TimelineItemProps } from '~/components/TimelineItem';
import { JobTimelineQuoteAccepted } from '~/types/job';
import StatusBadge from '~/components/JobStatusBadge';

export const TimelineQuoteAccepted = ({
  event, ...rest
}: {
  event: JobTimelineQuoteAccepted;
} & TimelineItemProps) => (
  <TimelineItem
    {...rest}
    event={event}
  >
    {event.status && <Box mb={2}><StatusBadge status={event.status} /></Box> }
    <Heading textTransform="none" size="sm" mb={1}>
      QUOTE ACCEPTED
    </Heading>
    <Text>
      {`QUOTE-${event.code} has been accepted by`}
      <Link ml={1} href={`mailto:${event.email}`} color="magnetize.brand-4">
        {event.email}
      </Link>
      &#29;
    </Text>

  </TimelineItem>
);
