import {
  Button,
  Modal, ModalBody, ModalCloseButton,
  ModalContent, ModalFooter, ModalHeader, ModalOverlay,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useAppDispatch } from '~/redux/store';
import { updateTag } from '~/redux/tags/actions';
import { JobTag } from '~/types/tag';
import JobTagForm from './JobTagForm';

const JobTagEditModal = ({
  jobTag,
  title = 'Edit tag',
  onClose = () => {},
  onSave = () => {},
}: {
  jobTag: Partial<JobTag>,
  title: string,
  onClose: () => void,
  onSave?: (tag: Partial<JobTag>) => void
}) => {
  const dispatch = useAppDispatch();
  const [editedJobTag, setEditedJobTag] = useState(jobTag);

  const canSave = editedJobTag.color && editedJobTag.name;
  const onSaveClicked = () => {
    dispatch(updateTag(editedJobTag as JobTag));
    onClose();
    onSave(editedJobTag);
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen
      onClose={onClose}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {title}
        </ModalHeader>
        <ModalCloseButton tabIndex={-1} />
        <ModalBody>
          <JobTagForm
            value={editedJobTag}
            onChange={(tag) => {
              setEditedJobTag({
                ...editedJobTag,
                ...tag,
              });
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            size="lg"
            disabled={!canSave}
            colorScheme="brandDark"
            onClick={onSaveClicked}
          >
            Save
          </Button>
          <Button size="lg" tabIndex={-1} variant="ghost" onClick={onClose}>cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default JobTagEditModal;
