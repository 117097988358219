import {
  Button, Flex, Spacer,
} from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import { getTime } from 'date-fns';
import React, { useState } from 'react';
import AddSchedulingNoteWithEditIcon from '~/components/AddCalendarEventPopover/AddSchedulingNote/withEditIcon';
import CalendarDateIntervalEditor from '~/components/JobCalendar/CalendarDateIntervalEditor';
import { ScheduleEventActions } from '~/redux/schedule/actions';
import { useAppDispatch } from '~/redux/store';
import { ScheduleEvent } from '~/types/ScheduleEvent';

interface AddLeavePopoverContentProps {
  resourceId: string;
  interval: Interval;
  onClose: () => void;
  onCreateEvent: (event: ScheduleEvent) => void;
}

const AddLeavePopoverContent = ({
  resourceId, interval, onClose, onCreateEvent,
}: AddLeavePopoverContentProps) => {
  const dispatch = useAppDispatch();
  const [notes, setNotes] = useState<string>();
  const [eventInterval, setEventInterval] = useState(interval);

  const addLeaveEvent = () => {
    const event: ScheduleEvent = {
      id: nanoid(),
      type: 'staff-leave',
      start: getTime(eventInterval.start),
      end: getTime(eventInterval.end),
      resourceId,
      label: 'Leave',
      notes: notes?.trim() || null,
    };

    dispatch(ScheduleEventActions.createEvent(event));
    onCreateEvent(event);
    onClose();
  };

  return (
    <>
      <CalendarDateIntervalEditor
        lockStart
        value={interval}
        onChange={setEventInterval}
      />
      <AddSchedulingNoteWithEditIcon value={notes} onChange={setNotes} />
      <Flex>
        <Spacer />
        <Button disabled={!resourceId} onClick={addLeaveEvent}>Save</Button>
      </Flex>
    </>
  );
};

export default AddLeavePopoverContent;
