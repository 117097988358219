import { createAction, nanoid } from '@reduxjs/toolkit';
import { TrackedAction } from '../../types/trackedAction';

export default function createTrackedAction<TData>(actionType: string) {
  return createAction(
    actionType,
    (payload: TData) => ({
      payload: {
        id: nanoid(),
        data: payload,
      } as TrackedAction<TData>,
    }),
  );
}
