import React from 'react';
import {
  Box, HStack, Icon, Text,
} from '@chakra-ui/react';
import { CheckCircleRounded } from '@material-ui/icons';
import CurrencyText from '~/components/CurrencyText';
import { useQuoted } from '~/hooks/useQuoted';
import { useInvoiced } from '~/hooks/useInvoiced';

const QuotedVsInvoiced = (
  {
    jobId, taxInclusive = false, quotedLabel = 'Quote approved for ', quotedIcon = null,
  }
  : { jobId : string, taxInclusive?: boolean, quotedLabel?: string, quotedIcon?: React.ReactNode },
) => {
  const { quoted } = useQuoted({ jobId, taxInclusive });
  const { invoiced } = useInvoiced({ jobId, taxInclusive });

  return (
    <HStack borderTopWidth={1} borderBottomWidth={1} py={4} px={3} borderColor="magnetize.ui-4" mb={10}>
      {quotedIcon || <Icon as={CheckCircleRounded} mr={1} mb="1.5px" fontSize="1.2em" color="magnetize.brand-4" />}

      <Text fontWeight="bold">
        {quotedLabel}
        <CurrencyText value={quoted} />
      </Text>
      <Text>{taxInclusive ? '(incl. tax)' : '(ex. tax)'}</Text>
      <Box flex={1} />
      <Text>
        invoiced:
        {' '}
        <CurrencyText value={invoiced} />
      </Text>
    </HStack>
  );
};

export default QuotedVsInvoiced;
