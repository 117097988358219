import { useDisclosure, UseDisclosureProps } from '@chakra-ui/react';

/**
 * A wrapper aruond chakra's useDisclosure hook, but aimed at
 * streamlining its use for controlling a component like a modal
 * or popover.
 *
 * It gives: verbs for the actions, rather than the 'onOpen' naming used
 * by Chakra, as well as a spreadable props object that provides the
 * required props for a modal: isOpen/onClose.
 *
 * Spreading Chakra's useDisclosure result would work, but it's dumping
 * a bunch of unnecessary props into the component and could have unwanted
 * effects, as some components have an "onOpen" event!
 *
 * @param props
 * @returns
 */
export const useModalState = (props: UseDisclosureProps = {}) => {
  const {
    isOpen, onOpen, onClose, onToggle,
  } = useDisclosure(props);

  return {
    isOpen,
    open: onOpen,
    close: onClose,
    toggle: onToggle,

    // These props can be safely spread onto a modal, popover etc.
    props: {
      isOpen,
      onClose,
    },
  };
};
