import {
  Box, Heading, Stack,
} from '@chakra-ui/react';
import { partition } from 'lodash';
import React, { useMemo } from 'react';
import ImageGallery from '~/components/ImageGallery';

import TimelineItem, { TimelineItemProps } from '~/components/TimelineItem';
import UploadedFileList from '~/components/UploadedFileList';
import { JobTimelineNote } from '~/types/job';

const TimelineNote = ({
  event, ...rest
}: {
  event: JobTimelineNote;
} &TimelineItemProps) => {
  const [imageFiles, otherFiles] = useMemo(
    () => partition(event.files, (f) => f.contentType?.startsWith('image')),
    [event.files],
  );

  return (
    <TimelineItem
      {...rest}
      event={event}
      topSpacing="8"
      bottomSpacing="8"
    >
      <Heading size="sm">{event.user}</Heading>

      <Stack spacing={4}>
        <Box>{event.message}</Box>

        {imageFiles.length > 0
          && <ImageGallery files={imageFiles} /> }

        {otherFiles.length > 0 && (
        <UploadedFileList
          files={otherFiles}
        />
        )}
      </Stack>
    </TimelineItem>
  );
};

export default TimelineNote;
