import {
  Box,
  Flex,
  Grid,
  GridItem,
  useDisclosure,
  Heading,
  FormControl,
  FormLabel,
  Textarea,
  FormErrorMessage,
  HStack,
} from '@chakra-ui/react';
import React, { useEffect, useMemo } from 'react';
import { format, addDays } from 'date-fns';
import LineItemsEditor from '~/components/LineItemsEditor';
import LineItemTotals from '~/components/LineItemTotals';
import LinkButton from '~/components/LinkButton';
import SwitchWithLabel from '~/components/SwitchWithLabel';
import useDebouncedState from '~/hooks/useDebouncedState';
import PriceBookBrowseModal from '~/pages/Job/components/PriceBook/PriceBookBrowseModal';
import PriceBookSearch from '~/pages/Job/components/PriceBook/PriceBookSearch';
import { selectTenantQuoteExpiry } from '~/redux/currentUser/selectors';
import { QuoteActions } from '~/redux/quote/actions';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import { LineItem } from '~/types/lineItem';
import { QuoteEdit } from '~/types/quote';
import { sortLineItems, addLineItems } from '~/utils/lineItemHelpers';
import SmartDatePicker from '~/components/SmartDatePicker';

const QuoteEditor = ({
  quote: quoteProp, jobId, quoteId,
}: {
  quoteId: string;
  jobId: string;
  quote: QuoteEdit;
}) => {
  const dispatch = useAppDispatch();
  const defaultExpiryDays = useAppSelector((state) => selectTenantQuoteExpiry(state));
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [quote, setQuote] = useDebouncedState({
    value: {
      expiryDate: addDays(new Date(), defaultExpiryDays).toISOString(),
      dateCreated: new Date().toISOString(),
      ...quoteProp,
    },
    delay: 1500,
    dispatchUpdate: (updated) => {
      dispatch(
        QuoteActions.edit({
          jobId,
          quoteId,
          edits: updated,
        }),
      );
    },
  });

  useEffect(() => {
    // Initialize dates
    if (!quote.expiryDate && !quote.dateCreated) {
      setQuote({
        ...quote,
        expiryDate: addDays(new Date(), defaultExpiryDays).toISOString(),
        dateCreated: new Date().toISOString(),
      });
    }
  }, []);

  const addLineItemsToQuote = (lineItems: LineItem[]) => {
    setQuote({
      ...quote,
      lineItems: {
        ...quote.lineItems,
        ...addLineItems(
          'start',
          sortLineItems(quote.lineItems),
          lineItems.map((item) => ({ ...item })),
        ),
      },
    });
  };

  const toggleEstimate = (isEstimate) => {
    setQuote({
      ...quote,
      isEstimate,
    });
  };

  const lineItemsChanged = (lineItems) => {
    setQuote({ ...quote, lineItems });
  };

  const quoteCreated = useMemo(
    () => format(
      quote.dateCreated ? new Date(quote.dateCreated) : new Date(),
      'MMM d, yyyy',
    ),
    [quote],
  );

  const quoteExpiry = useMemo(
    () => format(
      quote.expiryDate
        ? new Date(quote.expiryDate)
        : addDays(new Date(quoteCreated), defaultExpiryDays),
      'MMM d, yyyy',
    ),
    [quote],
  );

  const expired = quote.expiryDate
    ? new Date(quote.expiryDate) < new Date() : false;

  return (
    <>
      <Flex flexDirection="column" marginBottom={8} mt={8}>

        <Grid templateColumns="repeat(24, 1fr)" gap={4} mt={2} mb="50px">
          <GridItem colSpan={7}>
            <FormControl flex="1">
              <FormLabel fontWeight="bold">Date</FormLabel>
              <SmartDatePicker
                value={quoteCreated}
                placeholderText="Select date"
                selected={quoteCreated && new Date(quoteCreated)}
                onChange={(d) => setQuote({
                  ...quote,
                  dateCreated: new Date(d.toString()).toISOString(),
                  expiryDate: addDays(new Date(d.toString()), defaultExpiryDays).toISOString(),
                })}
              />
            </FormControl>
          </GridItem>
          <GridItem colSpan={7}>
            <FormControl flex="1" isInvalid={expired}>
              <HStack justifyContent="space-between">
                <FormLabel fontWeight="bold">Expiry</FormLabel>
                <FormErrorMessage pb={1} color="magnetize.support-warning" fontWeight="bold">
                  quote expired
                </FormErrorMessage>
              </HStack>
              <SmartDatePicker
                value={quoteExpiry}
                placeholderText="Select date"
                selected={quoteExpiry && new Date(quoteExpiry)}
                borderColor={expired ? 'magnetize.support-warning' : undefined}
                onChange={(d) => setQuote({
                  ...quote,
                  expiryDate: new Date(d.toString()).toISOString(),
                })}
              />
            </FormControl>
          </GridItem>
          <GridItem rowStart={2} colSpan={24}>
            <FormControl flex="1">
              <Textarea
                size="lg"
                style={{ height: '6rem' }}
                resize="none"
                placeholder="Enter a quote description"
                value={quote.description ?? ''}
                onChange={(e) => {
                  setQuote({ ...quote, description: e.target.value });
                }}
              />
            </FormControl>
          </GridItem>
        </Grid>

        <Heading color="black" size="sm" mb={4}>
          ADD TO QUOTE
        </Heading>

        <Box flex="1" position="relative">
          <LinkButton
            zIndex="1"
            right="10px"
            top="9.5px"
            textDecoration="none"
            isGreen
            position="absolute"
            onClick={onOpen}
          >
            Browse all
          </LinkButton>
          <PriceBookSearch
            showBundles
            onSelected={(lineItem) => addLineItemsToQuote([lineItem])}
          />
        </Box>
      </Flex>
      <Grid templateColumns="repeat(24, 1fr)" gap={6}>
        <GridItem colSpan={24}>
          <LineItemsEditor
            lineItems={quote.lineItems}
            onChange={lineItemsChanged}
          />
        </GridItem>
        <GridItem colStart={2} colSpan={14}>
          <SwitchWithLabel
            isChecked={quote.isEstimate}
            onChanged={(checked) => {
              toggleEstimate(checked);
            }}
            label="Pricing is an estimate"
          />
        </GridItem>
        <GridItem colSpan={9} fontSize="lg">
          <LineItemTotals
            lineItems={quote.lineItems}
            taxRate={quote.taxRate}
            taxRateName={quote.taxRateName}
            onTaxRateChange={({ taxRate, taxRateName }) => {
              setQuote({ ...quote, taxRate, taxRateName });
            }}
          />
        </GridItem>
      </Grid>
      {isOpen && (
        <>
          <PriceBookBrowseModal
            titleText="Add to quote"
            showBundles
            onAdd={addLineItemsToQuote}
            onClose={onClose}
          />
        </>
      )}
    </>
  );
};
export default QuoteEditor;
