import React, { useState, useEffect, useRef } from 'react';
import {
  Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter,
  ModalHeader, ModalOverlay, Box, Text, FormControl, FormLabel, Grid, GridItem, Input, Skeleton,
} from '@chakra-ui/react';
import {
  Elements, CardElement, useElements, useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import SubscriptionActions from '~/redux/subscription/actions';
import useTrackedAction from '~/hooks/useTrackedAction';
import { stripe as StripeConfig } from '~/helpers/config';
import AddressForm from '~/components/AddressForm';
import { Address } from '~/types/address';
import GreenButton from '~/components/GreenButton';

let stripePromise;
const ModalInner = ({
  onCancel, onSelectPaymentMethod,
}: {
  onCancel: () => void,
  onSelectPaymentMethod: () => void
}) => {
  const [isCCValid, setIsCCValid] = useState(false);
  const [name, setName] = useState('');
  const [billingAddress, setBillingAddress] = useState({} as Partial<Address>);
  const stripe = useStripe();
  const elements = useElements();

  const nameRef = useRef(null);

  const { isLoading, isDone, trigger: onSubmitCard } = useTrackedAction({
    trigger: () => SubscriptionActions.setDefaultPaymentMethod({
      stripe,
      cardElement: elements.getElement(CardElement),
      billingAddress: billingAddress as Address,
      name,
    }),
  });

  useEffect(() => {
    if (isDone) {
      onSelectPaymentMethod();
    }
  }, [isDone]);

  return (
    <>
      <ModalHeader paddingBottom={5}>
        <Text paddingTop={5}>Enter your payment details</Text>
        <Text marginTop={3} fontSize={14}>
          Lets get this sorted.
        </Text>
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody paddingBottom={6}>
        <Grid
          gridTemplateColumns="3fr"
          gridTemplateAreas={`
          "card"
          "name"
        `}
          columnGap="2"
          rowGap="8"
          sx={{ '& > *': { minWidth: 0 } }}
        >
          <GridItem gridArea="card">
            <FormControl>
              <FormLabel>Card</FormLabel>
              <Box
                borderWidth={1}
                borderColor="gray.200"
                padding={3}
              >
                <CardElement
                  onChange={(e) => {
                    if (e.complete) {
                      setIsCCValid(true);
                    } else {
                      setIsCCValid(false);
                    }
                  }}
                  options={{
                    hidePostalCode: true,
                  }}
                  onEscape={() => {
                    if (nameRef?.current) {
                      nameRef.current.focus();
                    }
                  }}
                />
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel>Name on card</FormLabel>
              <Input
                size="lg"
                ref={nameRef}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel>Billing Address</FormLabel>
              <AddressForm
                enableAutocomplete
                value={billingAddress}
                onChange={(val) => {
                  setBillingAddress({ ...billingAddress, ...val });
                }}
              />
            </FormControl>
          </GridItem>
        </Grid>
      </ModalBody>
      <ModalFooter>
        <Flex>
          <GreenButton
            size="lg"
            disabled={
              !stripe || !elements || isLoading || !isCCValid
              || !name
            }
            onClick={() => {
              onSubmitCard();
            }}
          >
            Subscribe
          </GreenButton>
          <Button
            variant="ghost"
            size="lg"
            onClick={onCancel}
          >
            cancel
          </Button>
        </Flex>
      </ModalFooter>
    </>
  );
};

const SelectPaymentMethodModal = ({
  onCancel, onSelectPaymentMethod,
}: {
  onCancel: () => void,
  onSelectPaymentMethod: () => void,
}) => {
  const [stripeLoaded, setStripeLoaded] = useState(false);
  useEffect(() => {
    if (!stripePromise) {
      stripePromise = loadStripe(StripeConfig.publishableKey);
    }

    setStripeLoaded(true);
  }, []);

  return (
    <Modal isOpen onClose={onCancel} size="xl">
      <ModalOverlay />
      <ModalContent>
        <Skeleton isLoaded={stripeLoaded}>
          <Elements stripe={stripePromise}>
            <ModalInner onCancel={onCancel} onSelectPaymentMethod={onSelectPaymentMethod} />
          </Elements>
        </Skeleton>
      </ModalContent>
    </Modal>
  );
};

export default SelectPaymentMethodModal;
