import { createReducer } from '@reduxjs/toolkit';
import { now } from 'lodash';
import { customerQuoteFetchError, customerQuoteFetchStart, customerQuoteFetchSuccess } from '~/redux/customerQuotes/actions';
import { FetchState } from '~/types/fetchState';
import { Dictionary } from '~/types/helpers';
import { CustomerFacingQuote } from '~/types/quote';

export type QuoteState = FetchState<CustomerFacingQuote>;

export const customerQuotesReducer = createReducer(
  {} as Dictionary<QuoteState>,
  (builder) => (builder
    .addCase(customerQuoteFetchSuccess, (state, action) => {
      const { token, data } = action.payload;
      return {
        ...state,
        [token]: {
          ...state[token],
          status: 'success',
          data,
          isInvalid: false,
          lastUpdatedAt: now(),
        },
      };
    })

    .addCase(customerQuoteFetchError, (state, action) => {
      const { token, errorMessage } = action.payload;
      return {
        ...state,
        [token]: {
          ...state[token],
          status: 'error',
          errorMessage,
        },
      };
    })

    .addCase(customerQuoteFetchStart, (state, action) => {
      const { token } = action.payload;
      return {
        ...state,
        [token]: {
          ...state[token],
          status: 'loading',
        },
      };
    })
  ),
);
