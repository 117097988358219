import { createSelector } from '@reduxjs/toolkit';
import { sortBy } from 'lodash';
import { RootState } from '~/redux/reducer';

export const selectAllEquipmentById = (s: RootState) => s.equipment.byId;

export const selectAllEquipment = createSelector(
  (s: RootState) => s.equipment.byId,
  (equipment) => sortBy(equipment, (e) => e.name.toLowerCase()),
);

export const selectEquipment = createSelector(
  selectAllEquipment,
  (equipment) => equipment.filter((e) => !e.isDeleted),
);

export const selectEquipmentById = (s: RootState, equipmentId: string) => (
  s.equipment.byId[equipmentId]
);

export const selectEquipmentName = (s: RootState, equipmentId: string) => (
  s.equipment.byId[equipmentId]?.name
);

export const selectEquipmentRate = createSelector([
  (s: RootState) => s.prices.entities,
  (s: RootState, equipmentId: string) => selectEquipmentById(s, equipmentId),
], (prices, equipment) => prices[equipment?.priceId]);

export const selectEquipmentNames = (s: RootState, equipmentIds: string[]) => (
  equipmentIds.map((id) => selectEquipmentName(s, id))
);
