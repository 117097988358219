import { calendarTourSteps, TourStep } from '~/redux/calendarTour/steps';
import { RootState } from '~/redux/store';

export const selectCurrentStep = (
  state: RootState,
) : TourStep => calendarTourSteps[state.calendarTour.currentStepIndex];

export const selectCurrentStepIndex = (state: RootState) => state.calendarTour.currentStepIndex;

export const selectTourEvent = (state: RootState) => state.calendarTour.event;

export const selectProgress = (
  state: RootState,
) => {
  const current = state.calendarTour.currentStepIndex + 1;
  const total = calendarTourSteps.length;
  return {
    current,
    total,
    isInProgress: current > 0 && current <= total,
  };
};

export const selectHighlightedDay = (state: RootState) => {
  const progress = selectProgress(state);
  return progress.isInProgress ? state.calendarTour.highlightedDay : null;
};
