import { createReducer } from '@reduxjs/toolkit';
import { now } from 'lodash';
import {
  JobActions, jobFetchError, jobFetchStart, jobFetchSuccess,
} from '~/redux/jobs/actions';
import { FetchState } from '~/types/fetchState';
import { Dictionary } from '~/types/helpers';
import { Job } from '~/types/job';

export type JobState = {
  // Contains complete jobs by id
  entities: Dictionary<FetchState<Job>>,
  // Contains a list of jobs with partial information
  jobList: FetchState<Dictionary<Job>>
};

export const jobsReducer = createReducer<JobState>({
  entities: {},
  jobList: {
    data: {},
    status: 'idle',
    lastUpdatedAt: null,
  },
},
(builder) => (builder
  .addCase(jobFetchSuccess, (state, action) => {
    const { jobId, data } = action.payload;
    return {
      ...state,
      entities: {
        ...state.entities,
        [jobId]: {
          ...state[jobId],
          data,
          status: 'success',
          lastUpdatedAt: now(),
        },
      },
      jobList: {
        ...state.jobList,
        data: {
          ...state.jobList.data,
          [jobId]: data,
        },
      },
    };
  })

  .addCase(jobFetchError, (state, action) => ({
    ...state,
    entities: {
      ...state.entities,
      [action.payload.jobId]: {
        ...state[action.payload.jobId],
        status: 'error',
      },
    },
  }))

  .addCase(jobFetchStart, (state, action) => {
    const { jobId } = action.payload;
    return ({
      ...state,
      entities: {
        ...state.entities,
        [jobId]: {
          ...state[jobId],
          status: 'loading',
        },
      },
    });
  })
  .addCase(JobActions.jobsListUpdated, (state, action) => {
    const { jobsById } = action.payload;
    return ({
      ...state,
      jobList: {
        data: jobsById,
        status: 'success',
        lastUpdatedAt: now(),
      },
    });
  })
));
