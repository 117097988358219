import { Flex, Text } from '@chakra-ui/react';
import React from 'react';
import DismissibleStatusAlert from '~/components/DismissibleStatusAlert';
import JobFileUpload from '~/components/FileUpload/JobFileUpload';
import LinkButton from '~/components/LinkButton';
import Routes from '~/pages/routes';
import { updateJob } from '~/redux/jobs/actions';
import { selectJobWithEdits } from '~/redux/jobs/selectors';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import { Job } from '~/types/job';

const JobFilesTab = ({ jobId }: { jobId: string, }) => {
  const dispatch = useAppDispatch();
  const job = useAppSelector((state) => selectJobWithEdits(state, jobId));
  const updateCurrentJob = (changes: Partial<Job>) => {
    dispatch(updateJob({ id: job.id, ...changes }));
  };
  const files = job?.files ?? [];

  return (
    <Flex direction="column">
      {files.length === 0 && (
      <DismissibleStatusAlert
        id="add-files-to-job"
        title="Adding a file to all jobs"
        mb={8}
      >
        <Text>
          If there’s a file you need for all jobs (such as a health and safety form),
          you can upload it here under the
          {' '}
          <LinkButton
            textDecoration="none"
            isGreen
            to={Routes.jobSettings({})}
          >
            organisation settings
          </LinkButton>
          .
        </Text>
      </DismissibleStatusAlert>
      )}
      <JobFileUpload
        files={job?.files}
        onFileUploaded={(file) => {
          updateCurrentJob({ files: [...job?.files, file] });
        }}
        onFileRemoved={(file) => {
          const { s3Key } = file;
          const filtered = job.files.filter((f) => f.s3Key !== s3Key);
          updateCurrentJob({ files: filtered });
        }}
      />
    </Flex>
  );
};

export default JobFilesTab;
