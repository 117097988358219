import {
  Heading, Text,
} from '@chakra-ui/react';
import { partition } from 'lodash';
import React, { useMemo } from 'react';
import ImageGallery from '~/components/ImageGallery';

import TimelineItem, { TimelineItemProps } from '~/components/TimelineItem';
import UploadedFileList from '~/components/UploadedFileList';

const TimelineUnknownConsumableAdded = ({
  event, ...rest
}: {
  event: any;
} & TimelineItemProps) => {
  const [imageFiles, otherFiles] = useMemo(
    () => partition(event.files, (f) => f.contentType?.startsWith('image')),
    [event.files],
  );

  return (
    <TimelineItem
      {...rest}
      event={event}
    >
      <Heading textTransform="none" size="sm" mb={1}>{`NEW CONSUMABLE '${event.name.toUpperCase()}' ADDED`}</Heading>
      {!!event.message && (
      <Text>
        {event.message}
      </Text>
      )}
      {imageFiles.length > 0
        && <ImageGallery files={imageFiles} /> }

      {otherFiles.length > 0 && (
      <UploadedFileList
        files={otherFiles}
      />
      )}

    </TimelineItem>
  );
};
export default TimelineUnknownConsumableAdded;
