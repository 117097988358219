export interface Time {
  hours: number;
  minutes: number;
}

const parse12HourTime = (time: string): (Time | null) => {
  // Should match:
  //  - 12:30pm
  //  - 1230pm
  //  - 12pm
  //  - 07:30am
  const match = time.match(/^(?<hours>\d{1,2})(:?(?<minutes>\d{1,2}))?(\s*(?<suffix>[ap]m))$/i);
  if (match) {
    let hours = parseInt(match.groups.hours, 10);
    const minutes = match.groups.minutes
      ? parseInt(match.groups.minutes, 10)
      : 0;
    const suffix = match.groups.suffix.toLowerCase();

    if (hours > 12 || minutes > 59) {
      return null;
    }

    if (suffix === 'am' && hours === 12) {
      hours = 0;
    } else if (suffix === 'pm' && hours !== 12) {
      hours += 12;
    }

    return { hours, minutes };
  }

  return null;
};

const parse24HourTime = (time: string): (Time | null) => {
  // Should match:
  //  - 1230
  const match = time.trim().match(/^(?<hours>\d{1,2}):?(?<minutes>\d{2})(\s*(h|hrs|hours))?$/i);
  if (match) {
    const hours = parseInt(match.groups.hours, 10);
    const minutes = parseInt(match.groups.minutes, 10);

    if (hours < 24 && minutes < 60) {
      return { hours, minutes };
    }
  }
  return null;
};

export const parseTime = (text: string): (Time | null) => (
  parse12HourTime(text)
    ?? parse24HourTime(text)
);

export const formatTime12Hour = (time: Time): string => {
  let { hours } = time;
  let suffix = 'am';
  if (hours === 0) {
    hours = 12;
    suffix = 'am';
  } else if (hours >= 12) {
    if (hours > 12) {
      hours -= 12;
    }
    suffix = 'pm';
  }
  return `${hours}:${String(time.minutes).padStart(2, '0')}${suffix}`;
};

const parseDurationHoursMinutes = (text: string): Time => {
  const match = text.trim().match(/^(?<hours>\d+)(\s*(h|hours|hrs)\s*)?(\s*:?\s*(?<minutes>\d+)(\s*(m|min|minutes))?)?$/i);
  if (!match) {
    return null;
  }

  const hours = parseInt(match.groups.hours, 10);
  const minutes = parseInt(match.groups.minutes ?? '0', 10);

  return { hours, minutes };
};

const parseDurationFractional = (text: string): Time => {
  const match = text.match(/^(?<value>(\d+(\.\d+)?))$/);
  if (!match) {
    return null;
  }

  const value = parseFloat(match.groups.value);

  return {
    hours: Math.floor(value),
    minutes: Math.floor((value - Math.floor(value)) * 60),
  };
};

export const parseDuration = (text: string) => (
  parseDurationHoursMinutes(text)
    ?? parseDurationFractional(text)
);

export const formatDuration = (time: Time) => `${time.hours}:${String(time.minutes).padStart(2, '0')}`;

export const minutesToDuration = (minutes: number): Time => ({
  hours: Math.floor(minutes / 60),
  minutes: Math.floor(minutes % 60),
});

export const parseISODateLocal = (s) => {
  const b = s.split(/\D/);
  return new Date(b[0], b[1] - 1, b[2]);
};
