import { omit } from 'lodash';
import { JobTimelineItem, JobTimelineNote, JobTimelineStatusChange } from '~/types/job';
import fetchJson from '~/utils/fetchJson';

export const getTimeline = (jobId:string) => fetchJson<JobTimelineItem[]>(`/api/jobs/${jobId}/timeline`);

export const putTimelineNote = (
  jobId: string,
  note: JobTimelineNote,
) => (
  fetchJson<JobTimelineItem>(
    `/api/jobs/${jobId}/timeline/${note.id}?includeUpdated=true`,
    {
      method: 'PUT',
      body: {
        ...omit(note, 'id', 'at', 'user'),
        files: note.files || [],
      },
    },
  )
);

export const putTimelineStatus = (
  jobId: string,
  status: JobTimelineStatusChange,
) => (
  fetchJson<JobTimelineItem>(
    `/api/jobs/${jobId}/timeline/${status.id}?includeUpdated=true`,
    {
      method: 'PUT',
      body: {
        ...omit(status, 'id', 'at', 'user'),
      },
    },
  )
);
