import { createAction } from '@reduxjs/toolkit';
import { Price } from '~/types/price';

const PriceActions = {
  fetch: createAction('prices/fetch'),
  save: createAction<{ price: Price }>('prices/save'),
  updated: createAction<{ prices: Price[] }>('prices/updated'),
  archive: createAction<{ priceId: string }>('prices/archive'),
};

export default PriceActions;
