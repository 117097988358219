import {
  Box, Collapse, Icon, IconButton, Text, Tooltip,
} from '@chakra-ui/react';
import { KeyboardArrowRightOutlined } from '@material-ui/icons';
import {
  areIntervalsOverlapping, getTime, startOfDay, toDate,
} from 'date-fns';
import { max, uniqBy } from 'lodash';
import React, { useContext, useMemo, useState } from 'react';
import AddJobEventPopover from '~/components/AddCalendarEventPopover';
import AdminEventCalendarCard from '~/components/AdminEventCalendarCard';
import { CalendarContext } from '~/components/Calendar/CalendarContext';
import CalendarGrid from '~/components/Calendar/CalendarGrid';
import CalendarGridItem from '~/components/Calendar/CalendarGridItem';
import ResizableCalendarItem from '~/components/Calendar/ResizableCalendarItem';
import { allocateRows } from '~/components/Calendar/rowAllocationAlgorithm';
import { useScrollbarWidth } from '~/hooks/useScrollbarWidth';
import PrefsActions from '~/redux/prefs/actions';
import { ScheduleEventActions } from '~/redux/schedule/actions';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import { makeTransparent } from '~/theme';
import {
  EquipmentStatusEvent, ScheduleEvent, StaffLeaveEvent,
} from '~/types/ScheduleEvent';
import { endOfDaySeconds } from '~/utils/calendarHelpers';

interface AdminEventsRowProps {
  adminEvents: (StaffLeaveEvent | EquipmentStatusEvent)[];
  selectedEventId: string;
  setSelectedEventId: (id: string) => void;
}

const AdminEventsRow = (
  { adminEvents, selectedEventId, setSelectedEventId }: AdminEventsRowProps,
) => {
  const dispatch = useAppDispatch();
  const adminRowAllocations = useMemo(() => allocateRows(adminEvents), [adminEvents]);
  const isExpanded = useAppSelector((s) => s.prefs.jobCalendarAdminRowExpanded ?? false);

  // This logic all relates to the adding an event popover. Maybe it'd be better refactored
  // into a reusable hook..
  const [newEventInterval, setNewEventInterval] = useState<{ start: Date, end: Date }>();
  const isAddingEvent = !!newEventInterval;

  const eventShadowRow = useMemo(() => {
    const overlappingEvents = newEventInterval
      ? adminEvents.filter((ev) => areIntervalsOverlapping(ev, newEventInterval))
      : [];
    return (max(overlappingEvents.map((ev) => adminRowAllocations[ev.id])) ?? 0) + 1;
  }, [newEventInterval, adminRowAllocations, adminEvents]);

  const onCalendarBackgroundClick = (event: React.MouseEvent, date: Date) => {
    const isClickOnBackground = event.target === event.currentTarget;
    if (isClickOnBackground && selectedEventId) {
      setSelectedEventId(null);
      return;
    }
    if (isClickOnBackground && !isAddingEvent && !event.isDefaultPrevented()) {
      const hoverInterval = {
        start: startOfDay(date),
        end: endOfDaySeconds(date),
      };
      setNewEventInterval(hoverInterval);
      dispatch(PrefsActions.set({
        prefs: {
          jobCalendarAdminRowExpanded: true,
        },
      }));
    }
  };

  const calendarContext = useContext(CalendarContext);
  const peopleOnLeavePerDay = useMemo(() => (
    calendarContext.days.map((d) => {
      const overlappingEvents = adminEvents
        .filter((ev) => ev.type === 'staff-leave')
        .filter((ev) => areIntervalsOverlapping(d, ev));
      return uniqBy(overlappingEvents, (ev) => ev.resourceId).length;
    })), [calendarContext, adminEvents]);

  const toggleIsExpanded = () => {
    dispatch(PrefsActions.set({
      prefs: {
        jobCalendarAdminRowExpanded: !isExpanded,
      },
    }));
    setNewEventInterval(undefined);
  };

  const moveCalendarEvent = (ev: ScheduleEvent, start: Date, end: Date) => {
    dispatch(ScheduleEventActions.createEvent({
      ...ev,
      start: getTime(start),
      end: getTime(end),
    }));
  };

  const scrollbarWidth = useScrollbarWidth();

  return (
    <Box bg={makeTransparent('#e3e1e1', 0.6)} position="relative" paddingRight={`${scrollbarWidth}px`}>
      <Tooltip label="Toggle admin row" openDelay={500} closeOnClick placement="top" hasArrow gutter={15}>
        <IconButton
          position="absolute"
          top="1.5"
          left="0"
          icon={(
            <Icon
              as={KeyboardArrowRightOutlined}
              transition="all 0.3s"
              transform={isExpanded ? 'rotate(90deg)' : undefined}
            />
          )}
          aria-label="expand"
          variant="ghost"
          size="xs"
          zIndex="1"
          onMouseDown={(e) => e.preventDefault()}
          onClick={toggleIsExpanded}
        />
      </Tooltip>
      <CalendarGrid subdivisions={1} py="2" onClick={onCalendarBackgroundClick}>
        {calendarContext.days.map((day, index) => (
          <CalendarGridItem
            key={day.id}
            start={day.start}
            end={day.end}
            textAlign="center"
            opacity="0.4"
            pointerEvents="none"
            userSelect="none"
          >
            <Text fontSize="12px">
              {peopleOnLeavePerDay[index] > 0
                ? `${peopleOnLeavePerDay[index]} on leave`
                : <>&nbsp;</>}
            </Text>
          </CalendarGridItem>
        ))}
      </CalendarGrid>
      <Collapse in={isExpanded}>
        <CalendarGrid
          subdivisions={1}
          rowGap="2"
          pb="4"
          minHeight="60px"
          onClick={onCalendarBackgroundClick}
        >
          {adminEvents?.map((ev) => (
            <ResizableCalendarItem
              key={ev.id}
              start={toDate(ev.start)}
              end={toDate(ev.end)}
              row={adminRowAllocations[ev.id] ?? 1}
              onSpanChange={({ start, end }) => moveCalendarEvent(ev, start, end)}
              onClick={() => {
                setNewEventInterval(undefined);
                if (selectedEventId === ev.id) {
                  setSelectedEventId(null);
                } else {
                  setSelectedEventId(ev.id);
                }
              }}
            >
              <AdminEventCalendarCard
                event={ev}
                isSelected={ev.id === selectedEventId}
                onSpanChange={({ start, end }) => moveCalendarEvent(ev, start, end)}
              />
            </ResizableCalendarItem>
          ))}

          {isExpanded && newEventInterval && (
          <CalendarGridItem
            start={newEventInterval.start}
            end={newEventInterval.end}
            row={eventShadowRow}
            minHeight="50px"
          >
            <AddJobEventPopover
              interval={newEventInterval}
              onClose={() => setNewEventInterval(undefined)}
              defaultType="staff-leave"
            />
          </CalendarGridItem>
          )}
        </CalendarGrid>
      </Collapse>
    </Box>
  );
};

export default AdminEventsRow;
