import {
  Box, Icon, NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure,
} from '@chakra-ui/react';
import { AddOutlined } from '@material-ui/icons';
import { nanoid } from '@reduxjs/toolkit';
import { eachDayOfInterval, format, parseISO } from 'date-fns';
import { groupBy, sortBy, uniqBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import LinkButton from '~/components/LinkButton';
import useDebouncedState from '~/hooks/useDebouncedState';
import { AddEquipmentLogModal } from '~/pages/Job/components/logs/AddEquipmentLogModal';
import { useResourcesQuery } from '~/queries/useResourcesQuery';
import { selectEquipmentName } from '~/redux/equipment/selectors';
import { LogsActions } from '~/redux/logs/actions';
import { selectLogsForJob } from '~/redux/logs/selectors';
import { selectPriceById } from '~/redux/prices/selectors';
import { selectJobResourceEvents } from '~/redux/schedule/selectors';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import { EquipmentLogEntry } from '~/types/LogEntry';
import { getIsoDate } from '~/utils/calendarHelpers';

interface EquipmentLogTableRowProps {
  jobId: string;
  logEntry: EquipmentLogEntry;
  isFirstEntry: boolean;
  isLastEntry: boolean;
}

const EquipmentLogTableRow = ({
  logEntry: logEntryProp, jobId, isFirstEntry, isLastEntry,
}: EquipmentLogTableRowProps) => {
  const dispatch = useAppDispatch();
  const [logEntry, setLogEntry] = useDebouncedState({
    value: logEntryProp,
    dispatchUpdate: (updated) => dispatch(LogsActions.save({
      jobId, log: updated,
    })),
  });

  const { resourceId, priceId } = logEntry;
  const [quantityString, setQuantityString] = useState(logEntry.quantity.toString() ?? '0');
  useEffect(() => {
    setQuantityString(logEntry.quantity.toString() ?? '0');
  }, [logEntry.quantity]);

  const equipmentName = useAppSelector((s) => selectEquipmentName(s, resourceId));
  const equipmentPrice = useAppSelector((s) => selectPriceById(s, priceId));
  const hideBorderUnderDate = (isFirstEntry && !isLastEntry) || (!isFirstEntry && !isLastEntry);

  return (
    <Tr key={logEntry.id} fontSize="12px">
      <Td
        borderBottomColor={hideBorderUnderDate ? 'transparent' : undefined}
        pr="0"
      >
        {isFirstEntry && <Text>{format(parseISO(logEntry.date), 'dd MMM yyyy')}</Text>}
      </Td>
      <Td>
        <Text fontWeight="semibold">
          {equipmentName}
        </Text>
      </Td>
      <Td pr="0">
        <NumberInput
          variant="ghost"
          size="sm"
          min={0}
          my="-4"
          mr="-2"
          onFocus={(e) => {
            e.target.select();
          }}
          onChange={(s) => {
            setQuantityString(s);
            const quantity = Number(s);
            if (!Number.isNaN(quantity)) {
              setLogEntry({
                ...logEntry,
                quantity,
              });
            }
          }}
          value={quantityString}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper
              fontSize="0.7rem"
              pt="1"
            />
            <NumberDecrementStepper
              fontSize="0.7rem"
              pb="1"
            />
          </NumberInputStepper>
        </NumberInput>
      </Td>
      <Td color="magnetize.text-4">{equipmentPrice?.unit}</Td>
    </Tr>
  );
};

interface JobEquipmentLogsTableProps {
  jobId: string;
}

const JobEquipmentLogsTable = ({ jobId }: JobEquipmentLogsTableProps) => {
  const logs = useAppSelector(
    (s) => selectLogsForJob(s, jobId).filter((e) => e.type === 'equipment'),
    'deep',
  );
  const { data: allResources } = useResourcesQuery();
  const resourceEvents = useAppSelector((s) => selectJobResourceEvents(s, jobId), 'deep');

  const defaultLogEntries: EquipmentLogEntry[] = useMemo(() => {
    const isEquipment = (resourceId: string) => (
      !!allResources?.find((r) => r.id === resourceId && r.type === 'equipment')
    );

    return resourceEvents
      .filter((ev) => isEquipment(ev.resourceId))
      .flatMap((ev) => eachDayOfInterval(ev).map((d) => [ev.resourceId, d] as const))
      .map(([resourceId, day]) => ({
        id: nanoid(),
        type: 'equipment',
        resourceId,
        date: getIsoDate(day),
        jobId,
        quantity: 0,
      }));
  }, [resourceEvents, allResources]);

  const logEntriesByDay = useMemo(() => {
    const uniqLogs = uniqBy(
      [...logs ?? [], ...defaultLogEntries ?? []],
      (e) => `${e.date}-${e.resourceId}`,
    );

    return Object.values(groupBy(
      sortBy(uniqLogs, (e) => e.date, (e) => e.resourceId),
      (e) => e.date,
    ));
  }, [logs, defaultLogEntries]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box mx={2}>
      <Table>
        <Thead>
          <Tr>
            <Th w="70px" pr="0">Date</Th>
            <Th pr="0">Equipment</Th>
            <Th w="90px" pr="0" isNumeric>Logged</Th>
            <Th w="70px" pr="0">Unit</Th>
          </Tr>
        </Thead>
        <Tbody>
          {
          logEntriesByDay.map((entries) => (
            entries.map((e, index) => (
              <EquipmentLogTableRow
                key={e.id}
                jobId={jobId}
                logEntry={e}
                isFirstEntry={index === 0}
                isLastEntry={index === entries.length - 1}
              />
            ))
          ))
            }
        </Tbody>
      </Table>

      <LinkButton
        isGreen
        noUnderline
        onClick={() => onOpen()}
        leftIcon={<Icon fontSize="md" as={AddOutlined} />}
        mt="6"
        mb="2"
      >
        Add equipment log
      </LinkButton>

      {isOpen && (
        <AddEquipmentLogModal
          jobId={jobId}
          onClose={onClose}
        />
      )}
    </Box>
  );
};

export default JobEquipmentLogsTable;
