import { chunk } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

interface UsePagingProps<T> {
  items: T[];
  pageSize: number;
}

function usePaging<T>({ items, pageSize } : UsePagingProps<T>) {
  const [currentPage, setCurrentPage] = useState(0);
  const chunks = useMemo(() => chunk(items, pageSize), [items, pageSize]);

  useEffect(() => {
    setCurrentPage(0);
  }, [items?.length]);

  return {
    items: chunks[currentPage],
    nextPage: () => {
      setCurrentPage(currentPage + 1);
    },
    nextDisabled: !chunks[currentPage + 1],
    prevPage: () => {
      setCurrentPage(currentPage - 1);
    },
    prevDisabled: currentPage === 0,
    currentPageCount: currentPage + 1,
    totalPageCount: chunks.length,
  };
}

export default usePaging;
