import {
  Box, Button, Flex, FormControl, FormLabel, Grid, Input, InputGroup, InputRightAddon, Switch,
} from '@chakra-ui/react';
import { isEmpty, isEqual } from 'lodash';
import React, { useState } from 'react';
import AutoSizingTextarea from '~/components/AutoSizingTextarea';
import OrgSettingsFileUpload from '~/components/FileUpload/OrgSettingsFileUpload';
import { Tenant } from '~/types/tenant';

interface QuoteSettingsTabProps {
  tenant: Tenant;
  isSaving: boolean;
  onSave: (tenant: Tenant) => void;
}

const QuoteSettingsTab = ({ tenant, isSaving, onSave }: QuoteSettingsTabProps) => {
  const [quoteRequired, setQuoteRequired] = useState(
    tenant.quoteRequired ?? false,
  );
  const [quoteTermsAndConditions, setQuoteTermsAndConditions] = useState(
    tenant.quoteTermsAndConditions ?? '',
  );
  const [quoteAttachments, setQuoteAttachments] = useState(
    tenant.quoteAttachments ?? [],
  );
  const [quoteExpiryDays, setQuoteExpiryDays] = useState(String(tenant.quoteExpiryDays ?? 30));

  const canSave = !isEqual(tenant.quoteAttachments ?? [], quoteAttachments)
    || tenant.quoteRequired !== quoteRequired
    || (tenant.quoteTermsAndConditions ?? '') !== quoteTermsAndConditions
    || String(tenant.quoteExpiryDays ?? 30) !== quoteExpiryDays;

  const save = () => {
    onSave({
      ...tenant,
      quoteRequired,
      quoteTermsAndConditions,
      quoteAttachments,
      quoteExpiryDays: isEmpty(quoteExpiryDays) ? 30 : parseInt(quoteExpiryDays, 10),
    });
  };

  return (
    <Grid gap={6}>
      <Box>
        <FormLabel>Default on jobs</FormLabel>
        <FormControl display="flex" flex="2" alignItems="center">
          <Switch
            id="quote-required"
            size="lg"
            isChecked={quoteRequired}
            onChange={(e) => {
              setQuoteRequired(e.target.checked);
            }}
            mr={2}
          />
          <FormLabel fontWeight="bold" htmlFor="quote-required" mb="0">
            Quote Required
          </FormLabel>
        </FormControl>
      </Box>
      <Box>
        <FormControl display="flex" flexDirection="column">
          <FormLabel fontWeight="bold" htmlFor="quote-required" mb="2">
            Default Quote Expiry
          </FormLabel>
          <InputGroup>
            <Input
              id="quote-expiry-days"
              size="lg"
              value={quoteExpiryDays}
              type="number"
              onChange={(e) => {
                setQuoteExpiryDays(e.target.value);
              }}
            />
            <InputRightAddon>Days</InputRightAddon>
          </InputGroup>
        </FormControl>
      </Box>
      <Box>
        <FormLabel>Terms &amp; Conditions</FormLabel>
        <AutoSizingTextarea
          value={quoteTermsAndConditions}
          onChange={(e) => setQuoteTermsAndConditions(e.target.value)}
        />
      </Box>
      <FormControl>
        <FormLabel>Quote Attachments</FormLabel>
        <OrgSettingsFileUpload
          onFileUploaded={(file) => {
            setQuoteAttachments([...quoteAttachments, file]);
          }}
          onFileRemoved={(file) => {
            const { s3Key } = file;
            const filtered = quoteAttachments.filter((f) => f.s3Key !== s3Key);
            setQuoteAttachments(filtered);
          }}
          files={quoteAttachments}
        />
      </FormControl>
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
        mt={4}
      >
        <Button
          disabled={isSaving || !canSave}
          onClick={save}
          size="lg"
        >
          Save
        </Button>
      </Flex>
    </Grid>
  );
};

export default QuoteSettingsTab;
