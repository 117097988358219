import {
  Flex, Icon, Stack, Text,
} from '@chakra-ui/react';
import { AddOutlined } from '@material-ui/icons';
import React, { useRef } from 'react';
import FileGallery from '~/components/FileUpload/FileGallery';
import FileUpload, { FileUploadRef } from '~/components/FileUpload';
import UploadedFile from '~/types/uploaded-file';

interface QuoteFileUploadProps {
  files: UploadedFile[];
  onFileUploaded: (file: UploadedFile) => void;
  onFileRemoved: (file: UploadedFile) => void;
}

const QuoteFileUpload = ({ files, onFileUploaded, onFileRemoved } : QuoteFileUploadProps) => {
  const fileUploadRef = useRef<FileUploadRef>();

  return (
    <Flex direction="column">
      <FileUpload
        ref={fileUploadRef}
        onFileUploaded={onFileUploaded}
      >
        <Stack flex="1">
          <Flex
            color="magnetize.text-1"
            alignItems="center"
            justifyContent="center"
            pt={12}
          >
            <Icon as={AddOutlined} />
          </Flex>
          <Text textAlign="center" pb={12} color="magnetize.text-3">
            Drag and drop
            <br />
            or select a file to upload
          </Text>
        </Stack>
      </FileUpload>
      <FileGallery
        compact
        onFileRemoved={(file) => {
          fileUploadRef.current.reset();
          onFileRemoved(file);
        }}
        files={files}
      />
    </Flex>
  );
};

export default QuoteFileUpload;
