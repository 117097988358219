import { AvatarProps, Icon } from '@chakra-ui/react';
import React from 'react';
import { FaTruck } from 'react-icons/fa';
import EquipmentAvatar from '~/components/EquipmentAvatar';
import StaffAvatar from '~/components/StaffAvatar';
import UserAvatar from '~/components/UserAvatar';
import { useResourceSummary } from '~/queries/useResourcesQuery';
import { ResourceSummary } from '~/types/resource';

interface ResourceAvatarProps extends Omit<AvatarProps, 'resource'> {
  resource?: ResourceSummary;
  resourceId?: string;
}

const ResourceAvatar = ({
  resource: resourceProp, resourceId, ...rest
}: ResourceAvatarProps) => {
  const { data: resource } = useResourceSummary(resourceId
    ? ({ id: resourceId, type: 'staff' })
    : resourceProp);

  if (resource?.type === 'staff' && resource.id) {
    return <StaffAvatar staffId={resource.id} {...rest} />;
  }
  if (resource?.type === 'equipment' && resource.id) {
    return <EquipmentAvatar equipmentId={resource.id} {...rest} />;
  }
  return (
    <UserAvatar
      background={resource?.type === 'equipment' ? 'magnetize.text-3' : 'magnetize.text-1'}
      name={resource?.nickname ?? resource?.name}
      icon={resource?.type === 'equipment' ? <Icon as={FaTruck} /> : undefined}
      {...rest}
    />
  );
};

export default ResourceAvatar;
