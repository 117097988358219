import {
  Box, HStack, Icon, Menu, MenuButton, MenuList, Text, useDisclosure,
} from '@chakra-ui/react';
import { Event } from '@material-ui/icons';
import { format, intlFormat } from 'date-fns';
import React from 'react';
import DatePicker from '~/components/DatePicker';
import JobCalendarActions from '~/redux/jobCalendar/jobCalendar';
import { useAppDispatch } from '~/redux/store';
import { endOfDaySeconds, moveInterval, roundIntervalToDays } from '~/utils/calendarHelpers';

const formatDate = (t: Date | number) => intlFormat(t, {
  weekday: 'long',
  day: 'numeric',
  month: 'short',
});

interface TextDatePickerProps {
  value: Date;
  onChange: (d: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  startDate?: Date;
  endDate?: Date;
  isDisabled?: boolean;
}

const TextDatePicker = (
  {
    value, onChange, minDate, maxDate, isDisabled,
    selectsStart, selectsEnd, startDate, endDate,
  } :
  TextDatePickerProps,
) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Menu
      flip={false}
      isOpen={isOpen}
      onOpen={isDisabled ? undefined : onOpen}
      onClose={onClose}
    >
      <MenuButton cursor={isDisabled ? 'unset' : 'pointer'}>
        <Text
          p={1}
          fontWeight="semibold"
          borderWidth={1}
          borderColor="#FFF"
          borderBottomColor={isOpen ? 'magnetize.brand-4' : undefined}
          backgroundColor={isOpen ? 'gray.50' : undefined}
        >
          {formatDate(value)}
        </Text>
      </MenuButton>
      <MenuList p={0} borderRadius="sm" boxShadow="lg">
        <Box>
          <DatePicker
            variant="ghost"
            minDate={minDate}
            maxDate={maxDate}
            onChange={(d) => {
              onChange(d as Date);
              onClose();
            }}
            selectsStart={selectsStart}
            selectsEnd={selectsEnd}
            startDate={startDate}
            endDate={endDate}
            selected={value}
            inline
          />
        </Box>
      </MenuList>
    </Menu>

  );
};

interface CalendarDateIntervalEditorProps {
  value: Interval;
  onChange?: (i : Interval) => void;
  lockStart?: boolean;
}

const CalendarDateIntervalEditor = (
  { value, onChange, lockStart }: CalendarDateIntervalEditorProps,
) => {
  const interval = roundIntervalToDays(value);
  const dispatch = useAppDispatch();

  return (
    <HStack spacing={2}>
      <Icon fontSize="16px" boxSize="16px" as={Event} />
      <HStack color="magnetize.text-3" flex="1">
        <TextDatePicker
          value={interval.start}
          startDate={interval.start}
          endDate={interval.end}
          isDisabled={lockStart}
          selectsStart
          onChange={(s) => {
            const shifted = moveInterval(interval, s);
            dispatch(JobCalendarActions.move(
              { focusedDate: format(shifted.start, 'yyyy-MM-dd') },
            ));
            onChange(shifted);
          }}
        />
        <Text>-</Text>
        <TextDatePicker
          value={interval.end}
          selectsEnd
          startDate={interval.start}
          endDate={interval.end}
          minDate={interval.start}
          onChange={(e) => {
            onChange({
              start: interval.start,
              end: endOfDaySeconds(e),
            });
          }}
        />
      </HStack>
    </HStack>

  );
};

export default CalendarDateIntervalEditor;
