import {
  addDays, startOfDay, startOfWeek,
} from 'date-fns';
import {
  all, put, select, takeEvery,
} from 'redux-saga/effects';
import CalendarTourActions from '~/redux/calendarTour/actions';
import { selectCurrentStepIndex, selectTourEvent } from '~/redux/calendarTour/selectors';
import { endOfDaySeconds } from '~/utils/calendarHelpers';

const handleCalendarTourStarted = takeEvery(
  CalendarTourActions.startTour,
  function* handle() {
    yield put(CalendarTourActions.setStep({ step: 0 }));
    const thursday = addDays(startOfWeek(new Date()), 4);
    yield put(CalendarTourActions.setHighlightDay({
      start: startOfDay(thursday).getTime(),
      end: endOfDaySeconds(thursday).getTime(),
    }));
  },
);

const handleCalendarTourCompleted = takeEvery(
  CalendarTourActions.complete,
  function* handle() {
    yield put(CalendarTourActions.setStep({ step: -1 }));
    yield put(CalendarTourActions.setHighlightDay(null));
    yield put(CalendarTourActions.setEvent({ event: null }));
  },
);

const handleCalendarTourEventPopoverOpened = takeEvery(
  CalendarTourActions.eventPopoverOpened,
  function* handle() {
    yield put(CalendarTourActions.setStep({ step: 1 }));
    yield put(CalendarTourActions.setHighlightDay(null));
  },
);

const handleCalendarTourJobAdded = takeEvery(
  CalendarTourActions.jobAssignedToEvent,
  function* handle() {
    yield put(CalendarTourActions.setStep({ step: 2 }));
  },
);

const handleCalendarTourResourceAdded = takeEvery(
  CalendarTourActions.resourceAssignedToEvent,
  function* handle() {
    yield put(CalendarTourActions.setStep({ step: 3 }));
  },
);

const handleCalendarTourEventCreated = takeEvery(
  CalendarTourActions.eventCreated,
  function* handle(action) {
    yield put(CalendarTourActions.setEvent({ event: action.payload.event }));
    yield put(CalendarTourActions.setStep({ step: 4 }));
  },
);

const handleCalendarTourEventUpdated = takeEvery(
  CalendarTourActions.eventUpdated,
  function* handle(action) {
    const actionEvent = action.payload.event;
    const event = yield select((state) => selectTourEvent(state));

    if (actionEvent.id !== event.id) {
      return;
    }

    const currentStepIndex = yield select((state) => selectCurrentStepIndex(state));

    const hasResizedEvent = actionEvent.start === event.start && actionEvent.end !== event.end;
    const hasMovedEvent = actionEvent.start !== event.start && actionEvent.end !== event.end;

    if (hasResizedEvent && currentStepIndex === 4) {
      yield put(CalendarTourActions.setStep({ step: 5 }));
    } else if (hasMovedEvent && currentStepIndex === 5) {
      yield put(CalendarTourActions.setStep({ step: 6 }));
    }
  },
);

const handleCalendarTourEventsFiltered = takeEvery(
  CalendarTourActions.eventsFiltered,
  function* handle() {
    yield put(CalendarTourActions.setStep({ step: 7 }));
  },
);

const handleCalendarTourResourcesViewSelected = takeEvery(
  CalendarTourActions.resourcesViewSelected,
  function* handle() {
    yield put(CalendarTourActions.complete());
  },
);

export default function* handleCalendarTour() {
  yield all([
    handleCalendarTourStarted,
    handleCalendarTourCompleted,
    handleCalendarTourEventPopoverOpened,
    handleCalendarTourEventUpdated,
    handleCalendarTourJobAdded,
    handleCalendarTourResourceAdded,
    handleCalendarTourEventCreated,
    handleCalendarTourEventsFiltered,
    handleCalendarTourResourcesViewSelected,
  ]);
}
