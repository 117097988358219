import React from 'react';
import { Heading } from '@chakra-ui/react';

import TimelineItem, { TimelineItemProps } from '~/components/TimelineItem';
import { useAppSelector } from '~/redux/store';
import { JobTimelineJobDuplicated } from '~/types/job';
import { selectJobWithEdits } from '~/redux/jobs/selectors';
import Routes from '~/pages/routes';
import LinkButton from '~/components/LinkButton';

const TimelineJobDuplicated = ({
  event, ...rest
}: {
  event: JobTimelineJobDuplicated;
} & TimelineItemProps) => {
  const duplicateJob = useAppSelector((state) => selectJobWithEdits(state, event.jobId));

  return (
    <TimelineItem
      {...rest}
      event={event}
    >
      <Heading textTransform="none" size="sm" mb={1}>
        JOB DUPLICATED
      </Heading>

      <LinkButton isGreen noUnderline to={Routes.job({ jobId: event.originalJobId })}>
        {`#JOB-${event.originalJobCode} - ${event.originalJobName}`}
      </LinkButton>
      {` → #JOB-${duplicateJob.code}`}
    </TimelineItem>
  );
};

export default TimelineJobDuplicated;
