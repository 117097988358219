import React, { useState } from 'react';
import {
  FormControl, FormLabel, Skeleton, Switch,
} from '@chakra-ui/react';
import { useAppSelector } from '~/redux/store';
import { selectJobWithEdits } from '~/redux/jobs/selectors';
import TurnOnDialog from '~/components/AutoSendLiveGeo/TurnOnDialog';
import TurnOffDialog from '~/components/AutoSendLiveGeo/TurnOffDialog';

const AutoSendLiveGeo = ({ jobId }: { jobId: string }) => {
  const job = useAppSelector((state) => selectJobWithEdits(state, jobId));
  const [turningOn, setTurningOn] = useState(false);
  const [turningOff, setTurningOff] = useState(false);
  const noContacts = (!job?.jobContactId && !job?.siteContactId);
  const disabled = noContacts || !!job.liveGeoSent;
  let tooltip = '';
  if (noContacts) {
    tooltip = 'A job or site contact is required';
  } else if (job.liveGeoSent) {
    tooltip = 'The report has already been sent to the customer';
  }

  return (
    <Skeleton isLoaded={!!job}>
      <FormControl display="flex" alignItems="center" mt="4" isDisabled={disabled}>
        <Switch
          size="lg"
          isDisabled={disabled}
          title={tooltip}
          isChecked={!noContacts && job?.liveGeoAutoSend?.enabled}
          onChange={() => {
            if (job?.liveGeoAutoSend?.enabled) {
              setTurningOff(true);
            } else {
              setTurningOn(true);
            }
          }}
        />
        <FormLabel mb={0} ml={3} textTransform="none">
          Auto-send live drill logs to customer
        </FormLabel>
      </FormControl>
      <TurnOnDialog
        onClose={() => setTurningOn(false)}
        isOpen={turningOn}
        jobId={jobId}
      />
      <TurnOffDialog
        onClose={() => setTurningOff(false)}
        isOpen={turningOff}
        jobId={jobId}
      />
    </Skeleton>
  );
};

export default AutoSendLiveGeo;
