import {
  Box, Button, GridItem, Heading,
  HStack,
  Icon, IconButton, SkeletonText, Spacer, Stack, Text, VStack,
} from '@chakra-ui/react';
import {
  find, last, orderBy, values,
} from 'lodash';
import React, { useEffect, useState } from 'react';
import { MdFileDownload } from 'react-icons/md';
import { useHistory } from 'react-router';
import { ReactComponent as DrillIcon } from '~/assets/live_geo_drill_icon.svg';
import AutoSendLiveGeo from '~/components/AutoSendLiveGeo';
import Card from '~/components/Card';
import CardTitle from '~/components/CardTitle';
import ImageGallery from '~/components/ImageGallery';
import NotFound from '~/components/NotFound';
import PagePrintPreview from '~/components/PagePrintPreview';
import SendConfirmationModal from '~/components/SendConfirmationModal';
import SentStatus from '~/components/SentStatus';
import { formatJobCode, formatJobName } from '~/helpers/job';
import useTrackedAction from '~/hooks/useTrackedAction';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import PageHeader from '~/layouts/PageHeader';
import PrimarySecondaryColumns from '~/layouts/PrimarySecondaryColumns';
import { DrillVisual } from '~/pages/LiveGeo/components/DrillVisual';
import { InstallVisual } from '~/pages/LiveGeo/components/InstallVisual';
import LiveGeoLogsTable from '~/pages/LiveGeo/components/LiveGeoLogsTable';
import Routes from '~/pages/routes';
import { triggerBackfillTypesFetch } from '~/redux/backfillTypes/actions';
import { triggerCasingTypesFetch } from '~/redux/casingTypes/actions';
import { triggerClearanceTechniquesFetch } from '~/redux/clearanceTechniques/actions';
import { selectCurrentUser, selectTenantPowerUps } from '~/redux/currentUser/selectors';
import { CustomerContactActions } from '~/redux/customerContacts/actions';
import { selectCustomerContactById } from '~/redux/customerContacts/selectors';
import { CustomerActions } from '~/redux/customers/actions';
import { selectCustomerById } from '~/redux/customers/selectors';
import { triggerDrillingTechniquesFetch } from '~/redux/drillingTechniques/actions';
import { EquipmentActions } from '~/redux/equipment/actions';
import { selectEquipment } from '~/redux/equipment/selectors';
import { selectFetchState } from '~/redux/fetch/selectors';
import { triggerJobFetch } from '~/redux/jobs/actions';
import { selectJobShortAddress, selectJobWithEdits } from '~/redux/jobs/selectors';
import { LiveGeoActions } from '~/redux/liveGeo/actions';
import { selectBoreholesByJobId, selectCPTByJobId } from '~/redux/liveGeo/selectors';
import { triggerPiezoTypesFetch } from '~/redux/piezoTypes/actions';
import { triggerRefusalReasonsFetch } from '~/redux/refusalReasons/actions';
import { triggerSoilTypesFetch } from '~/redux/soilTypes/actions';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import { triggerSurfaceTypesFetch } from '~/redux/surfaceTypes/actions';
import { Borehole, CPT } from '~/types/geotechnical';
import fetchJson from '~/utils/fetchJson';
import { useRouteParams } from '~/utils/RouteGenerator';

const LiveGeoLogsPage = () => {
  const history = useHistory();
  const powerUps = useAppSelector((state) => selectTenantPowerUps(state));
  const { jobId, logId } = useRouteParams(Routes.jobLiveGeo);
  const job = useAppSelector((state) => selectJobWithEdits(state, jobId));
  const currentUser = useAppSelector((state) => selectCurrentUser(state));
  const { isLoading: isLoadingJob } = useAppSelector((s) => selectFetchState(s, jobId));
  const [visualType, setVisualType] = useState<'drill' | 'install'>('drill');
  const { data: geotechnical, isLoading: isLoadingGeotechnical } = useTrackedFetch({
    key: `borehole-${jobId}`,
    trigger: () => LiveGeoActions.fetchForJob({ jobId }),
    selector: (s) => ({
      boreholes: selectBoreholesByJobId(s, jobId),
      cpts: selectCPTByJobId(s, jobId),
    }),
  });
  const { data: equipment, isLoading: isLoadingEquipment } = useTrackedFetch({
    key: 'equipment',
    selector: selectEquipment,
    trigger: () => EquipmentActions.fetch(),
  });

  const {
    data: customer,
  } = useTrackedFetch({
    trigger: () => CustomerActions.fetch({ customerId: job?.customerId }),
    selector: (s) => selectCustomerById(s, job?.customerId),
    key: `customer-${job?.customerId}`,
  });
  const {
    data: customerMainContact,
  } = useTrackedFetch({
    trigger: () => CustomerContactActions.fetch({ id: customer?.mainContactId }),
    selector: (s) => selectCustomerContactById(s, customer?.mainContactId),
    key: `contact-${customer?.mainContactId}`,
    enabled: !!customer?.mainContactId,
  });
  const {
    data: jobContact,
  } = useTrackedFetch({
    trigger: () => CustomerContactActions.fetch({ id: job?.jobContactId }),
    selector: (s) => selectCustomerContactById(s, job?.jobContactId),
    key: `contact-${job?.jobContactId}`,
    enabled: !!job?.jobContactId,
  });

  const { data: drillingTechniques } = useTrackedFetch({
    key: 'drilling-techniques',
    trigger: () => triggerDrillingTechniquesFetch({ force: false }),
    selector: (state) => state.drillingTechniques.data.byId,
  });

  const { data: soilTypes } = useTrackedFetch({
    key: 'soil-types',
    trigger: () => triggerSoilTypesFetch({ force: false }),
    selector: (state) => state.soilTypes.data.byId,
  });
  const { data: clearanceTechniques } = useTrackedFetch({
    key: 'clearance-techniques',
    trigger: () => triggerClearanceTechniquesFetch({ force: false }),
    selector: (state) => state.clearanceTechniques.data.byId,
  });
  const { data: casingTypes } = useTrackedFetch({
    key: 'casing-types',
    trigger: () => triggerCasingTypesFetch({ force: false }),
    selector: (state) => state.casingTypes.data.byId,
  });
  const { data: refusalReasons } = useTrackedFetch({
    key: 'refusal-reasons',
    trigger: () => triggerRefusalReasonsFetch({ force: false }),
    selector: (state) => state.refusalReasons.data.byId,
  });
  const { data: backfillTypes } = useTrackedFetch({
    key: 'backfill-types',
    trigger: () => triggerBackfillTypesFetch({ force: false }),
    selector: (state) => state.backfillTypes.data.byId,
  });
  const { data: surfaceTypes } = useTrackedFetch({
    key: 'surface-types',
    trigger: () => triggerSurfaceTypesFetch({ force: false }),
    selector: (state) => state.surfaceTypes.data.byId,
  });

  const { data: piezoTypes } = useTrackedFetch({
    key: 'piezo-types',
    trigger: () => triggerPiezoTypesFetch({ force: false }),
    selector: (state) => state.piezoTypes.data.byId,
  });

  const [showSendModal, setShowSendModal] = useState(false);

  const shortAddress = useAppSelector((state) => selectJobShortAddress(state, jobId));

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(triggerJobFetch({ jobId }));
  }, []);

  const { trigger: onSendClicked, isLoading: isSending } = useTrackedAction({
    trigger: ({ name, email }) => LiveGeoActions.send({
      jobId,
      recipients: [{
        email,
        name,
      }],
    }),
  });

  const [isDownloadingPdfData, setIsDownloadingPdfData] = useState(false);
  const { trigger: onDownloadClicked, isLoading: isDownloading } = useTrackedAction({
    trigger: (
      { boreholes, cpts }:
      { boreholes: Borehole[], cpts: CPT[] },
    ) => LiveGeoActions.downloadPDF({
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      tenant: currentUser?.tenant,
      customer,
      job,
      boreholes,
      cpts,
      drillingTechniques: Object.values(drillingTechniques),
      soilTypes: Object.values(soilTypes),
      clearanceTechniques: Object.values(clearanceTechniques),
      casingTypes: Object.values(casingTypes),
      refusalReasons: Object.values(refusalReasons),
      backfillTypes: Object.values(backfillTypes),
      surfaceTypes: Object.values(surfaceTypes),
      piezoTypes: Object.values(piezoTypes),
      equipment: equipment
        .map(({
          id, name, nickname, hammerEfficiency,
        }) => ({
          id, name, nickname, hammerEfficiency,
        })),
      contact: jobContact || customerMainContact,
      coordinateSystem: currentUser?.tenant?.coordinateSystem,
    }),
  });

  const hasSentLogs = !!job?.liveGeoSent;
  const recipient = last(orderBy(values(job?.liveGeoRecipients), (r) => r.sent));

  const isLoading = isLoadingJob || isLoadingGeotechnical || isLoadingEquipment;

  const selectedBorehole = geotechnical
    ? find(geotechnical?.boreholes, (bh) => bh.id === logId)
    : null;

  const selectedCpt = geotechnical
    ? find(geotechnical?.cpts, (bh) => bh.id === logId)
    : null;

  if (!isLoading && !job) {
    return <NotFound backTo={Routes.jobList({})}>Job not found</NotFound>;
  }

  let visual = <SkeletonText noOfLines={2} />;
  if (!isLoading) {
    visual = visualType === 'install'
      ? (
        <InstallVisual
          surfaceTypes={surfaceTypes}
          backfillTypes={backfillTypes}
          borehole={selectedBorehole}
        />
      ) : (
        <DrillVisual
          drillingTechniques={drillingTechniques}
          clearanceTechniques={clearanceTechniques}
          soilTypes={soilTypes}
          equipment={equipment}
          borehole={selectedBorehole}
        />
      );
  }

  return (
    <>
      <PrimarySecondaryColumns>
        <PageHeader backButton>
          <HStack align="stretch">
            <Stack align="stretch">
              <HStack align="stretch">
                <Icon boxSize={8} as={DrillIcon} />
                <Heading size="lg">Live Geo logs</Heading>
              </HStack>
              <SentStatus
                isSent={hasSentLogs}
                sentText="logs sent"
                draftText="draft"
              />
            </Stack>
            <Spacer />
            <Box mr="2" alignSelf="flex-end">
              {powerUps?.geotechnicalReport && (
              <IconButton
                aria-label="Print report"
                isLoading={isDownloading || isDownloadingPdfData}
                isDisabled={isDownloading || isDownloadingPdfData}
                onClick={async () => {
                  setIsDownloadingPdfData(true);
                  const dataWithImages = await fetchJson<{
                    boreholes: Borehole[],
                    cpts: CPT[],
                  }>(`/api/geotechnical/for-job-with-image-links/${jobId}`);
                  onDownloadClicked(dataWithImages);
                  setIsDownloadingPdfData(false);
                }}
                variant="ghost"
                colorScheme="brandDark"
                onMouseDown={(e) => e.preventDefault()}
                icon={<Icon fontSize="1.8rem" as={MdFileDownload} />}
              />
              )}
            </Box>
            <Box alignSelf="flex-end">
              <Button
                size="lg"
                disabled={isSending}
                isLoading={isSending}
                onClick={() => {
                  setShowSendModal(true);
                }}
              >
                {hasSentLogs ? 'Resend' : 'Send'}
              </Button>
            </Box>
          </HStack>
        </PageHeader>
        <GridItem gridArea="primary">
          <PagePrintPreview>
            {isLoading
              ? <SkeletonText noOfLines={2} />
              : (
                <>
                  <VStack spacing={1} alignItems="flex-start" mb={12}>
                    <Heading mb={1} size="md">{customer?.companyName}</Heading>
                    <Text fontWeight="semibold">{`${formatJobCode(job.code)} ${formatJobName(job)}`}</Text>
                    <Text fontWeight="semibold" color="magnetize.brand-4">{shortAddress}</Text>
                  </VStack>
                  <LiveGeoLogsTable
                    boreholes={geotechnical.boreholes}
                    cpts={geotechnical.cpts}
                    equipment={equipment}
                    selectedId={logId}
                    coordinateSystem={currentUser?.tenant?.coordinateSystem}
                    drillingTechniques={drillingTechniques}
                    soilTypes={soilTypes}
                    casingTypes={casingTypes}
                    clearanceTechniques={clearanceTechniques}
                    refusalReasons={refusalReasons}
                    surfaceTypes={surfaceTypes}
                    backfillTypes={backfillTypes}
                    piezoTypes={piezoTypes}
                    onLogSelected={(id, type) => {
                      if (type) {
                        setVisualType(type);
                      } else {
                        setVisualType('drill');
                      }
                      history.replace(Routes.jobLiveGeo({ jobId, logId: id }));
                    }}
                  />
                </>
              )}
          </PagePrintPreview>
        </GridItem>
        <GridItem gridArea="secondary">
          <Card width={340} pb={12} mb={5}>

            <CardTitle>Live GEO</CardTitle>

            <AutoSendLiveGeo jobId={jobId} />
          </Card>
          {(isLoading || selectedBorehole) && (
          <Card width={340} pb={12}>
            {visual}
          </Card>
          )}
          {!!selectedBorehole && (
          <Box w={340} mt={8}>
            <ImageGallery
              imageWidth={109}
              files={[
                ...(selectedBorehole.photos || []),
                ...(selectedBorehole.undergroundServicePhotos || []),
                ...orderBy([
                  ...(selectedBorehole.waterLevel ? [{
                    depth: selectedBorehole.waterLevel,
                    photos: selectedBorehole.waterLevelPhotos,
                  }] : []),
                  ...Object.values(selectedBorehole.piezoLogs || {})
                    .map(({ startDepth, photos }) => ({ depth: startDepth, photos })),
                  ...Object.values(selectedBorehole.soilLayers || {})
                    .map(({ startDepth, photos }) => ({ depth: startDepth, photos })),
                  ...Object.values(selectedBorehole.sptTests || {})
                    .map(({ depth, photos }) => ({ depth, photos })),
                  ...Object.values(selectedBorehole.pushTubes || {})
                    .map(({ depth, photos }) => ({ depth, photos })),
                  ...Object.values(selectedBorehole.drillLogs || {})
                    .map(({ depth, photos }) => ({ depth, photos })),
                ], (x) => x.depth)
                  .flatMap((p) => (p.photos || [])),
                ...(selectedBorehole.endPhotos || []),
              ]}
            />
          </Box>
          )}
          {!!selectedCpt && (
          <Box w={340} mt={8}>
            <ImageGallery
              imageWidth={109}
              files={[
                ...(selectedCpt.photos || []),
                ...(selectedCpt.undergroundServicePhotos || []),
                ...(selectedCpt.logPhotos || []),
              ]}
            />
          </Box>
          )}
        </GridItem>
      </PrimarySecondaryColumns>
      {showSendModal && (
      <SendConfirmationModal
        title={hasSentLogs ? 'Resend live geo logs' : 'Send live geo logs'}
        subtitle={`A link will be emailed to your customer for them to view and download
         all Live Geo logs for ${formatJobCode(job.code)} - ${formatJobName(job)}.`}
        onClose={() => setShowSendModal(false)}
        onConfirm={({ name, email, message }) => {
          setShowSendModal(false);
          onSendClicked({ name, email, message });
        }}
        contact={recipient || jobContact || customerMainContact}
      />
      )}
    </>
  );
};

export default LiveGeoLogsPage;
