import {
  format, isSameDay, startOfDay,
} from 'date-fns';
import { keyBy, round } from 'lodash';
import { formatJobCode, formatJobName } from '~/helpers/job';
import { Job } from '~/types/job';
import { Staff } from '~/types/staff';
import { Tag } from '~/types/tag';
import { getTimeRecordTotalMinutes, TimeRecord, timeTypes } from '~/types/time';

export interface ExpandedTimeRecord extends TimeRecord {
  isSplit?: boolean;
}

export interface FormattedTimeRecord extends ExpandedTimeRecord {
  id: string;
  date: string;
  formattedDate: string;
  startTime: string;
  endTime: string;
  downtimeHours: number;
  travelTimeHours: number;
  totalHours: number;
  staffId: string;
  staffName: string;
  staffTags: Tag[];
  jobId: string;
  jobName: string;
  note?: string;
}

export const formatTimeRecords = (
  timeRecords: TimeRecord[], staff: Staff[], jobs: Job[], tags: Tag[],
) : FormattedTimeRecord[] => {
  if (!timeRecords || !staff || !jobs) {
    return [];
  }

  const staffById = keyBy(staff, (s) => s.id);
  const jobsById = keyBy(jobs, (j) => j.id);
  const tagsById = keyBy(tags, (t) => t.id);

  return timeRecords
    .map((tr) => {
      const timeRecordStaff = staffById[tr.staffId];
      const timeRecordJob = jobsById[tr.jobId];
      const timeRecordStaffTags = timeRecordStaff?.tags ?? [];

      if (!timeRecordStaff || (tr.jobId && !timeRecordJob)) {
        return null;
      }

      return {
        ...tr,
        formattedDate: format(tr.start, 'dd MMM yyyy'),
        startTime: format(tr.start, 'h:mmaaa'),
        endTime: format(tr.end, 'h:mmaaa'),
        downtimeHours: round(tr.downtimeMinutes / 60, 2),
        travelTimeHours: round(tr.travelTimeMinutes / 60, 2),
        totalHours: round(getTimeRecordTotalMinutes(tr) / 60, 2),
        staffName: `${timeRecordStaff.name}${timeRecordStaff.isDeleted ? ' (deleted)' : ''}`,
        staffTags: timeRecordStaffTags.map((id) => tagsById[id]),
        jobName: tr.jobId
          ? `${formatJobCode(timeRecordJob.code)} – ${formatJobName(timeRecordJob)}`
          : (timeTypes.find((t) => t.type === tr.type)?.label || tr.type),
      };
    })
    .filter((tr) => !!tr);
};

/**
 * Takes time records which may cover two days and splits them into records
 * which only span a single day. This is NOT appropriate to use in edit scenarios
 * @param timeRecords original time records
 * @returns a list of time records split by day
 */
export const expandTimeRecords = (
  timeRecords: TimeRecord[],
  start: number,
  end:number,
) : ExpandedTimeRecord[] => {
  const nextTimeRecords: ExpandedTimeRecord[] = [];
  timeRecords.forEach((r) => {
    if (isSameDay(r.start, r.end)) {
      nextTimeRecords.push(r);
    } else {
      nextTimeRecords.push({
        ...r,
        end: startOfDay(r.end).getTime(),
      });
      nextTimeRecords.push({
        ...r,
        id: `${r.id}-overflow-day`,
        start: startOfDay(r.end).getTime(),
        travelTimeMinutes: 0,
        downtimeMinutes: 0,
        date: startOfDay(r.end).toISOString(),
      });
    }
  });
  return nextTimeRecords.filter((tr) => tr.start >= start && tr.end <= end);
};
