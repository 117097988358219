import { CloseIcon } from '@chakra-ui/icons';
import {
  Box, Icon, IconButton, NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr, useDisclosure,
} from '@chakra-ui/react';
import { AddOutlined } from '@material-ui/icons';
import { isEqual, round, sortBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import LinkButton from '~/components/LinkButton';
import RemoveButtonWithConfirmation from '~/components/RemoveButtonWithConfirmation';
import useDebouncedState from '~/hooks/useDebouncedState';
import PriceBookBrowseModal from '~/pages/Job/components/PriceBook/PriceBookBrowseModal';
import ConsumablesActions from '~/redux/consumables/actions';
import { selectConsumablesForJob, selectQuoteConsumables } from '~/redux/consumables/selectors';
import { selectPriceById } from '~/redux/prices/selectors';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import { ConsumableDef } from '~/types/consumable';
import { LineItem } from '~/types/lineItem';

interface JobConsumableViewModel extends ConsumableDef {
  id?: string;
  key: string;
  jobId: string;
  quantity: number;
  allocated: number | undefined;
  isFromQuote?: boolean;
}

interface ConsumableTableRowProps {
  consumable: JobConsumableViewModel;
}

const ConsumableTableRow = ({ consumable }: ConsumableTableRowProps) => {
  const dispatch = useAppDispatch();
  const price = useAppSelector((state) => selectPriceById(state, consumable.priceId));
  const [quantityString, setQuantityString] = useState(consumable.quantity.toString() ?? '0');
  const [quantity, setQuantity] = useDebouncedState({
    value: consumable.quantity,
    dispatchUpdate: (value) => {
      dispatch(ConsumablesActions.set({
        jobId: consumable.jobId,
        set: {
          priceId: consumable.priceId,
          name: consumable.name,
          quantity: value,
        },
      }));
    },
  });

  const deleteConsumable = () => {
    dispatch(ConsumablesActions.delete({
      jobId: consumable.jobId,
      consumableId: consumable.id,
    }));
  };

  const remaining = (consumable.allocated !== undefined)
    ? round(consumable.allocated - quantity, 2)
    : undefined;

  const isOverAllocation = remaining !== undefined && remaining < 0;

  return (
    <Tr fontSize="12px">
      <Td>{consumable.name}</Td>
      <Td
        textAlign="right"
        color={consumable.allocated === undefined ? 'magnetize.text-4' : undefined}
      >
        {consumable.allocated ?? '0'}
      </Td>
      <Td px="0">
        <NumberInput
          variant="ghost"
          size="sm"
          min={0}
          my="-4"
          mr="-2"
          color={isOverAllocation ? 'magnetize.support-warning' : undefined}
          fontWeight={isOverAllocation ? 'semibold' : 'regular'}
          onFocus={(e) => {
            e.target.select();
          }}
          onChange={(e) => {
            setQuantityString(e);
            const newQuantity = Number(e);
            if (!Number.isNaN(newQuantity)) {
              setQuantity(newQuantity);
            }
          }}
          value={quantityString}
        >
          <NumberInputField
            borderRadius="sm"
            fontWeight={isOverAllocation ? 'semibold' : 'regular'}
          />
          <NumberInputStepper>
            <NumberIncrementStepper
              fontSize="0.7rem"
              pt="1"
            />
            <NumberDecrementStepper
              fontSize="0.7rem"
              pb="1"
            />
          </NumberInputStepper>
        </NumberInput>
      </Td>
      <Td
        isNumeric
        pl="0"
        color={isOverAllocation ? 'magnetize.support-warning' : undefined}
        fontWeight={isOverAllocation ? 'semibold' : 'regular'}
      >
        {remaining ?? '-'}
      </Td>
      <Td position="relative" color="magnetize.text-4">
        {price?.unit || consumable.unit}
        { !consumable.isFromQuote && consumable.id && (
          <Box position="absolute" right="-2" top="2.5">
            <RemoveButtonWithConfirmation
              confirmationTitle="Remove consumable?"
              confirmationMessage={(
                <Text>
                  {`Are you sure you want to remove ${consumable.name} from this job?`}
                </Text>
              )}
              onRemoveConfirmed={() => deleteConsumable()}
            >
              {({ onClick }) => (
                <Tooltip label="Remove consumable" openDelay={500}>
                  <Box>
                    <IconButton
                      variant="link"
                      size="sm"
                      height="8"
                      aria-label="Remove consumable"
                      icon={<CloseIcon fontSize="0.5rem" />}
                      onClick={onClick}
                      tabIndex={-1}
                    />
                  </Box>
                </Tooltip>
              )}
            </RemoveButtonWithConfirmation>
          </Box>
        )}
      </Td>
    </Tr>
  );
};

interface JobConsumablesTableProps {
  jobId: string;
}

const JobLegacyConsumablesTable = ({ jobId }: JobConsumablesTableProps) => {
  const actualConsumables = useAppSelector(
    (s) => selectConsumablesForJob(s, jobId),
    (a, b) => isEqual(a, b),
  );
  const quoteConsumablesByKey = useAppSelector(
    (s) => selectQuoteConsumables(s, jobId),
    (a, b) => isEqual(a, b),
  );

  const consumables = useMemo(() => {
    const actuals: JobConsumableViewModel[] = actualConsumables.map((c) => {
      const key = c.priceId || c.name;
      return ({
        ...c,
        ...quoteConsumablesByKey[key],
        isFromQuote: !!quoteConsumablesByKey[key],
        key,
        jobId,
      });
    });

    const quoted: JobConsumableViewModel[] = Object.entries(quoteConsumablesByKey)
      .filter(([key]) => !actuals.find((c) => c.key === key))
      .map(([key, consumable]) => ({
        ...consumable,
        quantity: 0,
        jobId,
        id: undefined,
        key,
      }));

    return sortBy(
      [...actuals, ...quoted],
      (c) => !c.isFromQuote,
      (c) => c.name,
    );
  }, [actualConsumables, quoteConsumablesByKey, jobId]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useAppDispatch();

  // TODO we need to expand out bundle line items here....
  const addConsumables = (lineItems: LineItem[]) => {
    dispatch(ConsumablesActions.addToJob({
      jobId,
      consumables: lineItems.map((li) => ({
        name: li.name,
        priceId: li.priceId,
        quantityDelta: 0,
      })),
    }));
  };

  return (
    <Box mx={2}>
      <Table>
        <Thead>
          <Tr>
            <Th pr="0">Consumable</Th>
            <Th w="70px" pr="0">Quoted</Th>
            <Th w="70px" pr="0">Logged</Th>
            <Th w="100px" pr="0">Remaining</Th>
            <Th w="70px" pr="0">Unit</Th>
          </Tr>
        </Thead>
        <Tbody>
          {consumables.map((c) => (
            <ConsumableTableRow
              key={c.key}
              consumable={c}
            />
          ))}
        </Tbody>
      </Table>

      <LinkButton
        isGreen
        noUnderline
        onClick={() => onOpen()}
        leftIcon={<Icon fontSize="md" as={AddOutlined} />}
        mt="6"
        mb="2"
      >
        Add consumables
      </LinkButton>

      {isOpen && (
        <PriceBookBrowseModal
          titleText="Add consumables to job"
          addButtonText="Add"
          onAdd={addConsumables}
          onClose={onClose}
          filterCategories={['consumable']}
        />
      )}
    </Box>
  );
};

export default JobLegacyConsumablesTable;
