import {
  Box, Icon, SkeletonText, Table, Tbody, Td, Text, Th, Thead, Tr,
} from '@chakra-ui/react';
import { AddOutlined } from '@material-ui/icons';
import React, { useMemo, useState } from 'react';
import EditTimeRecordModal from '~/components/EditTimeRecordModal';
import LinkButton from '~/components/LinkButton';
import { useFormatInTimezone } from '~/hooks/useFormatInTimezone';
import { useResourceSummary } from '~/queries/useResourcesQuery';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import TimesheetActions from '~/redux/timesheets/actions';
import { selectTimeRecordsForJob } from '~/redux/timesheets/selectors';
import { getTimeRecordTotalMinutes, TimeRecord } from '~/types/time';
import { formatDuration, minutesToDuration } from '~/utils/parseTime';

const calcTotalDuration = (r: TimeRecord) => (
  formatDuration(minutesToDuration(getTimeRecordTotalMinutes(r)))
);

const JobTimeRecordRow = ({
  timeRecord,
}: { timeRecord: TimeRecord }) => {
  const { data: staff } = useResourceSummary({ type: 'staff', id: timeRecord.staffId });
  const timezone = useMemo(() => Intl.DateTimeFormat().resolvedOptions().timeZone, []);
  const formatInTimeZone = useFormatInTimezone();

  return (
    <Tr>
      <Td pr="0">
        {`${formatInTimeZone(timeRecord.start, timeRecord.timezone || timezone, 'dd MMM yyy')} `}
        {timeRecord.timezone && timeRecord.timezone !== timezone && (
        <Text color="magnetize.text-4" as="span">
          {formatInTimeZone(timeRecord.start, timeRecord.timezone, 'zzz') }
        </Text>
        )}
      </Td>
      <Td>
        {(staff)
          ? <Text fontWeight="semibold">{staff?.displayName}</Text>
          : <SkeletonText noOfLines={1} />}
      </Td>
      <Td pr="0">{formatDuration(minutesToDuration(timeRecord.travelTimeMinutes || 0))}</Td>
      <Td pr="0">{formatInTimeZone(timeRecord.start, timeRecord.timezone || timezone, 'h:mmaaa')}</Td>
      <Td pr="0">{formatInTimeZone(timeRecord.end, timeRecord.timezone || timezone, 'h:mmaaa')}</Td>

      <Td pr="0">{formatDuration(minutesToDuration(timeRecord.downtimeMinutes))}</Td>
      <Td pr="0">{calcTotalDuration(timeRecord)}</Td>
    </Tr>
  );
};

const JobTimesheetsTable = ({ jobId }: { jobId: string }) => {
  const timeRecords = useAppSelector((state) => selectTimeRecordsForJob(state, jobId));
  const dispatch = useAppDispatch();

  const [isEditing, setIsEditing] = useState(false);

  const startEditing = () => setIsEditing(true);
  const finishEditing = () => setIsEditing(false);

  const onSave = (tr: TimeRecord) => {
    dispatch(TimesheetActions.saveJobTimeRecord({
      jobId,
      timeRecord: tr,
    }));
  };

  return (
    <Box mx={2}>
      <Table variant="simple" fontSize="12px">
        <Thead>
          <Tr>
            <Th w="115px" pr="0">Date</Th>
            <Th pr="0">Staff</Th>
            <Th w="70px" pr="0">Travel</Th>
            <Th w="70px" pr="0">Start</Th>
            <Th w="70px" pr="0">End</Th>
            <Th w="70px" pr="0">Down</Th>
            <Th w="70px" pr="0">Total</Th>
          </Tr>
        </Thead>
        <Tbody>
          {timeRecords.map((r) => (
            <JobTimeRecordRow
              key={r.id}
              timeRecord={r}
            />
          ))}
        </Tbody>
      </Table>

      <Box>
        <LinkButton
          mt="6"
          mb="2"
          isGreen
          noUnderline
          onClick={startEditing}
          leftIcon={<Icon fontSize="md" as={AddOutlined} />}
        >
          Add time
        </LinkButton>
        {isEditing && (
          <EditTimeRecordModal
            onSave={onSave}
            jobId={jobId}
            onClose={finishEditing}
          />
        )}
      </Box>
    </Box>
  );
};

export default JobTimesheetsTable;
