import React from 'react';
import {
  Box,
  Heading,
  HStack, Icon, Text,
} from '@chakra-ui/react';
import {
  CheckCircleRounded, EditRounded, Error, SendRounded,
} from '@material-ui/icons';
import { format } from 'date-fns';
import { parseISO } from 'date-fns/esm';
import CurrencyText from '~/components/CurrencyText';
import { Quote, QuoteStatus as QuoteStatusType } from '~/types/quote';
import calculateQuoteTotals from '~/utils/calculateQuoteTotals';

const QuoteStatusLabel = ({ status } : { status : QuoteStatusType }) => {
  switch (status) {
    case 'accepted':
      return (
        <Text fontWeight="bold" color="magnetize.brand-4">
          <Icon as={CheckCircleRounded} mr={1} mb="2.5px" />
          Quote accepted
        </Text>
      );
    case 'expired':
      return (
        <Text fontWeight="bold" color="magnetize.support-warning">
          <Icon as={Error} mr={1} mb="2.5px" />
          Quote expired
        </Text>
      );
    case 'sent':
      return (
        <Text fontWeight="bold">
          <Icon as={SendRounded} mr={1} mb="2.5px" fontSize="1.2em" />
          Quote sent
        </Text>
      );

    case 'draft':
      return (
        <Text fontWeight="bold">
          <Icon as={EditRounded} mr={1} mb="2.5px" fontSize="1.2em" />
          Quote variation
        </Text>
      );

    default:
      return (
        <Text fontWeight="bold">
          <Icon as={CheckCircleRounded} mr={1} mb="2.5px" />
          Quote defaulted
        </Text>
      );
  }
};

const QuoteStatus = ({ quote } : { quote : Quote }) => {
  const { totalCents } = calculateQuoteTotals(
    Object.values(quote.draft?.lineItems || quote.lineItems),
    quote.taxRate,
  );

  if (quote.status === 'draft') {
    return (
      <HStack alignItems="center" py={1}>
        <Heading as="h3" size="md" minW="95px" textAlign="left">QUOTE VARIATION</Heading>
      </HStack>
    );
  }

  return (
    <HStack alignItems="center" py={1}>
      <Heading as="h3" size="md" minW="95px" textAlign="left">{`QUOTE-${quote.code}`}</Heading>
      <HStack alignItems="baseline" flex={1}>
        <QuoteStatusLabel status={quote.status} />
        <Text>
          for
          {' '}
          <CurrencyText fontWeight="bold" value={totalCents} />
          {' '}
          (incl. tax)
        </Text>
        <Box flex={1} />
        {quote.status === 'sent' && quote.expiryDate && (
        <Text fontWeight="bold" color="magnetize.support-warning" paddingRight={3}>
          {`expires ${format(parseISO(quote.expiryDate), 'dd MMM yyyy')}`}
        </Text>
        )}
      </HStack>
    </HStack>
  );
};

export default QuoteStatus;
