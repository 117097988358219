import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';

export const PrimaryButton = (
  {
    children, disabled, ...props
  }:ButtonProps,
) => (
  <Button
    size="lg"
    height="45px"
    px="25px"
    bg="magnetize.brand-5"
    _hover={{
      bg: disabled ? undefined : 'magnetize.brand-3',
    }}
    disabled={disabled}
    {...props}
  >
    {children}
  </Button>
);
