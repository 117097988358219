import React, { useMemo } from 'react';
import SearchSelect from '~/components/SearchSelect';

const units = [
  { label: 'Fixed rate', value: '' },
  { label: 'Hour (h)', value: 'h' },
  { label: 'Day (d)', value: 'd' },
  { label: 'Kilogram (kg)', value: 'kg' },
  { label: 'Tonne (t)', value: 't' },
  { label: 'Meter (m)', value: 'm' },
  { label: 'Litre (l)', value: 'l' }];

const UnitSearchSelect = ({
  placeholder, value, maxLength, onInputLengthChanged, onChange,
}: {
  value: string;
  placeholder: string;
  maxLength?: number;
  onInputLengthChanged?: (length: number) => void;
  onChange: (value: string) => void;
}) => {
  const selectedValue = useMemo(
    () => units.find((o) => o.value === value) || (!!value && { value, label: value }),
    [value],
  );
  return (
    <SearchSelect
      createable
      isValidNewOption={(inputValue) => inputValue.length > 0
        && (!maxLength || inputValue.length <= maxLength)}
      hideSearchIcon
      value={selectedValue}
      onInputChange={(inputValue) => {
        if (onInputLengthChanged) {
          onInputLengthChanged(inputValue.length);
        }
      }}
      onChange={(o) => { onChange(o.value); }}
      onCreateOption={(name : string) => {
        onChange(name);
      }}
      options={units}
      placeholder={placeholder}
      menuShouldScrollIntoView={false}
    />
  );
};

export default UnitSearchSelect;
