import React, { useEffect } from 'react';
import {
  Box,
  Center,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { addDays, startOfDay } from 'date-fns';
import { max } from 'lodash';
import { FaCheckCircle, FaRegCalendarCheck } from 'react-icons/fa';
import { AiOutlineDollar } from 'react-icons/ai';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import { selectInvoicesByJobId } from '~/redux/invoices/selectors';
import { InvoiceActions } from '~/redux/invoices/actions';
import { QuoteActions } from '~/redux/quote/actions';
import { selectQuotesByJobId } from '~/redux/quote/selectors';
import { selectJobWithEdits } from '~/redux/jobs/selectors';
import calculateQuoteTotals from '~/utils/calculateQuoteTotals';
import { useLoggedStats } from '~/hooks/useLoggedStats';
import CurrencyText from '~/components/CurrencyText';
import { parseISODateLocal } from '~/utils/parseTime';
import CreateProgressInvoiceModal from '~/pages/Job/components/invoices/CreateProgressInvoiceModal';
import CreatePercentageInvoiceModal from '~/pages/Job/components/invoices/CreatePercentageInvoiceModal';
import { SquareButton } from '~/components/SquareButton';
import { ReactComponent as Helmet } from '~/assets/helmet-safety-solid.svg';
import PriceActions from '~/redux/prices/actions';
import { selectPrices } from '~/redux/prices/selectors';
import { Price } from '~/types/price';
import { Job } from '~/types/job';
import { triggerJobFetch } from '~/redux/jobs/actions';

const InvoiceTypeModal = ({
  jobId,
  onClose,
}: {
  jobId: string;
  onClose: () => void;
}) => {
  const { data: job } = useTrackedFetch<Job>({
    key: `job:${jobId}`,
    selector: (state) => selectJobWithEdits(state, jobId),
    trigger: () => triggerJobFetch({ jobId }),
    equalityMode: 'deep',
  });

  const { data: invoices } = useTrackedFetch({
    key: `job-invoices-${jobId}`,
    trigger: () => InvoiceActions.fetchForJob({ jobId }),
    selector: (state) => selectInvoicesByJobId(state, jobId),
  });
  const { data: quotes } = useTrackedFetch({
    key: `job-quotes-${jobId}`,
    trigger: () => QuoteActions.fetchForJob({ jobId }),
    selector: (state) => selectQuotesByJobId(state, jobId),
  });

  const { data: prices, isLoading: isLoadingLogs } = useTrackedFetch<Price[]>({
    key: 'prices',
    selector: (state) => selectPrices(state),
    trigger: () => PriceActions.fetch(),
    equalityMode: 'deep',
  });

  const progressInvoiceModalState = useDisclosure();
  const percentageInvoiceModalState = useDisclosure();

  useEffect(() => {
    if (invoices[0]?.toDate) {
      progressInvoiceModalState.onOpen();
    } else if (invoices[0]?.proportionOfQuote) {
      percentageInvoiceModalState.onOpen();
    }
  }, []);
  const datedInvoices = invoices.filter((i) => i.toDate);

  const isBudget = !job?.needsQuote;
  const fromDate = datedInvoices.length > 0
    ? max(datedInvoices.map((i) => addDays(parseISODateLocal(i.toDate), 1)))
    : null;
  const toDate = max([fromDate, startOfDay(new Date())]);
  const interval = fromDate && toDate && fromDate < toDate
    ? { start: fromDate, end: toDate }
    : null;

  const { total, count } = useLoggedStats({
    prices,
    jobId,
    interval,
  });

  const acceptedQuotes = quotes?.filter((q) => q.status === 'accepted');
  const unacceptedQuotes = quotes?.filter(
    (q) => q.status !== 'accepted' && q.status !== 'draft',
  );

  const acceptedTotal = acceptedQuotes.reduce(
    (acc, q) => acc
      + calculateQuoteTotals(Object.values(q.lineItems), q.taxRate || 0)
        .subtotalCents,
    0,
  );
  const unacceptedTotal = unacceptedQuotes.reduce(
    (acc, q) => acc
      + calculateQuoteTotals(Object.values(q.lineItems), q.taxRate || 0)
        .subtotalCents,
    0,
  );

  const quoted = isBudget ? unacceptedTotal : acceptedTotal;
  const totalLogs = total;
  const logs = count;

  if (progressInvoiceModalState.isOpen) {
    return <CreateProgressInvoiceModal jobId={jobId} onClose={onClose} />;
  }
  if (percentageInvoiceModalState.isOpen) {
    return <CreatePercentageInvoiceModal jobId={jobId} onClose={onClose} />;
  }
  return (
    <Modal closeOnOverlayClick={false} isOpen onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight="bold">
          Invoice Type
          <Text fontSize={14} fontWeight="normal" mt={3} fontFamily="body">
            How would you like to invoice the customer?
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <HStack mb={10} px="10%" alignItems="inherit">
            <Box flex={1} p={4}>
              <SquareButton
                onClick={() => {
                  progressInvoiceModalState.onOpen();
                }}
              >
                <VStack>
                  <Icon as={FaRegCalendarCheck} fontSize={18} />
                  <Text fontWeight="bold" fontSize={14}>
                    As logged
                  </Text>
                </VStack>
              </SquareButton>
              {logs !== 0 && (
                <Skeleton isLoaded={!isLoadingLogs}>
                  <Center mt={4}>
                    <Text fontWeight="bold">
                      <Icon as={Helmet} mr={2} fontSize={14} pb={1} />
                      {`${logs} logs accrued`}
                    </Text>
                  </Center>
                  <Center>
                    <Text textAlign="center">
                      {'est. '}
                      <CurrencyText value={totalLogs} />
                      {' (excl. tax)'}
                    </Text>
                  </Center>
                </Skeleton>
              )}
            </Box>
            <Box flex={1} p={4}>
              <SquareButton onClick={percentageInvoiceModalState.onOpen}>
                <VStack>
                  <Icon as={AiOutlineDollar} fontSize={18} />
                  <Text fontWeight="bold" fontSize={14}>
                    % or fixed
                  </Text>
                </VStack>
              </SquareButton>
              <Center mt={4}>
                <Text fontWeight="bold" color="magnetize.brand-4">
                  <Icon as={FaCheckCircle} mr={2} fontSize={14} pb={1} />
                  {isBudget ? 'Budgeted' : 'Quote approved'}
                </Text>
              </Center>
              <Center>
                <Text textAlign="center">
                  {'for '}
                  <CurrencyText value={quoted} />
                  {' (excl. tax)'}
                </Text>
              </Center>
            </Box>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default InvoiceTypeModal;
