import {
  HStack, Text, Box, AccordionPanel, AccordionButton, AccordionItem,
} from '@chakra-ui/react';
import React from 'react';

export const CountSectionAccordionItem = ({
  title, icon, children, count = null,
} : {
  title: string;
  icon: React.ReactNode;
  count?: number;
  children: React.ReactNode[];
}) => (
  <AccordionItem>
    <AccordionButton
      _hover={{ bg: '#fafafa' }}
      bg="inherit"
      p={0}
      margin={0}
    >
      <HStack
        width="100%"
        spacing={4}
        align="stretch"
        alignItems="center"
        py={4}
        borderBottomWidth={1}
        borderBottomColor="magnetize.ui-3"
      >
        <Text
          size="sm"
          fontWeight="600"
          px={3}
          color="magnetize.brand-4"
          minWidth="3rem"
          textAlign="left"
        >
          {count !== null ? count : children.length}
        </Text>
        {icon}
        <Text
          fontWeight="semibold"
          fontSize="md"
        >
          {title}
        </Text>
      </HStack>
    </AccordionButton>
    <AccordionPanel>
      <Box
        pb={12}
        borderBottomWidth={1}
        borderBottomColor="magnetize.ui-3"
      >
        {children}
      </Box>
    </AccordionPanel>
  </AccordionItem>
);
