import {
  Flex,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import { orderBy, uniqBy } from 'lodash';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import AddTimelineNote from '~/components/AddTimelineNote';
import CurrentUserAvatar from '~/components/CurrentUserAvatar';
import TimelineItem, { TimelineItemProps } from '~/components/TimelineItem';
import TimelineNote from '~/components/TimelineNote';
import TimelineScheduleAdded from '~/components/TimelineSchedule/TimelineScheduleAdded';
import TimelineScheduleRemoved from '~/components/TimelineSchedule/TimelineScheduleRemoved';
import TimelineScheduleChanged from '~/components/TimelineSchedule/TimelineScheduleChanged';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import { jobTimelineQuery } from '~/queries/jobTimeline';
import { EquipmentActions } from '~/redux/equipment/actions';
import { selectEquipment } from '~/redux/equipment/selectors';
import { addJobNote } from '~/redux/jobs/actions';
import StaffActions from '~/redux/staff/actions';
import { selectStaff } from '~/redux/staff/selectors';
import { useAppDispatch } from '~/redux/store';
import TimelineJobCreated from '~/components/TimelineJobCreated';
import TimelineStatus from '~/components/TimelineStatus';
import TimelineUnknownConsumableAdded from '~/components/TimelineUnknowConsumableAdded';
import { TimelineJobTagsChanged } from '~/components/TimelineJobTagsChanged';
import { TimelineQuoteQuery } from '~/components/TimelineQuoteQuery';
import { TimelineQuoteAccepted } from '~/components/TimelineQuoteAccepted';
import { TimelineQuoteAcceptedOnBehalf } from '~/components/TimelineQuoteAcceptedOnBehalf';
import { TimelineQuoteAcceptedReverted } from '~/components/TimelineQuoteAcceptedReverted';
import TimelineJobDuplicated from '~/components/TimelineJobDuplicated';

const JobTimeline = ({ jobId }: { jobId: string }) => {
  const [firstRenderTime] = useState(new Date().getTime());
  const [localNotes, setLocalNotes] = useState([]);
  const dispatch = useAppDispatch();
  const { data: timeline, isLoading } = useQuery(jobTimelineQuery(jobId));

  // Prefetch staff and equipment so that we can load names
  useTrackedFetch({
    key: 'staff',
    selector: selectStaff,
    trigger: () => StaffActions.fetch(),
  });
  useTrackedFetch({
    key: 'equipment',
    trigger: () => EquipmentActions.fetch(),
    selector: selectEquipment,
  });

  const timelineEvents = orderBy(uniqBy([...(timeline ?? []), ...localNotes], (n) => n.id), (t) => t.at, 'desc');
  const timelineComponents = timelineEvents.map((event, index) => {
    const commonProps: { key: string } & TimelineItemProps = {
      event,
      key: event.id,
      animateIn: event.at && event.at > firstRenderTime,
      last: index === timelineEvents.length - 1,
    };

    switch (event.type) {
      case 'job-created':
        return (
          <TimelineJobCreated
            {...commonProps}
            event={event}
          />
        );
      case 'job-duplicated':
        return (
          <TimelineJobDuplicated
            {...commonProps}
            event={event}
          />
        );
      case 'note':
        return (
          <TimelineNote
            {...commonProps}
            event={event}
          />
        );

      case 'unknown-consumable-added':
        return (
          <TimelineUnknownConsumableAdded
            {...commonProps}
            event={event}
          />
        );
      case 'job-status-manual':
      case 'job-status-automated':
        return (
          <TimelineStatus
            {...commonProps}
            event={event}
          />
        );
      case 'job-schedule-added':
        return (
          <TimelineScheduleAdded
            {...commonProps}
            event={event}
          />
        );
      case 'job-schedule-changed':
        return (
          <TimelineScheduleChanged
            {...commonProps}
            event={event}
          />
        );
      case 'job-schedule-removed':
        return (
          <TimelineScheduleRemoved
            {...commonProps}
            event={event}
          />
        );
      case 'job-tags-changed':
        return (
          <TimelineJobTagsChanged
            {...commonProps}
            event={event}
          />
        );
      case 'quote-query':
        return (
          <TimelineQuoteQuery
            {...commonProps}
            event={event}
          />
        );
      case 'quote-accepted':
        return (
          <TimelineQuoteAccepted
            {...commonProps}
            event={event}
          />
        );
      case 'quote-accepted-on-behalf':
        return (
          <TimelineQuoteAcceptedOnBehalf
            {...commonProps}
            event={event}
          />
        );
      case 'quote-accepted-reverted':
        return (
          <TimelineQuoteAcceptedReverted
            {...commonProps}
            event={event}
          />
        );
      default:
        return null;
    }
  });

  return (
    <>
      <Stack spacing={0} mr="80px">
        <TimelineItem
          first
          last={timelineEvents.length === 0}
          dot={<CurrentUserAvatar />}
          topSpacing="4"
          bottomSpacing="4"
        >
          <AddTimelineNote
            addNote={(note) => {
              setLocalNotes((s) => [...s, note]);
              dispatch(addJobNote({
                jobId,
                note,
              }));
            }}
          />
        </TimelineItem>
        {timelineComponents}
      </Stack>

      {isLoading && (
      <Flex justifyContent="center" alignItems="center" my="4">
        <Spinner size="sm" color="gray.400" mr="4" />
        <Text color="gray.400">Loading timeline</Text>
      </Flex>
      )}
    </>
  );
};
export default JobTimeline;
