import {
  Icon, Table, Text, Tbody, Tr, VStack, Td, Skeleton, MenuItem, Image,
} from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
import React, { useState } from 'react';
import { ReactComponent as DrillIcon } from '~/assets/live_geo_soil_icon.svg';
import ConfirmationDialog from '~/components/ConfirmationDialog';
import { ExpandableCountSection } from '~/components/ExpandableCountSection';
import { KebabOptionsMenu } from '~/components/KebabOptionsMenu';
import LinkButton from '~/components/LinkButton';
import { useModalManager } from '~/components/ModalManager';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import { SoilTypeEditModal } from './SoilTypeEditModal';
import { triggerSoilTypesFetch, deleteSoilType } from '~/redux/soilTypes/actions';
import { useAppDispatch } from '~/redux/store';
import { SoilType } from '~/types/soilType';
import eventStopper from '~/utils/eventStopper';
import { getActiveSoilTypes } from '~/redux/soilTypes/selectors';

export const SoilTypesList = () => {
  const dispatch = useAppDispatch();
  const modalManager = useModalManager();
  const { data: soilTypes, isLoading } = useTrackedFetch({
    key: 'soil-types',
    trigger: () => triggerSoilTypesFetch({ force: false }),
    selector: getActiveSoilTypes,
  });

  const [
    selectedSoilType,
    setSelectedSoilType,
  ] = useState<Partial<SoilType>>(null);
  const [soilTypeModalTitle, setSoilTypeModalTitle] = useState<string>();

  const addTechnique = () => {
    setSelectedSoilType({
      id: nanoid(),
    });
    setSoilTypeModalTitle('Add soil type');
  };

  const editTechnique = (soilType: SoilType) => {
    setSelectedSoilType(soilType);
    setSoilTypeModalTitle('Edit soil type');
  };

  const deleteTechnique = (soilTypeId) => {
    dispatch(deleteSoilType({
      id: soilTypeId,
    }));
  };

  return (
    <VStack align="stretch">
      <ExpandableCountSection
        title="Soil types"
        icon={<Icon boxSize={8} as={DrillIcon} />}
        count={soilTypes?.length || 0}
      >
        <Table size="md">
          <Tbody>
            {isLoading ? (
              <Tr>
                <Td><Skeleton height={8} /></Td>
                <Td><Skeleton height={8} /></Td>
                <Td />
                <Td />
              </Tr>
            )
              : (orderBy(soilTypes, (t) => t.name).map((tr) => (
                <Tr
                  key={tr.id}
                  _hover={{ bg: '#fafafa', cursor: 'pointer' }}
                  transition="color 0.1s ease-out"
                  role="group"
                  onClick={() => editTechnique(tr)}
                >
                  <Td width="65px" />
                  <Td width="40px" px={0} m={0}>
                    {tr.imageId && <Image src={`/images/live-geo/soil/${tr.imageId}.png`} />}
                  </Td>
                  <Td>
                    <Text>
                      {tr.name}
                    </Text>
                  </Td>

                  <Td textAlign="end" {...eventStopper()}>
                    <KebabOptionsMenu>
                      <MenuItem onClick={() => editTechnique(tr)}>Edit</MenuItem>
                      <MenuItem
                        onClick={() => {
                          modalManager.open(ConfirmationDialog, {
                            title: 'Delete soil type',
                            children: 'This will remove the soil type from Magnetize, but will not remove it from any existing logs.',
                            onConfirm: () => deleteTechnique(tr.id),
                            confirmButtonLabel: 'Delete',
                          });
                        }}
                      >
                        Delete
                      </MenuItem>
                    </KebabOptionsMenu>
                  </Td>
                </Tr>
              )))}
          </Tbody>
        </Table>
        <LinkButton ml={24} mt={6} isGreen noUnderline onClick={addTechnique}>
          + add soil type
        </LinkButton>
        {!!selectedSoilType
      && (
      <SoilTypeEditModal
        title={soilTypeModalTitle}
        onClose={() => {
          setSelectedSoilType(null);
        }}
        soilType={selectedSoilType}
      />
      )}
      </ExpandableCountSection>
    </VStack>
  );
};
