import React, { useState } from 'react';
import {
  FormControl, FormLabel, Switch,
} from '@chakra-ui/react';
import TurnOnDialog from './TurnOnDialog';
import TurnOffDialog from './TurnOffDialog';
import { Tenant } from '~/types/tenant';

const TenantAutoSendLiveGeo = ({ tenant, onSave }:
{ tenant: Tenant, onSave: (tenant: Tenant) => void; }) => {
  const [turningOn, setTurningOn] = useState(false);
  const [turningOff, setTurningOff] = useState(false);

  return (
    <>
      <FormControl display="flex" alignItems="center" mt="4">
        <Switch
          size="lg"
          isChecked={tenant.liveGeoAutoSend}
          onChange={() => {
            if (tenant.liveGeoAutoSend) {
              setTurningOff(true);
            } else {
              setTurningOn(true);
            }
          }}
        />
        <FormLabel mb={0} ml={3} textTransform="none">
          Auto-send live drill logs to customer
        </FormLabel>
      </FormControl>
      <TurnOnDialog
        onTurnOn={() => {
          onSave({
            ...tenant,
            liveGeoAutoSend: true,
          });
          setTurningOn(false);
        }}
        onClose={() => setTurningOn(false)}
        isOpen={turningOn}
      />
      <TurnOffDialog
        onTurnOff={() => {
          onSave({
            ...tenant,
            liveGeoAutoSend: false,
          });
          setTurningOff(false);
        }}
        onClose={() => setTurningOff(false)}
        isOpen={turningOff}
      />
    </>
  );
};

export default TenantAutoSendLiveGeo;
