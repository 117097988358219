import { createAction } from '@reduxjs/toolkit';
import createTrackedAction from '~/redux/tracked/createTrackedAction';
import { Customer } from '~/types/customer';

export const CustomerActions = {
  fetchAll: createAction('customer/fetchAll'),
  fetch: createAction<{ customerId: string }>('customer/fetch'),
  save: createTrackedAction<{ customer: Customer }>('customer/save'),
  updated: createAction<{ customers: Customer[] }>('customer/updated'),
  archive: createAction<{ customerId: string }>('customer/archive'),
  exportCustomers: createTrackedAction<{ format: 'csv' | 'xlsx' }>('customer/exportReport'),
};
