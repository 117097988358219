import {
  has, isPlainObject, mergeWith, pickBy,
} from 'lodash';
import { LocalEdit } from '~/types/localEdit';
import { RecursivePartial } from '~/types/helpers';

function mergeAndRemovedUndefined<T>(entity: T, changes: RecursivePartial<T>[]) {
  return mergeWith({}, entity, ...changes, function customizer(objValue, srcValue) {
    if (isPlainObject(srcValue)) {
      // To merge in this object, we want to do the regular recursive merge, but omit
      // any keys that are explicitly set to undefined in the srcValue.
      return pickBy(
        mergeWith({}, objValue, srcValue, customizer),
        (value, k) => !(has(srcValue, k) && srcValue[k] === undefined),
      );
    }
    return undefined;
  }) as unknown as T;
}

export default function applyLocalEdits<T>(entity: T, changes: LocalEdit[]) {
  // TODO: should we change the local edit representation to be more similar to
  // the patch format the backend expects?
  return entity && changes?.length > 0
    ? mergeAndRemovedUndefined(entity, changes.map((c) => c.patch as any))
    : entity;
}
