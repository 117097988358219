import { SearchIcon } from '@chakra-ui/icons';
import {
  Box, Flex, HStack,
  Icon, IconButton, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent,
  PopoverTrigger, Portal, Text, Tooltip,
  VStack,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';
import { ReactComponent as UserHelmet } from '~/assets/icon-user-helmet.svg';
import AddSchedulingNoteWithEditIcon from '~/components/AddCalendarEventPopover/AddSchedulingNote/withEditIcon';
import { AddStartTime } from '~/components/AddCalendarEventPopover/AddStartTime';
import { NewBadge } from '~/components/Badge';
import CalendarCard from '~/components/Calendar/CalendarCard';
import CalendarDateIntervalEditor from '~/components/JobCalendar/CalendarDateIntervalEditor';
import { JobEventViewModel } from '~/components/JobCalendar/JobEventViewModel';
import ResourceList from '~/components/JobCalendar/ResourceList';
import JobContactAddressSummary from '~/components/JobContactAddressSummary';
import StatusBadge from '~/components/JobStatusBadge';
import { JobTagEditor } from '~/components/JobTagEditor';
import LinkButton from '~/components/LinkButton';
import RemoveButtonWithConfirmation from '~/components/RemoveButtonWithConfirmation';
import ResourceAvatar from '~/components/ResourceAvatar';
import TagsForJob from '~/components/TagsForJob';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import Routes from '~/pages/routes';
import { CustomerActions } from '~/redux/customers/actions';
import { selectCustomerById } from '~/redux/customers/selectors';
import { selectFetchState } from '~/redux/fetch/selectors';
import { selectJobById } from '~/redux/jobs/selectors';
import { ScheduleEventActions } from '~/redux/schedule/actions';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import { JobStatusInfo } from '~/types/job';
import { ResourceSummary } from '~/types/resource';
import eventStopper from '~/utils/eventStopper';
import { getJobCardText } from '~/utils/getJobCardText';
import { calculateResourceEventIntervals } from '~/helpers/schedule';
import { selectCurrentUser } from '~/redux/currentUser/selectors';

interface JobEventCardProps {
  jobEvent: JobEventViewModel;
  showResources?: boolean;
  isSelected?: boolean;
  onClose: () => void;
  onSpanChange: (span: { start: Date, end: Date }) => void;
}

const JobEventCard = ({
  jobEvent, isSelected, showResources = true, onClose, onSpanChange,
}: JobEventCardProps) => {
  const jobSummary = useAppSelector((state) => selectJobById(state, jobEvent.jobId));
  const { isLoading: isLoadingJob } = useAppSelector((state) => (
    selectFetchState(state, jobEvent.jobId)));

  const {
    data: customer,
  } = useTrackedFetch({
    trigger: () => CustomerActions.fetch({ customerId: jobSummary?.customerId }),
    selector: (s) => selectCustomerById(s, jobSummary?.customerId),
    key: `customer-${jobSummary?.customerId}`,
  });

  const { mainText, secondaryText } = getJobCardText(jobSummary, customer);

  const dispatch = useAppDispatch();

  const tenant = useAppSelector((state) => selectCurrentUser(state)?.tenant);

  const statusColor = JobStatusInfo[jobSummary?.status]?.color ?? 'transparent';
  const popoverFocusRef = useRef();
  const isLoading = isLoadingJob;

  const onAddResourceToJob = (resource: ResourceSummary) => {
    const intervals = calculateResourceEventIntervals({
      start: jobEvent.start,
      end: jobEvent.end,
    }, tenant);
    dispatch(ScheduleEventActions.editResourceEvents({
      jobEventId: jobEvent.id,
      resource,
      intervals,
    }));
  };

  const onRemoveResource = (resource: ResourceSummary) => {
    dispatch(ScheduleEventActions.removeResourceFromJob({
      jobEventId: jobEvent.id,
      resourceId: resource.id,
    }));
  };

  const onEditResource = (
    resource: ResourceSummary,
    intervals: { start: number, end: number }[],
  ) => {
    // Detect if the intervals are within the job start and end date. Intervals will fall outside
    // a jobs start and end when the user is editing a resource's days and then shifts the job
    // without closing the calender.
    const earliestStart = Math.min(...intervals.map((i) => i.start));
    const latestEnd = Math.max(...intervals.map((i) => i.end));

    if (jobEvent.start.getTime() <= earliestStart && jobEvent.end.getTime() >= latestEnd) {
      dispatch(ScheduleEventActions.editResourceEvents({
        jobEventId: jobEvent.id,
        resource,
        intervals,
      }));
    }
  };

  const onDeleteJobEvent = () => {
    dispatch(ScheduleEventActions.deleteJobEvent({ jobEventId: jobEvent.id }));
  };

  const updateNotes = (notes: string) => {
    dispatch(ScheduleEventActions.updateEvent({
      ...jobEvent.jobEvent,
      notes,
    }));
  };
  const updateStartTimeMinutes = (startTimeMinutes: number) => {
    dispatch(ScheduleEventActions.updateEvent({
      ...jobEvent.jobEvent,
      startTimeMinutes,
    }));
    jobEvent.resources.flatMap((r) => r.resourceEvents).forEach((re) => {
      dispatch(ScheduleEventActions.updateEvent({
        ...re,
        startTimeMinutes,
      }));
    });
  };

  return (
    <Popover
      initialFocusRef={popoverFocusRef}
      isOpen={isSelected}
      openDelay={500}
      placement="bottom"
      isLazy
    >
      <PopoverTrigger>
        {/* Wrapping the trigger in a box seems to be necessary, even if I
        forward the ref for the CalendarCard */}
        <Box>
          <CalendarCard
            isLoading={isLoading}
            mainText={mainText ?? ' '}
            secondaryText={secondaryText ?? ' '}
            statusColor={statusColor}
            backgroundColor={jobSummary?.isNewJob ? 'magnetize.support-warning-tint' : undefined}
          >
            {showResources && (
              <HStack spacing="1" mt={1} overflow="hidden">
                {jobEvent.resources.map((r) => (
                  <Tooltip key={r.id} label={r.displayName}>
                    <Box>
                      <ResourceAvatar size="sm" resource={r} />
                    </Box>
                  </Tooltip>
                ))}
              </HStack>
            )}
            <TagsForJob jobId={jobEvent.jobId} />
          </CalendarCard>
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent {...eventStopper()} width="30rem" maxHeight="45vh">
          <PopoverArrow />
          <PopoverCloseButton onClick={onClose} tabIndex={-1} top={3} right={3} />
          <PopoverBody
            overflowY="scroll"
          >
            <Box position="absolute" top={3} right={14}>
              <Tooltip
                label="Delete this scheduled event?"
                placement="top"
                hasArrow
              >
                <RemoveButtonWithConfirmation
                  confirmationTitle="Remove schedule"
                  confirmationMessage="Are you sure you want to remove this schedule?"
                  onRemoveConfirmed={onDeleteJobEvent}
                >
                  <IconButton
                    size="sm"
                    tabIndex={-1}
                    variant="ghost"
                    icon={<Icon boxSize="14px" as={FaRegTrashAlt} />}
                    aria-label="Delete calendar event"
                  />
                </RemoveButtonWithConfirmation>
              </Tooltip>
            </Box>
            <VStack
              py={8}
              spacing={8}
              align="stretch"
              ref={popoverFocusRef}
            >
              <HStack borderBottomWidth={1} borderColor="gray.200">
                <SearchIcon ml={1} />
                <Text px={1} py={2}>
                  {mainText}
                </Text>
                {jobSummary?.isNewJob ? <NewBadge /> : undefined}
              </HStack>

              <Flex direction="column">
                <Box mt={-5}>
                  <StatusBadge status={jobSummary?.status} />
                  <LinkButton ml={4} isGreen noUnderline to={Routes.job({ jobId: jobEvent.jobId })}>
                    view job
                  </LinkButton>
                </Box>

                <Box
                  mt={8}
                  mx={-8}
                  bg="gray.50"
                >
                  <Box px="8" py="4">
                    <JobContactAddressSummary jobId={jobEvent.jobId} />
                  </Box>
                </Box>
              </Flex>

              <CalendarDateIntervalEditor
                value={jobEvent}
                onChange={onSpanChange}
              />

              <AddStartTime
                startTimeMinutes={jobEvent.jobEvent.startTimeMinutes}
                onChange={(m) => updateStartTimeMinutes(m)}
              />
              <JobTagEditor jobId={jobEvent.jobId} />

              <Flex>
                <Icon mt={2} fontSize="16px" boxSize="16px" as={UserHelmet} />
                <Box pl="2" flex="1">
                  <ResourceList
                    resources={jobEvent.resources}
                    onAddResource={onAddResourceToJob}
                    onRemoveResource={onRemoveResource}
                    onEditResource={onEditResource}
                    showAvailability
                    minDate={jobEvent.start}
                    maxDate={jobEvent.end}
                  />
                </Box>
              </Flex>

              <AddSchedulingNoteWithEditIcon
                value={jobEvent.jobEvent.notes}
                onChange={updateNotes}
              />
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default JobEventCard;
