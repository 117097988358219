import {
  Button, Checkbox, FormControl, FormHelperText, FormLabel, Modal, ModalBody,
  ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, Text,
} from '@chakra-ui/react';
import {
  add, endOfDay, formatDistanceToNow, isBefore,
} from 'date-fns';
import { isPast } from 'date-fns/esm';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import SmartDatePicker from '~/components/SmartDatePicker';
import StatusAlert from '~/components/StatusAlert';
import queryClient from '~/queryClient';
import { AdminTenant } from '~/types/tenant';
import fetchJson from '~/utils/fetchJson';

interface ChangeTrialPeriodModalProps {
  tenant: AdminTenant;
  onClose: () => void;
}

export const ChangeTrialPeriodModal = ({ tenant, onClose }: ChangeTrialPeriodModalProps) => {
  const [planExpiry, setPlanExpiry] = useState(tenant.planExpiry);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const { mutate } = useMutation(() => fetchJson(
    `/api/tenant-admin/${tenant.tenantId}/set-trial-expiry`, {
      method: 'POST',
      body: {
        planExpiry,
      },
    },
  ), {
    onSuccess: () => {
      queryClient.invalidateQueries(['admin-tenant', tenant.tenantId]);
    },
  });

  const handleSaveClicked = () => {
    mutate();
    onClose();
  };

  const trialDuration = planExpiry ? formatDistanceToNow(planExpiry, { addSuffix: true }) : 'No expiry';

  const hasActiveTrial = tenant.status === 'trial' || tenant.status === 'welcome';
  const isShorteningTrial = hasActiveTrial
    && (planExpiry && tenant.planExpiry)
    && isBefore(planExpiry, tenant.planExpiry);
  const isExpiringTrial = hasActiveTrial && planExpiry && isPast(planExpiry);

  const needsConfirmation = isShorteningTrial || isExpiringTrial;
  const isValid = !needsConfirmation || isConfirmed;

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen
      onClose={onClose}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Change Trial Period
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing="8">
            <FormControl>
              <FormLabel>Date</FormLabel>
              <SmartDatePicker
                placeholder="Select date"
                selected={planExpiry ? new Date(planExpiry) : null}
                onChange={(x: Date) => {
                  setPlanExpiry(x ? endOfDay(x).getTime() : null);
                  setIsConfirmed(false);
                }}
                suggestedDates={[
                  [
                    { date: null, label: 'No expiry' },
                    { date: add(endOfDay(Date.now()), { weeks: 1 }), label: 'One week' },
                    { date: add(endOfDay(Date.now()), { weeks: 2 }), label: 'Two weeks' },
                  ],
                ]}
              />
              <FormHelperText>
                Expires:
                {' '}
                {trialDuration}
              </FormHelperText>
            </FormControl>

            {needsConfirmation && (
              <StatusAlert
                status={isExpiringTrial ? 'error' : 'warning'}
                title={isExpiringTrial ? 'Expiring Trial!' : 'Shortening Trial'}
              >
                <Stack spacing="4">
                  <Text>
                    {`Hold up, the date you've picked will ${isExpiringTrial ? 'end' : 'shorten'} their trial. Are you sure?`}
                  </Text>
                  <Checkbox
                    isChecked={isConfirmed}
                    onChange={(e) => setIsConfirmed(e.target.checked)}
                  >
                    Yep, that&apos;s what I mean.
                  </Checkbox>
                </Stack>
              </StatusAlert>
            )}
          </Stack>

        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="brandDark"
            isLoading={false}
            disabled={!isValid}
            onClick={handleSaveClicked}
          >
            Save
          </Button>
          <Button variant="ghost" onClick={onClose}>cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
