import { takeEvery, call, put } from 'redux-saga/effects';
import { duplicateJob, jobFetchSuccess } from '~/redux/jobs/actions';
import { showToast } from '~/toast';
import { performTrackedSaga } from '~/utils/performTrackedSaga';
import fetchJson from '~/utils/fetchJson';
import { Job } from '~/types/job';
import { TrackedActions } from '~/redux/tracked/actions';

const handleDuplicateJob = takeEvery(
  duplicateJob,
  function* handle(action) {
    const jobId = action.payload.data.id;
    yield performTrackedSaga({
      key: action.payload.id,
      * saga() {
        try {
          const newJob: Job = yield call(() => fetchJson(
            `/api/jobs/${jobId}/duplicate`,
            {
              method: 'POST',
            },
          ));
          yield put(jobFetchSuccess({ jobId: newJob.id, data: newJob }));
          yield put(TrackedActions.setResult({
            trackId: action.payload.id,
            result: newJob.id,
          }));
        } catch (error) {
          showToast({
            id: jobId,
            title: 'Job Duplication Error',
            description: error instanceof Error ? error.message : String(error),
            status: 'error',
            duration: 10000,
            isClosable: true,
          });
        }
      },
    });
  },
);

export default handleDuplicateJob;
