import {
  Button, Flex, Spacer, Stack,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import SwitchWithLabel from '~/components/SwitchWithLabel';
import JobDetails from '~/pages/Job/components/JobDetails';
import { saveJob, updateJob } from '~/redux/jobs/actions';
import { selectJobWithEdits } from '~/redux/jobs/selectors';
import { QuoteActions } from '~/redux/quote/actions';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import { Job } from '~/types/job';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import { selectQuotesByJobId } from '~/redux/quote/selectors';
import JobPrimaryActionButton from '~/pages/Job/components/JobPrimaryActionButton';

const JobDetailsTab = ({ jobId }: { jobId: string }) => {
  const dispatch = useAppDispatch();
  const job = useAppSelector((state) => selectJobWithEdits(state, jobId));
  const updateCurrentJob = (changes: Partial<Job>) => {
    dispatch(updateJob({ id: job.id, ...changes }));
  };

  useEffect(() => {
    dispatch(QuoteActions.fetchForJob({ jobId }));
  }, [job.needsQuote]);

  const { data: quotes } = useTrackedFetch({
    key: `job-quotes-${jobId}`,
    trigger: () => QuoteActions.fetchForJob({ jobId }),
    selector: (state) => selectQuotesByJobId(state, jobId),
  });

  return (
    <>
      <Stack spacing={6}>
        <JobDetails job={job} />
        <Flex>
          <SwitchWithLabel
            isChecked={job?.needsQuote}
            isDisabled={!(quotes?.every((q) => q.status === 'draft'))}
            onChanged={(needsQuote) => {
              const update : any = {
                needsQuote,
              };
              if (['quote', 'schedule'].includes(job.status)) {
                update.status = needsQuote ? 'quote' : 'schedule';
              }
              updateCurrentJob(update);
            }}
            label="Quote Required"
          />
        </Flex>
        <Spacer />
        <Flex>
          <Spacer />
          <Flex>
            {job.status === 'draft' ? (
              <Button
                size="lg"
                onClick={() => {
                  dispatch(saveJob({
                    id: job.id,
                    status: job.needsQuote ? 'quote' : 'schedule',
                  }));
                }}
                isDisabled={!job.name}
              >
                Save Job
              </Button>
            )
              : <JobPrimaryActionButton jobId={jobId} />}
          </Flex>
        </Flex>
      </Stack>
    </>
  );
};

export default JobDetailsTab;
