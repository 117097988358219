import {
  Flex, Icon, Stack, Text,
} from '@chakra-ui/react';
import { AddOutlined } from '@material-ui/icons';
import React, { useRef } from 'react';
import FileGallery from '~/components/FileUpload/FileGallery';
import FileUpload, { FileUploadRef } from '~/components/FileUpload';
import UploadedFile from '~/types/uploaded-file';

interface JobFileUploadProps {
  files: UploadedFile[];
  onFileUploaded: (file: UploadedFile) => void;
  onFileRemoved: (file: UploadedFile) => void;
}

const JobFileUpload = (
  { files, onFileUploaded, onFileRemoved } : JobFileUploadProps,
) => {
  const fileUploadRef = useRef<FileUploadRef>();

  return (
    <Flex direction="column">
      <FileUpload
        ref={fileUploadRef}
        onFileUploaded={onFileUploaded}
      >
        <Stack my={8} flex="1">
          <Text color="magnetize.text-3" textAlign="center">
            Files uploaded here will be available to staff in the mobile app.
          </Text>
          <Flex
            color="magnetize.text-1"
            alignItems="center"
            justifyContent="center"
          >
            <Icon as={AddOutlined} />
          </Flex>
          <Text textAlign="center" color="magnetize.text-3">
            Drag and drop
            <br />
            or select a file to upload
          </Text>
        </Stack>
      </FileUpload>
      <FileGallery
        onFileRemoved={(file) => {
          fileUploadRef.current.reset();
          onFileRemoved(file);
        }}
        files={files}
      />
    </Flex>
  );
};

export default JobFileUpload;
