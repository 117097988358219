import {
  Divider, Grid, GridItem,
} from '@chakra-ui/react';
import React, { useMemo } from 'react';
import CurrencyText from '~/components/CurrencyText';
import TaxRateSelector from '~/components/TaxRateSelector';
import calculateQuoteTotals from '~/utils/calculateQuoteTotals';

interface QuoteViewTotalsProps {
  taxRateName?: string;
  taxRate?: number;
  lineItems: { [id: string]: { quantity: number, unitPriceCents: number } };
  onTaxRateChange?: (
    { taxRate, taxRateName }
    :{ taxRate: number, taxRateName: string }
  ) => void;
  currency?: string;
}

const LineItemTotals = ({
  lineItems, onTaxRateChange, taxRate, taxRateName, currency,
}: QuoteViewTotalsProps) => {
  const totals = useMemo(
    () => calculateQuoteTotals(Object.values(lineItems),
      taxRate || 0),
    [lineItems, taxRate],
  );

  return (
    <Grid gridTemplateColumns="1fr minmax(max-content, 1fr)" gap={1} textAlign="right">
      <GridItem color="gray.700" p="3px">SUBTOTAL</GridItem>
      <GridItem><CurrencyText color="gray.700" value={totals.subtotalCents} currency={currency} /></GridItem>

      <GridItem color="gray.700" p="3px">
        <TaxRateSelector
          onChange={onTaxRateChange ? ({ rate, name }) => {
            onTaxRateChange({ taxRate: rate, taxRateName: name });
          } : undefined}
          name={taxRateName}
          rate={taxRate}
        />

      </GridItem>
      <GridItem><CurrencyText color="gray.700" value={totals.taxCents} currency={currency} /></GridItem>

      <Divider mt={2} mb={2} borderColor="gray.200" gridColumn="1 / 3" />

      <GridItem fontWeight="extrabold">TOTAL</GridItem>
      <GridItem><CurrencyText fontWeight="extrabold" value={totals.totalCents} currency={currency} /></GridItem>
    </Grid>
  );
};

export default LineItemTotals;
