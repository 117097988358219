import {
  FormControl, FormLabel, Grid, Input, Switch,
} from '@chakra-ui/react';
import { isBoolean } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { ClearanceTechnique } from '~/types/clearanceTechnique';

export const ClearanceTechniqueForm = (
  {
    value = {},
    onChange = () => {},
  }
  : {
    value?: Partial<ClearanceTechnique>;
    onChange?: (technique: Partial<ClearanceTechnique>) => void
  },
) => {
  const [name, setName] = useState(value.name ?? '');
  const [allowBorehole, setAllowBorehole] = useState(
    isBoolean(value.allowBorehole) ? value.allowBorehole : true,
  );
  const [allowCpt, setAllowCpt] = useState(
    isBoolean(value.allowCpt) ? value.allowCpt : true,
  );

  const nameRef = useRef(null);

  useEffect(() => {
    onChange({
      ...(value || {}),
      name,
      allowBorehole,
      allowCpt,
    });
  }, [
    name, allowBorehole, allowCpt,
  ]);

  return (
    <Grid gap={6}>
      <FormControl isRequired>
        <FormLabel>Name</FormLabel>
        <Input
          autoFocus
          size="lg"
          ref={nameRef}
          placeholder="Enter the name for the clearance technique"
          value={name}
          onChange={(e) => { setName(e.target.value); }}
        />
      </FormControl>
      <FormControl display="flex" alignItems="center">
        <Switch
          size="lg"
          isChecked={allowBorehole}
          onChange={() => setAllowBorehole(!allowBorehole)}
        />
        <FormLabel mb={0} ml={3} textTransform="none" fontWeight="normal">
          Available for bore holes
        </FormLabel>
      </FormControl>
      <FormControl display="flex" alignItems="center">
        <Switch
          size="lg"
          isChecked={allowCpt}
          onChange={() => setAllowCpt(!allowCpt)}
        />
        <FormLabel mb={0} ml={3} textTransform="none" fontWeight="normal">
          Available for CPT Tests
        </FormLabel>
      </FormControl>
    </Grid>
  );
};
