import { createReducer } from '@reduxjs/toolkit';
import {
  now, keyBy, groupBy, omit,
} from 'lodash';
import { tagDeleted, tagsLoaded, tagUpdated } from '~/redux/tags/actions';
import { FetchState } from '~/types/fetchState';
import { Tag } from '~/types/tag';

export const tagsReducer = createReducer(
  { data: { byId: {}, byType: {} } } as FetchState<{
    byType:{ [key: string]:Tag[] }
    byId: { [key:string]: Tag }
  }>,
  (builder) => (builder
    .addCase(tagsLoaded, (state, action) => ({
      status: 'success',
      data: {
        byId: keyBy(action.payload, (x) => x.id),
        byType: groupBy(action.payload, (x) => x.type),
      },
      isInvalid: false,
      lastUpdatedAt: now(),
    }))

    .addCase(tagUpdated, (state, action) => ({
      ...state,
      data: {
        byId: {
          ...state.data.byId,
          [action.payload.id]: action.payload,
        },
        byType: {
          ...state.data.byType,
          [action.payload.type]: [
            ...(state.data.byType[action.payload.type] || [])
              .filter((x) => x.id !== action.payload.id),
            action.payload,
          ],
        },
      },
    }))

    .addCase(tagDeleted, (state, action) => ({
      ...state,
      data: {
        byId: omit(state.data.byId, action.payload.id),
        byType: Object.entries(state.data.byType).reduce((acc, [type, entries]) => ({
          ...acc, [type]: entries.filter((e) => e.id !== action.payload.id),
        }), {}),
      },
    }))
  ),
);
