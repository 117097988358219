import {
  Button, Icon, Modal, ModalBody, ModalContent,
  ModalFooter, ModalHeader, ModalOverlay, Stack, Text, Box,
} from '@chakra-ui/react';
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { FaHardHat } from 'react-icons/fa';
import { Link as RouterLink, useLocation, useRouteMatch } from 'react-router-dom';
import { ReactComponent as SurprisedSnowPlow } from '~/assets/icon-excavator-alert.svg';
import { useCheckRole } from '~/hooks/useCheckRole';
import { useModalState } from '~/hooks/useModalState';
import { selectIsTenantTrial } from '~/redux/currentUser/selectors';
import { useAppSelector } from '~/redux/store';
import { CognitoGroup } from '~/types/cognitoUser';
import { SubscriptionStatusInfo } from '~/types/tenant';

interface PlanExpiredModalContentProps {
  onClose: () => void;
  onHushPopups: () => void;
}

const PlanExpiredModalContent = ({ onClose, onHushPopups }: PlanExpiredModalContentProps) => {
  const isTrial = useAppSelector(selectIsTenantTrial);
  const isSuperuser = useCheckRole(CognitoGroup.Superuser);
  const isDevMode = process.env.NODE_ENV === 'development';
  return (
    <>
      <ModalHeader borderTopRadius="md" fontWeight="bold">
        <Box mb="6" mt="2">
          <SurprisedSnowPlow />
        </Box>
        Heads Up!
        <br />
        {isTrial ? 'This free trial has ended' : 'Activate a plan to continue'}
      </ModalHeader>
      <ModalBody>
        <Stack spacing="8">
          <Text fontSize="lg">
            Not a problem! Select a plan and add a payment method to
            continue your good work.
          </Text>
          <Text fontSize="lg">
            If you need more time, call us on 0800 131 110
          </Text>
        </Stack>
      </ModalBody>
      <ModalFooter
        justifyContent="flex-end"
        position="relative"
        borderBottomRadius="md"
        py="10"
      >
        <Button
          as={RouterLink}
          to="/settings/plans"
          onClick={() => onClose()}
          size="lg"
        >
          Activate Plan
        </Button>
        {(isSuperuser || isDevMode) && (
          <Button
            variant="link"
            size="sm"
            fontSize="xs"
            onClick={() => {
              onHushPopups();
              onClose();
            }}
            position="absolute"
            right="2"
            bottom="2"
            opacity={0.2}
            _hover={{
              opacity: 0.7,
            }}
            leftIcon={<Icon as={FaHardHat} />}
          >
            Excuse me, I&apos;m testing.
          </Button>
        )}
      </ModalFooter>
    </>
  );
};

const PlanExpiredModal = () => {
  const location = useLocation();
  const tenantStatus = useAppSelector((s) => s.currentUser?.tenant?.status);
  const isActive = SubscriptionStatusInfo[tenantStatus]?.isActive ?? true;

  const routeMatch = useRouteMatch({
    path: [
      // We need people to get into the plans part of the app
      '/settings/plans',

      // At the moment it's not necessary to exclude the login, change password
      // etc. screens because they get handled earlier in the routing, in a separate
      // switch.
    ],
  });

  const timerId = useRef<any>();
  const isPermittedPath = !!routeMatch;
  const trialModal = useModalState();
  const [tryingToTestPlease, setTryingToTestPlease] = useState(false);

  // useMemo is being used here because we want to reconsider this on every
  // location change, but if we did this in the useEffect it would
  const shouldShowTrialExpiry = useMemo(() => (
    !isPermittedPath && !isActive && !tryingToTestPlease
  ), [isPermittedPath, isActive, location, tryingToTestPlease]);

  useEffect(() => {
    const hasTimer = !!timerId.current;
    if (!hasTimer && shouldShowTrialExpiry !== trialModal.isOpen) {
      timerId.current = setTimeout(() => {
        trialModal.open();
        timerId.current = undefined;
      }, 2000);
    } else if (hasTimer && !shouldShowTrialExpiry) {
      clearTimeout(timerId.current);
      timerId.current = undefined;
    }
    return () => {
      if (timerId.current) {
        clearTimeout(timerId.current);
        timerId.current = undefined;
      }
    };
  }, [shouldShowTrialExpiry, trialModal.isOpen]);

  return (
    <Modal
      {...trialModal.props}
      size="2xl"
      closeOnOverlayClick={false}
      closeOnEsc={false}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent borderRadius="md">
        <PlanExpiredModalContent
          onClose={trialModal.close}
          onHushPopups={() => setTryingToTestPlease(true)}
        />
      </ModalContent>
    </Modal>
  );
};

export default PlanExpiredModal;
