import { endOfWeek, getUnixTime, startOfWeek } from 'date-fns';
import {
  all, call, put, select, takeEvery,
} from 'redux-saga/effects';
import { currentUserActions } from '~/redux/currentUser/actions';
import { EquipmentActions } from '~/redux/equipment/actions';
import { ScheduleEventActions } from '~/redux/schedule/actions';
import StaffActions from '~/redux/staff/actions';
import WelcomeModalActions from '~/redux/welcomeModal/actions';
import { selectWelcomeModalState } from '~/redux/welcomeModal/selectors';
import { showErrorToast } from '~/toast';
import fetchJson from '~/utils/fetchJson';

const watchComplete = takeEvery(
  WelcomeModalActions.complete,
  function* handle() {
    const welcomeState = yield select(
      (state) => selectWelcomeModalState(state),
    );

    try {
      yield call(() => fetchJson(
        '/api/tenant/complete-welcome-survey', {
          method: 'PUT',
          body: welcomeState,
        },
      ));
      yield put(StaffActions.fetch());
      yield put(EquipmentActions.fetch());
      yield put(ScheduleEventActions.fetchForRange({
        from: getUnixTime(startOfWeek(new Date())),
        to: getUnixTime(endOfWeek(new Date())),
      }));
      yield put(currentUserActions.fetch());
    } catch (error) {
      showErrorToast(error);
    }
  },
);

export default function* handleWelcomeModal() {
  yield all([
    watchComplete,
  ]);
}
