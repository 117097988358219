import { createAction } from '@reduxjs/toolkit';
import { DateRangeMode } from '~/components/Calendar/getDateRange';
import { ResourceIdentifier } from '~/types/resource';

const ResourceCalendarActions = {
  move: createAction<{ focusedDate: string }>('resourceCalendar/move'),
  setRangeMode: createAction<{ rangeMode: DateRangeMode }>('resourceCalendar/setRangeMode'),
  setResources: createAction<{ resources: ResourceIdentifier[] }>('resourceCalendar/setResources'),
};

export default ResourceCalendarActions;
