import React from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';

import { difference } from 'lodash';
import TimelineItem, { TimelineItemProps } from '~/components/TimelineItem';
import { useAppSelector } from '~/redux/store';
import { JobTimelineJobTagsChanged } from '~/types/job';
import JobTag from '~/components/JobTag';
import { getTagById } from '~/redux/tags/selectors';
import { JobTag as JobTagType } from '~/types/tag';

const TimelineJobTagAdded = ({
  event,
  tagId,
  ...rest
}:{
  tagId: string;
  event: JobTimelineJobTagsChanged;
} & TimelineItemProps) => {
  const tag = useAppSelector((state) => getTagById(state, tagId)) as JobTagType;

  if (!tag) {
    return null;
  }

  return (
    <TimelineItem
      {...rest}
      event={event}
    >
      <Box display="flex" height="100%" flexDir="column" justifyContent="center">
        <Box>
          <JobTag id={tagId} />
        </Box>
        {/* <Heading textTransform="none" size="sm" mb={1} mt={2}>JOB TAG ADDED</Heading> */}
        {tag.description
        && (
        <HStack mt={2}>
          <Text>{tag.description}</Text>
        </HStack>
        )}
      </Box>
    </TimelineItem>
  );
};
const TimelineJobTagRemoved = ({
  event,
  tagId,
  ...rest
}:{
  tagId: string;
  event: JobTimelineJobTagsChanged;
} & TimelineItemProps) => {
  const tag = useAppSelector((state) => getTagById(state, tagId)) as JobTagType;

  if (!tag) {
    return null;
  }

  return (
    <TimelineItem
      {...rest}
      event={event}
    >
      <Box display="flex" height="100%">
        <HStack spacing="0.2rem">
          <Text>
            Job tag
          </Text>
          <Text fontWeight="bold">{tag.name}</Text>
          <Text>
            was removed/resolved
          </Text>
        </HStack>
      </Box>
    </TimelineItem>
  );
};

export const TimelineJobTagsChanged = ({
  event, ...rest
}: {
  event: JobTimelineJobTagsChanged;
} & TimelineItemProps) => {
  const added = difference(event.next, event.previous);
  const removed = difference(event.previous, event.next);

  if (added.length === 0 && removed.length === 0) {
    return null;
  }

  return (
    <>
      {added.map((t) => (<TimelineJobTagAdded key={t} tagId={t} event={event} {...rest} />))}
      {removed.map((t) => (<TimelineJobTagRemoved key={t} tagId={t} event={event} {...rest} />))}
    </>
  );
};
