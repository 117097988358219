import { AnyAction, createAction, PayloadAction } from '@reduxjs/toolkit';

export interface FetchActionPayload {
  keys: string[];
  at: number;
}

export const fetchStarted = createAction<FetchActionPayload>('fetch/started');
export const fetchPartiallyCompleted = createAction<FetchActionPayload>('fetch/partial');
export const fetchCompleted = createAction<FetchActionPayload>('fetch/completed');
export const fetchInvalidated = createAction<FetchActionPayload>('fetch/invalidated');

/**
 * An action that represents the data for one or more keys has been updated.
 * This is useful when a fetch needs to populate many items which have their
 * own fetch state.
 */
export const fetchUpdatedMany = createAction<FetchActionPayload>('fetch/updatedMany');

export interface FetchErrorPayload extends FetchActionPayload {
  errorMessage: string;
  errorType: string;
}
export const fetchErrored = createAction<FetchErrorPayload>('fetch/errored');

export const isFetchAction = (a: AnyAction): a is PayloadAction<FetchActionPayload> => (
  typeof a?.type === 'string' && a.type.startsWith('fetch/')
);
