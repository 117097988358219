import React, { useEffect } from 'react';
import {
  Menu, MenuButton, MenuItem, MenuList, Text,
} from '@chakra-ui/react';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import TaxRateActions from '~/redux/taxRates/actions';
import { selectAllTaxRates } from '~/redux/taxRates/selectors';
import { TaxRate } from '~/types/taxRate';

interface TaxRateSelectorPropsProps {
  name: string;
  rate: number;
  onChange?: ({ name, rate }:{ name: string, rate: number }) => void;
}

const TaxRateSelector = ({
  name, rate, onChange,
}: TaxRateSelectorPropsProps) => {
  const { data: taxRates } = useTrackedFetch<TaxRate[]>({
    key: 'taxRates',
    selector: (state) => selectAllTaxRates(state),
    trigger: () => TaxRateActions.fetch(),
    equalityMode: 'deep',
  });

  useEffect(() => {
    if (!name && taxRates?.length > 0) {
      onChange(taxRates.find((r) => r.isDefault));
    }
  }, [name, onChange, taxRates]);

  const currentTaxRateText = `${name} ${rate}%`;

  if (!onChange) {
    return <Text textTransform="uppercase">{currentTaxRateText}</Text>;
  }

  return (
    <Menu placement="bottom-end">
      <MenuButton m="-3px" p="3px" as={Text} textTransform="uppercase" _hover={{ backgroundColor: 'magnetize.ui-2' }}>
        {currentTaxRateText}
      </MenuButton>
      <MenuList p={0} borderRadius="sm" boxShadow="lg">
        {taxRates.filter((tr) => tr.name !== name && tr.rate !== rate).map((tr) => (
          <MenuItem onClick={() => onChange(tr)} key={tr.id}>
            <Text width="100%" textAlign="right">{`${tr.name} ${tr.rate}%`}</Text>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default TaxRateSelector;
