import { Address } from '~/types/address';
import { Dictionary } from '~/types/helpers';
import UploadedFile from '~/types/uploaded-file';

export interface PowerUps {
  xero: boolean;
  geotechnical: boolean;
  geotechnicalReport: boolean;
}

export interface Tenant {
  tenantId: string;
  name: string;
  phone: string;
  email: string;
  address: Address;
  website: string;
  logo: UploadedFile;
  quoteRequired: boolean;
  quoteTermsAndConditions?: string;
  quoteAttachments: UploadedFile[];
  jobAttachments: UploadedFile[];
  ownerStaffId: string;
  currency: string;
  quoteExpiryDays: number;

  publicHolidaysCountry?: string;
  publicHolidaysState?: string;

  stripePriceId?: string;
  stripeCustomerId?: string;
  stripeSubscriptionId?: string;

  status: SubscriptionStatus;
  planExpiry: number;
  trialStarted: number;

  powerUps: PowerUps;
  liveGeoAutoSend: boolean;
  coordinateSystem: string;
  trialDataType?: string;

  schedulingWeekDays: number[];
}

export const SubscriptionStatusInfo = {
  welcome: {
    isTrial: true,
    isActive: true,
    label: null,
  },
  trial: {
    isTrial: true,
    isActive: true,
    label: null,
  },
  trialExpired: {
    isTrial: true,
    isActive: false,
    label: null,
  },
  subscriptionPending: {
    isTrial: false,
    isActive: true,
    label: 'Pending',
  },
  subscribed: {
    isTrial: false,
    isActive: true,
    label: 'Active',
  },
  subscriptionGrace: {
    isTrial: false,
    isActive: true,
    label: 'Payment Due',
  },
  subscriptionExpired: {
    isTrial: false,
    isActive: false,
    label: 'Payment Due',
  },
  subscriptionCancelled: {
    isTrial: false,
    isActive: false,
    label: 'Cancelled',
  },
};

export type SubscriptionStatus = keyof typeof SubscriptionStatusInfo;

export interface AdminTenantSummary {
  tenantId: string;
  name: string;
  phone: string;
  email: string;
  planExpiry: number;
  trialStarted: number;
  signUpTimestamp: number;
  status: SubscriptionStatus;
}

interface AdminStaffSummary {
  id: string;
  name: string;
  email: string;
  phone: string;
  isAdmin: boolean;
}

export interface AdminTenant extends Tenant {
  staff: AdminStaffSummary[];
  sentEmails?: Dictionary<boolean>;
  welcomeSurvey: any;
  signUpTimestamp: number;
}
