import { createSelector } from '@reduxjs/toolkit';
import { sortBy } from 'lodash';
import { RootState } from '~/redux/store';

export const selectAllStaffById = (s: RootState) => s.staff.entities;

export const selectAllStaff = createSelector(
  (s: RootState) => s.staff.entities,
  (staff) => sortBy(staff, (s) => s.name.toLowerCase()),
);

export const selectStaff = createSelector(
  selectAllStaff,
  (staff) => staff.filter((s) => !s.isDeleted),
);

export const selectStaffById = (s: RootState, staffId: string) => (
  s.staff.entities[staffId]
);

export const selectStaffName = (s: RootState, staffId: string) => (
  selectStaffById(s, staffId)?.name
);

export const selectStaffNames = (
  s: RootState,
  staffIds: string[],
) => staffIds.map((id) => selectStaffName(s, id));
