import {
  Avatar, AvatarProps,
} from '@chakra-ui/react';
import React from 'react';

const UserAvatar = (props : AvatarProps) => (
  <Avatar {...props} bg="magnetize.text-1" color="white" />
);

export default UserAvatar;
