import { useControllableState } from '@chakra-ui/react';
import React from 'react';
import SearchSelect from '~/components/SearchSelect';

interface CategoryFilterProps {
  selected?: string[];
  onSelectionChange?: (selected: string[]) => void;
}

const CategoryFilter = ({
  selected: selectedProp, onSelectionChange,
}: CategoryFilterProps) => {
  const [selected, setSelected] = useControllableState<string[]>({
    defaultValue: [],
    value: selectedProp,
    onChange: onSelectionChange,
  });

  const options = ['bundle', 'labour', 'equipment', 'consumable', 'fee'];

  return (
    <SearchSelect
      isMulti
      hideSearchIcon
      value={selected.map((i) => ({ label: i, value: i }))}
      options={options.map((i) => ({ label: i, value: i }))}
      placeholder="Filter by category"
      size="sm"
      formatOptionLabel={(o) => o.label}
      onChange={(o) => {
        setSelected(o.map((i) => i.value));
      }}
    />
  );
};

export default CategoryFilter;
