import React, { useMemo, useState, useEffect } from 'react';

import {
  Button, Modal, ModalBody, ModalCloseButton, ModalContent,
  ModalFooter, ModalHeader, ModalOverlay,
} from '@chakra-ui/react';
import { isTimeRecordValid, TimeRecord } from '~/types/time';
import TimeRecordForm from '~/components/EditTimeRecordModal/TimeRecordForm';

interface EditTimeRecordModalProps {
  timeRecord?: TimeRecord;
  jobId: string;
  onSave: (value: TimeRecord) => void;
  onClose: () => void;
}

const EditTimeRecordModal = ({
  onClose, jobId, timeRecord: timeRecordProp, onSave,
}: EditTimeRecordModalProps) => {
  const [timeRecord, setTimeRecord] = useState<TimeRecord>();

  useEffect(() => {
    setTimeRecord(timeRecordProp);
  }, [timeRecordProp]);

  const isValid = useMemo(() => isTimeRecordValid(timeRecord), [timeRecord]);

  const handleSaveClicked = () => {
    onSave(timeRecord);
    onClose();
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen
      onClose={onClose}
      size="2xl"
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Add time log
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <TimeRecordForm
            jobId={jobId}
            value={timeRecord}
            onChange={(r) => setTimeRecord(r)}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            size="lg"
            colorScheme="brandDark"
            isLoading={false}
            disabled={!isValid}
            onClick={handleSaveClicked}
          >
            Save
          </Button>
          <Button size="lg" variant="ghost" onClick={onClose}>cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditTimeRecordModal;
