import React, { useState } from 'react';
import {
  Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter,
  ModalHeader, ModalOverlay, FormControl, FormLabel, Input, Text, ListItem, UnorderedList,
} from '@chakra-ui/react';

const CancelPlanModal = ({
  onCancel, onCancelSubscription, isSaving,
}: {
  onCancel: () => void,
  onCancelSubscription: () => void,
  isSaving: boolean,
}) => {
  const [confirmText, setConfirmText] = useState('');

  return (
    <Modal isOpen onClose={onCancel} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader paddingBottom={5}>
          <Text paddingTop={5}>Cancel subscription now</Text>
          <Text marginTop={3} fontSize={14}>
            Are you sure you want to cancel your Magnetize plan?
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody paddingBottom={6}>
          <Text>
            Cancelling your Magnetize subscription means:
          </Text>
          <UnorderedList marginLeft={10} marginTop={5}>
            <ListItem>
              Automated charges to your credit card will stop from the next billing cycle
            </ListItem>
            <ListItem>
              Any overdue payments are still owed
            </ListItem>
            <ListItem>
              Access to Magnetize will be disabled for all users until a plan is reactivated
            </ListItem>
          </UnorderedList>
          <FormControl marginTop={10}>
            <FormLabel>Type cancel to confirm</FormLabel>
            <Input
              size="lg"
              value={confirmText}
              onChange={(e) => { setConfirmText(e.target.value); }}
              placeholder="cancel"
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Flex>
            <Button
              size="lg"
              disabled={isSaving || confirmText.toLocaleLowerCase() !== 'cancel'}
              colorScheme="red"
              isLoading={isSaving}
              onClick={() => {
                onCancelSubscription();
              }}
            >
              Cancel subscription
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CancelPlanModal;
