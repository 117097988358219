import {
  Button,
  ButtonGroup,
  ButtonProps,
  ButtonGroupProps,
} from '@chakra-ui/react';
import { isString } from 'lodash';
import React from 'react';

interface ToggleButtonProps extends ButtonProps {
  value: string;
  isActive: boolean;
  onToggle: (value: string) => void;
}

const ToggleButton = ({
  value, isActive, onToggle, ...buttonProps
}: ToggleButtonProps) => (
  <Button
    {...buttonProps}
    onClick={() => onToggle(value)}
    borderColor="magnetize.ui-4"
    borderLeftWidth={0}
    _first={{
      borderLeftWidth: 1,
    }}
    variant="outline"
    bg="white"
    fontFamily="body"
    fontWeight="bold"
    textTransform="uppercase"
    height="35px"
      // Preventing default on mouse down stops the button taking focus from
      // clicking, so some semblence of keyboard navigability can be preserved
      // without triggering the outline effect on click
    onMouseDown={(e) => e.preventDefault()}
    sx={isActive ? {
      bg: 'magnetize.text-1',
      color: 'white',
      borderColor: 'magnetize.text-1',
      zIndex: 1, // raising z-index to ensure border covers the neighbouring button
      _hover: {
        bg: 'magnetize.text-1',
        color: 'white',
      },
    } : {}}
    borderRadius="sm"

  />
);

interface ToggleButtonGroupProps {
  items: {
    label: string;
    value: string;
  }[] | string[];
  activeItem: string;
  setActiveItem: (value : string) => void;
}

const ToggleButtonGroup = ({
  items, activeItem, setActiveItem, ...props
} : ToggleButtonGroupProps & ButtonGroupProps) => {
  const completedItems = items.map((i) => (isString(i) ? {
    label: i,
    value: i,
  } : i));
  return (
    <ButtonGroup isAttached {...props}>
      {completedItems
        .map(({ label, value }) => (
          <ToggleButton
            key={value}
            value={value}
            onToggle={(v) => setActiveItem(v)}
            isActive={value === activeItem}
          >
            {label}
          </ToggleButton>
        ))}
    </ButtonGroup>
  );
};

export default ToggleButtonGroup;
