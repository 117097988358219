import { Box, Heading, Text } from '@chakra-ui/react';
import { differenceInDays } from 'date-fns';
import React from 'react';

import TimelineItem, { TimelineItemProps } from '~/components/TimelineItem';
import { selectEquipmentNames } from '~/redux/equipment/selectors';
import { selectStaffNames } from '~/redux/staff/selectors';
import { useAppSelector } from '~/redux/store';
import { JobTimelineScheduleAdded } from '~/types/job';
import { makeRangeTime } from '~/components/TimelineSchedule/helpers';
import StatusBadge from '~/components/JobStatusBadge';

const TimelineScheduleAdded = ({
  event, ...rest
}: {
  event: JobTimelineScheduleAdded;
  hideHeading?: boolean;
} & TimelineItemProps) => {
  const staffNames = useAppSelector((s) => selectStaffNames(s, event.staffIds || []));
  const equipmentNames = useAppSelector((s) => selectEquipmentNames(s, event.equipmentIds || []));
  let assigned = 'No resources currently assigned to schedule';

  const note = event.notes || '';
  const from = event.from * 1000;
  const to = event.to * 1000;
  const daysDiff = differenceInDays(to, from);
  const header = `JOB SCHEDULED: ${daysDiff + 1} day${daysDiff > 0 ? 's' : ''}, ${makeRangeTime(from, to)}`;
  if (staffNames.length > 0 || equipmentNames.length > 0) {
    assigned = `Assigned to ${([...staffNames, ...equipmentNames]).join(', ')}`;
  }

  return (
    <TimelineItem
      {...rest}
      event={event}
    >
      {event.status && <Box mb={2}><StatusBadge status={event.status} /></Box> }
      <Heading textTransform="none" size="sm" mb={1}>{header}</Heading>
      <Text>
        {assigned}
      </Text>
      <Text as="i">
        {note}
      </Text>

    </TimelineItem>
  );
};

export default TimelineScheduleAdded;
