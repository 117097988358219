import UploadedFile from '~/types/uploaded-file';

export enum StaffCognitoGroup {
  Admin = 'Admin',
  Staff = 'Staff',
}

export interface Staff {
  id: string;
  name: string;
  jobTitle?: string;
  email?: string;
  phone?: string;
  isAvailable?: boolean;
  hasCognitoUserAccount?: boolean;
  cognitoUsername?: string;
  cognitoGroup?: StaffCognitoGroup;
  tags: string[];
  priceId?: string;
  isDeleted?: boolean;
  profilePicture?: UploadedFile;
  allowCreateJob?: boolean;
  reportsToId?: string;
  inviteEmailId?: string;
  lastAccessTimestamp?: number;
}

export interface StaffInviteStatus {

}
