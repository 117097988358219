import { nanoid } from '@reduxjs/toolkit';
import arrayMove from 'array-move';
import { isNil, keyBy, sortBy } from 'lodash';
import { LineItem } from '~/types/lineItem';
import { Price } from '~/types/price';
import { PriceBundleItem } from '~/types/priceBundle';

type SortableItem = LineItem | PriceBundleItem;

export const priceToLineItem = ({
  id, name, description, unitPriceCents, unit,
}: Price) : LineItem => ({
  id: nanoid(),
  type: 'price',
  priceId: id,
  name,
  description,
  unitPriceCents,
  quantity: 1,
  unit,
  order: 0,
});

export function sortLineItems<T extends SortableItem>(lineItems: T[] | { [key: string]: T }) {
  return sortBy(
    Array.isArray(lineItems) ? lineItems : Object.values(lineItems),
    // Prefer sorting by the ordering number, but break ties by the uuid
    (li) => li.order ?? 0,
    (li) => li.id,
  );
}

export function reorderLineItems<T extends SortableItem>(
  sortedLineItems: T[],
  from?: number,
  to?: number,
) {
  // This is a naive implementation which updates all line items. A more
  // concurrency-friendly solution would be to only update the items where
  // necessary, e.g. to space out the numbers and fuzz so that updates should
  // only affect the moved item in the vast majority of cases.
  const updatedLineItems = !isNil(from) && !isNil(to)
    ? arrayMove(sortedLineItems, from, to)
    : sortedLineItems;
  return keyBy(
    updatedLineItems.map((item, index) => ({ ...item, order: index })),
    (item) => item.id,
  );
}

/**
 * Appends or prepends line items using an existing array of items
 * as a reference. It will return the line items that should be updated, so
 * this should be spread with existing line items.
 */
export function addLineItems<T extends SortableItem>(
  to: 'start' | 'end',
  existingLineItems: T[],
  newItems: T[],
): { [id: string]: T } {
  // This is naive implementation which just uses sequential indexes from the
  // start or end.
  let startIndex: number;
  if (to === 'start') {
    startIndex = (existingLineItems[0]?.order ?? 0) - newItems.length;
  } else {
    startIndex = (existingLineItems[existingLineItems.length - 1]?.order ?? 0) + 1;
  }

  return keyBy(
    newItems.map((item, index) => ({ ...item, order: startIndex + index })),
    (item) => item.id,
  );
}
