import {
  Flex, SkeletonCircle,
} from '@chakra-ui/react';
import React from 'react';
import StaffAvatar from '~/components/StaffAvatar';
import { selectCurrentUser } from '~/redux/currentUser/selectors';
import { useAppSelector } from '~/redux/store';

const CurrentUserAvatar = () => {
  const currentUser = useAppSelector((state) => selectCurrentUser(state));
  const staffId = currentUser.staff?.id;
  return (
    <Flex direction="row-reverse">
      {!staffId
        ? <SkeletonCircle size="12" />
        : <StaffAvatar staffId={staffId} />}
    </Flex>
  );
};

export default CurrentUserAvatar;
