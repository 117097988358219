import { compact } from 'lodash';
import { Address } from '~/types/address';

const ROAD_SUFFIX_PATTERNS: (readonly [RegExp, string])[] = [
  ...Object.entries({
    Road: 'Rd',
    Street: 'St',
    Highway: 'Hwy',
    Motorway: 'Mwy',
    Lane: 'Ln',
    Court: 'Ct',
    Avenue: 'Ave',
    Close: 'Cls',
  }).map(([text, replacement]) => [
    new RegExp(`\\b${text}$`, 'gi'),
    replacement,
  ] as const),

  [/\bState (Highway|Hwy)\b/gi, 'SH'],
  [/\bSaint\b/gi, 'St.'],
  [/\bMount\b/gi, 'Mt.'],
];

export const abbreviateStreetNames = (text: string): string => (
  ROAD_SUFFIX_PATTERNS.reduce((s, [pattern, replacement]) => s.replace(pattern, replacement), text)
);

const formatShortAddress = (address: Address) => {
  if (!address) {
    return undefined;
  }
  return compact([
    address.line1,
    address.line2 ?? address.city,
  ]).map((s) => abbreviateStreetNames(s)).join(', ');
};

export default formatShortAddress;
