import {
  Box, Flex, Grid, Text,
} from '@chakra-ui/react';
import React from 'react';

const grades = ['50', '100', '200', '300', '400', '500', '600', '700', '800', '900'];

const SwatchPreview = ({ colorScheme = 'gray' }: { colorScheme: string }) => (
  <Grid templateColumns="repeat(1, 1fr)">
    {grades.map((g) => (
      <Flex flexDirection="row" key={g}>
        <Box bg={`${colorScheme}.${g}`} minHeight={10} minWidth={10} />
        <Text ml={4}>{`${colorScheme}.${g}`}</Text>
      </Flex>
    ))}
  </Grid>
);

export default SwatchPreview;
