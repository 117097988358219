import { createSelector } from '@reduxjs/toolkit';
import { fromPairs } from 'lodash';
import selectLocalEdits from '~/redux/localEdits/selectors';
import { RootState } from '~/redux/store';
import applyLocalEdits from '~/utils/applyLocalEdits';
import formatShortAddress from '~/utils/formatShortAddress';

export const selectJobStatuses = (state: RootState, jobIds: string[]) => (
  fromPairs(jobIds.map((id) => [id, state.jobs.entities[id]?.data?.status]))
);

export const selectJobs = (state: RootState) => Object.values(state?.jobs?.jobList?.data ?? {})
  .filter((j) => !j.isDeleted);

export const selectJobById = (state: RootState, jobId: string) => {
  const fullJob = state.jobs.entities[jobId]?.data;
  const allJobs = selectJobs(state);
  return fullJob || allJobs.find((j) => j.id === jobId);
};

export const selectJobShortAddress = (state: RootState, jobId: string) => (
  formatShortAddress(selectJobById(state, jobId)?.siteAddress)
);

export const selectJobWithEdits = createSelector(
  selectJobById,
  selectLocalEdits,
  (job, edits) => (job && edits ? applyLocalEdits(job, edits) : job),
);
