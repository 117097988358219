import { Channel } from '@redux-saga/types';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  actionChannel, delay, put, race, take,
} from 'redux-saga/effects';
import {
  beginQueuedWork, QueuedWorkAction, queuedWorkComplete, waitInQueue,
} from '~/redux/queuedTasks/actions';
import { showToast } from '~/toast';

export default function* queueDispatcher() {
  const queueChannel: Channel<QueuedWorkAction> = yield actionChannel(waitInQueue);
  while (true) {
    const work: PayloadAction<QueuedWorkAction> = yield take(queueChannel);
    const { id } = work.payload;
    yield put(beginQueuedWork({ id }));
    const { timeout } = yield race({
      complete: take((a) => queuedWorkComplete.match(a) && a.payload.id === id),
      timeout: delay(10000),
    });

    if (timeout) {
      // TODO: we should probably only show this to devs, or in test builds of
      //       the app. this is definitely a bug and should be logged if it
      //       happens in production but it's not something the end users should
      //       ever see. With a short timeout like 10s though this may well happen
      //       if a lambda is cold.
      showToast({
        title: 'Work queue task timed out',
        description: 'A sync task has taken too long to complete, and the next one'
         + ' will be started. This might be caused by a bug, or slow network conditions',
        status: 'warning',
      });
    }
  }
}
