import {
  cloneDeep, each, flatten, groupBy, intersection,
} from 'lodash';
import { SummaryConsumableRecord } from '~/types/consumable';
import { Equipment } from '~/types/equipment';
import { Invoice } from '~/types/invoice';
import { LineItem } from '~/types/lineItem';
import { EquipmentLogEntry } from '~/types/LogEntry';
import { Price } from '~/types/price';
import { Quote } from '~/types/quote';
import { Staff } from '~/types/staff';
import { TimeRecord } from '~/types/time';

export interface TimeRecordWithStaffAndPrice extends TimeRecord {
  staff: Staff;
  price: Price;
}

export interface EquipmentLogWithEquipmentAndPrice extends EquipmentLogEntry {
  equipment: Equipment;
  price: Price;
}

export interface ReconciliationItem {
  isVisible?: boolean;
  quoted: LineItem[];
  logged: SummaryConsumableRecord[] | TimeRecordWithStaffAndPrice[]
}

export interface MatchingResult {
  reconciliationItems: ReconciliationItem[];
  lineItems: LineItem[];
  consumables: SummaryConsumableRecord[];
}

export const getQuotesLineItems = (quotes: Quote[]) => {
  const lineItems = flatten(
    quotes.map((quote) => Object.values(quote.lineItems)),
  ) ?? [];

  return flatten(
    lineItems.map((lineItem) => {
      if (lineItem.type === 'bundle' && lineItem.expanded) {
        return Object.values(lineItem.lineItems);
      }
      return lineItem;
    }),
  ) ?? [];
};

const matchConsumablesOnPriceId = (
  lineItems: LineItem[],
  consumables: SummaryConsumableRecord[],
) : MatchingResult => {
  const output : MatchingResult = {
    reconciliationItems: [],
    lineItems,
    consumables,
  };
  const lineItemsByPriceId = groupBy(lineItems.filter((li) => li?.priceId), 'priceId');
  const consumablesByPriceId = groupBy(consumables.filter((c) => c.priceId), 'priceId');

  each(lineItemsByPriceId, (quoteLineItems, priceId) => {
    const consumableRecords = consumablesByPriceId[priceId];
    if (consumableRecords) {
      output.reconciliationItems.push({
        quoted: quoteLineItems,
        logged: consumableRecords,
      });
      output.lineItems = output.lineItems.filter((li) => li.priceId !== priceId);
      output.consumables = output.consumables.filter((c) => c.priceId !== priceId);
    }
  });

  return output;
};

const matchConsumablesOnName = (
  lineItems: LineItem[],
  consumables: SummaryConsumableRecord[],
) : MatchingResult => {
  const output : MatchingResult = {
    reconciliationItems: [],
    lineItems,
    consumables,
  };

  const groupByName = (value: any) => value.name.toLowerCase();

  const lineItemsByName = groupBy(lineItems, groupByName);
  const consumablesByName = groupBy(consumables, groupByName);

  each(lineItemsByName, (quoteLineItems, name) => {
    const consumableRecords = consumablesByName[name];
    if (consumableRecords) {
      output.reconciliationItems.push({
        quoted: quoteLineItems,
        logged: consumableRecords,
      });

      output.lineItems = output.lineItems.filter(
        (li) => li.name.toLocaleLowerCase() !== name,
      );
      output.consumables = output.consumables.filter(
        (c) => c.name.toLocaleLowerCase() !== name,
      );
    }
  });

  return output;
};

export const getConsumableReconciliationRows = (
  lineItems: LineItem[], consumablesRecords: SummaryConsumableRecord[],
) : {
  quoteLineItems: LineItem[],
  reconciliationItems: ReconciliationItem[]
} => {
  let quoteLineItems = cloneDeep(lineItems);
  let consumables = cloneDeep(consumablesRecords);
  let reconciliationItems : ReconciliationItem[] = [];

  // Add all matched items as reconciliation rows, each matching function
  // returns the reduced list of lineItems/consumables and the new
  // reconciliation items
  [matchConsumablesOnPriceId, matchConsumablesOnName].forEach((fn) => {
    const output = fn(quoteLineItems, consumables);
    quoteLineItems = output.lineItems;
    consumables = output.consumables;
    reconciliationItems = [
      ...reconciliationItems,
      ...output.reconciliationItems,
    ];
  });

  consumables.forEach((c) => {
    reconciliationItems.push({
      quoted: [],
      logged: [c],
    });
  });

  return ({
    quoteLineItems,
    reconciliationItems,
  });
};

const matchTimeRecordsOnPriceId = (
  lineItems : LineItem[],
  timeRecords : TimeRecordWithStaffAndPrice[],
) => {
  const output = {
    reconciliationItems: [],
    lineItems,
    timeRecords,
  };

  const lineItemsByPriceId = groupBy(lineItems.filter((li) => li.priceId), (li) => li.priceId);
  const timesByPriceId = groupBy(timeRecords
    .filter((tr) => tr.priceId), (li) => li.priceId);

  each(lineItemsByPriceId, (lineItem, priceId) => {
    const times = timesByPriceId[priceId];
    if (times) {
      output.reconciliationItems.push({
        quoted: lineItem,
        logged: times,
      });
      output.lineItems = output.lineItems
        .filter((li) => li.priceId !== priceId);
      output.timeRecords = timeRecords
        .filter((tr) => !times.some((t) => t.id === tr.id));
    }
  });

  return output;
};

const matchTimeRecordsOnName = (
  lineItems : LineItem[],
  timeRecords : TimeRecordWithStaffAndPrice[],
) => {
  const output = {
    reconciliationItems: [],
    lineItems,
    timeRecords,
  };

  const lineItemsByName = groupBy(lineItems.filter((li) => li.name), (li) => li.name);
  const timesByName = groupBy(timeRecords
    .filter((tr) => tr.price), (tr) => tr.price.name);

  each(lineItemsByName, (lineItem, name) => {
    const times = timesByName[name];
    if (times) {
      output.reconciliationItems.push({
        quoted: lineItem,
        logged: times,
      });
      output.lineItems = output.lineItems
        .filter((li) => li.name !== name);
      output.timeRecords = output.timeRecords
        .filter((tr) => !times.some((t) => t.price.name === tr.price.name));
    }
  });

  return output;
};

export const getTimeReconciliationRows = (
  lineItems: LineItem[],
  time: TimeRecord[],
  staff: Staff[],
  prices: Price[],
) => {
  let quoteLineItems = cloneDeep(lineItems);
  let timeRecords : TimeRecordWithStaffAndPrice[] = time.map((tr) => {
    const staffMatch = staff.find((s) => s.id === tr.staffId);
    const priceMatch = tr.priceId && prices.find((p) => p.id === tr.priceId);

    if (!staffMatch) {
      return null;
    }

    return {
      ...tr,
      staff: staffMatch,
      price: priceMatch,
    };
  }).filter((tr) => tr);

  let reconciliationItems = [];

  [matchTimeRecordsOnPriceId, matchTimeRecordsOnName].forEach((fn) => {
    const output = fn(quoteLineItems, timeRecords);
    quoteLineItems = output.lineItems;
    timeRecords = output.timeRecords;
    reconciliationItems = [
      ...reconciliationItems,
      ...output.reconciliationItems,
    ];
  });

  timeRecords.forEach((tr) => {
    reconciliationItems.push({
      quoted: [],
      logged: [tr],
    });
  });

  return ({
    quoteLineItems,
    reconciliationItems,
  });
};

export const getEquipmentReconciliationRows = (
  lineItems: LineItem[],
  logs: EquipmentLogEntry[],
  equipment: Equipment[],
  prices: Price[],
) => {
  const quoteLineItems = cloneDeep(lineItems);
  const equipmentLogs : EquipmentLogWithEquipmentAndPrice[] = logs.map((log) => {
    const equipmentMatch = equipment.find((e) => e.id === log.resourceId);
    const priceMatch = prices.find((p) => p.id === log.priceId);

    if (!equipmentMatch) {
      return null;
    }

    return {
      ...log,
      equipment: equipmentMatch,
      price: priceMatch,
    };
  }).filter((l) => l);

  const reconciliationItems = [];

  const lineItemsByPriceId = groupBy(lineItems.filter((li) => li.priceId), (li) => li.priceId);
  const logsByPriceId = groupBy(equipmentLogs
    .filter((el) => el.priceId), (e) => e.priceId);

  each(lineItemsByPriceId, (lineItem, priceId) => {
    const logsForPrice = logsByPriceId[priceId];
    if (logsForPrice) {
      reconciliationItems.push({
        quoted: lineItem,
        logged: logsForPrice,
      });
    }
  });

  const logsWithoutQuoteByEquipment = groupBy(equipmentLogs
    .filter((l) => l.equipment
    && (!l.priceId || !lineItems.some((li) => li.priceId === l.priceId))),
  (l) => l.equipment.id);

  each(Object.values(logsWithoutQuoteByEquipment), (l) => {
    if (l) {
      reconciliationItems.push({
        quoted: [],
        logged: l,
      });
    }
  });

  return ({
    quoteLineItems,
    reconciliationItems,
  });
};

export const getLeftoverReconciliationRows = (
  lineItems: LineItem[],
) : ReconciliationItem[] => Object.values(
  groupBy(lineItems, (li) => (li.priceId ? li.priceId : li.name)),
).map((quoted) => ({
  quoted,
  logged: [],
}));

export const getIds = (
  ...input: { id: string }[][]
) : string[] => input.flatMap((e) => (Array.isArray(e) ? e.map((x) => x.id) : []));

export const setReconciliationRowVisibility = (
  invoice: Invoice, reconciliationRows: {
    quoted: { id: string }[],
    logged: { id: string }[]
  }[],
) : any => {
  const invoiceReconciledItems = Object
    .values(invoice.lineItems)
    .flatMap((li) => li.reconciledItems) ?? [];
  const ignoredReconciledItems = invoice.ignoredReconciledItems ?? [];

  const toIgnore = [...invoiceReconciledItems, ...ignoredReconciledItems];

  return reconciliationRows.map((r) => {
    const reconciledItems = getIds(r.quoted, r.logged);
    return {
      ...r,
      isVisible: !intersection(toIgnore, reconciledItems).length,
    };
  });
};

const getReconciledIds = (invoice: Invoice) => {
  const invoiceReconciledItems = Object
    .values(invoice.lineItems)
    .flatMap((li) => li.reconciledItems) ?? [];
  const ignoredReconciledItems = invoice.ignoredReconciledItems ?? [];
  return [...invoiceReconciledItems, ...ignoredReconciledItems];
};

export const getItemsNeedingReconciliation = <T extends { id: string }>(
  invoice: Invoice, items: Array<T>,
): Array<T> => {
  const toIgnore = getReconciledIds(invoice);
  return items.filter((li) => toIgnore.indexOf(li.id) === -1);
};
