import React, { useContext } from 'react';
import { CalendarContext } from '~/components/Calendar/CalendarContext';
import CalendarGrid from '~/components/Calendar/CalendarGrid';
import CalendarGridItem from '~/components/Calendar/CalendarGridItem';
import { useScrollbarWidth } from '~/hooks/useScrollbarWidth';
import { makeTransparent } from '~/theme';

const CalendarDayDividers = () => {
  const calendarContext = useContext(CalendarContext);
  const scrollbarWidth = useScrollbarWidth();

  return (
    <CalendarGrid subdivisions={1} height="100%">
      {calendarContext.days.map((day, index) => {
        let bg = 'magnetize.ui-2';
        if (day.isWeekend || day.isHoliday) {
          bg = 'magnetize.ui-3';
        }
        if (day.isToday) {
          bg = makeTransparent('#359B4B', 0.05);
        }
        return (
          <CalendarGridItem
            start={day.start}
            end={day.end}
            key={day.start.getTime()}
            borderLeftWidth={index > 0 ? '1px' : 0}
            paddingRight={index === calendarContext.days.length - 1 ? `${scrollbarWidth}px` : 0}
            borderLeftColor="magnetize.ui-5"
            bg={bg}
          />
        );
      })}
    </CalendarGrid>
  );
};

export default CalendarDayDividers;
