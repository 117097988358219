import { createAction } from '@reduxjs/toolkit';
import { BackfillType } from '~/types/backfillType';
import { CasingType } from '~/types/casingType';
import { ClearanceTechnique } from '~/types/clearanceTechnique';
import { Customer } from '~/types/customer';
import { CustomerContact } from '~/types/customerContact';
import { DrillingTechnique } from '~/types/drillingTechnique';
import { Borehole, CPT } from '~/types/geotechnical';
import { Job } from '~/types/job';
import { PiezoType } from '~/types/piezoType';
import { RefusalReason } from '~/types/refusalReason';
import { SoilType } from '~/types/soilType';
import { SurfaceType } from '~/types/surfaceType';

export interface CustomerLiveGeoData {
  boreholes: Borehole[];
  cpts: CPT[];
  equipment: {
    id: string,
    name: string,
    nickname?: string,
  }[];
  job: Partial<Job>;
  customer: Customer;
  contact: CustomerContact;
  drillingTechniques: DrillingTechnique[];
  soilTypes: SoilType[];
  clearanceTechniques: ClearanceTechnique[];
  surfaceTypes: SurfaceType[];
  casingTypes: CasingType[];
  refusalReasons: RefusalReason[];
  backfillTypes: BackfillType[];
  piezoTypes: PiezoType[];
}

export const CustomerLiveGeoActions = {
  fetch: createAction<{ token: string }>('geotechnical/borehole/fetch/withToken'),
  updated: createAction<{
    token: string,
    data: CustomerLiveGeoData
  }>('geotechnical/borehole/updated'),
};
