import {
  all,
  call, put, select, takeEvery,
} from 'redux-saga/effects';
import {
  customerTenantFetchError,
  customerTenantFetchStart,
  customerTenantFetchSuccess,
  triggerCustomerTenantFetch,
} from '~/redux/customerTenant/actions';
import { getCustomerTenant } from '~/api/customerTenantApi';
import { selectCustomerTenantFetchState } from '~/redux/customerTenant/selectors';
import { Tenant } from '~/types/tenant';

const handleFetchCustomerTenant = takeEvery(
  triggerCustomerTenantFetch,
  function* handle(action) {
    const { token } = action.payload;
    // TODO Get correct type here...
    const quoteState = yield select(
      (state) => selectCustomerTenantFetchState(state, token),
    );
    if (quoteState?.status !== 'loading') {
      yield put(customerTenantFetchStart({ token }));
      try {
        const tenant: Tenant = yield call(getCustomerTenant, token);
        yield put(customerTenantFetchSuccess({ token, data: tenant }));
      } catch (error) {
        yield put(customerTenantFetchError({ token, errorMessage: String(error) }));
      }
    }
  },
);

export default function* fetchCustomerTenant() {
  yield all([
    handleFetchCustomerTenant,
  ]);
}
