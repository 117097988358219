import {
  takeEvery, all, put,
} from 'redux-saga/effects';
import { currentUserActions } from '~/redux/currentUser/actions';

const watchInitAndLogin = takeEvery(
  'app/init',
  function* handle() {
    yield put(currentUserActions.fetch());
  },
);

export default function* watchAllInit() {
  yield all([
    watchInitAndLogin,
  ]);
}
