import {
  all, call, put, takeEvery,
} from 'redux-saga/effects';
import CurrenciesActions from '~/redux/currencies/actions';
import { Currency } from '~/types/currency';
import fetchJson from '~/utils/fetchJson';
import { performFetchSaga } from '~/utils/performFetchSaga';

const watchFetch = takeEvery(
  CurrenciesActions.fetch,
  function* handle() {
    yield performFetchSaga({
      key: 'currencies',
      staleTime: 30000,
      * saga() {
        const currencies: Currency[] = yield call(
          fetchJson,
          '/api/currencies',
        );

        yield put(CurrenciesActions.loaded({
          currencies,
        }));
      },
    });
  },
);
export default function* handleCurrencies() {
  yield all([
    watchFetch,
  ]);
}
