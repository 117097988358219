import React from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './index.css';

export interface DatePickerProps extends ReactDatePickerProps {
  size?: 'sm' | 'md' | 'lg';
  variant?: 'normal' | 'ghost';
  onChange: (d: Date | [Date, Date]) => void;
}

const DatePicker = ({
  size = 'lg',
  variant = 'normal',
  onChange,
  ...props
}: DatePickerProps) => {
  const className = `react-datepicker__input--size-${size} react-datepicker__input--variant-${variant}`;
  const calendarClassName = `react-datepicker__calendar--size-${size} react-datepicker__calendar--variant-${variant}`;
  return (
    <ReactDatePicker
      dateFormat="d MMM yyyy"
      onChange={onChange}
      className={className}
      calendarClassName={calendarClassName}
      {...props}
    />
  );
};

export default DatePicker;
