import {
  Box, BoxProps, useTheme, forwardRef,
} from '@chakra-ui/react';
import { getColor } from '@chakra-ui/theme-tools';
import React from 'react';
import { FORM_CONTROL_FOCUS_BORDER_COLOUR } from '~/theme/constants';

interface OutlineWhenFocusWithinProps {
  /**
   * An optional ref to a focusable object. If the component's box
   * gains focus ifself, it will attempt to focus this referenced
   * object instead.
   */
  delegateFocusTo?: React.RefObject<{ focus(): void }>;
}

const OutlineWhenFocusWithin = forwardRef<BoxProps, 'div'>(({
  delegateFocusTo, onFocus, ...boxProps
}: OutlineWhenFocusWithinProps & BoxProps, ref) => {
  const theme = useTheme();

  const focusStyle = {
    outline: 0,
    borderColor: FORM_CONTROL_FOCUS_BORDER_COLOUR,
    boxShadow: `0 0 0 1px ${getColor(theme, FORM_CONTROL_FOCUS_BORDER_COLOUR)}`,
  };

  const handleFocusGained = (e: React.FocusEvent<HTMLDivElement>) => {
    onFocus?.(e);
    if (e.target === e.currentTarget) {
      delegateFocusTo?.current?.focus();
    }
  };

  return (
    <Box
      ref={ref}
      border="1px"
      borderColor="gray.200"
      bg="white"
      p={3}
      // Make this element focusable by mouse. This means if clicks hit the
      // element it will appear selected. You might want to pass focus on to
      // another element, which the delegateFocusTo prop will handle automatically.
      tabIndex={-1}
      transition="all 0.2s"
      borderRadius="xs"
      onFocus={handleFocusGained}
      _focus={focusStyle}
      _focusWithin={focusStyle}
      {...boxProps}
    />
  );
});

export default OutlineWhenFocusWithin;
