import { areIntervalsOverlapping } from 'date-fns';
import { sortBy, uniqBy } from 'lodash';
import { RootState } from '~/redux/reducer';
import { ResourceIdentifier } from '~/types/resource';
import { isJobResourceEvent, JobResourceEvent } from '~/types/ScheduleEvent';

export const selectEventsWithinRange = (
  state: RootState,
  start: number | Date,
  end: number | Date,
) => {
  const allEvents = Object.values(state.scheduleEvents);
  const eventsInRange = allEvents
    .filter((e) => areIntervalsOverlapping(
      { start, end },
      { start: e.start, end: e.end },
    ));

  const jobEventIds = eventsInRange
    .filter((e) => e.type === 'job')
    .map((e) => e.id);
  // Ensure that we grab all resource events for job events which are in range
  const resourceEvents = allEvents.filter(
    (e) => e.type === 'job-resource' && jobEventIds.includes(e.parentId),
  );

  return uniqBy([...eventsInRange, ...resourceEvents], (e) => e.id);
};

export const selectEventsForResourceWithinRange = (
  state: RootState,
  resourceId: string,
  from: number,
  to: number,
) => (
  selectEventsWithinRange(state, from, to)
    .filter((ev) => ev.resourceId === resourceId)
);

export const selectScheduleEventsForJob = (state: RootState, jobId: string) => (
  Object.values(state.scheduleEvents)
    .filter((e) => e.jobId === jobId));

export const selectJobResourceEvents = (s: RootState, jobId: string) => (
  selectScheduleEventsForJob(s, jobId)
    .filter(isJobResourceEvent)
);

export const selectResourcesAssignedToJob = (
  state: RootState,
  jobId: string,
): ResourceIdentifier[] => (
  // Get the sorted, unique list of resources assigned to a job.
  sortBy(
    uniqBy(
      selectScheduleEventsForJob(state, jobId)
        .filter((e) => e.type === 'job-resource')
        .map((e: JobResourceEvent) => ({ id: e.resourceId, type: e.resourceType as 'staff' | 'equipment' })),
      (r) => r.id,
    ),
    (r) => r.id,
  )
);

export const selectScheduleEvent = (state: RootState, eventId: string) => (
  state.scheduleEvents[eventId]
);

export const selectJobEventResources = (state: RootState, jobEventId: string) => (
  Object.values(state.scheduleEvents)
    .filter((ev) => ev.type === 'job-resource'
      && ev.parentId === jobEventId) as JobResourceEvent[]
);
