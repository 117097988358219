import React from 'react';
import {
  Stack, Box,
} from '@chakra-ui/react';
import Card from '~/components/Card';
import CardDivider from '~/components/CardDivider';
import CardTitle from '~/components/CardTitle';
import JobContactInfoSection from './JobContactInfoSection';
import JobCustomer from './JobCustomer';
import JobSiteAddressSection from './JobSiteAddressSection';
import { useAppSelector, useAppDispatch } from '~/redux/store';
import { selectJobWithEdits } from '~/redux/jobs/selectors';
import { updateJob } from '~/redux/jobs/actions';
import { Job } from '~/types/job';
import { JobTagEditor } from '~/components/JobTagEditor';
import { selectTenantPowerUps } from '~/redux/currentUser/selectors';
import AutoSendLiveGeo from '~/components/AutoSendLiveGeo';

const JobSidePanel = ({ jobId }: { jobId: string }) => {
  const dispatch = useAppDispatch();
  const powerUps = useAppSelector((state) => selectTenantPowerUps(state));
  const job = useAppSelector((state) => selectJobWithEdits(state, jobId));
  const updateCurrentJob = (changes: Partial<Job>) => {
    dispatch(updateJob({ id: job.id, ...changes }));
  };

  return (
    <>
      <Stack spacing={8} align="stretch">
        <JobCustomer job={job} />
        <Card>
          <JobTagEditor
            jobId={jobId}
          />
          <CardDivider />
          <JobContactInfoSection
            title="Job Contact"
            value={job.jobContactId}
            customerId={job.customerId}
            onChange={(contact) => {
              updateCurrentJob(
                {
                  jobContactId: contact?.id ?? null,
                  customerId: contact?.customerId ?? job.customerId,
                },
              );
            }}
          />
          <CardDivider />

          <CardTitle>Site Details</CardTitle>
          <Stack spacing="4">
            <Box>
              <JobSiteAddressSection
                title="Site Address"
                value={job.siteAddress}
                onChange={(address) => updateCurrentJob({ siteAddress: address })}
              />
            </Box>
            {/* This is a bit of a hack to increase the spacing between the sidebar sections.
            If I increase the stack spacing then the add buttons look too far apart (due to
            being link buttons) */}
            {/* Todo get site contact working again... */}
            <Box pt={job.siteContactId ? '4' : undefined}>
              <JobContactInfoSection
                title="Site Contact"
                value={job.siteContactId}
                customerId={job.customerId}
                showTitleWhenUnset={false}
                onChange={(contact) => {
                  updateCurrentJob(
                    {
                      siteContactId: contact?.id ?? null,
                      customerId: contact?.customerId ?? job.customerId,
                    },
                  );
                }}
              />
            </Box>
          </Stack>

          {powerUps?.geotechnical && (
          <>
            <CardDivider />
            <CardTitle>Live GEO</CardTitle>

            <AutoSendLiveGeo jobId={jobId} />
          </>
          )}
        </Card>
      </Stack>
    </>
  );
};

export default JobSidePanel;
