import {
  Box, Button, Heading, Stack, Text, useDisclosure, VStack,
} from '@chakra-ui/react';
import React from 'react';
import { DeactivateLiveGeoModal } from './DeactivateLiveGeoModal';
import { Tenant } from '~/types/tenant';
import TenantAutoSendLiveGeo from '~/pages/TenantSettings/PowerUpsTab/LiveGeoSettings/TenantAutoSendLiveGeo';
import { DrillingTechniquesList } from '~/pages/TenantSettings/PowerUpsTab/LiveGeoSettings/DrillingTechniquesList';
import { SoilTypesList } from '~/pages/TenantSettings/PowerUpsTab/LiveGeoSettings/SoilTypesList';
import { ClearanceTechniquesList } from '~/pages/TenantSettings/PowerUpsTab/LiveGeoSettings/ClearanceTechniquesList';
import { RefusalReasonsList } from '~/pages/TenantSettings/PowerUpsTab/LiveGeoSettings/RefusalReasonList';
import { CasingTypesList } from '~/pages/TenantSettings/PowerUpsTab/LiveGeoSettings/CasingTypesList';
import { SurfaceTypesList } from '~/pages/TenantSettings/PowerUpsTab/LiveGeoSettings/SurfaceTypeList';
import { BackfillTypesList } from '~/pages/TenantSettings/PowerUpsTab/LiveGeoSettings/BackfillTypesList';
import { PiezoTypesList } from '~/pages/TenantSettings/PowerUpsTab/LiveGeoSettings/PiezoTypeList';

export const LiveGeoSettings = ({ tenant, onSave }: { tenant: Tenant,
  onSave: (tenant: Tenant) => void; }) => {
  const deactivatePowerup = useDisclosure();

  return (
    <>

      <Heading mt={6} padding={2} size="sm">AUTO-SEND DRILL LOGS</Heading>
      <Stack spacing={8} padding={2}>
        <Text>
          Use this toggle to decide if Live Geo reports will be
          automatically sent to the job contact when drilling starts.
          The job contact will receive an email with a link to see
          logs, live.
        </Text>
        <TenantAutoSendLiveGeo onSave={onSave} tenant={tenant} />

        <VStack spacing={6} align="stretch">
          <Heading mt={6} size="sm">EDIT LOG OPTIONS</Heading>
          <Box
            borderTopWidth={1}
            borderTopColor="magnetize.ui-3"
          >
            <SoilTypesList />
            <DrillingTechniquesList />
            <CasingTypesList />
            <ClearanceTechniquesList />
            <RefusalReasonsList />
          </Box>
        </VStack>
        <VStack spacing={6} align="stretch">
          <Heading mt={6} size="sm">EDIT INSTALL OPTIONS</Heading>
          <Box
            borderTopWidth={1}
            borderTopColor="magnetize.ui-3"
          >
            <SurfaceTypesList />
            <BackfillTypesList />
            <PiezoTypesList />
          </Box>
        </VStack>
        <Box>
          <Button
            variant="link"
            color="magnetize.support-error"
            onClick={deactivatePowerup.onOpen}
          >
            Deactivate Power Up
          </Button>
        </Box>
      </Stack>

      {deactivatePowerup.isOpen && (
      <DeactivateLiveGeoModal
        onCancel={deactivatePowerup.onClose}
        onDeactivated={() => {
          deactivatePowerup.onClose();
        }}
      />
      )}
    </>
  );
};
