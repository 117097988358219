import { createSelector } from '@reduxjs/toolkit';
import { filter, sortBy } from 'lodash';
import { RootState } from '~/redux/store';

export const selectAllTaxRates = createSelector(
  (state: RootState) => state.taxRates.entities,
  (taxRates) => sortBy(taxRates, (p) => p.name.toLowerCase()),
);

export const selectAvailableTaxRates = createSelector(
  selectAllTaxRates,
  (taxRates) => filter(taxRates, (p) => p.isAvailable),
);

export const selectTaxRateById = (state: RootState, id: string) => state.taxRates.entities[id];
