import { createReducer } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';
import TaxRateActions from '~/redux/taxRates/actions';
import { Dictionary } from '~/types/helpers';
import { TaxRate } from '~/types/taxRate';

interface TaxRatesState {
  entities: Dictionary<TaxRate>;
}

export default createReducer<TaxRatesState>({
  entities: {},
}, (builder) => {
  builder.addCase(TaxRateActions.updated, (state, action) => {
    const { taxRates } = action.payload;
    return ({
      entities: {
        ...state.entities,
        ...keyBy(taxRates, (p) => p.id),
      },
    });
  });
  builder.addCase(TaxRateActions.delete, (state, action) => {
    const { taxRateId } = action.payload;
    return ({
      entities: {
        ...keyBy(Object.values(state.entities).filter((t) => t.id !== taxRateId), (p) => p.id),
      },
    });
  });
});
