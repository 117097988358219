import { Flex, Text } from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import React, { useMemo } from 'react';
import SearchSelect from '~/components/SearchSelect';
import { useCurrencyFormatter } from '~/hooks/useFormatCurrency';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import { makeBundleLineItem, makePriceBundleViewModels } from '~/pages/PriceBook/BundleViewModel';
import PriceBundleActions from '~/redux/priceBundle/actions';
import { selectPriceBundles } from '~/redux/priceBundle/selectors';
import PriceActions from '~/redux/prices/actions';
import { selectAllPrices, selectAvailablePrices } from '~/redux/prices/selectors';
import { useAppSelector } from '~/redux/store';
import { BundleLineItem, LineItem } from '~/types/lineItem';
import { Price } from '~/types/price';
import { PriceBundle } from '~/types/priceBundle';
import { priceToLineItem } from '~/utils/lineItemHelpers';

const RenderPriceOption = ({ option, price }: { option: LineItem, price: Price }) => {
  const formatCurrency = useCurrencyFormatter();
  return (
    <Flex justifyContent="space-between">
      <Text flex="1">
        {option.name}
      </Text>
      <Flex>
        {option.id && (
        <Text>
          {formatCurrency(option.unitPriceCents)}
        </Text>
        )}
        {price.unit && (
        <Text color="magnetize.text-4" ml={1}>
          /
          {' '}
          {price.unit}
        </Text>
        )}
      </Flex>
    </Flex>
  );
};

const RenderBundleOption = ({ option }: { option: BundleLineItem }) => {
  const formatCurrency = useCurrencyFormatter();
  return (
    <Flex justifyContent="space-between">
      <Text fontWeight="bold" flex="1">
        {option.name}
      </Text>
      <Flex>
        <Text fontWeight="bold">
          {formatCurrency(option.unitPriceCents)}
          {' '}
          bundle
        </Text>
      </Flex>
    </Flex>
  );
};
interface PriceBookSearchProps {
  onSelected: (lineItem: LineItem) => void;
  showBundles?: boolean;
}

const PriceBookSearch = (
  { onSelected, showBundles }: PriceBookSearchProps,
) => {
  const { data: prices, isLoading: isPricesLoading } = useTrackedFetch<Price[]>({
    key: 'prices',
    selector: (state) => selectAvailablePrices(state),
    trigger: () => PriceActions.fetch(),
  });

  const { data: priceBundles, isLoading: isPriceBundlesLoading } = useTrackedFetch<PriceBundle[]>({
    key: 'price-bundles',
    selector: (state) => selectPriceBundles(state),
    trigger: () => PriceBundleActions.fetch(),
  });
  const allPrices = useAppSelector((s) => selectAllPrices(s));

  const bundleLineItems = useMemo(
    () => {
      const bundleViewModels = makePriceBundleViewModels(priceBundles, allPrices) ?? [];
      return bundleViewModels.map((b) => makeBundleLineItem(b));
    },
    [priceBundles, allPrices],
  );

  const priceLineItems = useMemo(
    () => prices?.map((p) => priceToLineItem(p)) ?? [],
    [prices],
  );

  const options = showBundles ? [...bundleLineItems, ...priceLineItems] : priceLineItems;

  return (
    <SearchSelect
      placeholder="Search equipment, labour and costs"
      options={options}
      value={null}
      renderOption={({ option }) => (
        option.type === 'bundle'
          ? RenderBundleOption({ option })
          : RenderPriceOption({
            option,
            price: prices.find((p) => p.id === option.priceId),
          })
      )}
      getOptionLabel={(o) => o.name}
      getOptionValue={(o) => o.id}
      onChange={(option) => {
        onSelected({ ...option, id: nanoid() });
      }}
      isLoading={isPricesLoading || isPriceBundlesLoading}
    />
  );
};

export default PriceBookSearch;
