import { Flex, Text, Box } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import Flags from 'country-flag-icons/react/3x2';
import { orderBy } from 'lodash';
import SearchSelect from '~/components/SearchSelect';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import CurrenciesActions from '~/redux/currencies/actions';
import { Currency } from '~/types/currency';
import LinkButton from '~/components/LinkButton';

interface CurrencySearchSelectProps {
  value: string;
  placeholder?: string;
  onChange: (value: Currency) => void;
}

const CurrencyWithFlag = ({ currency }: { currency: Partial<Currency> }) => {
  if (!currency) {
    return <></>;
  }
  const FlagComponent = Flags[currency.countryCode];
  if (!FlagComponent) {
    return <></>;
  }
  return (
    <Flex>
      <Box h={5} w={10}>
        {currency.countryCode && <FlagComponent /> }
      </Box>
      <Text ml={5} fontWeight="bold">
        {`${currency.name}${currency.shortCode ? ` (${currency.shortCode})` : ''}`}
      </Text>
    </Flex>
  );
};

const CurrencyOption = ({ option }: { option: Partial<Currency> }) => (
  <Flex>
    <CurrencyWithFlag currency={option} />
  </Flex>
);

const CurrentValue = ({ option }: { option: Partial<Currency> }) => (
  <Flex justifyContent="space-between" flex={1}>
    <CurrencyWithFlag currency={option} />
    <LinkButton isGreen noUnderline>change currency</LinkButton>
  </Flex>
);

const CurrencySearchSelect = ({
  placeholder = 'Select a currency...', value, onChange,
} : CurrencySearchSelectProps) => {
  const { data: currencies, isLoading } = useTrackedFetch({
    key: 'currencies',
    trigger: () => CurrenciesActions.fetch(),
    selector: (state) => Object.values(state.currencies.entities),
  });

  const selectedValue = useMemo(
    () => {
      if (!currencies) {
        return null;
      }
      return currencies.find((p) => p.shortCode === value);
    },
    [currencies, value],
  );

  return (
    <SearchSelect
      isLoading={isLoading}
      hideSearchIcon
      value={selectedValue}
      getOptionLabel={(p) => p.name}
      getOptionValue={(p) => p.shortCode}
      onChange={onChange}
      options={orderBy(currencies, (c) => c.name)}
      renderOption={CurrencyOption}
      renderCurrentValue={CurrentValue}
      placeholder={placeholder}
      variant="link"
      hideCaret
      blurInputOnSelect
    />
  );
};

export default CurrencySearchSelect;
