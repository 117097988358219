import {
  Box, Flex, Icon, SkeletonText, Stack, Text,
} from '@chakra-ui/react';
import { PersonOutlined, PlaceOutlined } from '@material-ui/icons';
import React, { useEffect, useMemo } from 'react';
import { triggerJobFetch } from '~/redux/jobs/actions';
import { selectJobById, selectJobShortAddress } from '~/redux/jobs/selectors';
import { selectDoesNeedRefetch, selectFetchState } from '~/redux/fetch/selectors';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import { CustomerContactActions } from '~/redux/customerContacts/actions';
import { selectCustomerContactById } from '~/redux/customerContacts/selectors';

interface RowWithIconProps {
  children: React.ReactNode;
  isLoading: boolean;
  icon: React.ReactNode;
}

const RowWithIcon = ({ children, isLoading, icon }: RowWithIconProps) => (
  <Flex alignItems="center">
    <Box color="gray.900" mr="2">
      {icon}
    </Box>
    <Box color="gray.800" flex="1 1 auto">
      {isLoading ? <SkeletonText noOfLines={1} /> : children}
    </Box>
  </Flex>
);

const JobContactAddressSummary = ({ jobId }: { jobId: string }) => {
  const dispatch = useAppDispatch();
  const job = useAppSelector((state) => selectJobById(state, jobId));
  const { isLoading: isLoadingJob } = useAppSelector(
    (state) => selectFetchState(state, jobId),
  );
  const {
    data: contactInfo, isLoading: isLoadingContactInfo,
  } = useTrackedFetch({
    trigger: () => CustomerContactActions.fetch({ id: job?.jobContactId }),
    selector: (s) => selectCustomerContactById(s, job?.jobContactId),
    key: `contact-${job?.jobContactId}`,
    enabled: !!job?.jobContactId,
  });

  const needsRefetch = useAppSelector((state) => (
    selectDoesNeedRefetch(state, jobId, { staleTime: 10000 })
  ));

  useEffect(() => {
    if (jobId && needsRefetch) {
      dispatch(triggerJobFetch({ jobId }));
    }
  }, [jobId, needsRefetch]);

  const isLoading = isLoadingJob || isLoadingContactInfo;
  const shortAddress = useAppSelector((state) => selectJobShortAddress(state, jobId));
  const shortContactInfo = useMemo(
    () => ([
      contactInfo?.name,
      contactInfo?.phone,
    ].filter((x) => !!x).join(', ')),
    [contactInfo],
  );

  return (
    <Stack spacing="2">
      {(isLoading || job?.siteAddress) && (
        <RowWithIcon icon={<Icon fontSize="16px" boxSize="16px" as={PlaceOutlined} />} isLoading={isLoading}>
          <Flex alignItems="flex-start">
            {job?.siteAddress && <Text fontWeight="semibold">{shortAddress}</Text>}
          </Flex>
        </RowWithIcon>
      )}
      {(isLoading || job?.jobContactId) && (
        <RowWithIcon icon={<Icon fontSize="16px" boxSize="16px" as={PersonOutlined} />} isLoading={isLoading}>
          <Text fontWeight="semibold">{shortContactInfo}</Text>
        </RowWithIcon>
      )}
    </Stack>
  );
};

export default JobContactAddressSummary;
