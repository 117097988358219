import {
  Grid, Heading, Text, Link, Flex, Box, Stack,
} from '@chakra-ui/react';
import { captureException } from '@sentry/react';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { first, split } from 'lodash';
import LoginBackground from '~/components/LoginBackground';
import { magnetizeDocsUrl } from '~/helpers/config';
import { useAppSelector } from '~/redux/store';
import { selectCurrentUser } from '~/redux/currentUser/selectors';

const RedirectPage = () => {
  const location = useLocation();
  const currentUser = useAppSelector((state) => selectCurrentUser(state));
  const currentUserFirstName = first(split(currentUser.staff?.name ?? '', ' '));

  const PhoneImage = () => (
    <Box pt={6}>
      <img
        width="250px"
        height="45px"
        alt="Mobile App"
        src="/images/redirect-phone.png"
      />
    </Box>
  );

  const LogoImage = () => (
    <Box pt={6}>
      <img
        width="210px"
        height="37px"
        alt="Magnetize Logo"
        src="/images/login-logo.png"
      />
    </Box>
  );

  useEffect(() => {
    captureException(new Error(`Page not found: ${location.pathname}`));
  }, [location.pathname]);

  return (
    <LoginBackground>
      <Flex
        boxShadow="md"
        borderRadius="md"
        bgColor="white"
        width={{ base: '100vw', sm: '425px', md: '425px' }}
        height={{ base: '100vh', sm: 'fit-content', md: 'fit-content' }}
      >
        <Flex
          flex={1}
          direction="column"
          marginLeft={12}
          marginRight={12}
          marginTop={12}
        >
          <LogoImage />
          <Stack spacing={4} mt={14}>
            <form>
              <Grid gap={6}>
                <Text pl={0} fontSize="xl" fontWeight={900}>
                  {`Hey${currentUserFirstName ? ` ${currentUserFirstName}` : ''}, welcome! 👋`}
                </Text>
                <Heading pl={0} fontWeight={700} textTransform="none">
                  Open the Magnetize mobile app to continue
                </Heading>
                <Text pl={0} fontSize="xl">
                  Your account is set up for the mobile app.
                  Download and open the app from your smartphone.
                  <Link
                    href={`${magnetizeDocsUrl}`}
                    target="_blank"
                    color="magnetize.brand-4"
                  >
                    {' More help'}
                  </Link>
                </Text>
              </Grid>
            </form>
          </Stack>
          <PhoneImage />
        </Flex>
      </Flex>
    </LoginBackground>
  );
};

export default RedirectPage;
