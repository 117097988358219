import {
  Button, ButtonGroup, Flex, GridItem, Heading,
  HStack, Icon, IconButton, SkeletonText, Spacer, Text, VStack,
} from '@chakra-ui/react';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { nanoid } from '@reduxjs/toolkit';
import React, { useMemo, useState } from 'react';
import Card from '~/components/Card';
import CustomerContactEditModal from '~/components/CustomerContactEditModal';
import CustomerEditModal from '~/components/CustomerEditModal';
import FusedSearch from '~/components/FusedSearch';
import StatusAlert from '~/components/StatusAlert';
import usePaging from '~/hooks/usePaging';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import PageHeader from '~/layouts/PageHeader';
import PrimarySecondaryColumns from '~/layouts/PrimarySecondaryColumns';
import { CustomerContactActions } from '~/redux/customerContacts/actions';
import { selectAllCustomerContacts } from '~/redux/customerContacts/selectors';
import { CustomerActions } from '~/redux/customers/actions';
import { selectCustomers } from '~/redux/customers/selectors';
import { CustomerWithContact } from '~/types/customer';
import { CustomerContact } from '~/types/customerContact';
import CustomerList from './CustomerList';
import ExportCustomersMenu from './ExportCustomersMenu';

const fuseSettings = {
  includeScore: true,
  ignoreLocation: true,
  threshold: 0.3,
  keys: [
    { name: 'companyName', weight: 1 },
    { name: 'mainContact.phone', weight: 1 },
    { name: 'mainContact.email', weight: 1 },
    { name: 'mainContact.name', weight: 1 },
    { name: 'companyAddress.line1', weight: 1 },
    { name: 'companyAddress.line2', weight: 1 },
    { name: 'companyAddress.city', weight: 1 },
    { name: 'companyAddress.postCode', weight: 1 },
    { name: 'companyAddress.country', weight: 1 },
  ],
};

const CustomersPage = () => {
  const {
    data: customers, isLoading: isLoadingCustomers,
    isError, errorMessage,
  } = useTrackedFetch({
    trigger: () => CustomerActions.fetchAll(),
    selector: selectCustomers,
    key: 'customers',
  });
  const {
    data: customerContacts, isLoading: isLoadingCustomerContacts,
  } = useTrackedFetch({
    trigger: () => CustomerContactActions.fetchAll(),
    selector: selectAllCustomerContacts,
    key: 'contacts',
  });
  const isLoading = isLoadingCustomers || isLoadingCustomerContacts;

  const extendedCustomers = useMemo(() => (customers ?? []).map((c) => ({
    ...c,
    mainContact: customerContacts.find((cc) => cc.id === c.mainContactId),
  })), [customers, customerContacts]);

  const [selectedCustomer, setSelectedCustomer] = useState<CustomerWithContact>(null);
  const [selectedContact, setSelectedContact] = useState<CustomerContact>(null);

  const addCustomer = () => {
    const customerId = nanoid();
    const mainContactId = nanoid();
    setSelectedCustomer({
      id: customerId,
      companyName: '',
      mainContactId,
      mainContact: {
        id: mainContactId,
        customerId,
        name: '',
        email: '',
        phone: '',
      },
    });
  };

  const addContact = (customerId: string) => {
    setSelectedContact({
      id: nanoid(),
      customerId,
      name: '',
    });
  };

  const [filteredCustomers, setFilteredCustomers] = useState(extendedCustomers);

  const {
    items: pagedCustomers,
    nextPage,
    nextDisabled,
    prevPage,
    prevDisabled,
    currentPageCount,
    totalPageCount,
  } = usePaging({
    items: filteredCustomers,
    pageSize: 50,
  });

  return (
    <>
      <PrimarySecondaryColumns>
        <PageHeader>
          <Flex justifyContent="space-between">
            <Heading size="lg">
              Customers
            </Heading>
          </Flex>
        </PageHeader>
        <GridItem gridArea="primary-start / primary-start / secondary-end / secondary-end">
          <Flex direction="column">
            <Flex>
              <Spacer />
              <ExportCustomersMenu />
              <Button mb="3" onClick={addCustomer}>
                Add Customer
              </Button>
            </Flex>
            <Card>
              <VStack align="stretch" spacing={8}>
                <HStack align="stretch" spacing={4}>
                  <Flex flex={1}>
                    <FusedSearch
                      items={extendedCustomers}
                      onChange={(c) => setFilteredCustomers(c)}
                      fuseSettings={fuseSettings}
                    />
                  </Flex>
                  <Spacer flex={1} />
                  <HStack spacing={1} color="magnetize.text-4">
                    <Text>page</Text>
                    <Text fontWeight="semibold">{currentPageCount}</Text>
                    <Text>of</Text>
                    <Text fontWeight="semibold">{totalPageCount}</Text>
                  </HStack>
                  <ButtonGroup spacing={0}>
                    <IconButton
                      onClick={prevPage}
                      isDisabled={prevDisabled}
                      aria-label="Previous page"
                      variant="ghost"
                      icon={<Icon as={ChevronLeft} />}
                    />
                    <IconButton
                      onClick={nextPage}
                      isDisabled={nextDisabled}
                      aria-label="Next page"
                      variant="ghost"
                      icon={<Icon as={ChevronRight} />}
                    />
                  </ButtonGroup>
                </HStack>
                <SkeletonText isLoaded={!isLoading} noOfLines={3}>
                  {!isError && !isLoading && pagedCustomers?.length > 0 && (
                    <CustomerList
                      customers={pagedCustomers}
                      setSelected={setSelectedCustomer}
                      addContact={({ id }) => {
                        addContact(id);
                      }}
                    />
                  )}
                  {!isError && !isLoading && customers?.length === 0 && (
                  <Text mt={4}>
                    There are no customers to display.
                  </Text>
                  )}
                  {isError && (
                  <StatusAlert title={errorMessage} />
                  )}
                </SkeletonText>
              </VStack>
            </Card>
          </Flex>
        </GridItem>
      </PrimarySecondaryColumns>
      {selectedCustomer !== null
      && (
      <CustomerEditModal
        customer={selectedCustomer}
        isNewCustomer={!selectedCustomer.companyName}
        onClose={() => {
          setSelectedCustomer(null);
        }}
      />
      )}
      {selectedContact !== null
      && (
      <CustomerContactEditModal
        customerContact={selectedContact}
        isNew={!selectedContact.name}
        onClose={() => {
          setSelectedContact(null);
        }}
      />
      )}
    </>
  );
};

export default CustomersPage;
