import {
  Box, Icon,
} from '@chakra-ui/react';
import React from 'react';
import { IconType } from 'react-icons';
import { matchPath } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import CollapsibleText from '~/components/SideNav/CollapsibleText';

export interface NavItemProps {
  title: string;
  path: string;
  icon: IconType;
  isOpen: boolean;
  isDisabled?: boolean;
}

const NavItem = (
  {
    title, path, icon, isOpen, isDisabled,
  }
  : NavItemProps,
) => {
  const location = useLocation();
  const selected = path && matchPath(location.pathname, { path });
  return (
    <Box
      key={title}
      position="relative"
      fontWeight={400}
      sx={isDisabled ? {
        opacity: 0.5,
        pointerEvents: 'none',
        cursor: 'default',
      } : {}}
    >
      <Box zIndex={1} position="relative">
        <Link to={path ?? '#'} tabIndex={isDisabled ? -1 : undefined}>
          <Box
            alignItems="center"
            display="flex"
            paddingLeft={isOpen ? '50px' : 8}
            transition="padding 0.5s"
            _hover={{ backgroundColor: 'brand.300' }}
            h="35px"
          >
            <>
              <Icon color="white" fontSize={18} as={icon} />
              <CollapsibleText
                isOpen={isOpen}
                ml={3}
                textTransform="uppercase"
                letterSpacing="0.07rem"
                fontSize="1.15rem"
                lineHeight={1.725}
              >
                {title}
              </CollapsibleText>
            </>
          </Box>
        </Link>
      </Box>
      {selected && (
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        bg="brand.300"
        opacity={0.35}
      />
      )}
    </Box>
  );
};

export default NavItem;
