import { createAction } from '@reduxjs/toolkit';
import createTrackedAction from '~/redux/tracked/createTrackedAction';
import { Address } from '~/types/address';
import {
  InvoiceAccounts,
  XeroAccount, XeroContact, XeroInvoice, XeroStatus,
} from '~/types/xero';

export const XeroActions = {
  connect: createTrackedAction<{
    callbackUrl: string,
  }>('xero/connect'),

  disconnect: createTrackedAction('xero/disconnect'),

  refreshStatus: createAction('xero/refresh-status'),
  statusUpdated: createAction<{ status: XeroStatus }>('xero/status-updated'),

  fetchAccounts: createAction('xero/fetchAccounts'),
  accountsUpdated: createAction<{ accounts: XeroAccount[] }>('xero/accountsUpdated'),

  fetchContacts: createAction('xero/fetchContacts'),
  contactsUpdated: createAction<{ contacts: XeroContact[] }>('xero/contactsUpdated'),

  invoicesUpdated: createAction<{ invoices: XeroInvoice[] }>('xero/invoicesUpdated'),

  createInvoice: createTrackedAction<{
    jobId: string,
    invoiceId: string,
    xeroContactId: string,
  }>('xero/createInvoice'),

  setOrganisation: createTrackedAction<{ xeroTenantId: string }>('xero/changeXeroOrg'),
  setInvoiceAccounts: createTrackedAction<InvoiceAccounts>('xero/changeInvoiceAccount'),

  finishedConnecting: createAction('xero/finishedConnecting'),

  createContact: createTrackedAction<{
    name: string,
    emailAddress: string,
    phoneNumber: string,
    address: Address
  }>('xero/createContact'),
};
