import { Flex } from '@chakra-ui/react';
import { startOfDay } from 'date-fns';
import React, { useMemo } from 'react';
import { CalendarContextProvider } from '~/components/Calendar/CalendarContext';
import CalendarNav from '~/components/Calendar/CalendarNav';
import { getCalendarDateRange } from '~/components/Calendar/getDateRange';
import { JobEventViewModel } from '~/components/JobCalendar/JobEventViewModel';
import MonthCalendar from '~/components/JobCalendar/MonthCalendar';
import JobCalendarActions from '~/redux/jobCalendar/jobCalendar';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import { getIsoDate } from '~/utils/calendarHelpers';

interface JobScheduleCalendarProps {
  jobEvents: JobEventViewModel[]
}

const JobScheduleCalendar = ({ jobEvents } : JobScheduleCalendarProps) => {
  const focusedDay = useAppSelector((s) => (
    s.jobCalendar.focusedDay ?? getIsoDate(startOfDay(Date.now()))
  ));

  const dateRange = useMemo(
    () => getCalendarDateRange(focusedDay, 'month'),
    [focusedDay, 'month'],
  );
  const dispatch = useAppDispatch();
  return (
    <CalendarContextProvider
      start={dateRange.start}
      end={dateRange.end}
      calendarElementRef={null}
    >
      <Flex alignItems="center">
        <CalendarNav
          headingTextAlign="left"
          dateRangeModes={[]}
          focusedDay={focusedDay}
          mode="month"
          onFocusedDayChange={(d) => dispatch(JobCalendarActions.move({ focusedDate: d }))}
          onModeChange={(m) => dispatch(JobCalendarActions.setRangeMode({ rangeMode: m }))}
        />
      </Flex>
      <MonthCalendar
        hideTotals
        jobEvents={jobEvents}
        adminEvents={[]}
        focusedDay={focusedDay}
      />
    </CalendarContextProvider>
  );
};

export default JobScheduleCalendar;
