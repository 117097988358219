import {
  Center,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { createJob } from '~/api/jobApi';
import NotFound from '~/components/NotFound';
import SkeletonJobPage from '~/components/SkeletonJobPage';
import Routes from '~/pages/routes';
import { Job } from '~/types/job';

const JobPage = () => {
  const history = useHistory();
  const [createdJob, setCreatedJob] = useState<Job>();

  const { status, mutate, error } = useMutation(() => createJob(), {
    onSuccess: async (job: Job) => {
      setCreatedJob(job);
    },
  });

  useEffect(() => mutate(), []);
  useEffect(() => {
    if (createdJob) {
      history.replace(Routes.job({ jobId: createdJob.id }));
    }
  }, [createdJob]);

  if (status === 'loading') {
    return <SkeletonJobPage />;
  }

  return (
    <Center height="50vh">
      {status === 'error' && (
        <NotFound backTo={Routes.jobList({})} title="Error creating job">
          {String(error)}
        </NotFound>
      )}
    </Center>
  );
};

export default JobPage;
