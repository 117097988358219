import {
  Box,
  Button,
  Flex, FormControl, FormLabel, Heading, Image, Input, Select, Text, VStack,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import NextButton from '~/pages/WelcomeModal/components/NextButton';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import WelcomeModalActions from '~/redux/welcomeModal/actions';
import { selectWelcomeModalState } from '~/redux/welcomeModal/selectors';

const TeamAndJobs = ({ totalSteps, stepNumber }: { totalSteps: number, stepNumber: number }) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const welcomeState = useAppSelector((state) => selectWelcomeModalState(state));
  const isDrilling = welcomeState.industry === 'Drilling & Geotechnical';

  return (
    <Flex
      h="100%"
      flex={1}
    >
      <Flex
        direction="column"
        flex={1}
      >
        <VStack
          p={20}
          alignItems="start"
          spacing={8}
          flex={1}
        >
          <Flex display="column">
            <Heading textTransform="none">Magnetize will get your team humming</Heading>
            <Heading
              pl={1}
              pt={2}
              textTransform="none"
              size="sm"
            >
              Tell us about your team.
            </Heading>
          </Flex>
          <FormControl>
            <FormLabel>
              How many staff?
            </FormLabel>
            <Select
              placeholder="Select from the dropdown"
              value={welcomeState.teamSize}
              onChange={(e) => {
                dispatch(WelcomeModalActions.update({
                  update: {
                    teamSize: e.target.value,
                  },
                }));
              }}
            >
              <option value="1 - 5">1 - 5</option>
              <option value="5 - 15">5 - 15</option>
              <option value="20 - 50">20 - 50</option>
              <option value="50 - 100">50 - 100</option>
              <option value="100+">100+</option>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>
              Recent jobs
            </FormLabel>
            <VStack alignItems="start">
              <Text>What are three examples of recent jobs by your team?</Text>
              <Input
                id="example-job1"
                placeholder={isDrilling ? 'e.g. Bore hole' : 'e.g. Brew a keg'}
                value={welcomeState.exampleJobs[0]}
                onChange={(e) => {
                  const exampleJobs = [
                    e.target.value,
                    welcomeState.exampleJobs[1],
                    welcomeState.exampleJobs[2],
                  ];
                  dispatch(WelcomeModalActions.update({
                    update: {
                      exampleJobs,
                    },
                  }));
                }}
              />
              <Input
                id="example-job2"
                placeholder={isDrilling ? 'e.g. CPT Test' : 'e.g. Build a man cave'}
                value={welcomeState.exampleJobs[1]}
                onChange={(e) => {
                  const exampleJobs = [
                    welcomeState.exampleJobs[0],
                    e.target.value,
                    welcomeState.exampleJobs[2],
                  ];
                  dispatch(WelcomeModalActions.update({
                    update: {
                      exampleJobs,
                    },
                  }));
                }}
              />
              <Input
                id="example-job3"
                placeholder={isDrilling ? 'e.g. Soak hole' : 'e.g. Mow lawns'}
                value={welcomeState.exampleJobs[2]}
                onChange={(e) => {
                  const exampleJobs = [
                    welcomeState.exampleJobs[0],
                    welcomeState.exampleJobs[1],
                    e.target.value,
                  ];
                  dispatch(WelcomeModalActions.update({
                    update: {
                      exampleJobs,
                    },
                  }));
                }}
              />
            </VStack>
          </FormControl>
          <Box flex={1} />
          <FormControl>
            <NextButton />
          </FormControl>
          <Text color="magnetize.text-4" mt={8} width="100%" textAlign="center">
            {`step ${stepNumber} of ${totalSteps}`}
          </Text>
        </VStack>
      </Flex>
      <Flex
        flex={1}
        backgroundImage="url('/images/login-bg.jpg')"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        alignItems="flex-end"
        justifyContent="center"
      >
        <Button
          position="absolute"
          top="20px"
          right="20px"
          isLoading={loading}
          isDisabled={loading}
          onClick={() => {
            setLoading(true);
            dispatch(WelcomeModalActions.complete());
          }}
        >
          Skip all
        </Button>
        <Image
          src="/images/welcomeModal/jobs.png"
        />
      </Flex>
    </Flex>
  );
};

export default TeamAndJobs;
