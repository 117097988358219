import { Icon, Text } from '@chakra-ui/react';
import { isNumber } from 'lodash';
import React from 'react';
import { FaBalanceScale } from 'react-icons/fa';
import { CountSectionAccordionItem } from '~/components/CountSectionAccordionItem';
import LogRow from '~/pages/LiveGeo/components/LogRow';
import { CPT } from '~/types/geotechnical';

interface CPTZeroingFiguresRowProps {
  cpt: CPT;
}

const CPTZeroingFiguresRow = ({
  cpt,
} : CPTZeroingFiguresRowProps) => {
  const hasZeroingFigures = isNumber(cpt.qcStart);

  return (
    <CountSectionAccordionItem
      title="CPT zeroing figures (start and finish)"
      icon={<Icon boxSize={8} pr={1} as={FaBalanceScale} />}
    >
      {hasZeroingFigures && ([
        <LogRow key="qc">
          <Text fontWeight="semibold">
            qc
          </Text>
          <Text>{cpt.qcStart}</Text>
          <Text>
            {cpt.qcFinish}
          </Text>
        </LogRow>,
        <LogRow key="fs">
          <Text fontWeight="semibold">
            fs
          </Text>
          <Text>{cpt.fsStart}</Text>
          <Text>
            {cpt.fsFinish}
          </Text>
        </LogRow>,
        <LogRow key="u2">
          <Text fontWeight="semibold">
            u2
          </Text>
          <Text>{cpt.u2Start}</Text>
          <Text>
            {cpt.u2Finish}
          </Text>
        </LogRow>,
      ])}
    </CountSectionAccordionItem>
  );
};

export default CPTZeroingFiguresRow;
