import { Button, ButtonGroup, useControllableState } from '@chakra-ui/react';
import React from 'react';
import { makeTransparent } from '~/theme';
import { ScheduleEvent } from '~/types/ScheduleEvent';

type ScheduleEventType = ScheduleEvent['type'];

interface EventTypeToggleProps {
  type?: ScheduleEventType;
  onChange?: (type: ScheduleEventType) => void;
}

const SCHEDULE_TYPES: [ScheduleEventType, string][] = [
  ['job', 'Job'],
  ['staff-leave', 'Leave'],
  ['equipment-status', 'Equipment out'],
];

const EventTypeToggle = ({ type: typeProp, onChange }: EventTypeToggleProps) => {
  const [selected, setSelected] = useControllableState({
    value: typeProp,
    onChange,
    defaultValue: 'job',
  });

  return (
    <ButtonGroup variant="ghost" colorScheme="green">
      {SCHEDULE_TYPES.map(([type, name]) => {
        const isActive = type === selected;
        return (
          <Button
            key={type}
            fontWeight="normal"
            textDecoration="none"
            textTransform="lowercase"
            onClick={() => setSelected(type)}
            onMouseDown={(e) => e.preventDefault()}
            sx={{
              color: 'magnetize.text-3',
              _hover: {
                bg: makeTransparent('#359B4B', 0.05),
              },
              _active: {
                bg: makeTransparent('#359B4B', 0.15),
              },
              ...(isActive ? {
                fontWeight: 'semibold',
                color: 'magnetize.brand-4',
                bg: makeTransparent('#359B4B', 0.1),
                _hover: {
                  bg: makeTransparent('#359B4B', 0.1),
                },
              } : {}),
            }}
          >
            {name}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

export default EventTypeToggle;
