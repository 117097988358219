import { createReducer } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';
import { CustomerActions } from '~/redux/customers/actions';
import { Customer } from '~/types/customer';
import { Dictionary } from '~/types/helpers';

interface CustomerState {
  byId: Dictionary<Customer>;
}

const customerReducer = createReducer<CustomerState>({
  byId: {},
}, (builder) => {
  builder.addCase(CustomerActions.updated, (state, action) => ({
    ...state,
    byId: {
      ...state.byId,
      ...keyBy(action.payload.customers, (c) => c.id),
    },
  }));
});

export default customerReducer;
