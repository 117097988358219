import { useLoadScript } from '@react-google-maps/api';

import { googleMaps } from '~/helpers/config';

/**
 * A hook to ensure that Google maps is loaded.
 *
 * This lib uses the @googlemaps/js-api-loader behind the scenes, but allows
 * components to track whether the API has been loaded already, avoiding errors
 * from concurrent loads etc.
 */
function useGoogleMapsApi() {
  return useLoadScript(googleMaps);
}

export default useGoogleMapsApi;
