import { Auth } from '@aws-amplify/auth';
import jwt_decode from 'jwt-decode';
import { capitalize, toLower, replace } from 'lodash';
import { showToast } from '~/toast';
import { CognitoUser } from '~/types/cognitoUser';
import fetchJson from '~/utils/fetchJson';

const config = {
  mandatorySignIn: true,
  region: 'ap-southeast-2',
  userPoolId: 'ap-southeast-2_gvlo11Ixq',
  userPoolWebClientId: '1lmd4d2v9fg17sr5kqmpo35c19',
  oauth: {
    domain: 'local-auth.magnetize.companyx.nz',
    scope: ['email', 'profile'],
    redirectSignIn: window.location.href,
    redirectSignOut: `https://${window.location.host}`,
    responseType: 'code',
  },
  cookieStorage: {
    domain: window.location.hostname,
    sameSite: 'strict',
    secure: true,
    path: '/',
    expires: 365,
  },
};

const activeEnvironment = process.env.REACT_APP_FORCE_ENVIRONMENT || window.location.hostname;

switch (activeEnvironment) {
  case 'localhost':
    config.oauth.redirectSignOut = 'http://localhost:3000';
    config.cookieStorage.secure = false;
    break;
  case 'local.magnetize.companyx.nz':
    break;
  case 'development.magnetize.companyx.nz':
    config.userPoolId = 'ap-southeast-2_LWg0kqAzX';
    config.userPoolWebClientId = '1d0t6qb89o8pukpk9v8hj4l933';
    config.oauth.domain = 'development-auth.magnetize.companyx.nz';
    break;
  case 'luke.magnetize.companyx.nz':
    config.userPoolId = 'ap-southeast-2_aZubQ7oD0';
    config.userPoolWebClientId = '25q3bnaom7uqbmseo0h1fqnueu';
    config.oauth.domain = 'luke-auth.magnetize.companyx.nz';
    break;
  case 'staging.magnetize.companyx.nz':
    config.userPoolId = 'ap-southeast-2_3nw9ZSzj1';
    config.userPoolWebClientId = '7p6nng3j7v8ooqi51jo0i329gl';
    config.oauth.domain = 'staging-auth.magnetize.companyx.nz';
    break;
  case 'app.magnetize.co.nz':
    config.userPoolId = 'ap-southeast-2_fD0EtzsZD';
    config.userPoolWebClientId = '5g5hbrntgftr0av632vf3hga8e';
    config.oauth.domain = 'auth.magnetize.co.nz';
    break;
  default:
    throw new Error(`Cannot initialize Amplify: unknown environment '${activeEnvironment}`);
}

Auth.configure(config);

const getUserWithDecodedToken = (cognitoUser) : CognitoUser => {
  const token = cognitoUser.signInUserSession.idToken.jwtToken;
  const decodedToken = jwt_decode(token);

  return {
    username: cognitoUser.username,
    token,
    groups: decodedToken['cognito:groups'] || [],
  };
};

export const getCurrentUser = async () : Promise<CognitoUser | null> => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    return getUserWithDecodedToken(cognitoUser);
  } catch (ex) {
    return null;
  }
};

export const signIn = async (username, password) : Promise<CognitoUser> => {
  const cognitoUser = await Auth.signIn(toLower(username), password);
  if (cognitoUser.challengeName) {
    throw new Error(capitalize(replace(cognitoUser.challengeName, /_/g, ' ')));
  }
  return getUserWithDecodedToken(cognitoUser);
};

export const completeNewPassword = async (
  { username, temporaryPassword, newPassword },
) : Promise<null> => {
  const cognitoUser = await Auth.signIn(toLower(username), temporaryPassword);
  await Auth.completeNewPassword(cognitoUser, newPassword);
  return null;
};

export const forgotPassword = async (
  username : string,
) => Auth.forgotPassword(toLower(username));

export const forgotPasswordSubmit = async ({
  username, temporaryPassword, newPassword,
}) : Promise<any> => Auth.forgotPasswordSubmit(toLower(username), temporaryPassword, newPassword);

export const signOut = async () => {
  try {
    await Auth.signOut();
  } catch (error) {
    showToast({
      title: 'Sign out error',
      description: String(error),
      status: 'error',
    });
  }
};

export const register = async ({
  name, email, phone, countryCode, token,
}: { name: string, email: string, phone: string, countryCode: string, token:string }) => fetchJson('/api-register/create-trial-account', {
  method: 'POST',
  body: {
    name,
    email: toLower(email),
    phone,
    countryCode,
    token,
  },
});

export const resendWelcomeEmail = async ({ email, token } : { email : string, token:string }) => fetchJson('/api-register/resend-welcome-email', {
  method: 'POST',
  body: {
    email: toLower(email),
    token,
  },
});
