import {
  Box, Button, Flex, Icon, Spacer,
} from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import { getTime, toDate } from 'date-fns';
import React, { useState } from 'react';
import { ReactComponent as UserHelmet } from '~/assets/icon-user-helmet.svg';
import AddSchedulingNoteWithEditIcon from '~/components/AddCalendarEventPopover/AddSchedulingNote/withEditIcon';
import { AddStartTime } from '~/components/AddCalendarEventPopover/AddStartTime';
import CalendarDateIntervalEditor from '~/components/JobCalendar/CalendarDateIntervalEditor';
import ResourceList from '~/components/JobCalendar/ResourceList';
import JobContactAddressSummary from '~/components/JobContactAddressSummary';
import StatusBadge from '~/components/JobStatusBadge';
import { JobTagEditor } from '~/components/JobTagEditor';
import LinkButton from '~/components/LinkButton';
import Routes from '~/pages/routes';
import CalendarTourActions from '~/redux/calendarTour/actions';
import { selectJobById } from '~/redux/jobs/selectors';
import { ScheduleEventActions } from '~/redux/schedule/actions';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import { ResourceEventSummary } from '~/types/resource';
import { JobEvent, ScheduleEvent } from '~/types/ScheduleEvent';
import { selectCurrentUser } from '~/redux/currentUser/selectors';
import { calculateResourceEventIntervals } from '~/helpers/schedule';

interface JobEventContentProps {
  jobId: string;
  interval: Interval;
  onClose?: () => void;
  onCreateEvent: (event: ScheduleEvent) => void;
}

const JobEventPopoverContent = ({
  jobId, interval, onClose, onCreateEvent,
}: JobEventContentProps) => {
  const dispatch = useAppDispatch();
  const [resources, setResources] = useState<ResourceEventSummary[]>([]);
  const [notes, setNotes] = useState<string>();
  const [startTimeMinutes, setStartTimeMinutes] = useState<number>();
  const [eventInterval, setEventInterval] = useState(interval);
  const [jobEventId] = useState(nanoid());
  const tenant = useAppSelector((state) => selectCurrentUser(state)?.tenant);

  const addJob = () => {
    const event: JobEvent = {
      type: 'job',
      id: jobEventId,
      jobId,
      start: getTime(eventInterval.start),
      end: getTime(eventInterval.end),
      startTimeMinutes,
      notes: notes ?? null,
    };
    dispatch(ScheduleEventActions.createJobSchedule({
      event,
      resourceEvents: resources.flatMap((r) => r.resourceEvents),
    }));
    onCreateEvent(event);
    dispatch(CalendarTourActions.eventCreated({ event }));
    onClose?.();
  };

  const jobStatus = useAppSelector((state) => selectJobById(state, jobId)?.status);

  const onAddResource = (resource: ResourceEventSummary) => {
    if (!resources.find((r) => r.id === resource.id)) {
      const intervals = calculateResourceEventIntervals({
        start: new Date(eventInterval.start),
        end: new Date(eventInterval.end),
      },
      tenant);

      setResources((existingResources) => [
        ...existingResources,
        {
          ...resource,
          resourceEvents: intervals.map((i) => ({
            id: nanoid(),
            type: 'job-resource',
            jobId,
            parentId: jobEventId,
            resourceId: resource.id,
            resourceType: resource.type,
            startTimeMinutes,
            ...i,
          })),
        },
      ]);
    }
    dispatch(CalendarTourActions.resourceAssignedToEvent());
  };

  const onRemoveResource = (resource: ResourceEventSummary) => {
    setResources((existingResources) => (
      existingResources.filter((r) => r.id !== resource.id)
    ));
  };

  const onEditResource = (
    resource: ResourceEventSummary,
    intervals: { start: number, end: number }[],
  ) => {
    setResources((existingResources) => ([
      ...existingResources.filter((r) => r.id !== resource.id),
      {
        ...resource,
        resourceEvents: intervals.map((i) => ({
          id: nanoid(),
          type: 'job-resource',
          jobId,
          parentId: jobEventId,
          resourceId: resource.id,
          resourceType: resource.type,
          startTimeMinutes,
          ...i,
        })),
      },
    ]));
  };
  return (
    <>
      {jobId && (
        <Flex direction="column">
          <Box mt={-5}>
            <StatusBadge status={jobStatus} />
            <LinkButton ml={4} isGreen noUnderline to={Routes.job({ jobId })}>
              view job
            </LinkButton>
          </Box>

          <Box
            mt={8}
            mx={-8}
            bg="gray.50"
          >
            <Box px="8" py="4">
              <JobContactAddressSummary jobId={jobId} />
            </Box>
          </Box>
        </Flex>
      )}

      <CalendarDateIntervalEditor
        lockStart
        value={eventInterval}
        onChange={(i) => {
          let nextResources = resources;
          if (i.start !== eventInterval.start) {
            nextResources = nextResources.map((resource) => ({
              ...resource,
              resourceEvents: resource.resourceEvents
                .map((ev) => (
                  ev.start < getTime(i.start)
                  || ev.start === getTime(eventInterval.start)
                    ? {
                      ...ev,
                      start: getTime(i.start),
                    }
                    : ev))
                .filter((ev) => ev.end > ev.start),
            }));
          }
          if (i.end !== eventInterval.end) {
            nextResources = nextResources.map((resource) => ({
              ...resource,
              resourceEvents: resource.resourceEvents
                .map((ev) => (ev.end > getTime(i.end) || ev.end === getTime(eventInterval.end) ? {
                  ...ev,
                  end: getTime(i.end),
                } : ev))
                .filter((ev) => ev.end > ev.start),
            }));
          }
          setResources(nextResources.filter((r) => r.resourceEvents.length > 0));
          setEventInterval(i);
        }}
      />
      <AddStartTime
        startTimeMinutes={startTimeMinutes}
        onChange={(m) => setStartTimeMinutes(m)}
      />
      <JobTagEditor jobId={jobId} />

      <Flex>
        <Icon mt={2} fontSize="16px" boxSize="16px" as={UserHelmet} />
        <Box flex="1" pl="2">
          <ResourceList
            showAvailability
            minDate={toDate(eventInterval.start)}
            maxDate={toDate(eventInterval.end)}
            resources={resources}
            onAddResource={onAddResource}
            onRemoveResource={onRemoveResource}
            onEditResource={onEditResource}
          />
        </Box>
      </Flex>

      <AddSchedulingNoteWithEditIcon value={notes} onChange={setNotes} />
      <Flex>
        <Spacer />
        <Button data-calendar-tour-id="calendar-save" disabled={!jobId} onClick={addJob}>Save</Button>
      </Flex>
    </>
  );
};

export default JobEventPopoverContent;
