import {
  forwardRef, Grid, GridProps, useMergeRefs,
} from '@chakra-ui/react';
import { addDays, closestIndexTo, closestTo } from 'date-fns';
import React, {
  useCallback, useContext, useMemo, useRef,
} from 'react';
import { CalendarContext, CalendarContextValue } from '~/components/Calendar/CalendarContext';
import useMouseEvents from '~/hooks/useMouseEvents';
import { subdivideInterval } from '~/utils/calendarHelpers';

export interface CalendarGridContextValue extends CalendarContextValue {
  columnCount: number;
  getNearestColumnLine(date: Date): number;
  getDateForScreenPx(screenX: number): Date;
  gridElement(): HTMLElement;
  debugMode: boolean;
}

export const CalendarGridContext = React.createContext<CalendarGridContextValue>(undefined);

interface CalendarGridProps {
  rowGap?: GridProps['rowGap'];
  subdivisions: number;
  children?: React.ReactNode;
  debugMode?: boolean;
  onClick?: (event: React.MouseEvent, date: Date) => void;
  onMouseMove?: (event: React.MouseEvent, date: Date) => void;
}

function makeGridContext(
  calendarContext: CalendarContextValue,
  subdivisions: number,
  gridElementRef: React.MutableRefObject<HTMLElement>,
  debugMode: boolean,
): CalendarGridContextValue {
  const { days } = calendarContext;
  const columnCount = days.length * subdivisions;
  const columnDates = [
    ...days.flatMap((day) => (subdivideInterval(day, subdivisions).map((x) => (x)))),
    addDays(days[days.length - 1].start, 1),
  ];

  const getNearestColumnLine = (date: Date): number => closestIndexTo(date, columnDates) + 1;

  const getDateForScreenPx = (clientX: number): Date | undefined => {
    const dateAtPx = calendarContext.getDateFromPx(clientX);
    return closestTo(dateAtPx, columnDates);
  };

  return {
    ...calendarContext,
    columnCount,
    getNearestColumnLine,
    getDateForScreenPx,
    gridElement: () => gridElementRef?.current,
    debugMode,
  };
}

const CalendarGrid = forwardRef<CalendarGridProps, typeof Grid>(({
  subdivisions = 1, debugMode = false,
  onClick, onMouseMove,
  ...rest
}: CalendarGridProps,
ref) => {
  const calendarContext = useContext(CalendarContext);
  const gridElementRef = useRef<HTMLElement>();
  const mergedRef = useMergeRefs(gridElementRef, ref);
  const gridContext = useMemo(
    () => makeGridContext(calendarContext, subdivisions, gridElementRef, debugMode),
    [calendarContext, subdivisions],
  );

  const handleClick = useCallback((e: React.MouseEvent) => {
    const date = gridContext.getDateFromPx(e.clientX);
    onClick?.(e, date);
  }, [onClick, gridContext]);

  const handleMouseMove = useCallback((e: React.MouseEvent) => {
    const date = gridContext.getDateFromPx(e.clientX);
    onMouseMove?.(e, date);
  }, [onMouseMove, gridContext]);

  const mouseEvents = useMouseEvents({
    onClick: handleClick,
    onMouseMove: handleMouseMove,
  });

  return (
    <CalendarGridContext.Provider value={gridContext}>
      <Grid
        {...rest}
        ref={mergedRef}
        gridTemplateColumns={`repeat(${gridContext.columnCount}, 1fr)`}
        {...mouseEvents}
      />
    </CalendarGridContext.Provider>
  );
});

export default CalendarGrid;
