import {
  Box,
  Heading, MenuItem, Skeleton, Table, Tag, Tbody, Td, Text, Th, Thead, Tr,
} from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import React, { useState } from 'react';
import LinkButton from '~/components/LinkButton';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import TaxRateEditModal from './TaxRateEditModal';
import { TaxRate } from '~/types/taxRate';
import TaxRateActions from '~/redux/taxRates/actions';
import { selectAllTaxRates } from '~/redux/taxRates/selectors';
import { KebabOptionsMenu } from '~/components/KebabOptionsMenu';
import eventStopper from '~/utils/eventStopper';
import ConfirmationDialog from '~/components/ConfirmationDialog';
import { useModalManager } from '~/components/ModalManager';
import { useAppDispatch } from '~/redux/store';

const TaxRates = () => {
  const dispatch = useAppDispatch();
  const modalManager = useModalManager();
  const { data: taxRates, isLoading } = useTrackedFetch<TaxRate[]>({
    key: 'taxRates',
    selector: (state) => selectAllTaxRates(state),
    trigger: () => TaxRateActions.fetch(),
    equalityMode: 'deep',
  });

  const [selectedTaxRate, setSelectedTaxRate] = useState<TaxRate>(null);
  const [taxRateModalTitle, setTaxRateModalTitle] = useState<string>();

  const addTaxRate = () => {
    setSelectedTaxRate({
      id: nanoid(),
      name: '',
      description: '',
      rate: 0,
      isAvailable: true,
      isDefault: false,
    });
    setTaxRateModalTitle('Add rate');
  };

  const editTaxRate = (taxRate: TaxRate) => {
    setSelectedTaxRate(taxRate);
    setTaxRateModalTitle('Add new tax rate');
  };

  const deleteTaxRate = (taxRateId) => {
    dispatch(TaxRateActions.delete({
      taxRateId,
    }));
  };

  const setDefault = (taxRateId) => {
    dispatch(TaxRateActions.setDefault({
      taxRateId,
    }));
  };

  return (
    <Box>
      <Heading mt={6} pb={6} mb={6} size="sm" borderBottomColor="magnetize.text-5" borderBottomWidth={2}>Tax Rates</Heading>
      <Table size="md">
        <Thead>
          <Tr>
            <Th width={100}>Name</Th>
            <Th width={100}>Rate</Th>
            <Th>Description</Th>
            <Th width={100}>Quotes</Th>
            <Th width={95} />
            <Th width="50px" />
          </Tr>
        </Thead>
        <Tbody>
          {isLoading && (
            <Tr>
              <Td><Skeleton height={8} /></Td>
              <Td><Skeleton height={8} /></Td>
              <Td><Skeleton height={8} /></Td>
              <Td><Skeleton height={8} /></Td>
              <Td><Skeleton height={8} /></Td>
            </Tr>
          )}
          {taxRates.map((tr) => (
            <Tr
              key={tr.id}
              _hover={{ bg: '#fafafa', cursor: 'pointer' }}
              transition="color 0.1s ease-out"
              role="group"
              onClick={() => editTaxRate(tr)}
            >
              <Td>
                <Text>
                  {tr.name}
                </Text>
              </Td>
              <Td>
                <Text>
                  {`${tr.rate}%`}
                </Text>
              </Td>
              <Td>
                <Text textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                  {tr.description}
                </Text>
              </Td>
              <Td>
                {tr.isAvailable
                && (
                <Tag borderRadius="full" colorScheme="green">
                  <Text fontWeight="bold" color="black" whiteSpace="nowrap">
                    &bull;
                    {' Available'}
                  </Text>
                </Tag>
                )}
              </Td>
              <Td>
                {tr.isDefault
                && (
                  <Text color="magnetize.text-4" whiteSpace="nowrap">
                    {' default'}
                  </Text>
                )}
              </Td>

              <Td textAlign="end" {...eventStopper()}>
                <KebabOptionsMenu>
                  <MenuItem onClick={() => editTaxRate(tr)}>Edit</MenuItem>
                  <MenuItem
                    title={tr.isDefault ? 'Already default' : undefined}
                    isDisabled={tr.isDefault}
                    onClick={() => setDefault(tr.id)}
                  >
                    Set Default
                  </MenuItem>
                  <MenuItem
                    title={tr.isDefault ? 'Cant delete default rate, set another as default first!' : undefined}
                    isDisabled={tr.isDefault}
                    onClick={() => {
                      modalManager.open(ConfirmationDialog, {
                        title: 'Delete tax rate',
                        children: 'This will remove the tax rate from Magnetize, but existing quotes and invoices will be unaffected',
                        onConfirm: () => deleteTaxRate(tr.id),
                        confirmButtonLabel: 'Delete',
                      });
                    }}
                  >
                    Delete
                  </MenuItem>
                </KebabOptionsMenu>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <LinkButton mt={6} isGreen noUnderline onClick={addTaxRate}>+ add tax rate</LinkButton>
      {!!selectedTaxRate
      && (
      <TaxRateEditModal
        title={taxRateModalTitle}
        onClose={() => {
          setSelectedTaxRate(null);
        }}
        taxRate={selectedTaxRate}
      />
      )}
    </Box>
  );
};

export default TaxRates;
