import { format } from 'date-fns';

export const makeRangeTime = (from, to) => {
  const fromDay = format(from, 'd');
  const toDay = format(to, 'd');
  const fromMonth = format(from, 'MMM');
  const toMonth = format(to, 'MMM');
  const fromYear = format(from, 'yyyy');
  const toYear = format(to, 'yyyy');

  const toString = `${toDay} ${toMonth} ${toYear}`;
  if (fromDay === toDay && fromMonth === toMonth && fromYear === toYear) {
    return toString;
  }

  return `${fromDay} ${(fromMonth !== toMonth || fromYear !== toYear) ? ` ${fromMonth}` : ''}${fromYear !== toYear ? ` ${fromYear}` : ''} - ${toString}`;
};
