import {
  Text, TextProps,
} from '@chakra-ui/react';
import React from 'react';
import { Address as AddressType } from '~/types/address';

const Address = ({ value, ...textProps }: { value: AddressType | undefined } & TextProps) => {
  const lines = [
    value?.line1,
    value?.line2,
    `${value?.city || ''} ${value?.postCode || ''}`.trim(),
    value?.country,
  ].filter((x) => x);

  return (
    <Text {...textProps}>
      {lines.map((line) => (
        <Text as="span" key={line}>
          {line}
          <br />
        </Text>
      ))}
    </Text>
  );
};

export default Address;
