import React from 'react';
import {
  Box, Grid, GridItem, Heading, HStack, Text,
} from '@chakra-ui/react';
import { addMonths } from 'date-fns';
import format from 'date-fns/format';
import AddressComponent from '~/components/Address';
import LineItemTotals from '~/components/LineItemTotals';
import QuoteViewLineItemTable from '~/components/QuoteView/QuoteViewLineItemTable';
import QuoteViewTermsAndConditions from '~/components/QuoteView/QuoteViewTermsAndConditions';
import TenantAddress from '~/components/TenantAddress';
import TenantLogo from '~/components/TenantLogo';
import { Customer } from '~/types/customer';
import { Quote } from '~/types/quote';
import { Tenant } from '~/types/tenant';

const QuoteView = ({
  quote, customer, tenant, referenceNumber, showTermsAndConditions = false,
}: {
  customer?: Customer;
  quote: Quote;
  tenant: Tenant;
  showTermsAndConditions?: boolean;
  referenceNumber?: string;
}) => {
  const logo = tenant?.logo;
  const recipientName = quote.customerName || customer?.companyName;
  const recipientAddress = quote.customerAddress || customer?.companyAddress;
  const sent = quote.dateCreated ? new Date(quote.dateCreated) : quote?.sent?.at ?? new Date();

  const editableFields = quote.draft || quote;
  const expiry = editableFields.expiryDate
    ? new Date(editableFields.expiryDate)
    : addMonths(sent, 3);

  return (
    <>
      <HStack justifyContent="space-between" alignItems="flex-start" mb={8}>
        <Heading size="lg" flex={2}>
          {editableFields.isEstimate ? 'Estimate' : 'Quote'}
        </Heading>
        <Box maxH="120px" flex={1}>
          <TenantLogo logo={logo} maxH="120px" marginLeft="auto" />
        </Box>
      </HStack>

      <HStack alignItems="flex-start">
        <Box flex="7" overflow="hidden" mr={3}>
          <Text
            size="xl"
            fontWeight="bold"
          >
            {editableFields.title}
          </Text>
          <Text
            size="lg"
            fontWeight="bold"
            mb={8}
          >
            {`#QUOTE-${quote.code}`}
          </Text>
          {recipientName && (
          <Heading size="sm">{recipientName}</Heading>
          )}

          { recipientAddress && (
          <AddressComponent value={recipientAddress} />
          )}
        </Box>
        <Box flex="2" overflow="hidden" mr={3}>
          <Box mb={8}>
            <Heading size="sm">Date</Heading>
            <Text>{format(sent, 'dd/MM/yyyy')}</Text>
          </Box>
          <Box mb={8}>
            <Heading size="sm">Expiry</Heading>
            <Text>{format(expiry, 'dd/MM/yyyy')}</Text>
          </Box>
          {!!referenceNumber
          && (
            <Box mb={8}>
              <Heading size="sm">Ref #</Heading>
              <Text>{referenceNumber}</Text>
            </Box>
          )}

        </Box>
        <Box flex="3" overflow="hidden">
          <TenantAddress tenant={tenant} />
        </Box>
      </HStack>

      {editableFields.description && (
      <Box mt={16} p={8} backgroundColor="gray.50" borderColor="magnetize.text-1" borderTopWidth={1} borderBottomWidth={1}>
        <Heading size="sm">Description</Heading>
        <Text size="sm" whiteSpace="pre-line">{editableFields.description}</Text>
      </Box>
      )}

      {/* Moving this outside the grid seems to avoid some weird page-break issues in the table */}
      <Box mt={16} mb={10} fontSize="lg">
        <QuoteViewLineItemTable
          quoteLineItems={editableFields.lineItems}
          currency={quote.currency}
          boldHeaderRule
        />
      </Box>

      <Grid templateColumns="repeat(12, 1fr)" gap={6} lineHeight="short" sx={{ pageBreakInside: 'avoid' }}>
        <GridItem colStart={8} colSpan={5} pt={4}>
          <LineItemTotals
            lineItems={editableFields.lineItems}
            taxRate={editableFields.taxRate}
            taxRateName={editableFields.taxRateName}
            currency={quote.currency}
          />
        </GridItem>
      </Grid>

      {showTermsAndConditions && tenant && (
        <Box sx={{ pageBreakInside: 'avoid' }}>
          <QuoteViewTermsAndConditions
            tenant={tenant}
            termsAndConditions={quote?.termsAndConditions || tenant?.quoteTermsAndConditions}
          />
        </Box>
      )}
    </>
  );
};

export default QuoteView;
