import React, { useEffect, useState } from 'react';
import {
  NumberInput, NumberInputField, NumberInputProps,
} from '@chakra-ui/react';
import { round, isFinite } from 'lodash';
import { useCurrencyFormatter } from '~/hooks/useFormatCurrency';

interface CentsEditorInterface {
  value: number;
  onChange: (value: number) => any;
  numberInputFieldProps?: any;
}

const CentsEditor = ({
  value, onChange, numberInputFieldProps = {}, ...props
} : CentsEditorInterface & NumberInputProps) => {
  const formatCurrency = useCurrencyFormatter();

  const [priceText, setPriceText] = useState(formatCurrency(value));
  const [priceFocused, setPriceFocused] = useState(false);
  useEffect(() => {
    if (!priceFocused) {
      setPriceText(formatCurrency(value));
    }
  }, [value, priceFocused]);

  return (
    <NumberInput
      variant="ghost"
      onFocus={() => setPriceFocused(true)}
      onBlur={() => setPriceFocused(false)}
      onChange={(pt, p) => {
        // Magic to replace all but the currency symbol with the text we entered
        setPriceText(formatCurrency(0).replace(/([0.,])+/g, pt));
        // For some reason if an invalid value is entered e.g. '.' and the user tabs away
        // the value of the input will be set to - Number.MAX_SAFE_INTEGER
        if (isFinite(p) && p !== -Number.MAX_SAFE_INTEGER) {
          const rounded = round(p * 100);
          onChange(rounded);
        }
      }}
      value={priceText}
      {...props}
    >
      <NumberInputField {...numberInputFieldProps} />
    </NumberInput>
  );
};

export default CentsEditor;
