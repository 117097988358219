import { createAction } from '@reduxjs/toolkit';
import { Staff } from '~/types/staff';

const StaffActions = {
  fetch: createAction('staff/fetch'),
  fetchInviteStatus: createAction('staff/fetchInviteStatus'),
  save: createAction<{ staff: Staff }>('staff/save'),
  updated: createAction<{ staff: Staff[] }>('staff/updated'),
  deactivate: createAction<{ staffId: string, archive?: boolean; }>('staff/deactivate'),
};

export default StaffActions;
