import {
  Box,
  Flex, Icon, Menu, MenuButton, MenuItem, MenuList, Stack, Text,
} from '@chakra-ui/react';
import { ExpandMore, Lock } from '@material-ui/icons';
import { first, split } from 'lodash';
import React from 'react';
import { useHistory } from 'react-router';
import CurrentUserAvatar from '~/components/CurrentUserAvatar';
import { useCheckRole } from '~/hooks/useCheckRole';
import Routes from '~/pages/routes';
import { currentUserActions } from '~/redux/currentUser/actions';
import { selectCurrentUser } from '~/redux/currentUser/selectors';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import { CognitoGroup } from '~/types/cognitoUser';
import { magnetizeDocsUrl } from '~/helpers/config';

const SettingsMenu = () => {
  const isAdmin = useCheckRole(CognitoGroup.Admin);
  const isSuperuser = useCheckRole(CognitoGroup.Superuser);
  const history = useHistory();
  const dispatch = useAppDispatch();
  return (
    <MenuList
      borderWidth={0}
      borderTopColor="brand.400"
      borderTopWidth={5}
      pb={10}
      zIndex={100}
    >
      {isAdmin && (
      <MenuItem
        py={2}
        pl={6}
        onClick={() => {
          history.push(Routes.tenantSettings({}));
        }}
        fontWeight="bold"
      >
        Organisation Settings
      </MenuItem>
      )}
      {isSuperuser && (
        <MenuItem
          py={2}
          pl={6}
          onClick={() => {
            history.push(Routes.adminSettings({}));
          }}
          fontWeight="bold"
        >
          Admin Page
        </MenuItem>
      )}
      {isAdmin && (
      <MenuItem
        py={2}
        pl={6}
        as="a"
        target="_blank"
        href={magnetizeDocsUrl}
      >
        Help & Support
      </MenuItem>
      )}

      <MenuItem
        py={2}
        pl={6}
        onClick={() => dispatch(currentUserActions.signOut())}
      >
        <Icon as={Lock} mr={2} />
        <span>
          Log out
        </span>
      </MenuItem>
    </MenuList>
  );
};

const UserProfileAvatar = () => {
  const currentUser = useAppSelector((state) => selectCurrentUser(state));

  return (
    <Flex alignItems="center" mr={2}>
      <Menu placement="bottom-end">
        <MenuButton>
          <Box flexDirection="row" display="flex" alignItems="center">
            {currentUser.staff && (
            <Stack spacing={0} mr={2}>
              <Text lineHeight={5} fontWeight="bold" align="right">
                {currentUser.tenant?.name ?? ''}
              </Text>
              <Text lineHeight={5} align="right">
                Kia Ora
                {' '}
                {first(split(currentUser.staff?.name ?? '', ' '))}
              </Text>
            </Stack>
            )}
            <CurrentUserAvatar />
            <Icon as={ExpandMore} ml={2} />

          </Box>
        </MenuButton>
        <SettingsMenu />
      </Menu>
    </Flex>
  );
};

export default UserProfileAvatar;
