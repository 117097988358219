import {
  Box, Icon, Link,
} from '@chakra-ui/react';
import React from 'react';
import { IconType } from 'react-icons';
import { matchPath } from 'react-router';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import CollapsibleText from '~/components/SideNav/CollapsibleText';

const SecondaryNavItem = (
  {
    title, path, href, icon, isOpen, isDisabled, target,
  }
  : {
    title: string;
    path?: string;
    href?: string;
    target?: string;
    icon: IconType;
    isOpen: boolean;
    isDisabled?: boolean;
  },
) => {
  const location = useLocation();
  const selected = path && matchPath(location.pathname, { path });

  return (
    <Box
      key={title}
      position="relative"
      fontWeight={200}
      sx={isDisabled ? {
        opacity: 0.5,
        pointerEvents: 'none',
        cursor: 'default',
      } : {}}
    >
      <Box zIndex={1} position="relative">
        <Link
          as={path ? RouterLink : undefined}
          href={href}
          target={target}
          to={path}
          tabIndex={isDisabled ? -1 : undefined}
          _hover={{}}
        >
          <Box
            alignItems="center"
            display="flex"
            paddingLeft={isOpen ? '50px' : 8}
            transition="padding 0.5s"
            _hover={{ backgroundColor: 'brand.300' }}
            h="35px"
          >
            <>
              {!isOpen && <Icon color="white" opacity={0.4} fontSize={18} as={icon} />}
              <CollapsibleText
                isOpen={isOpen}
                fontSize="1.05rem"
                fontWeight={200}
              >
                {title}
              </CollapsibleText>
            </>
          </Box>
        </Link>
      </Box>
      {selected && (
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        bg="brand.300"
        opacity={0.35}
      />
      )}
    </Box>
  );
};

export default SecondaryNavItem;
