import {
  Icon, IconButton, Menu, MenuButton, MenuList, Portal, useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { FaEllipsisH } from 'react-icons/fa';

interface KebabOptionsMenuProps {
  children: React.ReactNode;
}

export const KebabOptionsMenu = ({
  children,
}: KebabOptionsMenuProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  /*
  The chakra-ui menu uses popper which is a real performance killer
  especially as we're often using this component in large tables
  if the menu isn't open just return a button to improve performance
  */
  if (!isOpen) {
    return (
      <IconButton
        icon={<Icon as={FaEllipsisH} />}
        variant="ghost"
        aria-label="Options menu"
        opacity={0}
        _groupHover={{
          opacity: 1,
        }}
        _expanded={{
          bg: 'magnetize.ui-3',
        }}
        onClick={onOpen}
      />
    );
  }

  return (
    <Menu
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      variant="magnetize"
      isLazy
    >
      <>
        <MenuButton
          as={IconButton}
          icon={<Icon as={FaEllipsisH} />}
          variant="ghost"
          colorScheme="gray"
          aria-label="Options menu"
          _expanded={{
            bg: 'magnetize.ui-3',
          }}
        />
        <Portal>
          <MenuList>
            {children}
          </MenuList>
        </Portal>
      </>
    </Menu>
  );
};
