import {
  Flex, FormLabel, IconButton, SkeletonText, Spacer, Text, useDisclosure, FormControl,
} from '@chakra-ui/react';
import { CloseOutlined, EditOutlined } from '@material-ui/icons';
import { nanoid } from '@reduxjs/toolkit';
import React, { useState } from 'react';
import Card from '~/components/Card';
import CustomerEditModal from '~/components/CustomerEditModal';
import CustomerSelect from '~/components/CustomerSelect';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import { CustomerContactActions } from '~/redux/customerContacts/actions';
import { selectCustomerContactById } from '~/redux/customerContacts/selectors';
import { CustomerActions } from '~/redux/customers/actions';
import { selectCustomerById } from '~/redux/customers/selectors';
import { updateJob } from '~/redux/jobs/actions';
import { useAppDispatch } from '~/redux/store';
import { CustomerWithContact } from '~/types/customer';
import { Job } from '~/types/job';

const JobCustomer = ({ job }: { job: Job }) => {
  const jobId = job.id;
  const dispatch = useAppDispatch();
  const selectedCustomerId = job.customerId;
  const [temporaryCustomer, setTemporaryCustomer] = useState<CustomerWithContact>(null);

  const updateJobCustomer = (customerId: string | null) => {
    let { jobContactId } = job;
    let { siteContactId } = job;
    // The job customer has changed, because contacts belong to customers
    // we need to clear the job and site contacts
    if (customerId !== selectedCustomerId) {
      jobContactId = null;
      siteContactId = null;
    }
    dispatch(updateJob({
      id: jobId, customerId, jobContactId, siteContactId,
    }));
  };

  const [isNew, setIsNew] = useState(false);

  const {
    data: customer, isLoading: isLoadingCustomer,
  } = useTrackedFetch({
    trigger: () => CustomerActions.fetch({ customerId: selectedCustomerId }),
    selector: (s) => selectCustomerById(s, selectedCustomerId),
    key: `customer-${selectedCustomerId}`,
    enabled: !!selectedCustomerId,
  });
  const {
    data: mainContact, isLoading: isLoadingContact,
  } = useTrackedFetch({
    trigger: () => CustomerContactActions.fetch({ id: customer?.mainContactId }),
    selector: (s) => selectCustomerContactById(s, customer?.mainContactId),
    key: `contact-${customer?.mainContactId}`,
    enabled: !!customer?.mainContactId,
  });

  const isLoading = isLoadingCustomer || isLoadingContact;

  const { onOpen, onClose, isOpen } = useDisclosure();

  if (isLoading) {
    return (
      <Card height={120}>
        <SkeletonText noOfLines={2} />
      </Card>
    );
  }

  const selectedCustomer = customer && mainContact ? {
    ...customer,
    mainContact,
  } : temporaryCustomer;

  return (
    <>
      <Card>
        <FormControl isRequired>
          <FormLabel>Customer</FormLabel>
          { selectedCustomer && selectedCustomer.id === selectedCustomerId
            ? (
              <Flex alignItems="center" backgroundColor="magnetize.ui-3" padding={3}>
                <Text size="lg">{customer.companyName}</Text>
                <Spacer />
                <IconButton
                  variant="link"
                  size="xs"
                  aria-label="Remove"
                  icon={<EditOutlined />}
                  onClick={() => {
                    setIsNew(false);
                    onOpen();
                  }}
                />
                <IconButton
                  variant="link"
                  size="xs"
                  aria-label="Remove"
                  icon={<CloseOutlined />}
                  onClick={() => {
                    updateJobCustomer(null);
                  }}
                />
              </Flex>
            )
            : (
              <CustomerSelect
                value={selectedCustomerId}
                onChange={(cid) => {
                  setIsNew(false);
                  updateJobCustomer(cid);
                }}
                onCreateCustomer={(name, id) => {
                  setIsNew(true);
                  const mainContactId = nanoid();
                  setTemporaryCustomer({
                    id,
                    companyName: name,
                    mainContactId,
                    mainContact: {
                      id: mainContactId,
                      customerId: id,
                      name: '',
                    },
                  });
                  onOpen();
                }}
              />
            )}
        </FormControl>
      </Card>
      {isOpen
      && (
      <CustomerEditModal
        customer={selectedCustomer}
        isNewCustomer={isNew}
        onSave={(nextCustomer) => {
          setTemporaryCustomer(nextCustomer);
          updateJobCustomer(nextCustomer.id);
        }}
        onClose={onClose}
      />
      )}
    </>
  );
};

export default JobCustomer;
