import {
  Box, Tag, Text,
} from '@chakra-ui/react';
import React from 'react';

interface SentStatusProps {
  isSent: boolean;
  sentText: string;
  draftText: string;
}

const SentStatus = ({ isSent, sentText, draftText } : SentStatusProps) => (
  <Box>
    <Tag
      py={1}
      borderRadius="full"
      fontWeight="bold"
      color={isSent ? '#FFFFFF' : '#333333'}
      backgroundColor={isSent ? 'magnetize.brand-4' : 'magnetize.ui-4'}
    >
      <Text fontSize="xl">
        &bull;
      </Text>
      <Text
        fontWeight="bold"
        mx={1}
        whiteSpace="nowrap"
      >
        {isSent ? sentText : draftText}
      </Text>
    </Tag>
  </Box>
);

export default SentStatus;
