import { Interval, isWithinInterval } from 'date-fns';
import { groupBy, mapValues, sumBy } from 'lodash';
import { selectPriceById } from '~/redux/prices/selectors';
import { selectQuotesByJobId } from '~/redux/quote/selectors';
import { RootState } from '~/redux/store';
import { SummaryConsumableRecord } from '~/types/consumable';
import { parseISODateLocal } from '~/utils/parseTime';

export const addV2ConsumableQuantity = (
  consumables: SummaryConsumableRecord[],
) => consumables.map((c): SummaryConsumableRecord => {
  if (c.entries !== undefined) {
    return ({
      ...c,
      quantity: sumBy(Object.values(c.entries), (v) => v.quantity),
    });
  }
  return c;
});

export const selectConsumablesForJob = (state: RootState, jobId: string) => {
  const indexes = state.consumables.byJob[jobId] ?? [];
  return indexes.map((x) => state.consumables.entities[x]);
};

export const selectConsumablesForJobInDateRange = (
  state: RootState,
  jobId: string,
  interval: Interval,
) => {
  const consumables = selectConsumablesForJob(state, jobId);
  return addV2ConsumableQuantity(consumables.map((c) => {
    if (c.entries) {
      const nextEntries = Object.fromEntries(
        Object.entries(c.entries)
          .filter(([,entry]) => isWithinInterval(parseISODateLocal(entry.date), interval)),
      );

      return { ...c, entries: nextEntries };
    }
    return c;
  }).filter((c) => !c.entries || Object.keys(c.entries).length > 0));
};

export const selectQuoteConsumables = (state: RootState, jobId: string) => {
  const quoteLineItems = selectQuotesByJobId(state, jobId)
    .filter((q) => q.status === 'accepted' || q.status === 'sent')
    .flatMap((q) => Object.values(q.lineItems).flatMap((li) => (li.type === 'bundle' ? Object.values(li.lineItems) : li)))
    .filter((li) => !li.priceId || selectPriceById(state, li.priceId)?.category === 'consumable');

  return mapValues(
    groupBy(quoteLineItems, (li) => li.priceId || li.name),
    (lineItems) => ({
      allocated: lineItems.reduce((sum, li) => sum + li.quantity, 0),
      priceId: lineItems[0].priceId,
      name: lineItems[0].name,
    }),
  );
};

export const selectBudgetConsumables = (state: RootState, jobId: string) => {
  const quoteLineItems = selectQuotesByJobId(state, jobId)
    .filter((q) => q.status === 'draft')
    .flatMap((q) => Object.values(q.lineItems).flatMap((li) => (li.type === 'bundle' ? Object.values(li.lineItems) : li)))
    .filter((li) => !li.priceId || selectPriceById(state, li.priceId)?.category === 'consumable');

  return mapValues(
    groupBy(quoteLineItems, (li) => li.priceId || li.name),
    (lineItems) => ({
      allocated: lineItems.reduce((sum, li) => sum + li.quantity, 0),
      priceId: lineItems[0].priceId,
      name: lineItems[0].name,
    }),
  );
};
