import {
  Box, Flex, SystemCSSProperties, usePointerEvent,
} from '@chakra-ui/react';
import {
  addDays, differenceInDays, isAfter, isEqual,
} from 'date-fns';
import React, { useContext, useRef, useState } from 'react';
import { CalendarGridContext } from '~/components/Calendar/CalendarGrid';
import CalendarGridItem, { CalendarGridItemProps } from '~/components/Calendar/CalendarGridItem';
import useDebouncedState from '~/hooks/useDebouncedState';
import useMouseEvents from '~/hooks/useMouseEvents';
import { endOfDaySeconds } from '~/utils/calendarHelpers';

interface ResizableCalendarItemProps {
  start: Date;
  end: Date;
  canEdit?: boolean;
  children?: React.ReactNode;
  onSpanChange: (span: { start: Date, end: Date }) => void;
  onClick?: () => void;
  isEditingChange?: (isEditing: boolean) => void;
}

const ResizableCalendarItem = ({
  start: startProp, end: endProp, children, canEdit = true,
  onSpanChange, onClick, isEditingChange, ...gridItemProps
}: ResizableCalendarItemProps & CalendarGridItemProps) => {
  const gridContext = useContext(CalendarGridContext);
  const [isResizing, setIsResizing] = useState(false);
  const [isMoving, setIsMoving] = useState(false);
  // the date associated with the column you started to drag in
  const [startMoveMouseDate, setStartMoveMouseDate] = useState(null);
  const gridItemRef = useRef<HTMLElement>();

  const [span, setSpan, flushPositionUpdates] = useDebouncedState({
    value: { start: startProp, end: endProp },
    dispatchUpdate: (value) => {
      if (!isEqual(startProp, span.start) || !isEqual(endProp, span.end)) {
        onSpanChange(value);
      }
    },
  });

  const startMoving = (e: React.MouseEvent) => {
    if (canEdit && !isMoving && !isResizing) {
      e.preventDefault();
      setIsMoving(true);
      isEditingChange?.(true);
      setStartMoveMouseDate(gridContext.getDateForScreenPx(e.clientX));
      setSpan({ start: startProp, end: endProp });
    }
  };

  const startResizing = (e: React.MouseEvent) => {
    if (canEdit && !isMoving && !isResizing) {
      e.preventDefault();
      setIsResizing(true);
      isEditingChange?.(true);
      setSpan({ start: startProp, end: endProp });
    }
  };

  usePointerEvent(
    null,
    'pointermove',
    (e, info) => {
      if (!canEdit) {
        return;
      }
      if (isResizing) {
        e.preventDefault();
        const newEnd = endOfDaySeconds(addDays(gridContext.getDateForScreenPx(info.point.x), -1));
        if (isAfter(newEnd, span.start)) {
          setSpan({
            start: span.start,
            end: newEnd,
          });
        }
      } else if (isMoving) {
        e.preventDefault();
        const currentMoveMouseDate = gridContext.getDateForScreenPx(info.point.x);
        if (currentMoveMouseDate.getTime() !== startMoveMouseDate.getTime()) {
          const diff = differenceInDays(currentMoveMouseDate, startMoveMouseDate);
          setSpan({
            start: addDays(span.start, diff),
            end: addDays(span.end, diff),
          });
          setStartMoveMouseDate(currentMoveMouseDate);
        }
      }
    },
  );

  // We want to track a global pointer-up event here otherwise the box can get
  // stuck in resizing mode if the mouse-up event doesn't happen on the div itself.
  usePointerEvent(
    null,
    'pointerup',
    (e) => {
      if (isResizing) {
        e.preventDefault();
        setIsResizing(false);
        isEditingChange?.(false);
        flushPositionUpdates();
      } else if (isMoving) {
        e.preventDefault();
        setIsMoving(false);
        isEditingChange?.(false);
        flushPositionUpdates();
      }
    },
  );

  const mouseEvents = useMouseEvents({
    onClick,
    onMouseDown: startMoving,
  });

  const isEditing = isMoving || isResizing;

  let moveCursor: SystemCSSProperties['cursor'] = 'default';
  if (canEdit) {
    moveCursor = isMoving ? 'grabbing' : 'grab';
  }

  return (
    <CalendarGridItem
      {...gridItemProps}
      zIndex={isEditing ? 1 : undefined}
      start={span.start}
      end={span.end}
      ref={gridItemRef}
      userSelect="none"
      cursor={!canEdit ? 'default' : undefined}
    >
      <Flex position="relative">
        <Box
          boxShadow={isEditing ? 'md' : undefined}
          flex="1 1 auto"
          minWidth={0}
          cursor={moveCursor}
          {...mouseEvents}
        >
          {children}
        </Box>
        <Box
          position="absolute"
          right="0"
          top="0"
          bottom="0"
          px={2}
          pointerEvents={!canEdit ? 'none' : undefined}
          onMouseDown={startResizing}
          opacity={isResizing ? 1 : 0}
          borderRightColor="brand.100"
          borderRightWidth={isResizing ? '2px' : '1px'}
          cursor={canEdit ? 'col-resize' : 'default'}
          _hover={{
            opacity: !isResizing ? '0.5' : undefined,
          }}
        />
      </Flex>
    </CalendarGridItem>
  );
};

export default ResizableCalendarItem;
