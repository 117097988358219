import React, {
  MutableRefObject, useState,
} from 'react';
import { Flex } from '@chakra-ui/react';
import DndKitDragItem from '~/components/DndKitDragItem';
import LineItemDescription from '~/components/LineItemsEditor/components/LineItemDescription';
import LineItemName from '~/components/LineItemsEditor/components/LineItemName';
import LineItemQuantity from '~/components/LineItemsEditor/components/LineItemQuantity';
import LineItemRemove from '~/components/LineItemsEditor/components/LineItemRemove';
import LineItemTotal from '~/components/LineItemsEditor/components/LineItemTotal';
import LineItemUnitPrice from '~/components/LineItemsEditor/components/LineItemUnitPrice';
import { LineItem } from '~/types/lineItem';

interface LineItemEditRowProps {
  lineItem: LineItem;
  onLineItemChanged?: (nextLineItem: LineItem) =>void;
  onLineItemRemoved?: (lineItemId:string) =>void;
  isNew?: boolean;
  disableModifyLineItem?: boolean;
  focusRef?: MutableRefObject<HTMLInputElement>;
}

const LineItemEditRow = React.memo(({
  lineItem,
  onLineItemChanged,
  onLineItemRemoved,
  isNew = false,
  disableModifyLineItem = false,
  focusRef = null,
} : LineItemEditRowProps) => {
  const [isDescriptionFocused, setIsDescriptionFocused] = useState(false);

  return (
    <DndKitDragItem disabled={isNew} id={lineItem.id}>
      <Flex flexDirection="column" flex={1}>
        <Flex flex={1}>
          <LineItemName
            placeholder={isNew ? 'Add custom item' : ''}
            value={lineItem.name}
            onChange={(name) => {
              onLineItemChanged({
                ...lineItem,
                type: 'custom',
                priceId: undefined,
                name,
              });
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                setIsDescriptionFocused(true);
              }
            }}
            isDisabled={disableModifyLineItem}
          />
          <LineItemUnitPrice
            hide={isNew || disableModifyLineItem}
            value={lineItem.unitPriceCents}
            onChange={(unitPriceCents) => {
              onLineItemChanged({
                ...lineItem,
                type: 'custom',
                priceId: undefined,
                unitPriceCents: Number.isFinite(unitPriceCents) ? unitPriceCents : 0,
              });
            }}
          />
          <LineItemQuantity
            hide={isNew}
            focusRef={focusRef}
            value={lineItem.quantity}
            onChange={(quantity) => {
              onLineItemChanged({
                ...lineItem,
                quantity: Number.isFinite(quantity) ? quantity : 0,
              });
            }}
          />
          <LineItemTotal hide={isNew} value={lineItem.unitPriceCents * lineItem.quantity} />
          <LineItemRemove hide={isNew} onClick={() => { onLineItemRemoved(lineItem.id); }} />
        </Flex>

        {(lineItem.description || isDescriptionFocused)
      && (
      <LineItemDescription
        value={lineItem.description}
        onChange={(description) => {
          onLineItemChanged({
            ...lineItem,
            description,
          });
        }}
        isDisabled={disableModifyLineItem}
        onFocus={() => setIsDescriptionFocused(true)}
        onBlur={() => setIsDescriptionFocused(false)}
      />
      )}
      </Flex>
    </DndKitDragItem>
  );
});

export default LineItemEditRow;
