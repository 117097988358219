import { ButtonProps, Icon } from '@chakra-ui/react';
import { ChevronRight } from '@material-ui/icons';
import React from 'react';
import GreenButton from '~/components/GreenButton';
import { useAppDispatch } from '~/redux/store';
import WelcomeModalActions from '~/redux/welcomeModal/actions';

const NextButton = ({ children, ...props } : ButtonProps) => {
  const dispatch = useAppDispatch();
  return (
    <GreenButton
      width="100%"
      size="lg"
      borderRadius="50vh"
      my={2}
      position="relative"
      onClick={() => {
        dispatch(WelcomeModalActions.incrementStep());
      }}
      {...props}
    >
      {children || (
      <>
        Next
        <Icon as={ChevronRight} position="absolute" right={10} />
      </>
      )}
    </GreenButton>
  );
};
export default NextButton;
