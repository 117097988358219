import React from 'react';

import {
  Button,
} from '@chakra-ui/react';
import LinkButton from '~/components/LinkButton';
import CenteredFlex from '~/components/CenteredFlex';

const AddIgnoreButtons = (
  {
    onAddClick,
    onIgnoreClick,
    isDisabled,
  } : {
    onAddClick: () => void;
    onIgnoreClick: () => void;
    isDisabled: boolean;
  },
) => (
  <CenteredFlex direction="column">
    <Button
      isDisabled={isDisabled}
      width="40px"
      height={5}
      onClick={onAddClick}
    >
      Add
    </Button>
    <LinkButton
      isDisabled={isDisabled}
      onClick={onIgnoreClick}
    >
      ignore
    </LinkButton>
  </CenteredFlex>
);

export default AddIgnoreButtons;
