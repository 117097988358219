import {
  Box, Stack, Table,
  Tbody, Td, Text, Th, Thead, Tr,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import React from 'react';
import { useHistory } from 'react-router';
import { JobEventViewModel } from '~/components/JobCalendar/JobEventViewModel';
import LinkButton from '~/components/LinkButton';
import Routes from '~/pages/routes';
import JobCalendarActions from '~/redux/jobCalendar/jobCalendar';
import { useAppDispatch } from '~/redux/store';
import { formatDateRange, roundIntervalToDays } from '~/utils/calendarHelpers';

interface JobScheduleTableProps {
  jobEvents: JobEventViewModel[];
  onSelectEvent: (je: JobEventViewModel) => void
}

const JobScheduleTable = ({ jobEvents, onSelectEvent } : JobScheduleTableProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const goToCalendar = (d: Date) => {
    dispatch(JobCalendarActions.setRangeMode({ rangeMode: 'week' }));
    dispatch(JobCalendarActions.move({ focusedDate: format(d, 'yyyy-MM-dd') }));
    history.push(Routes.jobCalendar({}));
  };

  return (
    <Box mx={2}>
      <Table variant="simple" fontSize="12px">
        <Thead>
          <Tr>
            <Th width="30%" pr="0">Date</Th>
            <Th width="30%" pr="0">Team</Th>
            <Th width="30%" pr="0">Equipment</Th>
            <Th pr="0" w="70px" />
          </Tr>
        </Thead>
        <Tbody>
          {jobEvents.map((jobEvent) => {
            const rounded = roundIntervalToDays({
              start: jobEvent.start,
              end: jobEvent.end,
            });

            return (
              <Tr key={jobEvent.id}>
                <Td
                  pr="0"
                  verticalAlign="top"
                >
                  <Text
                    cursor="pointer"
                    fontWeight="bold"
                    _hover={{
                      textDecoration: 'underline',
                    }}
                    onClick={() => {
                      goToCalendar(jobEvent.start);
                    }}
                  >
                    {formatDateRange(rounded)}
                  </Text>
                </Td>
                <Td
                  pr="0"
                  verticalAlign="top"
                >
                  <Stack spacing={4}>
                    {jobEvent.resources
                      .filter((r) => r.type === 'staff')
                      .map((staff) => (
                        <Text
                          key={jobEvent.id + staff.id}
                        >
                          {staff.displayName}
                        </Text>
                      ))}
                  </Stack>
                </Td>
                <Td
                  pr="0"
                  verticalAlign="top"
                >
                  <Stack spacing={4}>
                    {jobEvent.resources
                      .filter((r) => r.type === 'equipment')
                      .map((equipment) => (
                        <Text key={jobEvent.id + equipment.id}>
                          {equipment.displayName}
                        </Text>
                      ))}
                  </Stack>
                </Td>
                <Td
                  pr="0"
                  verticalAlign="top"
                  textAlign="end"
                >
                  <LinkButton
                    noUnderline
                    onClick={() => {
                      onSelectEvent(jobEvent);
                    }}
                  >
                    edit
                  </LinkButton>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
};

export default JobScheduleTable;
