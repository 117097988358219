import { createReducer } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';
import { EquipmentActions } from '~/redux/equipment/actions';
import { Equipment } from '~/types/equipment';
import { Dictionary } from '~/types/helpers';

interface EquipmentState {
  byId: Dictionary<Equipment>;
}

const equipmentReducer = createReducer<EquipmentState>({
  byId: {},
}, (builder) => {
  builder.addCase(EquipmentActions.updated, (state, action) => ({
    ...state,
    byId: {
      ...state.byId,
      ...keyBy(action.payload.equipment, (e) => e.id),
    },
  }));
});

export default equipmentReducer;
