import React, { lazy, Suspense } from 'react';
// This library depends on zxcvbn which is very large - lazy load it
const StrengthBar = lazy(() => import('react-password-strength-bar'));

const PasswordStrengthBar = ({ password, isInvalid } : {
  password : string, isInvalid: boolean }) => {
  if (!password) {
    return <></>;
  }
  return (
    <Suspense
      fallback={<></>}
    >
      <StrengthBar
        barColors={['#ddd', '#ef4836', '#f6b44d', '#359B4B', '#359B4B']}
        // If password is invalid show short score word to prompt user to enter
        // a valid password
        password={isInvalid ? '' : password}
        shortScoreWord="must be at least 6 characters long and contain a number"
        minLength={6}
        scoreWordStyle={{
          fontSize: '12px',
          textAlign: 'left',
        }}
      />
    </Suspense>
  );
};

export default PasswordStrengthBar;
