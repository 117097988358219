import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Checkbox, Flex, Heading,
  Input,
  InputGroup,
  InputLeftElement, Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SkeletonText, Spacer, Text,
} from '@chakra-ui/react';
import Fuse from 'fuse.js';
import React, {
  Fragment, useMemo, useState,
} from 'react';
import CurrencyText from '~/components/CurrencyText';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import { makeBundleLineItem, makePriceBundleViewModels } from '~/pages/PriceBook/BundleViewModel';
import PriceBundleActions from '~/redux/priceBundle/actions';
import { selectPriceBundles } from '~/redux/priceBundle/selectors';
import PriceActions from '~/redux/prices/actions';
import { selectAllPrices, selectAvailablePrices } from '~/redux/prices/selectors';
import { useAppSelector } from '~/redux/store';
import { LineItem } from '~/types/lineItem';
import { Price } from '~/types/price';
import { PriceBundle } from '~/types/priceBundle';
import { priceToLineItem } from '~/utils/lineItemHelpers';

const fuseSettings = {
  threshold: 0.3,
  keys: [
    { name: 'name', weight: 2 },
    { name: 'category', weight: 1 },
    { name: 'description', weight: 1 },
    { name: 'unit', weight: 1 },
  ],
};

const PriceBookBrowseModal = ({
  onAdd, onClose,
  titleText,
  addButtonText = 'Add to quote',
  showBundles = false,
  filterCategories = null,
  excludedPrices = [],
} : {
  onAdd: (lineItems: LineItem[]) => void;
  onClose: () => void;
  addButtonText?: string;
  titleText: string;
  showBundles?: boolean;
  filterCategories?: string[];
  excludedPrices?: string[]
}) => {
  const { data: prices, isLoading: isPricesLoading } = useTrackedFetch<Price[]>({
    key: 'prices',
    selector: (state) => selectAvailablePrices(state),
    trigger: () => PriceActions.fetch(),
    equalityMode: 'deep',
  });
  const { data: priceBundles, isLoading: isPriceBundlesLoading } = useTrackedFetch<PriceBundle[]>({
    key: 'price-bundles',
    selector: (state) => selectPriceBundles(state),
    trigger: () => PriceBundleActions.fetch(),
  });
  const allPrices = useAppSelector((s) => selectAllPrices(s));

  const isLoading = isPricesLoading || isPriceBundlesLoading;

  const [selected, setSelected] = useState([]);

  const selectedCount = selected.length;

  const [searchTerm, setSearchTerm] = useState('');

  const fusedPrices = useMemo(
    () => new Fuse(prices, fuseSettings),
    [prices, fuseSettings],
  );

  const visiblePrices = useMemo(
    () => {
      if (searchTerm.length === 0) {
        return prices
          .filter((p) => !excludedPrices.includes(p.id));
      }
      return fusedPrices
        .search(searchTerm)
        .map((s) => s.item)
        .filter((p) => !excludedPrices.includes(p.id));
    },
    [fusedPrices, searchTerm, excludedPrices],
  );

  const groupedLineItems = useMemo(
    () => {
      const priceCategories = filterCategories || ['labour', 'equipment', 'consumable', 'fee'];
      const groupedPrices = priceCategories.map((category) => ({
        category,
        lineItems: visiblePrices
          .filter((p) => p.category === category)
          .map((p) => priceToLineItem(p)),
      }));

      if (!showBundles) {
        return groupedPrices;
      }

      const bundleViewModels = makePriceBundleViewModels(priceBundles, allPrices) ?? [];
      const bundles = {
        category: 'bundles',
        lineItems: bundleViewModels.map((b) => makeBundleLineItem(b)),
      };

      return [
        bundles,
        ...groupedPrices,
      ];
    },
    [visiblePrices],
  );

  return (
    <Modal
      isOpen
      closeOnOverlayClick={false}
      onClose={onClose}
      size="2xl"
      scrollBehavior="inside"
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {titleText}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pt={0}>
          <Box
            position="sticky"
            top={0}
            zIndex={1}
            backgroundColor="white"
            pb={8}
            pt={1}
          >
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.300" />
              </InputLeftElement>
              <Input
                placeholder="Search equipment, labour and costs"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </InputGroup>
          </Box>
          <SkeletonText isLoaded={!isLoading}>
            <Flex direction="column" overflow="auto">
              { groupedLineItems.map(
                ({ category, lineItems }) => (
                  <Fragment key={category}>
                    <Flex
                      borderColor="magnetize.ui-3"
                      borderBottomWidth={1}
                      py={4}
                    >
                      <Heading size="sm">{category}</Heading>
                    </Flex>
                    { lineItems.map((lineItem) => {
                      const isSelected = selected.some((li) => (lineItem.type === 'bundle'
                        ? li.bundleId === lineItem.bundleId
                        : li.priceId === lineItem.priceId));
                      const select = (e) => {
                        if (isSelected) {
                          setSelected(selected.filter((li) => (lineItem.type === 'bundle'
                            ? li.bundleId !== lineItem.bundleId
                            : li.priceId !== lineItem.priceId)));
                        } else {
                          setSelected([...selected, lineItem]);
                        }
                        e.preventDefault();
                      };
                      const price = prices.find((p) => p.id === lineItem.priceId);

                      return (
                        <Flex
                          key={lineItem.id}
                          cursor="pointer"
                          onClick={select}
                          alignItems="center"
                          py={3}
                          borderColor="magnetize.ui-3"
                          borderBottomWidth={1}
                          _hover={{
                            backgroundColor: '#fafafa',
                          }}
                          fontWeight={lineItem.type === 'bundle' ? 'bold' : 'normal'}
                        >
                          <Checkbox mr={8} isChecked={isSelected} onClick={select} />
                          <Box flex="2">{lineItem.name}</Box>
                          <Flex flex="1" justifyContent="flex-end">
                            <CurrencyText value={lineItem.unitPriceCents} />
                            {price?.unit && (
                              <Text color="magnetize.text-4" pl={1}>
                                &#47;
                                {' '}
                                {price?.unit}
                              </Text>
                            )}
                          </Flex>
                        </Flex>
                      );
                    }) }
                  </Fragment>
                ),
              )}
            </Flex>
          </SkeletonText>
        </ModalBody>
        <ModalFooter>
          {selectedCount > 0 && (
          <Text>
            {`${selectedCount} item${selectedCount > 1 ? 's' : ''} selected`}
          </Text>
          )}
          <Spacer />
          <Button
            disabled={selectedCount === 0}
            onClick={() => {
              onAdd(selected);
              onClose();
            }}
          >
            {addButtonText}
          </Button>
          <Button variant="ghost" onClick={onClose}>cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PriceBookBrowseModal;
