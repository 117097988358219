import {
  Box, Button, Flex, Heading, ListItem, Text,
  OrderedList, Stack, useDisclosure, Spacer,
} from '@chakra-ui/react';
import { values } from 'lodash';
import React, { useEffect, useState } from 'react';
import SearchSelect from '~/components/SearchSelect';
import StatusAlert from '~/components/StatusAlert';
import ChangeXeroOrgModal from './ChangeXeroOrgModal';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import { XeroActions } from '~/redux/xero/actions';
import { XeroStatus } from '~/types/xero';
import { DeactivateXeroModal } from '~/pages/TenantSettings/PowerUpsTab/XeroSettings/DeactivateXeroModal';

export const XeroSettings = ({ xeroStatus, isError, errorMessage }:
{ xeroStatus: XeroStatus, isError:boolean, errorMessage?:string }) => {
  const dispatch = useAppDispatch();

  const [invoiceAccounts, setInvoiceAccounts] = useState(xeroStatus?.config?.invoiceAccounts);
  useEffect(() => {
    setInvoiceAccounts(xeroStatus?.config?.invoiceAccounts);
  }, [xeroStatus?.config?.invoiceAccounts]);

  const xeroOrgName = useAppSelector((s) => s.xero?.organization?.name);
  const hasMultipleOrgs = useAppSelector((s) => s.xero.status?.organizations?.length > 1);
  const needsToSelectOrg = useAppSelector((s) => s.xero.status?.status === 'needs-select-org');
  const changeOrgModal = useDisclosure();
  const deactivatePowerup = useDisclosure();

  const xeroAccounts = useAppSelector((s) => s.xero.accounts);
  useEffect(() => {
    if (xeroOrgName) {
      dispatch(XeroActions.fetchAccounts());
    }
  }, [xeroOrgName]);

  useEffect(() => {
    if (needsToSelectOrg && !changeOrgModal.isOpen) {
      changeOrgModal.onOpen();
    }
  }, [needsToSelectOrg, changeOrgModal.onOpen, changeOrgModal.isOpen]);

  const connectToXero = () => {
    window.location.href = xeroStatus?.consentUrl;
  };

  const status = xeroStatus?.status ?? 'needs-reauth';

  return (
    <>
      <Stack spacing={8} mb={12} padding={2}>
        {isError && (
          <StatusAlert mt={4} status="error" title="Xero integration not available">
            {errorMessage || 'Something has gone wrong when connecting to Xero.'}
          </StatusAlert>
        )}

        {!isError && status === 'disconnected' && (
          <Stack spacing="8">
            <Box>
              <Heading size="sm" textTransform="none" mb="4">Connect to Xero to send invoices</Heading>
              <OrderedList pl="4" spacing="1" maxW="40rem">
                <ListItem>Click &lsquo;Connect to Xero&rsquo;</ListItem>
                <ListItem>Sign in to your Xero account</ListItem>
                <ListItem>
                  You&apos;ll be asked to give Magnetize to access your Xero organisation.
                  If you belong to multiple organisations, you&apos;ll
                  also need to pick the one to connect with.
                </ListItem>
              </OrderedList>
            </Box>
            <Box>
              <Button size="lg" onClick={connectToXero}>Connect to Xero</Button>
            </Box>
          </Stack>
        )}

        {!isError && status === 'needs-reauth' && (
          <Box>
            <StatusAlert mb="4" status="warning" title="Connection to Xero Expired">
              This could happen if you haven&apos;t used it in 60 days. It&apos;s
              easily fixed by reconnecting.
            </StatusAlert>
            <Button onClick={connectToXero}>Reconnect to Xero</Button>
          </Box>
        )}

        {(status === 'ready' || status === 'needs-select-org') && (
          <Stack spacing={8}>
            {status === 'ready' && (
            <>
              <Flex mt={4}>
                <Flex
                  fontSize="12px"
                  alignItems="center"
                  px="8"
                  py="4"
                  h="30px"
                  bg="magnetize.ui-3"
                >
                  <Box fontSize="lg">
                    {xeroOrgName ?? 'Unknown'}
                  </Box>
                  {hasMultipleOrgs && (
                  <Box>
                    <Button
                      ml="2"
                      variant="link"
                      fontWeight="semibold"
                      colorScheme="green"
                      onClick={() => changeOrgModal.onOpen()}
                    >
                      change
                    </Button>
                  </Box>
                  )}
                </Flex>
              </Flex>
              <Stack>
                <Heading size="sm">Account Mapping</Heading>
                <Text>
                  Map labour, equipment, consumables and fixed costs to the
                  correct sales account type in Xero,
                  so invoice items can be reconciled correctly.
                </Text>
              </Stack>
              <Stack>
                <Flex alignItems="center">
                  <Heading flex="1" size="sm">Labour</Heading>
                  <Box flex="2">
                    <SearchSelect
                      placeholder="Select account"
                      hideSearchIcon
                      getOptionLabel={(o) => `${o.code} - ${o.name}`}
                      getOptionValue={(o) => o.code}
                      value={invoiceAccounts?.labour}
                      onChange={(labour) => {
                        setInvoiceAccounts({
                          ...invoiceAccounts,
                          labour,
                        });
                      }}
                      options={values(xeroAccounts)}
                    />
                  </Box>
                </Flex>
                <Flex alignItems="center">
                  <Heading flex="1" size="sm">Equipment</Heading>
                  <Box flex="2">
                    <SearchSelect
                      placeholder="Select account"
                      hideSearchIcon
                      getOptionLabel={(o) => `${o.code} - ${o.name}`}
                      getOptionValue={(o) => o.code}
                      value={invoiceAccounts?.equipment}
                      onChange={(equipment) => {
                        setInvoiceAccounts({
                          ...invoiceAccounts,
                          equipment,
                        });
                      }}
                      options={values(xeroAccounts)}
                    />
                  </Box>
                </Flex>
                <Flex alignItems="center">
                  <Heading flex="1" size="sm">Consumables</Heading>
                  <Box flex="2">
                    <SearchSelect
                      placeholder="Select account"
                      hideSearchIcon
                      getOptionLabel={(o) => `${o.code} - ${o.name}`}
                      getOptionValue={(o) => o.code}
                      value={invoiceAccounts?.consumable}
                      onChange={(consumable) => {
                        setInvoiceAccounts({
                          ...invoiceAccounts,
                          consumable,
                        });
                      }}
                      options={values(xeroAccounts)}
                    />
                  </Box>
                </Flex>
                <Flex alignItems="center">
                  <Heading flex="1" size="sm">Fixed Costs</Heading>
                  <Box flex="2">
                    <SearchSelect
                      placeholder="Select account"
                      hideSearchIcon
                      getOptionLabel={(o) => `${o.code} - ${o.name}`}
                      getOptionValue={(o) => o.code}
                      value={invoiceAccounts?.fee}
                      onChange={(fee) => {
                        setInvoiceAccounts({
                          ...invoiceAccounts,
                          fee,
                        });
                      }}
                      options={values(xeroAccounts)}
                    />
                  </Box>
                </Flex>
              </Stack>
              <Flex>
                <Spacer />
                <Button onClick={() => {
                  dispatch(XeroActions.setInvoiceAccounts(invoiceAccounts));
                }}
                >
                  Save
                </Button>
              </Flex>
            </>
            )}
          </Stack>
        )}

        <Box>
          <Button
            variant="link"
            color="magnetize.support-error"
            onClick={deactivatePowerup.onOpen}
          >
            Deactivate Power Up
          </Button>
        </Box>
      </Stack>

      {changeOrgModal.isOpen && (
      <ChangeXeroOrgModal onClose={changeOrgModal.onClose} />
      )}
      {deactivatePowerup.isOpen && (
      <DeactivateXeroModal
        onCancel={deactivatePowerup.onClose}
        onDeactivated={() => {
          dispatch(XeroActions.disconnect({}));
          deactivatePowerup.onClose();
        }}
      />
      )}
    </>
  );
};
