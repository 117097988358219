import { createReducer } from '@reduxjs/toolkit';
import { keyBy, sortBy } from 'lodash';
import TimesheetActions from '~/redux/timesheets/actions';
import { Dictionary } from '~/types/helpers';
import { TimeRecord } from '~/types/time';

interface TimeState {
  entities: Dictionary<TimeRecord>;
  byJob: Dictionary<string[]>;
  byRange: Dictionary<string[]>;
}

const timeReducer = createReducer<TimeState>({
  entities: {},
  byJob: {},
  byRange: {},
}, (builder) => {
  builder.addCase(TimesheetActions.timesUpdatedForJob, (state, action) => {
    const { jobId, times } = action.payload;
    return ({
      entities: {
        ...state.entities,
        ...keyBy(times, (t) => t.id),
      },
      byJob: {
        ...state.byJob,
        [jobId]: sortBy(times, (t) => t.start).map((t) => t.id),
      },
      byRange: {
        ...state.byRange,
      },
    });
  });
  builder.addCase(TimesheetActions.timesUpdatedForRange, (state, action) => {
    const { from, to, times } = action.payload;
    return ({
      entities: {
        ...state.entities,
        ...keyBy(times, (t) => t.id),
      },
      byJob: {
        ...state.byJob,
      },
      byRange: {
        ...state.byRange,
        [`${from}_${to}`]: sortBy(times, (t) => t.start).map((t) => t.id),
      },
    });
  });
});

export default timeReducer;
