import {
  AlertDialog, AlertDialogBody, AlertDialogCloseButton, AlertDialogContent, AlertDialogFooter,
  AlertDialogHeader, AlertDialogOverlay, Box, Button, Img, Text,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import useTrackedAction from '~/hooks/useTrackedAction';
import { selectCurrentUser } from '~/redux/currentUser/selectors';
import { updateJob } from '~/redux/jobs/actions';
import { useAppSelector } from '~/redux/store';
import { showToast } from '~/toast';
import { selectJobById } from '~/redux/jobs/selectors';
import { selectCustomerContactById } from '~/redux/customerContacts/selectors';

const noop = () => {};

const TurnOffDialog = ({
  jobId,
  isOpen,
  onClose = noop,
}: {
  jobId: string;
  isOpen?: boolean;
  onClose?: () => void;
}) => {
  const currentUser = useAppSelector(selectCurrentUser);
  const job = useAppSelector((state) => selectJobById(state, jobId));
  const jobContact = useAppSelector(
    (s) => (job?.jobContactId ? selectCustomerContactById(s, job.jobContactId) : null),
  );
  const siteContact = useAppSelector(
    (s) => (job?.siteContactId ? selectCustomerContactById(s, job.siteContactId) : null),
  );

  const cancelRef = useRef<HTMLButtonElement>(null);

  const contacts = `${jobContact ? jobContact.name : ''}${jobContact && siteContact ? ' and ' : ''}${siteContact ? siteContact.name : ''}`;
  const { trigger: onTurnOnConfirm, isLoading: isSaving } = useTrackedAction({
    trigger: () => updateJob({
      id: jobId,
      liveGeoAutoSend: {
        enabled: true,
        setByStaffId: currentUser.staff.id,
      },
    }),
    onSuccess: () => {
      showToast({
        description: `Logs will be sent to ${contacts}`,
        status: 'success',
      });
    },
  });

  return (
    <AlertDialog
      isOpen={isOpen ?? false}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      size="3xl"
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontWeight="bold">
            Turn on auto-send?
            <Text size="lg" mt={2}>Share logs in real time from when you break ground</Text>
          </AlertDialogHeader>
          <AlertDialogCloseButton tabIndex={-1} />
          <AlertDialogBody>
            <Text size="lg">
              {`When the first hole is logged on this job ${contacts}
              will receive an email with a link to view logs
              being recorded in real time.`}
            </Text>
            <Box padding={5} backgroundColor="magnetize.ui-2" justifyContent="center" mt={10}>
              <Img
                width="250px"
                alt="preview"
                margin="auto"
                src="/images/live-geo/customer-facing-preview.png"
              />
            </Box>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              size="lg"
              onClick={() => {
                onTurnOnConfirm();
                onClose();
              }}
              disabled={isSaving}
            >
              Turn On
            </Button>
            <Button ref={cancelRef} variant="ghost" onClick={onClose}>
              cancel
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default TurnOffDialog;
