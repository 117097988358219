import {
  Button, Modal, ModalBody, ModalCloseButton, ModalContent,
  ModalFooter, ModalHeader, ModalOverlay,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';

import XeroContactMatch from '~/components/XeroContactMatch';
import useTrackedAction from '~/hooks/useTrackedAction';
import { selectInvoiceById } from '~/redux/invoices/selectors';
import { selectJobById } from '~/redux/jobs/selectors';
import { useAppSelector } from '~/redux/store';
import { XeroActions } from '~/redux/xero/actions';

interface XeroCreateInvoiceModalProps {
  invoiceId: string;
  jobId: string;
  isOpen: boolean;
  onClose: () => void;
}

const XeroCreateInvoiceModal = ({
  invoiceId, jobId, isOpen, onClose,
}: XeroCreateInvoiceModalProps) => {
  const [xeroContactId, setXeroContactId] = useState<string>();
  const magnetizeInvoice = useAppSelector((s) => selectInvoiceById(s, invoiceId));
  const job = useAppSelector((s) => selectJobById(s, magnetizeInvoice?.jobId));

  const { trigger, isLoading: isCreatingInvoice, isDone } = useTrackedAction({
    trigger: () => XeroActions.createInvoice({
      jobId,
      invoiceId,
      xeroContactId,
    }),
  });

  useEffect(() => {
    if (isDone) {
      onClose();
    }
  }, [isDone, onClose]);

  const handleCreateInvoice = () => {
    if (xeroContactId) {
      trigger();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Match to Xero Customer</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <XeroContactMatch
            customerId={job?.customerId}
            xeroContactId={xeroContactId}
            onChange={(id) => setXeroContactId(id)}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="brandDark"
            disabled={!xeroContactId || !invoiceId || isDone}
            isLoading={isCreatingInvoice}
            onClick={handleCreateInvoice}
          >
            Send to Xero
          </Button>
          <Button variant="ghost" onClick={onClose}>cancel</Button>

        </ModalFooter>
      </ModalContent>
    </Modal>

  );
};

export default XeroCreateInvoiceModal;
