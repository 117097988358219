export const TooltipStyles = {
  baseStyle: {
    '--tooltip-bg': 'colors.magnetize.text-1',
    px: 4,
    py: 2,
    fontWeight: 'semibold',
    borderRadius: 'md',
  },

  defaultProps: {
    placement: 'top',
    hasArrow: true,
  },
};
