import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Heading,
  SkeletonText,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { orderBy } from 'lodash';
import React, { useMemo } from 'react';
import useTrackedAction from '~/hooks/useTrackedAction';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import QuotePanel from '~/pages/Job/components/quotes/QuotePanel';
import QuoteStatus from '~/pages/Job/components/quotes/QuoteStatus';
import { addQuoteToJob } from '~/redux/jobs/actions';
import { QuoteActions } from '~/redux/quote/actions';
import {
  selectQuotesByJobId,
} from '~/redux/quote/selectors';

const JobQuoteTab = ({ jobId }: { jobId: string, }) => {
  const { isLoading, data: quotes } = useTrackedFetch({
    key: `job-quotes-${jobId}`,
    trigger: () => QuoteActions.fetchForJob({ jobId }),
    selector: (state) => selectQuotesByJobId(state, jobId),
  });

  const { trigger: triggerAdd, isLoading: addQuoteLoading } = useTrackedAction({
    trigger: () => addQuoteToJob({
      jobId,
    }),
  });

  const sortedQuotes = useMemo(
    () => orderBy(quotes ?? [], (x) => x.code),
    [quotes],
  );

  if (isLoading) {
    return (
      <SkeletonText noOfLines={2} />
    );
  }

  return (
    <>
      {sortedQuotes.length === 1 && sortedQuotes[0].status === 'draft'
        ? (
          <Box>
            <Heading as="h3" size="md" mb={0}>CREATE QUOTE</Heading>
            <QuotePanel quote={sortedQuotes[0]} />
          </Box>
        )
        : (
          <Accordion
            key={sortedQuotes.length}
            defaultIndex={[sortedQuotes.length - 1]}
            allowToggle
          >
            {sortedQuotes.map((q, ix) => (
              <AccordionItem key={q.id}>
                <AccordionButton px={3} py={0}>
                  <Box borderColor="magnetize.ui-3" flex={1} py={4}>
                    <QuoteStatus quote={q} />
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel
                  paddingTop={0}
                  paddingBottom={ix !== sortedQuotes.length - 1 ? 20 : undefined}
                  borderColor="magnetize.ui-3"
                  borderBottomWidth={ix === sortedQuotes.length - 1 ? 0 : 1}
                  px={3}
                >
                  <QuotePanel
                    quote={q}
                    canCreateVariations={!sortedQuotes.some((x) => x.status === 'draft' || x.status === 'sent')}
                    isVariation={q.status === 'draft' && sortedQuotes.some((x) => x.status === 'accepted')}
                  />
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        ) }

      {sortedQuotes.length === 0 && (
        <Box>
          <Heading as="h3" size="md" mb={8}>CREATE QUOTE</Heading>
          <Text mb={10} maxW="420px" size="lg">
            Create a quote from your ratebook for this job, and send
            your customer a secure link to accept it online.
          </Text>
          <Button
            size="lg"
            alignItems="center"
            onClick={() => {
              triggerAdd();
            }}
            disabled={addQuoteLoading}
          >
            {addQuoteLoading ? (
              <>
                <Spinner mr={5} />

                <Text>
                  CREATING QUOTE
                </Text>
              </>
            )
              : (
                <Text>
                  CREATE QUOTE
                </Text>
              )}
          </Button>
        </Box>
      )}
    </>
  );
};

export default JobQuoteTab;
