import { Box, Flex, Icon } from '@chakra-ui/react';
import React from 'react';
import { ReactComponent as EditNote } from '~/assets/icon-edit-note.svg';
import AddSchedulingNote, { AddSchedulingNoteProps } from '~/components/AddCalendarEventPopover/AddSchedulingNote';

const AddSchedulingNoteWithEditIcon = (props : AddSchedulingNoteProps) => (
  <Flex>
    <Icon fontSize="18px" boxSize="18px" as={EditNote} mr={2} />
    <Box mt={-2} flex="1">
      <AddSchedulingNote {...props} />
    </Box>
  </Flex>
);

export default AddSchedulingNoteWithEditIcon;
