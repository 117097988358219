import {
  Grid,
} from '@chakra-ui/react';
import React from 'react';

interface LogRowProps {
  children: React.ReactNode;
}

const LogRow = ({ children } : LogRowProps) => (
  <Grid
    px={12}
    py={2}
    borderBottomWidth={1}
    borderBottomColor="magnetize.ui-3"
    templateColumns="0.5fr 1.5fr 2fr 4fr"
    gap={10}
  >
    {children}
  </Grid>
);

export default LogRow;
