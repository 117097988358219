import React from 'react';
import { Box, BoxProps, Tooltip } from '@chakra-ui/react';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import { triggerTagsFetch } from '~/redux/tags/actions';
import { getTagById } from '~/redux/tags/selectors';
import { JobTag } from '~/types/tag';

export const JobTagSwatch = ({ id, ...props }: { id: string } & BoxProps) => {
  const { data: tag, isLoading } = useTrackedFetch<JobTag>({
    key: `tag:${id}`,
    selector: (state) => getTagById(state, id) as JobTag,
    trigger: () => triggerTagsFetch({ force: false }),
    equalityMode: 'deep',
  });

  if (isLoading || !tag) {
    return null;
  }
  return (
    <Tooltip hasArrow label={tag.name}>
      <Box backgroundColor={tag.color} borderRadius={2} w={4} h={4} {...props} />
    </Tooltip>
  );
};
