import {
  Heading, Text, Box, Flex, Stack, Button, Icon,
} from '@chakra-ui/react';
import React from 'react';
import { ArrowBack } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { HelpLinks } from '~/components/SupportLinks';

const NoPermissionsPage = () => {
  const history = useHistory();

  const LogoImage = () => (
    <Box pt={6}>
      <img
        width="210px"
        height="37px"
        alt="Magnetize Logo"
        src="/images/login-logo.png"
      />
    </Box>
  );

  return (
    <Flex
      minH="100vh"
      minW="100vw"
      justifyContent="center"
    >
      <Stack
        spacing={10}
        mt="12%"
        width={{ base: '100vw', sm: '310px', md: '400px' }}
        height={{ base: '100vh', sm: 'fit-content', md: 'fit-content' }}
        alignItems="center"
        direction="column"
      >
        <LogoImage />
        <Heading fontWeight={700} textTransform="none" align="center">
          No access permission
        </Heading>
        <Box>
          <Text
            fontSize="xl"
            align="center"
            paddingInline="5%"
          >
            Looks like you&apos;ve stumbled across a page without the
            right access permissions to view it.
          </Text>
          <Text
            fontSize="xl"
            align="center"
            paddingInline="5%"
            mt={3}
            pb={3}
          >
            Return to log in, or contact your Magnetize administrator for help.
          </Text>
        </Box>
        <Button
          textTransform="uppercase"
          textDecoration="none"
          leftIcon={<Icon as={ArrowBack} mr={-1} />}
          variant="solid"
          size="lg"
          onClick={() => {
            history.goBack();
          }}
        >
          <Text mt={0.5}>
            Go Back
          </Text>
        </Button>
        <Box
          height={60}
        />
        <HelpLinks />
      </Stack>
    </Flex>

  );
};

export default NoPermissionsPage;
