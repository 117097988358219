import { useCallback, useState } from 'react';
import { useReactToPrint } from 'react-to-print';

interface UsePrintHelperProps {
  documentTitle: string;
  contentRef: React.RefObject<HTMLElement>;
  pageStyle?: string;
}

export const usePrintHelper = ({
  contentRef,
  documentTitle,
  pageStyle = `
    html, body {
      zoom: 100% !important;
      height: initial !important;
      width: initial !important;
    }
  `,
}: UsePrintHelperProps) => {
  const [isPrinting, setIsPrinting] = useState(false);
  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
    documentTitle,
    pageStyle,
    onBeforePrint: () => { setIsPrinting(true); },
    onAfterPrint: () => { setIsPrinting(false); },
    removeAfterPrint: false,
  });

  const print = useCallback(() => {
    // TODO: clear timeout id? This shouldn't really be a problem.
    setIsPrinting(true);
    setTimeout(() => handlePrint(), 100);
  }, [handlePrint]);

  return {
    print,
    isPrinting,
  };
};
