import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';
import ShowMoreText from 'react-show-more-text';

import LinkButton from '~/components/LinkButton';

interface ExpandableTextProps extends BoxProps {
  lines?: number;
  children: React.ReactNode;
}

const ExpandableText = ({ children, lines = 1, ...props } :ExpandableTextProps) => (
  <Box fontStyle="italic" {...props}>
    <ShowMoreText
      lines={lines}
      more={<LinkButton ml={2} fontStyle="italic">more...</LinkButton>}
      less={<LinkButton ml={2} fontStyle="italic">less...</LinkButton>}
      truncatedEndingComponent=""
    >
      {children}
    </ShowMoreText>
  </Box>
);

export default ExpandableText;
