import {
  addDays,
  addSeconds,
  differenceInMinutes,
  format,
  formatISO, getTime, Interval, isSameDay,
  isSameMonth, isSameYear, isValid, max, startOfDay, subDays, toDate,
} from 'date-fns';
import { range } from 'lodash';

export const subdivideInterval = (interval: Interval, subdivisions: number): Date[] => {
  const startTime = getTime(interval.start);
  const subdivDuration = (getTime(interval.end) - startTime) / subdivisions;
  return range(subdivisions).map((i) => toDate(startTime + (i * subdivDuration)));
};

export const moveInterval = (interval: Interval, toStartAt: Date) => {
  const delta = getTime(toStartAt) - getTime(interval.start);
  return {
    start: toDate(toStartAt),
    end: toDate(getTime(interval.end) + delta),
  };
};

/**
 * Finds the end of the day specified in seconds (eg truncates milliseconds)
 * Gross bodge to deal with the way that rounding is different in different places
 *
 * @param d the date you want to find the end of day for
 * @returns the next day less a second.
 */
export const endOfDaySeconds = (d: Date | number)
: Date => addSeconds(addDays(startOfDay(d), 1), -1);

/**
 * If a timestamp is very close to a day boundary, interpret it as the end of
 * the previous day; otherwise the end of the day it falls in.
 *
 * e.g. if a timestamp is around midnight, treat it as the end of the previous
 * day
 *
 * @param t
 * @returns
 */
const sensiblyRoundToDayEnd = (t: number | Date): Date => {
  // TODO: this would work just as well if it was rounding to the nearest hour,
  // not day, and be more applicable in other calendar views.
  const endOfPreviousDay = endOfDaySeconds(subDays(t, 1));
  if (Math.abs(differenceInMinutes(t, endOfPreviousDay)) < 10) {
    return endOfPreviousDay;
  }
  return endOfDaySeconds(t);
};

/**
 * If a timestamp is very close to a day boundary, interpret it as
 * the start of that day. 23:59 will be rounded to 00:00 the next day.
 * @param t
 * @returns
 */
const sensiblyRoundToDayStart = (t: number | Date): Date => {
  const startOfNextDay = startOfDay(addDays(t, 1));
  if (Math.abs(differenceInMinutes(t, startOfNextDay)) < 10) {
    return startOfNextDay;
  }
  return startOfDay(t);
};

/**
 * Gets an ID string for this day -- in ISO8601 notation.
 *
 * "2021-09-18" or something.
 */
export const getIsoDate = (t: number | Date): string => (
  formatISO(startOfDay(t), { representation: 'date' })
);

/**
 * Takes a time interval and tries to find the start and end of an interval in
 * days, doing a bit of rounding to fudge the start and end dates so that
 * timestamps very near day boundaries get rounded in the appropriate direction.
 *
 * The schedule editor currently isn't doing rounding when you resize a schedule
 * event and it tends to set the end time to midnight, rather than the
 * millisecond before. While the edit behaviour should be tightened up, this helper
 * is used by the main calendar view to align these events to days.
 *
 * @param interval
 * @returns
 */
export const roundIntervalToDays = (interval: Interval): { start: Date, end: Date } => {
  const startDay = sensiblyRoundToDayStart(interval.start);
  return ({
    start: startDay,
    end: max([
      // If the end date is very close to midnight, assume it's indicating the end
      // of the previous day.
      sensiblyRoundToDayEnd(interval.end),

      // But, the minimum interval should still cover a whole day! If the start and end are
      // very close together, the above rounding could give an invalid negative interval.
      endOfDaySeconds(startDay),
    ]),
  });
};

/**
 * Takes an interval and turns it into a human readable date string
 * @param interval
 * @returns
 */
export const formatDateRange = (interval: Interval): string => {
  const { start, end } = interval;

  if (!isValid(start) || !isValid(end)) {
    return '';
  }
  if (isSameDay(start, end)) {
    return format(start, 'dd MMM yyyy');
  }
  if (isSameMonth(start, end)) {
    return format(start, 'dd') + format(end, ' - dd MMM yyyy');
  }
  if (isSameYear(start, end)) {
    return format(start, 'dd MMM') + format(end, ' - dd MMM yyyy');
  }
  return format(start, 'dd MMM yyyy') + format(end, ' - dd MMM yyyy');
};
