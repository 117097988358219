import { Box, Tag, Text } from '@chakra-ui/react';
import React from 'react';
import { JobStatusId, JobStatusInfo } from '~/types/job';

const StatusBadge = (
  { status, isCollapsed = false }:
  { status: JobStatusId, isCollapsed?: boolean },
) => {
  const statusDetails = JobStatusInfo[status]
  ?? {
    color: 'rgba(23,25,19,0.2)',
    textColor: '#000000',
    text: status,
  };

  return (
    <Tag
      py={1.5}
      borderRadius="full"
      fontWeight="bold"
      color={statusDetails.textColor}
      backgroundColor={statusDetails.color}
    >
      {!isCollapsed && (
        <>
          <Box h="0.5rem" w="0.5rem" backgroundColor={statusDetails.textColor} borderRadius="0.25rem" ml={1.5} />
          <Text
            mx={2}
            whiteSpace="nowrap"
            fontSize="1rem"
          >
            {statusDetails.text}
          </Text>
        </>
      )}
    </Tag>
  );
};

export default StatusBadge;
