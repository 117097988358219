import {
  Button, Flex, Grid, GridItem, Icon, Spacer, Stack, Text,
} from '@chakra-ui/react';
import {
  closestCorners, DndContext, PointerSensor, useSensor, useSensors,
} from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { AddOutlined, RemoveCircleOutlineOutlined } from '@material-ui/icons';
import {
  getUnixTime, startOfDay,
} from 'date-fns';
import { isEqual } from 'lodash';
import React, {
  useEffect, useMemo, useRef,
} from 'react';
import ResourceCalendarActions from '~/redux/resourceCalendar/actions';
import { ScheduleEventActions } from '~/redux/schedule/actions';
import { CalendarContextProvider } from '~/components/Calendar/CalendarContext';
import CalendarDayDividers from '~/components/Calendar/CalendarDayDividers';
import CalendarHeader from '~/components/Calendar/CalendarHeader';
import CalendarNav from '~/components/Calendar/CalendarNav';
import { getCalendarDateRange } from '~/components/Calendar/getDateRange';
import ResourceCalendarRow from '~/components/ResourceCalendar/ResourceCalendarRow';
import StaffAndEquipmentSelect from '~/components/StaffAndEquipmentSelect';
import { useResourcesQuery } from '~/queries/useResourcesQuery';
import { selectResourcesAssignedToJob } from '~/redux/schedule/selectors';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import { ResourceIdentifier } from '~/types/resource';
import { endOfDaySeconds, getIsoDate } from '~/utils/calendarHelpers';

const CALENDAR_GRID_AREAS = `
  "search header"
  "add-remove calendar-header"
  "resources calendar-body"
`;

interface SchedulingCalendarProps {
  jobId?: string;
  isReadOnly?: boolean;
}

const ResourceCalendar = ({ jobId, isReadOnly = false }: SchedulingCalendarProps) => {
  const focusedDay = useAppSelector((s) => (
    s.resourceCalendar.focusedDay ?? getIsoDate(startOfDay(Date.now()))
  ));

  const mode = useAppSelector((s) => (
    s.resourceCalendar.rangeMode ?? 'week'
  ));

  const { start: startDate, end: endDate } = useMemo(
    () => getCalendarDateRange(focusedDay, mode),
    [focusedDay, mode],
  ); const dispatch = useAppDispatch();
  const calendarElementRef = useRef<HTMLDivElement>();

  const visibleResourcesFromPrefs = useAppSelector((s) => s.prefs.resourceCalendarResources ?? []);
  const setResources = (r: ResourceIdentifier[]) => {
    dispatch(ResourceCalendarActions.setResources({ resources: r }));
  };

  const { data: allResources, isLoading: isLoadingResources } = useResourcesQuery();
  const allNonDeletedResources = useMemo(() => (
    allResources?.filter((r) => !r.isDeleted) ?? []
  ), [allResources]);

  const resources = useMemo(() => (
    visibleResourcesFromPrefs.filter((x) => (!isLoadingResources
      ? allNonDeletedResources.some((r) => r.id === x.id)
      : true))
  ), [visibleResourcesFromPrefs, allNonDeletedResources, isLoadingResources]);

  const resourceIds = useMemo(() => resources.map((r) => r.id), [resources]);

  const allocatedResources = useAppSelector(
    (state) => selectResourcesAssignedToJob(state, jobId),
    (a, b) => isEqual(a, b),
  );

  useEffect(() => {
    dispatch(ScheduleEventActions.fetchForRange({
      from: getUnixTime(startOfDay(startDate)),
      to: getUnixTime(endOfDaySeconds(endDate)),
    }));
  }, [startDate, endDate]);

  useEffect(() => {
    if (jobId) {
      dispatch(ScheduleEventActions.fetchForJob({ jobId }));
    }
  }, [jobId]);

  const addMissingResources = (resourcesToAdd: ResourceIdentifier[]) => {
    const resourcesNotInList = (resourcesToAdd ?? [])
      .filter((r) => !resources.find((x) => x.id === r.id));
    setResources([
      ...resources,
      ...resourcesNotInList.map((r) => ({ id: r.id, type: r.type })),
    ]);
  };

  useEffect(() => {
    if (jobId) {
      addMissingResources(allocatedResources);
    }
  }, [jobId, allocatedResources]);

  const addResource = (resource: ResourceIdentifier) => {
    addMissingResources([resource]);
  };

  const removeResource = (resourceIdToRemove: string) => {
    setResources(resources.filter((r) => r.id !== resourceIdToRemove));
  };

  const loadAllResources = () => {
    addMissingResources(allNonDeletedResources);
  };

  const clearResources = () => {
    setResources([]);
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = resources.findIndex((x) => x.id === active.id);
      const newIndex = resources.findIndex((x) => x.id === over.id);
      setResources(arrayMove(resources, oldIndex, newIndex));
    }
  };

  return (
    <CalendarContextProvider
      start={startDate}
      end={endDate}
      calendarElementRef={calendarElementRef}
    >
      <Grid
        templateAreas={CALENDAR_GRID_AREAS}
        templateColumns="2fr 5fr"
        gridTemplateRows="min-content min-content 1fr"
        overflow="hidden"
        flex={1}
      >
        <GridItem gridArea="search" mr="2">
          <StaffAndEquipmentSelect
            closeMenuOnSelect={false}
            hiddenResourceIds={resourceIds}
            onChange={(r) => addResource(r)}
          />
        </GridItem>
        <GridItem gridArea="add-remove" alignItems="center" mt="4">
          <Flex flexDir="row" pl="2" pr="2">
            <Button
              variant="ghost"
              color="magnetize.text-3"
              size="sm"
              leftIcon={<Icon as={AddOutlined} fontSize="1.2rem" />}
              onClick={loadAllResources}
            >
              Load all
            </Button>
            <Spacer />
            <Button
              variant="ghost"
              size="sm"
              color="magnetize.text-3"
              leftIcon={<Icon as={RemoveCircleOutlineOutlined} fontSize="1.2rem" />}
              onClick={clearResources}
            >
              Remove all
            </Button>
          </Flex>
        </GridItem>
        <GridItem display="flex" alignItems="center" gridArea="header" alignSelf="center">
          <CalendarNav
            focusedDay={focusedDay}
            mode={mode}
            onFocusedDayChange={(d) => (
              dispatch(ResourceCalendarActions.move({ focusedDate: d }))
            )}
            onModeChange={(m) => (
              dispatch(ResourceCalendarActions.setRangeMode({ rangeMode: m }))
            )}
          />
        </GridItem>
        <GridItem gridArea="calendar-body" bg="magnetize.ui-2">
          <CalendarDayDividers />
        </GridItem>
        <GridItem
          ref={calendarElementRef}
          gridArea="calendar-body-start / calendar-body-start / calendar-body-end / calendar-body-end"
          borderLeftWidth="1px"
          borderLeftColor="magnetize.ui-4"
        />
        <GridItem gridArea="calendar-header" mt="4">
          <CalendarHeader />
        </GridItem>
        <GridItem
          borderTopWidth="1px"
          borderTopColor="magnetize.ui-4"
          gridArea="resources-start / resources-start / calendar-body-end / calendar-body-end"
          overflowY="auto"
          flex={1}
        >
          <DndContext
            sensors={sensors}
            collisionDetection={closestCorners}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis, restrictToParentElement]}
          >
            <SortableContext
              items={resources}
              strategy={verticalListSortingStrategy}
            >
              <Stack spacing="0">
                {resources.map((resource) => (
                  <ResourceCalendarRow
                    key={resource.id}
                    jobId={jobId}
                    resourceId={resource.id}
                    resourceType={resource.type}
                    onRemove={removeResource}
                    isReadOnly={isReadOnly}
                  />
                ))}
              </Stack>
            </SortableContext>
          </DndContext>
        </GridItem>
        {resources.length === 0 && (
        <GridItem gridArea="resources" py="8" color="magnetize.text-3">
          <Text size="sm" fontWeight="bold" mb="1">No resources to display</Text>
          <Text size="xs">Use the search above to add people and equipment to this calendar</Text>
        </GridItem>
        )}
      </Grid>
    </CalendarContextProvider>
  );
};

export default ResourceCalendar;
