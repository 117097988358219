import { Flex } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import SearchSelect from '~/components/SearchSelect';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import { JobTag } from '~/types/tag';
import { selectTagsByType } from '~/redux/tags/selectors';
import { triggerTagsFetch } from '~/redux/tags/actions';
import JobTagComponent from '~/components/JobTag';
import { MAGNETIZE_COLOURS } from '~/theme/constants';

const JobTagOption = ({ option }: { option: Partial<JobTag> }) => (
  <Flex justifyContent="space-between">
    <JobTagComponent id={option.id} />
  </Flex>
);

const JobTagSearchSelect = ({
  placeholder = 'Add job tags', tags, disabled = false, onChange, onCreateTag,
} : {
  tags?: string[];
  placeholder?: string;
  disabled?: boolean,
  onChange: (ids: string[]) => void;
  onCreateTag: (name: string) => void;
}) => {
  const { data: jobTags, isLoading } = useTrackedFetch<JobTag[]>({
    key: 'jobTags',
    selector: (state) => selectTagsByType(state, 'job') as JobTag[],
    trigger: () => triggerTagsFetch({ force: false }),
    equalityMode: 'deep',
  });

  const selectedValues = useMemo(
    () => jobTags?.filter((p) => tags?.includes(p.id)),
    [jobTags, tags],
  );

  return (
    <SearchSelect
      isDisabled={disabled}
      placeholder={placeholder}
      isMulti
      createable
      onCreateOption={onCreateTag}
      createOptionText="Create a new job tag"
      isValidNewOption={() => true}
      isLoading={isLoading}
      hideSearchIcon
      value={selectedValues}
      getOptionLabel={(p) => p.name}
      getOptionValue={(p) => (p as any).value ?? p.id} // for createable it uses value not id
      onChange={(p) => {
        onChange(p.map((x) => x.id));
      }}
      options={jobTags}
      renderOption={JobTagOption}
      hideCaret
      styles={
        {
          control: (styles, state) => ({
            ...styles,
            backgroundColor: state.hasValue || state.menuIsOpen ? MAGNETIZE_COLOURS['ui-2'] : 'transparent',
            borderRadius: '2px',
            borderWidth: 0,
          }),
          placeholder: (styles, state) => ({
            ...styles,
            color: state.isFocused ? MAGNETIZE_COLOURS['text-4'] : MAGNETIZE_COLOURS['text-2'],
            fontWeight: state.isFocused ? 'normal' : 'bold',
            marginLeft: state.isFocused ? '2px' : '-5px',
          }),
          multiValue: (styles, { data }) => ({
            ...styles,
            backgroundColor: `${data.color}22`,
          }),
          multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: data.color,
            fontSize: '1em',
            fontWeight: 'bold',
            maxWidth: '130px',
          }),
          multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: data.color,
            ':hover': {
              backgroundColor: data.color,
              color: 'white',
            },
          }),
        }
      }
    />
  );
};

export default JobTagSearchSelect;
