import {
  Box, CSSObject, Flex, SkeletonText, SystemProps, Text,
} from '@chakra-ui/react';
import React from 'react';

interface CalendarCardProps {
  mainText?: string;
  secondaryText?: string;
  leftContent?: React.ReactNode;
  statusColor?: SystemProps['color'];
  backgroundColor?: SystemProps['color'];
  children?: React.ReactNode | (() => React.ReactNode);
  isLoading?: boolean;
  skeleton?: React.ReactNode;
  isActive?: boolean;
}

const DEFAULT_SKELETON = <SkeletonText noOfLines={2} />;

const INACTIVE_STYLE: CSSObject = {
  bg: 'gray.50',
  opacity: 0.5,
};

const CalendarCard = ({
  mainText, secondaryText,
  children, isLoading = false,
  statusColor = 'transparent',
  backgroundColor = undefined,
  skeleton = DEFAULT_SKELETON,
  isActive = true,
  leftContent,
}: CalendarCardProps) => (
  // Using nested boxes here is an easy way to get a 'faded' effect
  // when the card is inactive. It's not super elegant but it looks
  // nice.
  <Box
    bg="white"
    mx="1"
    boxShadow="0px 1px 2px rgba(0, 0, 0, 0.1)"
    borderWidth="0.25px"
    borderColor="magnetize.ui-3"
    borderRadius="sm"
    borderLeftWidth="6px"
    borderLeftColor={statusColor}
    backgroundColor={backgroundColor}
  >
    <Box
      sx={!isActive ? INACTIVE_STYLE : undefined}
      p="2"
    >
      {isLoading
        ? skeleton
        : (
          <Flex alignItems="center">
            {leftContent && <Box>{leftContent}</Box>}
            <Box flex="1 1 auto" overflow="hidden">
              {(mainText !== null && mainText !== undefined) && (
                <Text
                  as="div"
                  fontWeight="bold"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  fontSize="12px"
                >
                  {mainText || ' '}
                &nbsp;
                </Text>
              )}
              {(secondaryText !== null && secondaryText !== undefined) && (
                <Text
                  as="div"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  fontSize="12px"
                  textColor="magnetize.text-3"
                >
                  {secondaryText || ' '}
                &nbsp;
                </Text>
              )}
              <Box>{typeof children === 'function' ? children() : children}</Box>
            </Box>
          </Flex>
        )}
    </Box>
  </Box>
);

export default CalendarCard;
