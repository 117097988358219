import { forwardRef, Icon, IconProps } from '@chakra-ui/react';
import { SvgIconComponent } from '@material-ui/icons';
import React, { useMemo } from 'react';
import { IconType } from 'react-icons';
import {
  FaRegFile,
  FaRegFileAlt,
  FaRegFileArchive,
  FaRegFileAudio,
  FaRegFileExcel, FaRegFileImage, FaRegFilePdf, FaRegFilePowerpoint, FaRegFileVideo, FaRegFileWord,
} from 'react-icons/fa';
import { ImFileOpenoffice } from 'react-icons/im';

// Match mimetype prefixes to SVG icon components. Order matters as the first
// match will win.
const FILE_TYPE_ICONS: [string[], SvgIconComponent | IconType][] = [
  [['application/pdf'], FaRegFilePdf],
  [
    [
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ],
    FaRegFileWord,
  ],

  [
    [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ],
    FaRegFileExcel,
  ],

  [
    [
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ],
    FaRegFilePowerpoint,
  ],

  [
    ['application/zip', 'application/x-7z-compressed', 'application/vnd.rar'],
    FaRegFileArchive,
  ],

  [
    ['application/vnd.oasis'],
    ImFileOpenoffice,
  ],

  [['image'], FaRegFileImage],
  [['video'], FaRegFileVideo],
  [['audio'], FaRegFileAudio],
  [['text'], FaRegFileAlt],

  // Finally, anything should match this.
  [[''], FaRegFile],
];

const getComponentForMimeType = (mimeType: string) => {
  const mt = mimeType || '';
  return FILE_TYPE_ICONS.find(([pattern]) => pattern.some((prefix) => mt.startsWith(prefix)))?.[1];
};

interface FileTypeIconProps {
  contentType: string;
  width?: React.CSSProperties['width'];
  height?: React.CSSProperties['height'];
  fontSize?: React.CSSProperties['fontSize'];
}

/**
 * Displays a suitable icon for a mimetype. This behaves as a Chakra SVG icon
 * component.
 */
const FileTypeIcon = forwardRef<IconProps, 'svg'>(({
  contentType, ...iconProps
}: FileTypeIconProps & IconProps,
ref) => {
  const IconComponent = useMemo(() => getComponentForMimeType(contentType), [contentType]);
  return (
    <Icon
      {...iconProps}
      ref={ref}
      as={IconComponent}
    />
  );
});

export default FileTypeIcon;
