import { nanoid } from '@reduxjs/toolkit';
import { omit } from 'lodash';
import {
  all, call, put, select, takeEvery,
} from 'redux-saga/effects';
import PriceActions from '~/redux/prices/actions';
import { selectAllPrices, selectPriceById, selectPrices } from '~/redux/prices/selectors';
import { showErrorToast, showToast } from '~/toast';
import { Price } from '~/types/price';
import fetchJson from '~/utils/fetchJson';
import { performFetchSaga } from '~/utils/performFetchSaga';

const watchFetch = takeEvery(
  PriceActions.fetch,
  function* handle() {
    yield performFetchSaga({
      key: 'prices',
      staleTime: 30000,
      * saga() {
        const prices: Price[] = yield call(
          fetchJson,
          '/api/prices',
        );

        yield put(PriceActions.updated({
          prices,
        }));
      },
    });
  },
);

const watchSave = takeEvery(
  PriceActions.save,
  function* handler(action) {
    const { price } = action.payload;
    const id = price.id ?? nanoid();

    const existingPrices: Price[] = yield select(
      (state) => selectPrices(state),
    );

    yield put(PriceActions.updated({
      prices: [price],
    }));

    try {
      const nextPrice = yield call(() => fetchJson(
        `/api/prices/${id}`, {
          method: 'PUT',
          body: omit(price, 'id'),
        },
      ));

      yield put(PriceActions.updated({
        prices: [
          ...(id !== nextPrice.id ? [{
            ...price, id, supersededBy: nextPrice.id, isDeleted: true,
          }] : []),
          nextPrice,
        ],
      }));
      showToast({
        status: 'success',
        title: 'Rate saved!',
      });
    } catch (error) {
      yield put(PriceActions.updated({
        prices: existingPrices,
      }));

      showToast({
        status: 'error',
        title: 'Saving price failed',
        description: String(error),
      });
    }
  },
);

const watchArchive = takeEvery(
  PriceActions.archive,
  function* handle(action) {
    const { priceId } = action.payload;
    const allPrices: Price[] = yield select(selectAllPrices);
    const existing: Price = yield select((s) => selectPriceById(s, priceId));
    if (!existing) {
      return;
    }

    try {
      yield put(PriceActions.updated({
        prices: [{
          ...existing,
          isDeleted: true,
        }],
      }));
      const result: Price = yield call(() => fetchJson(`/api/prices/${priceId}`, {
        method: 'DELETE',
      }));
      yield put(PriceActions.updated({ prices: [result] }));
    } catch (e) {
      yield put(PriceActions.updated({ prices: allPrices }));
      showErrorToast(e, {
        title: 'Could not delete rate',
      });
    }
  },
);
export default function* handlePrices() {
  yield all([
    watchFetch,
    watchSave,
    watchArchive,
  ]);
}
