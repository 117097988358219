import {
  Box, Flex, HStack, Text, VStack,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';

interface DepthLogProps {
  soilImageId: string;
  hasWater: boolean;
  isEndOfHole: boolean;
  depth: number;
  children: ReactNode;
}

export const DepthLayer = ({
  soilImageId, hasWater, depth, children, isEndOfHole,
} : DepthLogProps) => {
  let waterBarColor = '#FFFFFF';
  if (hasWater) {
    waterBarColor = '#7cccef';
  }

  return (
    <Flex
      align="stretch"
      spacing={0}
      alignItems="start"
      fontWeight="semibold"
    >
      {!isEndOfHole ? (
        <>
          <Flex
            alignSelf="stretch"
            width="35px"
            mb={-7}
            mt={5}
            backgroundImage={soilImageId ? `'/images/live-geo/soil/${soilImageId}-bar.png'` : undefined}
          />

          <Flex
            alignSelf="stretch"
            width="8px"
            mb={-7}
            mt={5}
            borderColor={waterBarColor}
            backgroundColor={waterBarColor}
            position="relative"
          />

          <Flex
            borderColor="black"
            backgroundColor="black"
            alignSelf="stretch"
            borderWidth={2}
            mb={-7}
            mt={5}
          />
        </>
      ) : (
        <Flex
          alignSelf="stretch"
          width="45px"
        />
      )}
      {isEndOfHole ? (
        <Box
          borderColor="black"
          backgroundColor="black"
          borderWidth={1}
          width="76px"
          ml="-45px"
          mt={5}
        />
      )
        : (
          <Box
            borderColor="black"
            backgroundColor="black"
            borderWidth={1}
            width={10}
            mt={5}
          />
        )}
      <HStack
        backgroundColor="magnetize.ui-2"
        p={2}
        flex={1}
        ml={1}
        align="stretch"
        spacing={4}
      >
        <Text fontWeight="semibold">
          {depth}
          m
        </Text>
        <VStack align="stretch" alignItems="start">
          {children}
        </VStack>
      </HStack>
    </Flex>
  );
};
