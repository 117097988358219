import { nanoid } from '@reduxjs/toolkit';
import { omit } from 'lodash';
import {
  all, call, put, select, takeEvery,
} from 'redux-saga/effects';
import TaxRateActions from '~/redux/taxRates/actions';
import { selectAllTaxRates } from '~/redux/taxRates/selectors';
import { showErrorToast, showToast } from '~/toast';
import { TaxRate } from '~/types/taxRate';
import fetchJson from '~/utils/fetchJson';
import { performFetchSaga } from '~/utils/performFetchSaga';

const watchFetch = takeEvery(
  TaxRateActions.fetch,
  function* handle() {
    yield performFetchSaga({
      key: 'taxRates',
      staleTime: 30000,
      * saga() {
        const taxRates: TaxRate[] = yield call(
          fetchJson,
          '/api/tax-rates',
        );

        yield put(TaxRateActions.updated({
          taxRates,
        }));
      },
    });
  },
);

const watchSave = takeEvery(
  TaxRateActions.save,
  function* handler(action) {
    const { taxRate } = action.payload;
    const id = taxRate.id ?? nanoid();

    const existingTaxRates: TaxRate[] = yield select(
      (state) => selectAllTaxRates(state),
    );

    yield put(TaxRateActions.updated({
      taxRates: [taxRate],
    }));

    try {
      yield call(() => fetchJson(
        `/api/tax-rates/${id}`, {
          method: 'PUT',
          body: omit(taxRate, 'id'),
        },
      ));
      showToast({
        status: 'success',
        title: 'Rate saved!',
      });
    } catch (error) {
      yield put(TaxRateActions.updated({
        taxRates: existingTaxRates,
      }));

      showToast({
        status: 'error',
        title: 'Saving tax rate failed',
        description: String(error),
      });
    }
  },
);

const watchSetDefault = takeEvery(
  TaxRateActions.setDefault,
  function* handle({ payload: { taxRateId } }) {
    const allTaxRates: TaxRate[] = yield select(selectAllTaxRates);

    yield put(TaxRateActions.updated({
      taxRates: allTaxRates.map((tr) => ({ ...tr, isDefault: tr.id === taxRateId })),
    }));

    try {
      yield call(() => fetchJson(`/api/tax-rates/${taxRateId}/default`, {
        method: 'PUT',
      }));
      showToast({
        status: 'success',
        title: 'Rate set as default!',
      });
    } catch (e) {
      yield put(TaxRateActions.updated({ taxRates: allTaxRates }));
      showErrorToast(e, {
        title: 'Could not set as default tax rate',
      });
    }
  },
);

const watchDelete = takeEvery(
  TaxRateActions.delete,
  function* handle(action) {
    const { taxRateId } = action.payload;
    const allTaxRates: TaxRate[] = yield select(selectAllTaxRates);

    try {
      yield call(() => fetchJson(`/api/tax-rates/${taxRateId}`, {
        method: 'DELETE',
      }));
    } catch (e) {
      yield put(TaxRateActions.updated({ taxRates: allTaxRates }));
      showErrorToast(e, {
        title: 'Could not delete rate',
      });
    }
  },
);

export default function* handlePrices() {
  yield all([
    watchFetch,
    watchSave,
    watchSetDefault,
    watchDelete,
  ]);
}
