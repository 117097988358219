export const RadioStyles = {
  baseStyle: {
    control: {
      _hover: {
        borderColor: 'magnetize.brand-4',
      },
      _checked: {
        backgroundColor: 'magnetize.brand-4',
        borderColor: 'magnetize.brand-4',
        _hover: {
          backgroundColor: 'magnetize.brand-4',
          borderColor: 'magnetize.brand-4',
        },
      },
    },
  },
};
