import { MAGNETIZE_COLOURS, FORM_CONTROL_FOCUS_BORDER_COLOUR } from '~/theme/constants';

export const InputComponentStyles = {
  Input: {
    sizes: {
      lg: {
        field: {
          borderRadius: 'xs',
        },
        addon: {
          borderRadius: 'xs',
        },
      },
      md: {
        field: {
          borderRadius: 'xs',
        },
        addon: {
          borderRadius: 'xs',
        },
      },
      sm: {
        field: {
          borderRadius: 'xs',
        },
        addon: {
          borderRadius: 'xs',
        },
      },
      xs: {
        field: {
          borderRadius: 'xs',
        },
        addon: {
          borderRadius: 'xs',
        },
      },
    },

    defaultProps: {
      focusBorderColor: FORM_CONTROL_FOCUS_BORDER_COLOUR,
    },
  },

  Textarea: {
    baseStyle: {
      minHeight: '50px',
    },
    sizes: {
      xs: {
        borderRadius: 'xs',
      },
      sm: {
        borderRadius: 'xs',
      },
      md: {
        borderRadius: 'xs',
      },
      lg: {
        borderRadius: 'xs',
      },
    },

    defaultProps: {
      focusBorderColor: FORM_CONTROL_FOCUS_BORDER_COLOUR,
    },
  },

  Select: {
    sizes: {
      lg: {
        field: {
          borderRadius: 'xs',
        },
        addon: {
          borderRadius: 'xs',
        },
      },
      md: {
        field: {
          borderRadius: 'xs',
        },
        addon: {
          borderRadius: 'xs',
        },
      },
      sm: {
        field: {
          borderRadius: 'xs',
        },
        addon: {
          borderRadius: 'xs',
        },
      },
      xs: {
        field: {
          borderRadius: 'xs',
        },
        addon: {
          borderRadius: 'xs',
        },
      },
    },
    defaultProps: {
      focusBorderColor: FORM_CONTROL_FOCUS_BORDER_COLOUR,
    },
  },

  NumberInput: {
    baseStyle: {
      field: {
        textAlign: 'right',
      },
    },
    sizes: {
      xs: {
        field: {
          borderRadius: 'xs',
          paddingRight: 8,
        },
        stepper: {
          _first: {
            borderTopRightRadius: 'xs',
          },
          _last: {
            borderBottomRightRadius: 'xs',
          },
        },
      },
      sm: {
        field: {
          borderRadius: 'xs',
          paddingRight: 8,
        },
        stepper: {
          _first: {
            borderTopRightRadius: 'xs',
          },
          _last: {
            borderBottomRightRadius: 'xs',
          },
        },
      },
      md: {
        field: {
          borderRadius: 'xs',
          paddingRight: 8,
        },
        stepper: {
          _first: {
            borderTopRightRadius: 'xs',
          },
          _last: {
            borderBottomRightRadius: 'xs',
          },
        },
      },
      lg: {
        field: {
          borderRadius: 'xs',
          paddingRight: 8,
        },
        stepper: {
          _first: {
            borderTopRightRadius: 'xs',
          },
          _last: {
            borderBottomRightRadius: 'xs',
          },
        },
      },
    },
    variants: {
      ghost: {
        stepper: {
          _first: {
            borderWidth: 0,
            color: MAGNETIZE_COLOURS['ui-4'],
            fontSize: '0.85rem',
            alignItems: 'flex-end',
          },
          _last: {
            borderWidth: 0,
            color: MAGNETIZE_COLOURS['ui-4'],
            fontSize: '0.85rem',
            alignItems: 'flex-start',
          },
        },
      },
    },
    defaultProps: {
      focusBorderColor: FORM_CONTROL_FOCUS_BORDER_COLOUR,
    },
  },
};
