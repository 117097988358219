import {
  Box, Icon, Popover, PopoverArrow, PopoverBody,
  PopoverCloseButton, PopoverContent, PopoverTrigger, Portal, VStack,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import FocusLock from 'react-focus-lock';
import { FaSnowplow } from 'react-icons/fa';
import { ReactComponent as UserHelmet } from '~/assets/icon-user-helmet.svg';
import AddEquipmentPopoverContent from '~/components/AddCalendarEventPopover/AddEquipmentPopoverContent';
import AddLeavePopoverContent from '~/components/AddCalendarEventPopover/AddLeavePopoverContent';
import EventTypeToggle from '~/components/AddCalendarEventPopover/EventTypeToggle';
import JobEventPopoverContent from '~/components/AddCalendarEventPopover/JobEventPopoverContent';
import JobSearchSelect from '~/components/JobSearchSelect';
import StaffAndEquipmentSelect from '~/components/StaffAndEquipmentSelect';
import CalendarTourActions from '~/redux/calendarTour/actions';
import { useAppDispatch } from '~/redux/store';
import { ScheduleEvent } from '~/types/ScheduleEvent';
import eventStopper from '~/utils/eventStopper';

interface AddJobEventPopoverProps {
  defaultType?: 'job' | 'staff-leave' | 'equipment-status';
  interval: { start: Date, end: Date };
  onClose?: () => void;
  onCreateEvent?: (event: ScheduleEvent) => void;
}

const AddJobEventPopover = ({
  interval, onClose, defaultType = 'job', onCreateEvent,
}: AddJobEventPopoverProps) => {
  const dispatch = useAppDispatch();
  const [selectedJobId, setSelectedJobId] = useState<string>();
  const [staffId, setStaffId] = useState<string>();
  const [equipmentId, setEquipmentId] = useState<string>();
  const [type, setType] = useState<ScheduleEvent['type']>(defaultType);

  const selectRef = useRef<any>();

  useEffect(() => {
    const timer = setTimeout(() => selectRef?.current?.focus(), 0);
    return () => {
      clearTimeout(timer);
    };
  }, [type]);

  const itemSelected = selectedJobId || staffId || equipmentId;

  return (
    <Popover
      isOpen
      isLazy
      placement="right-start"
      onClose={onClose}
      closeOnBlur={false}
    >
      <PopoverTrigger>
        <Box minHeight="50px" bg="magnetize.brand-4" opacity="0.25" />
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          {...eventStopper()}
          width="30rem"
          tabIndex={-1}
        >
          <FocusLock as={Box}>
            <PopoverArrow />
            <PopoverCloseButton tabIndex={-1} top={3} right={3} />
            <PopoverBody data-calendar-tour-id="calendar-add-popover">
              <VStack
                pt={8}
                spacing={8}
                align="stretch"
              >
                <VStack
                  spacing={2}
                  align="stretch"
                >
                  {type === 'job' && (
                  <div data-calendar-tour-id="calendar-add-job">
                    <JobSearchSelect
                      variant="flushed"
                      value={selectedJobId}
                      onChange={(id) => {
                        setSelectedJobId(id);
                        dispatch(
                          CalendarTourActions.jobAssignedToEvent(),
                        );
                      }}
                      placeholder="Add a job to schedule"
                      selectRef={selectRef}
                    />
                  </div>
                  )}
                  {type === 'staff-leave' && (
                  <StaffAndEquipmentSelect
                    limitTo="staff"
                    placeholder="Who is going on leave?"
                    showAvailability
                    availabilityStart={interval.start}
                    availabilityEnd={interval.end}
                    closeMenuOnSelect={false}
                    variant="flushed"
                    value={staffId}
                    onChange={(r) => setStaffId(r?.id)}
                    icon={<Icon boxSize="16px" as={UserHelmet} />}
                    selectRef={selectRef}
                    autoFocus
                  />
                  )}
                  {type === 'equipment-status' && (
                  <StaffAndEquipmentSelect
                    limitTo="equipment"
                    placeholder="What equipment will be out of action?"
                    showAvailability
                    availabilityStart={interval.start}
                    availabilityEnd={interval.end}
                    closeMenuOnSelect={false}
                    variant="flushed"
                    value={equipmentId}
                    onChange={(r) => setEquipmentId(r?.id)}
                    icon={<Icon boxSize="16px" as={FaSnowplow} />}
                    selectRef={selectRef}
                    autoFocus
                  />
                  )}
                  {!itemSelected && <EventTypeToggle type={type} onChange={setType} />}
                </VStack>

                {type === 'job' && (
                <JobEventPopoverContent
                  jobId={selectedJobId}
                  interval={interval}
                  onClose={onClose}
                  onCreateEvent={(ev) => onCreateEvent?.(ev)}
                />
                )}
                {type === 'staff-leave' && (
                <AddLeavePopoverContent
                  resourceId={staffId}
                  interval={interval}
                  onClose={onClose}
                  onCreateEvent={(ev) => onCreateEvent?.(ev)}
                />
                )}

                {type === 'equipment-status' && (
                <AddEquipmentPopoverContent
                  resourceId={equipmentId}
                  interval={interval}
                  onClose={onClose}
                  onCreateEvent={(ev) => onCreateEvent?.(ev)}
                />
                )}
              </VStack>
            </PopoverBody>
          </FocusLock>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default AddJobEventPopover;
