import {
  Icon, Input, InputGroup, InputProps, InputRightElement,
} from '@chakra-ui/react';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useState } from 'react';

const PasswordInput = ({ ...inputProps } : InputProps) => {
  const [hidden, setHidden] = useState(true);

  return (
    <InputGroup>
      <Input
        type={hidden ? 'password' : 'text'}
        autoComplete="off"
        {...inputProps}
      />
      <InputRightElement onClick={() => setHidden(!hidden)}>
        <Icon opacity={0.4} as={hidden ? Visibility : VisibilityOff} />
      </InputRightElement>
    </InputGroup>
  );
};

export default PasswordInput;
