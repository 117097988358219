import {
  Box,
  Flex, FormControl, FormLabel, Heading, Image, Input, Text, VStack,
} from '@chakra-ui/react';
import React from 'react';
import IndustrySelector from '~/pages/WelcomeModal/components/IndustrySelector';
import NextButton from '~/pages/WelcomeModal/components/NextButton';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import WelcomeModalActions from '~/redux/welcomeModal/actions';
import { selectWelcomeModalState } from '~/redux/welcomeModal/selectors';

const Welcome = ({ totalSteps, stepNumber }: { totalSteps: number, stepNumber: number }) => {
  const dispatch = useAppDispatch();
  const welcomeState = useAppSelector((state) => selectWelcomeModalState(state));

  return (

    <Flex
      h="100%"
      flex={1}
    >
      <Flex
        padding={20}
        direction="column"
        flex={1}
      >
        <VStack
          alignItems="start"
          spacing={8}
          flex={1}
        >
          <Flex display="column">
            <Heading textTransform="none">Welcome to Magnetize</Heading>
            <Heading
              pl={1}
              pt={2}
              textTransform="none"
              size="sm"
            >
              We&#39;re stoked you&#39;re here. Tell us about you.
            </Heading>
          </Flex>
          <FormControl>
            <FormLabel>
              Organisation name *
            </FormLabel>
            <Input
              value={welcomeState.organisationName}
              onChange={(e) => {
                dispatch(WelcomeModalActions.update({
                  update: {
                    organisationName: e.target.value,
                  },
                }));
              }}
              type="text"
            />
          </FormControl>
          <FormControl>
            <FormLabel>
              Industry
            </FormLabel>
            <Text mb={1}>Which industry best describes your services?</Text>
            <IndustrySelector
              value={welcomeState.industry}
              otherValue={welcomeState.otherIndustry}
              onChange={(industry, otherIndustry) => {
                dispatch(WelcomeModalActions.update({
                  update: {
                    industry,
                    otherIndustry,
                  },
                }));
              }}
            />
          </FormControl>
          <Box flex={1} />
          <FormControl>
            <NextButton isDisabled={!welcomeState.organisationName} />
          </FormControl>
          <Text color="magnetize.text-4" mt={8} width="100%" textAlign="center">
            {`step ${stepNumber} of ${totalSteps}`}
          </Text>
        </VStack>
      </Flex>
      <Flex
        flex={1}
        padding={20}
        backgroundImage="url('/images/login-bg.jpg')"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        alignItems="center"
        justifyContent="center"
      >
        <Image
          src="/images/welcomeModal/welcome.png"
        />
      </Flex>
    </Flex>
  );
};

export default Welcome;
