import {
  all, call, put, select, takeEvery,
} from 'redux-saga/effects';
import { currentUserActions } from '~/redux/currentUser/actions';
import SubscriptionActions from '~/redux/subscription/actions';
import { showToast } from '~/toast';
import {
  SubscriptionPrices, SubscriptionPaymentMethod, SubscriptionSetupIntent, SubscriptionInvoice,
} from '~/types/subscription';
import fetchJson from '~/utils/fetchJson';
import { performFetchSaga } from '~/utils/performFetchSaga';
import { takeEveryTracked } from '~/utils/performTrackedSaga';

const watchFetchSubscriptionPlans = takeEvery(
  SubscriptionActions.fetchPrices,
  function* handle() {
    yield performFetchSaga({
      key: 'subscriptionPlans',
      * saga() {
        const prices:SubscriptionPrices = yield call(
          fetchJson,
          '/api/subscription/prices',
        );

        yield put(SubscriptionActions.pricesUpdated({ prices }));
      },
    });
  },
);
const watchFetchSubscriptionInvoices = takeEvery(
  SubscriptionActions.fetchInvoices,
  function* handle() {
    yield performFetchSaga({
      key: 'subscriptionInvoices',
      * saga() {
        const invoices: SubscriptionInvoice[] = yield call(
          fetchJson,
          '/api/subscription/invoices',
        );

        yield put(SubscriptionActions.invoicesUpdated({ invoices }));
      },
    });
  },
);

const watchFetchSubscriptionPaymentMethod = takeEvery(
  SubscriptionActions.fetchPaymentMethod,
  function* handle() {
    yield performFetchSaga({
      key: 'subscriptionPaymentMethod',
      * saga() {
        const paymentMethod: SubscriptionPaymentMethod = yield call(
          fetchJson,
          '/api/subscription/payment-method',
        );

        yield put(SubscriptionActions.paymentMethodUpdated({ paymentMethod }));
      },
    });
  },
);

const watchSetDefaultPaymentMethod = takeEveryTracked(
  SubscriptionActions.setDefaultPaymentMethod,
  function* handle(action) {
    const {
      stripe, cardElement, name, billingAddress,
    } = action.payload.data;
    yield performFetchSaga({
      key: 'setDefaultPaymentMethod',
      * saga() {
        try {
          const oldPaymentMethod = yield select((s) => s.subscription.paymentMethod);
          const { clientSecret }: SubscriptionSetupIntent = yield call(
            fetchJson,
            '/api/subscription/payment-method/setup',
          );
          // Use your card Element with other Stripe.js APIs
          const { error, setupIntent: result } : any = yield call(
            stripe.confirmCardSetup,
            clientSecret,
            {
              payment_method: {
                card: cardElement,
                billing_details: {
                  name,
                  address: {
                    line1: billingAddress.line1,
                    line2: billingAddress.line2,
                    city: billingAddress.city,
                    postal_code: billingAddress.postCode,
                  },
                },
              },
            },
          );
          if (error) {
            showToast({
              status: 'error',
              title: 'Something went wrong',
              description: error.message,
            });
            return;
          }
          const paymentMethod: SubscriptionPaymentMethod = yield call(
            fetchJson,
            '/api/subscription/payment-method/set-default',
            {
              method: 'POST',
              body: {
                paymentMethodId: result.payment_method,
              },
            },
          );
          showToast({
            status: 'success',
            title: !oldPaymentMethod ? 'Payment method added' : 'Payment method updated',
            description: "Cheers! You're all set to go now.",
          });
          yield put(SubscriptionActions.paymentMethodUpdated({ paymentMethod }));
        } catch {
          showToast({
            status: 'error',
            title: 'Something went wrong',
            description: 'We couldnt update your payment method',
          });
        }
      },
    });
  },
);

const watchActivatePlan = takeEveryTracked(
  SubscriptionActions.activatePlan,
  function* handle() {
    yield performFetchSaga({
      key: 'setDefaultPaymentMethod',
      * saga() {
        yield call(
          fetchJson,
          '/api/subscription/activate-plan',
          {
            method: 'POST',
          },
        );
        yield put(currentUserActions.tenantPlanActivated());
      },
    });
  },
);
const watchCancelPlan = takeEveryTracked(
  SubscriptionActions.cancelPlan,
  function* handle() {
    yield performFetchSaga({
      key: 'setDefaultPaymentMethod',
      * saga() {
        yield call(
          fetchJson,
          '/api/subscription/cancel',
          {
            method: 'POST',
          },
        );

        yield put(currentUserActions.tenantPlanCancelled());
      },
    });
  },
);

const watchAddPowerUp = takeEveryTracked(
  SubscriptionActions.addPowerUp,
  function* handle(action) {
    const { powerUpId } = action.payload.data;
    yield performFetchSaga({
      key: 'addPowerUp',
      * saga() {
        yield call(
          fetchJson,
          `/api/subscription/add-power-up?powerUpId=${powerUpId}`,
          {
            method: 'POST',
          },
        );
        yield put(SubscriptionActions.powerUpAdded({ powerUpId }));
      },
    });
  },
);

const watchRemovePowerUp = takeEveryTracked(
  SubscriptionActions.removePowerUp,
  function* handle(action) {
    const { powerUpId } = action.payload.data;
    yield performFetchSaga({
      key: 'removePowerUp',
      * saga() {
        yield call(
          fetchJson,
          `/api/subscription/remove-power-up?powerUpId=${powerUpId}`,
          {
            method: 'POST',
          },
        );
        yield put(SubscriptionActions.powerUpRemoved({ powerUpId }));
      },
    });
  },
);

export default function* handleStaff() {
  yield all([
    watchFetchSubscriptionPlans,
    watchFetchSubscriptionInvoices,
    watchFetchSubscriptionPaymentMethod,
    watchSetDefaultPaymentMethod,
    watchActivatePlan,
    watchCancelPlan,
    watchAddPowerUp,
    watchRemovePowerUp,
  ]);
}
