import { getInvoiced } from '~/helpers/job';
import { selectJobById } from '~/redux/jobs/selectors';
import { useAppSelector } from '~/redux/store';

export const useInvoiced = (
  { jobId, taxInclusive } : { jobId : string, taxInclusive?: boolean },
) => {
  const job = useAppSelector((state) => selectJobById(state, jobId));

  return getInvoiced({ job, taxInclusive });
};
