import React from 'react';
import { Box } from '@chakra-ui/react';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import { triggerJobFetch } from '~/redux/jobs/actions';
import { selectJobWithEdits } from '~/redux/jobs/selectors';
import { Job } from '~/types/job';
import { JobTagSwatch } from '~/components/JobTagSwatch';

const TagsForJob = ({ jobId }: { jobId: string }) => {
  const { data: job, isLoading } = useTrackedFetch<Job>({
    key: `job:${jobId}`,
    selector: (state) => selectJobWithEdits(state, jobId),
    trigger: () => triggerJobFetch({ jobId }),
    equalityMode: 'deep',
  });

  if (isLoading || !job?.tags) {
    return null;
  }
  return (
    <Box display="flex" mt={2} mb={2}>
      {job?.tags.map((t) => <JobTagSwatch id={t} key={t} mr={2} />)}
    </Box>
  );
};

export default TagsForJob;
