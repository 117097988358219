import React from 'react';
import { Center, Box } from '@chakra-ui/react';

export const SquareButton = ({
  children, isSelected = false, onClick = () => {}, isDisabled = false,
} : {
  children: React.ReactNode, isSelected?: boolean, isDisabled?: boolean, onClick?: () => void;
}) => (
  <Box
    cursor={isDisabled ? 'not-allowed' : 'pointer'}
    backgroundColor={isSelected ? '#f5faf6' : 'magnetize.ui-2'}
    borderRadius={6}
    boxShadow="sm"
    borderWidth="1px"
    borderColor={isSelected ? 'magnetize.brand-5' : 'magnetize.ui-4'}
    width="100%"
    paddingTop="100%" /* 1:1 Aspect Ratio */
    position="relative" /* If you want text inside of it */
    onClick={onClick}
    _hover={isDisabled ? {} : {
      backgroundColor: '#f5faf6',
      borderColor: 'magnetize.brand-4',
    }}
    color={isDisabled ? 'magnetize.text-4' : 'magnetize.text-1'}
  >
    <Center position="absolute" top={0} bottom={0} left={0} right={0}>
      {children}
    </Center>
  </Box>
);
