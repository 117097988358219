import { CloseIcon } from '@chakra-ui/icons';
import {
  Flex, Icon, Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import CalendarTourActions from '~/redux/calendarTour/actions';
import { TourStep } from '~/redux/calendarTour/steps';
import { useAppDispatch } from '~/redux/store';
import './style.css';

interface TourPopoverProps {
  currentStep: TourStep;
  progress: {
    current: number;
    total: number;
  }
}

const selectTourElement = (tourId: string) : HTMLElement => document.querySelector(`[data-calendar-tour-id="${tourId}"]`) as HTMLElement;

const TourPopover = ({ currentStep, progress } : TourPopoverProps) => {
  const dispatch = useAppDispatch();
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: currentStep?.placement ?? 'auto',
    modifiers: [
      {
        name: 'arrow',
        options: { element: arrowElement },
      }, {
        name: 'offset',
        options: {
          offset: currentStep?.offset ?? [0, 0],
        },
      }],
  });

  useEffect(() => {
    // This is pretty gross but we need to ensure the element is in the dom
    // we also need to set the target element to null between steps to stop
    // the popover jumping around
    setTargetElement(null);
    const timeout = setTimeout(() => {
      const el = selectTourElement(currentStep?.targetElement);
      setTargetElement(el);
    }, 250);

    return () => {
      clearTimeout(timeout);
    };
  }, [currentStep]);

  const targetElementIsVisible = !!targetElement?.offsetParent;

  if (!targetElementIsVisible) {
    return <></>;
  }

  return (
    <div
      id="tooltip"
      role="tooltip"
      ref={setPopperElement}
      style={{
        ...styles.popper,
        zIndex: 100,
        boxShadow: '0px 50px 40px 50px rgba(68, 68, 68, 0.05), 0px 2px 8px rgba(102, 102, 102, 0.1)',
        maxWidth: '300px',
      }}
      {...attributes.popper}
    >
      <Flex
        color="white"
        bgColor="magnetize.brand-5"
        borderRadius="md"
        padding={6}
        fontSize="14px"
        direction="column"
      >
        <Flex
          flex="1"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text fontWeight="bold">
            {`${progress.current} / ${progress.total}`}
          </Text>
          <Icon
            fontSize="8px"
            mb={2}
            cursor="pointer"
            as={CloseIcon}
            onClick={() => {
              dispatch(CalendarTourActions.complete());
            }}
          />
        </Flex>
        <Flex mt={6}>
          <Text fontWeight="bold">{currentStep.title}</Text>
        </Flex>
        <Flex mt={2}>
          <Text>{currentStep.description}</Text>
        </Flex>
        <Flex mt={6} flex="1" alignItems="center" justifyContent="space-between">
          <Text
            cursor="pointer"
            textDecoration="underline"
            onClick={() => {
              dispatch(CalendarTourActions.complete());
            }}
          >
            skip tour
          </Text>
        </Flex>
      </Flex>
      <div id="arrow" ref={setArrowElement} data-popper-arrow />
    </div>
  );
};

export default TourPopover;
