import { nanoid } from '@reduxjs/toolkit';
import { Staff } from '~/types/staff';
import fetchJson from '~/utils/fetchJson';

export const getStaffList = () => fetchJson<Staff[]>('/api/staff');

export const getStaff = (staffId: string) => (
  fetchJson<Staff>(`/api/staff/${staffId}`)
);

export const getStaffByCognitoUsername = (cognitoUsername: string) => (
  fetchJson<Staff>(`/api/staff/by-cognito-username/${cognitoUsername}`)
);

export const putStaff = (staff: Staff) => (
  fetchJson<Staff>(`/api/staff/${staff.id ?? nanoid()}`, {
    method: 'PUT',
    body: staff,
  })
);
