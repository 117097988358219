import { Flex, Text } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import PriceActions from '~/redux/prices/actions';
import SearchSelect from '~/components/SearchSelect';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import { selectPrices } from '~/redux/prices/selectors';
import { Price } from '~/types/price';
import { useCurrencyFormatter } from '~/hooks/useFormatCurrency';

interface RateSearchSelectProps {
  category: 'labour' | 'equipment';
  value: string;
  placeholder?: string;
  menuPlacement?: 'top' | 'bottom' | 'auto';
  maxMenuHeight?: number | undefined;
  onChange: (value: string) => void;
}

const RateOption = ({ option }: { option: Partial<Price> }) => {
  const formatCurrency = useCurrencyFormatter();
  return (
    <Flex justifyContent="space-between">
      <Text>
        {option.name}
      </Text>
      {option.id && (
      <Text>
        {`${formatCurrency(option.unitPriceCents)} / ${option.unit}`}
      </Text>
      )}
    </Flex>
  );
};
const RateSearchSelect = ({
  placeholder = '', category, value, menuPlacement = 'auto', maxMenuHeight = undefined, onChange,
} : RateSearchSelectProps) => {
  const { data: prices, isLoading } = useTrackedFetch<Price[]>({
    key: 'prices',
    selector: (state) => selectPrices(state),
    trigger: () => PriceActions.fetch(),
    equalityMode: 'deep',
  });

  const filteredPrices = useMemo(
    () => (prices ?? []).filter((p) => p.category === category),
    [category, prices],
  );

  const options = useMemo(
    () => ([{
      id: undefined,
      name: 'No rate',
    },
    ...filteredPrices]),
    [filteredPrices],
  );

  const selectedValue = useMemo(
    () => options.find((p) => p.id === value),
    [options, prices, value],
  );

  return (
    <SearchSelect
      isLoading={isLoading}
      hideSearchIcon
      value={selectedValue}
      getOptionLabel={(p) => p.name}
      getOptionValue={(p) => p.id}
      onChange={(p) => {
        onChange(p.id);
      }}
      options={options}
      renderOption={RateOption}
      placeholder={placeholder}
      menuPlacement={menuPlacement}
      maxMenuHeight={maxMenuHeight}
    />
  );
};

export default RateSearchSelect;
