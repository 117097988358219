import {
  Button, Flex, FormControl, FormLabel, Grid, Input,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import LinkButton from '~/components/LinkButton';
import LoginBackground from '~/components/LoginBackground';
import LoginCard from '~/components/LoginCard';
import PasswordInput from '~/components/PasswordInput';
import WarningLabel from '~/components/WarningLabel';
import useTrackedAction from '~/hooks/useTrackedAction';
import { currentUserActions } from '~/redux/currentUser/actions';
import { selectFetchState } from '~/redux/fetch/selectors';
import { useAppSelector } from '~/redux/store';

export default () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [hasAttemptedLogin, setHasAttemptedLogin] = useState(false);
  const isDisabled = !email || !password;

  const location = useLocation<{ redirectTo?: string }>();

  const { isError, errorType } = useAppSelector(
    (s) => selectFetchState(s, 'currentUser'),
  );

  const errorMessage = useMemo(() => {
    switch (errorType) {
      case 'InvalidParameterException':
        return 'Please enter a valid email address.';
      case 'UserNotFoundException':
        return 'No account found with that email address.';
      case 'NotAuthorizedException':
        return 'Incorrect email or password, try again.';
      default:
        return 'An error occurred. Check your email and password then try again.';
    }
  }, [errorType]);

  const { trigger: signIn, isLoading } = useTrackedAction({
    trigger: () => {
      setHasAttemptedLogin(true);
      return currentUserActions.signIn({
        username: email.trim(),
        password,
        redirectTo: location.state?.redirectTo,
      });
    },
  });

  // Clear password field on error
  useEffect(() => {
    if (isError) {
      setPassword('');
    }
  }, [isError]);

  useEffect(() => {
    setHasAttemptedLogin(false);
  }, []);

  return (
    <LoginBackground>
      <LoginCard title="Log in">
        <Grid
          gap={6}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !isDisabled) {
              signIn();
            }
          }}
        >
          {!isLoading
          && hasAttemptedLogin
          && isError && <WarningLabel mt={2} message={errorMessage} />}
          <FormControl>
            <FormLabel>Email</FormLabel>
            <Input
              isDisabled={isLoading}
              value={email}
              onChange={(e) => { setEmail(e.target.value); }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Password</FormLabel>
            <PasswordInput
              isDisabled={isLoading}
              value={password}
              onChange={(e) => { setPassword(e.target.value); }}
            />
          </FormControl>
          <Flex>
            <Button
              isDisabled={isDisabled}
              isLoading={isLoading}
              size="lg"
              onClick={signIn}
            >
              Log in to magnetize
            </Button>
          </Flex>
          <Flex>
            <LinkButton
              isGreen
              noUnderline
              to="/forgot-password"
            >
              Forgot Password?
            </LinkButton>
          </Flex>
        </Grid>
      </LoginCard>
    </LoginBackground>
  );
};
