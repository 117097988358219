import {
  Icon, Table, Text, Tbody, Tr, VStack, Td, Skeleton, MenuItem,
} from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
import React, { useState } from 'react';
import { ReactComponent as RefusalIcon } from '~/assets/surface-type.svg';
import ConfirmationDialog from '~/components/ConfirmationDialog';
import { ExpandableCountSection } from '~/components/ExpandableCountSection';
import { KebabOptionsMenu } from '~/components/KebabOptionsMenu';
import LinkButton from '~/components/LinkButton';
import { useModalManager } from '~/components/ModalManager';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import { SurfaceTypeEditModal } from './SurfaceTypeEditModal';
import { triggerSurfaceTypesFetch, deleteSurfaceType } from '~/redux/surfaceTypes/actions';
import { getActiveSurfaceTypes } from '~/redux/surfaceTypes/selectors';
import { useAppDispatch } from '~/redux/store';
import { SurfaceType } from '~/types/surfaceType';
import eventStopper from '~/utils/eventStopper';

export const SurfaceTypesList = () => {
  const dispatch = useAppDispatch();
  const modalManager = useModalManager();
  const { data: surfaceTypes, isLoading } = useTrackedFetch({
    key: 'surface-types',
    trigger: () => triggerSurfaceTypesFetch({ force: false }),
    selector: getActiveSurfaceTypes,
  });

  const [
    selectedSurfaceType,
    setSelectedSurfaceType,
  ] = useState<Partial<SurfaceType>>(null);
  const [surfaceTypeModalTitle, setSurfaceTypeModalTitle] = useState<string>();

  const addTechnique = () => {
    setSelectedSurfaceType({
      id: nanoid(),
    });
    setSurfaceTypeModalTitle('Add surface finish type');
  };

  const editTechnique = (surfaceType: SurfaceType) => {
    setSelectedSurfaceType(surfaceType);
    setSurfaceTypeModalTitle('Edit surface finish type');
  };

  const deleteTechnique = (surfaceTypeId) => {
    dispatch(deleteSurfaceType({
      id: surfaceTypeId,
    }));
  };

  return (
    <VStack
      align="stretch"
    >
      <ExpandableCountSection
        title="Surface finish types"
        icon={<Icon boxSize={8} as={RefusalIcon} />}
        count={surfaceTypes?.length || 0}
      >
        <Table size="md">
          <Tbody>
            {isLoading ? (
              <Tr>
                <Td><Skeleton height={8} /></Td>
                <Td><Skeleton height={8} /></Td>
                <Td />
              </Tr>
            )
              : (orderBy(surfaceTypes, (t) => t.name).map((tr) => (
                <Tr
                  key={tr.id}
                  _hover={{ bg: '#fafafa', cursor: 'pointer' }}
                  transition="color 0.1s ease-out"
                  role="group"
                  onClick={() => editTechnique(tr)}
                >
                  <Td>
                    <Text pl={24}>
                      {tr.name}
                    </Text>
                  </Td>

                  <Td textAlign="end" {...eventStopper()}>
                    <KebabOptionsMenu>
                      <MenuItem onClick={() => editTechnique(tr)}>Edit</MenuItem>
                      <MenuItem
                        onClick={() => {
                          modalManager.open(ConfirmationDialog, {
                            title: 'Delete surface finish type',
                            children: 'This will remove the surface finish type from Magnetize, but will not remove it from any existing logs.',
                            onConfirm: () => deleteTechnique(tr.id),
                            confirmButtonLabel: 'Delete',
                          });
                        }}
                      >
                        Delete
                      </MenuItem>
                    </KebabOptionsMenu>
                  </Td>
                </Tr>
              )))}
          </Tbody>
        </Table>
        <LinkButton ml={24} mt={6} isGreen noUnderline onClick={addTechnique}>
          + add surface finish type
        </LinkButton>
        {!!selectedSurfaceType
      && (
      <SurfaceTypeEditModal
        title={surfaceTypeModalTitle}
        onClose={() => {
          setSelectedSurfaceType(null);
        }}
        surfaceType={selectedSurfaceType}
      />
      )}
      </ExpandableCountSection>
    </VStack>
  );
};
