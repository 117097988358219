import {
  Table, Tbody, Td, Text, Th, Thead, Tr,
} from '@chakra-ui/react';
import React, { useMemo } from 'react';
import CurrencyText from '~/components/CurrencyText';
import { Dictionary } from '~/types/helpers';
import { LineItem } from '~/types/lineItem';
import { sortLineItems } from '~/utils/lineItemHelpers';

interface InvoiceLineItemsTableProps {
  lineItems: Dictionary<LineItem>;
}

const InvoiceLineItemsTable = ({ lineItems }: InvoiceLineItemsTableProps) => {
  const sortedLineItems = useMemo(() => sortLineItems(lineItems), [lineItems]);
  return (
    <Table variant="simple" size="md">
      <Thead>
        <Tr>
          <Th width="40%">Item</Th>
          <Th width="20%" isNumeric>Unit Price</Th>
          <Th width="20%" isNumeric>Qty</Th>
          <Th width="20%" isNumeric>Total</Th>
        </Tr>
      </Thead>
      <Tbody>
        {sortedLineItems.map((li) => (
          <Tr key={li.id}>
            <Td>
              <Text>{li.name}</Text>
              {li.description && <Text fontSize="md" fontStyle="italic" mt={1}>{li.description}</Text>}
            </Td>
            <Td isNumeric><CurrencyText value={li.unitPriceCents} /></Td>
            <Td isNumeric>{li.quantity}</Td>
            <Td isNumeric><CurrencyText value={li.quantity * li.unitPriceCents} /></Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default InvoiceLineItemsTable;
