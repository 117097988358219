import { Stack } from '@chakra-ui/react';
import '@uppy/core/dist/style.css';
import '@uppy/drop-target/dist/style.css';
import '@uppy/status-bar/dist/style.css';
import { partition } from 'lodash';
import React, { useMemo } from 'react';
import ImageGallery from '~/components/ImageGallery';
import UploadedFileList from '~/components/UploadedFileList';
import UploadedFile from '~/types/uploaded-file';

interface FileGalleryProps {
  files: UploadedFile[];
  onFileRemoved?: (file: UploadedFile) => void;
  compact?: boolean;
}

const FileGallery = ({ files = [], compact = false, onFileRemoved } : FileGalleryProps) => {
  const [imageFiles, otherFiles] = useMemo(
    () => partition(files, (f) => f.contentType?.startsWith('image')),
    [files],
  );

  if (files.length === 0) {
    return <></>;
  }

  return (
    <Stack flex="1" spacing={2}>
      {imageFiles.length > 0 && (
      <ImageGallery
        imageWidth={50}
        files={imageFiles}
        onFileRemoved={onFileRemoved}
      />
      )}
      {otherFiles.length > 0 && (
      <UploadedFileList
        compact={compact}
        files={otherFiles}
        onFileRemoved={onFileRemoved}
      />
      )}
    </Stack>
  );
};

export default FileGallery;
