import { MenuDivider, MenuItem, Text } from '@chakra-ui/react';
import React, { useCallback, useState, useEffect } from 'react';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { MdDeleteOutline } from 'react-icons/md';
import { ReactComponent as Archive } from '~/assets/archive.svg';
import { ReactComponent as Duplicate } from '~/assets/duplicate.svg';
import ConfirmationDialog from '~/components/ConfirmationDialog';
import { KebabOptionsMenu } from '~/components/KebabOptionsMenu';
import { useModalManager } from '~/components/ModalManager';
import Routes from '~/pages/routes';
import { updateJob, archiveJob } from '~/redux/jobs/actions';
import { QuoteActions } from '~/redux/quote/actions';
import { selectQuotesByJobId } from '~/redux/quote/selectors';
import { useAppDispatch } from '~/redux/store';
import { Job } from '~/types/job';
import useTrackedFetch from '~/hooks/useTrackedFetch';
import LinkButton from '~/components/LinkButton';
import { DeleteExampleDataModal } from '~/components/DeleteExampleDataModal';
import { DuplicateJobModal } from '~/pages/Job/components/DuplicateJobModal';
import { getIsArchivable, formatJobCode } from '~/helpers/job';

export const JobKebabMenu = ({ job }: { job: Job }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const modalManager = useModalManager();
  const [deleteExampleDataVisible, setDeleteExampleDataVisible] = useState(false);
  const [duplicateJobId, setDuplicateJobId] = useState(null);
  const [canArchive, setCanArchive] = useState(false);

  const { data: quotes } = useTrackedFetch({
    key: `job-quotes-${job.id}`,
    trigger: () => QuoteActions.fetchForJob({ jobId: job.id }),
    selector: (state) => selectQuotesByJobId(state, job.id),
  });

  useEffect(() => {
    setCanArchive(getIsArchivable({ job, quotes }));
  }, [job, quotes]);

  const deleteDemoJob = useCallback(() => {
    const id = modalManager.open(ConfirmationDialog, {
      title: 'Delete job',
      children: (
        <>
          <Text>
            Deleting this job will remove this job and all its information
            from Magnetize. You will no longer be able to access any quotes,
            logs, notes or invoices created for this job.
          </Text>
          <Text mt={2}>
            {'Or click here to '}
            <LinkButton onClick={() => {
              setDeleteExampleDataVisible(true);
              modalManager.close(id);
            }}
            >
              delete all demo data
            </LinkButton>
            {' instead.'}

          </Text>
        </>),
      onConfirm: () => dispatch(updateJob({
        id: job.id,
        isDeleted: true,
      })),
      confirmButtonLabel: 'Delete job',
    });
  }, [modalManager, dispatch, job.id]);

  const toggleArchiveJob = useCallback(() => {
    let modalTitle = 'Archive this job ';
    let modalChild = `${formatJobCode(job.code)} will be archived in Magnetize. You will still be able to find it later.`;
    let buttonLabel = 'Archive';

    if (job.status === 'archived') {
      modalTitle = 'Undo archive';
      modalChild = `${formatJobCode(job.code)} will be unarchived and restored to its previous status.`;
      buttonLabel = 'Undo Archive';
    }
    modalManager.open(ConfirmationDialog, {
      title: modalTitle,
      children: modalChild,
      onConfirm: () => dispatch(archiveJob({
        jobId: job.id,
      })),
      confirmButtonLabel: buttonLabel,
    });
  }, [dispatch, modalManager, job.id]);

  const deleteJob = useCallback(() => {
    modalManager.open(ConfirmationDialog, {
      title: 'Delete job',
      children: 'This job will be deleted from Magnetize. You will no longer be able to access it.',
      onConfirm: () => dispatch(updateJob({
        id: job.id,
        isDeleted: true,
      })),
      confirmButtonLabel: 'Delete',
    });
  }, [dispatch, modalManager, job.id]);

  return (
    <>
      <KebabOptionsMenu>
        <MenuItem onClick={() => history.push(Routes.job({ jobId: job.id }))}>
          Edit job
        </MenuItem>
        {quotes?.length === 1
        && (
          <MenuItem
            as={RouterLink}
            to={Routes.jobQuotePreview({ jobId: job.id, quoteId: quotes[0].id })}
          >
            View Quote
          </MenuItem>
        )}
        {quotes?.length > 1
        && (
        <MenuItem onClick={() => { history.push(Routes.jobQuote({ jobId: job.id })); }}>
          View Quotes
        </MenuItem>
        )}
        <MenuDivider />
        <MenuItem icon={<Duplicate width="1.5rem" />} onClick={() => setDuplicateJobId(job.id)}>
          Duplicate
        </MenuItem>
        {job.status === 'archived'
          ? (
            <MenuItem
              icon={<Archive width="1.5rem" />}
              onClick={toggleArchiveJob}
            >
              Undo archive
            </MenuItem>
          ) : (
            <MenuItem
              icon={<Archive width="1.5rem" />}
              onClick={toggleArchiveJob}
              isDisabled={!canArchive}
            >
              Archive
            </MenuItem>
          )}
        {!job.isDemo && (
        <MenuItem icon={<MdDeleteOutline fontSize="1.8rem" />} onClick={deleteJob} isDisabled={!(['draft', 'quote'].includes(job.status))}>
          Delete
        </MenuItem>
        )}
        {(job.isDemo) && (
        <MenuItem onClick={deleteDemoJob}>
          Delete demo job
        </MenuItem>
        )}
      </KebabOptionsMenu>
      {deleteExampleDataVisible
    && (
    <DeleteExampleDataModal
      onCancel={() => { setDeleteExampleDataVisible(false); }}
    />
    )}
      {duplicateJobId
  && (
    <DuplicateJobModal
      jobId={duplicateJobId}
      onCancel={() => setDuplicateJobId(null)}
    />
  )}
    </>
  );
};
