import {
  Button, FormControl,
  Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay,
  Stack, Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import SearchSelect from '~/components/SearchSelect';
import { useAppDispatch, useAppSelector } from '~/redux/store';
import { XeroActions } from '~/redux/xero/actions';

interface ChangeXeroOrgModalProps {
  onClose: () => void;
}

const ChangeXeroOrgModal = ({ onClose }: ChangeXeroOrgModalProps) => {
  const dispatch = useAppDispatch();
  const xeroOrgs = useAppSelector((s) => s.xero.status?.organizations ?? [], 'deep');
  const currentTenantId = useAppSelector((s) => s.xero.status.xeroTenantId);

  const [selectedOrgId, setSelectedOrgId] = useState<string>();
  const selectedOrg = xeroOrgs?.find((o) => o.organisationID === selectedOrgId);

  useEffect(() => {
    setSelectedOrgId(currentTenantId);
  }, [currentTenantId]);

  const handleSaveClicked = () => {
    dispatch(XeroActions.setOrganisation({
      xeroTenantId: selectedOrgId,
    }));
    onClose();
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen
      onClose={onClose}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Select Xero Organisation
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing="8">
            <Text>
              You have multiple Xero organisations connected to Magnetize. Pick which one
              you want to use.
            </Text>
            <FormControl>
              <SearchSelect
                options={xeroOrgs}
                value={selectedOrg}
                hideSearchIcon
                onChange={(o) => setSelectedOrgId(o?.organisationID)}
                getOptionLabel={(o) => o.name}
                getOptionValue={(o) => o.organisationID}
                placeholder="Select Xero organisation"
              />
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="brandDark"
            disabled={!selectedOrg}
            onClick={handleSaveClicked}
          >
            Save
          </Button>
          <Button variant="ghost" onClick={onClose}>cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ChangeXeroOrgModal;
