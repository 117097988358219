import { createReducer } from '@reduxjs/toolkit';
import CalendarTourActions from '~/redux/calendarTour/actions';

export interface CalendarTourState {
  currentStepIndex: number;
  event: {
    id: string;
    start: number;
    end: number;
  };
  highlightedDay: {
    start: number,
    end: number
  };
}

const calendarTourReducer = createReducer<CalendarTourState>({
  currentStepIndex: -1,
  event: null,
  highlightedDay: null,
}, (builder) => {
  builder.addCase(CalendarTourActions.setStep, (state, action) => ({
    ...state,
    currentStepIndex: action.payload.step,
  }));

  builder.addCase(CalendarTourActions.setEvent, (state, action) => ({
    ...state,
    event: action.payload.event,
  }));

  builder.addCase(CalendarTourActions.setHighlightDay, (state, action) => ({
    ...state,
    highlightedDay: action.payload,
  }));

  builder.addCase(CalendarTourActions.complete, (state) => ({
    ...state,
    currentStepIndex: -1,
  }));
});

export default calendarTourReducer;
