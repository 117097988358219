import { SubscriptionPrices } from '~/types/subscription';

export const calculateStaffPrice = (
  prices: SubscriptionPrices,
) => (prices.admin.currentCount * prices.admin.priceCents)
    + (prices.staff.currentCount * prices.staff.priceCents);

export const calculatePowerUpsPrice = (
  prices: SubscriptionPrices,
) => (prices?.powerUps || [])
  .filter((x) => x.isActive)
  .reduce((acc, x) => acc + x.priceCents, 0);

export const calculateSubscriptionTotalPrice = (
  prices: SubscriptionPrices,
) => calculateStaffPrice(prices) + calculatePowerUpsPrice(prices);
