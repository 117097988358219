import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ChakraProvider } from '@chakra-ui/react';
import { QueryClientProvider } from 'react-query';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import 'focus-visible/dist/focus-visible';

// Polyfill for older browsers
import 'core-js/actual/object/from-entries';

// eslint-disable-next-line no-restricted-imports
import { StylesProvider as MaterialStylesProvider } from '@material-ui/core';

import { first, split } from 'lodash';
import { Router } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import RootPage from '~/pages';
import store from './redux/store';
import theme from '~/theme';
import queryClient from '~/queryClient';
import { ModalManager } from '~/components/ModalManager';
import history from '~/history';

store.dispatch({ type: 'app/init' });

Sentry.init({
  dsn: 'https://06770f1713e5401db37e16512f1a4ae4@o961081.ingest.sentry.io/5909466',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.2,
  environment: first(split(process.env.REACT_APP_FORCE_ENVIRONMENT || window.location.hostname, '.')),
  release: process.env.REACT_APP_COMMIT_SHA,
});

ReactDOM.render(
  <React.StrictMode>
    {/*
      We need to explicitly provide Material UI's styles here to
      ensure the styles get injected before Chakra's in the DOM.
      Without this, Material UI's styles take precedence over
      Chakra/Emotion's and prevents us from re-styling the icons we use.
    */}
    <MaterialStylesProvider injectFirst>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
          <Provider store={store}>
            <Router history={history}>
              <ModalManager>
                <RootPage />
              </ModalManager>
            </Router>
          </Provider>
        </ChakraProvider>
      </QueryClientProvider>
    </MaterialStylesProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
