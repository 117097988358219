import {
  FormControl, FormErrorMessage, FormLabel, Grid, HStack, Input,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { CustomerContact } from '~/types/customerContact';

interface CustomerContactEditFormProps {
  value: Partial<CustomerContact>;
  onChange: (customerContact: Partial<CustomerContact>, canSave: boolean) => void;
  focusName?: boolean;
}

const CustomerContactEditForm = (
  { value, onChange, focusName = false }
  : CustomerContactEditFormProps,
) => {
  const nameInput = useRef(null);

  const [name, setName] = useState(value.name || '');
  const [email, setEmail] = useState(value.email || '');
  const [phone, setPhone] = useState(value.phone || '');

  const emailValid = yup.string().email().isValidSync(email);
  const canSave = !!name && emailValid;

  useEffect(() => {
    onChange({
      ...(value || {}),
      name,
      email: email || undefined,
      phone: phone || undefined,
    },
    canSave);
  }, [
    name,
    email,
    phone,
  ]);

  useEffect(() => {
    if (focusName) {
      nameInput?.current?.focus();
    }
  }, []);

  return (
    <Grid gap={2}>
      <FormControl>
        <FormLabel>Customer Contact</FormLabel>
        <Input
          ref={nameInput}
          value={name}
          onChange={(e) => { setName(e.target.value); }}
          placeholder="Name *"
        />
      </FormControl>
      <HStack>
        <FormControl
          flex="3"
          alignItems="start"
          isInvalid={!emailValid}
        >
          <Input
            value={email}
            type="email"
            onChange={(e) => { setEmail(e.target.value); }}
            placeholder="Email address"
          />
          <FormErrorMessage>email is invalid</FormErrorMessage>
        </FormControl>
        <FormControl flex="2">
          <Input
            value={phone}
            onChange={(e) => { setPhone(e.target.value); }}
            placeholder="Phone number"
          />
        </FormControl>
      </HStack>
    </Grid>
  );
};

export default CustomerContactEditForm;
